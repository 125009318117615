import { InitializationOptions } from '@braze/web-sdk';

export const getBrazeConfig = (config: {
  production?: boolean;
}): {
  apiKey: string;
  options: InitializationOptions;
} => ({
  apiKey: config?.production ? '4f799017-8f6c-4e07-bd44-f5ce08c1dfed' : '2241af2b-0711-4852-bd8d-cb013a85175c',
  options: {
    baseUrl: 'sdk.iad-05.braze.com',
    localization: 'en',
    minimumIntervalBetweenTriggerActionsInSeconds: 2,
    enableLogging: config?.production ? false : true,
  },
});
