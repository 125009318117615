import { AccountsApiClient } from '@melio/platform-api-axios-client';
//eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { ApiError } from '../../core';
import { BankAccountDeliveryMethod } from '../delivery-method';

export const useOrganizationACHDeliveryMethods = () => {
  const { mutateAsync, ...rest } = useMutation<BankAccountDeliveryMethod[], ApiError, string>(
    ['AccountsApi', 'getOrganizationDeliveryMethodsById'],
    async (orgId: string) => {
      const { data } = await AccountsApiClient.getOrganizationDeliveryMethodsById(orgId);
      return data.data.filter((dm): dm is BankAccountDeliveryMethod => dm.type === 'bank-account');
    }
  );

  return {
    getOrganizationDeliveryMethodsById: mutateAsync,
    ...rest,
  };
};
