import * as braze from '@braze/web-sdk';
import { ModalMessage } from '@braze/web-sdk';

export type Props = {
  onButtonClick: (b: braze.InAppMessageButton, openInNewTab: boolean) => void;
  onClose: VoidFunction;
  message: ModalMessage;
  properties: ParsedProperties;
  logImpression: VoidFunction;
};

export type ModalProps = {
  message: ModalMessage;
  properties: ParsedProperties;
  clearMessage: VoidFunction;
  logMessageClick: VoidFunction;
  logImpression: VoidFunction;
  logButtonClick: (b: braze.InAppMessageButton) => void;
};

export type BannerProps = {
  properties: ParsedProperties;
  clearMessage: VoidFunction;
  logMessageClick: VoidFunction;
  logImpression: VoidFunction;
  logButtonClick: (b: braze.InAppMessageButton) => void;
  message: ModalMessage;
};

export enum ModalType {
  SINGLE_BUTTON,
  DOUBLE_BUTTON,
}

export type ElementProperties = Map<string, Map<string, string>>;
export type GeneralProperties = Map<string, string>;

export type ParsedProperties = {
  elementProperties: ElementProperties;
  generalProperties: GeneralProperties;
};

export enum BrazeContentCardType {
  DASHBOARD_CONTENT_CARD = 'dashboard_content_card',
  SPLASH_BANNER_TOP = 'splash_banner_top',
}
