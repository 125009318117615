export const Success: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_131926)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0002 126C101.824 126 126 101.823 126 72.0001C126 42.1767 101.824 18 72.0002 18C42.1768 18 18.0001 42.1767 18.0001 72.0001C18.0001 101.823 42.1768 126 72.0002 126Z"
        fill="#128020"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.3816 95.5444L43.5558 79.5604C43.0212 79.0204 43.0212 78.1564 43.5558 77.6164C44.0905 77.0764 44.9459 77.0764 45.4806 77.6164L60.3439 92.6284L96.4865 50.6163C97.0211 50.0763 97.7696 49.9683 98.4112 50.5083C98.9459 51.0483 99.0528 51.8043 98.5181 52.4523L61.4132 95.6524C61.0924 95.8684 60.7717 95.9764 60.3439 95.9764C60.0231 95.9764 59.7024 95.8684 59.3816 95.5444Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_11475_131926">
        <rect width="108" height="108" fill="white" transform="translate(18.0001 18)" />
      </clipPath>
    </defs>
  </svg>
);
