import { useAccount } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { useSubscriptionContext } from '../context';
import { useAccountingClientSubscriptionSelectedPlan } from './useAccountingClientSubscriptionSelectedPlan';
import { useIsSubscriptionsEnabledBase } from './useIsSubscriptionsEnabledBase';

export const useIsSubscriptionsEnabledFull = () => {
  const isBaseEnabled = useIsSubscriptionsEnabledBase();
  const { subscription } = useSubscriptionContext();
  const [isFirstWeek] = usePartnerFeature('subscriptionsFirstWeek', false);
  const { data: account = { isAccountingClient: false }, isLoading } = useAccount({ id: 'me' });
  const hasPlanRequest = !!useAccountingClientSubscriptionSelectedPlan();

  return {
    isLoading,
    enabled: isBaseEnabled && !(isFirstWeek && account.isAccountingClient && !subscription && !hasPlanRequest),
  };
};
