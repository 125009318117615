/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { QueryObserver, useMutation } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useVendor, UseVendorProps, useVendors as _useVendors, Vendor } from '@melio/platform-api';
// eslint-disable-next-line no-restricted-imports
import { PatchVendorsVendorIdRequest, PostVendorsRequest } from '@melio/platform-api-axios-client';

import { createVendor, deleteVendor, updateVendor } from '@/api/vendors/vendors.api';
import { VendorFullyExpanded } from '@/api/vendors/vendors.api.types';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { queryClient } from '@/queries/reactQueryClient';
import { vendorsDashboardSelectedVendorIdSelector, vendorsState } from '@/store/Vendors/Vendors.model';
import { useIsNewDashboardEnabled } from './useIsNewDashboardEnabled';

export const useVendorEnrichedByIdQuery = (vendorId?: string, props?: UseVendorProps) => {
  return useVendor({ id: vendorId, ...props });
};

export const useVendorEnrichedById = (vendorId?: string) => {
  return useVendorEnrichedByIdQuery(vendorId).data as VendorFullyExpanded;
};

export const useVendorCreate = () => {
  const { mutateAsync, ...rest } = useMutation((updateParams: PostVendorsRequest) => createVendor(updateParams), {
    onSuccess: (vendor) => {
      emitAppEvent(APP_EVENTS.VENDOR_CREATED, { vendor: vendor as Vendor });
    },
  });

  return {
    createVendor: mutateAsync,
    ...rest,
  };
};

type CreateVendorUpdateProps = {
  vendorId: string;
  body: PatchVendorsVendorIdRequest;
};
export const useVendorUpdate = () => {
  const { mutateAsync, ...rest } = useMutation(
    ({ vendorId, body }: CreateVendorUpdateProps) => updateVendor(vendorId, body),
    {
      onSuccess: (vendor) => {
        emitAppEvent(APP_EVENTS.VENDOR_UPDATED, { vendor });
      },
    },
  );

  return {
    updateVendor: mutateAsync,
    ...rest,
  };
};

export const useVendorDelete = () => {
  const { mutateAsync, ...rest } = useMutation((id: string) => deleteVendor(id), {
    onSuccess: (data) => {
      emitAppEvent(APP_EVENTS.VENDOR_DELETED, { deletedId: data.id });
    },
  });

  return {
    deleteVendor: mutateAsync,
    ...rest,
  };
};

export const useSelectedVendor = () => {
  const [selectedVendor, setSelectedVendor] = useRecoilState(vendorsDashboardSelectedVendorIdSelector);

  const isSelected = React.useCallback(
    ({ vendorId }: { vendorId: string }) => selectedVendor === vendorId,
    [selectedVendor],
  );

  const onSelect = React.useCallback(({ vendorId }: { vendorId: string | undefined }) => {
    setSelectedVendor(vendorId);
  }, []);

  return {
    isSelected,
    onSelect,
    selectedVendorId: selectedVendor,
  };
};

export const useVendors = () => {
  const { data: vendors, ...query } = _useVendors();

  return {
    ...query,
    vendors,
  };
};

export const useVendorsObserver = () => {
  const isNPEEnabled = useIsNewDashboardEnabled();
  const setVendors = useSetRecoilState(vendorsState);
  const { queryKey } = _useVendors({ enabled: false });

  React.useEffect(() => {
    if (isNPEEnabled) return;
    const observer = new QueryObserver<Vendor[]>(queryClient, { queryKey });
    return observer.subscribe(({ data: vendors, isLoading }) => {
      if (!isLoading && vendors) {
        setVendors(vendors as Vendor[]);
      }
    });
  }, [setVendors]);
};
