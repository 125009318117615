import { useParams } from 'react-router-dom';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { UnilateralActivity } from '@melio/vex-activities';

export const UnilateralRoute = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { paymentId } = useParams<{ paymentId: string }>();
  setAnalyticsProperties({ paymentId, EntryPoint: 'unilateral-payment-email', Flow: 'unilateral' });

  if (!paymentId) {
    return null;
  }

  return <UnilateralActivity paymentId={paymentId} />;
});

UnilateralRoute.displayName = 'UnilateralRoute';
