import { Button, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { PCIComplianceInfo, TermsAndPolicyDisclaimerWidget, useIsMobile } from '@melio/vex-widgets';

import { PersistentFastPreferenceOfferBanner } from './PersistentFastPreferenceOfferBanner.component';
import { PersistentFastLabels } from './types';

type Props = {
  isSaving: boolean;
  onSave: () => Promise<void>;
  persistentFastOfferLabels: PersistentFastLabels['offer'];
};

export const PersistentFastPreferenceCardFooter = ({ isSaving, onSave, persistentFastOfferLabels }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [isPersistentFastActive, setPersistentFastCheckboxState] = useBoolean(false);
  const isMobile = useIsMobile();
  return (
    <Group spacing="m" variant="vertical">
      <Group spacing={isMobile ? 'm' : 'l'} variant="vertical">
        <Text textStyle="heading3Semi">
          {formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.label')}
        </Text>
        <PersistentFastPreferenceOfferBanner
          isChecked={isPersistentFastActive}
          isDisabled={isSaving}
          onChange={setPersistentFastCheckboxState.toggle}
          labels={persistentFastOfferLabels}
        />
        <Group spacing="s" variant="vertical">
          <Button
            data-testid="persistent-fast-preference-save-button"
            isDisabled={!isPersistentFastActive}
            isLoading={isSaving}
            label={formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.CTA')}
            size="large"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSave}
          />
          <TermsAndPolicyDisclaimerWidget alignCenter />
        </Group>
      </Group>
      <PCIComplianceInfo />
    </Group>
  );
};

PersistentFastPreferenceCardFooter.displayName = 'PersistentFastPreferenceCardFooter';
