import { Image } from '@chakra-ui/react';
import { BrandSymbol, BrandSymbolKey, Container, Group, Icon, IconKey, ListItem, NakedButton } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { PaymentReviewLineItemProps } from './PaymentReviewLineItem.widget.types';
import { isBrandSymbol } from './utils';

export const PaymentReviewLineItem = forwardRef<PaymentReviewLineItemProps, 'div'>(
  (
    {
      labelProps,
      mainLabelProps,
      descriptionProps,
      icon,
      imageIcon,
      editText,
      editTestId,
      contentTestId,
      onEdit,
      ...props
    },
    ref
  ) => {
    const isLogoIcon = Boolean(icon?.type && isBrandSymbol(icon.type));
    const hasOnlyMainLabel = !(labelProps?.label || descriptionProps?.label);

    const leftIconComponent = imageIcon ? (
      <Image boxSize={8} src={imageIcon.src} alt={imageIcon.alt} />
    ) : icon ? (
      isLogoIcon ? (
        <BrandSymbol type={icon.type as BrandSymbolKey} size="medium" />
      ) : (
        <Icon type={icon.type as IconKey} />
      )
    ) : null;

    const rightEditButtonComponent = onEdit && editText && (
      <NakedButton
        variant="secondary"
        onClick={onEdit}
        data-testid={editTestId || 'payment-review-line-item-edit-button'}
        aria-label="Edit item"
        label={editText}
      />
    );

    return (
      <Group data-component="PaymentReviewLineItem" {...props} ref={ref}>
        {leftIconComponent && (
          <Group.Item alignSelf={hasOnlyMainLabel ? 'center' : 'flex-start'} shrink={0}>
            <Container paddingY="xs">{leftIconComponent}</Container>
          </Group.Item>
        )}
        <Group.Item grow={1}>
          <Container>
            <ListItem
              labelProps={labelProps}
              mainLabelProps={mainLabelProps}
              descriptionProps={descriptionProps}
              data-testid={contentTestId}
            />
          </Container>
        </Group.Item>
        {onEdit && editText && (
          <Container overflow="visible" width="fit-content" alignItems="flex-start">
            {rightEditButtonComponent}
          </Container>
        )}
      </Group>
    );
  }
);
PaymentReviewLineItem.displayName = 'PaymentReviewLineItem';
