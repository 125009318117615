export const getSessionStorage = () => {
  return window.sessionStorage;
};

export const getSessionStorageItem = (key: string) => {
  return window.sessionStorage.getItem(key);
};

export const setSessionStorageItem = (key: string, value: string) => {
  window.sessionStorage.setItem(key, value);
};

export const clearSessionStorageItem = (key: string) => {
  window.sessionStorage.removeItem(key);
};
