import { Container, Group, IconButton } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

export type ExternalLayoutContentWidgetProps = {
  title: ReactNode;
  backButtonProps?: { isDisabled?: boolean; onClick: VoidFunction };
};

export const Header = forwardRef<ExternalLayoutContentWidgetProps, 'div'>(
  ({ title, backButtonProps, ...props }, ref) => (
    <Container
      data-component="ExternalLayoutContent.Header"
      {...props}
      paddingX="xl"
      paddingY="l"
      backgroundColor="light"
      ref={ref}
    >
      <Group variant="vertical" spacing="l">
        {backButtonProps && (
          <IconButton
            {...backButtonProps}
            data-testid="layout-back-button"
            aria-label="back"
            icon="chevron-left"
            variant="naked"
            size="medium"
          />
        )}
        {title}
      </Group>
    </Container>
  )
);

Header.displayName = 'ExternalLayoutContentWidget.Header';
