import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CloverIcon = (props: IconProps) => (
  <Icon
    width={{ xs: '147px', s: '593px' }}
    height={{ xs: '30px', s: '239px' }}
    viewBox="0 0 593 239"
    fill="none"
    {...props}
  >
    <path
      d="M0.833252 139.234H30.512L38.0233 91.6011H57.0763C87.3047 91.6011 110.205 76.7618 110.205 44.5182C110.205 21.4347 93.3504 8.24411 64.7708 8.24411H21.5351L0.833252 139.234ZM41.1377 70.8993L47.5498 30.5948H61.4732C75.0302 30.5948 81.8086 36.0909 81.8086 47.083C81.8086 62.472 73.0149 70.8993 56.5267 70.8993H41.1377Z"
      fill="#228800"
    />
    <path
      d="M130.379 141.249C143.569 141.249 152.729 135.02 158.775 126.043C158.592 130.074 158.592 134.837 159.325 139.234H184.607C183.691 131.173 184.24 122.379 186.255 109.921L190.835 80.7922C195.232 53.3119 180.027 41.2205 152.729 41.2205C131.478 41.2205 113.89 50.747 109.127 74.0137H134.409C136.241 65.5864 141.371 60.4568 151.447 60.4568C163.722 60.4568 166.836 66.6856 165.004 77.861L164.454 81.8914H148.699C119.936 81.8914 99.7839 89.7691 99.7839 114.318C99.7839 130.99 112.058 141.249 130.379 141.249ZM139.356 122.929C130.379 122.929 125.982 117.799 125.982 111.57C125.982 101.311 133.31 97.6468 149.432 97.6468H161.889L160.607 105.158C158.958 116.517 150.165 122.929 139.356 122.929Z"
      fill="#228800"
    />
    <path
      d="M192.33 171.844H219.077L290.526 43.4189H263.779L234.1 101.128L220.726 43.4189H193.429L216.512 128.425L192.33 171.844Z"
      fill="#228800"
    />
    <path
      d="M280.41 139.234H337.386C367.065 139.234 386.301 123.478 386.301 99.2956C386.118 83.5402 377.507 74.5633 363.767 71.4489C379.522 67.2352 388.866 56.6095 388.866 39.7549C389.049 20.1523 376.225 8.24411 348.378 8.24411H301.112L280.41 139.234ZM321.447 62.1056L326.76 28.5796H343.615C355.889 28.5796 361.385 32.9764 361.385 43.0525C361.385 55.5103 354.424 62.1056 339.401 62.1056H321.447ZM312.47 118.715L318.516 81.1586H336.103C351.309 81.1586 357.355 86.8379 357.355 97.83C357.355 110.105 349.477 118.715 333.905 118.715H312.47Z"
      fill="#228800"
    />
    <path
      d="M420.776 30.2284C429.203 30.2284 435.798 23.9995 435.798 15.7554C435.798 7.69449 429.386 1.64882 420.959 1.64882C412.532 1.64882 405.936 7.8777 405.936 15.9386C405.936 24.1827 412.348 30.2284 420.776 30.2284ZM388.532 139.234H414.913L430.119 43.4189H403.738L388.532 139.234Z"
      fill="#228800"
    />
    <path d="M429.066 139.234H455.447L477.431 0H451.05L429.066 139.234Z" fill="#228800" />
    <path d="M469.42 139.234H495.801L517.786 0H491.404L469.42 139.234Z" fill="#228800" />
    <path
      d="M547.148 141.432C571.514 141.432 588.185 130.257 588.185 109.738C588.185 91.6011 578.109 84.8227 555.759 80.0594C543.667 77.3114 539.087 74.9297 539.087 69.2505C539.087 63.0216 544.034 59.3575 553.011 59.3575C561.987 59.3575 567.3 64.304 567.85 70.8993H592.033C590.75 49.831 575.361 41.2205 553.743 41.2205C531.942 41.0373 514.172 51.663 514.172 72.3649C514.172 88.1203 523.149 95.998 544.766 100.578C558.507 103.692 562.171 106.074 562.171 112.12C562.171 119.081 557.591 122.746 548.064 122.746C535.606 122.746 531.576 116.883 531.026 109.189H506.294C506.477 129.158 521.317 141.432 547.148 141.432Z"
      fill="#228800"
    />
    <path
      d="M340.396 224.672C350.711 224.672 357.769 216.341 357.769 204.229C357.769 194.937 352.069 189.682 344.468 189.682C339.106 189.682 334.966 191.989 332.523 195.514L335.917 175.263H326.144L318 223.967H327.773L328.723 218.263C330.284 221.66 334.152 224.672 340.396 224.672ZM338.088 217.558C333.066 217.558 330.08 214.611 330.08 209.484C330.08 201.794 334.559 196.667 340.464 196.667C345.011 196.667 347.59 199.615 347.59 204.87C347.59 212.304 343.993 217.558 338.088 217.558Z"
      fill="#717173"
    />
    <path
      d="M358.513 235.374H368.421L394.889 190.451H384.98L373.986 210.637L369.032 190.451H358.92L367.471 220.186L358.513 235.374Z"
      fill="#717173"
    />
    <g clipPath="url(#clip0_241_3955)">
      <path
        d="M511.543 208.585C511.902 208.585 512.205 208.322 512.257 207.967C512.411 206.923 512.487 206.092 512.487 205.006C512.487 202.136 511.952 199.657 510.897 197.17C509.847 194.688 508.679 192.604 506.841 190.724C505.004 188.843 503.05 187.346 500.564 186.268C498.079 185.191 495.839 184.644 492.975 184.644C490.111 184.644 487.398 185.191 484.913 186.268C482.429 187.346 480.237 188.843 478.399 190.724C476.564 192.599 475.039 194.684 473.984 197.17C472.931 199.654 472.397 202.371 472.397 205.241C472.397 208.11 472.931 210.374 473.984 212.886C475.039 215.398 476.503 217.612 478.338 219.459C480.173 221.309 482.363 222.794 484.851 223.873C487.337 224.949 490.049 225.497 492.914 225.497C495.778 225.497 498.963 224.949 501.449 223.873C503.937 222.794 506.127 221.309 507.962 219.459C508.728 218.689 509.429 217.854 510.062 216.961C510.297 216.632 510.214 216.176 509.876 215.951L503.838 211.929C503.502 211.706 503.052 211.805 502.83 212.141C502.452 212.719 502.021 213.253 501.546 213.741C500.567 214.745 499.382 215.542 498.02 216.115C496.657 216.686 494.678 216.975 493.07 216.975H492.883C491.277 216.975 489.77 216.686 488.408 216.115C487.046 215.542 485.859 214.745 484.882 213.741C483.9 212.735 483.118 211.53 482.557 210.162C482.354 209.664 482.183 209.135 482.053 208.585H511.545H511.543ZM482.555 199.455C483.115 198.084 483.898 196.881 484.879 195.873C485.856 194.872 487.044 194.073 488.406 193.502C489.768 192.93 491.275 192.641 492.88 192.641H492.594C494.202 192.641 495.709 192.93 497.071 193.502C498.433 194.073 499.618 194.872 500.598 195.873C501.579 196.881 502.362 198.084 502.922 199.455C503.126 199.953 503.294 200.482 503.426 201.032H482.051C482.181 200.482 482.351 199.953 482.555 199.455V199.455Z"
        fill="#717173"
      />
      <path
        d="M525.892 169.579H518.399C518.002 169.579 517.68 169.899 517.68 170.293V223.246C517.68 223.64 518.002 223.96 518.399 223.96H525.892C526.289 223.96 526.611 223.64 526.611 223.246V170.293C526.611 169.899 526.289 169.579 525.892 169.579Z"
        fill="#717173"
      />
      <path
        d="M539.077 173.861C538.956 173.725 538.816 173.6 538.656 173.516C537.965 173.144 537.173 173.321 536.704 173.847L532.057 179.081C531.639 179.551 531.598 180.232 531.991 180.768C532.244 181.116 532.667 181.297 533.1 181.297H542.671C543.107 181.297 543.532 181.111 543.785 180.761C544.171 180.225 544.128 179.549 543.712 179.081L539.079 173.861H539.077Z"
        fill="#717173"
      />
      <path
        d="M541.631 186.181H534.138C533.741 186.181 533.419 186.501 533.419 186.895V223.246C533.419 223.64 533.741 223.96 534.138 223.96H541.631C542.028 223.96 542.35 223.64 542.35 223.246V186.895C542.35 186.501 542.028 186.181 541.631 186.181Z"
        fill="#717173"
      />
      <path
        d="M452.474 185.245C448.297 185.245 444.617 186.886 441.531 190.127C438.444 186.888 434.764 185.245 430.588 185.245C426.411 185.245 422.464 186.893 419.595 189.887C416.771 192.839 415.215 196.801 415.215 201.048V223.246C415.215 223.64 415.537 223.96 415.934 223.96H423.356C423.753 223.96 424.075 223.64 424.075 223.246V201.048C424.075 199.128 424.718 197.361 425.884 196.07C427.071 194.757 428.743 194.03 430.588 194.03C432.433 194.03 434.102 194.754 435.292 196.07C436.458 197.361 437.101 199.128 437.101 201.048V223.246C437.101 223.64 437.423 223.96 437.82 223.96H445.244C445.641 223.96 445.963 223.64 445.963 223.246V201.048C445.963 199.13 446.606 197.361 447.772 196.07C448.962 194.757 450.631 194.03 452.476 194.03C454.321 194.03 455.991 194.754 457.18 196.07C458.346 197.361 458.989 199.128 458.989 201.048V223.246C458.989 223.64 459.311 223.96 459.708 223.96H467.13C467.527 223.96 467.849 223.64 467.849 223.246V201.048C467.849 196.801 466.293 192.839 463.469 189.887C460.602 186.893 456.698 185.245 452.476 185.245H452.474Z"
        fill="#717173"
      />
      <path
        d="M572.776 230.085H563.205C562.774 230.085 562.348 230.268 562.095 230.613C561.705 231.149 561.743 231.831 562.162 232.301L566.795 237.521C566.915 237.657 567.055 237.781 567.216 237.866C567.906 238.237 568.699 238.061 569.167 237.535L573.814 232.301C574.23 231.833 574.273 231.156 573.887 230.62C573.634 230.268 573.209 230.085 572.774 230.085H572.776Z"
        fill="#717173"
      />
      <path
        d="M586.839 197.114C585.803 194.667 584.358 192.486 582.546 190.634C580.734 188.782 578.575 187.304 576.127 186.244C573.677 185.182 571.002 184.644 568.181 184.644C565.359 184.644 562.682 185.182 560.234 186.244C557.786 187.306 555.625 188.782 553.813 190.634C552.004 192.484 550.561 194.663 549.521 197.114C548.482 199.563 547.957 202.24 547.957 205.069C547.957 207.899 548.482 210.592 549.521 213.067C550.559 215.544 552.004 217.725 553.813 219.549C555.622 221.372 557.782 222.834 560.232 223.897C562.682 224.959 565.357 225.497 568.178 225.497C571 225.497 573.675 224.959 576.125 223.897C578.575 222.834 580.737 221.37 582.546 219.549C584.358 217.723 585.8 215.542 586.839 213.067C587.877 210.59 588.402 207.899 588.402 205.069C588.402 202.24 587.874 199.565 586.836 197.114H586.839ZM557.266 205.069C557.266 203.356 557.526 201.753 558.037 200.305C558.548 198.858 559.276 197.605 560.208 196.583C561.138 195.56 562.289 194.742 563.635 194.15C564.981 193.558 566.511 193.255 568.181 193.255C569.851 193.255 571.378 193.556 572.726 194.15C574.074 194.745 575.226 195.563 576.156 196.583C577.085 197.603 577.814 198.855 578.327 200.305C578.838 201.758 579.098 203.361 579.098 205.069C579.098 206.778 578.84 208.383 578.327 209.833C577.814 211.283 577.083 212.536 576.156 213.556C575.229 214.578 574.074 215.396 572.726 215.988C571.383 216.58 569.853 216.884 568.181 216.884C566.509 216.884 564.979 216.583 563.635 215.988C562.289 215.396 561.135 214.576 560.208 213.556C559.279 212.533 558.548 211.283 558.04 209.833C557.529 208.388 557.269 206.785 557.269 205.069H557.266Z"
        fill="#717173"
      />
    </g>
    <defs>
      <clipPath id="clip0_241_3955">
        <rect width="173.189" height="71.1552" fill="white" transform="translate(415.215 167.8)" />
      </clipPath>
    </defs>
  </Icon>
);
