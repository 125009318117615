import { DeliveryMethodByPayee, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { PaymentReviewLineItem } from '../PaymentReviewLineItem';

export type DeliveryDateListItemWidgetProps = {
  deliveryMethodType: DeliveryMethodByPayee['type'];
  estimatedDelivery: Payment['estimatedDelivery'];
  maxEstimatedDelivery: Payment['maxEstimatedDelivery'];
  children?: never;
};

export const DeliveryDateListItemWidget = forwardRef<DeliveryDateListItemWidgetProps, 'div'>(
  ({ estimatedDelivery, maxEstimatedDelivery, deliveryMethodType, ...props }, ref) => {
    const { formatMessage, formatDateTimeRange } = useMelioIntl();

    return (
      <PaymentReviewLineItem
        data-component="DeliveryDateListItemWidget"
        labelProps={{ label: formatMessage('widgets.deliveryDateListItemByPayee.label') }}
        mainLabelProps={{
          label: formatDateTimeRange(estimatedDelivery, maxEstimatedDelivery, { dateStyle: 'medium' }),
        }}
        descriptionProps={
          deliveryMethodType === 'bank-account'
            ? { label: formatMessage('widgets.deliveryDateListItemByPayee.bankHelperText') }
            : undefined
        }
        icon={{ type: 'calendar-move' }}
        contentTestId="delivery-date-list-item-by-payee"
        {...props}
        ref={ref}
      />
    );
  }
);

DeliveryDateListItemWidget.displayName = 'DeliveryDateListItemWidget';
