import { useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import { AccountLimitations, AccountsLimitationsApiClient, ApiKeys } from '@melio/platform-api-axios-client';

type QueryFunc = (id: string) => ReturnType<typeof AccountsLimitationsApiClient.getAccountsMeLimitations>;

export const useAccountMeLimitations = (props?: UseModelApiProps<QueryFunc>) => {
  const queryClient = useMelioQueryClient();
  const query = useModelApi<QueryFunc, never, never, never, AccountLimitations>({
    id: 'me',
    queryKey: [ApiKeys.AccountsLimitationsApi],
    queryFn: () => AccountsLimitationsApiClient.getAccountsMeLimitations(),
    ...props,
  });

  const invalidateQuery = () => queryClient.invalidateQueries(ApiKeys.AccountsLimitationsApi);

  return {
    ...query,
    invalidateQuery,
  };
};
