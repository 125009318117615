import { LinkOverrides } from '../types';

export const cloverLinksOverrides: LinkOverrides = {
  helpCenter: 'https://paybillsbymelio.zendesk.com/hc/en-us',
  failedToCollectSupport:
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
  'app.settings.SupportSection.accordion.businessBills.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5336673410332-Business-bills-you-can-pay-with-Pay-Bills',
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5322861777308-Add-a-credit-card-to-Pay-Bills',
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5337359120412-Sync-your-QuickBooks-Online-account-with-Pay-Bills',
  'app.settings.SupportSection.accordion.addDebitCard.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5337351341980',
  'app.settings.SupportSection.accordion.verifyMicroDeposits.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5347064421788',
  'app.settings.SupportSection.accordion.addBankAccount.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5337612417820-Add-and-connect-a-bank-account-automatically',
  'app.settings.SupportSection.accordion.addBill.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5322782411164-Adding-a-bill-to-Pay-Bills',
  'app.settings.SupportSection.accordion.payBill.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5337659069596-Paying-a-bill-with-Pay-Bills',
  'app.settings.SupportSection.accordion.deliveryTimelines.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5322875394588-Payment-delivery-timelines',
  'app.settings.SupportSection.accordion.vendors.links.link1.href':
    'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5338439852060-Add-edit-and-delete-a-vendor',
  'widgets.companySettings.header.sectionBanner.linkHref': 'https://www.clover.com/setupapp',
};
