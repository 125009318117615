import { Icon, IconProps } from '@chakra-ui/react';

export const UsBankIcon = (props: IconProps) => (
  <Icon width="147px" height="30px" viewBox="0 0 1976 403" fill="none" {...props}>
    <path d="M44.805 36.686V17.888H12.94v18.798l15.933 5.427z" fill="#cc162b" />
    <path
      d="M28.83 36.078h-4.862v-1.693h-.043c-.955 1.346-2.3 1.997-4.298 1.997-2.388.043-4.515-1.433-4.515-4.428V22.88h5.036v6.946c0 1.52.174 2.605 1.65 2.605.868 0 1.997-.434 1.997-2.56v-7h5.036zm9.42-9.378c-.043-.434-.26-.78-.608-1-.304-.26-.695-.4-1.13-.4-.695 0-1.52.174-1.52 1 0 .4.304.564.608.695.825.347 2.735.478 4.428 1s3.17 1.52 3.17 3.7c0 3.7-3.517 4.645-6.73 4.645-3.126 0-6.512-1.26-6.6-4.645h4.82a1.64 1.64 0 0 0 .608 1.172c.26.217.738.4 1.346.4.65 0 1.693-.26 1.693-1 0-.78-.434-1-2.778-1.4-3.864-.65-5.383-1.867-5.383-4.168 0-3.343 3.603-4.2 6.252-4.2 2.865 0 6.295.78 6.425 4.168h-4.6z"
      fill="#fff"
    />
    <path
      fill="#011E79"
      d="M46.585 17.888h5.036v6.425h.043c.78-1.13 2.127-1.78 3.603-1.78 4.428 0 5.644 3.777 5.644 6.86 0 3.3-1.823 7-5.557 7-2.475 0-3.256-.955-3.864-1.78h-.043v1.433h-4.862zm7.076 8.335c-1.823 0-2.214 1.693-2.214 3.256s.4 3.3 2.214 3.3 2.214-1.693 2.214-3.3c0-1.563-.4-3.256-2.214-3.256zm21.317 6.903c0 1 .043 2.127.608 2.952H70.42c-.13-.347-.217-.912-.174-1.26-1.085 1.216-2.605 1.563-4.255 1.563-2.56 0-4.645-1.216-4.645-3.994 0-4.168 4.906-4.037 7.467-4.515.695-.13 1.4-.347 1.4-1.172 0-.912-.825-1.216-1.65-1.216-1.563 0-1.867.78-1.867 1.346h-4.7c.174-3.734 3.7-4.34 6.816-4.34 6.338 0 6.252 2.605 6.252 5.166v5.47zm-4.82-2.952c-.608.304-1.302.478-1.997.65-1.13.26-1.693.52-1.693 1.433 0 .608.65 1.216 1.563 1.216 1.13 0 2.04-.695 2.127-2.084zM76.8 22.88h4.862v1.693h.043c.955-1.346 2.3-1.997 4.298-1.997 2.388 0 4.558 1.433 4.558 4.472v9.073h-5.036v-6.946c0-1.52-.174-2.605-1.65-2.605-.868 0-1.997.434-1.997 2.56v7h-5.036V22.88zm15.543-4.992h5.036v8.77l3.213-3.82h5.687l-4.7 5.036 5.47 8.25h-6.078L98.3 31.35l-.912.955v3.82H92.34z"
    />
  </Icon>
);
