import { createContext, useContext } from 'react';

import { CreateConfig } from '../../hooks/useActivityConfigData';
import { isPartnerMelio } from '../../shared/partner';

export type ActivityConfig = {
  showPoweredByMelio: boolean;
};

export const createActivityConfig: CreateConfig<ActivityConfig> = () => {
  const isMelio = isPartnerMelio();

  return {
    showPoweredByMelio: !isMelio,
  };
};

export const ShiftVirtualCardToACHActivityConfigContext = createContext<ActivityConfig>({} as ActivityConfig);
export const useActivityContext = () => useContext<ActivityConfig>(ShiftVirtualCardToACHActivityConfigContext);
