import { StatusIconSolidProps } from '@melio/penny';
import { DeliveryMethodType, PaymentDeliveryPreferenceEnum, TrackerTimelineStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { FormattedMessageType } from '../../types';

const getHeaderTitleMessageKeyForInProgressPayment = ({
  deliveryPreference,
  deliveryMethodType,
}: {
  deliveryPreference?: PaymentDeliveryPreferenceEnum | null;
  deliveryMethodType: DeliveryMethodType;
}): FormattedMessageType => {
  if (deliveryPreference === PaymentDeliveryPreferenceEnum.ExpeditedAch) {
    return 'vex.activities.vendorPaymentTracking.header.today.pst';
  } else if (
    deliveryPreference === PaymentDeliveryPreferenceEnum.Rtp ||
    deliveryMethodType === DeliveryMethodType.Card
  ) {
    return 'vex.activities.vendorPaymentTracking.header.today';
  } else {
    return 'vex.activities.vendorPaymentTracking.header.date';
  }
};

export const usePaymentTrackingHeaderText = ({
  trackerStatus,
  deliveryEta,
  deliveryPreference,
  deliveryMethodType,
}: {
  trackerStatus: TrackerTimelineStatusEnum;
  deliveryEta: Date;
  deliveryPreference: PaymentDeliveryPreferenceEnum | null | undefined;
  deliveryMethodType: DeliveryMethodType;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  let title;
  let subtitle;
  let statusIconVariant: StatusIconSolidProps['variant'];
  if (trackerStatus === TrackerTimelineStatusEnum.DEPOSITED) {
    title = formatMessage('vex.activities.vendorPaymentTracking.header.paymentDelivered');
    statusIconVariant = 'success';
  } else if (trackerStatus === TrackerTimelineStatusEnum.FAILED) {
    title = formatMessage('vex.activities.vendorPaymentTracking.header.paymentFailed');
    statusIconVariant = 'cancel';
  } else {
    title = formatMessage(
      getHeaderTitleMessageKeyForInProgressPayment({
        deliveryPreference,
        deliveryMethodType,
      }),
      {
        date: formatDate(deliveryEta, { month: 'long', day: 'numeric' }),
      }
    );
    subtitle = formatMessage('vex.activities.vendorPaymentTracking.header.estimatedDelivery');
    statusIconVariant = 'scheduled';
  }

  return { title, subtitle, statusIconVariant };
};
