export const DinersLogo: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="36" height="24" rx="4" fill="#0079BE" />
    <path
      d="M12.21 12.0014C12.2083 12.8222 12.456 13.6241 12.9203 14.3009C13.3846 14.9778 14.0436 15.4975 14.81 15.7914V8.19141C14.0413 8.48767 13.3808 9.0108 12.9164 9.69132C12.452 10.3718 12.2056 11.1775 12.21 12.0014Z"
      fill="white"
    />
    <path
      d="M17.71 8.19141V15.7714C18.4763 15.477 19.1354 14.9572 19.6003 14.2806C20.0652 13.604 20.3141 12.8024 20.3141 11.9814C20.3141 11.1605 20.0652 10.3588 19.6003 9.68218C19.1354 9.00557 18.4763 8.48578 17.71 8.19141Z"
      fill="white"
    />
    <path
      d="M19.51 5.0018H16.23C15.3019 4.9881 14.3806 5.1618 13.5211 5.51251C12.6617 5.86322 11.8819 6.38373 11.2283 7.04288C10.5748 7.70203 10.061 8.48627 9.71759 9.34865C9.37423 10.211 9.2084 11.1338 9.23002 12.0618C9.24585 13.9079 9.99033 15.673 11.3014 16.9728C12.6124 18.2726 14.3839 19.0019 16.23 19.0018H19.51C21.3892 19.0141 23.2002 18.2982 24.563 17.0043C25.9259 15.7104 26.7348 13.9391 26.82 12.0618C26.8084 11.1168 26.6095 10.1836 26.2349 9.31592C25.8603 8.44829 25.3174 7.66353 24.6377 7.00701C23.9579 6.35049 23.1547 5.83524 22.2746 5.49106C21.3945 5.14687 20.4548 4.98058 19.51 5.0018ZM16.25 18.4118C14.9814 18.4158 13.7401 18.0432 12.6834 17.3413C11.6266 16.6393 10.8019 15.6396 10.3137 14.4687C9.82549 13.2978 9.69569 12.0083 9.94076 10.7636C10.1858 9.51885 10.7947 8.37482 11.6904 7.47637C12.586 6.57791 13.7282 5.96543 14.9721 5.71647C16.2161 5.46751 17.5059 5.59328 18.6784 6.07785C19.8508 6.56241 20.8531 7.38398 21.5583 8.43853C22.2636 9.49308 22.64 10.7332 22.64 12.0018C22.64 13.6984 21.9674 15.3258 20.7697 16.5273C19.5719 17.7288 17.9466 18.4065 16.25 18.4118Z"
      fill="white"
    />
  </svg>
);
