type WithId = {
  id: string | number;
};

export enum UpdateListOnIndividualActionType {
  UPDATE_OR_CREATE = 'update',
  DELETE = 'delete',
}

type UpdateListOnIndividualUpdateParams<T> =
  | {
      type: UpdateListOnIndividualActionType.UPDATE_OR_CREATE;
      updatedIndividual: T;
      oldList?: T[];
    }
  | {
      type: UpdateListOnIndividualActionType.DELETE;
      updatedIndividual: WithId;
      oldList?: T[];
    };

export const updateListOnIndividualUpdate = <T extends WithId>({
  type,
  updatedIndividual,
  oldList,
}: UpdateListOnIndividualUpdateParams<T>) => {
  if (oldList) {
    const index = oldList.findIndex((it) => it.id === updatedIndividual.id);

    if (index >= 0) {
      const updatedArray = [...oldList];
      switch (type) {
        case UpdateListOnIndividualActionType.UPDATE_OR_CREATE:
          updatedArray[index] = updatedIndividual as T;
          return updatedArray;
        case UpdateListOnIndividualActionType.DELETE:
          updatedArray.splice(index, 1);
          return updatedArray;
      }
    } else {
      switch (type) {
        case UpdateListOnIndividualActionType.UPDATE_OR_CREATE:
          return [...oldList, updatedIndividual];
        case UpdateListOnIndividualActionType.DELETE:
          return oldList;
      }
    }
  }
};
