import { Box, BoxProps } from '@chakra-ui/react';
import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { MelioInitialsLogo } from './assets/MelioInitialsLogo';

type PoweredByMelioVendorWidgetProps = {
  color: BoxProps['color'];
};

export const PoweredByMelioVendorWidget = forwardRef<PoweredByMelioVendorWidgetProps, 'div'>(
  ({ color, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group
        variant="horizontal"
        width="full"
        alignItems="center"
        spacing="xs"
        data-component="PoweredByMelioVendorWidget"
        data-testid="PoweredByMelioVendorWidget"
        ref={ref}
        {...props}
      >
        <MelioInitialsLogo fill={color as string} />
        <Box color={color}>
          <Text color="inherit" textStyle="body4">
            {formatMessage('vex.widgets.poweredByMelioFooter.privacyAndTermsLine')}
          </Text>
        </Box>
      </Group>
    );
  }
);

PoweredByMelioVendorWidget.displayName = 'PoweredByMelioVendorWidget';
