import { useNonEntityMutation } from '@melio/api-client';
import { ApiKeys, ApiRequest, PostVexW9RequestResponse, VexGuestW9ApiClient } from '@melio/platform-api-axios-client';

type PostVexGuestW9RequestFormFn = ApiRequest<PostVexW9RequestResponse>;
export const useCollectW9RequestZenworkUrl = (vendorId: string) =>
  useNonEntityMutation<PostVexGuestW9RequestFormFn, string, { data: { link: string } }>(
    () => VexGuestW9ApiClient.postVexGuestW9RequestForm(vendorId),
    [ApiKeys.VexGuestW9Api, 'model', 'request-w9-form', vendorId]
  );

export const useZenworkRequestCompleted = (vendorId: string) =>
  useNonEntityMutation<PostVexGuestW9RequestFormFn, string, never>(
    (vendorId: string) => VexGuestW9ApiClient.postVexGuestW9RequestCompleted(vendorId),
    [ApiKeys.VexGuestW9Api, 'model', 'completed-w9-form', vendorId]
  );
