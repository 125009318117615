import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const SyncAccounts: React.VFC = () => (
  <LazyAnimation
    id="sync-account"
    getAnimationData={() => import('./assets/sync-accounts.lottie')}
    width="100%"
    height="100%"
  />
);
