import { createProvider } from '@melio/i18n-tools';

import messages from './messages.json';
import { MessageKey } from './types';
import { renderers } from './utils';

export type FormatMessage = (id: MessageKey, values?: Record<string, unknown>) => string;

const { LocalizationProvider, useMelioIntl, FormattedMessage } = createProvider(messages, renderers);

export { FormattedMessage, LocalizationProvider, useMelioIntl };
