import { useEffect } from 'react';
import {
  MessageCallback,
  useInitPartnerBridge,
  useListenToEvent as useListenToEventUntyped,
  useSendMessage as useSendMessageUntyped,
} from '@melio/partner-bridge';

export const PostMessageTypes = {
  CONTENT_SIZE_CHANGE: 'CONTENT_SIZE_CHANGE',
  USER_ACTIVE_PING: 'USER_ACTIVE_PING',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
} as const;

type PostMessageTypeType = (typeof PostMessageTypes)[keyof typeof PostMessageTypes];

type sendMessageTyped = (type: PostMessageTypeType, payload: object) => void;
type MessageCallbackTyped = (type: PostMessageTypeType, payload: object) => void;
export function frameSizeChangeHandler(sendMessage: sendMessageTyped) {
  const resizeObserver = new ResizeObserver((entries) => {
    const [entry] = entries;
    if (entry) {
      sendMessage(PostMessageTypes.CONTENT_SIZE_CHANGE, {
        height: entry.target.clientHeight,
        width: entry.target.clientWidth,
      });
    }
  });
  resizeObserver.observe(document.body);
  return () => resizeObserver.disconnect();
}

export function useSendMessage() {
  const sendMessage = useSendMessageUntyped();
  return sendMessage as sendMessageTyped;
}
export function usePartnerBridge() {
  useInitPartnerBridge();
  const sendMessage = useSendMessage();
  useEffect(() => {
    const unlisten = frameSizeChangeHandler(sendMessage);
    return unlisten;
  }, [sendMessage]);
}

export function useListenToEvent(type: PostMessageTypeType, cb: MessageCallbackTyped) {
  return useListenToEventUntyped(type, cb as MessageCallback);
}
