/* eslint-disable react-hooks/exhaustive-deps */
import { ExternalLayout, useToast } from '@melio/penny';
import { BankAccountDeliveryMethod, useDeliveryMethods, usePayment } from '@melio/platform-api';
import { useEffect, useState } from 'react';

import { BankAccountFormModel } from '../../../types';
import { useActivityContext } from '../AddDeliveryMethodByPayee/AddDeliveryMethodByPayee.config';
import { DeliveryMethodAddedSuccessfullyScreen } from '../screens';
import { AddBankDeliveryMethodScreen } from './screens';
import { AddBankDeliveryMethodByPayeeActivityProps } from './types';
import { useAddBankDeliveryMethodByPayeeStep } from './useAddBankDeliveryMethodByPayeeStep';

export const AddBankDeliveryMethodByPayeeActivity: React.VFC<AddBankDeliveryMethodByPayeeActivityProps> = ({
  paymentId,
  accountName,
  paymentNote,
  paymentInvoiceNumber,
  onBack: onFirstStepBack,
  onError,
  onDone,
  userName,
}) => {
  const { data: payment, refetch: refetchPayment, isLoading: isLoadingPayment } = usePayment({ id: paymentId });

  const { create: createVendorDeliveryMethod, isMutating: isCreatingDeliveryMethod } = useDeliveryMethods({
    vendorId: payment?.vendorId,
    enabled: false,
  });

  const { toast } = useToast();

  const [newDeliveryMethod, setNewDeliveryMethod] = useState<BankAccountDeliveryMethod>();

  const { currentStep, goToStep, goToPreviousStep } = useAddBankDeliveryMethodByPayeeStep({ onFirstStepBack });

  const { showPoweredByMelio, showJoinMelioButton, showLoginHeaderSection, emailVerificationReturnUrl } =
    useActivityContext();

  const isLoggedIn = !!userName;

  useEffect(() => {
    if (newDeliveryMethod && payment?.deliveryMethodId === newDeliveryMethod?.id) {
      goToStep('BANK_DETAILS_ADDED_SUCCESSFULLY');
    }
  }, [payment?.deliveryMethodId, newDeliveryMethod?.id]);

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const createDeliveryMethod = (data: BankAccountFormModel) => {
    createVendorDeliveryMethod({
      type: 'bank-account',
      details: {
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        accountType: 'checking',
      },
    })
      .then((deliveryMethod) => {
        setNewDeliveryMethod(deliveryMethod as BankAccountDeliveryMethod);
        /* As a side effect of setting a new delivery method, the payment entity is updated with the new one.
        so we refetch the payment to get the new data */
        refetchPayment().catch(handleFail);
        onDone(deliveryMethod.id);
      })
      .catch(handleFail);
  };

  if (!payment || (isLoadingPayment && !newDeliveryMethod)) {
    return <ExternalLayout isLoading />;
  }

  switch (currentStep) {
    case 'ADD_BANK_DETAILS':
    default:
      return (
        <AddBankDeliveryMethodScreen
          accountName={accountName}
          paymentAmount={payment.amount}
          paymentNote={paymentNote}
          paymentInvoiceNumber={paymentInvoiceNumber}
          onBack={goToPreviousStep}
          onDone={createDeliveryMethod}
          isSaving={isCreatingDeliveryMethod || isLoadingPayment}
          showPoweredByMelio={showPoweredByMelio}
          showLoginHeaderSection={showLoginHeaderSection}
          emailVerificationReturnUrl={emailVerificationReturnUrl}
          userName={userName}
        />
      );

    case 'BANK_DETAILS_ADDED_SUCCESSFULLY':
      return (
        <DeliveryMethodAddedSuccessfullyScreen
          showJoinMelioButton={!isLoggedIn && showJoinMelioButton}
          paymentInvoiceNumber={paymentInvoiceNumber}
          accountName={accountName}
          paymentAmount={payment.amount}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          deliveryMethod={newDeliveryMethod!}
          estimatedDelivery={payment.estimatedDelivery}
          maxEstimatedDelivery={payment.maxEstimatedDelivery}
          showLoginHeaderSection={showLoginHeaderSection}
          showPoweredByMelio={showPoweredByMelio}
          userName={userName}
        />
      );
  }
};
