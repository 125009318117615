import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  ExternalLayoutContentWidget,
  FooterWidget,
  TermsAndPolicyDisclaimerWidget,
  VendorBankDetailsPayeeForm,
  VendorBankDetailsPayeeFormProps,
} from '@melio/vex-widgets';
import { ComponentProps, ReactNode } from 'react';

import { BankAccountFormModel } from '../../../types';

export type AddBankAccountScreenProps = {
  onSubmit: VendorBankDetailsPayeeFormProps['onSubmit'];
  isLoading: boolean;
  defaultValues?: Partial<BankAccountFormModel>;
  onBack: VoidFunction;
  contentHeaderTitle: ReactNode;
  payorOrganizationName: string;
  header: ComponentProps<typeof ExternalLayout>['header'];
};

const analyticsProps = { PageName: 'add-bank-account', DeliveryMethodType: DeliveryMethodType.BankAccount };

export const AddBankAccountScreen = forwardRef<AddBankAccountScreenProps, 'div'>(
  (
    { isLoading, defaultValues, onBack, onSubmit, contentHeaderTitle, payorOrganizationName, children: _, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { track } = useAnalytics();
    usePageView('DeliveryMethod', {
      Intent: 'add-bank-account-details',
      ...analyticsProps,
    });

    const handleBackButtonClick = () => {
      track('DeliveryMethod', 'Click', {
        Intent: 'Go-Back',
        Cta: 'back',
        ...analyticsProps,
      });
      onBack();
    };

    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<BankAccountFormModel>();
    const handleSubmitButtonClick = () => {
      track('DeliveryMethod', 'Click', {
        Intent: 'connect-bank-account',
        Cta: 'add-bank-account',
        ...analyticsProps,
      });
      submitButtonProps?.onClick();
    };

    const contentForm = (
      <VendorBankDetailsPayeeForm
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        onSubmissionStateChange={onSubmissionStateChange}
        size="small"
        columns={2}
      />
    );

    return (
      <ExternalLayout
        data-component="AddBankAccountScreen"
        data-testid="add-bank-account-screen"
        {...props}
        ref={ref}
        content={
          <ExternalLayoutContentWidget
            header={{
              backButtonProps: { isDisabled: cancelButtonProps?.isDisabled, onClick: handleBackButtonClick },
              title: contentHeaderTitle,
            }}
            form={{
              title: formatMessage('vex.screen.addBankAccount.title'),
              fields: contentForm,
            }}
            continueSection={{
              buttonProps: {
                ...submitButtonProps,
                label: formatMessage('vex.screen.addBankAccount.continue'),
                onClick: handleSubmitButtonClick,
                isLoading,
              },
              legalInfoOverride: formatMessage('vex.widgets.legal.infoWithAccountName', {
                accountName: payorOrganizationName,
              }),
              termsAndPolicyDisclaimer: <TermsAndPolicyDisclaimerWidget />,
            }}
          />
        }
        footer={<FooterWidget />}
      />
    );
  }
);

AddBankAccountScreen.displayName = 'AddBankAccountScreen';
