/* eslint-disable react-hooks/exhaustive-deps */
import { StatusModal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { useEffect } from 'react';

export type CardValidationModalScreenProps = {
  isOpen: boolean;
  onDone: VoidFunction;
};

export const CardValidationModalScreen = ({ isOpen, onDone }: CardValidationModalScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      track('PushToDebitCardNotSupported', 'View');
    }
  }, [isOpen]);

  return (
    <StatusModal
      data-testid="push-to-debit-activity-card-validation-modal-screen"
      variant="warning"
      header={formatMessage('activities.pushToDebit.screens.cardValidation.title')}
      isOpen={isOpen}
      onClose={onDone}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.pushToDebit.screens.cardValidation.continue'),
        onClick: () => {
          track('PushToDebitCardNotSupportedApproved', 'Chose');
          onDone();
        },
      }}
    >
      {formatMessage('activities.pushToDebit.screens.cardValidation.content')}
    </StatusModal>
  );
};

CardValidationModalScreen.displayName = 'PushToDebitActivity.CardValidationModalScreen';
