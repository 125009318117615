import { CardExpirationDateElement as ICardExpirationDateElement } from '@basis-theory/basis-theory-react/types';
import { forwardRef } from 'react';

import { TBTFormFieldWidget } from '../TBTFormField';
import { TBTFormFieldWidgetProps } from '../TBTFormField/TBTFormField.widget';

export type TBTExpirationDateFieldWidgetProps = Omit<TBTFormFieldWidgetProps, 'elementType'>;

export const TBTExpirationDateFieldWidget = forwardRef<ICardExpirationDateElement, TBTExpirationDateFieldWidgetProps>(
  (props, ref) => (
    // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <TBTFormFieldWidget
      data-component="TBTExpirationDateFieldWidget"
      {...props}
      elementType="expirationDate"
      ref={ref}
    />
  )
);

TBTExpirationDateFieldWidget.displayName = 'TBTExpirationDateFieldWidget';
