import { Card, Container, ExternalLayout, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { FormattedMessage } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { FooterWidget } from '@melio/vex-widgets';

type DeliveryMethodExpiredScreenProps = {
  accountName: string;
  accountEmail: string;
  isLoading?: boolean;
};

export const DeliveryMethodExpiredScreen = forwardRef<DeliveryMethodExpiredScreenProps, 'div'>(
  ({ accountName, accountEmail, children, ...props }, ref) => {
    useAnalyticsView('UnilateralPaymentExpiredScreen');

    const content = (
      <Card paddingX="none" paddingY="none">
        <Container paddingX="xl" paddingY="l">
          <Group variant="vertical" spacing="s">
            <StatusIconSolid variant="cancel" size="large" />
            <Text>
              <FormattedMessage
                id="activities.addDeliveryMethodByUnilateral.screens.deliveryMethodExpired.content"
                values={{
                  accountName,
                  accountEmail,
                }}
              />
            </Text>
          </Group>
        </Container>
      </Card>
    );

    const footer = <FooterWidget displayTrustedPartners={false} />;

    return (
      <ExternalLayout
        data-component="AddDeliveryMethodByUnilateralActivity.DeliveryMethodExpiredScreen"
        data-testid="add-delivery-method-by-unilateral-activity-delivery-method-expired-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

DeliveryMethodExpiredScreen.displayName = 'AddDeliveryMethodByUnilateralActivity.DeliveryMethodExpiredScreen';
