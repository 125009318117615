import { Link } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  FeeCatalogValueTypeEnum,
  PaymentUpgradeOfferOption,
  PaymentUpgradeOfferOptionEnum,
  VendorPersistentFastEligibilityTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PersistentFastLabels } from '../components/types';
import { ANALYTICS_CONTEXT } from '../consts';
import { useActivityContext } from '../PaymentUpgrade.config';
import { getUpgradeOfferByType } from '../utils';

type UsePersistentFastLabelsProps = {
  paymentUpgradeOffers: PaymentUpgradeOfferOption[];
  persistentFastEligibility: VendorPersistentFastEligibilityTypeEnum | undefined;
  payorOrgName: string;
};

export const usePersistentFastLabels = ({
  paymentUpgradeOffers,
  persistentFastEligibility,
  payorOrgName,
}: UsePersistentFastLabelsProps): PersistentFastLabels => {
  const { track } = useAnalytics();
  const { formatMessage, formatPercents, formatCurrency } = useMelioIntl();
  const { persistentFastOptOutArticleUrl } = useActivityContext();
  const rtpUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Rtp);
  const fachUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Fach);
  const paymentUpgradeOffer = rtpUpgradeOffer ?? fachUpgradeOffer;

  const { feeCatalog, calculatedFee, offerType } = paymentUpgradeOffer ?? {};

  const formattedFeeAmount =
    calculatedFee && feeCatalog
      ? feeCatalog.valueType === FeeCatalogValueTypeEnum.Percent
        ? formatPercents(feeCatalog.value, { divide: true })
        : formatCurrency(calculatedFee)
      : undefined;

  const offerSupportLink = (
    <Link
      newTab
      color="inherit"
      variant="inline"
      href={persistentFastOptOutArticleUrl}
      label={formatMessage(
        'vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.text.supportLink'
      )}
      onClick={() => track(ANALYTICS_CONTEXT, 'Click', { Intent: 'click-learn-how-kb-article' })}
    />
  );

  const isEligibleForGlobalPersistentFast =
    persistentFastEligibility === VendorPersistentFastEligibilityTypeEnum.GLOBAL;

  const offerTitle = formatMessage(
    `vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.preferencePromo.${
      isEligibleForGlobalPersistentFast ? 'global' : 'local'
    }.${offerType === PaymentUpgradeOfferOptionEnum.Rtp ? 'RTP' : 'FACH'}.h1`,
    isEligibleForGlobalPersistentFast ? undefined : { payorOrgName }
  );

  const offerDescription = formatMessage(
    `vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.preferencePromo.${
      offerType === PaymentUpgradeOfferOptionEnum.Rtp ? 'RTP' : 'FACH'
    }.text`,
    {
      feeAmount: formattedFeeAmount,
      supportLink: offerSupportLink,
    }
  );

  const successSupportLink = (
    <Link
      newTab
      variant="inline"
      href={persistentFastOptOutArticleUrl}
      label={formatMessage(
        'vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.successBanner.supportLink'
      )}
      onClick={() => track(ANALYTICS_CONTEXT, 'Click', { Intent: 'click-learn-how-kb-article' })}
    />
  );

  const successTitle = formatMessage(
    'vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.successBanner.h1'
  );

  const successFeeMessage = formattedFeeAmount
    ? formatMessage(
        'vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.successBanner.feeMessage',
        { feeAmount: formattedFeeAmount }
      )
    : '';

  const successDescription = formatMessage(
    `vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.preferencePromo.${
      isEligibleForGlobalPersistentFast ? 'global' : 'local'
    }.successBanner.${offerType === PaymentUpgradeOfferOptionEnum.Rtp ? 'RTP' : 'FACH'}.guest.text`,
    {
      supportLink: successSupportLink,
      feeMessage: successFeeMessage,
      ...(isEligibleForGlobalPersistentFast ? {} : { payorOrgName }),
    }
  );

  return {
    offer: {
      title: offerTitle,
      description: offerDescription,
    },
    success: {
      title: successTitle,
      description: successDescription,
    },
  };
};
