import { isNumber, join, split, take } from 'lodash';
import { parse } from 'qs';
import { Location } from 'react-router-dom';

import { ExternalNavRecovery, LocationState, PersistedState, UseWizardArgs } from './types';

export const getLog =
  (isVerbose: boolean) =>
  (msg: string, ...args: unknown[]) => {
    if (isVerbose) {
      // eslint-disable-next-line no-console
      console.log(`%cuseWizard: ${msg}`, 'color: #737cdd', ...args);
    }
  };

export const getQueryExternalNavRecoveryTarget = <Steps extends string>(
  location: Location,
  locationsMap: UseWizardArgs<Steps>['locationsMap']
): null | ExternalNavRecovery<Steps> => {
  const { queryExternalNavRecoveryStep, shouldReplace } = parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as {
    queryExternalNavRecoveryStep: Steps;
    shouldReplace: string;
  };

  return queryExternalNavRecoveryStep && locationsMap[queryExternalNavRecoveryStep]
    ? { recoveryTarget: queryExternalNavRecoveryStep, shouldReplace }
    : null;
};

export const getCurrentStepIndex = (location: Location, flowName: string): number => {
  const indexFromLocation = (location.state as LocationState)?.[flowName]?.stepIndex;

  return isNumber(indexFromLocation) ? indexFromLocation : -1;
};

export const getPersistedState = <Steps extends string>(
  flowName: string,
  resetNavigation: () => void
): PersistedState<Steps> | null => {
  try {
    const persistedStateString = window.sessionStorage.getItem(flowName);

    if (persistedStateString) {
      const persistedState = JSON.parse(persistedStateString) as PersistedState<Steps>;

      return persistedState;
    }

    resetNavigation();
  } catch (e) {
    resetNavigation();
  }

  return null;
};

export const buildTargetUrl = (currentStepLocation: string, newStepLocation: string, pathname: string) => {
  const sanitizedCurrentStepLocation = currentStepLocation.replace('/', '');
  const replaceCurrentStepWithNewStep = (pathParts: string[]): string[] => {
    const currentStepPathIndex = pathParts.indexOf(sanitizedCurrentStepLocation);
    const partsToKeep = sanitizedCurrentStepLocation ? currentStepPathIndex : pathParts.length;
    return [...take(pathParts, partsToKeep), newStepLocation];
  };

  const pathParts = split(pathname, '/');
  return join(replaceCurrentStepWithNewStep(pathParts), '/');
};
