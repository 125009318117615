export const Blocked: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11828_10812)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
        fill="#273B49"
      />
      <mask
        id="mask0_11828_10812"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11828_10812)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110 72C110 92.9868 92.9868 110 72 110C51.0132 110 34 92.9868 34 72C34 51.0132 51.0132 34 72 34C92.9868 34 110 51.0132 110 72ZM100 71.9994C100 87.4634 87.4641 99.9994 72.0001 99.9994C66.7094 99.9994 61.7613 98.532 57.5409 95.982L95.9827 57.5402C98.5327 61.7607 100 66.7087 100 71.9994ZM86.5778 48.0888C82.3316 45.4945 77.3404 43.9994 72.0001 43.9994C56.5361 43.9994 44.0001 56.5355 44.0001 71.9994C44.0001 77.3397 45.4952 82.3309 48.0895 86.5771L86.5778 48.0888Z"
          fill="#D03027"
        />
        <path
          d="M57.5409 95.982L56.1266 94.5678L54.3211 96.3733L56.5066 97.6938L57.5409 95.982ZM95.9827 57.5402L97.6945 56.5059L96.374 54.3204L94.5685 56.126L95.9827 57.5402ZM86.5778 48.0888L87.992 49.503L89.7884 47.7067L87.6205 46.3822L86.5778 48.0888ZM48.0895 86.5771L46.3829 87.6199L47.7074 89.7877L49.5037 87.9913L48.0895 86.5771ZM72 112C94.0914 112 112 94.0914 112 72H108C108 91.8823 91.8823 108 72 108V112ZM32 72C32 94.0914 49.9086 112 72 112V108C52.1178 108 36 91.8823 36 72H32ZM72 32C49.9086 32 32 49.9086 32 72H36C36 52.1178 52.1178 36 72 36V32ZM112 72C112 49.9086 94.0914 32 72 32V36C91.8823 36 108 52.1178 108 72H112ZM72.0001 101.999C88.5687 101.999 102 88.568 102 71.9994H98.0001C98.0001 86.3588 86.3595 97.9994 72.0001 97.9994V101.999ZM56.5066 97.6938C61.0303 100.427 66.3347 101.999 72.0001 101.999V97.9994C67.0841 97.9994 62.4924 96.637 58.5751 94.2702L56.5066 97.6938ZM94.5685 56.126L56.1266 94.5678L58.9551 97.3962L97.3969 58.9544L94.5685 56.126ZM102 71.9994C102 66.334 100.428 61.0296 97.6945 56.5059L94.2709 58.5744C96.6377 62.4917 98.0001 67.0834 98.0001 71.9994H102ZM87.6205 46.3822C83.0693 43.6014 77.7187 41.9994 72.0001 41.9994V45.9994C76.9621 45.9994 81.5939 47.3875 85.5351 49.7955L87.6205 46.3822ZM72.0001 41.9994C55.4316 41.9994 42.0001 55.4309 42.0001 71.9994H46.0001C46.0001 57.64 57.6407 45.9994 72.0001 45.9994V41.9994ZM42.0001 71.9994C42.0001 77.718 43.6021 83.0686 46.3829 87.6199L49.7962 85.5344C47.3882 81.5932 46.0001 76.9615 46.0001 71.9994H42.0001ZM49.5037 87.9913L87.992 49.503L85.1636 46.6746L46.6753 85.1629L49.5037 87.9913Z"
          fill="#273B49"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11828_10812">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
