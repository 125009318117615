import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformBillLabelsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatformsBillLabelsById
> & {
  accountingPlatformId?: string;
  noInvalidations?: boolean;
};

export const useAccountingPlatformBillLabels = (props: UseAccountingPlatformBillLabelsProps) => {
  const { accountingPlatformId, noInvalidations } = props;
  const meta = { noInvalidations };

  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatformsBillLabelsById>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'billLabels', accountingPlatformId],
    queryFn: () => AccountingPlatformsApiClient.getAccountingPlatformsBillLabelsById(accountingPlatformId as string),
    enabled: !!accountingPlatformId && props.enabled,
    meta,
  });

  return {
    ...query,
    hasAccountingPlatformBillLabels: !!query.data?.length,
  };
};
