import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const Bank: React.VFC = () => (
  <LazyAnimation
    id="bank"
    width="100%"
    height="100%"
    getAnimationData={() => import('./assets/bank.lottie')}
    loop={false}
  />
);
