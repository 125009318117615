import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const CustomerAdd: React.VFC = () => (
  <LazyAnimation
    id="customer-add"
    getAnimationData={() => import('./assets/customer-add.lottie')}
    width="100%"
    height="100%"
  />
);
