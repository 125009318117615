/* eslint-disable react-hooks/exhaustive-deps */
import { ExternalLayout, useToast } from '@melio/penny';
import { PaperCheckDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';
import { createAddressLabel, useAddressAutocomplete } from '@melio/vex-widgets';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { CheckAccountFormModel } from '../../../types';
import { useActivityContext } from '../../add-delivery-method-by-unilateral/AddDeliveryMethodByUnilateral.config';
import { DeliveryMethodAddedSuccessfullyWithoutPaymentScreen } from '../screens';
import {
  AddCheckAddressManuallyScreen,
  AddCheckDeliveryMethodScreen,
  AddressSuggestionsScreen,
  InvalidAddressConfirmationScreen,
} from './screens';
import {
  AddCheckDeliveryMethodByVendorActivityProps,
  CheckDetailsWithAddressSuggestions,
  CheckDetailsWithAddressSuggestionsKey,
} from './types';
import { useAddCheckDeliveryMethodByPayeeStep } from './useAddCheckDeliveryMethodByPayeeStep';

export const AddCheckDeliveryMethodByVendorActivity: React.VFC<AddCheckDeliveryMethodByVendorActivityProps> = ({
  vendorId,
  accountName,
  onBack: onFirstStepBack,
  onError,
  onDone,
}) => {
  const { data: vendor, isLoading: isLoadingVendor } = useVendor({ id: vendorId });
  const { create: createVendorDeliveryMethod, isMutating: isCreatingDeliveryMethod } = useDeliveryMethods({
    vendorId,
    enabled: false,
  });

  const { toast } = useToast();

  const [newDeliveryMethod, setNewDeliveryMethod] = useState<PaperCheckDeliveryMethod>();

  const [checkDetailsWithAddressSuggestions, setCheckDetailsWithAddressSuggestions] =
    useState<CheckDetailsWithAddressSuggestions>({
      checkDetailsWithSuggestedAddress: undefined,
      checkDetailsWithOriginalAddress: { line1: '', line2: '', city: '', state: '', postalCode: '', printName: '' },
    });

  const { currentStep, goToStep, goToPreviousStep, goToManuallyAddressFormStep } = useAddCheckDeliveryMethodByPayeeStep(
    { onFirstStepBack }
  );

  const { addressAutocomplete } = useAddressAutocomplete();

  const { showPoweredByMelio, showNewAddressFlow, showLoginHeaderSection, emailVerificationReturnUrl } =
    useActivityContext();

  useEffect(() => {
    if (newDeliveryMethod) {
      goToStep('CHECK_DETAILS_ADDED_SUCCESSFULLY');
    }
  }, [newDeliveryMethod?.id]);

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const createDeliveryMethod = (data: CheckAccountFormModel) => {
    const { printName, ...address } = data;

    createVendorDeliveryMethod({
      type: 'paper-check',
      details: {
        printName,
        address,
      },
    })
      .then((deliveryMethod) => {
        setNewDeliveryMethod(deliveryMethod as PaperCheckDeliveryMethod);
        onDone(deliveryMethod.id);
      })
      .catch(handleFail);
  };
  const handleManuallyAddressVerification = (checkDetailsWithOriginalAddress: CheckAccountFormModel) => {
    const { printName, line2, ...originalAddress } = checkDetailsWithOriginalAddress;
    const addressQuery = createAddressLabel(originalAddress);
    addressAutocomplete(addressQuery)
      .then(([addressSuggestions]) => {
        const getAddressSuggestions = () => {
          if (!addressSuggestions) {
            return undefined;
          }
          return {
            printName,
            ...addressSuggestions,
            line2,
          };
        };
        setCheckDetailsWithAddressSuggestions({
          checkDetailsWithSuggestedAddress: getAddressSuggestions(),
          checkDetailsWithOriginalAddress,
        });
        if (isEqual(checkDetailsWithOriginalAddress, getAddressSuggestions())) {
          return createDeliveryMethod(checkDetailsWithOriginalAddress);
        }
        getAddressSuggestions() ? goToStep('ADDRESS_SUGGESTIONS') : goToStep('INVALID_ADDRESS_CONFIRMATION');
      })
      .catch(handleFail);
  };

  const createDeliveryMethodWithOriginalAddress = () => {
    createDeliveryMethod(checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress);
  };
  const createDeliveryMethodWithSelectedAddress = ({ key }: CheckDetailsWithAddressSuggestionsKey) => {
    createDeliveryMethod(checkDetailsWithAddressSuggestions[key] as CheckAccountFormModel);
  };

  if ((isLoadingVendor && !newDeliveryMethod) || !vendor) {
    return <ExternalLayout isLoading />;
  }
  switch (currentStep) {
    case 'ADD_CHECK_ADDRESS_MANUALLY':
      return (
        <AddCheckAddressManuallyScreen
          addressValues={checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress}
          accountName={accountName}
          onBack={goToPreviousStep}
          onDone={handleManuallyAddressVerification}
          isSaving={isCreatingDeliveryMethod}
        />
      );
    case 'ADDRESS_SUGGESTIONS':
      return (
        <AddressSuggestionsScreen
          onEditAddressClicked={goToManuallyAddressFormStep}
          checkDetailsWithAddressSuggestions={checkDetailsWithAddressSuggestions}
          accountName={accountName}
          onBack={goToPreviousStep}
          onDone={createDeliveryMethodWithSelectedAddress}
          isSaving={isCreatingDeliveryMethod}
        />
      );
    case 'INVALID_ADDRESS_CONFIRMATION':
      return (
        <InvalidAddressConfirmationScreen
          onEditAddressClicked={goToManuallyAddressFormStep}
          accountName={accountName}
          checkDetailsWithOriginalAddress={checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress}
          onBack={goToPreviousStep}
          onDone={createDeliveryMethodWithOriginalAddress}
          isLoading={isCreatingDeliveryMethod}
        />
      );
    case 'CHECK_DETAILS_ADDED_SUCCESSFULLY':
      return (
        <DeliveryMethodAddedSuccessfullyWithoutPaymentScreen
          accountName={accountName}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          deliveryMethod={newDeliveryMethod!}
        />
      );
    case 'ADD_CHECK_DETAILS':
    default:
      return (
        <AddCheckDeliveryMethodScreen
          accountName={accountName}
          showNewAddressFlow={showNewAddressFlow}
          onAddressNotFound={goToManuallyAddressFormStep}
          onBack={goToPreviousStep}
          onDone={createDeliveryMethod}
          isSaving={isCreatingDeliveryMethod || isLoadingVendor}
          showPoweredByMelio={showPoweredByMelio}
          showLoginHeaderSection={showLoginHeaderSection}
          emailVerificationReturnUrl={emailVerificationReturnUrl}
        />
      );
  }
};
