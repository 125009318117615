import { Container, ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import {
  ExternalLayoutContentWidget,
  PoweredByMelioFooterWidget,
  PushToDebitHeaderWidget,
  PushToDebitHeaderWidgetProps,
  TBTFormWidget,
  TBTFormWidgetFields,
  TBTFormWidgetProps,
} from '@melio/vex-widgets';
import React from 'react';

export type TBTCreateCardDetailsScreenProps = PushToDebitHeaderWidgetProps & {
  onDone: TBTFormWidgetProps['onSubmit'];
  onError?: ErrorFunction;
  isSaving?: boolean;
};

export const TBTCreateCardDetailsScreen: React.VFC<TBTCreateCardDetailsScreenProps> = forwardRef<
  TBTCreateCardDetailsScreenProps,
  'div'
>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      feePercentValue,
      calculatedFee,
      onDone,
      onError,
      isSaving,
      children,
      ...props
    },
    ref
  ) => {
    const [isLoading, loading] = useBoolean(true);
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useBoolean(false);

    useAnalyticsView('PushToDebitAddCardDetails', !isLoading);

    const { formatMessage } = useMelioIntl();

    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<TBTFormWidgetFields>();

    const content = (
      <ExternalLayoutContentWidget
        header={{
          title: (
            <PushToDebitHeaderWidget
              accountName={accountName}
              paymentAmount={paymentAmount}
              paymentInvoiceNumber={paymentInvoiceNumber}
              paymentNote={paymentNote}
              feePercentValue={feePercentValue}
              calculatedFee={calculatedFee}
            />
          ),
        }}
        form={{
          title: formatMessage('activities.pushToDebit.screens.createCardDetails.title'),
          description: formatMessage('activities.pushToDebit.screens.createCardDetails.subTitle'),
          fields: (
            <Container paddingTop="m">
              <TBTFormWidget
                onSubmit={onDone}
                onSubmissionStateChange={onSubmissionStateChange}
                onRenderError={setSubmitButtonDisabled.on}
                onReady={loading.off}
                isSaving={isSaving}
                size="small"
              />
            </Container>
          ),
        }}
        continueSection={{
          buttonProps: {
            ...submitButtonProps,
            isDisabled: isSubmitButtonDisabled || submitButtonProps?.isDisabled,
            label: formatMessage(`activities.pushToDebit.screens.createCardDetails.continue`),
          },
        }}
      />
    );

    const footer = <PoweredByMelioFooterWidget />;

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.TBTCreateCardDetailsScreen"
        data-testid="tbt-push-to-debit-activity-create-card-details-screen"
        ref={ref}
        {...props}
        content={content}
        footer={footer}
      />
    );
  }
);
TBTCreateCardDetailsScreen.displayName = 'PushToDebitActivity.TBTCreateCardDetailsScreen';
