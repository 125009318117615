import {
  CreatePaymentParameters,
  CreatePaymentsBulkRequestParameters,
  PaymentsApiInstance,
  UpdatePaymentPayload,
} from '@melio/platform-api-axios-client';
import { convertCentsToDollars, convertDateTimeToDateString, convertDollarsToCents } from '@melio/platform-utils';

import { Payment, PaymentsListParams } from './types';

export const getPayments = async (params: PaymentsListParams = {}) => {
  const { cursor, expand, limit, paymentIntentId, search, searchTerm, sort, pendingCurrentUserApproval } = params;
  return PaymentsApiInstance.getPayments({
    paymentIntentId,
    expand: expand ?? 'none',
    searchTerm,
    search: JSON.stringify(search),
    sort,
    limit,
    cursor,
    pendingCurrentUserApproval,
  });
};

type BasePayment = Pick<Payment, 'amount' | 'paymentBillsInfo'>;
type BaseBill = {
  amount: number;
  balance: number;
  payments?: BasePayment[];
};

type PaymentWithAmounts<T extends BaseBill = BaseBill> = Pick<Payment, 'amount' | 'paymentBillsInfo' | 'currency'> & {
  bills?: T[];
};

export const convertPaymentsCentsToDollars = (data: PaymentWithAmounts[]) => {
  const convertedData = data.map(convertPaymentCentsToDollars);

  return convertedData;
};

export const convertPaymentCentsToDollars = (data: PaymentWithAmounts): Payment =>
  convertCentsToDollars(
    data,
    [
      'amount',
      'foreignAmount',
      'bills[].amount',
      'bills[].balance',
      'bills[].payments[].amount',
      'loan.installments[].amount',
      'loan.remainingAmount',
      'loan.nextInstallmentAmount',
      'loan.fees.financingTotalFee',
      'loan.fees.totalAmount',
      'subscriptionOccurrence.billSubscription.amount',
      'subscriptionOccurrence.billSubscription.lastAmount',
      'fees[].amount',
    ],
    data.currency
  );

const convertCreateParamsDollarsToCents = (data: CreatePaymentParameters) =>
  convertDollarsToCents(data, ['paymentBillsInfo[].paymentAmount']);

export const prepareBatchCreatePayload = (
  data?: CreatePaymentsBulkRequestParameters,
  convertCurrency = true
): CreatePaymentsBulkRequestParameters | void =>
  data && {
    payments: prepareCreatePaymentDatesBulk(data.payments).map((item) =>
      prepareCreatePaymentDates(convertCurrency ? convertCreateParamsDollarsToCents(item) : item)
    ),
  };

const prepareCreatePaymentDatesBulk = <T extends { scheduledDate?: string; deliveryDate?: string | Date }>(data: T[]) =>
  data.map(prepareCreatePaymentDates);

const prepareCreatePaymentDates = <T extends { scheduledDate?: string | Date; deliveryDate?: string | Date }>(
  data: T
) => convertDateTimeToDateString(data, ['scheduledDate', 'deliveryDate']);

export const prepareCreateParams = (data: CreatePaymentParameters, convertCurrency = true) => {
  const preparedData = convertCurrency ? convertCreateParamsDollarsToCents(data) : data;
  return prepareCreatePaymentDates(preparedData);
};

const convertUpdateParamsDollarsToCents = (data: UpdatePaymentPayload) => convertDollarsToCents(data, ['amount']);

export const prepareUpdateParams = (data: UpdatePaymentPayload) => {
  const preparedData = convertUpdateParamsDollarsToCents(data);
  return prepareCreatePaymentDates(preparedData);
};
