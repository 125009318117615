import { Button, Form, Group, Link, SectionBanner, Text, useMelioForm } from '@melio/penny';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { EmailLoginInputModel, FormWidgetProps } from '../../types';
import { useEmailValidationSchema } from './useEmailValidationSchema';

export type EmailLoginFormProps = FormWidgetProps<EmailLoginInputModel> & {
  errorMessage?: string;
  onBack: VoidFunction;
};

const messagesPath = 'vex.widgets.login.email';

export const EmailLoginForm = forwardRef<EmailLoginFormProps, 'div'>(
  (
    { onSubmit, isSaving, defaultValues: _defaultValues, onSubmissionStateChange, errorMessage, onBack, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const defaultValues = defaults(_defaultValues, {
      email: '',
    });

    const { registerField, formProps, submitButtonProps } = useMelioForm<EmailLoginInputModel>({
      onSubmit,
      schema: useEmailValidationSchema(),
      onSubmissionStateChange,
      defaultValues,
      isSaving,
    });

    return (
      <Group
        variant="vertical"
        spacing="xl"
        data-component="EmailLoginForm"
        data-testid="EmailLoginForm"
        ref={ref}
        {...props}
      >
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing="xs">
            <Text textStyle="heading3Semi">{formatMessage(`${messagesPath}.title`)}</Text>
            <Text>{formatMessage(`${messagesPath}.subtitle`)}</Text>
          </Group>

          <Form size="small" {...formProps}>
            <Form.TextField
              labelProps={{ label: formatMessage(`${messagesPath}.input.label`) }}
              {...registerField('email')}
              columns={2}
            />
          </Form>

          {errorMessage && (
            <SectionBanner
              data-testid="EmailLoginFrom.form-error-email"
              variant="critical"
              title={formatMessage(`${messagesPath}.invalidEmailError.title`)}
              description={
                <Text>
                  <FormattedMessage id={`${messagesPath}.invalidEmailError.message`} />
                  <Link label={formatMessage(`${messagesPath}.invalidEmailError.link`)} href="#" onClick={onBack} />
                </Text>
              }
            />
          )}
        </Group>
        <Button
          data-testid="layout-next-button"
          {...submitButtonProps}
          label={formatMessage(`${messagesPath}.button`)}
          size="large"
          isFullWidth
        />
      </Group>
    );
  }
);

EmailLoginForm.displayName = 'EmailLoginForm';
