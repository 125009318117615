import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const Processing: React.VFC = () => (
  <LazyAnimation
    id="processing"
    getAnimationData={() => import('./assets/processing.lottie')}
    width="100%"
    height="100%"
  />
);
