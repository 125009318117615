import { Icon, IconProps } from '@chakra-ui/react';

export const backIcon = (props: IconProps) => (
  <Icon width={{ xs: '10px', s: '7px' }} height={{ xs: '10px', s: '14px' }} viewBox="0 0 7 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66659 13.6669C6.66659 13.7582 6.62926 13.8482 6.55592 13.9142C6.41926 14.0382 6.20859 14.0269 6.08526 13.8902L0.0852559 7.2289C-0.0287441 7.1009 -0.0287441 6.9089 0.0852559 6.7829L6.08526 0.1109C6.20859 -0.0264335 6.41926 -0.0377668 6.55592 0.0855665C6.69326 0.208233 6.70392 0.420233 6.58059 0.556233L0.781922 7.00557L6.58059 13.4436C6.63792 13.5076 6.66659 13.5876 6.66659 13.6669Z"
      fill="#02121F"
    />
  </Icon>
);
