/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { MessageKey } from '@melio/platform-i18n';
import { FormattedMessage as FormattedMessagePlatform, useMelioIntl } from '@melio/platform-provider';

export type FormatMessage = (id: MessageKey, values?: Record<string, unknown>) => string;

const formatPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  const [, partOne, partTwo, partThree] = phone.match(/(\d{1,3})(\d{0,3})(\d*)/) || [];
  return `(${partOne}) ${partTwo}${partThree ? '-' + partThree : ''}`;
};

export const usePlatformIntl = () => {
  const { formatMessage, ...rest } = useMelioIntl();

  const formatMessagePlatform = useCallback<FormatMessage>(
    (id: MessageKey, values) => formatMessage(id as MessageKey, { ...values, br: <br /> }),
    [formatMessage],
  );

  const formatDateWithTime = useCallback(
    (date: Date) =>
      `${rest.formatDate(date, { dateStyle: 'long' })}, ${rest.formatDate(date, {
        timeStyle: 'short',
      })}`,
    [rest.formatDate],
  );

  return {
    formatMessage: formatMessagePlatform,
    formatPhone,
    formatDateWithTime,
    ...rest,
  };
};

export type FormattedMessageType = {
  id: MessageKey;
  values?: Record<string, unknown>;
};

export const FormattedMessage = ({ id, values }: FormattedMessageType) => {
  return <FormattedMessagePlatform id={id as MessageKey} values={{ ...values, br: <br /> }} />;
};
