import { useNonEntityMutation } from '@melio/api-client';
import {
  ApiKeys,
  PostVendorsVendorIdDeliveryMethodsResponse,
  PostVexGuestDeliveryMethodRequest,
  VexGuestDeliveryMethodsApiClient,
} from '@melio/platform-api-axios-client';

type CreateFunction = typeof VexGuestDeliveryMethodsApiClient.postVexGuestDeliveryMethod;

export const useVexGuestDeliveryMethod = () =>
  useNonEntityMutation<CreateFunction, PostVexGuestDeliveryMethodRequest, PostVendorsVendorIdDeliveryMethodsResponse>(
    (params) => VexGuestDeliveryMethodsApiClient.postVexGuestDeliveryMethod(params),
    [ApiKeys.VexGuestDeliveryMethodsApi, 'collection', 'default']
  );
