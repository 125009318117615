import { Flex } from '@chakra-ui/react';
import { LinearProgress, LinearProgressProps } from '@melio/penny';

export const ProgressBar = (props: LinearProgressProps) => (
  <Flex gridArea="header-progressBar" w="full" justifyContent="center">
    <LinearProgress
      data-component="NewSinglePaymentStepLayout.ProgressBar"
      data-testid="layout-progressBar-button"
      {...props}
    />
  </Flex>
);

ProgressBar.displayName = 'NewSinglePaymentStepLayout.ProgressBar';
