import { Button, Group, Link, SectionBanner, Text } from '@melio/penny';
import { Account, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { useState } from 'react';

import { VendorOnboardingCardsEnum } from '../../../shared';
import { LocalizationKey } from '../../../types';
import { FormDetailsReviewCard } from '../components/ReviewDetailsSectionCard.component';
import { DetailsSectionWrapper } from '../components/ReviewDetailsSectionWrapper.component';
import { orderedOnboardingCardsToReview, ReviewDetailsErrorTypes, sectionTitlesKeysMap } from '../consts';
import { formatPhone, formatTaxId } from '../utils';

export type ReviewDetailsCardScreenProps = {
  onEditSection: (onboardingCardType: VendorOnboardingCardsEnum) => void;
  bankAccountDetails: {
    isPlaidAccount: boolean;
    accountNumber: string | undefined;
    routingNumber: string | undefined;
  };
  account: Account;
  onSubmit: () => Promise<void>;
  error: ReviewDetailsErrorTypes | undefined;
};

export const ReviewDetailsCardScreen = forwardRef<ReviewDetailsCardScreenProps, 'div'>(
  ({ onEditSection, bankAccountDetails, account, onSubmit, error, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { convertFromISO, dateToString } = useDateUtils('MM/dd/yyyy');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const renderSectionErrorBanner = ({ errorMessage, onEdit }: { errorMessage: string; onEdit: () => void }) => (
      <SectionBanner
        data-testid="error-banner"
        action={
          <Text textStyle="body3">
            <Link
              href="#"
              label={formatMessage('vex.screen.reviewDetailsCard.error.cta')}
              onClick={onEdit}
              data-testid="review-details-error-cta"
            />
          </Text>
        }
        description={errorMessage}
        variant="critical"
        isCompact
      />
    );

    const renderEmailDetailsCard = () => {
      const emailAddress = (account.user.email as string) ?? account.company.inboxEmailAddress;
      return (
        <FormDetailsReviewCard
          data-testid="email-details-card"
          content={[
            {
              title: formatMessage('vex.screen.reviewDetailsCard.emailDetailsSection.title'),
              description: emailAddress,
            },
          ]}
          onEdit={() => onEditSection(VendorOnboardingCardsEnum.EmailVerification)}
        />
      );
    };

    const renderPersonalDetailsCard = () => {
      const { firstName, lastName, dateOfBirth, phoneNumber } = account.user;

      return (
        <FormDetailsReviewCard
          data-testid="personal-details-card"
          content={[
            {
              title: formatMessage('vex.screen.reviewDetailsCard.personalDetailsSection.name.title'),
              description: `${firstName} ${lastName}`,
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.personalDetailsSection.dateOfBirth.title'),
              description: dateToString(convertFromISO(dateOfBirth as string)),
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.personalDetailsSection.phoneNumber.title'),
              description: phoneNumber ? formatPhone(phoneNumber) : '',
            },
          ]}
          onEdit={() => onEditSection(VendorOnboardingCardsEnum.PersonalDetails)}
        />
      );
    };

    const renderBusinessDetailsCard = ({ isError }: { isError: boolean }) => {
      const { address, name, legalAddress, legalName, businessType, taxInfo, industry } = account.company;
      const onEdit = () => onEditSection(VendorOnboardingCardsEnum.BusinessDetails);

      if (isError) {
        return renderSectionErrorBanner({
          errorMessage: formatMessage('vex.screen.reviewDetailsCard.error.missingBusinessDetails'),
          onEdit,
        });
      }

      return (
        <FormDetailsReviewCard
          data-testid="business-details-card"
          content={[
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.companyName.title'),
              description: name as string,
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.address.title'),
              description: address ? formatAddress(address) : '',
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.industry.title'),
              description: industry?.name ?? '',
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.legalName.title'),
              description: legalName as string,
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.legalAddress.title'),
              description: legalAddress ? formatAddress(legalAddress) : '',
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.businessType.title'),
              description: businessType ? formatMessage(`app.mtl.labels.businessType.option.${businessType}`) : '',
            },
            {
              title: formatMessage('vex.screen.reviewDetailsCard.businessDetailsSection.taxType.title'),
              description: taxInfo?.type ?? '',
            },
            {
              title: formatMessage(`app.mtl.labels.company.taxId.${taxInfo?.type ?? 'EIN'}`),
              description: taxInfo?.identifier ? formatTaxId(taxInfo.identifier) : '',
            },
          ]}
          onEdit={onEdit}
        />
      );
    };

    const renderBankDetailsCard = ({ isError }: { isError: boolean }) => {
      const { isPlaidAccount, accountNumber, routingNumber } = bankAccountDetails;
      const onEdit = () => onEditSection(VendorOnboardingCardsEnum.BankDetails);

      if (isError) {
        return renderSectionErrorBanner({
          errorMessage: formatMessage('vex.screen.reviewDetailsCard.error.missingBankDetails'),
          onEdit,
        });
      }

      const cardDescription = isPlaidAccount
        ? formatMessage('vex.screen.reviewDetailsCard.bankAccountDetailsSection.bankAccount.plaidDescription')
        : formatMessage('vex.screen.reviewDetailsCard.bankAccountDetailsSection.bankAccount.bankDetailsDescription', {
            accountNumberLast4Digits: accountNumber?.slice(-4),
            routingNumberLast4Digits: routingNumber?.slice(-4),
          });

      return (
        <FormDetailsReviewCard
          data-testid="bank-details-card"
          content={[
            {
              title: formatMessage('vex.screen.reviewDetailsCard.bankAccountDetailsSection.bankAccount.title'),
              description: cardDescription,
            },
          ]}
          onEdit={onEdit}
        />
      );
    };

    const renderDetailsReviewCard = (cardType: VendorOnboardingCardsEnum) => {
      switch (cardType) {
        case VendorOnboardingCardsEnum.EmailVerification:
          return renderEmailDetailsCard();
        case VendorOnboardingCardsEnum.PersonalDetails:
          return renderPersonalDetailsCard();
        case VendorOnboardingCardsEnum.BusinessDetails:
          return renderBusinessDetailsCard({ isError: error === ReviewDetailsErrorTypes.BUSINESS_DETAILS_ERROR });
        case VendorOnboardingCardsEnum.BankDetails:
          return renderBankDetailsCard({ isError: error === ReviewDetailsErrorTypes.BANK_DETAILS_ERROR });
        default:
          return null;
      }
    };

    const onSubmitButtonClick = async () => {
      try {
        setIsSubmitting(true);
        await onSubmit();
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <Group variant="vertical" spacing="l" ref={ref} data-component="ReviewDetailsCardScreen" {...props}>
        {orderedOnboardingCardsToReview.map((cardType) => (
          <DetailsSectionWrapper
            key={cardType}
            title={formatMessage(sectionTitlesKeysMap[cardType] as LocalizationKey)}
          >
            {renderDetailsReviewCard(cardType)}
          </DetailsSectionWrapper>
        ))}
        <Group justifyContent="flex-end">
          <Button
            data-testid="submit-button"
            label={formatMessage('vex.screen.reviewDetailsCard.submitButtonLabel')}
            size="medium"
            onClick={() => void onSubmitButtonClick()}
            isLoading={isSubmitting}
          />
        </Group>
      </Group>
    );
  }
);

ReviewDetailsCardScreen.displayName = 'ReviewDetailsCardScreen';
