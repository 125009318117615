import { Container, ContainerProps, Text, TextProps, useBreakpointValue } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type BottomTextContentProps = TextProps & { textAlign?: ContainerProps['textAlign'] };

export const BottomTextContent = forwardRef<BottomTextContentProps, 'div'>(
  ({ textAlign: textAlignProp, ...props }, ref) => {
    const textAlign = useBreakpointValue<ContainerProps['textAlign']>({ xs: 'start', s: 'center' });
    const paddingX = useBreakpointValue<ContainerProps['paddingX']>({ xs: 's', s: 's', m: 'none' });

    return (
      <Container textAlign={textAlignProp ?? textAlign} paddingX={paddingX}>
        <Text
          ref={ref}
          data-component="NewSinglePaymentStepLayout.BottomTextContent"
          data-testid="layout-bottom-text-content"
          textStyle="body4"
          {...props}
        />
      </Container>
    );
  }
);

BottomTextContent.displayName = 'NewSinglePaymentStepLayout.BottomTextContent';
