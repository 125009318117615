import { Container, Group, GroupProps, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

export type ExternalLayoutContentWidgetFormProps = {
  title: string;
  description?: string;
  fields: ReactNode;
  spacing?: GroupProps['spacing'];
};

export const Form = forwardRef<ExternalLayoutContentWidgetFormProps, 'div'>(
  ({ title, description, fields, spacing, ...props }, ref) => (
    <Container data-component="ExternalLayoutContent.Form" {...props} paddingX="xl" paddingY="l" ref={ref}>
      <Group variant="vertical" spacing={spacing}>
        <Group variant="vertical" spacing="xs">
          <Text as="h1" textStyle="heading3Semi">
            {title}
          </Text>
          {description && <Text as="h2">{description}</Text>}
        </Group>
        {fields}
      </Group>
    </Container>
  )
);

Form.displayName = 'ExternalLayoutContentWidget.Form';
