import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiKeys, GetVexGuestPaymentResponseData, VexGuestApiClient } from '@melio/platform-api-axios-client';

export type VexGuestPaymentResponse = GetVexGuestPaymentResponseData;

export const useGuestPayment = (
  paymentId: string,
  options?: UseModelApiProps<typeof VexGuestApiClient.getVexGuestPayment, VexGuestPaymentResponse>
) =>
  useModelApi({
    ...options,
    id: paymentId,
    queryKey: [ApiKeys.VexGuestApi, 'getVexGuestPayment', paymentId],
    queryFn: VexGuestApiClient.getVexGuestPayment,
    select: (data) => ({
      ...data,
      orgName: data.orgName || '',
      note: data.note || '',
    }),
  });
