import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const NoVendorFoundIcon = (props: IconProps) => (
  <Icon width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 100C22.386 100 0 77.614 0 50C0 22.386 22.386 0 50 0C77.614 0 100 22.386 100 50C100 77.614 77.614 100 50 100Z"
      fill="#273B49"
    />
    <mask id="mask0_4288_264322" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100C22.386 100 0 77.614 0 50C0 22.386 22.386 0 50 0C77.614 0 100 22.386 100 50C100 77.614 77.614 100 50 100Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4288_264322)">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2227 22H76.7681V83H22.2227V22Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4285 61.3506C37.4285 61.3506 38.6763 60.7576 43.4677 60.4118C44.0667 59.3247 44.3162 57.6447 44.466 56.2612C41.2717 53.9388 39.0756 49.5906 39.0756 44.6494C39.0756 37.1882 40.0239 32 49.9561 32C59.8884 32 60.8367 37.2376 60.8367 44.6494C60.8367 49.64 58.6406 53.9882 55.3964 56.3106C55.4963 57.6941 55.6959 59.3741 56.3447 60.4612C60.7369 60.7576 62.4838 61.3012 62.4838 61.3012C68.7725 62.9812 70.6192 69.5035 70.6192 73.9506C60.7868 74 43.7672 74 29.293 74C29.293 69.5529 31.4392 63.5741 37.4285 61.3506Z"
        fill="#98D5EC"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2227 66H76.7681V83H22.2227V66Z" fill="#DCD7DA" />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.3643 76H62.627V78H36.3643V76Z" fill="#687680" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42.4248 71H57.5763V73H42.4248V71Z" fill="#687680" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.4346 27H55.0509V14.9916C55.0509 14.9916 55.1134 10.0482 49.2395 10.0004C43.5559 9.95418 43.4346 14.8972 43.4346 14.8972V27Z"
        fill="#D8D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4118 17.6957C50.0275 17.6957 50.5761 17.4213 50.9308 16.9938C51.192 16.6788 51.3479 16.2807 51.3479 15.8478C51.3479 14.8273 50.4811 14 49.4118 14C48.3425 14 47.4756 14.8273 47.4756 15.8478C47.4756 16.8684 48.3425 17.6957 49.4118 17.6957Z"
        fill="#273B49"
      />
    </g>
  </Icon>
);
