import { useMelioIntl } from '@melio/platform-provider';
import { object, SchemaOf, string } from 'yup';

import { BankAccountFormModel } from '../../../types';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
  validateRoutingNumber,
  ValidationError,
} from '../../../utils/bank-account-validations/bank-account-validations.utils';

const isDigitsOnly = (value = '') => /^\d+$/.test(value);

export const useAchValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    routingNumber: string()
      .required(
        formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.required', {
          length: ROUTING_NUMBER_LENGTH,
        })
      )
      .test('routingNumber', '', (value, testContext) => {
        const routingNumberValidation = validateRoutingNumber(value);
        if (routingNumberValidation.valid) {
          return true;
        }
        switch (routingNumberValidation.error) {
          case ValidationError.INVALID_CHARACTERS:
            return testContext.createError({
              message: formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.digitsOnly'),
            });
          case ValidationError.INVALID_LENGTH:
            return testContext.createError({
              message: formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.invalidLength', {
                length: ROUTING_NUMBER_LENGTH,
              }),
            });
        }
        return testContext.createError({
          message: formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.invalidNumber', {
            length: ROUTING_NUMBER_LENGTH,
          }),
        });
      }),

    accountNumber: string()
      .required(
        formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.required', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      )
      .test(
        'digitsOnly',
        formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.digitsOnly'),
        isDigitsOnly
      )
      .min(
        ACCOUNT_NUMBER_MIN_LENGTH,
        formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.invalidMinLength', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      )
      .max(
        ACCOUNT_NUMBER_MAX_LENGTH,
        formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.invalidMaxLength', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      ),
  }) as SchemaOf<BankAccountFormModel>;
};
