import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { CardHolderDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  CardHolderDetailsForm,
  ExternalLayoutContentWidget,
  FooterWidget,
  PushToDebitHeaderWidget,
  PushToDebitHeaderWidgetProps,
} from '@melio/vex-widgets';
import { Navigate } from 'react-router-dom';

import { ANALYTICS_CONTEXT } from '../../consts';

export type PaymentUpgradeTBTCardHolderDetailsScreenProps = PushToDebitHeaderWidgetProps & {
  isSaving: boolean;
  isCardDetailsFilled: boolean;
  isPaymentUpgraded: boolean;
  defaultValues?: Partial<CardHolderDetails>;
  handleBack: VoidFunction;
  handleSubmit: (data: CardHolderDetails) => Promise<void>;
};

export const PaymentUpgradeTBTCardHolderDetailsScreen = forwardRef<
  PaymentUpgradeTBTCardHolderDetailsScreenProps,
  'div'
>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      isSaving,
      isCardDetailsFilled,
      isPaymentUpgraded,
      defaultValues,
      feePercentValue,
      calculatedFee,
      handleBack,
      handleSubmit: _handleSubmit,
      children,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CardHolderDetails>();

    const analyticsProps = {
      Intent: 'add-fast-delivery-method-vendor-details',
      DeliveryMethodChosen: 'card',
      PageName: 'please-add-your-cardholder-details',
    };

    usePageView(ANALYTICS_CONTEXT, analyticsProps);

    const handleSubmit = async (data: CardHolderDetails) => {
      track(ANALYTICS_CONTEXT, 'Click', { ...analyticsProps, Cta: 'submit-and-accept-payment' });
      await _handleSubmit(data);
    };

    if (!isCardDetailsFilled) {
      return <Navigate to="../card-details" />;
    }

    if (isPaymentUpgraded) {
      return <Navigate to="../success" />;
    }

    const content = (
      <ExternalLayoutContentWidget
        header={{
          backButtonProps: { isDisabled: cancelButtonProps?.isDisabled, onClick: handleBack },
          title: (
            <PushToDebitHeaderWidget
              accountName={accountName}
              paymentAmount={paymentAmount}
              paymentInvoiceNumber={paymentInvoiceNumber}
              paymentNote={paymentNote}
              feePercentValue={feePercentValue}
              calculatedFee={calculatedFee}
              showAvatar={false}
            />
          ),
        }}
        form={{
          title: formatMessage('activities.paymentUpgrade.screens.cardHolderDetails.title'),
          fields: (
            <CardHolderDetailsForm
              onSubmit={handleSubmit}
              defaultValues={defaultValues}
              isSaving={isSaving}
              onSubmissionStateChange={onSubmissionStateChange}
              size="small"
            />
          ),
        }}
        continueSection={{
          buttonProps: {
            ...submitButtonProps,
            label: formatMessage('activities.paymentUpgrade.screens.cardHolderDetails.continue'),
          },
        }}
      />
    );

    return (
      <ExternalLayout
        data-component="PaymentUpgradeActivity.PaymentUpgradeTBTCardHolderDetailsScreen"
        data-testid="vex-payment-upgrade-cardholder-details-screen"
        ref={ref}
        {...props}
        content={content}
        footer={<FooterWidget />}
      />
    );
  }
);

PaymentUpgradeTBTCardHolderDetailsScreen.displayName =
  'PaymentUpgradeActivity.PaymentUpgradeTBTCardHolderDetailsScreen';
