import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';
export const Celebration: React.VFC = () => (
  <LazyAnimation
    id="celebration"
    getAnimationData={() => import('./assets/celebration.lottie')}
    width="100%"
    height="100%"
    loop={false}
  />
);
