import { featureFlags } from '@melio/shared-web';
import { UseFeatureOptions } from '@melio/shared-web/dist/feature-flags';

import { useConfig } from './ConfigProvider';
import { SettingsKeysWithEnabledProp } from './types';

export type ConfigFeature = SettingsKeysWithEnabledProp;

type FeatureSetting = {
  flagName?: string;
  enabled?: boolean;
};

export const usePartnerFeature = <T, FEATURE extends ConfigFeature>(
  feature: FEATURE,
  defaultValue: T,
  options?: UseFeatureOptions
): [T | boolean, boolean] => {
  const { settings } = useConfig();
  const featureSetting = settings?.[feature] as FeatureSetting;
  const configFlagName = featureSetting?.flagName;
  const [flagValue, isLoading]: [T, boolean] = featureFlags.useFeature(configFlagName || '', defaultValue, options);

  if (!settings) {
    return [defaultValue, true];
  }

  const enabledInConfig = featureSetting?.enabled;

  if (!enabledInConfig) {
    return [false, false];
  }

  if (featureSetting.flagName) {
    return [flagValue, isLoading];
  }

  return [enabledInConfig, false];
};
