import { createContext, useContext } from 'react';

import { CreateConfig } from '../../hooks/useActivityConfigData';

type ActivityConfig = { persistentFastOptOutArticleUrl: string };

export const createActivityConfig: CreateConfig<ActivityConfig> = ({ vex: { persistentFastOptOutArticleUrl } }) => ({
  persistentFastOptOutArticleUrl,
});

export const PaymentUpgradeConfigContext = createContext<ActivityConfig>({} as ActivityConfig);
export const useActivityContext = () => useContext<ActivityConfig>(PaymentUpgradeConfigContext);
