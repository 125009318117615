import { Icon, IconProps } from '@chakra-ui/react';

export const CapOneErrorIcon = (props: IconProps) => (
  <Icon width="100px" height="100px" viewBox="0 0 100 100" fill="#C1E7F5" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 50C100 77.614 77.614 100 50 100C22.386 100 0 77.614 0 50C0 22.386 22.386 0 50 0C77.614 0 100 22.386 100 50Z"
      fill="#C1E7F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.2721 78.3331C65.3241 90.6301 51.3451 96.5881 39.0481 91.6401C26.7511 86.6921 20.7931 72.7131 25.7411 60.4161C30.6891 48.1191 44.6681 42.1611 56.9641 47.1091C69.2611 52.0571 75.2191 66.0361 70.2721 78.3331Z"
      fill="#33B1DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6065 55.6183C39.1635 59.2053 35.0855 60.9423 31.4995 59.4993C27.9125 58.0563 26.1745 53.9793 27.6185 50.3923C29.0615 46.8053 33.1385 45.0683 36.7255 46.5123C40.3115 47.9543 42.0495 52.0313 40.6065 55.6183Z"
      fill="#33B1DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.1441 68.1232C73.9671 71.0482 70.6421 72.4652 67.7161 71.2882C64.7911 70.1112 63.3741 66.7862 64.5511 63.8602C65.7281 60.9362 69.0531 59.5192 71.9781 60.6952C74.9041 61.8722 76.3211 65.1972 75.1441 68.1232Z"
      fill="#33B1DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.1703 57.539L38.7723 44.101L68.4883 13.507C69.7353 12.223 71.9133 13.099 71.9233 14.889L72.1703 57.539Z"
      fill="#FFDC24"
    />
    <mask id="mask0_5900_358331" maskUnits="userSpaceOnUse" x="39" y="16" width="34" height="36">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.0667 16L39 43.8683V44.1933L58.4031 52H72.1381L71.9304 16H66.0667Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_5900_358331)">
      <path fillRule="evenodd" clipRule="evenodd" d="M39 44H77V40H39V44Z" fill="#F0C501" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39 52H77V48H39V52Z" fill="#F0C501" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39 28H77V24H39V28Z" fill="#F0C501" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39 36H77V32H39V36Z" fill="#F0C501" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39 20H77V16H39V20Z" fill="#F0C501" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1416 61.4364L36.8156 47.6254C35.5346 47.1104 34.9136 45.6544 35.4296 44.3734C35.9446 43.0914 37.4006 42.4714 38.6816 42.9874L73.0076 56.7974C74.2886 57.3124 74.9096 58.7694 74.3936 60.0494C73.8786 61.3314 72.4226 61.9514 71.1416 61.4364Z"
        fill="#F0C501"
      />
    </g>
    <mask id="mask1_5900_358331" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 0C22.386 0 0 22.386 0 50C0 77.614 22.386 100 50 100C77.614 100 100 77.614 100 50C100 22.386 77.614 0 50 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_5900_358331)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 100H100V75H0V100Z" fill="#004977" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5 75H20.5C19.119 75 18 73.881 18 72.5C18 71.119 19.119 70 20.5 70H59.5C60.881 70 62 71.119 62 72.5C62 73.881 60.881 75 59.5 75Z"
        fill="#66C4E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 86C40.657 86 42 84.657 42 83V76.998C42 75.038 44 75 44 75H32C32 75 36 75.064 36 79V83C36 84.657 37.343 86 39 86Z"
        fill="#66C4E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 82C48.343 82 47 80.657 47 79V76.998C47 75.038 45 75 45 75H56C56 75 53 75.045 53 78.001V79C53 80.657 51.657 82 50 82Z"
        fill="#66C4E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 100C66.896 100 66 99.104 66 98V79C66 75.056 62 75 62 75H73C73 75 70 75.102 70 77.995V98C70 99.104 69.104 100 68 100Z"
        fill="#66C4E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 70H27C30 70 30 64 38 64C46 64 45 69 51 69C57 69 58.333 67 63 67C67.667 67 76.238 72.587 73.826 75H25.111L24 70Z"
        fill="#66C4E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 89.9999C59.896 89.9999 59 89.1039 59 87.9999V72.9999C59 67.6499 55 68.6409 55 68.6409L61.345 63.0129L66 67.5519C66 67.5519 63 66.1019 63 68.9949V87.9999C63 89.1039 62.104 89.9999 61 89.9999Z"
        fill="#33B1DD"
      />
    </g>
  </Icon>
);
