import { Actions } from './Actions';
import { BackButton } from './BackButton';
import { BottomTextContent } from './BottomTextContent';
import { CloseButton } from './CloseButton';
import { Content } from './Content';
import { Description } from './Description';
import { Footer } from './Footer';
import { Header } from './Header';
import { NakedNextButton } from './NakedNextButton';
import { NextButton } from './NextButton';
import { ProgressBar } from './ProgressBar';
import { SecondaryNextButton } from './SecondaryNextButton';
import { Title } from './Title';

export const components = {
  Header,
  Actions,
  BackButton,
  ProgressBar,
  CloseButton,
  NextButton,
  SecondaryNextButton,
  NakedNextButton,
  Title,
  Description,
  Content,
  BottomTextContent,
  Footer,
};

Object.entries(components).forEach(([key, Comp]) => {
  Comp.displayName = `NewSinglePaymentStepLayout.${key}`;
});
