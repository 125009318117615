import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  ExternalLayoutContentWidget,
  FooterWidget,
  PayorPendingPaymentDetailsWidget,
  PoweredByMelioWidget,
  VendorBankDetailsPayeeForm,
} from '@melio/vex-widgets';

import { BankAccountFormModel } from '../../../types';

export type ShiftToAchInsertDetailsScreenProps = {
  accountName: string;
  paymentAmount: number;
  paymentInvoiceNumber?: string | null;
  paymentNote?: string;
  showPoweredByMelio: boolean;
  onDone: (data: BankAccountFormModel) => void;
  isSaving?: boolean;
  defaultValues?: Partial<BankAccountFormModel>;
  isLoading?: boolean;
};

export const ShiftToAchInsertDetailsScreen = forwardRef<ShiftToAchInsertDetailsScreenProps, 'div'>(
  (
    {
      accountName,
      paymentNote,
      paymentAmount,
      paymentInvoiceNumber,
      showPoweredByMelio,
      children,
      onDone,
      isSaving,
      isLoading,
      defaultValues,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<BankAccountFormModel>();
    const { track } = useAnalytics();

    usePageView('shift-suvc-to-ach', { Intent: 'shift-suvc-to-ach' });

    const handleContinueClick = () => {
      track('shift-suvc-to-ach', 'Click', {
        intent: 'shift-suvc-to-ach',
      });
      submitButtonProps?.onClick();
    };
    const legalInfoOverride = accountName
      ? formatMessage('vex.widgets.legal.infoWithAccountName', { accountName })
      : undefined;

    const contentForm = (
      <VendorBankDetailsPayeeForm
        onSubmit={onDone}
        isSaving={isSaving}
        defaultValues={defaultValues}
        onSubmissionStateChange={onSubmissionStateChange}
        size="small"
        columns={2}
      />
    );
    const contentHeader = (
      <PayorPendingPaymentDetailsWidget
        accountName={accountName}
        description={formatMessage('vex.screen.suvcAcceptance.ShiftToAchInsertDetails.description.title', {
          accountName,
        })}
        paymentAmount={paymentAmount}
        paymentInvoiceNumber={paymentInvoiceNumber}
        paymentNote={paymentNote}
        paymentInvoiceNumberLabel={formatMessage(
          'widgets.deliveryMethodSelectionByPayeeHeader.paymentInvoiceNumberLabel'
        )}
        paymentNoteLabel={formatMessage('widgets.deliveryMethodSelectionByPayeeHeader.paymentNoteLabel')}
        showAvatar
      />
    );
    const content = (
      <ExternalLayoutContentWidget
        header={{
          title: contentHeader,
        }}
        form={{
          title: formatMessage('vex.screen.suvcAcceptance.ShiftToAchInsertDetails.addBankDeliveryMethod.title'),
          fields: contentForm,
        }}
        continueSection={{
          buttonProps: {
            ...submitButtonProps,
            label: formatMessage(
              'vex.screen.suvcAcceptance.ShiftToAchInsertDetails.addBankDeliveryMethod.continueButton'
            ),
            onClick: handleContinueClick,
          },
          legalInfoOverride,
        }}
      />
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="ShiftVirtualCardToACHActivity.ShiftToAchInsertDetailsScreen"
        data-testid="shift-virtual-card-to-ach-activity-shift-to-ach-insert-details-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

ShiftToAchInsertDetailsScreen.displayName = 'ShiftVirtualCardToACHActivity.ShiftToAchInsertDetailsScreen';
