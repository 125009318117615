import { ContinueSection } from './ContinueSection';
import { Form } from './Form';
import { Header } from './Header';

export type { ExternalLayoutContentWidgetContinueSectionProps } from './ContinueSection';
export type { ExternalLayoutContentWidgetFormProps } from './Form';

export const components = {
  ContinueSection,
  Header,
  Form,
};
