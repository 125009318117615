import React from 'react';
import { Image } from '@chakra-ui/react';
import { isEmpty, merge } from 'lodash';
import { PartnerName } from '@melio/platform-api';

import { PartnersConfiguration } from '@/partnersConfig';
import { PartnerConfig } from '@/partnersConfig.types';

export const getOverrideParams = (partner?: PartnerName) => {
  const queryParams = new URLSearchParams(window.location.search);

  const customOverride =
    partner === PartnerName.Melio
      ? (JSON.parse(window.atob(queryParams.get('override') || '') || '{}') as Record<
          string,
          Record<string, unknown> | string
        >)
      : {};

  if (!isEmpty(customOverride)) {
    const icons = customOverride?.icons
      ? Object.keys(customOverride?.icons).reduce((icons, currentKey) => {
          const src = typeof customOverride?.icons === 'object' ? customOverride?.icons[currentKey] || '' : '';
          return {
            ...icons,
            ...(src && typeof src === 'string' ? { [currentKey]: () => <Image src={src} alt={currentKey} /> } : {}),
          };
        }, {})
      : {};

    const partnerConfiguration = PartnersConfiguration[partner!];
    return {
      ...(customOverride?.icons ? { icons: merge(partnerConfiguration.icons, icons) } : {}),
      ...(customOverride?.theme ? { theme: merge(partnerConfiguration.theme, customOverride.theme) } : {}),
      ...(customOverride?.displayName ? { displayName: customOverride.displayName } : {}),
    } as RecursivePartial<PartnerConfig>;
  }
  return {};
};
