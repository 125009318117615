import { Button, Container, Group, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';

export type SessionExpiredScreenProps = {
  onContinue: (() => void) | undefined;
};

export const SessionExpiredScreen = withAnalyticsContext<SessionExpiredScreenProps>(
  forwardRef(({ onContinue, setAnalyticsProperties, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'session-expired',
    });
    usePageView('Connection');
    const showContinueButton = Boolean(onContinue);
    const onContinueClick = () => {
      if (onContinue) {
        onContinue();
        track('Organization', 'Click', {
          Cta: 'continue',
        });
      }
    };

    return (
      <Container
        height="full"
        alignItems="center"
        justifyContent="center"
        ref={ref}
        data-component="SessionExpiredScreen"
        {...props}
      >
        <Group variant="vertical" alignItems="center" spacing="xxl" textAlign="center">
          <Group variant="vertical" spacing="xs">
            <Text textStyle="heading1Semi">{formatMessage('vex.screen.vendorOnboarding.sessionExpired.title')}</Text>
            <Text textStyle="body2">{formatMessage('vex.screen.vendorOnboarding.sessionExpired.subtitle')}</Text>
          </Group>
          {showContinueButton && (
            <Button
              variant="primary"
              size="large"
              onClick={onContinueClick}
              data-testid="continue-button"
              label={formatMessage('vex.screen.vendorOnboarding.sessionExpired.buttonLabel')}
            />
          )}
        </Group>
      </Container>
    );
  })
);

SessionExpiredScreen.displayName = 'SessionExpiredScreen';
