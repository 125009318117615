/* eslint-disable max-lines */
import {
  BillsEmailInboxDashboardView,
  FileType,
  MelioConfig,
  PaymentSchedulingPreference,
  TrustedPartners,
  VendorDetailsFormType,
} from './types';

const defaultLinks = {
  'app.settings.SupportSection.accordion.businessBills.links.link1.href': '',
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href': '',
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href': '',
  'app.settings.SupportSection.accordion.addDebitCard.links.link1.href': '',
  'app.settings.SupportSection.accordion.verifyMicroDeposits.links.link1.href': '',
  'app.settings.SupportSection.accordion.addBankAccount.links.link1.href': '',
  'app.settings.SupportSection.accordion.addBill.links.link1.href': '',
  'app.settings.SupportSection.accordion.payBill.links.link1.href': '',
  'app.settings.SupportSection.accordion.deliveryTimelines.links.link1.href': '',
  'app.settings.SupportSection.accordion.vendors.links.link1.href': '',
  'app.settings.SupportSection.accordion.cancelPayment.links.link1.href': '',
  'app.settings.SupportSection.accordion.security.links.link1.href': '',
  'app.settings.SupportSection.accordion.addBillClone.links.link1.href': '',
  'app.settings.SupportSection.accordion.addBillClone.links.link2.href': '',
  'app.settings.SupportSection.accordion.addBillClone.links.link3.href': '',
  'widgets.footer.termsOfServiceLink.href': '',
  'widgets.footer.privacyPolicy.href': '',
  'widgets.companySettings.header.sectionBanner.linkHref': '',
  'creditKey.legal.privacyPolicy': '',
  'creditKey.legal.termsOfService': '',
  'fx.legal.termsAndConditions': '',
  'widgets.learnAboutRates.href': '',
  'activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.creditKey': '',
  'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.vendor-ineligible.learnMore.href':
    '',
  'activities.financingApplication.screens.applicationForm.title.learnMore': '',
  helpCenter: '',
  submitSupportRequest: '',
  backButtonUrl: '',
  backButtonUrlFallback: '',
  portalLink: '',
  failedToCollectSupport: '',
  financingMoreDetails: '',
  unilateralDeliveryLearnMore: '',
  undepositedCheckLearnMore: '',
  contactCreditKey: '',
  resolveFailedPayment: '',
  contactMelioSupport: '',
  quickBooksDesktopWebConnectorGuide: '',
  verifyBankAccountWithMicroDeposit: '',
};
export const defaultConfig: MelioConfig = {
  tabTitle: 'Pay Bills',
  analytics: { key: '', shouldPrintEvents: false, shouldTrackEvents: false, blockedEventDestinations: [] },
  dataDog: { appId: '', clientId: '', service: '', shouldInit: false, site: '' },
  featureFlagProvider: { clientId: '', shouldLoad: false },
  production: false,
  tutorialVideo: '',
  zendesk: { useNewWidgetApi: false, headerColor: '', logo: '', resultListsColor: '', themeColor: '', title: '' },
  server: {
    apiServer: 'http://localhost:3333',
  },
  settings: {
    expiredSessionTime: 15,
    showDemoRequestIcon: true,
    isEmbeddedExperience: false,
    isPaymentApprovalLimitEnabled: false,
    showApproveActionButton: true,
    isDeclinePaymentReasonEnabled: true,
    supportRequestTimeoutEnable: false,
    collaborator: {
      isAddCollaboratorEnabled: true,
      isChangeCollaboratorPermissionLevelEnabled: false,
      showRoleTransferDisclaimer: false,
    },
    isEntitlementsEnabled: false,
    isLineItemsEnabled: false,
    newBillExperience: {
      isRecurringEnabled: true,
      isRecurringPaymentImprovementsEnabled: true,
      shouldShowIconInBillsTabCta: false,
    },
    accountingFirmDashboard: {
      emptyStateAsset:
        'https://platform-static.meliopayments.com/assets/default/illustrations/accountants-dashboard-illustration.svg',
    },
    singlePaymentStepLayout: {
      backgroundColor: 'lightest',
    },
    contextualOnboarding: {
      buttons: [],
    },
    icons: {
      fundingSource: {
        bank: 'bank',
      },
      deliveryMethod: {
        virtualCard: 'mastercard',
      },
    },
    assets: {},
    collectMissingCompanyLegalInfoFields: true,
    billsEmailInboxDashboardView: BillsEmailInboxDashboardView.Link,
    payment: {
      isConfirmationNumberEnabled: false,
      scheduling: {
        scheduleBy: PaymentSchedulingPreference.ByScheduleDate,
        showEarlierApprovalDate: false,
        showFuturePaymentBanner: false,
        disableEditDeliveryDateForCards: false,
        recurringDeliveryMethodBlackList: [],
      },
      editing: {
        defaultToManagedAccountPreferredDeliveryMethod: false,
        shouldCheckFundingSourceEntitlements: false,
        disableEditDeliveryMethod: false,
      },
      scheduled: {
        showZeroFees: true,
        showEstimatedDeliveryDateBanner: false,
      },
      showCreatedByIndication: false,
      memoToVendor: {
        maxLength: 50,
        deliveryMethodBlackList: ['biller-account', 'managed-account'],
        allowSendingEmailToVendorWhenAddingMemo: true,
      },
      allowNotifyingVendor: true,
    },
    batchPayments: {
      showDeliverySpeedColumn: true,
      showVendorVerifiedDeliveryMethodTooltip: true,
      usePreDefinedlDeliveryMethodList: false,
      showDebitSameDateIndication: false,
      useMinimalHeaderDesign: false,
      showLateIndicator: true,
      backgroundColor: 'lightest',
      headerVariant: 'light',
      showZeroFees: true,
      showFeesTooltipTitle: true,
      closeButtonOnLeft: false,
      showVendorDetails: false,
      showRelativeDates: false,
    },
    fundingSourcePolicy: {
      'bank-account': {
        read: true,
        write: true,
      },
      credit: {
        read: true,
        write: true,
      },
      debit: {
        read: true,
        write: true,
      },
    },
    uploadBill: {
      shouldShowMultipleBillUploadIndication: true,
    },
    mccLearnMoreUrl:
      'https://melio.zendesk.com/hc/en-us/articles/4415821314076-A-change-in-how-you-pay-using-your-American-Express-card',
    supportEmail: 'webmaster@example.com',
    termsOfServiceUrl:
      'https://www.meliopayments.com/legal/terms-of-service?_ga=2.47262112.662432983.1653317311-444475439.1647269629',
    privacyPolicyUrl:
      'https://www.meliopayments.com/legal/privacy-policy?_ga=2.9364146.662432983.1653317311-444475439.1647269629',
    learnMoreAccountVerificationURL: 'https://melio.zendesk.com/hc/en-us/articles/7104668877084',
    registerToMelioGetPaid: 'https://app.meliopayments.com/meliome/register',
    goodsReceivedAmountThreshold: 3000,
    requireInvoiceForBillsAmountThreshold: 10000,
    helpCenterUrl: 'https://melio.zendesk.com/hc/en-us',
    howContactToSupportArticleUrl:
      'https://melio.zendesk.com/hc/en-us/articles/4951819705628-All-the-ways-to-contact-Melio-customer-support',
    fileSizeLimit: 10 * 1024 * 1024, // 10MB
    fileAllowedFormats: ['pdf', 'png', 'jpg', 'jpeg'] as FileType[],
    tokenProvider: 'tabapay',
    eoyCheck: {
      enabled: false,
      promoteFastCheck: false,
      link: 'https://melio.zendesk.com/hc/en-us/articles/6548872564508-USPS-potential-check-delivery-delays-during-the-holidays',
    },
    unilateralRequest: {
      announceFees: false,
    },
    payDashboardBillsTabTourEnabled: false,
    payDashboardSearchLabelDisabled: true,
    newPayExperience: {
      isEnabled: true,
      firstTimeExperience: {
        assetType: 'image',
        imageUrl: 'https://platform-static.meliopayments.com/assets/default/illustrations/first-time-experience.png',
        mobileImageUrl:
          'https://platform-static.meliopayments.com/assets/default/illustrations/first-time-experience.png',
        videoLink: '',
      },
    },
    trustedPartners: [TrustedPartners.Mastercard, TrustedPartners.Quickbooks, TrustedPartners.Visa],
    isUnilateralDeliveryMethodSupported: true,
    isPaymentTimelineEnabled: false,
    virtualCardLearnMoreLink:
      'https://melio.zendesk.com/hc/en-us/articles/4416039832732-Why-has-my-vendor-s-delivery-method-changed-to-single-use-virtual-card',
    partnerDisplayName: '',
    partnerProductName: '',
    contractorLearnMoreLink: 'https://google.com',
    tax1099LearnMoreLink: 'https://google.com',
    vendor: {
      collectedDetails: 'minimal',
      requiredDirectoryFields: [],
      vendorSelect: {
        createVendor: {
          showVendorDetailsForm: false,
          vendorDetailsFormType: VendorDetailsFormType.Modal,
        },
      },
      createVendor: {
        hasSkipDeliveryMethodButton: true,
        canAddVendorWithTheSameName: false,
        shouldSwitchToExtendedFormOnApiErrors: false,
        shouldShowBankDetailsFields: false,
        shouldShowAddressDetailsBanner: false,
      },
      forms: {
        shouldUseZipCodeMask: false,
        shouldUseSearchVendorSectionLabel: true,
        shouldUseTooltipsForManagedVendorForm: true,
      },
      warnEditingVendorWithFuturePayments: false,
    },
    paymentLateApproval: {
      debitDatePassedIndicationEnabled: true,
      debitDateTodayIndicationEnabled: false,
    },
    isMultiColoredAvatarsEnabled: true,
    virtualCardExpiryPeriodInDays: 0,
    deliveryMethodTypeOrder: {
      'bank-account': 1,
      'paper-check': 2,
      'domestic-wire-account': 3,
      'virtual-card': 4,
      'virtual-account': 5,
      'international-account': 6,
    },
    deliveryMethodTypeOptionDisabledReasons: {
      'bank-account': [],
      'paper-check': [],
      'domestic-wire-account': [],
      'virtual-account': [],
      'international-account': ['cardFundingSourceNotSupported'], // TODO: maybe merge with fundingSourceNotSupported
      'virtual-card': [],
    },
    syncProgressIndicationEnabled: false,
    vex: {
      zdSupportFormUrl: 'https://melio.zendesk.com/hc/en-us/requests/new',
      vendorsContactEmail: 'ar-vendors-support@melio.com',
      persistentFastOptOutArticleUrl:
        'https://melio.zendesk.com/hc/en-us/articles/12413481862812?utm_source=&utm_medium=&utm_campaign=opt-out-fast-preference',
      unilateral: {
        isEmailLoginEnabled: false,
        shouldSuggestJoinMelio: false,
        isNewUnilateral: false,
        suvcInformationLink: 'https://meliopayments.com/guides/single-use-virtual-cards/',
      },
    },
    showCheckMarkIconForManagedVendor: true,
    accountingSoftware: {
      isConnectConfirmationEnabled: false,
    },
    unmaskVendorAccountNumber: false,
    subscriptionsFirstWeek: {
      enabled: false,
    },
    InternationalFx: {
      enabled: false,
    },
    MarkAsPaid: {
      enabled: true,
    },
    InvalidVendorBankAccountModal: {
      enabled: false,
    },
    File1099SettingsTaxAndReports: {
      flagName: 'platform-feature-1099-tax-and-reports-settings',
      enabled: true,
    },
  },
  services: {
    zendeskWorkflow: '',
    zendeskKey: '',
    forethoughtApiKey: '',
    googleClientId: '',
    smartystreets: {
      apiKey: '27409250903411363',
    },
    googleMaps: {
      apiKey: 'AIzaSyCy2BbnUakgGy08r_gmL8Qlo0VikreL7WA',
    },
    plaid: {
      env: 'sandbox',
      apiKey: '7f066139be81ee696f3a4c27a78d7e',
    },
    tabapay: {
      card: {
        url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioPayments1.html',
        iframeHeight: [430, 380],
        iframeWidth: '100%',
      },
    },
    userSnap: {
      spaceId: '39ab2947-23d9-4768-a96f-9eff5f508557',
      projectId: '786dcee3-f5f6-42da-93f7-15db525c1e1b',
    },
    tbt: {
      apiKey: 'key_GZFc6KNn6zbN6Y54aFZfpV',
      supportedCardProviders: {
        visa: true,
        mastercard: true,
        'american-express': true,
        'diners-club': true,
        discover: true,
        unknown: false,
      },
      fontsUrl: 'https://platform-static.meliopayments.com/fonts/fonts-config.css',
    },
  },
  links: defaultLinks,
};
