import { Box, ToastPositionWithLogical } from '@chakra-ui/react';
import { useBreakpoint } from '@melio/penny';

import { Banner, BannerProps } from './Banner';

export type PositionedBannerProps = BannerProps & {
  position?: ToastPositionWithLogical;
};

export const PositionedBanner = (props: PositionedBannerProps) => {
  const { isExtraSmallScreen } = useBreakpoint();

  if (isExtraSmallScreen) {
    return (
      <Box
        position="fixed"
        display="flex"
        bottom="16px"
        right="16px"
        left="16px"
        zIndex="10"
        justifyContent="right"
        alignItems="right"
      >
        <Banner {...props} />
      </Box>
    );
  }
  return (
    <Box position="fixed" bottom="s" right="16px" left="auto" zIndex="1000">
      <Banner {...props} />
    </Box>
  );
};
