import { Card, Container, ExternalLayout, Group, IconButton, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { EmailLoginForm, EmailLoginInputModel, FooterWidget, PoweredByMelioWidget } from '@melio/vex-widgets';

import { useUtmParameters } from '../../../../hooks/useUtmParameters';

export type EmailLoginScreenProps = {
  isSaving?: boolean;
  onBack: VoidFunction;
  onDone: (email: string) => Promise<void>;
  showPoweredByMelio?: boolean;
  errorMessage?: string;
};

export const EmailLoginScreen = withAnalyticsContext<EmailLoginScreenProps>(
  forwardRef(
    (
      { isSaving, onBack, onDone, showPoweredByMelio, errorMessage, children, setAnalyticsProperties, ...props },
      ref
    ) => {
      const { utmMedium, utmCampaign } = useUtmParameters();
      const analyticsProperties = {
        EntryPoint: utmMedium ?? undefined,
        Flow: utmCampaign ?? undefined,
        Intent: 'verify-business-email',
        PageName: 'verify-your-business-email',
      };
      setAnalyticsProperties(analyticsProperties);
      usePageView('Vendor');

      const { track } = useAnalytics();
      const { cancelButtonProps, onSubmissionStateChange } = useFormSubmissionController<{
        email: string;
      }>();

      const handleDone = (data: EmailLoginInputModel) => {
        track('Vendor', 'Click', {
          Cta: 'send-code',
          BusinessEmail: data.email,
        });
        onDone(data.email).catch(() =>
          track('Vendor', 'Status', {
            Cta: 'send-code',
            BusinessEmail: data.email,
          })
        );
      };

      const onBackClick = () => {
        track('Vendor', 'Click', {
          Cta: 'back',
        });
        onBack();
      };

      const content = (
        <Card paddingX="none" paddingY="none">
          <Group variant="vertical" spacing="none" width="full">
            <Container paddingX="xl" paddingTop="l" paddingBottom="none">
              <IconButton
                aria-label=""
                variant="naked"
                onClick={onBackClick}
                icon="chevron-left"
                size="medium"
                {...cancelButtonProps}
              />
            </Container>
            <Container paddingX="xl" paddingY="xl">
              <EmailLoginForm
                isSaving={isSaving}
                onSubmit={handleDone}
                onSubmissionStateChange={onSubmissionStateChange}
                errorMessage={errorMessage}
                onBack={onBack}
              />
            </Container>
          </Group>
        </Card>
      );

      const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

      return (
        <ExternalLayout
          data-component="EmailLoginScreen"
          data-testid="email-login-screen"
          {...props}
          content={content}
          footer={footer}
          ref={ref}
        />
      );
    }
  )
);

EmailLoginScreen.displayName = 'EmailLoginScreen';
