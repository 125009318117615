import { Container, Group, Text } from '@melio/penny';
import { FormattedMessage, TrustedPartners, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { AmexGray } from '../../PoweredByMelioFooter/assets/AmexGray';
import { DinersGray } from '../../PoweredByMelioFooter/assets/DinersGray';
import { DiscoverGray } from '../../PoweredByMelioFooter/assets/DiscoverGray';
import { EvolveGray } from '../../PoweredByMelioFooter/assets/EvolveGray';
import { MasterGray } from '../../PoweredByMelioFooter/assets/MasterGray';
import { QuickbooksGray } from '../../PoweredByMelioFooter/assets/QuickbooksGray';
import { VisaGray } from '../../PoweredByMelioFooter/assets/VisaGray';

const mapTrustedPartnerToLogo: Record<TrustedPartners, React.ComponentType> = {
  Evolve: EvolveGray,
  Mastercard: MasterGray,
  Visa: VisaGray,
  Quickbooks: QuickbooksGray,
  Discover: DiscoverGray,
  Diners: DinersGray,
  Amex: AmexGray,
} as const;

export const TrustedPartnersWidget = forwardRef<unknown, 'div'>((props, ref) => {
  const { trustedPartners } = useConfig().settings;

  return (
    <Group
      data-component="TrustedPartnersWidget"
      variant="vertical"
      alignItems="center"
      spacing="s"
      ref={ref}
      {...props}
    >
      <Container textAlign="center">
        <Text textStyle="body4" color="global.neutral.800">
          <FormattedMessage id="widgets.poweredByMelioFooter.secondLine" />
        </Text>
      </Container>
      <Group alignItems="center">
        {trustedPartners.map((item, index) => {
          const Comp = mapTrustedPartnerToLogo[item];
          return <Comp key={index} />;
        })}
      </Group>
    </Group>
  );
});

TrustedPartnersWidget.displayName = 'TrustedPartnersWidget';
