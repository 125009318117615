import { useNonEntityMutation } from '@melio/api-client';
import { VexAuthenticationApiClient } from '@melio/platform-api-axios-client';

export const useSendEmailValidationCode = () =>
  useNonEntityMutation(VexAuthenticationApiClient.postAuthGuestSendEmailValidationCode, [
    'VexAuthenticationApi',
    'model',
    'me',
    'postAuthGuestSendEmailValidationCode',
  ]);
