import { ExternalLayout, Group, SectionBanner, SelectionCard, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { ExternalLayoutContentWidget, FooterWidget } from '@melio/vex-widgets';
import { ReactElement } from 'react';

export type AcceptPaymentWithVirtualCardScreenProps = {
  payorOrganizationName: string;
  payorEmail: string;
  vendorId: string;
  vendorContactEmail: string;
  contentHeaderTitle?: React.ReactNode;
  onDone: () => Promise<void>;
  isSaving?: boolean;
  onBack?: VoidFunction;
  header?: ReactElement;
};

export const AcceptPaymentWithVirtualCardScreen = forwardRef<AcceptPaymentWithVirtualCardScreenProps, 'div'>(
  (
    {
      payorOrganizationName,
      payorEmail,
      vendorContactEmail,
      onDone,
      onBack,
      isSaving,
      contentHeaderTitle,
      vendorId,
      children: _,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const commonAnalyticsProps = {
      PageName: 'virtual-card',
      DeliveryMethodType: DeliveryMethodType.VirtualCard,
      Intent: 'confirm-virtual-card',
    };

    usePageView('DeliveryMethod', commonAnalyticsProps);

    const { track } = useAnalytics();

    const handleAcceptPayment = async () => {
      track('DeliveryMethod', 'Click', {
        ...commonAnalyticsProps,
        Cta: 'confirm-and-finish',
        LocalVendorId: vendorId,
      });
      await onDone();
    };

    const footer = <FooterWidget />;

    const content = (
      <ExternalLayoutContentWidget
        data-testid="accept-payment-with-virtual-card-screen"
        header={{
          title: contentHeaderTitle,
          backButtonProps: onBack ? { onClick: onBack } : undefined,
        }}
        form={{
          title: formatMessage('vex.screen.acceptPaymentWithVirtualCard.content.title'),
          description: formatMessage('vex.screen.acceptPaymentWithVirtualCard.content.description'),
          spacing: 'l',
          fields: (
            <Group variant="vertical" spacing="l">
              <SectionBanner
                variant="informative"
                title={formatMessage('vex.screen.acceptPaymentWithVirtualCard.posInfoBanner.title')}
                description={formatMessage('vex.screen.acceptPaymentWithVirtualCard.posInfoBanner.description')}
              />
              <Group variant="vertical" spacing="s">
                <Text textStyle="body4SemiUpper" color="global.neutral.900">
                  {formatMessage('vex.screen.acceptPaymentWithVirtualCard.paymentsDetailsSection.title')}
                </Text>
                <SelectionCard
                  descriptionProps={{
                    label: formatMessage(
                      'vex.screen.acceptPaymentWithVirtualCard.paymentsDetailsSection.selectionCard.description',
                      { contactEmail: vendorContactEmail }
                    ),
                  }}
                  icon="credit-card"
                  mainLabelProps={{
                    label: formatMessage(
                      'vex.screen.acceptPaymentWithVirtualCard.paymentsDetailsSection.selectionCard.title'
                    ),
                  }}
                />
                <Text textStyle="body4" color="global.neutral.800">
                  {formatMessage('vex.screen.acceptPaymentWithVirtualCard.paymentsDetailsSection.changeEmailText', {
                    payorOrgNameWithEmailLink: (
                      <a href={`mailto:${payorEmail}`}>
                        <Text textStyle="body4" color="global.brand.700">
                          {payorOrganizationName}
                        </Text>
                      </a>
                    ),
                  })}
                </Text>
              </Group>
            </Group>
          ),
        }}
        continueSection={{
          spacing: 'l',
          buttonProps: {
            label: formatMessage('vex.screen.acceptPaymentWithVirtualCard.continueSection.button'),
            onClick: handleAcceptPayment,
            isLoading: isSaving,
          },
          termsAndPolicyDisclaimer: (
            <Text textStyle="body4" color="global.neutral.800">
              {formatMessage('vex.screen.acceptPaymentWithVirtualCard.continueSection.termsAndPolicyDisclaimer', {
                payorOrgName: payorOrganizationName,
              })}
            </Text>
          ),
        }}
      />
    );

    return (
      <ExternalLayout
        data-component="AcceptPaymentWithVirtualCardScreen"
        ref={ref}
        content={content}
        footer={footer}
        {...props}
      />
    );
  }
);

AcceptPaymentWithVirtualCardScreen.displayName = 'AcceptPaymentWithVirtualCardScreen';
