import { Bill, Payment, PaymentRequest, ScannedInvoice, Vendor } from '@melio/platform-api';

export enum PayDashboardTabs {
  Inbox = 'inbox',
  Scheduled = 'scheduled',
  Paid = 'paid',
}

export enum PayDashboardSectionEnum {
  error = 'error',
  overdue = 'overdue',
  pendingInfo = 'pendingInfo',
  thisMonth = 'thisMonth',
  thisWeek = 'thisWeek',
  older = 'older',
  later = 'later',
}

export type GroupItem = ScannedInvoiceGroupItem | BillGroupItem | PaymentRequestGroupItem | PaymentGroupItem;

export enum GroupItemType {
  PAID,
  SCHEDULED,
  FAILED,
  UNPAID,
  SCANNED_INVOICE,
  PAYMENT_REQUEST,
}

export type ScannedInvoiceGroupItem = {
  type: Extract<GroupItemType, GroupItemType.SCANNED_INVOICE>;
  scannedInvoice: ScannedInvoice;
};

export type BillGroupItem = {
  type: Extract<GroupItemType, GroupItemType.UNPAID>;
  bill: Bill;
  vendor?: Vendor;
};

export type PaymentRequestGroupItem = {
  type: Extract<GroupItemType, GroupItemType.PAYMENT_REQUEST>;
  paymentRequest: PaymentRequest;
};

export type PaymentGroupItem = {
  type: Exclude<GroupItemType, GroupItemType.UNPAID | GroupItemType.SCANNED_INVOICE>;
  payment: Payment;
  vendor?: Vendor;
  isVirtualDeliverymethod?: boolean;
};

type BaseGroupType = {
  items: GroupItem[];
};

export type PayDashboardScheduledTabGroup = {
  tab: PayDashboardTabs.Scheduled;
  section:
    | PayDashboardSectionEnum.pendingInfo
    | PayDashboardSectionEnum.thisWeek
    | PayDashboardSectionEnum.thisMonth
    | PayDashboardSectionEnum.later;
  items: PaymentGroupItem[];
};

export type PayDashboardPaidTabGroup = {
  tab: PayDashboardTabs.Paid;
  section: PayDashboardSectionEnum.thisWeek | PayDashboardSectionEnum.older;
  items: PaymentGroupItem[];
};

export type PayDashboardInboxTabGroup = {
  tab: PayDashboardTabs.Inbox;
  section:
    | PayDashboardSectionEnum.error
    | PayDashboardSectionEnum.overdue
    | PayDashboardSectionEnum.thisWeek
    | PayDashboardSectionEnum.later;
} & BaseGroupType;

export type PayDashboardTabGroups = {
  [PayDashboardTabs.Inbox]: PayDashboardInboxTabGroup[];
  [PayDashboardTabs.Scheduled]: PayDashboardScheduledTabGroup[];
  [PayDashboardTabs.Paid]: PayDashboardPaidTabGroup[];
};

export const isScannedInvoiceGroupItem = (item: GroupItem): item is ScannedInvoiceGroupItem => 'scannedInvoice' in item;

export const isBillGroupItem = (item: GroupItem): item is BillGroupItem => 'bill' in item;

export const isPaymentRequestGroupItem = (item: GroupItem): item is PaymentRequestGroupItem => 'paymentRequest' in item;

export const isPaymentGroupItem = (item: GroupItem): item is PaymentGroupItem => 'payment' in item;

export type PaymentType = 'singlePayment' | 'recurringPayment';
export type SelectedEntity = 'payment' | 'subscription' | null;
