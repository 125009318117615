import React, { createContext, useContext } from 'react';

type SessionConfigProps = {
  setAccessTokens: (accessToken: string, refreshToken: string | null) => void;
  overrideSessionExpiredRoute: (sessionExpiredRoute: string) => void;
  accessToken: string | undefined;
};
const SessionConfigContext = createContext<SessionConfigProps>({} as SessionConfigProps);

export const SessionConfigProvider: React.FC<SessionConfigProps> = ({
  children,
  accessToken,
  setAccessTokens,
  overrideSessionExpiredRoute,
}) => (
  <SessionConfigContext.Provider value={{ setAccessTokens, overrideSessionExpiredRoute, accessToken }}>
    {children}
  </SessionConfigContext.Provider>
);

export const useSessionConfig = () => useContext(SessionConfigContext);
