import { ExternalLayout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethodType, useVexMyOrganization, useVexMyOrganizations } from '@melio/platform-api';
import { ReactElement, ReactNode } from 'react';

import { AcceptPaymentWithBankAccountDeliveryMethodParams } from '../../types';
import { AcceptPaymentWithBankAccountWizard } from './AcceptPaymentWithBankAccount.wizard';

type AcceptPaymentWithBankAccountActivityProps = {
  contentHeaderTitle: ReactNode;
  onAcceptPayment: (params: AcceptPaymentWithBankAccountDeliveryMethodParams) => Promise<void>;
  cancelUrlFallback: string;
  payorOrganizationName: string;
  paymentId: string;
  header: ReactElement | undefined;
};

export const AcceptPaymentWithBankAccountActivity = withAnalyticsContext<AcceptPaymentWithBankAccountActivityProps>(
  ({
    contentHeaderTitle,
    onAcceptPayment,
    cancelUrlFallback,
    payorOrganizationName,
    paymentId,
    setAnalyticsProperties,
    header,
  }) => {
    setAnalyticsProperties({ PaymentId: paymentId, DeliveryMethodType: DeliveryMethodType.BankAccount });

    const {
      data: organizationData,
      isFetching: isLoadingOrganizationData,
      refetch: refetchOrganizationData,
      isGuest,
    } = useVexMyOrganization();

    const { data: userOrganizations, isLoading: isLoadingUserOrganizations } = useVexMyOrganizations({
      enabled: !isGuest,
    });

    if (isLoadingOrganizationData || isLoadingUserOrganizations) {
      return <ExternalLayout isLoading />;
    }

    return (
      <AcceptPaymentWithBankAccountWizard
        onAcceptPayment={onAcceptPayment}
        cancelUrlFallback={cancelUrlFallback}
        contentHeaderTitle={contentHeaderTitle}
        organizationId={organizationData?.id}
        payorOrganizationName={payorOrganizationName}
        refetchOrganizationData={refetchOrganizationData}
        isFetchingOrganizationData={isLoadingOrganizationData}
        organizations={userOrganizations}
        header={header}
      />
    );
  }
);
