import { Card, Group, Icon, Text } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { getDeliveryMethodIconKey } from '@melio/vex-domain';

export type BankAccountCardWidgetProps = {
  isPlaidAccount: boolean;
  accountNumber: string | undefined;
  routingNumber: string | undefined;
  rightElement?: React.ReactNode;
};

export const BankAccountCardWidget = forwardRef<BankAccountCardWidgetProps, 'div'>(
  ({ isPlaidAccount, accountNumber, routingNumber, rightElement, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const cardDescription = isPlaidAccount
      ? formatMessage('vex.widgets.bankAccountCard.plaidDescription')
      : formatMessage('vex.widgets.bankAccountCard.bankDetailsDescription', {
          accountNumberLast4Digits: accountNumber?.slice(-4),
          routingNumberLast4Digits: routingNumber?.slice(-4),
        });

    return (
      <Card variant="flat" {...props} ref={ref} data-component="BankAccountCardWidget">
        <Group justifyContent="space-between" width="full">
          <Group alignItems="center">
            <Icon type={getDeliveryMethodIconKey(DeliveryMethodType.BankAccount)} />
            <Group variant="vertical" spacing="xxs">
              <Text textStyle="body2Semi">{formatMessage('vex.widgets.bankAccountCard.title')}</Text>
              <Text textStyle="body4" color="global.neutral.800">
                {cardDescription}
              </Text>
            </Group>
          </Group>
          {rightElement}
        </Group>
      </Card>
    );
  }
);

BankAccountCardWidget.displayName = 'BankAccountCardWidget';
