import { useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import { CardHolderDetails, CardParams, Payment, UpdatePaymentPayload, useDeliveryMethods } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';

export type UsePushToDebitActivityCallbacksProps = {
  vaulting: 'TBT' | 'Tabapay';
  onError?: ErrorFunction;
  cardDetails?: CardParams;
  payment?: Payment;
  updatePayment: (data: UpdatePaymentPayload) => Promise<Payment>;
  onCreateVendorDeliveryMethodDone: (id: string) => void;
};

export const usePushToDebitActivityCallbacks = ({
  vaulting,
  onError,
  cardDetails,
  payment,
  updatePayment,
  onCreateVendorDeliveryMethodDone,
}: UsePushToDebitActivityCallbacksProps) => {
  const { create: createVendorDeliveryMethod, isMutating: isCreatingDeliveryMethod } = useDeliveryMethods({
    vendorId: payment?.vendorId,
    enabled: false,
  });

  useAnalyticsContext({ globalProperties: { Vaulting: vaulting } });
  const { createTrackHandler } = useAnalytics();

  const trackRequest = createTrackHandler<{ Status: 'succeeded' | 'failed' }>(
    'PushToDebitAddCardHolderDetails',
    'Submitted'
  );

  const { toast } = useToast();

  const [isCardError, cardError] = useBoolean(false);

  const isCardValidationModalOpen = isCardError;
  const onCardValidationModalDone = cardError.off;

  const showVerificationErrorDialog = (error: PlatformError) => {
    cardError.on();
    onError?.(error);
  };

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const createDeliveryMethod = (data: CardHolderDetails) => {
    if (cardDetails) {
      const { firstName, lastName, ...address } = data;
      const details = { ...cardDetails, address, cardOwner: { firstName, lastName } };
      createVendorDeliveryMethod({ type: 'card', details })
        .then(({ id }) =>
          updatePayment({
            deliveryMethodId: id,
          })
        )
        .then((payment) =>
          trackRequest({ Status: 'succeeded' }, () => {
            onCreateVendorDeliveryMethodDone(payment.deliveryMethodId);
          })
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .catch((...args) => trackRequest({ Status: 'failed' }, () => handleFail(...args)));
    }
  };

  return {
    showVerificationErrorDialog,
    createDeliveryMethod,
    isCardValidationModalOpen,
    onCardValidationModalDone,
    isCreatingDeliveryMethod,
  };
};
