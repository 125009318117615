import React from 'react';

export const Sent: React.VFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="none" viewBox="0 0 96 96">
    <g clipPath="url(#clip0_8258_590)">
      <path
        fill="#98D5EC"
        fillRule="evenodd"
        d="M48 84c-19.882 0-36-16.118-36-36s16.118-36 36-36 36 16.118 36 36-16.118 36-36 36z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_8258_590"
        style={{ maskType: 'alpha' }}
        width="72"
        height="72"
        x="12"
        y="12"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M48 84c-19.882 0-36-16.118-36-36s16.118-36 36-36 36 16.118 36 36-16.118 36-36 36z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_8258_590)">
        <path
          fill="#fff"
          d="M44.666 39.684a3.06 3.06 0 01-3.05 3.07H26.581v-.016a2.028 2.028 0 01-1.914-2.031c0-1.128.912-2.046 2.033-2.046.252 0 .482.052.705.134.378-1.255 1.529-2.18 2.902-2.18.2 0 .393.022.579.06.534-2.249 2.537-3.922 4.935-3.922 2.426 0 4.445 1.71 4.957 3.996.267-.075.549-.135.838-.135a3.06 3.06 0 013.05 3.07zM70.823 55.974a2.88 2.88 0 01-2.87 2.89H53.802v-.015A1.909 1.909 0 0152 56.937c0-1.061.86-1.925 1.914-1.925.237 0 .454.049.663.126.357-1.18 1.44-2.052 2.731-2.052.189 0 .37.02.545.056.503-2.116 2.389-3.69 4.645-3.69 2.284 0 4.183 1.61 4.665 3.76.252-.07.517-.126.79-.126a2.875 2.875 0 012.87 2.888z"
        ></path>
        <path
          fill="#8AB0C7"
          d="M32.314 70.32a.7.7 0 01-.493-.204.686.686 0 010-.978l4.486-4.485a.686.686 0 01.977 0 .686.686 0 010 .977l-4.486 4.486a.684.684 0 01-.484.204zm-5.926-6.114l4.827-4.827a.686.686 0 000-.977.686.686 0 00-.977 0l-4.827 4.826a.686.686 0 000 .978c.136.136.31.204.492.204a.753.753 0 00.485-.204zm0 5.918l5.918-5.918a.686.686 0 000-.978.686.686 0 00-.977 0l-5.918 5.918a.686.686 0 000 .978c.136.136.31.204.492.204a.754.754 0 00.485-.204z"
        ></path>
        <path
          fill="#018BBB"
          d="M36.563 37.19c4.56-3.014 11.866-3.982 15.733-4.291.945-.075 1.425 1.029.754 1.7l-12.67 12.67a1.055 1.055 0 01-.892.299l-9.87-1.371c-.805-.112-1.18-1.043-.657-1.665 1.655-1.968 4.812-5.498 7.602-7.341z"
        ></path>
        <path
          fill="#273B49"
          d="M58.295 59.51c3.014-4.56 3.983-11.866 4.292-15.732.075-.946-1.03-1.425-1.7-.755l-12.67 12.67a1.055 1.055 0 00-.299.892l1.371 9.87c.112.805 1.043 1.18 1.665.657 1.967-1.655 5.498-4.812 7.341-7.601z"
        ></path>
        <path
          fill="#F4F4F4"
          d="M57.752 28.575c-5.592 2.108-11.908 5.308-16.592 9.99-7.183 7.182-9.448 16.564-5.103 21.033 4.465 4.344 13.856 2.08 21.04-5.101 4.678-4.677 7.873-10.98 9.982-16.563l-9.327-9.36z"
        ></path>
        <path
          fill="#018BBB"
          d="M57.689 28.534s.667-.385 4.563-1.591c2.178-.672 4.54-1.224 6.195-1.574a1.549 1.549 0 011.85 1.78c-.47 2.758-1.465 7.515-3.177 10.968l-9.431-9.583z"
        ></path>
        <path
          fill="#D7D7D7"
          d="M38.962 61.328c4.924 1.617 12.265-.965 18.135-6.83 4.677-4.676 7.877-10.976 9.982-16.564l-.788-.788c-4.464 8.594-12.994 20.1-27.329 24.182z"
        ></path>
        <path
          fill="#018BBB"
          d="M31.833 54.679a2.11 2.11 0 00.464 2.287l3.108 3.108 3.109 3.11c.6.599 1.499.781 2.284.463l4.936-1.997-5.953-5.953-5.946-5.946-2.002 4.928z"
        ></path>
        <path
          fill="#273B49"
          d="M40.268 63.863l5.47-2.211-2.023-2.024a37.817 37.817 0 01-6.576 2.177l1.788 1.788c.353.353.882.459 1.341.27zM67.12 38.117c1.712-3.453 2.706-8.212 3.177-10.97-.2 1.035-1.435 5.07-4.047 10.082l.87.882v.006z"
        ></path>
        <path
          fill="#33B1DD"
          fillRule="evenodd"
          d="M33.326 49.2a.62.62 0 01.878 0l12.081 12.08a.62.62 0 11-.877.879L33.325 50.077a.62.62 0 010-.878zM57.086 27.855a.62.62 0 01.878 0l9.666 9.666a.62.62 0 11-.878.878l-9.666-9.666a.62.62 0 010-.878z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#B3CDDE"
          d="M57.1 35.166a4.562 4.562 0 00-5.58 3.221 4.562 4.562 0 003.221 5.58 4.562 4.562 0 005.58-3.221 4.562 4.562 0 00-3.221-5.58z"
        ></path>
        <path
          fill="#255F82"
          fillRule="evenodd"
          d="M51.82 35.466a5.803 5.803 0 018.2 0 5.803 5.803 0 010 8.2 5.803 5.803 0 01-8.2 0 5.803 5.803 0 010-8.2zm.878.878a4.562 4.562 0 016.444 0 4.562 4.562 0 010 6.444 4.562 4.562 0 01-6.444 0 4.562 4.562 0 010-6.444z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M56.72 36.146c.326.565-.81.748-1.959 1.413-1.148.663-1.876 1.555-2.202.99-.326-.565.264-1.693 1.413-2.358 1.148-.665 2.42-.608 2.748-.045z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_8258_590">
        <path fill="#fff" d="M0 0H72V72H0z" transform="translate(12 12)"></path>
      </clipPath>
    </defs>
  </svg>
);
