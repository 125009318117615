import { createContext, useContext } from 'react';

import { CreateConfig } from '../../hooks/useActivityConfigData';
import { isPartnerMelio } from '../../shared/partner';

export type ActivityConfig = {
  showPoweredByMelio: boolean;
  showNewAddressFlow: boolean;
  showLoginHeaderSection: boolean;
  emailVerificationReturnUrl: string;
};
export const createActivityConfig: CreateConfig<ActivityConfig> = (appConfig) => {
  const { isEmailLoginEnabled } = appConfig.vex.unilateral;
  const showPoweredByMelio = !isPartnerMelio();

  return {
    showPoweredByMelio,
    showNewAddressFlow: false,
    showLoginHeaderSection: isEmailLoginEnabled ?? false,
    emailVerificationReturnUrl: '../accept',
  };
};

export const AddDeliveryMethodByUnilateralActivityConfigContext = createContext<ActivityConfig>({} as ActivityConfig);

export const useActivityContext = () => useContext<ActivityConfig>(AddDeliveryMethodByUnilateralActivityConfigContext);
