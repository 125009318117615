/* eslint-disable react-hooks/exhaustive-deps */
import { CardChangeEvent, ChangeEvent } from '@basis-theory/basis-theory-js/types/elements';
import type {
  CardExpirationDateElement as ICardExpirationDateElement,
  CardNumberElement as ICardNumberElement,
  CardVerificationCodeElement as ICardVerificationCodeElement,
} from '@basis-theory/basis-theory-react/types';
import { Form, Group, LoadingContainer, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { CardBrand } from '@melio/platform-provider';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { TBTCardBrandLogosWidget } from '../TBTCardBrandLogos';
import { TBTCardNumberFieldWidget } from '../TBTCardNumberField';
import { TBTExpirationDateFieldWidget } from '../TBTExpirationDateField';
import { TBTVerificationCodeFieldWidget } from '../TBTVerificationCodeField';
import { AdBlockerErrorBannerComponent } from './components';
import { TBTFormWidgetFields, TBTFormWidgetProps } from './types';

export const TBTFormWidget = forwardRef<TBTFormWidgetProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, onReady, size, isDisabled, onRenderError, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const [cardNumberEvent, setCardNumberEvent] = useState<CardChangeEvent>();
    const [cardExpirationEvent, setCardExpirationEvent] = useState<ChangeEvent>();
    const [cardVerificationCodeEvent, setCardVerificationCodeEvent] = useState<ChangeEvent>();

    const [shouldShowErrorOnEmpty, showErrorOnEmpty] = useBoolean();

    const [isCardNumberReady, cardNumberReady] = useBoolean();
    const [isExpirationDateReady, expirationDateReady] = useBoolean();
    const [isVerificationCodeReady, verificationCodeReady] = useBoolean();

    const [isLoading, loading] = useBoolean(true);

    useEffect(() => {
      if (isCardNumberReady && isExpirationDateReady && isVerificationCodeReady) {
        loading.off();
        onReady?.();
      }
    }, [isCardNumberReady, isExpirationDateReady, isVerificationCodeReady]);

    const cardNumberRef = useRef<ICardNumberElement>(null);
    const cardExpirationRef = useRef<ICardExpirationDateElement>(null);
    const cardVerificationRef = useRef<ICardVerificationCodeElement>(null);

    const submit = async () => {
      if (cardNumberEvent?.complete && cardExpirationEvent?.complete && cardVerificationCodeEvent?.complete) {
        const result = {
          cardNumber: cardNumberRef.current,
          cardExpiration: cardExpirationRef.current,
          cardVerificationCode: cardVerificationRef.current,
        } as TBTFormWidgetFields;

        await onSubmit(result);
      } else {
        showErrorOnEmpty.on();
      }
    };

    const { formProps, registerField } = useMelioForm<TBTFormWidgetFields>({
      onSubmit: submit,
      isSaving,
      onSubmissionStateChange,
    });

    const { error: cardNumberFormError, ...cardNumberRegisterField } = registerField('cardNumber');
    const { error: cardExpirationFormError, ...cardExpirationRegisterField } = registerField('cardExpiration');
    const { error: cardVerificationCodeFormError, ...cardVerificationCodeRegisterField } =
      registerField('cardVerificationCode');

    return (
      <Group data-component="TBTFormWidget" {...props} variant="vertical" spacing={size === 'large' ? 'xxl' : 'xl'}>
        <ErrorBoundary fallback={<AdBlockerErrorBannerComponent />} onError={onRenderError}>
          <TBTCardBrandLogosWidget selectedBrand={cardNumberEvent?.cardBrand as CardBrand} />
          <LoadingContainer isLoading={isLoading}>
            <Form {...formProps} columns={2} ref={ref} size={size}>
              <TBTCardNumberFieldWidget
                {...cardNumberRegisterField}
                colSpan={2}
                name="cardNumber"
                labelProps={{ label: formatMessage('vex.widgets.tbtForm.cardNumber.label') }}
                aria-label={formatMessage('vex.widgets.tbtForm.cardNumber.label')}
                placeholder={formatMessage('vex.widgets.tbtForm.cardNumber.placeholder')}
                isRequired
                isDisabled={isDisabled}
                onReady={cardNumberReady.on}
                onChange={(event) => {
                  setCardNumberEvent(event as CardChangeEvent);
                  showErrorOnEmpty.off();
                }}
                error={
                  cardNumberFormError ||
                  (shouldShowErrorOnEmpty && cardNumberEvent?.empty
                    ? { message: formatMessage('vex.widgets.tbtForm.cardNumber.validation.required') }
                    : undefined)
                }
                id={uniqueId('cardNumber')}
                ref={cardNumberRef}
              />
              <TBTExpirationDateFieldWidget
                {...cardExpirationRegisterField}
                name="cardExpiration"
                labelProps={{ label: formatMessage('vex.widgets.tbtForm.cardExpiration.label') }}
                aria-label={formatMessage('vex.widgets.tbtForm.cardExpiration.label')}
                placeholder={formatMessage('vex.widgets.tbtForm.cardExpiration.placeholder')}
                isRequired
                isDisabled={isDisabled}
                onReady={expirationDateReady.on}
                onChange={(event) => {
                  setCardExpirationEvent(event);
                  showErrorOnEmpty.off();
                }}
                error={
                  cardExpirationFormError ||
                  (shouldShowErrorOnEmpty && cardExpirationEvent?.empty
                    ? { message: formatMessage('vex.widgets.tbtForm.cardExpiration.validation.required') }
                    : undefined)
                }
                id={uniqueId('cardExpiration')}
                ref={cardExpirationRef}
              />
              <TBTVerificationCodeFieldWidget
                {...cardVerificationCodeRegisterField}
                name="cardVerificationCode"
                labelProps={{ label: formatMessage('vex.widgets.tbtForm.cardVerificationCode.label') }}
                aria-label={formatMessage('vex.widgets.tbtForm.cardVerificationCode.label')}
                placeholder={formatMessage('vex.widgets.tbtForm.cardVerificationCode.placeholder')}
                isRequired
                isDisabled={isDisabled}
                onReady={verificationCodeReady.on}
                onChange={(event) => {
                  setCardVerificationCodeEvent(event);
                  showErrorOnEmpty.off();
                }}
                error={
                  cardVerificationCodeFormError ||
                  (shouldShowErrorOnEmpty && cardVerificationCodeEvent?.empty
                    ? { message: formatMessage('vex.widgets.tbtForm.cardVerificationCode.validation.required') }
                    : undefined)
                }
                id={uniqueId('cardVerificationCode')}
                ref={cardVerificationRef}
              />
            </Form>
          </LoadingContainer>
        </ErrorBoundary>
      </Group>
    );
  }
);

TBTFormWidget.displayName = 'TBTFormWidget';
