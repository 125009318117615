import { Button, Container, Divider, Form, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { FormWidgetProps, W9FileFormModel } from '../../types';
import { useUploadW9FileSchema } from './useCollectW9Schema';

export type CollectW9FileWidgetProps = {
  onTaxPayerButtonClicked: () => void;
  isLoadingZenworkUrl: boolean;
} & FormWidgetProps<W9FileFormModel>;

export const CollectW9FileWidget = forwardRef<CollectW9FileWidgetProps, 'form'>(
  ({ isSaving, onSubmit, onTaxPayerButtonClicked, isLoadingZenworkUrl, onSubmissionStateChange, ...props }, ref) => {
    const schema = useUploadW9FileSchema();
    const { formatMessage } = useMelioIntl();
    const { registerField, formProps, submitButtonProps } = useMelioForm<W9FileFormModel>({
      onSubmit,
      isSaving,
      schema,
      onSubmissionStateChange,
      defaultValues: {
        w9file: null,
      },
    });

    return (
      <Form data-component="CollectW9FileWidget" {...props} {...formProps} ref={ref}>
        <Container paddingY="s">
          <Button
            size="large"
            data-testid="tax-payer-button"
            onClick={onTaxPayerButtonClicked}
            isFullWidth
            isLoading={isLoadingZenworkUrl}
            label={formatMessage('vex.widgets.collectW9File.button.title')}
          />
        </Container>
        <Divider label={formatMessage('vex.widgets.collectW9File.divider.text')} />
        <Text>{formatMessage('vex.widgets.collectW9File.uploadFile.title')}</Text>
        <Form.FileUpload
          placeholder={formatMessage('vex.widgets.collectW9File.uploadFile.placeholder')}
          data-testid="upload-w9file-input"
          {...registerField('w9file')}
          acceptTypes={['pdf', 'jpg', 'png']}
        />
        <Button
          {...submitButtonProps}
          variant="secondary"
          size="large"
          data-testid="upload-w9file-submit-button"
          label={formatMessage('vex.widgets.collectW9File.uploadFile.button')}
        />
      </Form>
    );
  }
);

CollectW9FileWidget.displayName = 'CollectW9FileWidget';
