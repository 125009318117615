import { useNonEntityMutation } from '@melio/api-client';
import {
  ApiKeys,
  ApiRequest,
  PostVexExchangeResponse,
  VexAuthenticationApiClient,
} from '@melio/platform-api-axios-client';

type PostVexAuthExchangeFn = ApiRequest<PostVexExchangeResponse>;

export const useExchangeVexActionToken = () =>
  useNonEntityMutation<PostVexAuthExchangeFn, string, PostVexExchangeResponse>(
    (token: string) => VexAuthenticationApiClient.postVexAuthExchange({ token }),
    [ApiKeys.VexAuthenticationApi, 'collection', 'postVexAuthExchange']
  );
