import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MelioSmallIcon = (props: IconProps) => (
  <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.58576 2.30965C7.51494 2.23145 7.43488 2.16228 7.34251 2.11265C6.94222 1.90062 6.48342 2.00137 6.21246 2.30213L3.51973 5.28262C3.27802 5.5503 3.25493 5.93827 3.48124 6.24354C3.6275 6.44053 3.87384 6.5458 4.12325 6.5458H9.66881C9.91976 6.5458 10.1676 6.44054 10.3139 6.24053C10.5371 5.93526 10.5125 5.5503 10.2708 5.28262L7.58576 2.30965Z"
      fill="#212124"
    />
    <path
      d="M26.8783 33.8208H21.3323C21.0829 33.8208 20.8365 33.9246 20.6902 34.1231C20.4639 34.4283 20.487 34.8163 20.7287 35.084L23.414 38.057C23.4848 38.1352 23.5649 38.2043 23.6573 38.254C24.0576 38.466 24.5164 38.3652 24.7874 38.0645L27.4804 35.084C27.7221 34.8178 27.7452 34.4329 27.5235 34.1261C27.3772 33.9261 27.1293 33.8208 26.8783 33.8208Z"
      fill="#212124"
    />
    <path
      d="M9.07513 9.14355H4.71772C4.48637 9.14355 4.29883 9.32528 4.29883 9.54943V30.1679C4.29883 30.3921 4.48637 30.5738 4.71772 30.5738H9.07513C9.30648 30.5738 9.49403 30.3921 9.49403 30.1679V9.54943C9.49403 9.32528 9.30648 9.14355 9.07513 9.14355Z"
      fill="#212124"
    />
    <path
      d="M34.5898 15.4319C34.0074 14.0702 33.1951 12.8584 32.1783 11.8272C31.16 10.7974 29.946 9.97484 28.5708 9.38432C27.1941 8.7938 25.6905 8.49414 24.1047 8.49414C22.519 8.49414 21.0154 8.7938 19.6387 9.38432C18.262 9.97484 17.048 10.7974 16.0312 11.8272C15.0143 12.8554 14.2035 14.0688 13.6197 15.4319C13.0359 16.7951 12.7402 18.2832 12.7402 19.8579C12.7402 21.4326 13.0359 22.9295 13.6197 24.3073C14.2035 25.6852 15.0143 26.8985 16.0312 27.9136C17.048 28.9272 18.262 29.7424 19.6387 30.3329C21.0154 30.9235 22.519 31.2231 24.1047 31.2231C25.6905 31.2231 27.1941 30.9235 28.5708 30.3329C29.9475 29.741 31.1614 28.9272 32.1783 27.9136C33.1966 26.8971 34.0074 25.6837 34.5898 24.3073C35.1736 22.9295 35.4692 21.4326 35.4692 19.8579C35.4692 18.2832 35.1736 16.7951 34.5898 15.4319ZM17.9708 19.8579C17.9708 18.9045 18.1171 18.0129 18.4038 17.2079C18.6905 16.4015 19.1011 15.7052 19.6237 15.1367C20.1449 14.5682 20.7944 14.1128 21.5499 13.7823C22.307 13.4518 23.167 13.2844 24.1047 13.2844C25.0424 13.2844 25.9025 13.4518 26.6595 13.7823C27.4166 14.1128 28.0646 14.5682 28.5857 15.1367C29.1068 15.7052 29.5175 16.4015 29.8056 17.2079C30.0923 18.0158 30.2387 18.9075 30.2387 19.8579C30.2387 20.8083 30.0938 21.7014 29.8056 22.5079C29.5175 23.3143 29.1068 24.0121 28.5857 24.5791C28.0646 25.1476 27.4166 25.6029 26.6595 25.932C25.904 26.2625 25.0454 26.4299 24.1047 26.4299C23.164 26.4299 22.3055 26.2625 21.5499 25.932C20.7929 25.6015 20.1449 25.1461 19.6237 24.5791C19.1011 24.0106 18.6905 23.3143 18.4038 22.5079C18.1156 21.7029 17.9708 20.8112 17.9708 19.8579Z"
      fill="#212124"
    />
  </Icon>
);
