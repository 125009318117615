import { UseCollectionApiProps } from '@melio/api-client';
import { UserPreference, UserPreferencesApiClient } from '@melio/platform-api-axios-client';

import { useUserPreferences } from './useUserPreferences';

type Props = UseCollectionApiProps<typeof UserPreferencesApiClient.getUserPreferences, UserPreference> & {
  id?: string;
};

export const useUserPreference = (props: Props = {}) => {
  const { enabled = true } = props;
  const { data, ...rest } = useUserPreferences({
    ...props,
    enabled: enabled && !!props.id,
  });

  return {
    ...rest,
    data: data?.find((item) => item.key === props.id),
  };
};
