import { Loader } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  APIErrorWithErrorCode,
  BankAccountDeliveryMethod,
  DeliveryMethodType,
  useAccount,
  useOrganizationDeliveryMethods,
  useVexMyOrganization,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { CollapsibleCardFormWidget } from '@melio/vex-widgets';
import { useEffect, useState } from 'react';

import { isPlaidAccount, VendorOnboardingCardsEnum } from '../../shared';
import { ReviewDetailsErrorTypes } from './consts';
import { ReviewDetailsCardScreen } from './screens/ReviewDetailsCard.screen';
import { cardToAnalyticIntent, extractReviewDetailsErrorTypeFromError } from './utils';

export type ReviewDetailsCardActivityProps = {
  isOpened?: boolean;
  onEditSection: (onboardingCardType: VendorOnboardingCardsEnum) => void;
  onOpen: () => void;
  onSubmit: () => Promise<void>;
  isDisabled?: boolean;
};

export const ReviewDetailsCardActivity = withAnalyticsContext<ReviewDetailsCardActivityProps>(
  forwardRef(({ onOpen, isOpened, isDisabled, onEditSection, onSubmit, setAnalyticsProperties }, ref) => {
    const { formatMessage } = useMelioIntl();
    const [reviewDetailsErrorType, setReviewDetailsErrorType] = useState<ReviewDetailsErrorTypes | undefined>();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'review-account-details',
    });

    const { data: organizationData, isFetching: isLoadingOrganization } = useVexMyOrganization({
      enabled: isOpened,
    });

    const { data: accountData, isLoading: isLoadingAccount } = useAccount({ id: 'me', enabled: isOpened });

    const organizationId = organizationData?.id;

    const { data: organizationDeliveryMethods, isLoading: isLoadingDeliveryMethods } = useOrganizationDeliveryMethods({
      organizationId,
      options: { enabled: isOpened },
    });

    const isLoading = isLoadingOrganization || isLoadingDeliveryMethods || isLoadingAccount;

    useEffect(() => {
      setReviewDetailsErrorType(undefined);

      if (isOpened) {
        track('Organization', 'View');
      }
    }, [isOpened, track]);

    const onSubmitReviewDetails = async () => {
      try {
        await onSubmit();
        track('Organization', 'Click', { Cta: 'submit' });
      } catch (error) {
        const reviewDetailsErrorType = extractReviewDetailsErrorTypeFromError(error as APIErrorWithErrorCode);
        setReviewDetailsErrorType(reviewDetailsErrorType);

        track('Organization', 'Click', { ErrorType: 'general-error', Status: 'failure' });
      }
    };

    const bankAccountDeliveryMethod = organizationDeliveryMethods?.find(
      ({ type }) => type === DeliveryMethodType.BankAccount
    ) as BankAccountDeliveryMethod | undefined;

    const bankAccountDetails = bankAccountDeliveryMethod
      ? {
          isPlaidAccount: isPlaidAccount(bankAccountDeliveryMethod),
          accountNumber: bankAccountDeliveryMethod.details.accountNumber,
          routingNumber: bankAccountDeliveryMethod.details.routingNumber,
        }
      : undefined;

    const shouldShowReviewCard =
      isOpened && accountData && organizationData && bankAccountDeliveryMethod && bankAccountDetails && !isLoading;

    const onEditSectionClick = (onboardingCardType: VendorOnboardingCardsEnum) => {
      onEditSection(onboardingCardType);
      track('Organization', 'Click', {
        Intent: cardToAnalyticIntent(onboardingCardType),
        Cta: 'edit',
      });
    };

    return (
      <CollapsibleCardFormWidget
        title={formatMessage('vex.activities.reviewDetailsCard.title')}
        isOpened={isOpened}
        onOpen={onOpen}
        isDisabled={isDisabled}
        ref={ref}
      >
        {isLoading && <Loader />}
        {shouldShowReviewCard && (
          <ReviewDetailsCardScreen
            onEditSection={onEditSectionClick}
            bankAccountDetails={bankAccountDetails}
            account={accountData}
            onSubmit={onSubmitReviewDetails}
            error={reviewDetailsErrorType}
          />
        )}
      </CollapsibleCardFormWidget>
    );
  })
);

ReviewDetailsCardActivity.displayName = 'ReviewDetailsCardActivity';
