export const NetworkError: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_132318)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
        fill="#C1E7F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.8939 102.599C88.55 115.88 73.4527 122.315 60.172 116.971C46.8912 111.627 40.4566 96.5299 45.8004 83.2491C51.1442 69.9683 66.2416 63.5337 79.5212 68.8775C92.802 74.2214 99.2366 89.3187 93.8939 102.599Z"
        fill="#33B1DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.855 78.0677C60.2966 81.9416 55.8923 83.8176 52.0195 82.2591C48.1455 80.7007 46.2685 76.2975 47.828 72.4236C49.3864 68.5496 53.7896 66.6736 57.6635 68.2332C61.5364 69.7905 63.4135 74.1937 61.855 78.0677Z"
        fill="#33B1DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1557 91.573C97.8845 94.732 94.2935 96.2624 91.1334 94.9912C87.9744 93.7201 86.4441 90.129 87.7152 86.969C88.9864 83.811 92.5774 82.2807 95.7364 83.5508C98.8965 84.8219 100.427 88.4129 99.1557 91.573Z"
        fill="#33B1DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9439 80.1419L59.8741 65.6288L91.9674 32.5873C93.3141 31.2006 95.6664 32.1467 95.6772 34.0799L95.9439 80.1419Z"
        fill="#FFDC24"
      />
      <mask
        id="mask0_11475_132318"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="60"
        y="35"
        width="36"
        height="40"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.352 35.2798L60.12 65.3776V65.7285L81.0753 74.1598H95.9091L95.6848 35.2798H89.352Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_132318)">
        <path fillRule="evenodd" clipRule="evenodd" d="M60.12 65.5197H101.16V61.1997H60.12V65.5197Z" fill="#F0C501" />
        <path fillRule="evenodd" clipRule="evenodd" d="M60.12 74.1598H101.16V69.8398H60.12V74.1598Z" fill="#F0C501" />
        <path fillRule="evenodd" clipRule="evenodd" d="M60.12 48.2399H101.16V43.9199H60.12V48.2399Z" fill="#F0C501" />
        <path fillRule="evenodd" clipRule="evenodd" d="M60.12 56.8796H101.16V52.5596H60.12V56.8796Z" fill="#F0C501" />
        <path fillRule="evenodd" clipRule="evenodd" d="M60.12 39.5998H101.16V35.2798H60.12V39.5998Z" fill="#F0C501" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.8329 84.3512L57.7608 69.4353C56.3773 68.8791 55.7066 67.3067 56.2639 65.9232C56.8201 64.5386 58.3926 63.869 59.7761 64.4263L96.8482 79.3411C98.2316 79.8973 98.9023 81.4709 98.345 82.8533C97.7888 84.2378 96.2164 84.9074 94.8329 84.3512Z"
          fill="#F0C501"
        />
      </g>
      <mask
        id="mask1_11475_132318"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 18C42.1769 18 18 42.1769 18 72C18 101.823 42.1769 126 72 126C101.823 126 126 101.823 126 72C126 42.1769 101.823 18 72 18Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_11475_132318)">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 126H126V99H18V126Z" fill="#004977" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.26 99.0001H40.14C38.6485 99.0001 37.44 97.7916 37.44 96.3001C37.44 94.8086 38.6485 93.6001 40.14 93.6001H82.26C83.7515 93.6001 84.96 94.8086 84.96 96.3001C84.96 97.7916 83.7515 99.0001 82.26 99.0001Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.12 110.88C61.9096 110.88 63.36 109.43 63.36 107.64V101.158C63.36 99.041 65.52 99 65.52 99H52.56C52.56 99 56.88 99.0691 56.88 103.32V107.64C56.88 109.43 58.3304 110.88 60.12 110.88Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 106.56C70.2104 106.56 68.76 105.11 68.76 103.32V101.158C68.76 99.041 66.6 99 66.6 99H78.48C78.48 99 75.24 99.0486 75.24 102.241V103.32C75.24 105.11 73.7895 106.56 72 106.56Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.44 126C90.2477 126 89.28 125.032 89.28 123.84V103.32C89.28 99.0605 84.96 99 84.96 99H96.84C96.84 99 93.6 99.1102 93.6 102.235V123.84C93.6 125.032 92.6323 126 91.44 126Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.92 93.6001H47.16C50.4 93.6001 50.4 87.1201 59.04 87.1201C67.68 87.1201 66.6 92.5201 73.08 92.5201C79.56 92.5201 80.9996 90.3601 86.04 90.3601C91.0803 90.3601 100.337 96.3941 97.7321 99.0001H45.1199L43.92 93.6001Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.88 115.2C82.6877 115.2 81.72 114.232 81.72 113.04V96.8397C81.72 91.0617 77.4 92.1319 77.4 92.1319L84.2526 86.0537L89.28 90.9558C89.28 90.9558 86.04 89.3898 86.04 92.5143V113.04C86.04 114.232 85.0723 115.2 83.88 115.2Z"
          fill="#33B1DD"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_132318">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
