import { ExternalLayout, useToast } from '@melio/penny';
import {
  DeliveryPreferenceType,
  useAccount,
  useCopyDeliveryMethodOwnedToLocalVendor,
  usePayment,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { SelectOrganizationModel } from '@melio/vex-widgets';

import { WithActivityConfig } from '../../../contexts/ActivityConfig';
import { PaymentCanceledScreen } from '../../../shared/screens/payment-canceled';
import { AddBankDeliveryMethodByPayeeActivity } from '../AddBankDeliveryMethodByPayee';
import { AddCheckDeliveryMethodByPayeeActivity } from '../AddCheckDeliveryMethodByPayee';
import { DeliveryMethodAddedSuccessfullyScreen } from '../screens';
import {
  AddDeliveryMethodByPayeeActivityConfigContext,
  createActivityConfig,
  useActivityContext,
} from './AddDeliveryMethodByPayee.config';
import {
  AcceptPaymentForExistsDeliveryMethodScreen,
  CompanySelectionScreen,
  SelectDeliveryMethodTypeScreen,
} from './screens';
import { AddDeliveryMethodByPayeeActivityProps, SupportedPaymentDeliveryMethods } from './types';
import { useAddDeliveryMethodByPayeeStep } from './useAddDeliveryMethodByPayeeStep';
import { useUnilateralOrganizations } from './useUnilateralOrganizations';
import { extractAccountNameFromPayment } from './utils';

export const Activity = ({
  paymentId,
  accountName,
  accountEmail,
  onError,
  onDone,
}: AddDeliveryMethodByPayeeActivityProps) => {
  const { toast } = useToast();
  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const { showPoweredByMelio, showLoginHeaderSection, emailVerificationReturnUrl } = useActivityContext();
  const { data: accountMe, isLoading: isLoadingUser } = useAccount({ id: 'me' });
  const { data: payment, isLoading: isLoadingPayment, error: paymentError } = usePayment({ id: paymentId });
  const {
    copyDeliveryMethodOwnedToLocalVendor,
    data: copyDmData,
    isLoading: isLoadingCopyDm,
  } = useCopyDeliveryMethodOwnedToLocalVendor();
  const {
    organizations,
    selectedOrganization,
    handleOrgSelection,
    selectedDeliveryMethod,
    copyDMError,
    fetchOrgsError,
  } = useUnilateralOrganizations();
  const { currentStep, goToStep, goToPreviousStep } = useAddDeliveryMethodByPayeeStep({
    organizations,
    selectedDeliveryMethod,
    selectedOrganization,
    isDmCopied: Boolean(copyDmData?.data.deliveryMethod),
  });

  const handleSubmitOrg = ({ organizationId }: SelectOrganizationModel) => {
    handleOrgSelection(organizationId).catch(handleFail);
  };

  const handleAcceptPaymentForExistingDM = () => {
    if (selectedDeliveryMethod?.id) {
      copyDeliveryMethodOwnedToLocalVendor({ deliveryMethodId: selectedDeliveryMethod.id }).catch(handleFail);
    }
  };

  const paymentInvoiceNumber = payment?.bills?.[0]?.invoice?.number;
  const userName = accountMe?.user ? `${accountMe.user.firstName} ${accountMe.user.lastName}` : undefined;

  if (paymentError?.code === '404') {
    return <PaymentCanceledScreen accountEmail={accountEmail} accountName={accountName} />;
  }

  if (!payment || isLoadingPayment || isLoadingUser) {
    return <ExternalLayout isLoading />;
  }

  if (
    payment.deliveryPreferenceType === DeliveryPreferenceType.Check ||
    payment.deliveryPreferenceType === DeliveryPreferenceType.Ach
  ) {
    return (
      <DeliveryMethodAddedSuccessfullyScreen
        paymentInvoiceNumber={paymentInvoiceNumber}
        accountName={extractAccountNameFromPayment(payment)}
        deliveryMethod={payment.deliveryMethod as SupportedPaymentDeliveryMethods}
        estimatedDelivery={payment.estimatedDelivery}
        maxEstimatedDelivery={payment.maxEstimatedDelivery}
        paymentAmount={payment.amount}
        showPoweredByMelio={showPoweredByMelio}
      />
    );
  }

  switch (currentStep) {
    case 'SELECT_DELIVERY_METHOD_TYPE':
    default: {
      return (
        <SelectDeliveryMethodTypeScreen
          accountName={accountName}
          paymentAmount={payment.amount}
          paymentNote={payment.note}
          paymentInvoiceNumber={paymentInvoiceNumber}
          onDone={(deliveryMethodType) => {
            goToStep(deliveryMethodType === 'bank-account' ? 'ADD_BANK' : 'ADD_CHECK');
          }}
          origin="payee"
          showPoweredByMelio={showPoweredByMelio}
          showLoginHeaderSection={showLoginHeaderSection}
          emailVerificationReturnUrl={emailVerificationReturnUrl}
          userName={userName}
        />
      );
    }
    case 'ADD_BANK': {
      return (
        <AddBankDeliveryMethodByPayeeActivity
          accountName={accountName}
          paymentNote={payment.note}
          paymentInvoiceNumber={paymentInvoiceNumber}
          paymentId={paymentId}
          onDone={onDone}
          onBack={goToPreviousStep}
          onError={handleFail}
          userName={userName}
        />
      );
    }
    case 'ADD_CHECK': {
      return (
        <AddCheckDeliveryMethodByPayeeActivity
          accountName={accountName}
          paymentNote={payment.note}
          paymentInvoiceNumber={paymentInvoiceNumber}
          paymentId={paymentId}
          onDone={onDone}
          onBack={goToPreviousStep}
          onError={handleFail}
          userName={userName}
        />
      );
    }
    case 'COMPANY_SELECTION_SCREEN': {
      return (
        <CompanySelectionScreen
          accountName={accountName}
          paymentAmount={payment.amount}
          onDone={handleSubmitOrg}
          showPoweredByMelio={showPoweredByMelio}
          organizations={organizations}
          error={fetchOrgsError}
        />
      );
    }
    case 'ACCEPT_PAYMENT_FOR_EXISTS_DELIVERY_METHOD': {
      if (!selectedDeliveryMethod || !selectedOrganization || !userName) {
        return null;
      }
      return (
        <AcceptPaymentForExistsDeliveryMethodScreen
          deliveryMethod={selectedDeliveryMethod}
          companyName={selectedOrganization.companyName || ''}
          accountName={userName}
          isSaving={isLoadingCopyDm}
          paymentAmount={payment.amount}
          onDone={handleAcceptPaymentForExistingDM}
          showPoweredByMelio={showPoweredByMelio}
          error={copyDMError}
        />
      );
    }
    case 'DELIVERY_METHOD_ADDED_SUCCESSFULLY': {
      if (!selectedDeliveryMethod || !selectedOrganization || !copyDmData) {
        return null;
      }
      return (
        <DeliveryMethodAddedSuccessfullyScreen
          paymentInvoiceNumber={paymentInvoiceNumber}
          accountName={selectedOrganization.companyName || ''}
          deliveryMethod={selectedDeliveryMethod}
          estimatedDelivery={payment.estimatedDelivery}
          maxEstimatedDelivery={payment.maxEstimatedDelivery}
          paymentAmount={payment.amount}
          userName={userName}
          showLoginHeaderSection={showLoginHeaderSection}
          showPoweredByMelio={showPoweredByMelio}
        />
      );
    }
  }
};

export const AddDeliveryMethodByPayeeActivity: React.VFC<AddDeliveryMethodByPayeeActivityProps> = (props) => {
  const [isNewAddressFlowVisible] = useFeature(FeatureFlags.UnilateralPaperCheckAddressFlow, false);

  return (
    <WithActivityConfig
      Context={AddDeliveryMethodByPayeeActivityConfigContext}
      createConfig={createActivityConfig}
      params={{ isNewAddressFlowVisible }}
    >
      <Activity {...props} />
    </WithActivityConfig>
  );
};
