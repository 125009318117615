// eslint-disable-next-line no-restricted-imports
import { PatchVendorsVendorIdRequest, PostVendorsRequest } from '@melio/platform-api-axios-client';

import { getApiHeaders } from '@/api/utilities';
import { vendorApi } from '../apiClients';
import { VendorFullyExpanded, VendorLean } from './vendors.api.types';

export const vendorsGetVendorsList = () =>
  vendorApi.getVendors({ expand: 'none' }, getApiHeaders()).then((res) => res.data.data as VendorLean[]);

export const vendorsGetVendorExpandedById = (id: string) =>
  vendorApi
    .getVendorsVendorId({ id, expand: ['deliveryMethods', 'paymentsOverview'] }, getApiHeaders())
    .then((res) => res.data.data as VendorFullyExpanded);

export const createVendor = ({ name, contact }: PostVendorsRequest) =>
  vendorApi
    .postVendors(
      {
        postVendorsRequest: {
          name,
          contact: contact
            ? {
                name: contact.name || '',
                email: contact.email || '',
                phoneNumber: contact.phoneNumber || '',
              }
            : undefined,
        },
        expand: ['none'],
      },
      getApiHeaders(),
    )
    .then((res) => res.data.data);

export const updateVendor = (id: string, { name, contact }: PatchVendorsVendorIdRequest) =>
  vendorApi
    .patchVendorsVendorId(
      {
        id,
        expand: ['deliveryMethods', 'paymentsOverview'],
        patchVendorsVendorIdRequest: {
          name,
          contact: contact
            ? {
                name: contact.name || '',
                email: contact.email || '',
                phoneNumber: contact.phoneNumber || '',
              }
            : undefined,
        },
      },
      getApiHeaders(),
    )
    .then((res) => res.data.data as VendorFullyExpanded);

export const deleteVendor = (id: string) =>
  vendorApi
    .deleteVendorsVendorId(
      {
        id,
      },
      getApiHeaders(),
    )
    .then((res) => res.data.deleted);
