import { useNonEntityMutation } from '@melio/api-client';
import {
  PostAuthResponseData,
  PostResolveEmailVerificationRequest,
  VexAuthenticationApiClient,
} from '@melio/platform-api-axios-client';

const apiRequest = VexAuthenticationApiClient.postAuthGuestVerifyValidationCode;
const queryFn = async (params: PostResolveEmailVerificationRequest) => {
  const { data } = await apiRequest(params);

  return data;
};
export const useResolveEmailVerificationCode = () =>
  useNonEntityMutation<typeof apiRequest, PostResolveEmailVerificationRequest, PostAuthResponseData>(
    queryFn as unknown as typeof apiRequest,
    ['VexAuthenticationApi', 'model', 'me', 'default']
  );

export { UserRegistrationFlowEnum } from '@melio/platform-api-axios-client';
