export const MoneySuccess: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_131887)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#273B49"
      />
      <path d="M106.744 56.4912H29.7611V92.844H106.744V56.4912Z" fill="#198040" />
      <path d="M109.951 53.2837H32.9689V89.6365H109.951V53.2837Z" fill="#16A149" />
      <path d="M113.159 50.0762H36.1765V86.429H113.159V50.0762Z" fill="#4CB648" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.328 75.7369C103.871 75.7369 106.744 72.386 106.744 68.2525C106.744 64.119 103.871 60.7681 100.328 60.7681C96.7855 60.7681 93.9132 64.119 93.9132 68.2525C93.9132 72.386 96.7855 75.7369 100.328 75.7369Z"
        fill="#179747"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.0068 75.7369C52.5499 75.7369 55.422 72.386 55.422 68.2525C55.422 64.119 52.5499 60.7681 49.0068 60.7681C45.4638 60.7681 42.5916 64.119 42.5916 68.2525C42.5916 72.386 45.4638 75.7369 49.0068 75.7369Z"
        fill="#179747"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.8373 69.3217C61.8373 78.1961 67.8248 85.3597 75.2023 85.3597C82.5798 85.3597 87.4981 78.1961 87.4981 69.3217C87.4981 60.4473 82.5798 53.2837 75.2023 53.2837C67.8248 53.2837 61.8373 60.4473 61.8373 69.3217Z"
        fill="#179747"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.894 74.0261C68.894 74.0261 69.7494 73.8123 71.7809 73.5985C72.1016 73.0639 72.2085 72.3154 72.2085 71.6739C70.7117 70.6047 69.6425 68.6801 69.6425 66.4348C69.6425 63.0134 70.0701 60.7681 74.5608 60.7681H74.9885C79.4791 60.7681 79.9068 63.1203 79.9068 66.4348C79.9068 68.6801 78.8376 70.6047 77.3407 71.6739C77.3407 72.3154 77.5545 73.0639 77.7684 73.5985C80.0137 73.7054 80.6552 74.0261 80.6552 74.0261C82.7936 74.7746 86.6427 77.3407 87.2843 85.0389L73.2777 85.7873L62.9065 85.3597C62.9065 79.3721 65.9003 74.7746 68.894 74.0261Z"
        fill="#4CB648"
      />
      <g clipPath="url(#clip1_11475_131887)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.512 45.27C119.968 45.27 126.012 39.1654 126.012 31.635C126.012 24.1046 119.968 18 112.512 18C105.057 18 99.0125 24.1046 99.0125 31.635C99.0125 39.1654 105.057 45.27 112.512 45.27Z"
          fill="#128020"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.449 37.5799L105.453 33.5439C105.318 33.4076 105.318 33.1894 105.453 33.0531C105.588 32.9167 105.804 32.9167 105.939 33.0531L109.692 36.8436L118.818 26.2356C118.953 26.0992 119.142 26.0719 119.304 26.2083C119.439 26.3446 119.466 26.5355 119.331 26.6992L109.962 37.6072C109.881 37.6617 109.8 37.689 109.692 37.689C109.611 37.689 109.53 37.6617 109.449 37.5799Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_131887">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
      <clipPath id="clip1_11475_131887">
        <rect width="27.27" height="27.27" fill="white" transform="translate(99 18)" />
      </clipPath>
    </defs>
  </svg>
);
