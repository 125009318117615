/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export function useOneTimeEffect(effect: EffectCallback, shouldRun: () => boolean, deps: DependencyList) {
  const oneTimeUpdateRef = useRef(false);
  useEffect(() => {
    if (!oneTimeUpdateRef.current && shouldRun()) {
      oneTimeUpdateRef.current = true;
      effect();
    }
  }, deps);
}
