import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VerifyEmailIcon = (props: IconProps) => (
  <Icon width="100px" height="100px" viewBox="0 0 100 100" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 100C22.386 100 0 77.614 0 50C0 22.386 22.386 0 50 0C77.614 0 100 22.386 100 50C100 77.614 77.614 100 50 100Z"
      fill="#273B49"
    />
    <mask
      id="mask0_5287_305074"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="100"
      height="100"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100C22.386 100 0 77.614 0 50C0 22.386 22.386 0 50 0C77.614 0 100 22.386 100 50C100 77.614 77.614 100 50 100Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_5287_305074)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 36.6113H74.4252H78.1471V59.5111C78.1471 62.8248 75.4608 65.5111 72.1471 65.5111H27C23.6863 65.5111 21 62.8248 21 59.5111V36.6113Z"
        fill="#008BC1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 36.6113L40.1537 53.6137H59.8078L78.1471 36.6113V72.8864H21V36.6113Z"
        fill="#98D5EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1083 53.5742H59.7624L78.1017 72.8469C78.1017 72.8469 21.0157 72.8469 20.9546 72.8469C20.8935 72.8469 33.8431 59.8782 33.8431 59.8782L40.1083 53.5742Z"
        fill="#C4EFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5735 19.166L78.1471 36.6112H21L49.5735 19.166Z"
        fill="#008BC1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.28 30.5332C27.28 29.4286 28.1755 28.5332 29.28 28.5332H69.8673C70.9719 28.5332 71.8673 29.4286 71.8673 30.5332V44.9161L59.5142 53.9025H40.2679L27.28 44.9161V30.5332Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.1809 48.2792C45.9049 48.2792 43.4749 45.8132 43.4749 42.6272C43.4749 38.7392 46.8769 35.5352 50.6209 35.5352C54.0229 35.5352 56.2909 38.0912 56.2909 41.2412C56.2909 44.0492 54.6889 45.4892 53.1769 45.4892C52.1689 45.4892 51.5929 44.7872 51.5389 44.0312V43.9052C50.9089 44.8052 49.8289 45.4892 48.6769 45.4892C47.0029 45.4892 45.9229 44.3012 45.9229 42.5552C45.9229 40.0532 48.0289 38.0372 50.1529 38.0372C51.3229 38.0372 52.1149 38.6672 52.4569 39.4412L52.6909 38.3252H53.9149L52.8889 43.2032C52.8709 43.3112 52.8529 43.4732 52.8529 43.5812C52.8529 44.1752 53.2309 44.5172 53.6989 44.5172C54.4009 44.5172 55.6609 43.7252 55.6609 41.2412C55.6609 38.2892 53.6269 36.1472 50.5669 36.1472C47.0749 36.1472 44.1049 39.2252 44.1049 42.5912C44.1049 45.4712 46.2649 47.6852 49.2529 47.6852C50.5309 47.6852 51.6289 47.2892 52.6009 46.6772L52.9249 47.1452C51.8089 47.8652 50.5129 48.2792 49.1809 48.2792ZM49.0549 44.4812C50.2789 44.4812 51.1969 43.6172 51.7189 42.8612L52.2409 40.3592C52.0429 39.8012 51.4489 39.0452 50.3869 39.0452C48.5869 39.0452 47.2189 40.7372 47.2189 42.4832C47.2189 43.6352 47.8849 44.4812 49.0549 44.4812Z"
        fill="#273B49"
      />
    </g>
  </Icon>
);
