import { EmptyIcon } from '@/cl/icons/melio/empty.icon';
import { EmptyVendorsIcon } from '@/cl/icons/melio/emptyVendors.icon';
import { ExpiredSessionIcon } from '@/cl/icons/melio/ExpiredSession.icon';
import { MelioIcon } from '@/cl/icons/system';
import Favicon from '@/images/usbank/favicon.ico';
import { PartnerIcons } from '@/partnersConfig.types';
import { Tax1099Icon } from '../default/taxSummary.icon';
import { UsBankIcon } from './usBank.icon';
import { UsBankSmallIcon } from './usBankSmall.icon';

export const UsBankIcons: PartnerIcons = {
  Favicon,
  MelioIcon,
  LogoIcon: UsBankIcon,
  SmallLogoIcon: UsBankSmallIcon,
  ErrorIcon: EmptyIcon,
  EmptyBillsIcon: EmptyIcon,
  EmptyVendorsIcon: EmptyVendorsIcon,
  NoBillsFoundIcon: EmptyIcon,
  NoVendorsFoundIcon: EmptyIcon,
  VerifyEmailIcon: EmptyIcon,
  TaxSummaryIcon: Tax1099Icon,
  ExpiredSessionIcon: ExpiredSessionIcon,
  BackToPartnerIcon: EmptyIcon,
  AddBillIcon: EmptyIcon,
  StartNewExperienceIcon: EmptyIcon,
  TryDifferentEmailIcon: EmptyIcon,
};
