import React from 'react';
import { Container, Illustration } from '@melio/penny';

export const ExpiredSessionIcon = () => {
  return (
    <Container>
      <Illustration type="expired" />
    </Container>
  );
};
