import { Card, Container, ExternalLayout } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { PayorPendingPaymentBanner } from '@melio/vex-widgets';

type ServerErrorScreenProps = Record<string, never>;

export const ServerErrorScreen = forwardRef<ServerErrorScreenProps, 'div'>(({ children, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  useAnalyticsView('VexServerErrorScreen');

  const content = (
    <Card paddingX="none" paddingY="none">
      <Container paddingX="xl" paddingY="l">
        <PayorPendingPaymentBanner variant="warning" description={formatMessage('vex.screen.serverError.content')} />
      </Container>
    </Card>
  );

  return (
    <ExternalLayout
      data-component="ServerErrorScreen"
      data-testid="server-error-screen"
      {...props}
      content={content}
      ref={ref}
    />
  );
});

ServerErrorScreen.displayName = 'ServerErrorScreen';
