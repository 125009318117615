import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { PayorPendingPaymentDetailsWidget } from '../PayorPendingPaymentDetails';

export type PushToDebitHeaderWidgetProps = {
  accountName: string;
  paymentAmount: number;
  paymentInvoiceNumber?: string;
  paymentNote?: string;
  feePercentValue?: number;
  calculatedFee?: number;
  showAvatar?: boolean;
};

export const PushToDebitHeaderWidget = forwardRef<PushToDebitHeaderWidgetProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      feePercentValue,
      calculatedFee,
      showAvatar = true,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatCurrency, formatPercents } = useMelioIntl();

    return (
      <PayorPendingPaymentDetailsWidget
        data-component="PushToDebitHeaderWidget"
        description={formatMessage('widgets.pushToDebitHeader.description', { accountName })}
        accountName={accountName}
        paymentAmount={paymentAmount}
        paymentInvoiceNumber={paymentInvoiceNumber}
        paymentNote={paymentNote}
        paymentInvoiceNumberLabel={formatMessage('widgets.pushToDebitHeader.paymentInvoiceNumberLabel')}
        paymentNoteLabel={formatMessage('widgets.pushToDebitHeader.paymentNoteLabel')}
        feeLabel={formatMessage('widgets.pushToDebitHeader.feeLabel')}
        fee={
          feePercentValue && calculatedFee
            ? formatMessage('widgets.pushToDebitHeader.fee', {
                feeValueString: formatPercents(feePercentValue, { divide: true }),
                calculatedFeeString: formatCurrency(calculatedFee),
              })
            : undefined
        }
        showAvatar={showAvatar}
        {...props}
        ref={ref}
      />
    );
  }
);

PushToDebitHeaderWidget.displayName = 'PushToDebitHeaderWidget';
