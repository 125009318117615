import { Box } from '@chakra-ui/react';
import { Group, Link, Text } from '@melio/penny';
import { FormattedMessage, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { PCIIcon, PrivacyIcon } from './icons';

export type PCIComplianceInfoProps = {
  legalInfoOverride?: string;
};
export const PCIComplianceInfo: React.VFC<PCIComplianceInfoProps> = forwardRef(
  ({ legalInfoOverride, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group {...props} data-component="PCIComplianceInfo" alignItems="center" ref={ref}>
        <Box as="span" color="legalInfo.icon.color">
          <PrivacyIcon />
        </Box>
        <Text as="p" textStyle="caption1">
          {legalInfoOverride || (
            <FormattedMessage
              id="widgets.legal.info"
              values={{
                link: (
                  <Link
                    href="https://www.meliopayments.com/security"
                    label={formatMessage('widgets.legal.link')}
                    variant="inline"
                    data-testid="pci-comp-info-sec-link"
                    newTab
                  />
                ),
              }}
            />
          )}
        </Text>
        <PCIIcon />
      </Group>
    );
  }
);
PCIComplianceInfo.displayName = 'PCIComplianceInfo';
