import { useContext } from 'react';

import { AnalyticsContext } from '../AnalyticsProvider';
import { EventProperties } from '../types';

type UseSetAnalyticsContextProps = {
  globalProperties: EventProperties;
};

/**
 * @deprecated instead, wrap your component with `withAnalyticsContext` and call `setAnalyticsProperties` from props
 */
export const useAnalyticsContext = ({ globalProperties }: UseSetAnalyticsContextProps) => {
  const { setGlobalProperties } = useContext(AnalyticsContext);

  setGlobalProperties(globalProperties);
};
