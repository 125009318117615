import cookies from 'js-cookie';

type GlobalThisWithMelioStorage = typeof globalThis & { __melioLocalStorage__: Storage };

const checkIsLocalStorageAvailable = () => {
  try {
    localStorage.key(0);
    localStorage.setItem('__testLocalStorage__', '0');
    localStorage.getItem('__testLocalStorage__');
    localStorage.removeItem('__testLocalStorage__');
    return true;
  } catch (e) {
    return false;
  }
};

class MelioLocalStorageEmulator implements Storage {
  get length(): number {
    return Object.keys(cookies.get()).length;
  }
  clear(): void {
    const allCookies = Object.keys(cookies.get());
    for (const cookie of allCookies) {
      this.removeItem(cookie);
    }
  }
  key(index: number): string | null {
    const allCookies = Object.keys(cookies.get());
    return allCookies[index] ?? null;
  }
  getItem(key: string): string | null {
    return cookies.get(key) ?? null;
  }
  removeItem(key: string): void {
    cookies.remove(key);
  }
  setItem(key: string, value: string): void {
    cookies.set(key, value);
  }
  getKeys(): string[] {
    return Object.keys(cookies.get());
  }
}

export const getStorageKeys = () => {
  const storage = getStorageInstance();
  const keys = storage instanceof MelioLocalStorageEmulator ? storage.getKeys() : Object.keys(storage);

  return keys;
};

export const getStorageInstance = (): Storage => {
  if ((globalThis as GlobalThisWithMelioStorage).__melioLocalStorage__) {
    return (globalThis as GlobalThisWithMelioStorage).__melioLocalStorage__;
  }

  const isLocalStorageAvailable = checkIsLocalStorageAvailable();

  if (isLocalStorageAvailable) {
    (globalThis as GlobalThisWithMelioStorage).__melioLocalStorage__ = localStorage;
  } else {
    (globalThis as GlobalThisWithMelioStorage).__melioLocalStorage__ = new MelioLocalStorageEmulator();
  }

  return (globalThis as GlobalThisWithMelioStorage).__melioLocalStorage__;
};
