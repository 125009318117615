export const TaxForm: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11828_10553)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#98D5EC"
      />
      <mask
        id="mask0_11828_10553"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11828_10553)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.4283 46.0801H110.499V132.48H44.4283V46.0801Z"
          fill="#D7D6D9"
        />
        <path d="M107.195 42.8398H41.1246V129.24H107.195V42.8398Z" fill="#E1E0E2" />
        <path d="M103.892 39.5996H37.8212V126H103.892V39.5996Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.0795 59.0399C62.4601 59.0399 62.0471 58.6079 62.0471 57.9599C62.0471 57.3119 62.4601 56.8799 63.0795 56.8799H76.4312C77.0507 56.8799 77.4636 57.3119 77.4636 57.9599C77.4636 58.6079 77.0507 59.0399 76.4312 59.0399H63.0795Z"
          fill="#C8CDD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.0795 54.7196C62.4601 54.7196 62.0471 54.2876 62.0471 53.6396C62.0471 52.9916 62.4601 52.5596 63.0795 52.5596H76.4312C77.0507 52.5596 77.4636 52.9916 77.4636 53.6396C77.4636 54.2876 77.0507 54.7196 76.4312 54.7196H63.0795Z"
          fill="#C8CDD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.0795 50.3993C62.4601 50.3993 62.0471 49.9673 62.0471 49.3193C62.0471 48.6713 62.4601 48.2393 63.0795 48.2393H76.4312C77.0507 48.2393 77.4636 48.6713 77.4636 49.3193C77.4636 49.9673 77.0507 50.3993 76.4312 50.3993H63.0795Z"
          fill="#C8CDD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9005 59.0399C82.2812 59.0399 81.8682 58.6079 81.8682 57.9599C81.8682 57.3119 82.2812 56.8799 82.9005 56.8799H96.2523C96.8717 56.8799 97.2846 57.3119 97.2846 57.9599C97.2846 58.6079 96.8717 59.0399 96.2523 59.0399H82.9005Z"
          fill="#C8CDD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9005 54.7196C82.2812 54.7196 81.8682 54.2876 81.8682 53.6396C81.8682 52.9916 82.2812 52.5596 82.9005 52.5596H96.2523C96.8717 52.5596 97.2846 52.9916 97.2846 53.6396C97.2846 54.2876 96.8717 54.7196 96.2523 54.7196H82.9005Z"
          fill="#C8CDD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.0795 50.3993C62.4601 50.3993 62.0471 49.9673 62.0471 49.3193C62.0471 48.6713 62.4601 48.2393 63.0795 48.2393H76.4312C77.0507 48.2393 77.4636 48.6713 77.4636 49.3193C77.4636 49.9673 77.0507 50.3993 76.4312 50.3993H63.0795Z"
          fill="#C8CDD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9005 50.3993C82.2812 50.3993 81.8682 49.9673 81.8682 49.3193C81.8682 48.6713 82.2812 48.2393 82.9005 48.2393H96.2523C96.8717 48.2393 97.2846 48.6713 97.2846 49.3193C97.2846 49.9673 96.8717 50.3993 96.2523 50.3993H82.9005Z"
          fill="#C8CDD1"
        />
        <path d="M57.6424 48.2393H42.226V59.0393H57.6424V48.2393Z" fill="#687680" />
        <path
          d="M96.2046 87.1191H82.9482C82.3517 87.1191 81.8682 87.6027 81.8682 88.1991V95.7591C81.8682 96.3556 82.3517 96.8391 82.9482 96.8391H96.2046C96.8011 96.8391 97.2846 96.3556 97.2846 95.7591V88.1991C97.2846 87.6027 96.8011 87.1191 96.2046 87.1191Z"
          fill="#CFCCCF"
        />
        <path
          d="M96.2046 73.0801H82.9482C82.3517 73.0801 81.8682 73.5636 81.8682 74.1601V81.7201C81.8682 82.3165 82.3517 82.8001 82.9482 82.8001H96.2046C96.8011 82.8001 97.2846 82.3165 97.2846 81.7201V74.1601C97.2846 73.5636 96.8011 73.0801 96.2046 73.0801Z"
          fill="#CFCCCF"
        />
        <path
          d="M76.3836 87.1191H43.306C42.7095 87.1191 42.226 87.6027 42.226 88.1991V95.7591C42.226 96.3556 42.7095 96.8391 43.306 96.8391H76.3836C76.9801 96.8391 77.4636 96.3556 77.4636 95.7591V88.1991C77.4636 87.6027 76.9801 87.1191 76.3836 87.1191Z"
          fill="#CFCCCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.226 74.1601C42.226 73.5636 42.7095 73.0801 43.306 73.0801H76.3835C76.98 73.0801 77.4635 73.5636 77.4635 74.1601V81.7201C77.4635 82.3166 76.98 82.8001 76.3835 82.8001H43.306C42.7095 82.8001 42.226 82.3166 42.226 81.7201V74.1601Z"
          fill="#CFCCCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.6753 64.418H43.8352C43.238 64.418 42.7552 64.9102 42.7552 65.5191C42.7552 66.1281 43.238 66.6203 43.8352 66.6203H95.6753C96.2725 66.6203 96.7553 66.1281 96.7553 65.5191C96.7553 64.9102 96.2725 64.418 95.6753 64.418Z"
          fill="#CFCCCF"
        />
        <path
          d="M96.2046 101.159H82.9482C82.3517 101.159 81.8682 101.643 81.8682 102.239V109.799C81.8682 110.396 82.3517 110.879 82.9482 110.879H96.2046C96.8011 110.879 97.2846 110.396 97.2846 109.799V102.239C97.2846 101.643 96.8011 101.159 96.2046 101.159Z"
          fill="#CFCCCF"
        />
        <path
          d="M76.3836 101.159H43.306C42.7095 101.159 42.226 101.643 42.226 102.239V109.799C42.226 110.396 42.7095 110.879 43.306 110.879H76.3836C76.9801 110.879 77.4636 110.396 77.4636 109.799V102.239C77.4636 101.643 76.9801 101.159 76.3836 101.159Z"
          fill="#CFCCCF"
        />
        <path
          d="M96.2046 116.279H82.9482C82.3517 116.279 81.8682 116.763 81.8682 117.359V124.919C81.8682 125.516 82.3517 125.999 82.9482 125.999H96.2046C96.8011 125.999 97.2846 125.516 97.2846 124.919V117.359C97.2846 116.763 96.8011 116.279 96.2046 116.279Z"
          fill="#CFCCCF"
        />
        <path
          d="M76.3836 116.279H43.306C42.7095 116.279 42.226 116.763 42.226 117.359V124.919C42.226 125.516 42.7095 125.999 43.306 125.999H76.3836C76.9801 125.999 77.4636 125.516 77.4636 124.919V117.359C77.4636 116.763 76.9801 116.279 76.3836 116.279Z"
          fill="#CFCCCF"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11828_10553">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
