export const Together: React.VFC = () => (
  <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="66.9336" cy="72" r="54" fill="#C8E6F3" />
    <path
      d="M116.99 58.391C117.084 57.4399 118.332 57.1559 118.827 57.9729L122.08 63.3436C122.302 63.7096 122.732 63.8924 123.149 63.7976L129.358 62.3844C130.303 62.1694 130.964 63.2981 130.317 64.02L126.139 68.6788C125.846 69.0052 125.801 69.4852 126.028 69.8604L129.24 75.1626C129.738 75.9845 128.899 76.966 128.012 76.5986L122.116 74.1558C121.727 73.9948 121.279 74.0967 120.998 74.4103L116.733 79.1655C116.092 79.8807 114.912 79.3589 115.007 78.402L115.618 72.2296C115.662 71.7928 115.414 71.3793 115.01 71.2116L109.233 68.8184C108.338 68.4476 108.448 67.1434 109.392 66.9284L115.602 65.5152C116.018 65.4204 116.328 65.0692 116.37 64.6432L116.99 58.391Z"
      fill="#3374AC"
      stroke="#2B3A48"
    />
    <ellipse cx="47.2227" cy="118.5" rx="8" ry="7.5" fill="#3374AC" />
    <circle cx="93.1328" cy="25.5" r="4.5" fill="#3374AC" />
    <circle cx="87.1328" cy="105.5" r="4.5" fill="#3374AC" />
    <path
      d="M74.7098 58.5757C74.851 53.8291 73.0974 49.2204 69.8335 45.7597C66.5696 42.299 62.0616 40.2687 57.2978 40.1138C54.3712 40.1439 51.4996 40.9095 48.949 42.3396C46.8728 43.5037 45.0641 45.0775 43.6301 46.9587C43.1785 47.5511 42.2444 47.5457 41.799 46.9486C40.3895 45.0592 38.6002 43.4748 36.539 42.3003C34.0053 40.8564 31.1441 40.0795 28.2255 40.0427C23.4609 40.1743 18.9431 42.1825 15.6623 45.6272C12.3815 49.0719 10.6054 53.672 10.7233 58.4192C10.6739 78.6493 38.8193 90.7561 42.3137 92.1812C42.5421 92.2744 42.7948 92.2748 43.0236 92.1826C46.5211 90.773 74.6604 78.8041 74.7098 58.5757Z"
      fill="white"
      stroke="#2B3A48"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M87.5876 76.9262C87.6597 74.4981 86.7627 72.1405 85.0931 70.3702C83.4234 68.5999 81.1174 67.5613 78.6804 67.482C77.1833 67.4974 75.7144 67.8891 74.4097 68.6206C73.58 69.0858 72.8339 69.6788 72.1974 70.3748C71.6947 70.9245 70.747 70.9191 70.2494 70.3647C69.6223 69.6659 68.8842 69.0694 68.0613 68.6005C66.7652 67.8619 65.3016 67.4645 63.8086 67.4457C61.3712 67.513 59.0602 68.5403 57.3819 70.3024C55.7036 72.0645 54.7951 74.4177 54.8554 76.8461C54.8307 86.923 68.4815 93.0599 70.8489 94.0483C71.0765 94.1434 71.3172 94.1438 71.5452 94.0497C73.9143 93.0719 87.5629 87.002 87.5876 76.9262Z"
      fill="white"
      stroke="#2B3A48"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
  </svg>
);
