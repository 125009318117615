export const PageNotFound: React.VFC = () => (
  <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8317_10901)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
        fill="#C1E7F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.8939 102.6C88.55 115.88 73.4527 122.315 60.172 116.971C46.8912 111.627 40.4566 96.5301 45.8004 83.2493C51.1442 69.9686 66.2416 63.5339 79.5212 68.8778C92.802 74.2216 99.2366 89.3189 93.8939 102.6Z"
        fill="#33B1DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.8553 78.0679C60.2968 81.9419 55.8926 83.8178 52.0197 82.2594C48.1457 80.7009 46.2687 76.2978 47.8282 72.4238C49.3867 68.5499 53.7898 66.6739 57.6638 68.2334C61.5367 69.7908 63.4137 74.1939 61.8553 78.0679Z"
        fill="#33B1DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1554 91.573C97.8842 94.732 94.2932 96.2624 91.1331 94.9912C87.9741 93.7201 86.4438 90.129 87.7149 86.969C88.9861 83.811 92.5771 82.2807 95.7361 83.5508C98.8962 84.8219 100.427 88.4129 99.1554 91.573Z"
        fill="#33B1DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9439 80.1421L59.874 65.6291L91.9673 32.5876C93.3141 31.2008 95.6663 32.1469 95.6771 34.0801L95.9439 80.1421Z"
        fill="#FFDC24"
      />
      <mask id="mask0_8317_10901" maskUnits="userSpaceOnUse" x="60" y="35" width="36" height="40">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.3522 35.28L60.1201 65.3778V65.7288L81.0755 74.16H95.9093L95.6849 35.28H89.3522Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_8317_10901)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.1201 65.5202H101.16V61.2002H60.1201V65.5202Z"
          fill="#F0C501"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.1201 74.1601H101.16V69.8401H60.1201V74.1601Z"
          fill="#F0C501"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.1201 48.2399H101.16V43.9199H60.1201V48.2399Z"
          fill="#F0C501"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.1201 56.8801H101.16V52.5601H60.1201V56.8801Z"
          fill="#F0C501"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M60.1201 39.6H101.16V35.28H60.1201V39.6Z" fill="#F0C501" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.8325 84.3515L57.7604 69.4356C56.377 68.8794 55.7063 67.3069 56.2636 65.9234C56.8198 64.5389 58.3922 63.8693 59.7757 64.4265L96.8478 79.3413C98.2313 79.8975 98.902 81.4711 98.3447 82.8535C97.7885 84.2381 96.216 84.9077 94.8325 84.3515Z"
          fill="#F0C501"
        />
      </g>
      <mask id="mask1_8317_10901" maskUnits="userSpaceOnUse" x="18" y="18" width="108" height="108">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 18C42.1769 18 18 42.1769 18 72C18 101.823 42.1769 126 72 126C101.823 126 126 101.823 126 72C126 42.1769 101.823 18 72 18Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_8317_10901)">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 126H126V99H18V126Z" fill="#004977" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.2604 99.0001H40.1404C38.6489 99.0001 37.4404 97.7916 37.4404 96.3001C37.4404 94.8086 38.6489 93.6001 40.1404 93.6001H82.2604C83.7519 93.6001 84.9604 94.8086 84.9604 96.3001C84.9604 97.7916 83.7519 99.0001 82.2604 99.0001Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.1196 110.88C61.9091 110.88 63.3596 109.43 63.3596 107.64V101.158C63.3596 99.041 65.5196 99 65.5196 99H52.5596C52.5596 99 56.8796 99.0691 56.8796 103.32V107.64C56.8796 109.43 58.33 110.88 60.1196 110.88Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.9996 106.56C70.21 106.56 68.7596 105.11 68.7596 103.32V101.158C68.7596 99.041 66.5996 99 66.5996 99H78.4796C78.4796 99 75.2396 99.0486 75.2396 102.241V103.32C75.2396 105.11 73.7892 106.56 71.9996 106.56Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.44 126C90.2476 126 89.28 125.032 89.28 123.84V103.32C89.28 99.0605 84.96 99 84.96 99H96.84C96.84 99 93.6 99.1102 93.6 102.235V123.84C93.6 125.032 92.6323 126 91.44 126Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9199 93.6001H47.1599C50.3999 93.6001 50.3999 87.1201 59.0399 87.1201C67.6799 87.1201 66.5999 92.5201 73.0799 92.5201C79.5599 92.5201 80.9996 90.3601 86.0399 90.3601C91.0803 90.3601 100.337 96.3941 97.732 99.0001H45.1198L43.9199 93.6001Z"
          fill="#66C4E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.8804 115.2C82.6881 115.2 81.7204 114.232 81.7204 113.04V96.8399C81.7204 91.0619 77.4004 92.1322 77.4004 92.1322L84.253 86.054L89.2804 90.9561C89.2804 90.9561 86.0404 89.3901 86.0404 92.5145V113.04C86.0404 114.232 85.0727 115.2 83.8804 115.2Z"
          fill="#33B1DD"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_8317_10901">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
