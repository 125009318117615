import { Button, ButtonProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const SecondaryNextButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    data-component="NewSinglePaymentStepLayout.SecondaryNextButton"
    data-testid="layout-secondary-next-button"
    variant="secondary"
    tabIndex={0}
    size="large"
    {...props}
    ref={ref}
  />
));

SecondaryNextButton.displayName = 'NewSinglePaymentStepLayout.SecondaryNextButton';
