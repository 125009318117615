import EventEmitter from 'eventemitter3';
import { Account } from '@melio/platform-api';

import { OrganizationPreferenceList } from '@/hooks/preferences.hooks';
import { APP_EVENTS, EventPayloadTypes } from '@/queries/event-emitter-query';
import { UpdateListOnIndividualActionType, updateListOnIndividualUpdate } from '@/utils/eventEmitter.utils';
import {
  handleVendorCreated,
  handleVendorDeleted,
  handleVendorDeliveryMethodsUpdated,
  handleVendorUpdated,
} from '../event-handlers/vendors.handlers';
import { queryClient } from '../reactQueryClient';
import {
  getAccountingPlatformQueryKey,
  getAccountingPlatformsListQueryKey,
  getAccountingPlatformSyncStatusQueryKey,
  getAccountListQueryKey,
  getAccountMeQueryKey,
  getPreferencesQueryKey,
  getVendorsListQueryKey,
} from '../reactQueryKeys';

export const EventsEmitter = new EventEmitter<EventPayloadTypes>();

// Vendor events
EventsEmitter.on(APP_EVENTS.VENDOR_CREATED, handleVendorCreated);
EventsEmitter.on(APP_EVENTS.VENDOR_UPDATED, handleVendorUpdated);
EventsEmitter.on(APP_EVENTS.VENDOR_DELETED, handleVendorDeleted);
EventsEmitter.on(APP_EVENTS.VENDOR_DELIVERY_METHODS_UPDATED, handleVendorDeliveryMethodsUpdated);

EventsEmitter.on(APP_EVENTS.PREFERENCES_UPDATED, ({ preference }) => {
  queryClient.setQueriesData<OrganizationPreferenceList | undefined>(getPreferencesQueryKey(), (oldList) => {
    return updateListOnIndividualUpdate({
      type: UpdateListOnIndividualActionType.UPDATE_OR_CREATE,
      updatedIndividual: { id: preference.key, value: preference.value },
      oldList,
    });
  });
});

EventsEmitter.on(APP_EVENTS.LOGO_URL, ({ accountId, logoUrl }) => {
  queryClient.setQueryData<Account | undefined>(getAccountMeQueryKey('logoUrl'), (account) =>
    account ? { ...account, logoUrl } : undefined,
  );
  queryClient.setQueryData<Account[]>(getAccountListQueryKey(), (accounts = []) =>
    accounts.map((account) => (account.id === accountId ? { ...account, logoUrl } : account)),
  );
});

EventsEmitter.on(APP_EVENTS.ACCOUNTING_PLATFORM_SYNC_TRIGGERED, ({ accountingPlatformId }) => {
  queryClient.invalidateQueries(getAccountingPlatformSyncStatusQueryKey(accountingPlatformId));
});

EventsEmitter.on(APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED, () => {
  queryClient.invalidateQueries(getAccountingPlatformsListQueryKey());
  queryClient.invalidateQueries(getAccountingPlatformQueryKey());
});
EventsEmitter.on(APP_EVENTS.ACCOUNTING_PLATFORM_SYNC_STATUS_DONE, () => {
  queryClient.invalidateQueries(getAccountingPlatformsListQueryKey());
  queryClient.invalidateQueries(getVendorsListQueryKey());
});
EventsEmitter.on(APP_EVENTS.SCHEDULE_PAYMENT_FLOW_CLOSED, () => {
  queryClient.invalidateQueries({
    predicate: (query) => !query.meta?.noInvalidations,
  });
});
EventsEmitter.on(APP_EVENTS.PAYMENT_METHOD_FLOW_CLOSED, () => {
  queryClient.invalidateQueries({
    predicate: (query) => !query.meta?.noInvalidations,
  });
});

export const emitAppEvent = EventsEmitter.emit.bind(EventsEmitter);
