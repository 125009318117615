// eslint-disable-next-line no-restricted-imports
import { useIsMutating, useQuery } from 'react-query';

import { ApiError, ModelProps, UseModelApiResult } from './types';
import { createQueryKey } from './util';

export const useModelApi = <TQueryFnData, UData = never, TDelete extends boolean = false, TData = TQueryFnData>({
  queryKey: _queryKey,
  enabled = true,
  scope = 'default',
  id,
  ...options
}: ModelProps<TQueryFnData, TData>): UseModelApiResult<TQueryFnData, UData, TDelete, TData> => {
  const queryKey = createQueryKey({ queryKey: _queryKey, role: 'model', scope, id });
  const query = useQuery<TQueryFnData, ApiError, TData>(queryKey, {
    ...options,
    enabled: enabled && !!id,
  });
  const isUpdating = useIsMutating(queryKey) > 0;

  return {
    ...query,
    queryKey,
    isUpdating,
    update: () => {
      throw new Error('update method not implemented');
    },
    delete: () => {
      throw new Error('delete method not implemented');
    },
  };
};
