import { ExternalLayout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import {
  CardHolderDetails,
  PaymentUpgradeOfferOptionEnum,
  VendorPreferredPremiumMethodsEnum,
} from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useNavigate } from '@melio/platform-utils';
import { VexMonitoredAction } from '@melio/vex-domain';
import { TBTFormWidgetFields } from '@melio/vex-widgets';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { WithActivityConfig } from '../../contexts/ActivityConfig';
import { PaymentCanceledScreen } from '../../shared/screens/payment-canceled';
import { mapUpgradeOfferToVendorFastPreferenceValue } from './consts';
import { useFailedScreen, usePersistentFastLabels } from './hooks';
import { createActivityConfig, PaymentUpgradeConfigContext } from './PaymentUpgrade.config';
import {
  PaymentAlreadyDeliveredScreen,
  PaymentUpgradeConfirmScreen,
  PaymentUpgradeFailedScreen,
  PaymentUpgradeIndexScreen,
  PaymentUpgradeSuccessScreen,
  PaymentUpgradeTBTCardDetailsScreen,
  PaymentUpgradeTBTCardHolderDetailsScreen,
} from './screens';
import { HandleSubmitACHUpgradeProps } from './types';
import { useP2DUpgrade } from './useP2DUpgrade';
import { usePaymentUpgrade } from './usePaymentUpgrade';
import { usePersistentFastPreference } from './usePersistentFastPreference';
import {
  getFormattedDeliveryMethodDetails,
  getIsACHUpgradeSupported,
  getIsPaymentCompleted,
  getIsPaymentFailed,
  getUpgradeOfferByType,
  getUpgradeTypeOfPayment,
} from './utils';

type PaymentUpgradeActivityProps = { paymentId: string };

const Activity = withAnalyticsContext<PaymentUpgradeActivityProps>(({ setAnalyticsProperties, paymentId }) => {
  const navigate = useNavigate();
  const { startAction, endAction } = useMonitoring<VexMonitoredAction>();

  const {
    error,
    isPaymentFetching,
    isPaymentUpgradeLoading,
    isPaymentUpgradeSubmitting,
    payment,
    paymentUpgradeOffers,
    refetchGuestPayment,
    postPaymentUpgrade,
  } = usePaymentUpgrade({
    paymentId,
  });

  const {
    handleTokenizeCardDetails,
    handleCreateCardDeliveryMethod,
    isCardTokenizingInProgress,
    isCreatingCardDeliveryMethod,
    cardParams,
  } = useP2DUpgrade();

  const {
    persistentFastEligibility,
    isPersistentFastOfferEnabled,
    isPersistentFastPreferenceLoading,
    isPersistentFastPreferenceCreated,
    isPersistentFastPreferenceUpdating,
    handleSavePersistentFastPreference,
  } = usePersistentFastPreference({ vendorId: payment?.vendor?.id });

  const paymentUpgradeType = getUpgradeTypeOfPayment(payment);
  const isPaymentFailed = getIsPaymentFailed(payment);
  const isPaymentCompleted = getIsPaymentCompleted(payment);
  const formattedDeliveryMethodDetails = getFormattedDeliveryMethodDetails(payment);
  const isACHUpgradeSupported = getIsACHUpgradeSupported(paymentUpgradeOffers);
  const p2dUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Debit);

  const persistentFastBannerLabels = usePersistentFastLabels({
    paymentUpgradeOffers,
    payorOrgName: payment?.orgName ?? '',
    persistentFastEligibility,
  });

  const failedScreenProps = useFailedScreen({
    isPaymentFailed,
    isUpsellOfferNotAvailable: error?.code === '400',
    isUnauthorized: error?.code === '401',
    offerType: paymentUpgradeType,
  });

  useEffect(() => {
    if (payment) {
      setAnalyticsProperties({
        EntryPoint: 'email',
        Flow: 'push-2-fast-payment',
        IsUpsellInPlatform: true,
        PaymentId: paymentId,
        PaymentStatus: payment.status,
      });
    }
  }, [paymentId, payment, setAnalyticsProperties]);

  const handleNavigateToConfirmScreen = () => navigate('confirm');
  const handleNavigateToCardDetailsScreen = () => navigate('card-details');

  const refetchPaymentAndNavigateToSuccess = async () => {
    await refetchGuestPayment();
    navigate('success');
  };

  const handleSaveCardDetails = async (cardDetails: TBTFormWidgetFields) => {
    await handleTokenizeCardDetails(cardDetails);
    navigate('cardholder-details');
  };

  const handleSubmitP2DUpgrade = async (cardHolderData: CardHolderDetails) => {
    startAction(VexMonitoredAction.PaymentUpgradeSubmit);
    const cardDeliveryMethod = await handleCreateCardDeliveryMethod(cardHolderData);

    if (payment && cardDeliveryMethod) {
      await postPaymentUpgrade({
        offerType: PaymentUpgradeOfferOptionEnum.Debit,
        deliveryMethodId: cardDeliveryMethod.id,
      });
      endAction(VexMonitoredAction.PaymentUpgradeSubmit);
      await refetchPaymentAndNavigateToSuccess();
    }
  };

  const handleSubmitACHUpgrade = async ({ offerType, isPersistentFastActive }: HandleSubmitACHUpgradeProps) => {
    if (payment?.deliveryMethod) {
      startAction(VexMonitoredAction.PaymentUpgradeSubmit);
      await postPaymentUpgrade({ offerType, deliveryMethodId: payment.deliveryMethod.id });
      endAction(VexMonitoredAction.PaymentUpgradeSubmit);
      if (isPersistentFastActive) {
        await handleSavePersistentFastPreference(
          mapUpgradeOfferToVendorFastPreferenceValue[offerType] as VendorPreferredPremiumMethodsEnum
        );
      }
      await refetchPaymentAndNavigateToSuccess();
    }
  };

  if (isPaymentFetching) {
    return <ExternalLayout isLoading />;
  }

  if (payment?.isCanceled) {
    return <PaymentCanceledScreen accountEmail={payment.creatorAccountEmail} accountName={payment.orgName} />;
  }

  if (!isPaymentUpgradeLoading && (error || isPaymentFailed)) {
    return <PaymentUpgradeFailedScreen {...failedScreenProps} />;
  }

  return (
    <Routes>
      <Route
        path="index"
        element={
          <PaymentUpgradeIndexScreen
            payment={payment}
            paymentUpgradeOffers={paymentUpgradeOffers}
            isLoading={isPaymentUpgradeLoading || isPersistentFastPreferenceLoading}
          />
        }
      />
      {payment && (
        <>
          {p2dUpgradeOffer ? (
            <>
              <Route
                path="card-details"
                element={
                  <PaymentUpgradeTBTCardDetailsScreen
                    accountName={payment.orgName}
                    paymentAmount={payment.amount}
                    paymentInvoiceNumber={payment.invoiceNumber || undefined}
                    paymentNote={payment.note}
                    handleBack={isACHUpgradeSupported ? handleNavigateToConfirmScreen : undefined}
                    handleDone={handleSaveCardDetails}
                    isSaving={isCardTokenizingInProgress}
                    calculatedFee={p2dUpgradeOffer.calculatedFee}
                    feePercentValue={p2dUpgradeOffer.feeCatalog.value}
                  />
                }
              />
              <Route
                path="cardholder-details"
                element={
                  <PaymentUpgradeTBTCardHolderDetailsScreen
                    accountName={payment.orgName}
                    paymentAmount={payment.amount}
                    paymentInvoiceNumber={payment.invoiceNumber || undefined}
                    paymentNote={payment.note}
                    handleBack={handleNavigateToCardDetailsScreen}
                    handleSubmit={handleSubmitP2DUpgrade}
                    isSaving={isPaymentUpgradeSubmitting || isCreatingCardDeliveryMethod}
                    calculatedFee={p2dUpgradeOffer.calculatedFee}
                    feePercentValue={p2dUpgradeOffer.feeCatalog.value}
                    isCardDetailsFilled={Boolean(cardParams)}
                    isPaymentUpgraded={Boolean(paymentUpgradeType)}
                  />
                }
              />
            </>
          ) : null}
          {Boolean(paymentUpgradeOffers.length) && payment.deliveryEta && (
            <Route
              path="confirm"
              element={
                <PaymentUpgradeConfirmScreen
                  amount={payment.amount}
                  formattedDeliveryMethodDetails={formattedDeliveryMethodDetails}
                  invoiceNumber={payment.invoiceNumber}
                  note={payment.note}
                  orgName={payment.orgName}
                  paymentUpgradeOffers={paymentUpgradeOffers}
                  isSubmitting={isPaymentUpgradeSubmitting || isPersistentFastPreferenceUpdating}
                  // persistent fast is hidden from confirm screen during a/b test
                  // when rolling back, do not forget to re-enable integration tests
                  isPersistentFastOfferEnabled={false}
                  isPaymentUpgraded={Boolean(paymentUpgradeType)}
                  onNavigateToCardDetailsScreen={handleNavigateToCardDetailsScreen}
                  onSubmit={handleSubmitACHUpgrade}
                  persistentFastOfferLabels={persistentFastBannerLabels.offer}
                />
              }
            />
          )}
          {paymentUpgradeType && payment.deliveryEta && (
            <Route
              path="success"
              element={
                <PaymentUpgradeSuccessScreen
                  amount={payment.amount}
                  formattedDeliveryMethodDetails={formattedDeliveryMethodDetails}
                  orgName={payment.orgName}
                  offerType={paymentUpgradeType}
                  expeditedDeliveryEta={payment.deliveryEta}
                  paymentUpgradeOffer={getUpgradeOfferByType(paymentUpgradeOffers, paymentUpgradeType)}
                  isPersistentFastOfferEnabled={
                    isPersistentFastOfferEnabled && paymentUpgradeType !== PaymentUpgradeOfferOptionEnum.Debit
                  }
                  isPersistentFastPreferenceSuccessBannerVisible={isPersistentFastPreferenceCreated}
                  isPersistentFastPreferenceUpdating={isPersistentFastPreferenceUpdating}
                  onSavePersistentFastPreference={handleSavePersistentFastPreference}
                  persistentFastLabels={persistentFastBannerLabels}
                />
              }
            />
          )}
          {isPaymentCompleted && payment.deliveryEta && (
            <Route
              path="payment-delivered"
              element={
                <PaymentAlreadyDeliveredScreen
                  deliveryEta={payment.deliveryEta}
                  amount={payment.amount}
                  orgName={payment.orgName}
                  deliveryMethodType={payment.deliveryMethod?.type}
                  formattedDeliveryMethodDetails={formattedDeliveryMethodDetails}
                  offerType={paymentUpgradeType}
                />
              }
            />
          )}
        </>
      )}
    </Routes>
  );
});

export const PaymentUpgradeActivity: React.VFC<PaymentUpgradeActivityProps> = (props) => (
  <WithActivityConfig Context={PaymentUpgradeConfigContext} createConfig={createActivityConfig}>
    <Activity {...props} />
  </WithActivityConfig>
);

PaymentUpgradeActivity.displayName = 'PaymentUpgradeActivity';
