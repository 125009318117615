import { Group, Text } from '@melio/penny';
import React from 'react';

type ReviewDetailsSectionWrapperProps = {
  title: string;
  children: React.ReactNode;
};

export const DetailsSectionWrapper: React.FC<ReviewDetailsSectionWrapperProps> = ({ children, title }) => (
  <Group variant="vertical" spacing="xs-s">
    <Text color="global.neutral.1000" textStyle="body4Semi">
      {title}
    </Text>
    {children}
  </Group>
);

DetailsSectionWrapper.displayName = 'DetailsSectionWrapper';
