import { useBreakpointValue } from '@melio/penny';
import { useDevice } from '@melio/platform-utils';
// Should use constant device ratio breakpoints once defined DS level
const STICKY_FOOTER_DEVICE_RATIO_THRESHOLD = 3;

// Logic should consume from Penny once implemented
export const useIsStickyFooter = () => {
  const isStickyMode = useBreakpointValue({ xs: true, s: true, m: false });
  const { devicePixelRatio, isMobile, isTablet } = useDevice();
  return isMobile || isTablet || (isStickyMode && devicePixelRatio < STICKY_FOOTER_DEVICE_RATIO_THRESHOLD);
};
