/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

export const NotFoundScreen = () => {
  const { formatMessage } = usePlatformIntl();
  const { goToPayDashboard } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const { track } = useAnalytics();

  useEffect(() => {
    track('ErrorScreen', 'Viewed', {
      errorText: formatMessage('screens.notFound.title'),
      errorCode: 404,
      errorType: 'NotFound',
    });
  }, [track]);

  const helpCenterLink = (
    <Link href={partnerConfig.config.links.helpCenter} label={formatMessage('screens.notFound.helpCenter')} newTab />
  );

  return (
    <FullScreenContainer centered>
      <ErrorPage
        title={formatMessage('screens.notFound.title')}
        message={formatMessage('screens.notFound.message', { helpCenterLink })}
        primaryButton={{
          text: formatMessage('screens.notFound.button'),
          onClick: goToPayDashboard,
        }}
      />
    </FullScreenContainer>
  );
};
