// eslint-disable-next-line no-restricted-imports
import { ServerConfig } from '@melio/platform-api-axios-client';
import { useCallback, useMemo, useState } from 'react';

import { useDeleteExpiredOldAuthTokens } from './hooks/useDeleteExpiredAuthTokens';
import { getAuthLocalStorage, getRefreshAccessTokenFn } from './utils';

type UseAuthParams = {
  tokenIdentifier: string | undefined;
  onUnauthorized: () => void;
  apiBasePath: string;
};

export const useAuth = ({ tokenIdentifier, onUnauthorized, apiBasePath }: UseAuthParams) => {
  // We use the first tokenIdentifier value we get for the rest of the session.
  // We don't want it to be changed, because we then may return different access tokens.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authLocalStorage = useMemo(() => getAuthLocalStorage(tokenIdentifier), []);

  const [accessTokenValue, setAccessTokenValue] = useState<string | undefined>(() => {
    const accessTokenFromStorage = authLocalStorage.getAccessToken();
    return accessTokenFromStorage ?? undefined;
  });

  useDeleteExpiredOldAuthTokens();

  const setAccessTokens = useCallback(
    (accessToken: string, refreshToken: string | null) => {
      ServerConfig.update({ accessToken });
      authLocalStorage.setAccessToken(accessToken);

      if (refreshToken) {
        authLocalStorage.setRefreshToken(refreshToken);
      } else {
        authLocalStorage.deleteRefreshToken();
      }

      setAccessTokenValue(accessToken);
    },
    [authLocalStorage]
  );

  const refreshAccessToken = useMemo(
    () => getRefreshAccessTokenFn({ onUnauthorized, setAccessTokens, authLocalStorage, apiBasePath }),
    [apiBasePath, authLocalStorage, onUnauthorized, setAccessTokens]
  );

  return {
    setAccessTokens,
    accessToken: accessTokenValue,
    refreshAccessToken,
  };
};
