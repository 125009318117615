import {
  Card,
  Container,
  ExternalLayout,
  Group,
  SelectionCard,
  SelectionCardProps,
  Text,
  VerticalSelectionCard,
} from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { DeliveryMethod, VirtualAccountDeliveryMethod } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import {
  DeliveryMethodSelectionByPayeeHeaderWidget,
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  FooterWidget,
  HeaderAuthSection,
  PoweredByMelioWidget,
  useIsMobile,
} from '@melio/vex-widgets';

import { useNavigateToEmailVerification } from '../../../../../hooks/useNavigateToEmailVerification';

const isVendorFlow = (origin: SelectDeliveryMethodTypeScreenProps['origin']) => origin === 'vendor';

const getLabelsByOrigin = (origin: SelectDeliveryMethodTypeScreenProps['origin']) =>
  ({
    title: isVendorFlow(origin)
      ? 'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.title'
      : 'activities.addDeliveryMethodByPayee.screens.selectDeliveryMethodType.title',
    bankTitle: isVendorFlow(origin)
      ? 'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.bank.title'
      : 'activities.addDeliveryMethodByPayee.screens.selectDeliveryMethodType.bank.title',
    bankDescription: isVendorFlow(origin)
      ? 'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.bank.description'
      : 'activities.addDeliveryMethodByPayee.screens.selectDeliveryMethodType.bank.description',
    checkTitle: isVendorFlow(origin)
      ? 'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.check.title'
      : 'activities.addDeliveryMethodByPayee.screens.selectDeliveryMethodType.check.title',
    checkDescription: isVendorFlow(origin)
      ? 'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.check.description'
      : 'activities.addDeliveryMethodByPayee.screens.selectDeliveryMethodType.check.description',
  } as const);

export type SelectDeliveryMethodTypeScreenProps = DeliveryMethodSelectionByPayeeHeaderWidgetProps & {
  onDone: (type: Exclude<DeliveryMethod['type'], VirtualAccountDeliveryMethod['type']>) => void;
  origin: 'vendor' | 'payee';
  showPoweredByMelio?: boolean;
  showLoginHeaderSection: boolean;
  emailVerificationReturnUrl: string;
  userName?: string;
  isLoading?: boolean;
};

export type SelectionCardsProps = {
  handleBankAccountClick: VoidFunction | undefined;
  handlePaperCheckClick: VoidFunction | undefined;
  origin: 'vendor' | 'payee';
};
export const SelectionCards = ({ handleBankAccountClick, handlePaperCheckClick, origin }: SelectionCardsProps) => {
  const isMobile = useIsMobile();
  const { formatMessage } = useMelioIntl();
  const { bankTitle, bankDescription, checkTitle, checkDescription } = getLabelsByOrigin(origin);
  const {
    settings: { eoyCheck },
  } = useConfig();

  const mainLabelProps: SelectionCardProps['mainLabelProps'] = {
    label: formatMessage(checkTitle),
  };
  if (eoyCheck.enabled) {
    mainLabelProps.tooltipProps = {
      label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.check.tooltipEOYText`),
    };
  }

  if (isMobile) {
    return (
      <>
        <SelectionCard
          icon="fast"
          mainLabelProps={{
            label: formatMessage(bankTitle),
          }}
          descriptionProps={{
            label: formatMessage(bankDescription),
          }}
          data-testid="bank-delivery-method"
          onClick={handleBankAccountClick}
        />
        <SelectionCard
          icon="ship"
          mainLabelProps={mainLabelProps}
          descriptionProps={{
            label: formatMessage(checkDescription),
          }}
          data-testid="check-delivery-method"
          onClick={handlePaperCheckClick}
        />
      </>
    );
  } else {
    return (
      <Group>
        <VerticalSelectionCard
          icon="fast"
          mainLabelProps={{
            label: formatMessage(bankTitle),
          }}
          descriptionProps={{
            label: formatMessage(bankDescription),
          }}
          data-testid="bank-delivery-method"
          onClick={handleBankAccountClick}
        />
        <VerticalSelectionCard
          icon="ship"
          mainLabelProps={mainLabelProps}
          descriptionProps={{
            label: formatMessage(checkDescription),
          }}
          data-testid="check-delivery-method"
          onClick={handlePaperCheckClick}
        />
      </Group>
    );
  }
};

export const SelectDeliveryMethodTypeScreen = forwardRef<SelectDeliveryMethodTypeScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      onDone,
      origin,
      showPoweredByMelio,
      showLoginHeaderSection,
      emailVerificationReturnUrl,
      userName,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    useAnalyticsView('UnilateralSelectDeliveryMethodTypeScreen');
    const { track } = useAnalytics();
    const handleBankAccountClick = () => {
      track('UnilateralSelectDeliveryMethodTypeScreen', 'Click', { intent: 'choose ACH' });
      onDone('bank-account');
    };
    const handlePaperCheckClick = () => {
      track('UnilateralSelectDeliveryMethodTypeScreen', 'Click', { intent: 'choose Paper Check' });
      onDone('paper-check');
    };
    const { title } = getLabelsByOrigin(origin);

    const { navigateToEmailVerification } = useNavigateToEmailVerification(emailVerificationReturnUrl);

    const header = <HeaderAuthSection onLoginButtonClick={navigateToEmailVerification} loggedInUserName={userName} />;

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            <DeliveryMethodSelectionByPayeeHeaderWidget
              accountName={accountName}
              paymentAmount={paymentAmount}
              paymentInvoiceNumber={paymentInvoiceNumber}
              paymentNote={paymentNote}
            />
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" width="full">
              <Text textStyle="body2Semi" as="h1">
                {formatMessage(title)}
              </Text>
              <SelectionCards
                handleBankAccountClick={handleBankAccountClick}
                handlePaperCheckClick={handlePaperCheckClick}
                origin={origin}
              />
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddDeliveryMethodByPayeeActivity.SelectDeliveryMethodTypeScreen"
        data-testid="add-delivery-method-by-payee-activity-select-delivery-method-type-screen"
        {...props}
        header={showLoginHeaderSection ? header : undefined}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

SelectDeliveryMethodTypeScreen.displayName = 'AddDeliveryMethodByPayeeActivity.SelectDeliveryMethodTypeScreen';
