export const ROUTE_NUMBER_PREFIXES = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '80',
];

export enum ValidationError {
  INVALID_LENGTH,
  INVALID_CHARACTERS,
  INVALID_PREFIX,
  INVALID_HASH,
}

export const ROUTING_NUMBER_LENGTH = 9;
export const ACCOUNT_NUMBER_MIN_LENGTH = 6;
export const ACCOUNT_NUMBER_MAX_LENGTH = 17;

type ValidateRoutingNumberResult = { valid: true } | { valid: false; error: ValidationError };

export const validateRoutingNumber = (value = ''): ValidateRoutingNumberResult => {
  if (!/^[0-9]+$/.test(value)) {
    return { valid: false, error: ValidationError.INVALID_CHARACTERS };
  }

  if (value.length !== ROUTING_NUMBER_LENGTH) {
    return { valid: false, error: ValidationError.INVALID_LENGTH };
  }

  const prefix = value.substring(0, 2);

  if (!ROUTE_NUMBER_PREFIXES.includes(prefix)) {
    return { valid: false, error: ValidationError.INVALID_PREFIX };
  }

  const digits = value.split('').map(Number);

  const [d0, d1, d2, d3, d4, d5, d6, d7, d8] = digits;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sum = 3 * (d0! + d3! + d6!) + 7 * (d1! + d4! + d7!) + (d2! + d5! + d8!);

  if (sum % 10 !== 0) {
    return { valid: false, error: ValidationError.INVALID_HASH };
  }

  return { valid: true };
};
