import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const PageNotFound: React.VFC = () => (
  <LazyAnimation
    id="page-not-found"
    getAnimationData={() => import('./assets/page-not-found.lottie')}
    width="100%"
    height="100%"
  />
);
