import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import {
  AccountingPlatformConnectionStatus,
  AccountingPlatformsApiClient,
  AccountingPlatformSlug,
} from '@melio/platform-api-axios-client';

export type UseAccountingPlatformsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatforms
>;

export const useAccountingPlatforms = (props?: UseAccountingPlatformsProps) => {
  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatforms>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'getAccountingPlatforms'],
    queryFn: () => AccountingPlatformsApiClient.getAccountingPlatforms(),
  });

  const activeAccountingPlatform =
    query.data?.find((platform) => platform.connectionStatus === AccountingPlatformConnectionStatus.Connected) ||
    query.data?.find(
      (platform) =>
        platform.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop &&
        platform.connectionStatus === AccountingPlatformConnectionStatus.Error
    );

  return {
    ...query,
    activeAccountingPlatform,
    hasAccountingPlatform: !!activeAccountingPlatform,
  };
};
