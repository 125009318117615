export enum HttpStatusCodes {
  success = 200,
  created = 201,
  deleted = 204,
  BadRequest = 400,
  notFound = 404,
  Forbidden = 403,
  unauthorized = 401,
  conflict = 409,
  serverError = 500,
}
export enum HttpMethods {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  CONNECT = 'CONNECT',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
  PATCH = 'PATCH',
}
