import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  DeliveryMethodSelectionByPayeeHeaderWidget,
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  ExternalLayoutContentWidget,
  FooterWidget,
  HeaderAuthSection,
  PoweredByMelioWidget,
  VendorCheckDetailsPayeeCollapsedForm,
  VendorCheckDetailsPayeeForm,
} from '@melio/vex-widgets';

import { useNavigateToEmailVerification } from '../../../../../hooks/useNavigateToEmailVerification';
import { CheckAccountFormModel } from '../../../../../types';

type AddCheckDeliveryMethodScreenProps = Pick<
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  'accountName' | 'paymentAmount' | 'paymentNote' | 'paymentInvoiceNumber'
> & {
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<CheckAccountFormModel>;
  onBack: VoidFunction;
  onAddressNotFound: VoidFunction;
  onDone: (data: CheckAccountFormModel) => void;
  showNewAddressFlow: boolean;
  showPoweredByMelio?: boolean;
  showLoginHeaderSection: boolean;
  emailVerificationReturnUrl?: string;
  userName?: string;
};

export const AddCheckDeliveryMethodScreen = forwardRef<AddCheckDeliveryMethodScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      isSaving,
      defaultValues,
      onBack,
      onDone,
      onAddressNotFound,
      paymentInvoiceNumber,
      paymentNote,
      showPoweredByMelio,
      showNewAddressFlow,
      showLoginHeaderSection,
      emailVerificationReturnUrl,
      userName,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    usePageView('DeliveryMethod', { PageName: 'paper-check-delivery-method', Intent: 'add-delivery-method' });
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CheckAccountFormModel>();
    const { navigateToEmailVerification } = useNavigateToEmailVerification(emailVerificationReturnUrl || '');

    const legalInfoOverride = accountName
      ? formatMessage('vex.widgets.legal.infoWithAccountName', { accountName })
      : undefined;

    const header = <HeaderAuthSection onLoginButtonClick={navigateToEmailVerification} loggedInUserName={userName} />;

    const content = (
      <ExternalLayoutContentWidget
        header={{
          title: (
            <DeliveryMethodSelectionByPayeeHeaderWidget
              accountName={accountName}
              paymentNote={paymentNote}
              paymentInvoiceNumber={paymentInvoiceNumber}
              paymentAmount={paymentAmount}
              showAvatar={false}
            />
          ),
          backButtonProps: { isDisabled: cancelButtonProps?.isDisabled, onClick: onBack },
        }}
        form={{
          title: formatMessage('activities.addCheckDeliveryMethodByPayee.screens.addCheckDeliveryMethod.title'),
          fields: showNewAddressFlow ? (
            <VendorCheckDetailsPayeeCollapsedForm
              onSubmit={onDone}
              isSaving={isSaving}
              defaultValues={defaultValues}
              onEmptyStateClick={onAddressNotFound}
              onSubmissionStateChange={onSubmissionStateChange}
              size="small"
            />
          ) : (
            <VendorCheckDetailsPayeeForm
              onSubmit={onDone}
              isSaving={isSaving}
              defaultValues={defaultValues}
              onSubmissionStateChange={onSubmissionStateChange}
              size="small"
            />
          ),
        }}
        continueSection={{
          buttonProps: {
            ...submitButtonProps,
            label: formatMessage('activities.addCheckDeliveryMethodByPayee.screens.addCheckDeliveryMethod.continue'),
          },
          legalInfoOverride,
        }}
      />
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddCheckDeliveryMethodByPayeeActivity.AddCheckDeliveryMethodScreen"
        data-testid="add-check-delivery-method-by-payee-activity-add-check-delivery-method-screen"
        {...props}
        header={showLoginHeaderSection ? header : undefined}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

AddCheckDeliveryMethodScreen.displayName = 'AddCheckDeliveryMethodByPayeeActivity.AddCheckDeliveryMethodScreen';
