import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiKeys, VexGuestApiClient } from '@melio/platform-api-axios-client';

type OnboardingSessionData = {
  onboardingSessionUuid: string;
  options?: UseModelApiProps<typeof VexGuestApiClient.getVendorOnboardingSessionByUuid>;
};

export const useVendorOnboardingSession = ({ onboardingSessionUuid, options = {} }: OnboardingSessionData) =>
  useModelApi({
    ...options,
    id: onboardingSessionUuid,
    queryKey: [ApiKeys.VexFTIApi, 'getVendorOnboardingSessionByUuid', onboardingSessionUuid],
    queryFn: () => VexGuestApiClient.getVendorOnboardingSessionByUuid(onboardingSessionUuid),
  });
