import { TrackerTimelineStatusEnum } from '@melio/platform-api';

import { SuccessStepItem, TrackerTimelineStatusEnumWithoutFailed } from '../types';

export const getInternationalStatusTrackerSteps = ({
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
  trackerTimelineStatus,
}: {
  paymentCreatedAt: Date;
  paymentScheduledDate: Date;
  paymentDeliveryEta: Date;
  trackerTimelineStatus: TrackerTimelineStatusEnumWithoutFailed;
}): SuccessStepItem => {
  const internationalStatusSteps: Record<TrackerTimelineStatusEnumWithoutFailed, SuccessStepItem> = {
    [TrackerTimelineStatusEnum.SCHEDULED]: [
      {
        title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
          date: paymentCreatedAt,
        },
      },
      {
        title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.international.label' },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
          date: paymentScheduledDate,
        },
      },
      {
        title: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
        },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
          date: paymentDeliveryEta,
        },
      },
    ],
    [TrackerTimelineStatusEnum.SENT]: [
      {
        title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
          date: paymentCreatedAt,
        },
      },
      {
        title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.international.label' },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
          date: paymentScheduledDate,
        },
      },
      {
        title: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
        },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
          date: paymentDeliveryEta,
        },
      },
    ],
    [TrackerTimelineStatusEnum.DEPOSITED]: [
      {
        title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
          date: paymentCreatedAt,
        },
      },
      {
        title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.international.label' },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
          date: paymentScheduledDate,
        },
      },
      {
        title: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.delivered',
        },
        description: {
          messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
          date: paymentDeliveryEta,
        },
      },
    ],
  };
  return internationalStatusSteps[trackerTimelineStatus];
};
