/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@melio/penny';
import { BankAccountDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';
import { useEffect, useState } from 'react';

import { BankAccountFormModel } from '../../../types';
import { useActivityContext } from '../../add-delivery-method-by-unilateral/AddDeliveryMethodByUnilateral.config';
import { DeliveryMethodAddedSuccessfullyWithoutPaymentScreen } from '../screens';
import { AddBankDeliveryMethodScreen } from './screens';
import { AddBankDeliveryMethodByVendorActivityProps } from './types';
import { useAddBankDeliveryMethodByPayeeStep } from './useAddBankDeliveryMethodByPayeeStep';

export const AddBankDeliveryMethodByVendorActivity: React.VFC<AddBankDeliveryMethodByVendorActivityProps> = ({
  vendorId,
  accountName,
  onBack: onFirstStepBack,
  onError,
  onDone,
}) => {
  const { data: vendor, isLoading: isLoadingVendor } = useVendor({ id: vendorId });

  const { create: createVendorDeliveryMethod, isMutating: isCreatingDeliveryMethod } = useDeliveryMethods({
    vendorId,
    enabled: false,
  });

  const { toast } = useToast();

  const [newDeliveryMethod, setNewDeliveryMethod] = useState<BankAccountDeliveryMethod>();

  const { currentStep, goToStep, goToPreviousStep } = useAddBankDeliveryMethodByPayeeStep({ onFirstStepBack });

  const { showPoweredByMelio, showLoginHeaderSection, emailVerificationReturnUrl } = useActivityContext();

  useEffect(() => {
    if (newDeliveryMethod) {
      goToStep('BANK_DETAILS_ADDED_SUCCESSFULLY');
    }
  }, [newDeliveryMethod?.id]);

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const createDeliveryMethod = (data: BankAccountFormModel) => {
    createVendorDeliveryMethod({
      type: 'bank-account',
      details: {
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        accountType: 'checking',
      },
    })
      .then((deliveryMethod) => {
        setNewDeliveryMethod(deliveryMethod as BankAccountDeliveryMethod);
        onDone(deliveryMethod.id);
      })
      .catch(handleFail);
  };

  const isLoading = !vendor || (isLoadingVendor && !newDeliveryMethod);

  switch (currentStep) {
    case 'ADD_BANK_DETAILS':
    default:
      return (
        <AddBankDeliveryMethodScreen
          accountName={accountName}
          onBack={goToPreviousStep}
          onDone={createDeliveryMethod}
          isSaving={isCreatingDeliveryMethod || isLoadingVendor}
          showPoweredByMelio={showPoweredByMelio}
          showLoginHeaderSection={showLoginHeaderSection}
          emailVerificationReturnUrl={emailVerificationReturnUrl}
          isLoading={isLoading}
        />
      );

    case 'BANK_DETAILS_ADDED_SUCCESSFULLY':
      return (
        <DeliveryMethodAddedSuccessfullyWithoutPaymentScreen
          accountName={accountName}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          deliveryMethod={newDeliveryMethod!}
          isLoading={isLoading}
        />
      );
  }
};
