import { useAnalytics } from '@melio/platform-analytics';
import {
  PartnerName,
  useAccount,
  useResolveEmailVerificationCode,
  UserRegistrationFlowEnum,
  useSendEmailValidationCode,
  useVexMyOrganization,
} from '@melio/platform-api';
import { VerificationCodeFailureResult } from '@melio/vex-widgets';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { NOT_FOUND_PAGE } from '../../shared';
import { CodeVerificationScreen, EmailLoginScreen } from './screens';

export type EmailVerificationActivityProps = {
  children?: React.ReactNode;
  setAccessTokens: (accessToken: string, refreshToken: string | null) => void;
  showPoweredByMelio?: boolean;
  helpLinkHref: string;
  defaultReturnUrl?: string;
};

const LOGIN_QUERY_PARAMS = {
  returnUrl: 'returnUrl',
  emailAddress: 'emailAddress',
} as const;

const handleCodeVerificationFailure = (error: PlatformError) => {
  if (error.code === '404' && error.message === 'User not found') {
    throw VerificationCodeFailureResult.UserNotFoundError;
  }
  throw error;
};

export const EmailVerificationActivity: React.VFC<EmailVerificationActivityProps> = ({
  setAccessTokens,
  showPoweredByMelio,
  helpLinkHref,
  defaultReturnUrl = NOT_FOUND_PAGE,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const returnUrl = searchParams.get(LOGIN_QUERY_PARAMS.returnUrl);
  const emailAddress = searchParams.get(LOGIN_QUERY_PARAMS.emailAddress);

  /** @description Vendor user can be only melio's partner  */
  const partnerName = PartnerName.Melio;

  const navigateToReturnUrl = () => navigate(returnUrl || defaultReturnUrl);

  const backToEmailScreen = () =>
    navigate({
      pathname: '../email-verification',
      search: searchParams.toString(),
    });

  const { track } = useAnalytics();

  const {
    isLoading: isSendingEmail,
    error: sendEmailError,
    mutateAsync: sendVerificationEmailCode,
  } = useSendEmailValidationCode();

  const { mutateAsync: resolveEmailVerificationCode } = useResolveEmailVerificationCode();

  const { invalidateQuery: invalidateAccountQuery } = useAccount({ id: 'me' });
  const { removeQuery: invalidateVexOrganizationQuery } = useVexMyOrganization();
  const onSendEmailVerificationCode = async (email: string) => {
    await sendVerificationEmailCode({
      email,
      partnerName,
    });

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('returnUrl', returnUrl || defaultReturnUrl);
    newSearchParams.append('emailAddress', email);
    navigate({
      pathname: 'code',
      search: newSearchParams.toString(),
    });
    return;
  };

  const onReSendEmailVerificationCode = (email: string) => {
    sendVerificationEmailCode({
      email,
      partnerName,
    }).catch((error) => {
      track('Vendor', 'Status', {
        StatusType: 'Failure',
        ErrorType: typeof error === 'string' ? error.toString() : 'unknown',
      });
      backToEmailScreen();
    });
  };

  const onSubmitCodeVerification = async (code: string, email: string) => {
    try {
      const { accessToken, refreshToken } = await resolveEmailVerificationCode({
        verificationCode: parseInt(code, 10),
        email,
        partnerName,
        registrationFlow: UserRegistrationFlowEnum.UnilateralWithEnrollment,
      });
      setAccessTokens(accessToken, refreshToken);
      await invalidateAccountQuery({ id: 'me' });
      invalidateVexOrganizationQuery();
      navigateToReturnUrl();
    } catch (e) {
      handleCodeVerificationFailure(e as PlatformError);
    }
    return true;
  };

  return (
    <Routes>
      <Route
        index
        element={
          <EmailLoginScreen
            onBack={navigateToReturnUrl}
            onDone={onSendEmailVerificationCode}
            showPoweredByMelio={showPoweredByMelio}
            isSaving={isSendingEmail}
            errorMessage={sendEmailError?.message}
          />
        }
      />
      <Route
        path="code"
        element={
          <CodeVerificationScreen
            generateNewCode={onReSendEmailVerificationCode}
            onBack={backToEmailScreen}
            onUserNotFound={navigateToReturnUrl}
            onDone={onSubmitCodeVerification}
            email={emailAddress}
            helpLinkHref={helpLinkHref}
            showPoweredByMelio={showPoweredByMelio}
          />
        }
      />
    </Routes>
  );
};
