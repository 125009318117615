import { PaymentDeliveryPreferenceEnum, TrackerTimelineStatusEnum } from '@melio/platform-api';

import { SuccessStepItem, TrackerTimelineStatusEnumWithoutFailed } from '../types';

export const getRtpStatusTrackerSteps = ({
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
}: {
  paymentCreatedAt: Date;
  paymentScheduledDate: Date;
  paymentDeliveryEta: Date;
}): Record<TrackerTimelineStatusEnumWithoutFailed, SuccessStepItem> => ({
  [TrackerTimelineStatusEnum.SCHEDULED]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.rtp.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
  [TrackerTimelineStatusEnum.SENT]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.rtp.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
      },
      description: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.deposited.withinMinutes' },
    },
  ],
  [TrackerTimelineStatusEnum.DEPOSITED]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.rtp.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.delivered',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
});

export const getFactAchStatusTrackerSteps = ({
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
}: {
  paymentCreatedAt: Date;
  paymentScheduledDate: Date;
  paymentDeliveryEta: Date;
}): Record<TrackerTimelineStatusEnumWithoutFailed, SuccessStepItem> => ({
  [TrackerTimelineStatusEnum.SCHEDULED]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.fastAch.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
  [TrackerTimelineStatusEnum.SENT]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.fastAch.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
      },
      description: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.deposited.today' },
    },
  ],
  [TrackerTimelineStatusEnum.DEPOSITED]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.fastAch.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.delivered',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
});

export const getAchStatusTrackerSteps = ({
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
}: {
  paymentCreatedAt: Date;
  paymentScheduledDate: Date;
  paymentDeliveryEta: Date;
}): Record<TrackerTimelineStatusEnumWithoutFailed, SuccessStepItem> => ({
  [TrackerTimelineStatusEnum.SCHEDULED]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.ach.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
  [TrackerTimelineStatusEnum.SENT]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.ach.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.estimatedDeliveryTime',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
  [TrackerTimelineStatusEnum.DEPOSITED]: [
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentCreatedAt,
      },
    },
    {
      title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.sent.ach.label' },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
        date: paymentScheduledDate,
      },
    },
    {
      title: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.delivered',
      },
      description: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.deliveryDate',
        date: paymentDeliveryEta,
      },
    },
  ],
});

export const handleAchDeliveryMethodSteps = ({
  deliveryPreference,
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
  trackerTimelineStatus,
}: {
  paymentCreatedAt: Date;
  paymentScheduledDate: Date;
  paymentDeliveryEta: Date;
  trackerTimelineStatus: TrackerTimelineStatusEnumWithoutFailed;

  deliveryPreference?: PaymentDeliveryPreferenceEnum | null;
}): SuccessStepItem => {
  let trackerSteps;
  if (deliveryPreference === PaymentDeliveryPreferenceEnum.Rtp) {
    trackerSteps = getRtpStatusTrackerSteps({ paymentCreatedAt, paymentScheduledDate, paymentDeliveryEta });
  } else if (deliveryPreference === PaymentDeliveryPreferenceEnum.ExpeditedAch) {
    trackerSteps = getFactAchStatusTrackerSteps({
      paymentDeliveryEta,
      paymentScheduledDate,
      paymentCreatedAt,
    });
  } else {
    trackerSteps = getAchStatusTrackerSteps({ paymentScheduledDate, paymentDeliveryEta, paymentCreatedAt });
  }

  return trackerSteps[trackerTimelineStatus];
};
