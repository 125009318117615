import { Group, StatusIconOutlined, Text, Tooltip } from '@melio/penny';
import { PaymentDeliveryPreferenceEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const DeliveryNachaNumber = ({
  deliveryNachaNumber,
  deliveryPreference,
}: {
  deliveryNachaNumber: string | null;
  deliveryPreference?: PaymentDeliveryPreferenceEnum | null;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxxs" data-testid="delivery-nacha-number">
      <Group spacing="xxs">
        <Text textStyle="body4Semi" color="global.neutral.800">
          {formatMessage(
            deliveryPreference === PaymentDeliveryPreferenceEnum.Rtp
              ? 'vex.activities.vendorPaymentTracking.deliveryNachaNumber.traceNumber'
              : 'vex.activities.vendorPaymentTracking.deliveryNachaNumber.nachaTraceNumber'
          )}
        </Text>
        <Tooltip isEnabled label={formatMessage('vex.activities.vendorPaymentTracking.deliveryNachaNumber.tooltip')}>
          <StatusIconOutlined size="small" variant="neutral" />
        </Tooltip>
      </Group>
      <Text textStyle="body4">{deliveryNachaNumber}</Text>
    </Group>
  );
};
