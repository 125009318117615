import {
  Card,
  Container,
  ExternalLayout,
  Group,
  Illustration,
  IllustrationProps,
  StatusIconSolid,
  Text,
} from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { FooterWidget, PoweredByMelioWidget } from '@melio/vex-widgets';

export type BaseErrorScreenProps = {
  description: string;
  title?: string;
  showPoweredByMelio?: boolean;
  trackBaseError?: TrackBaseErrorType;
  'data-testid'?: string;
  withIllustration?: boolean;
  illustrationProps?: IllustrationProps;
};
export type TrackBaseErrorType = {
  name: string;
};

export const BaseErrorScreen = forwardRef<BaseErrorScreenProps, 'div'>(
  (
    { description, showPoweredByMelio, withIllustration, illustrationProps, children, title, trackBaseError, ...props },
    ref
  ) => {
    const pageEvent = trackBaseError?.name || 'ErrorScreen';
    const eventParams = {
      Intent: pageEvent,
      description,
    };
    usePageView(pageEvent, eventParams);
    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none">
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="s">
              {withIllustration && illustrationProps ? (
                <Container>
                  <Illustration {...illustrationProps} />
                </Container>
              ) : (
                <StatusIconSolid variant="alert" size="large" />
              )}
              {title && (
                <Text as="h1" textStyle="body2Semi">
                  {title}
                </Text>
              )}
              <Text>{description}</Text>
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;
    return (
      <ExternalLayout
        data-component="BaseErrorScreen"
        data-testid={props['data-testid'] ?? 'base-error-screen'}
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

BaseErrorScreen.displayName = 'BaseErrorScreen';
