import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const Notification: React.VFC = () => (
  <LazyAnimation
    id="notification"
    getAnimationData={() => import('./assets/notification.lottie')}
    width="100%"
    height="100%"
  />
);
