import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformItemsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatformsItemsById
> & { accountingPlatformId?: string; noInvalidations?: boolean };

export const useAccountingPlatformItems = (props: UseAccountingPlatformItemsProps) => {
  const { accountingPlatformId, noInvalidations } = props;
  const meta = { noInvalidations };
  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatformsItemsById>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'items', accountingPlatformId],
    queryFn: () => AccountingPlatformsApiClient.getAccountingPlatformsItemsById(accountingPlatformId as string),
    enabled: !!accountingPlatformId && props.enabled,
    meta,
  });

  return {
    ...query,
    hasAccountingPlatformItems: !!query.data?.length,
  };
};
