import { useNonEntityMutation } from '@melio/api-client';
import {
  ApiKeys,
  GetVirtualCardAcceptanceResponse,
  VexGuestApiClient,
  VexGuestApiInstance,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

import { ApiError } from '../../core';

type CreateFunction = typeof VexGuestApiClient.postVexReActivateVirtualCard;
export const useVirtualCardAcceptance = (paymentId: string) =>
  useQuery<GetVirtualCardAcceptanceResponse, ApiError>({
    queryKey: ['VexGuestApiInstance', 'getVirtualCard', paymentId],
    queryFn: async () => {
      const { data } = await VexGuestApiInstance.getVirtualCard({ id: paymentId });
      return data;
    },
  });

export const useReActivateVirtualCard = () =>
  useNonEntityMutation<CreateFunction>(
    (virtualCardId) => VexGuestApiClient.postVexReActivateVirtualCard(virtualCardId),
    [ApiKeys.VexGuestApi, 'collection', 'default']
  );

export type { GetVirtualCardAcceptanceResponse };
