import {
  GetMeOrgData,
  useAccount,
  useSwitchOrganization,
  useVexMyOrganization,
  useVexMyOrganizations,
} from '@melio/platform-api';
import { useEffect, useState } from 'react';

import { useSessionConfig } from '../../../contexts';

export type UseVendorMultiOrganizationsProps = {
  accountFetchEnabled: boolean;
  vexOrgListFetchEnabled: boolean;
};

export type UseVendorMultiOrganizationsReturnType = {
  isSwitchingOrganization: boolean;
  selectedOrganizationId: string;
  onSwitchOrganization: (organizationId: string) => Promise<void>;
  isLoadingOrganizationList: boolean;
  organizationsList: GetMeOrgData[] | undefined;
};

export const useVendorMultiOrganizations = ({
  accountFetchEnabled,
  vexOrgListFetchEnabled,
}: UseVendorMultiOrganizationsProps): UseVendorMultiOrganizationsReturnType => {
  const { setAccessTokens } = useSessionConfig();
  const [isSwitchingOrganization, setIsSwitchingOrganization] = useState(false);
  const { mutateAsync: switchOrganization } = useSwitchOrganization(setAccessTokens);
  const { refetch: refetchAccount, data: accountMeData } = useAccount({ id: 'me', enabled: accountFetchEnabled });
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(accountMeData?.organizationId ?? '');
  const { removeQuery: removeVexOrgQuery } = useVexMyOrganization({ enabled: false });
  const { data: organizationsList, isLoading: isLoadingOrganizationList } = useVexMyOrganizations({
    enabled: vexOrgListFetchEnabled,
  });

  const onSwitchOrganization = async (organizationId: string) => {
    try {
      setIsSwitchingOrganization(true);
      await switchOrganization(organizationId);
      await refetchAccount();
      removeVexOrgQuery();
      setSelectedOrganizationId(organizationId);
    } finally {
      setIsSwitchingOrganization(false);
    }
  };

  useEffect(() => {
    if (accountMeData?.organizationId) {
      setSelectedOrganizationId(accountMeData.organizationId);
    }
  }, [accountMeData?.organizationId]);

  return {
    organizationsList,
    isLoadingOrganizationList,
    isSwitchingOrganization,
    selectedOrganizationId,
    onSwitchOrganization,
  };
};
