/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { EventProperties, ViewContext } from '../types';
import { useBaseAnalytics } from './useAnalytics';

export const useAnalyticsView = (
  viewContext: ViewContext,
  shouldTrack = true,
  isNewAction = false,
  properties?: EventProperties
) => {
  const { setViewContext } = useBaseAnalytics();

  useEffect(() => {
    if (shouldTrack) {
      setViewContext(viewContext, isNewAction, properties);
    }
  }, [shouldTrack]);
};
