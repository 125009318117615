import { createQueryKey, useCreateMutation } from '@melio/api-client';
import { ApiKeys, VendorPreferredPremiumMethodsEnum, VexGuestVendorApiClient } from '@melio/platform-api-axios-client';

type CreateVendorPreferredPremiumMethodsParams = {
  vendorId: string;
  value: VendorPreferredPremiumMethodsEnum;
};

export const useVendorPreferredPremiumMethod = () =>
  useCreateMutation(
    ({ vendorId, value }: CreateVendorPreferredPremiumMethodsParams) =>
      VexGuestVendorApiClient.postVexGuestVendorPreferencesPremiumMethods(vendorId, { value }),
    createQueryKey({ queryKey: ApiKeys.VexGuestVendorApi, role: 'model', scope: 'default' })
  );
