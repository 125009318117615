import { Card, ExternalLayout } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { PayorPendingPaymentBanner, PoweredByMelioFooterWidget } from '@melio/vex-widgets';

type ActionBlockedPaymentCanceledScreenProps = {
  accountName: string;
};

export const ActionBlockedPaymentCanceledScreen = forwardRef<ActionBlockedPaymentCanceledScreenProps, 'div'>(
  ({ accountName, children, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    useAnalyticsView('PushToDebitPayorPaymentIsCancelled');

    const content = (
      <Card paddingX="xl" paddingY="l">
        <PayorPendingPaymentBanner
          variant="warning"
          description={formatMessage('activities.pushToDebit.screens.actionBlockedPaymentCanceled.description', {
            accountName,
          })}
        />
      </Card>
    );

    const footer = <PoweredByMelioFooterWidget />;

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.ActionBlockedPaymentCanceledScreen"
        data-testid="push-to-debit-activity-action-blocked-payment-canceled-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

ActionBlockedPaymentCanceledScreen.displayName = 'PushToDebitActivity.ActionBlockedPaymentCanceledScreen';
