import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';
export const Together: React.VFC = () => (
  <LazyAnimation
    id="together"
    getAnimationData={() => import('./assets/together.lottie')}
    width="100%"
    height="100%"
    loop={false}
  />
);
