import { Card, Container, ExternalLayout, Group, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { ApiError, BankAccountDeliveryMethod, PaperCheckDeliveryMethod } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import {
  AcceptPaymentForExistsDeliveryMethod,
  DeliveryMethodSelectionByPayeeHeaderWidget,
  FooterWidget,
  HeaderAuthSection,
  PoweredByMelioWidget,
} from '@melio/vex-widgets';

export type AcceptPaymentForExistsDeliveryMethodScreenProps = {
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod;
  accountName: string;
  paymentAmount: number;
  isSaving?: boolean;
  onDone: VoidFunction;
  showPoweredByMelio: boolean;
  companyName: string;
  error?: ApiError;
};
export const AcceptPaymentForExistsDeliveryMethodScreen = forwardRef<
  AcceptPaymentForExistsDeliveryMethodScreenProps,
  'div'
>(
  (
    {
      accountName,
      paymentAmount,
      isSaving,
      onDone,
      showPoweredByMelio,
      deliveryMethod,
      companyName,
      error,
      children,
      ...props
    },
    ref
  ) => {
    useAnalyticsView('AcceptPaymentForExistsDeliveryMethodScreen');
    const { track } = useAnalytics();
    const { toast } = useToast();

    const handleDone = () => {
      track('AcceptPaymentForExistsDeliveryMethod', 'Submitted');
      onDone();
    };

    if (error) {
      toast({ type: 'error', title: error.message });
    }

    const header = <HeaderAuthSection loggedInUserName={accountName} onLoginButtonClick={() => null} />;

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            <DeliveryMethodSelectionByPayeeHeaderWidget
              accountName={companyName}
              paymentAmount={paymentAmount}
              showAvatar
            />
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical">
              <AcceptPaymentForExistsDeliveryMethod
                companyName={companyName}
                isSaving={isSaving}
                onDone={handleDone}
                deliveryMethod={deliveryMethod}
              />
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AcceptPaymentForExistsDeliveryMethodScreen"
        data-testid="accept-payment-for-exists-delivery-method-screen"
        {...props}
        header={header}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

AcceptPaymentForExistsDeliveryMethodScreen.displayName = 'AcceptPaymentForExistsDeliveryMethodScreen';
