import { Card, ExternalLayout } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { PayorPendingPaymentBanner, PoweredByMelioFooterWidget } from '@melio/vex-widgets';

export type ActionBlockedIneligibleScreenProps = {
  accountName: string;
};

export const ActionBlockedIneligibleScreen = forwardRef<ActionBlockedIneligibleScreenProps, 'div'>(
  ({ accountName, children, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const content = (
      <Card paddingX="xl" paddingY="l">
        <PayorPendingPaymentBanner
          variant="warning"
          description={formatMessage('activities.pushToDebit.screens.actionBlockedIneligible.description', {
            accountName,
          })}
        />
      </Card>
    );

    const footer = <PoweredByMelioFooterWidget />;

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.ActionBlockedIneligibleScreen"
        data-testid="push-to-debit-activity-action-blocked-ineligible-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

ActionBlockedIneligibleScreen.displayName = 'PushToDebitActivity.ActionBlockedIneligibleScreen';
