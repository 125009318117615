import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const NetworkError: React.VFC = () => (
  <LazyAnimation
    id="network-error"
    getAnimationData={() => import('./assets/network-error.lottie')}
    width="100%"
    height="100%"
  />
);
