import { ChangeEvent, FieldError, FieldErrorType } from '@basis-theory/basis-theory-js/types/elements';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { ElementTypeName } from './types';

const getKeyString = (elementType: ElementTypeName) => {
  switch (elementType) {
    case 'cardNumber':
      return 'cardNumber';
    case 'expirationDate':
      return 'cardExpiration';
    case 'verificationCode':
      return 'cardVerificationCode';
  }
};
const getFirstErrorType = (errors: FieldError[] | undefined): FieldErrorType | undefined => errors?.[0]?.type;

export const useErrorMessages = () => {
  const { formatMessage } = useMelioIntl();

  const errorMessage = useCallback(
    (elementType: ElementTypeName, event: ChangeEvent) => {
      if (event.complete) {
        return undefined;
      }
      const keyString = getKeyString(elementType);
      const errorType = getFirstErrorType(event.errors);
      switch (errorType) {
        case 'incomplete':
          return { message: formatMessage(`vex.widgets.tbtForm.${keyString}.validation.required`) };
        case 'invalid':
          return { message: formatMessage(`vex.widgets.tbtForm.${keyString}.validation.format`) };
        default:
          return undefined;
      }
    },
    [formatMessage]
  );
  return { errorMessage };
};
