export const DiscoverGray = () => (
  <svg width="72" height="12" viewBox="0 0 72 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.4013 7.83498L45.3841 0.258995H42.9731L47.7744 11.8271H48.9621L53.8501 0.258995H51.4577L48.4013 7.83498Z"
      fill="#8E8E90"
    />
    <path
      d="M18.2129 4.58714C16.8886 4.09862 16.4997 3.77641 16.4997 3.16703C16.4997 2.45671 17.1925 1.91689 18.1437 1.91689C18.805 1.91689 19.3485 2.18765 19.9235 2.83035L21.0782 1.32299C20.1292 0.49557 18.9939 0.0725298 17.7534 0.0725298C15.7516 0.0725298 14.2246 1.45839 14.2246 3.30433C14.2246 4.85822 14.9356 5.65377 17.0084 6.39741C17.8723 6.70117 18.312 6.90358 18.5339 7.03975C18.9749 7.32679 19.1957 7.7331 19.1957 8.20676C19.1957 9.12066 18.4668 9.79773 17.4821 9.79773C16.4295 9.79773 15.5816 9.27294 15.0735 8.2934L13.6477 9.66189C14.6645 11.1498 15.8857 11.8092 17.5649 11.8092C19.8584 11.8092 21.4672 10.2891 21.4672 8.10561C21.4672 6.31364 20.7234 5.50246 18.2129 4.58714Z"
      fill="#8E8E90"
    />
    <path
      d="M22.1636 5.90619C22.1636 9.22144 24.775 11.7921 28.1356 11.7921C29.0856 11.7921 29.8992 11.6059 30.9026 11.1348V8.54501C30.0203 9.4253 29.2389 9.78032 28.2382 9.78032C26.0156 9.78032 24.438 8.17391 24.438 5.88995C24.438 3.72445 26.0654 2.01626 28.1356 2.01626C29.1882 2.01626 29.985 2.3907 30.9026 3.28534V0.696846C29.9339 0.206914 29.1372 0.00401783 28.1877 0.00401783C24.8441 0.00401783 22.1636 2.62672 22.1636 5.90619Z"
      fill="#8E8E90"
    />
    <path
      d="M65.4174 5.45156H64.7727V2.03549H65.4522C66.8267 2.03549 67.5739 2.60952 67.5739 3.70721C67.5739 4.8411 66.8267 5.45156 65.4174 5.45156ZM69.845 3.58874C69.845 1.47721 68.3859 0.258945 65.8408 0.258945H62.5684V11.5377H64.7727V7.00687H65.0604L68.1152 11.5377H70.8293L67.2675 6.78612C68.9297 6.44924 69.845 5.31572 69.845 3.58874Z"
      fill="#8E8E90"
    />
    <path
      d="M54.8481 11.5377H61.1069V9.62832H57.0533V6.58383H60.9574V4.67334H57.0533V2.16992H61.1069V0.258945H54.8481V11.5377Z"
      fill="#8E8E90"
    />
    <path d="M10.6096 11.5377H12.8166V0.258945H10.6096V11.5377Z" fill="#8E8E90" />
    <path
      d="M6.09844 8.73179C5.40502 9.35599 4.50424 9.62832 3.07826 9.62832H2.48596V2.16992H3.07826C4.50424 2.16992 5.36956 2.42428 6.09844 3.08208C6.86169 3.7596 7.32087 4.80939 7.32087 5.88991C7.32087 6.97262 6.86169 8.05455 6.09844 8.73179ZM3.52035 0.258945H0.280762V11.5377H3.50325C5.21684 11.5377 6.45413 11.1347 7.54029 10.2357C8.8309 9.17091 9.59415 7.56608 9.59415 5.90615C9.59415 2.57765 7.09946 0.258945 3.52035 0.258945Z"
      fill="#8E8E90"
    />
    <path
      d="M70.8354 0.81817H70.7953V0.559086H70.8379C70.9554 0.559086 71.0161 0.601217 71.0161 0.686568C71.0161 0.774303 70.9546 0.81817 70.8354 0.81817ZM71.2536 0.682933C71.2536 0.485247 71.117 0.377154 70.8772 0.377154H70.5579V1.36853H70.7953V0.984025L71.0737 1.36853H71.3634L71.0364 0.959708C71.1762 0.922142 71.2536 0.820553 71.2536 0.682933Z"
      fill="#8E8E90"
    />
    <path
      d="M70.9217 1.58076C70.5416 1.58076 70.2308 1.26549 70.2308 0.872143C70.2308 0.477378 70.5376 0.162268 70.9217 0.162268C71.2997 0.162268 71.6081 0.484608 71.6081 0.872143C71.6081 1.26218 71.2997 1.58076 70.9217 1.58076ZM70.9249 0.00667334C70.4419 0.00667334 70.0586 0.390895 70.0586 0.87089C70.0586 1.35056 70.4464 1.73523 70.9249 1.73523C71.3954 1.73523 71.7807 1.34644 71.7807 0.87089C71.7807 0.397681 71.3954 0.00667334 70.9249 0.00667334Z"
      fill="#8E8E90"
    />
    <circle cx="37.7808" cy="6" r="6" fill="#8E8E90" />
  </svg>
);
