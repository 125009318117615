export const BankMissing: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11828_10664)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#98D5EC"
      />
      <mask
        id="mask0_11828_10664"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11828_10664)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7852 98.8003H119.932V126.393H22.7852V98.8003Z"
          fill="#273B49"
        />
        <path d="M110.923 95.6548H32.8635V98.8004H110.923V95.6548Z" fill="white" />
        <path d="M102.369 55.8105H40.3489V89.364H102.369V55.8105Z" fill="#DCD7DA" />
        <path d="M107.716 92.5093H35.0027V95.6549H107.716V92.5093Z" fill="#DCD7DA" />
        <path d="M105.576 89.3638H37.1405V92.5094H105.576V89.3638Z" fill="white" />
        <path d="M49.9722 51.6162H43.5564V89.3638H49.9722V51.6162Z" fill="white" />
        <path d="M59.596 51.6162H53.1802V89.3638H59.596V51.6162Z" fill="white" />
        <path d="M89.5368 51.6162H83.121V89.3638H89.5368V51.6162Z" fill="white" />
        <path d="M99.1606 51.6162H92.7448V89.3638H99.1606V51.6162Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.973 57.9073H38.7453C38.4245 57.9073 38.1037 57.8025 37.8899 57.488L35.7512 54.6569C35.3235 54.1326 35.5374 53.5034 36.072 53.0841L70.8245 32.0083C71.1453 31.7987 71.573 31.7987 72.0007 32.0083L106.753 53.0841C107.288 53.3987 107.395 54.1326 107.074 54.6569L104.935 57.488C104.615 57.8025 104.401 57.9073 103.973 57.9073Z"
          fill="white"
        />
        <path d="M77.7738 68.3926H64.9421V89.3635H77.7738V68.3926Z" fill="#C2C0C5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3591 51.6163C74.3118 51.6163 76.7057 49.269 76.7057 46.3735C76.7057 43.4781 74.3118 41.1309 71.3591 41.1309C68.4063 41.1309 66.0126 43.4781 66.0126 46.3735C66.0126 49.269 68.4063 51.6163 71.3591 51.6163Z"
          fill="#DCD7DA"
        />
      </g>
      <g clipPath="url(#clip1_11828_10664)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.5 45.27C119.956 45.27 126 39.1654 126 31.635C126 24.1046 119.956 18 112.5 18C105.044 18 99 24.1046 99 31.635C99 39.1654 105.044 45.27 112.5 45.27Z"
          fill="#273B49"
        />
        <path
          d="M112.798 38.9135C112.631 39.0948 112.433 39.1854 112.203 39.1854C111.973 39.1854 111.768 39.0955 111.587 38.9156C111.405 38.7358 111.315 38.5309 111.315 38.3011C111.315 38.097 111.405 37.9043 111.584 37.723C111.764 37.5417 111.969 37.451 112.199 37.451C112.429 37.451 112.628 37.541 112.796 37.7208C112.965 37.9007 113.049 38.0927 113.049 38.2968C113.049 38.5266 112.965 38.7321 112.798 38.9135Z"
          fill="white"
        />
        <path
          d="M112.509 34.4062H111.584C111.584 33.841 111.745 33.2372 112.066 32.5948C112.387 31.9524 112.908 31.2972 113.627 30.6292C114.141 30.1667 114.539 29.717 114.822 29.2802C115.105 28.8434 115.246 28.3552 115.246 27.8156C115.246 27.0448 114.989 26.3703 114.475 25.7922C113.961 25.2141 113.177 24.925 112.124 24.925C111.302 24.925 110.64 25.1049 110.139 25.4646C109.638 25.8243 109.233 26.3253 108.925 26.9677L108 26.5823C108.385 25.8885 108.951 25.2847 109.696 24.7708C110.441 24.2569 111.289 24 112.24 24C113.324 24 114.237 24.3597 114.98 25.0792C115.722 25.7986 116.094 26.7194 116.094 27.8416C116.094 28.4923 115.94 29.0875 115.631 29.6271C115.323 30.1667 114.809 30.7833 114.09 31.4771C113.499 32.0167 113.094 32.4984 112.876 32.9224C112.657 33.3464 112.535 33.841 112.509 34.4062Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11828_10664">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
      <clipPath id="clip1_11828_10664">
        <rect width="27.27" height="27.27" fill="white" transform="translate(99 18)" />
      </clipPath>
    </defs>
  </svg>
);
