import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ExpiredSessionIcon = (props: IconProps) => (
  <Icon width="69px" height="82px" viewBox="0 0 69 82" fill="none" {...props}>
    <path
      d="M34.0434 80.9999C52.2928 80.9999 67.0869 66.2059 67.0869 47.9567C67.0869 29.7074 52.2928 14.9133 34.0434 14.9133C15.7941 14.9133 1 29.7074 1 47.9567C1 66.2059 15.7941 80.9999 34.0434 80.9999Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M23.6094 1H44.4789" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M57.4082 24.5927L63.61 18.391"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8262 15.32V1H39.2609V15.3235"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.8691 16.6519L65.3473 20.1301"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0447 47.9564L18.3926 32.3043"
      stroke="#228800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
