import { useDateUtils } from '@melio/platform-utils';
import { useMemo } from 'react';

import { BIRTHDAY_DATE_FORMAT } from '../consts';

export const useDayOfBirthDate = (dateOfBirth: string | undefined | null) => {
  const { convertFromISO } = useDateUtils(BIRTHDAY_DATE_FORMAT);

  const formattedDateOfBirth = useMemo(() => {
    if (!dateOfBirth) {
      return undefined;
    }

    try {
      const formattedDate = convertFromISO(dateOfBirth);

      if (isNaN(formattedDate.getTime())) {
        return undefined;
      }

      return formattedDate;
    } catch {
      return undefined;
    }
  }, [dateOfBirth, convertFromISO]);

  return formattedDateOfBirth;
};
