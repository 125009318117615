import { noop } from 'lodash';
import { createContext } from 'react';

import { EventProperties } from './types';

type AnalyticsContextType = {
  setGlobalProperties: (properties?: EventProperties) => void;
  getGlobalProperties: () => EventProperties;
};

export const AnalyticsContext = createContext<AnalyticsContextType>({
  setGlobalProperties: noop,
  getGlobalProperties: () => ({}),
});
