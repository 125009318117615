import { useConfig } from '@melio/platform-provider';

import { TabapayPushToDebitActivity } from './TabapayPushToDebit.activity';
import { TBTPushToDebitActivity } from './TBTPushToDebit.activity';
import { PushToDebitActivityProps } from './types';

export const PushToDebitActivity: React.VFC<PushToDebitActivityProps> = (props) => {
  const { settings } = useConfig();
  if (settings.tokenProvider === 'tbt') {
    return <TBTPushToDebitActivity {...props} />;
  }
  return <TabapayPushToDebitActivity {...props} />;
};
