// eslint-disable-next-line no-restricted-imports
import { QueryKey, useQueryClient } from 'react-query';

import { QueryKeyEnum } from './query-key-enum';
import { ModelId, Role } from './types';

type DataUpdateFunction<TInput, TOutput> = (input: TInput) => TOutput;
type Updater<TInput, TOutput> = TOutput | DataUpdateFunction<TInput, TOutput>;

type UseInvalidateQueryQueryProps =
  | [QueryKeyEnum]
  | [QueryKeyEnum, Extract<Role, 'collection'>]
  | [QueryKeyEnum, Extract<Role, 'model'>, ModelId?];

type MelioQueryClient = {
  invalidateQueries: (...queryKey: UseInvalidateQueryQueryProps) => Promise<void>;
  resetQueries: (...queryKey: UseInvalidateQueryQueryProps) => Promise<void>;
  setQueriesData: <T>(queryKey: UseInvalidateQueryQueryProps, data: Updater<T | undefined, T>) => [QueryKey, T][];
};

export const useMelioQueryClient = (): MelioQueryClient => {
  const queryClient = useQueryClient();

  const invalidateQueries = (...queryKey: UseInvalidateQueryQueryProps) =>
    queryClient.invalidateQueries(queryKey, { exact: false });

  const resetQueries = (...queryKey: UseInvalidateQueryQueryProps) =>
    queryClient.resetQueries(queryKey, { exact: false });

  const setQueriesData = <T>(queryKey: UseInvalidateQueryQueryProps, data: T | Updater<T | undefined, T>) =>
    queryClient.setQueriesData<T>({ queryKey, exact: false }, data);

  return {
    invalidateQueries,
    resetQueries,
    setQueriesData,
  };
};
