import { ExternalLayout, useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps, FC, ReactNode, useState } from 'react';

import { BankAccountFormModel } from '../../types';
import { AddBankAccountScreen, AddBankAccountScreenProps } from './screens';

type AddBankAccountActivityProps = {
  onSubmitBankAccountDetails: (bankAccountData: BankAccountFormModel) => Promise<void>;
  onBack: VoidFunction;
  contentHeaderTitle: ReactNode;
  payorOrganizationName: string;
  header: ComponentProps<typeof ExternalLayout>['header'];
};

export const AddBankAccountActivity: FC<AddBankAccountActivityProps> = ({
  onSubmitBankAccountDetails,
  onBack,
  contentHeaderTitle,
  payorOrganizationName,
  header,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const onSubmit: AddBankAccountScreenProps['onSubmit'] = async ({
    accountNumber,
    routingNumber,
  }: {
    accountNumber: string;
    routingNumber: string;
  }) => {
    try {
      setIsLoading(true);
      await onSubmitBankAccountDetails({ accountNumber, routingNumber });
    } catch (error) {
      toast({ type: 'error', title: formatMessage('vex.activities.addBankAccount.errors.toast.title') });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AddBankAccountScreen
      payorOrganizationName={payorOrganizationName}
      contentHeaderTitle={contentHeaderTitle}
      onSubmit={onSubmit}
      onBack={onBack}
      isLoading={isLoading}
      header={header}
    />
  );
};
