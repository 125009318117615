import { css, SystemStyleObject } from '@chakra-ui/styled-system';
import { CSSObject } from '@emotion/styled';
import { Theme as MelioTheme, useTheme } from '@melio/penny';

import { Size } from './theme.types';

type PlatformTheme = {
  baseStyle?: Record<string, CSSObject>;
  variants?: Record<string, Record<string, CSSObject>>;
  sizes?: Record<string, Record<string, CSSObject>>;
};

type ThemeEssentials = {
  size?: Size;
  variant?: string;
  [k: string]: unknown;
};

export type ComponentThemeType<T extends ThemeEssentials = {}> = (theme: MelioTheme, props: T) => PlatformTheme;

export type Theme = Record<string, SystemStyleObject>;

export const useAppTheme = <T extends ThemeEssentials>(styles: ComponentThemeType<T>, props: T): Theme => {
  const currentTheme = useTheme();

  const { variant = '', size = '' } = props;
  const { baseStyle = {}, variants = {}, sizes = {} } = styles(currentTheme, props);

  const variantStyles = variants[variant] || {};
  const sizeStyles = sizes[size] || {};

  const compiledStyles: Theme = {};

  Object.keys(baseStyle).forEach((key) => {
    compiledStyles[key] = css({
      ...baseStyle[key],
      ...variantStyles[key],
      ...sizeStyles[key],
    })(currentTheme);
  });

  return compiledStyles;
};
