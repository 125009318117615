import { Money } from '@melio/money';

import { Data, updateProperties } from '../object-utils';

export const getDollarsFromCents = (centsAmount: number, currency?: string): number =>
  Money.fromBaseUnit(centsAmount, currency || 'USD').toNaturalUnit();
export const getCentsFromDollars = (dollarsAmount: number, currency?: string): number =>
  Money.fromNaturalUnit(dollarsAmount, currency || 'USD').toBaseUnit();

export const convertCentsToDollars = <T extends Data, R extends T = T>(
  object: T,
  properties: string[],
  currency?: string
) =>
  updateProperties<T, R>(
    object,
    properties,
    (amount?: number | null) => amount && getDollarsFromCents(amount, currency)
  );

export const convertDollarsToCents = <T extends Data>(object: T, properties: string[], currency?: string) =>
  updateProperties(object, properties, (amount?: number | null) => amount && getCentsFromDollars(amount, currency));
