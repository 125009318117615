/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

export const useScrollIntoView = ({
  ref,
  isSelected,
  locationIndexInList,
}: {
  ref: React.RefObject<HTMLDivElement | HTMLButtonElement>;
  isSelected: boolean;
  locationIndexInList?: number;
}) => {
  React.useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, [isSelected, locationIndexInList]);
};
