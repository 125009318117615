import { CardVerificationCodeElement as ICardVerificationCodeElement } from '@basis-theory/basis-theory-react/types';
import { forwardRef } from 'react';

import { TBTFormFieldWidget } from '../TBTFormField';
import { TBTFormFieldWidgetProps } from '../TBTFormField/TBTFormField.widget';

export type TBTVerificationCodeFieldWidgetProps = Omit<TBTFormFieldWidgetProps, 'elementType'>;

export const TBTVerificationCodeFieldWidget = forwardRef<
  ICardVerificationCodeElement,
  TBTVerificationCodeFieldWidgetProps
>((props, ref) => (
  // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  <TBTFormFieldWidget
    data-component="TBTVerificationCodeFieldWidget"
    {...props}
    elementType="verificationCode"
    ref={ref}
  />
));

TBTVerificationCodeFieldWidget.displayName = 'TBTVerificationCodeFieldWidget';
