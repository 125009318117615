import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import {
  DeliveryMethodSelectionByPayeeHeaderWidget,
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  ExternalLayoutContentWidget,
  FooterWidget,
  HeaderAuthSection,
  PoweredByMelioWidget,
  VendorBankDetailsPayeeForm,
} from '@melio/vex-widgets';

import { useNavigateToEmailVerification } from '../../../../../hooks/useNavigateToEmailVerification';
import { BankAccountFormModel } from '../../../../../types';

type AddBankDeliveryMethodScreenProps = Pick<
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  'accountName' | 'paymentAmount' | 'paymentNote' | 'paymentInvoiceNumber'
> & {
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<BankAccountFormModel>;
  onBack: VoidFunction;
  onDone: ((data: BankAccountFormModel) => void) | ((data: BankAccountFormModel) => Promise<void>);
  showPoweredByMelio?: boolean;
  showLoginHeaderSection: boolean;
  emailVerificationReturnUrl: string;
  userName?: string;
};

// TODO: change to standardized the gaps and margins - PLA-749

export const AddBankDeliveryMethodScreen = forwardRef<AddBankDeliveryMethodScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      isSaving,
      defaultValues,
      showPoweredByMelio,
      showLoginHeaderSection,
      paymentInvoiceNumber,
      paymentNote,
      onBack,
      onDone,
      emailVerificationReturnUrl,
      userName,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    useAnalyticsView('UnilateralAddBankDeliveryMethodScreen');
    const { track } = useAnalytics();
    const handleBackButtonClick = () => {
      track('UnilateralAddBankDeliveryMethodScreen', 'Click', { intent: 'GoBack' });
      onBack();
    };
    const handleNextButtonClick = () => {
      track('UnilateralAddBankDeliveryMethodScreen', 'Click', { intent: 'Accept ACH Payment' });
      submitButtonProps?.onClick();
    };
    const legalInfoOverride = accountName
      ? formatMessage('vex.widgets.legal.infoWithAccountName', { accountName })
      : undefined;

    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<BankAccountFormModel>();

    const { navigateToEmailVerification } = useNavigateToEmailVerification(emailVerificationReturnUrl);

    const header = showLoginHeaderSection ? (
      <HeaderAuthSection onLoginButtonClick={navigateToEmailVerification} loggedInUserName={userName} />
    ) : undefined;

    const contentForm = (
      <VendorBankDetailsPayeeForm
        onSubmit={onDone}
        isSaving={isSaving}
        defaultValues={defaultValues}
        onSubmissionStateChange={onSubmissionStateChange}
        size="small"
        columns={2}
      />
    );

    const contentHeader = (
      <DeliveryMethodSelectionByPayeeHeaderWidget
        accountName={accountName}
        paymentAmount={paymentAmount}
        paymentNote={paymentNote}
        paymentInvoiceNumber={paymentInvoiceNumber}
        showAvatar={false}
      />
    );

    const content = (
      <ExternalLayoutContentWidget
        header={{
          backButtonProps: { isDisabled: cancelButtonProps?.isDisabled, onClick: handleBackButtonClick },
          title: contentHeader,
        }}
        form={{
          title: formatMessage('activities.addBankDeliveryMethodByPayee.screens.addBankDeliveryMethod.title'),
          fields: contentForm,
        }}
        continueSection={{
          buttonProps: {
            ...submitButtonProps,
            label: formatMessage('activities.addBankDeliveryMethodByPayee.screens.addBankDeliveryMethod.continue'),
            onClick: handleNextButtonClick,
          },
          legalInfoOverride,
        }}
      />
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddBankDeliveryMethodByPayeeActivity.AddBankDeliveryMethodScreen"
        data-testid="add-bank-delivery-method-by-payee-activity-add-bank-delivery-method-screen"
        {...props}
        header={header}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

AddBankDeliveryMethodScreen.displayName = 'AddBankDeliveryMethodByPayeeActivity.AddBankDeliveryMethodScreen';
