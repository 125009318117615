export const Celebration: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_131930)">
      <path
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#084A77"
      />
      <path
        d="M38.4228 101.765L60.7572 46.1987L97.8984 84.3011L42.9588 106.301C40.1076 107.446 37.278 104.616 38.4228 101.765Z"
        fill="#FDDA21"
      />
      <path
        d="M84.2364 60.1525C73.944 49.5469 63.6084 43.0129 61.146 45.5401C58.6836 48.0781 65.034 58.7269 75.3264 69.3325C85.6188 79.9381 95.9544 86.4721 98.4168 83.9449C100.868 81.4069 94.5288 70.7581 84.2364 60.1525Z"
        fill="#D9AF0D"
      />
      <path
        d="M52.938 92.8654C52.7544 92.6818 52.56 92.4982 52.3764 92.3038C52.1712 92.0986 51.9768 91.8826 51.7824 91.6774C48.0456 87.6814 45.6912 83.8042 46.1772 82.4434L44.2224 87.2926L44.1684 87.433L43.704 88.5994L43.6392 88.7614C43.3368 89.917 45.3348 93.0922 48.4128 96.2674C51.5448 99.4966 54.6984 101.57 55.7568 101.171L55.8 101.16L57.0636 100.652L57.1068 100.631L61.9452 98.6866C60.6168 99.1834 56.826 96.7426 52.938 92.8654Z"
        fill="#D22E2E"
      />
      <path
        d="M81.3096 85.9537C77.778 83.7181 73.4256 80.1001 69.0732 75.6181C64.6776 71.0929 61.146 66.5569 58.986 62.9065C57.1824 59.8609 56.3508 57.4309 56.772 56.1133L54.2232 62.4637L54.1044 62.7661C53.7156 64.1593 54.8604 66.9241 57.1068 70.3045C59.0076 73.1665 61.686 76.4713 64.8936 79.7761C68.0688 83.0485 71.244 85.7809 73.998 87.7357C77.3676 90.1225 80.1108 91.3321 81.4608 90.8785L81.6336 90.8137L87.7572 88.3621C86.418 88.6213 84.1284 87.7465 81.3096 85.9537Z"
        fill="#D22E2E"
      />
      <path
        d="M66.0384 88.8698C64.332 87.4658 62.5176 85.8134 60.714 83.945C58.878 82.055 57.2472 80.165 55.8756 78.3722C52.7544 74.3222 51.0156 70.8554 51.4584 69.3218L48.9204 75.629L48.78 75.9638C48.3588 77.8754 51.5772 82.9946 56.5344 88.103C61.632 93.3626 66.7512 96.7322 68.4792 96.0626L68.5224 96.0518L75.0024 93.4598C73.5552 94.0322 70.0884 92.1962 66.0384 88.8698Z"
        fill="#D22E2E"
      />
      <path
        d="M76.32 22.8926L77.886 27.7202H82.9728L78.858 30.7009L80.424 35.5393L76.32 32.5477L72.216 35.5393L73.782 30.7009L69.6672 27.7202H74.754L76.32 22.8926Z"
        fill="#FDDA21"
      />
      <path
        d="M100.08 36.9326L101.646 41.7602H106.733L102.618 44.741L104.184 49.5794L100.08 46.5878L95.976 49.5794L97.542 44.741L93.4272 41.7602H98.514L100.08 36.9326Z"
        fill="#FDDA21"
      />
      <path
        d="M111.96 60.6924L113.526 65.52H118.613L114.498 68.5008L116.064 73.3392L111.96 70.3476L107.856 73.3392L109.422 68.5008L105.307 65.52H110.394L111.96 60.6924Z"
        fill="#FDDA21"
      />
      <path d="M82.8324 53.5535L88.8588 26.856L94.0212 28.584L82.8324 53.5535Z" fill="#058BBA" />
      <path d="M94.9715 53.9316L87.066 58.014L91.1484 50.1084L94.9715 53.9316Z" fill="#058BBA" />
      <path d="M90.0576 61.6321L115.2 47.8945L117.36 52.9057L90.0576 61.6321Z" fill="#058BBA" />
      <path d="M78.9984 38.3149L77.1732 49.5793L73.6416 38.7253L78.9984 38.3149Z" fill="#058BBA" />
      <path d="M103.05 70.2507L94.95 66.7947L103.59 65.1099L103.05 70.2507Z" fill="#058BBA" />
      <path
        d="M81.3096 85.9537C77.778 83.7181 73.4256 80.1001 69.0732 75.6181C64.6776 71.0929 61.146 66.5569 58.986 62.9065C57.1824 59.8609 56.3508 57.4309 56.772 56.1133L54.2232 62.4637L54.1044 62.7661C53.7156 64.1593 54.8604 66.9241 57.1068 70.3045C59.0076 73.1665 61.686 76.4713 64.8936 79.7761C68.0688 83.0485 71.244 85.7809 73.998 87.7357C77.3676 90.1225 80.1108 91.3321 81.4608 90.8785L81.6336 90.8137L87.7572 88.3621C86.418 88.6213 84.1284 87.7465 81.3096 85.9537Z"
        fill="#D22E2E"
      />
      <path
        d="M66.0384 88.8698C64.332 87.4658 62.5176 85.8134 60.714 83.945C58.878 82.055 57.2472 80.165 55.8756 78.3722C52.7544 74.3222 51.0156 70.8554 51.4584 69.3218L48.9204 75.629L48.78 75.9638C48.3588 77.8754 51.5772 82.9946 56.5344 88.103C61.632 93.3626 66.7512 96.7322 68.4792 96.0626L68.5224 96.0518L75.0024 93.4598C73.5552 94.0322 70.0884 92.1962 66.0384 88.8698Z"
        fill="#D22E2E"
      />
    </g>
    <defs>
      <clipPath id="clip0_11475_131930">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
