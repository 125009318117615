import {
  DeliveryMethod,
  DeliveryMethodType,
  PaymentDeliveryPreferenceEnum,
  TrackerTimelineStatusEnum,
  VendorManagedBy,
} from '@melio/platform-api';

import { PaymentTrackerSteps, VendorType } from '../types';
import { handleAchDeliveryMethodSteps } from './achTrackerSteps';
import { getCardStatusTrackerSteps } from './cardTrackerSteps';
import { getFailureSteps } from './failureTrackerSteps';
import { getInternationalStatusTrackerSteps } from './internationalTrackerSteps';
import { getPaperCheckStatusTrackerSteps } from './paperCheckTrackerSteps';

const getVendorType = ({
  vendorManagedBy,
  isErpVendor,
}: {
  vendorManagedBy: VendorManagedBy | null;
  isErpVendor: boolean;
}): VendorType => {
  if (isErpVendor) {
    return VendorType.Erp;
  } else if (vendorManagedBy === VendorManagedBy.Shared) {
    return VendorType.GetPaid;
  }

  return VendorType.Guest;
};

export const getPaymentTrackerStepsTexts = ({
  deliveryMethod,
  deliveryPreference,
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
  trackerTimelineStatus,
  payorEmail,
  vendorManagedBy,
  howContactToSupportArticleUrl,
  vendorsContactEmail,
  isErpVendor,
}: {
  deliveryMethod: DeliveryMethod;
  deliveryPreference: PaymentDeliveryPreferenceEnum | null;
  paymentCreatedAt: Date;
  paymentScheduledDate: Date;
  paymentDeliveryEta: Date;
  trackerTimelineStatus: TrackerTimelineStatusEnum;
  payorEmail: string;
  vendorManagedBy: VendorManagedBy | null;
  howContactToSupportArticleUrl: string;
  vendorsContactEmail: string;
  isErpVendor: boolean;
}): PaymentTrackerSteps | undefined => {
  if (trackerTimelineStatus === TrackerTimelineStatusEnum.FAILED) {
    return getFailureSteps({
      payorEmail,
      paymentCreatedAt,
      howContactToSupportArticleUrl,
      vendorsContactEmail,
      vendorType: getVendorType({ vendorManagedBy, isErpVendor }),
    });
  }
  switch (deliveryMethod.type) {
    case DeliveryMethodType.BankAccount:
      return handleAchDeliveryMethodSteps({
        deliveryPreference,
        paymentCreatedAt,
        paymentScheduledDate,
        paymentDeliveryEta,
        trackerTimelineStatus,
      });
    case DeliveryMethodType.Card:
      return getCardStatusTrackerSteps({
        paymentDeliveryEta,
        paymentCreatedAt,
        paymentScheduledDate,
        trackerTimelineStatus,
      });
    case DeliveryMethodType.PaperCheck:
      return getPaperCheckStatusTrackerSteps({
        paymentScheduledDate,
        paymentCreatedAt,
        paymentDeliveryEta,
        trackerTimelineStatus,
      });
    case DeliveryMethodType.InternationalAccount:
      return getInternationalStatusTrackerSteps({
        paymentDeliveryEta,
        paymentCreatedAt,
        paymentScheduledDate,
        trackerTimelineStatus,
      });
    default:
      return undefined;
  }
};
