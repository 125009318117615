// eslint-disable-next-line no-restricted-imports
import { ReactQueryDevtools } from 'react-query/devtools';
import setupLocatorUI from '@locator/runtime';

import { getViteEnvironment } from '@/utils/error-tracking';

export const Devtools = () => {
  const environment = getViteEnvironment();
  if (environment !== 'development') return null;

  setupLocatorUI();
  return <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />;
};
