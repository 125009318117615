import React from 'react';
export const UsBankLogo: React.VFC<React.SVGProps<SVGSVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
    <path d="M32 56.327l32-10.9V7.673H0v37.755z" fill="#cc162b" />
    <path
      d="M31.913 44.207h-9.766v-3.4h-.087c-1.918 2.703-4.62 4-8.632 4-4.796.087-9.068-2.877-9.068-8.894V17.7h10.114v13.95c0 3.052.35 5.232 3.313 5.232 1.744 0 4-.872 4-5.144V17.7H31.91zm18.92-18.834c-.087-.872-.523-1.57-1.22-2.005-.6-.523-1.395-.785-2.267-.785-1.395 0-3.052.35-3.052 2.005 0 .785.6 1.134 1.22 1.395 1.657.698 5.493.96 8.894 2.005s6.365 3.052 6.365 7.4c0 7.4-7.063 9.33-13.515 9.33-6.278 0-13.08-2.53-13.253-9.33h9.678c.087.96.436 1.657 1.22 2.354.523.436 1.482.785 2.703.785 1.308 0 3.4-.523 3.4-2.005 0-1.57-.872-2.005-5.58-2.8-7.76-1.308-10.812-3.75-10.812-8.37 0-6.714 7.237-8.458 12.556-8.458 5.755 0 12.643 1.57 12.905 8.37h-9.243z"
      fill="#fff"
    />
  </svg>
);
