import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EmptyIcon = (props: IconProps) => (
  <Icon width="120" height="120" viewBox="0 0 120 120" fill="none" {...props}>
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M84.4757 37.0677C77.2108 28.4737 79.1591 8 62.5655 8C43.8998 8 46.2031 29.0928 38.6328 38.8096"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M60.5598 15.9665C60.5598 17.9396 61.0304 22.7278 57.3649 23.0497C51.099 23.6028 51.842 31.1897 56.9604 31.1979C60.4112 31.1979 62.5576 31.2474 62.5576 31.2474"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M56.0023 16.9243L55.9775 19.9871"
        stroke="#212124"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5214 17.015L65.5049 20.086"
        stroke="#212124"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6613 65.1776C12.7933 65.2107 12.9254 65.2519 13.0493 65.285C19.8848 66.9278 25.3747 64.1622 31.4013 62.0405C30.4271 60.2161 23.2531 46.8009 21.1397 42.0044C6.47787 50.4828 6.08161 60.4555 10.4075 64.4512C10.8781 64.6328 11.3404 64.7896 11.7944 64.9382C12.0916 65.029 12.3806 65.1116 12.6695 65.1859L12.6613 65.1776Z"
        fill="#9874FF"
        stroke="#9874FF"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M31.4014 62.0405C25.3831 64.1622 19.8932 66.9195 13.0494 65.2849C12.9173 65.2519 12.7852 65.2106 12.6614 65.1776C12.3724 65.1033 12.0835 65.0207 11.7863 64.9299C11.3322 64.7896 10.8699 64.6245 10.3994 64.4428C6.07347 60.4472 6.46973 50.4745 21.1398 42.0044L22.0561 41.5421"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M48.8124 61.0746C56.9028 63.5678 52.5191 69.1733 48.4079 74.6632C55.7222 73.8789 61.8726 69.2228 64.7703 62.7753C63.3173 62.4038 62.1285 61.6608 61.5671 60.4555C60.7333 56.8395 62.5991 53.3392 66.0499 49.9792C64.0025 40.9147 55.9121 34.1451 46.2284 34.1451C35.0009 34.1451 25.9033 43.2427 25.9033 54.4702C25.9033 57.6486 26.6298 60.6536 27.9342 63.3284C34.0267 61.0746 40.3009 58.4494 48.8289 61.0829L48.8124 61.0746Z"
        fill="#BDBDFF"
      />
      <path
        d="M42.4551 59.9933C42.0753 59.3163 41.9433 58.5651 41.9185 57.9789H38.4512C38.4512 58.7219 38.5667 59.4649 38.8062 60.1666C39.9784 60.018 41.2003 59.952 42.4634 59.9933H42.4551Z"
        fill="white"
      />
      <path
        d="M44.9653 56.4268V60.2161C45.6505 60.3152 46.344 60.439 47.0539 60.6041C47.0622 60.4968 47.0704 60.3812 47.0704 60.2409V56.7736C49.382 57.2441 50.0094 58.0862 50.0094 59.1924C50.0094 59.7703 49.737 60.5216 48.961 61.1325C50.3066 61.5618 51.2973 62.0819 51.9907 62.6763C53.0392 61.6608 53.584 60.3565 53.584 58.986C53.584 55.0729 50.9588 53.8346 47.0704 53.2815V48.84C48.9114 49.2363 49.5141 50.6562 49.5141 51.6799H52.8988C52.8493 48.8978 51.1404 46.2148 47.0704 45.8515V43.4904H44.9653V45.8515C41.2586 46.2148 39.0791 48.4272 39.0791 51.0525C39.0791 53.9915 40.5238 55.6756 44.9653 56.4351V56.4268ZM43.9911 48.8648C44.5443 48.6006 45.4854 48.4933 45.5927 48.7575C45.6753 48.9391 45.5184 48.9969 45.3285 49.1785C45.0396 49.4427 44.9653 49.6491 44.9653 50.3838V52.9595C42.8601 52.4642 42.629 51.49 42.629 50.8874C42.629 50.2847 42.7858 49.4427 43.9911 48.8648Z"
        fill="white"
      />
      <path
        d="M97.2311 53.6613C96.6862 46.0002 95.9267 36.1019 95.0186 32.4529C79.2093 40.5598 59.1401 50.0041 61.5507 60.4474C63.2349 64.0303 70.3181 63.6257 74.0826 61.0005"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M45.7329 86.056C49.2332 88.3675 52.4694 85.6184 56.0193 82.2419C56.011 82.1924 56.0027 82.1511 55.9862 82.1016C55.9862 82.1016 53.5674 70.3375 53.3858 65.0374C53.361 65.0374 53.3362 65.0539 53.3115 65.0539C54.0132 71.3529 38.9138 81.5567 45.7329 86.056Z"
        fill="#9874FF"
        stroke="#9874FF"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M44.082 83.9507L46.212 90.5221" stroke="#212124" strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M82.7751 64.4511C82.998 65.9454 83.8566 70.5519 84.905 76.0501C87.0762 75.3401 89.7923 77.2389 89.7923 79.633V88.3013C89.7923 88.3013 103.901 69.4127 111.678 60.4059C111.678 60.4059 96.116 50.9038 80.6534 45.0589C80.6534 45.0589 76.2367 52.6788 74.082 61.0168C78.5895 57.8715 83.2456 60.1995 82.7751 64.4511Z"
        fill="#BDBDFF"
      />
      <path
        d="M84.4761 59.5308C86.1905 59.5308 87.5802 58.1411 87.5802 56.4268C87.5802 54.7124 86.1905 53.3227 84.4761 53.3227C82.7618 53.3227 81.3721 54.7124 81.3721 56.4268C81.3721 58.1411 82.7618 59.5308 84.4761 59.5308Z"
        fill="#212124"
      />
      <path d="M89.0994 65.5986L82.7344 63.0229" stroke="#212124" strokeWidth="2" strokeLinejoin="round" />
      <path d="M90.2876 70.7006L83.4355 68.2322" stroke="#212124" strokeWidth="2" strokeLinejoin="round" />
      <path d="M103.48 63.0229L96.6855 59.5226" stroke="#212124" strokeWidth="2" strokeLinejoin="round" />
      <path d="M101.755 66.0693L98.4443 64.3108" stroke="#212124" strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M82.7757 64.4511C83.238 60.1913 78.5819 57.8715 74.0827 61.0169C70.3181 63.6421 63.2349 64.0384 61.5508 60.4637C62.6488 65.0868 65.2162 75.3154 65.2162 75.3154C73.0507 72.4177 80.3899 71.1794 82.7757 64.4594V64.4511Z"
        fill="#9874FF"
        stroke="#9874FF"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M82.7758 64.4512C83.2381 60.1913 78.582 57.8715 74.0828 61.0169C70.3183 63.6421 63.235 64.0384 61.5509 60.4638C60.7088 58.4659 62.1371 55.238 62.1371 55.238"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M82.776 64.4511C80.3902 71.1711 73.0428 72.4094 65.2166 75.3071C57.3821 78.2048 51.9747 90.1836 45.7418 86.0641C37.1313 80.376 63.4829 65.5986 48.8129 61.0829C32.6155 56.0883 24.5747 70.0236 10.4082 64.4594L31.4185 87.4345V79.6413C31.4185 76.24 36.9084 73.8211 38.51 78.4855C40.1033 83.1499 42.2332 90.2414 42.2332 90.2414C42.2332 90.2414 51.0914 90.7697 54.1046 90.7697C57.1179 90.7697 59.4872 95.1699 56.0034 98.373C58.5378 100.874 57.96 103.987 56.0034 105.621C57.4811 106.703 57.7536 109.039 56.6061 110.781H64.6222C63.4664 109.039 63.7471 106.711 65.2248 105.621C63.2683 103.987 62.6904 100.866 65.2248 98.373C61.7327 95.1699 64.1103 90.7697 67.1236 90.7697C70.1368 90.7697 78.995 90.2414 78.995 90.2414C78.995 90.2414 81.1249 83.1499 82.7182 78.4855C83.1888 77.1068 84.0061 76.3556 84.9142 76.0584C83.8658 70.5602 83.0154 65.9536 82.7843 64.4594L82.776 64.4511Z"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M89.7929 91.8265V79.6331C89.7929 76.2318 84.303 73.8129 82.7014 78.4773C81.1081 83.1417 78.9782 90.2331 78.9782 90.2331C78.9782 90.2331 70.12 90.7615 67.1067 90.7615C64.0935 90.7615 61.7242 95.1617 65.208 98.3648C62.6735 100.866 63.2514 103.979 65.208 105.613C63.2432 107.05 63.4 110.69 66.1986 112.218C68.1882 113.299 72.9021 112.548 79.8037 112.548H98.2713C107.187 112.548 114.403 102.369 110.671 90.3074C108.038 81.7878 105.024 75.5136 103.481 72.5663"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M65.2158 98.3648C67.9071 100.429 73.8759 98.7941 78.2183 98.7941"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M65.2158 105.613C67.4118 107.405 73.4713 106.216 78.2183 106.216"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M31.4091 91.8265C31.4091 88.2849 31.4091 83.0344 31.4091 79.6331C31.4091 76.2319 36.8991 73.813 38.5006 78.4774C40.0939 83.1417 42.2239 90.2332 42.2239 90.2332C42.2239 90.2332 51.082 90.7616 54.0953 90.7616C57.1086 90.7616 59.4779 95.1618 55.9941 98.3649C58.5285 100.866 57.9506 103.979 55.9941 105.613C57.9589 107.05 57.802 110.69 55.0034 112.218C53.0138 113.299 48.2999 112.548 41.3983 112.548C34.4967 112.548 31.8467 112.548 22.9307 112.548C14.0148 112.548 6.79946 102.369 10.5309 90.3075C14.2624 78.2462 18.1178 72.0298 18.1178 72.0298"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M55.9946 98.3648C53.3033 100.429 47.3346 98.7941 42.9922 98.7941"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M55.9946 105.613C53.7987 107.405 47.7391 106.216 42.9922 106.216"
        stroke="#212124"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
