import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';

const einOnlyBusinessTypes: OrganizationBusinessType[] = [
  OrganizationBusinessType.Partnership,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.NonProfit,
  OrganizationBusinessType.NonGovernmentalOrganization,
  OrganizationBusinessType.MunicipalCorporation,
];

export const padTaxIdNumber = (input: string) => input.slice(-4).padStart(input.length, '•');

export const getValidTaxInfoByBusinessType = ({
  businessType,
  taxIdType,
  taxIdNumber,
}: {
  businessType: OrganizationBusinessType | undefined | null;
  taxIdType: TaxIdTypeEnum | undefined;
  taxIdNumber: string;
}) => {
  const isValidBusinessType = businessType && Object.values(OrganizationBusinessType).includes(businessType);
  const isEinOnly = businessType && isValidBusinessType && einOnlyBusinessTypes.includes(businessType);

  if (isEinOnly) {
    return {
      type: TaxIdTypeEnum.Ein,
      identifier: taxIdNumber || '',
    };
  }

  return {
    type: (taxIdType?.toLocaleUpperCase() as TaxIdTypeEnum) || TaxIdTypeEnum.Ein,
    identifier: taxIdNumber || '',
  };
};
