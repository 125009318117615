import { VirtualCard } from '@melio/penny';
import { ComponentProps } from 'react';

type VirtualCardProps = ComponentProps<typeof VirtualCard>;
export const getMonth = (date: Date): VirtualCardProps['expirationDateProps']['month'] =>
  String(date.getMonth() + 1).padStart(2, '0') as VirtualCardProps['expirationDateProps']['month'];
export const getYear = (date: Date): VirtualCardProps['expirationDateProps']['year'] =>
  String(date.getFullYear()).slice(-2) as VirtualCardProps['expirationDateProps']['year'];

export const shortDataFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});
