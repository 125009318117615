import { Button, Group, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { BankAccountCardWidget } from '../BankAccountCard';

export type ConfirmBankAccountCardWidgetProps = {
  onSubmit: () => Promise<void>;
  onUpdateBankAccountClick: () => void;
  isPlaidAccount: boolean;
  accountNumber: string | undefined;
  routingNumber: string | undefined;
  isSaving?: boolean;
};

export const ConfirmBankAccountCardWidget = forwardRef<ConfirmBankAccountCardWidgetProps, 'div'>(
  ({ isPlaidAccount, accountNumber, routingNumber, onSubmit, onUpdateBankAccountClick, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const [isConfirmBankAccountError, setIsConfirmBankAccountError] = useState(false);

    const onConfirmBankAccount = async () => {
      try {
        setIsConfirmBankAccountError(false);
        await onSubmit();
      } catch (error) {
        setIsConfirmBankAccountError(true);
      }
    };

    return (
      <Group
        spacing="l"
        variant="vertical"
        ref={ref}
        width="full"
        data-component="ConfirmBankAccountCardWidget"
        {...props}
      >
        {isConfirmBankAccountError && (
          <SectionBanner
            data-testid="confirm-bank-account-card-error-banner"
            variant="critical"
            description={formatMessage('vex.widgets.confirmBankAccountCard.errorBanner.description')}
          />
        )}
        <BankAccountCardWidget
          accountNumber={accountNumber}
          routingNumber={routingNumber}
          isPlaidAccount={isPlaidAccount}
        />
        <Group justifyContent="flex-end">
          <Button
            data-testid="update-bank-account-button"
            label={formatMessage('vex.widgets.confirmBankAccountCard.updateBankAccount')}
            variant="tertiary"
            onClick={onUpdateBankAccountClick}
          />
          <Button
            data-testid="continue-exiting-bank-account-button"
            label={formatMessage('vex.widgets.confirmBankAccountCard.submitButtonLabel')}
            onClick={() => void onConfirmBankAccount()}
            isLoading={isSaving}
          />
        </Group>
      </Group>
    );
  }
);

ConfirmBankAccountCardWidget.displayName = 'ConfirmBankAccountCardWidget';
