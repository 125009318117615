import { Card, ExternalLayout, Group, Text } from '@melio/penny';
import { BankAccountDeliveryMethod, DeliveryMethodByPayee, PaperCheckDeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { DeliveryMethodListItemWidget, FooterWidget, PayorPendingPaymentBanner } from '@melio/vex-widgets';

type DeliveryMethodAddedSuccessfullyWithoutPaymentScreenProps = {
  accountName: string;
  deliveryMethod: DeliveryMethodByPayee;
  wasDeliveryMethodPreExisted?: boolean;
  isLoading?: boolean;
};

export const DeliveryMethodAddedSuccessfullyWithoutPaymentScreen = forwardRef<
  DeliveryMethodAddedSuccessfullyWithoutPaymentScreenProps,
  'div'
>(({ accountName, deliveryMethod, wasDeliveryMethodPreExisted, children, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  const content = (
    <Card paddingX="xl" paddingY="l">
      <Group variant="vertical" spacing="xl">
        <PayorPendingPaymentBanner
          variant="success"
          title={formatMessage(
            'activities.addDeliveryMethodByPayeeWithoutPayment.screens.deliveryMethodAddedSuccessfullyWithoutPayment.title'
          )}
          description={
            wasDeliveryMethodPreExisted
              ? formatMessage(
                  'activities.addDeliveryMethodByPayeeWithoutPayment.screens.deliveryMethodAddedSuccessfullyWithoutPayment.onItsWayAlreadyAdded',
                  { accountName }
                )
              : formatMessage(
                  'activities.addDeliveryMethodByPayeeWithoutPayment.screens.deliveryMethodAddedSuccessfullyWithoutPayment.onItsWayText',
                  { accountName }
                )
          }
        />
        <Group variant="vertical" spacing="s">
          <Text textStyle="body4SemiUpper" color="global.neutral.900">
            {formatMessage(
              'activities.addDeliveryMethodByPayeeWithoutPayment.screens.deliveryMethodAddedSuccessfullyWithoutPayment.vendorReceivePaymentSection.title'
            )}
          </Text>
          <Group variant="vertical" spacing="m" hasDivider>
            <DeliveryMethodListItemWidget
              deliveryMethod={deliveryMethod as BankAccountDeliveryMethod | PaperCheckDeliveryMethod}
            />
          </Group>
        </Group>
      </Group>
    </Card>
  );

  const footer = <FooterWidget displayTrustedPartners={false} />;

  return (
    <ExternalLayout
      data-component="AddDeliveryMethodByPayeeActivity.DeliveryMethodAddedSuccessfullyWithoutPaymentScreen"
      data-testid="add-delivery-method-by-payee-activity-delivery-method-added-successfully-screen"
      {...props}
      content={content}
      footer={footer}
      ref={ref}
    />
  );
});

DeliveryMethodAddedSuccessfullyWithoutPaymentScreen.displayName =
  'AddDeliveryMethodByPayeeActivity.DeliveryMethodAddedSuccessfullyWithoutPaymentScreen';
