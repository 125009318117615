import { createContext, useContext } from 'react';

import { SubscriptionContextType } from './types';

const SubscriptionContext = createContext<SubscriptionContextType>({} as SubscriptionContextType);

export const SubscriptionProvider = SubscriptionContext.Provider;

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(`${useSubscriptionContext.name} must be used within a SubscriptionsWrapper`);
  }
  return context;
};
