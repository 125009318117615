import { getStorageInstance, getStorageKeys } from './vexStorage';

const VEX_ACCESS_TOKEN_KEY_PREFIX = 'vex:access_token';
const VEX_REFRESH_TOKEN_KEY_PREFIX = 'vex:refresh_token';

const getAccessTokenKey = (tokenIdentifier: string | undefined) => {
  const tokenIdentifierSuffix = tokenIdentifier ? `:${tokenIdentifier}` : '';
  return `${VEX_ACCESS_TOKEN_KEY_PREFIX}${tokenIdentifierSuffix}`;
};

const getRefreshTokenKey = (tokenIdentifier: string | undefined) => {
  const tokenIdentifierSuffix = tokenIdentifier ? `:${tokenIdentifier}` : '';
  return `${VEX_REFRESH_TOKEN_KEY_PREFIX}${tokenIdentifierSuffix}`;
};

export type AuthLocalStorage = ReturnType<typeof getAuthLocalStorage>;

export const getAuthLocalStorage = (tokenIdentifier: string | undefined) => ({
  getAccessToken: () => {
    const accessTokenKey = getAccessTokenKey(tokenIdentifier);
    const storage = getStorageInstance();
    return storage.getItem(accessTokenKey);
  },

  setAccessToken: (accessToken: string) => {
    const accessTokenKey = getAccessTokenKey(tokenIdentifier);
    const storage = getStorageInstance();
    return storage.setItem(accessTokenKey, accessToken);
  },

  getRefreshToken: () => {
    const refreshTokenKey = getRefreshTokenKey(tokenIdentifier);
    const storage = getStorageInstance();
    return storage.getItem(refreshTokenKey);
  },

  setRefreshToken: (refreshToken: string) => {
    const refreshTokenKey = getRefreshTokenKey(tokenIdentifier);
    const storage = getStorageInstance();
    return storage.setItem(refreshTokenKey, refreshToken || '');
  },

  deleteRefreshToken: () => {
    const refreshTokenKey = getRefreshTokenKey(tokenIdentifier);
    const storage = getStorageInstance();
    storage.removeItem(refreshTokenKey);
  },

  deleteAccessToken: () => {
    const accessTokenKey = getAccessTokenKey(tokenIdentifier);
    const storage = getStorageInstance();
    storage.removeItem(accessTokenKey);
  },
});

export const getAllVexLocalStorageAuthKeys = () => {
  const keys = getStorageKeys();
  return keys.filter(
    (key) => key.startsWith(VEX_ACCESS_TOKEN_KEY_PREFIX) || key.startsWith(VEX_REFRESH_TOKEN_KEY_PREFIX)
  );
};
