import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePageViewStatus } from '@melio/vex-domain';

export const AdBlockerErrorBannerComponent = () => {
  const { formatMessage } = useMelioIntl();

  usePageViewStatus('DeliveryMethod', {
    StatusType: 'Failure',
    ErrorType: 'Failed to communicate with Basis Theory Elements',
  });

  return <SectionBanner description={formatMessage('vex.widgets.tbtForm.adBlockerMessage')} variant="critical" />;
};
