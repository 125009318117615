import * as types from '@braze/web-sdk';
import { Card } from '@braze/web-sdk';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { Logger } from '@melio/platform-logger';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { BrazeBanner } from './components/BrazeBanner';
import { Modal } from './components/Modal';
import { BrazeContentCardType, ParsedProperties } from './components/types';
import { extractProperties } from './components/utils';
import { useIsDashboardTourComplete } from './hooks/useIsDashboardTourComplete';

declare const window: {
  braze: typeof types;
} & Window;

type MessageProps = {
  message: types.InAppMessage;
  clearMessage: VoidFunction;
  properties: ParsedProperties;
};

export type MessagerContextType = {
  cards: Card[];
  logImpression: (card: Card) => void;
  logClick: (card: Card) => void;
  logDismissal: (card: Card) => void;
};

export const MessagerContext = createContext<MessagerContextType | Record<string, never>>({
  cards: [],
  logClick: () => undefined,
  logImpression: () => undefined,
  logDismissal: () => undefined,
});

export const useMessager = () => useContext<MessagerContextType | Record<string, never>>(MessagerContext);

const Messager = ({ message, clearMessage, properties }: MessageProps) => {
  const logImpression = useCallback(() => {
    window.braze.logInAppMessageImpression(message);
  }, [message]);

  const logMessageClick = useCallback(() => {
    window.braze.logInAppMessageClick(message);
  }, [message]);

  const logButtonClick = useCallback(
    (button: types.InAppMessageButton) => {
      window.braze.logInAppMessageButtonClick(button, message);
    },
    [message]
  );

  if (message instanceof window.braze.ModalMessage) {
    const type = properties.generalProperties.get('type') || 'modal';

    if (type === 'banner') {
      return (
        <BrazeBanner
          logImpression={logImpression}
          logButtonClick={logButtonClick}
          logMessageClick={logMessageClick}
          clearMessage={clearMessage}
          properties={properties}
          message={message}
        />
      );
    }

    return (
      <Modal
        logImpression={logImpression}
        logButtonClick={logButtonClick}
        logMessageClick={logMessageClick}
        message={message}
        clearMessage={clearMessage}
        properties={properties}
      />
    );
  }
  return null;
};

type Props = {
  onContextChange: (value: MessagerContextType) => void;
};

const InnerProvider = ({ onContextChange }: Props) => {
  const [message, setMessage] = useState<types.InAppMessage>();
  const [isAnalyticsLoaded, setisAnalyticsLoaded] = useState(false);
  const [isUserIdentified, setUserIdentified] = useState(false);
  const { ready, onUserIdentified } = useAnalytics();
  const { isTourCompleteLoading, isTourComplete } = useIsDashboardTourComplete();

  useEffect(() => {
    // The ready callback ensures Segment Anlytics has finished loading associated libraries to the window
    ready(() => {
      setisAnalyticsLoaded(true);
    });
  }, [ready]);

  useEffect(() => {
    // The onUserIdentified callback ensures Segment has loaded a user with an id
    onUserIdentified(() => setUserIdentified(true));
  }, [onUserIdentified]);

  const clearMessage = () => setMessage(undefined);

  // 1) Analytics.js is loaded
  // 2) A user has been identified in Analytics.js
  // 3) The braze SDK is present on the window
  const isBrazeReady = Boolean(isAnalyticsLoaded && window.braze && isUserIdentified);

  // Determine whether our user has completed the onboarding dashboard tour
  useEffect(() => {
    if (!isBrazeReady || isTourCompleteLoading) {
      return;
    }

    const user = window.braze.getUser();
    if (!user) {
      return;
    }

    user.setCustomUserAttribute('isDashboardTourComplete', isTourComplete);
  }, [isBrazeReady, isTourCompleteLoading, isTourComplete]);

  const isValidDashboardCard = useCallback(
    (card: Card) =>
      card.extras['type'] === BrazeContentCardType.DASHBOARD_CONTENT_CARD &&
      'mode' in card.extras &&
      'linkText' in card &&
      'url' in card &&
      'title' in card &&
      'description' in card,
    []
  );

  const isValidSplashTopCard = useCallback(
    (card: Card) =>
      card.extras['type'] === 'splash_banner_top' &&
      'mode' in card.extras &&
      'linkText' in card &&
      'url' in card &&
      'description' in card,
    []
  );

  const logCardImpression = useCallback((card: Card) => {
    window.braze.logContentCardImpressions([card]);
  }, []);

  const logCardClick = useCallback((card: Card) => {
    window.braze.logContentCardClick(card);
  }, []);

  const logCardDismissed = useCallback((card: Card) => {
    window.braze.logCardDismissal(card);
  }, []);

  useEffect(() => {
    if (!isBrazeReady) {
      return;
    }
    window.braze.requestContentCardsRefresh();
    const subscription = window.braze.subscribeToContentCardsUpdates((subscriber) => {
      const controlContentCards: Card[] = [];
      const nonControlContentCards: Card[] = [];
      subscriber.cards.forEach((card) => {
        if (card.isControl) {
          controlContentCards.push(card);
        } else {
          const cardId = card.id || '';
          if (!('type' in card.extras)) {
            Logger.log(`Content card does not have a type, id: ${cardId}`, 'warn');
            card.dismissCard();
            return;
          }
          switch (card.extras['type']) {
            case BrazeContentCardType.DASHBOARD_CONTENT_CARD:
              if (!isValidDashboardCard(card)) {
                Logger.log(`Invalid dashboard content card, id: ${cardId}`, 'warn');
                card.dismissCard();
                return;
              }
              break;
            case BrazeContentCardType.SPLASH_BANNER_TOP:
              if (!isValidSplashTopCard(card)) {
                Logger.log(`Invalid splash banner top content card, id: ${cardId}`, 'warn');
                card.dismissCard();
                return;
              }
              break;
            default:
              Logger.log(`Invalid content card type: ${card.extras['type'] || ''}`, 'warn');
              card.dismissCard();
          }
          nonControlContentCards.push(card);
        }
      });
      window.braze.logContentCardImpressions(controlContentCards);

      onContextChange({
        cards: nonControlContentCards,
        logImpression: logCardImpression,
        logClick: logCardClick,
        logDismissal: logCardDismissed,
      });
    });
    window.braze.subscribeToInAppMessage((m) => {
      if (m.isControl) {
        window.braze.showInAppMessage(m);
      } else {
        setMessage(m);
      }
    });

    return () => {
      if (subscription) {
        window.braze.removeSubscription(subscription);
      }
    };
  }, [
    isBrazeReady,
    isValidDashboardCard,
    isValidSplashTopCard,
    logCardImpression,
    logCardDismissed,
    logCardClick,
    onContextChange,
  ]);

  const canRender = message && isBrazeReady;

  if (canRender) {
    const parsedProperties = extractProperties(message.extras);
    return <Messager clearMessage={clearMessage} message={message} properties={parsedProperties} />;
  }

  return null;
};

export const Provider = ({ children }: { children: ReactNode }) => {
  const [contextValue, setContextValue] = useState<MessagerContextType | Record<string, never>>({});
  const [isEnabled] = useFeature(FeatureFlags.InAppMarketingEnabled, true);
  return (
    <MessagerContext.Provider value={contextValue}>
      {children}
      {isEnabled && (
        <ErrorBoundary FallbackComponent={() => null}>
          <InnerProvider onContextChange={setContextValue} />
        </ErrorBoundary>
      )}
    </MessagerContext.Provider>
  );
};
