import { TrackerTimelineStatusEnum } from '@melio/platform-api';

export const getTrackerStepIndex = (trackerTimelineStatus: TrackerTimelineStatusEnum) => {
  const mapTrackerTimelineStatusToStepIndex: Record<TrackerTimelineStatusEnum, number> = {
    [TrackerTimelineStatusEnum.SCHEDULED]: 0,
    [TrackerTimelineStatusEnum.SENT]: 1,
    [TrackerTimelineStatusEnum.DEPOSITED]: 2,
    [TrackerTimelineStatusEnum.FAILED]: 0,
  };
  const trackerStepIndex = mapTrackerTimelineStatusToStepIndex[trackerTimelineStatus];

  return { trackerStepIndex };
};
