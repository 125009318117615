import { useEffect } from 'react';

import { MessageCallback, partnerBridgeService } from './PartnerBridgeService';

export function useInitPartnerBridge() {
  useEffect(() => {
    const cleanListeners = partnerBridgeService.init();
    return cleanListeners;
  }, []);
}

export function useListenToEvent(event: string, cb: MessageCallback) {
  useEffect(() => {
    const unlisten = partnerBridgeService.listenToMessage(event, cb);
    return unlisten;
  }, [event, cb]);
}

export function useSendMessage() {
  return partnerBridgeService.sendMessage;
}
