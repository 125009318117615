import { Route, Routes, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { ShiftVirtualCardToACHActivity, SuvcAcceptanceActivity } from '@melio/vex-activities';
import { BaseErrorScreen } from '@melio/vex-activities/src/components/base-screens';

import { vendorActionVirtualCardIdSelector } from '@/store/VendorActions/VendorAction.model';

export const SuvcAcceptanceRoute = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { paymentId } = useParams<{ paymentId: string }>();
  const virtualCardId = useRecoilValue(vendorActionVirtualCardIdSelector);

  setAnalyticsProperties({ paymentId, virtualCardId, pageEntryPoint: 'suvc-delivered-email', flow: 'suvc-acceptance' });
  const trackBaseError = {
    name: 'suvc-acceptance-error',
  };
  const GeneralServerErrorScreen = (
    <BaseErrorScreen trackBaseError={trackBaseError} description={formatMessage('vex.generalServerError')} />
  );

  if (!paymentId) return GeneralServerErrorScreen;

  return (
    <Routes>
      <Route path="/shift-suvc-to-ach/*" element={<ShiftVirtualCardToACHActivity paymentId={paymentId} />} />
      <Route path="error" element={GeneralServerErrorScreen} />
      <Route path="*" element={<SuvcAcceptanceActivity paymentId={paymentId} />} />
    </Routes>
  );
});

SuvcAcceptanceRoute.displayName = 'SuvcAcceptance';
