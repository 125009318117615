import { ExternalLayout } from '@melio/penny';
import {
  DeliveryMethodByPayee,
  UnilateralRequest,
  useDeliveryMethods,
  useUnilateralRequests,
  useVendor,
  Vendor,
  VirtualAccountDeliveryMethod,
} from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { isAfter } from 'date-fns';

import { WithActivityConfig } from '../../contexts/ActivityConfig';
import { AddDeliveryMethodByVendorActivity } from '../add-delivery-method-by-payee';
import { DeliveryMethodAddedSuccessfullyWithoutPaymentScreen } from '../add-delivery-method-by-payee/screens';
import { DeliveryMethodExpiredScreen } from '../add-delivery-method-by-payee/screens/DeliveryMethodExpired';
import {
  AddDeliveryMethodByUnilateralActivityConfigContext,
  createActivityConfig,
} from './AddDeliveryMethodByUnilateral.config';
import { AddDeliveryMethodByUnilateralActivityProps } from './types';

export const useAddDeliveryMethodByUnilateralActivity = ({
  vendorId,
  unilateralRequestId,
}: {
  vendorId: Vendor['id'];
  unilateralRequestId?: UnilateralRequest['id'];
}) => {
  const { data: vendorDeliveryMethods, isLoading: isLoadingVendorDeliveryMethods } = useDeliveryMethods({
    vendorId,
    scope: 'unilateral',
  });
  const { data: unilateralRequests, isLoading: isLoadingUnilateralRequests } = useUnilateralRequests({ vendorId });
  const { data: vendor, isLoading: isLoadingVendor } = useVendor({ id: vendorId });
  const { createDate } = useDateUtils();

  const isRequestExpired = () => {
    if (!vendor) {
      return true;
    }

    const virtualDeliveryMethod = vendorDeliveryMethods?.find(({ type }) => type === 'virtual-account') as
      | VirtualAccountDeliveryMethod
      | undefined;
    if (!virtualDeliveryMethod) {
      return false;
    }

    const virtualAccountExpirationDate = virtualDeliveryMethod.details.expiresAt;
    return !!virtualAccountExpirationDate && isAfter(createDate(), virtualAccountExpirationDate);
  };

  const getVendorSelectedDeliveryMethod = () => {
    const selectedDeliveryMethodId = unilateralRequests?.find(
      ({ id }) => id === unilateralRequestId
    )?.selectedDeliveryMethodId;

    return selectedDeliveryMethodId
      ? (vendorDeliveryMethods?.find(({ id }) => id === selectedDeliveryMethodId) as DeliveryMethodByPayee)
      : (vendorDeliveryMethods?.find(({ type }) => type !== 'virtual-account') as DeliveryMethodByPayee);
  };

  const isLoadingExpiration = isLoadingVendorDeliveryMethods || isLoadingVendor;
  const isLoadingSuccess = isLoadingUnilateralRequests || isLoadingVendorDeliveryMethods;

  return {
    hasExpiredRequest: isLoadingExpiration ? null : isRequestExpired(),
    isLoadingExpiration,
    selectedDeliveryMethod: isLoadingSuccess ? null : getVendorSelectedDeliveryMethod(),
  };
};

const Activity: React.VFC<AddDeliveryMethodByUnilateralActivityProps> = ({
  vendorId,
  unilateralRequestId,
  accountName,
  accountEmail,
  onError,
  onDone,
}) => {
  const { hasExpiredRequest, isLoadingExpiration, selectedDeliveryMethod } = useAddDeliveryMethodByUnilateralActivity({
    vendorId,
    unilateralRequestId,
  });

  if (isLoadingExpiration) {
    return <ExternalLayout isLoading />;
  }

  return hasExpiredRequest ? (
    <DeliveryMethodExpiredScreen accountEmail={accountEmail} accountName={accountName} />
  ) : selectedDeliveryMethod ? (
    <DeliveryMethodAddedSuccessfullyWithoutPaymentScreen
      accountName={accountName}
      deliveryMethod={selectedDeliveryMethod}
      wasDeliveryMethodPreExisted
    />
  ) : (
    <AddDeliveryMethodByVendorActivity
      vendorId={vendorId}
      accountName={accountName}
      onDone={onDone}
      onError={onError}
    />
  );
};

export const AddDeliveryMethodByUnilateralActivity: React.VFC<AddDeliveryMethodByUnilateralActivityProps> = (props) => (
  <WithActivityConfig Context={AddDeliveryMethodByUnilateralActivityConfigContext} createConfig={createActivityConfig}>
    <Activity {...props} />
  </WithActivityConfig>
);
