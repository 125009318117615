export const NOT_FOUND_PAGE = '/melio/404';

export const enum VendorOnboardingCardsEnum {
  TermAndConditions = 'term-and-conditions',
  EmailVerification = 'email-verification',
  PersonalDetails = 'personal-details',
  BusinessDetails = 'business-details',
  BankDetails = 'bank-details',
  UploadLogo = 'upload-logo',
  ReviewDetails = 'review-details',
}

export const vendorOnboardingCardsOrder = [
  VendorOnboardingCardsEnum.TermAndConditions,
  VendorOnboardingCardsEnum.EmailVerification,
  VendorOnboardingCardsEnum.PersonalDetails,
  VendorOnboardingCardsEnum.BusinessDetails,
  VendorOnboardingCardsEnum.BankDetails,
  VendorOnboardingCardsEnum.UploadLogo,
  VendorOnboardingCardsEnum.ReviewDetails,
];
