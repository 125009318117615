import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VerifyEmailIcon = (props: IconProps) => (
  <Icon width="82px" height="82px" viewBox="0 0 82 82" fill="none" {...props}>
    <path
      d="M67.087 29.6957L81 39.2609V75.7826C81 77.1663 80.4503 78.4934 79.4719 79.4719C78.4934 80.4503 77.1663 81 75.7826 81H6.21739C4.83365 81 3.50659 80.4503 2.52814 79.4719C1.54969 78.4934 1 77.1663 1 75.7826V39.2609L14.913 29.6957"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4355 70.5652L28.8269 56.6522H53.1747L70.566 70.5652"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.0004 39.2609L60.1309 53.1739"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 39.2609L21.8696 53.1739" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M67.086 48.5374V4.47826C67.086 3.55577 66.7196 2.67106 66.0673 2.01876C65.415 1.36646 64.5303 1 63.6078 1H18.3904C17.4679 1 16.5832 1.36646 15.9309 2.01876C15.2786 2.67106 14.9121 3.55577 14.9121 4.47826V48.5374"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.0006 39.261L29.1432 26.8923C28.1019 25.8507 27.4145 24.5079 27.1786 23.054C26.9426 21.6002 27.17 20.1089 27.8285 18.7914C28.3257 17.7974 29.052 16.9357 29.9476 16.2774C30.8431 15.619 31.8823 15.1829 32.9794 15.0049C34.0766 14.827 35.2003 14.9122 36.258 15.2536C37.3158 15.5951 38.2772 16.1829 39.0632 16.9688L41.0006 18.9062L42.938 16.9688C43.724 16.1829 44.6855 15.5951 45.7432 15.2536C46.801 14.9122 47.9247 14.827 49.0218 15.0049C50.119 15.1829 51.1581 15.619 52.0537 16.2774C52.9492 16.9357 53.6756 17.7974 54.1728 18.7914V18.7914C54.8313 20.1089 55.0587 21.6002 54.8227 23.054C54.5867 24.5079 53.8994 25.8507 52.858 26.8923L41.0006 39.261Z"
      stroke="#228800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
