import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StartNewExperienceIcon = (props: IconProps) => (
  <Icon width="100px" height="100px" viewBox="0 0 100 100" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
      fill="#98D5EC"
    />
    <mask
      id="mask0_8974_431976"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="100"
      height="100"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_8974_431976)">
      <path d="M99.0099 70H0V100H99.0099V70Z" fill="#273B49" />
      <path d="M84.1585 30H14.8516V60H84.1585V30Z" fill="#FDDA21" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7822 60H24.7525V70H21.7822V60Z" fill="#071A29" />
      <mask
        id="mask1_8974_431976"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="30"
        width="71"
        height="30"
      >
        <path d="M84.1585 30H14.8516V60H84.1585V30Z" fill="white" />
      </mask>
      <g mask="url(#mask1_8974_431976)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.4059 60H49.5049L59.4059 30H69.3069L59.4059 60Z"
          fill="#F69139"
        />
      </g>
      <mask
        id="mask2_8974_431976"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="30"
        width="71"
        height="30"
      >
        <path d="M84.1585 30H14.8516V60H84.1585V30Z" fill="white" />
      </mask>
      <g mask="url(#mask2_8974_431976)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.2076 60H69.3066L79.2076 30H89.1086L79.2076 60Z"
          fill="#F69139"
        />
      </g>
      <mask
        id="mask3_8974_431976"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="30"
        width="71"
        height="30"
      >
        <path d="M84.1585 30H14.8516V60H84.1585V30Z" fill="white" />
      </mask>
      <g mask="url(#mask3_8974_431976)">
        <mask
          id="mask4_8974_431976"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="30"
          width="71"
          height="30"
        >
          <path d="M84.1585 30H14.8516V60H84.1585V30Z" fill="white" />
        </mask>
        <g mask="url(#mask4_8974_431976)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.6041 60H29.7031L39.6041 30H49.5051L39.6041 60Z"
            fill="#F69139"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8023 60H9.90137L19.8023 30H29.7033L19.8023 60Z"
            fill="#F69139"
          />
        </g>
      </g>
      <path d="M77.2281 60H74.2578V70H77.2281V60Z" fill="#071A29" />
    </g>
  </Icon>
);
