import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Tax1099Icon = (props: IconProps) => (
  <Icon width="160" height="149" viewBox="0 0 160 149" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M159.91 124.444C159.91 124.622 159.91 124.8 159.998 124.978C160.976 137.244 150.221 139.378 142.043 141.333C120.976 146.489 99.3762 148.711 77.7762 148.267C57.5984 147.822 35.1984 147.644 16.0873 140.267C11.2873 138.4 6.48731 135.911 3.55398 131.378C-11.7349 108.178 27.6429 105.689 37.8651 103.822C51.8206 101.333 66.0429 100.355 80.2651 100.355C101.865 100.355 129.154 100.711 149.243 110.4C154.487 113.067 159.11 118.133 159.91 124.444Z"
        fill="#EFEFEF"
      />
      <path
        d="M116.978 124.356H42.3109C39.1998 124.356 36.7109 121.867 36.7109 118.756V21.0668C36.7109 17.9557 39.1998 15.4668 42.3109 15.4668H116.978C120.089 15.4668 122.578 17.9557 122.578 21.0668V118.756C122.578 121.778 120.089 124.356 116.978 124.356Z"
        fill="#85E8C7"
      />
      <path
        d="M99.998 20.8891C101.065 32.3557 115.198 40.8891 127.287 34.4891C136.798 29.4224 138.665 16.8891 132.265 8.35574C127.02 1.33352 115.909 -0.710923 108.531 3.55574C102.398 7.1113 99.3758 13.778 99.998 20.8891Z"
        fill="white"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.22 14.4894C122.22 10.4894 115.375 10.1339 115.109 14.045C115.02 15.645 116.531 16.8894 117.953 17.6894C119.464 18.4894 121.775 20.0894 122.22 21.9561C122.753 24.5339 119.553 26.2228 117.331 25.7783C115.909 25.4228 114.664 24.2672 114.309 22.845"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.688 11.2896C117.688 10.4896 117.688 9.68963 117.688 8.97852"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.398 26.2227C118.487 26.8449 118.487 27.4671 118.576 28.0893"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4657 124.356C49.4212 124.356 42.2212 125.245 36.3545 123.467C34.8434 116.8 35.9101 108.711 35.999 101.867C36.0879 94.0447 36.1768 86.1336 36.2657 78.3114C36.4434 62.5781 36.5323 46.9336 35.9101 31.2003C35.7323 27.7336 35.8212 24.8003 36.2657 21.2447C36.4434 20.0892 36.6212 18.7558 37.4212 17.8669C38.4879 16.6225 40.2657 16.4447 41.8657 16.3558C58.4879 15.1114 75.199 15.4669 91.999 15.4669"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.576 44.9785C122.576 56.5341 122.576 68.0007 122.576 79.5563C122.576 87.5563 122.576 95.4674 122.576 103.467C122.576 108.801 123.554 115.734 122.487 120.979C122.131 122.579 121.154 124.179 119.554 124.801C118.754 125.067 117.865 125.067 116.976 125.067C100.62 124.801 84.1759 124.356 67.8203 124.356"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.4883 61.422C50.4883 61.422 55.8216 52.9776 56.5327 48.8887C56.9772 58.0442 57.1549 68.7998 57.0661 77.9553"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.2223 61.7788C62.4001 65.4232 62.8445 68.9788 63.8223 72.4454C64.2668 74.3121 65.0668 76.2676 66.8445 76.9788C68.3557 77.5121 70.1334 76.801 71.2001 75.5565C72.2668 74.3121 72.8001 72.7121 73.1557 71.1121C74.0445 67.0232 75.0223 48.8899 68.2668 48.001C63.3779 47.4677 62.0445 58.5788 62.2223 61.7788Z"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3314 61.5113C88.6203 62.4002 87.5536 63.1113 86.3092 63.2002C84.2648 63.378 82.3981 62.0447 81.3314 60.3558C80.2648 58.6669 79.9092 56.5336 79.9981 54.578C79.9981 52.8002 80.2648 51.0225 81.3314 49.6002C82.3092 48.178 84.2648 47.2891 85.8648 47.8225C88.087 48.6225 88.7092 51.378 88.887 53.6891C89.4203 58.6669 89.4203 63.6447 89.2425 68.6225C89.1536 70.578 89.0648 72.5336 88.2648 74.3113C87.4648 76.0891 85.9536 77.6891 83.9981 77.9558C82.0425 78.2224 79.9092 76.8891 79.8203 74.9336"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.333 59.2002C105.511 61.2447 103.733 63.0225 101.689 63.2002C99.6444 63.378 97.7777 62.0447 96.7111 60.3558C95.6444 58.6669 95.2888 56.5336 95.3777 54.578C95.3777 52.8002 95.6444 51.0225 96.7111 49.6002C97.6888 48.178 99.6444 47.2891 101.244 47.8225C103.467 48.6225 104.089 51.378 104.267 53.6891C104.711 58.6669 104.8 63.6447 104.533 68.5336C104.444 70.578 104.178 72.6225 103.289 74.4002C102.4 76.178 100.711 77.778 98.6666 77.8669C96.5333 78.0447 94.3111 76.178 94.6666 74.0447"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6641 95.4672C59.3752 95.3783 71.1085 95.3783 79.8196 95.645"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.5547 95.5548C95.6436 95.4659 99.7325 95.2881 103.821 95.1992"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.8672 103.022C59.0227 102.845 60.5339 103.111 69.6894 103.556"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.7539 103.2C95.1983 102.844 99.6428 102.844 103.998 103.289"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5977 28.5332C52.7977 28.6221 55.9977 28.711 59.1977 28.7999"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.2188 28.8887C54.3076 31.822 54.3076 34.7553 54.3965 37.6887"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.6211 37.9563C62.31 35.2007 63.4655 32.0896 64.1767 28.9785C64.2655 29.2452 68.8878 37.6896 69.2433 37.9563"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.6641 35.6436C64.4418 35.7325 64.7085 35.7325 66.5752 35.5547"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.7969 28.5332C75.6413 31.5554 78.1302 35.0221 81.5969 37.2443"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.6211 37.421C72.6211 37.421 79.5544 29.6877 81.4211 28.7988"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="160" height="148.356" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
