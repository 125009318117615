import * as braze from '@braze/web-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PositionedBanner, PositionedBannerProps } from './PositionedBanner';
import { BannerProps } from './types';
import { getMode, splitMessage } from './utils';

export const BrazeBanner = ({
  clearMessage,
  logMessageClick,
  logImpression,
  properties,
  logButtonClick,
  message,
}: BannerProps) => {
  const {
    buttons: [firstButton, secondButton],
  } = message;

  const { elementProperties } = properties;
  const navigate = useNavigate();

  const _message = message.message;

  useEffect(() => {
    logImpression();
  }, [logImpression]);

  if (!message || !_message || (!firstButton && !secondButton)) {
    return null;
  }

  const mode = getMode(properties.generalProperties.get('mode'));
  const isIcon = properties.generalProperties.get('isIcon') === 'true';

  const cta = firstButton && secondButton ? secondButton : firstButton;

  const firstButtonText = firstButton?.text;
  const firstButtonOpenInNewTab =
    elementProperties.has('button1') && elementProperties.get('button1')?.get('newTab') === 'true';

  const secondButtonText = secondButton?.text;
  const secondButtonOpenInNewTab =
    elementProperties.has('button2') && elementProperties.get('button2')?.get('newTab') === 'true';

  const ctaAsLink = properties.generalProperties.get('ctaAsLink') === 'true';

  const title = message.header;

  const { description } = splitMessage(_message, '__subDescription__');

  if (!title || !description || !cta || !message.header) {
    return null;
  }

  const onClose = () => {
    logMessageClick();
    clearMessage();
  };

  const logAndClear = (button: braze.InAppMessageButton) => {
    logButtonClick(button);
    clearMessage();
  };

  const route = (uri: string, openInNewTab: boolean) => {
    if (uri.startsWith('/')) {
      if (openInNewTab) {
        const fullUrl = `${window.location.origin}/melio${uri}`;
        return window.open(fullUrl);
      }
      return navigate(uri);
    }

    if (openInNewTab) {
      return window.open(uri);
    }

    return window.location.replace(uri);
  };

  const handleClick = (button: braze.InAppMessageButton, openInNewTab: boolean) => {
    logAndClear(button);
    if (button.uri) {
      route(button.uri, openInNewTab);
    }
  };

  const props: PositionedBannerProps = {
    mode,
    title,
    description,
    onClose,
    ...(ctaAsLink
      ? {
          linkText: cta.text,
          linkOnClick: () => handleClick(cta, secondButton ? secondButtonOpenInNewTab : firstButtonOpenInNewTab),
          linkHref: cta.uri,
        }
      : secondButton && firstButton
      ? {
          secondaryButtonText: firstButtonText,
          secondaryButtonOnClick: () => handleClick(firstButton, firstButtonOpenInNewTab),
          primaryButtonText: secondButtonText,
          primaryButtonOnClick: () => handleClick(secondButton, secondButtonOpenInNewTab),
        }
      : {
          primaryButtonText: firstButtonText,
          primaryButtonOnClick: () => handleClick(cta, firstButtonOpenInNewTab),
        }),
    ...(message.imageUrl && { imageUrl: message.imageUrl, imageAsIcon: isIcon }),
  };

  return <PositionedBanner {...props} />;
};
