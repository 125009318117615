export const NoItems: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11448_697271)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#8BCFEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.6 99.1076L99.108 93.5996L112.392 104.94C113.472 105.804 114.444 106.776 115.416 107.748L121.68 114.012C123.84 116.172 123.84 119.52 121.68 121.68C119.52 123.84 116.172 123.84 114.012 121.68L107.1 114.768C106.56 114.228 105.912 113.58 105.48 112.932L93.6 99.1076Z"
        fill="#273B49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 105.48C53.532 105.48 38.52 90.4675 38.52 71.9995C38.52 53.5315 53.532 38.5195 72 38.5195C90.468 38.5195 105.48 53.5315 105.48 71.9995C105.48 90.4675 90.468 105.48 72 105.48Z"
        fill="#98D5EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 41.7603C88.632 41.7603 102.24 55.3683 102.24 72.0003C102.24 88.6323 88.632 102.24 72 102.24C55.368 102.24 41.76 88.6323 41.76 72.0003C41.76 55.3683 55.368 41.7603 72 41.7603ZM72 35.2803C51.696 35.2803 35.28 51.6963 35.28 72.0003C35.28 92.3043 51.696 108.72 72 108.72C92.304 108.72 108.72 92.3043 108.72 72.0003C108.72 51.6963 92.304 35.2803 72 35.2803Z"
        fill="#273B49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.844 77.4002C92.844 80.5322 92.304 83.5562 91.224 86.3642C94.248 82.3682 96.084 77.4002 96.084 72.0002C96.084 58.8242 85.5 48.2402 72.324 48.2402C66.924 48.2402 61.956 50.0762 57.96 53.1002C60.768 52.0202 63.792 51.4802 66.924 51.4802C81.288 51.4802 92.844 63.0362 92.844 77.4002Z"
        fill="#E2F3FC"
      />
    </g>
    <defs>
      <clipPath id="clip0_11448_697271">
        <rect width="100%" height="100%" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
