import { Box } from '@chakra-ui/react';
import { Card, Container, ExternalLayout, Group, IconButton } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  FooterWidget,
  PoweredByMelioWidget,
  VerificationCode,
  VerificationCodeFailureResult,
} from '@melio/vex-widgets';
import { useEffect } from 'react';

import { useUtmParameters } from '../../../../hooks/useUtmParameters';

export type CodeVerificationScreenProps = {
  onBack: VoidFunction;
  onDone: (code: string, email: string) => Promise<boolean | void>;
  onUserNotFound: VoidFunction;
  generateNewCode: (emailAddress: string) => void;
  email: string | null;
  showPoweredByMelio?: boolean;
  helpLinkHref: string;
  resendEmailError?: string;
};

export const CodeVerificationScreen = withAnalyticsContext<CodeVerificationScreenProps>(
  forwardRef(
    (
      {
        onBack,
        onDone,
        onUserNotFound,
        generateNewCode,
        email,
        showPoweredByMelio,
        helpLinkHref,
        resendEmailError,
        children,
        setAnalyticsProperties,
        ...props
      },
      ref
    ) => {
      const { utmMedium, utmCampaign } = useUtmParameters();
      setAnalyticsProperties({
        EntryPoint: utmMedium ?? undefined,
        Flow: utmCampaign ?? undefined,
        PageName: 'enter-code',
        Intent: 'complete-verification',
        BusinessEmail: email,
      });
      usePageView('Vendor');
      const { track } = useAnalytics();

      const handleDone = (...args: Parameters<CodeVerificationScreenProps['onDone']>) =>
        onDone(...args)
          .then(() => {
            track('Vendor', 'Status', {
              StatusType: 'success',
            });
          })
          .catch((e) => {
            track('Vendor', 'Status', {
              StatusType: 'Failure',
              ErrorType:
                e === VerificationCodeFailureResult.UserNotFoundError ? 'no-user-found' : 'code-does-not-match',
            });
            throw e;
          });

      useEffect(() => {
        if (!email) {
          onBack();
        }
      }, [email, onBack]);

      if (!email) {
        return null;
      }

      const onClickBack = () => {
        track('Vendor', 'Click', {
          Cta: 'back',
        });
        onBack();
      };

      const content = (
        <Card paddingX="none" paddingY="none">
          <Group variant="vertical" spacing="none" width="full">
            <Container paddingX="xl" paddingY="l" backgroundColor="light">
              <Box gap="l">
                <IconButton aria-label="back" variant="naked" onClick={onClickBack} icon="chevron-left" size="medium" />
              </Box>
            </Container>
            <Container paddingX="xl" paddingY="l">
              <Group variant="vertical">
                <VerificationCode
                  emailAddress={email}
                  generateNewCode={generateNewCode}
                  helpLinkHref={helpLinkHref}
                  onCodeComplete={handleDone}
                  onUserNotFound={onUserNotFound}
                  resendEmailError={resendEmailError}
                />
              </Group>
            </Container>
          </Group>
        </Card>
      );

      const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

      return (
        <ExternalLayout
          data-component="CodeVerificationScreen"
          data-testid="code-verification-screen"
          {...props}
          content={content}
          footer={footer}
          ref={ref}
        />
      );
    }
  )
);

CodeVerificationScreen.displayName = 'CodeVerificationScreen';
