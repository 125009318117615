import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiKeys, VexGuestVendorApiClient } from '@melio/platform-api-axios-client';

type UseVendorPreferencesProps = {
  vendorId: string | undefined;
  options?: UseModelApiProps<typeof VexGuestVendorApiClient.getVexGuestVendorPreferences>;
};

export const useVendorPreferences = ({ vendorId, options = {} }: UseVendorPreferencesProps) =>
  useModelApi({
    ...options,
    id: vendorId,
    queryKey: [ApiKeys.VexGuestVendorApi, 'vexVendorPreferences', vendorId],
    queryFn: () => VexGuestVendorApiClient.getVexGuestVendorPreferences(vendorId as string),
  });
