import { LinkOverrides } from '../types';

export const capOneLinksOverrides: LinkOverrides = {
  helpCenter: 'https://capitalonevendorpayments.zendesk.com/hc/en-us',
  failedToCollectSupport:
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5040345921820-All-the-reasons-why-your-payment-could-fail',
  'app.settings.SupportSection.accordion.businessBills.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4880792978972',
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4977565763740-',
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5024954247452-Sync-your-QuickBooks-Online-account-with-the-Vendor-Payments-experience',
  'widgets.companySettings.header.sectionBanner.linkHref': 'tel:1-800-867-0904',
};
