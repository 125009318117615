import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { mixed, object, SchemaOf } from 'yup';

import { W9FileFormModel } from '../../types';

export const useUploadW9FileSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  return object().shape({
    w9file: mixed<File>()
      .required(formatMessage('vex.widgets.collectW9File.uploadFile.validate.empty'))
      .test('is-file-not-empty', formatMessage('vex.widgets.collectW9File.uploadFile.validate.empty'), (w9file) => {
        const isFilePresent = !!w9file;
        if (!isFilePresent) {
          track('VexCollectW9', 'Vendor-Status', {
            PageName: 'add-your-tax-info',
            Intent: 'upload-w9',
            TaxIdType: 'upload-pdf',
            ErrorType: 'file-is-empty',
            Status: 'failure',
          });
        }
        return isFilePresent;
      })
      .test(
        'is-file-size-below-limit',
        formatMessage('vex.widgets.collectW9File.uploadFile.validate.sizeError'),
        (w9file) => {
          if (w9file) {
            const isBellowSizeLimit = w9file.size <= 4 * 1024 * 1024; // 4MB
            if (!isBellowSizeLimit) {
              track('VexCollectW9', 'Vendor-Status', {
                PageName: 'add-your-tax-info',
                Intent: 'upload-w9',
                TaxIdType: 'upload-pdf',
                ErrorType: 'file-is-too-long',
                Status: 'failure',
              });
            }
            return isBellowSizeLimit;
          }
          return true;
        }
      )
      .test(
        'is-file-of-correct-type',
        formatMessage('vex.widgets.collectW9File.uploadFile.validate.typeError'),
        (w9file) => {
          const fileTypes = ['application/pdf', 'image/png', 'image/jpeg'];

          if (w9file) {
            return fileTypes.includes(w9file.type);
          }
          return true;
        }
      ),
  }) as SchemaOf<W9FileFormModel>;
};
