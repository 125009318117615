import { PartnerName } from '@melio/platform-api';

import { LocalStorage, LocalStorageKeys, PartnerLocalStorageKey, PartnerLocalStorageParams } from './types';

let isLocalStorage = true;
const localStorageObj: { [key in PartnerLocalStorageKey]?: string } = {};

const getLocalStorageKey = (key: LocalStorageKeys, partnerName: PartnerName): PartnerLocalStorageKey =>
  `${partnerName}:${key}`;

export const getPartnerLocalStorage = ({ partnerName }: PartnerLocalStorageParams): LocalStorage => {
  const setItem = (key: LocalStorageKeys, value: string): void => {
    const localStorageKey = getLocalStorageKey(key, partnerName);
    localStorageObj[localStorageKey] = value;

    try {
      if (isLocalStorage) {
        window.localStorage.setItem(localStorageKey, value);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      isLocalStorage = false;
    }
  };

  const removeItem = (key: LocalStorageKeys): void => {
    const localStorageKey = getLocalStorageKey(key, partnerName);
    delete localStorageObj[localStorageKey];

    try {
      if (isLocalStorage) {
        window.localStorage.removeItem(localStorageKey);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      isLocalStorage = false;
    }
  };

  const getItem = (key: LocalStorageKeys): string | null => {
    const localStorageKey = getLocalStorageKey(key, partnerName);

    try {
      if (isLocalStorage) {
        return window.localStorage.getItem(localStorageKey);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      isLocalStorage = false;
    }

    return localStorageObj[localStorageKey] || null;
  };

  const getObject = <T>(key: LocalStorageKeys): Partial<T> => JSON.parse(getItem(key) || '{}') as Partial<T>;

  const setObject = <T>(key: LocalStorageKeys, value: T) => {
    setItem(key, JSON.stringify(value));
  };

  return {
    setItem,
    getItem,
    removeItem,
    setObject,
    getObject,
  };
};
