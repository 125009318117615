import { Box, Flex } from '@chakra-ui/react';
import { ModalProps, Text } from '@melio/penny';
import React, { useEffect } from 'react';

import { ModalCopiedFromPenny } from './ModalCopiedFromPenny';
import { Props } from './types';
import { splitMessage } from './utils';

const legacyFirstButtonClick = `clickBehaviorButton01`;
const legacySecondButtonClick = `clickBehaviorButton02`;

export const DoubleButtonModal = ({ onButtonClick, onClose, message, logImpression, properties }: Props) => {
  const {
    buttons: [firstButton, secondButton],
  } = message;

  const _message = message.message;

  useEffect(() => {
    logImpression();
  }, [logImpression]);

  if (!firstButton || !secondButton || !_message) {
    return null;
  }

  const { elementProperties, generalProperties } = properties;

  const { description, subDescription } = splitMessage(_message, '__subDescription__');

  const openInNewTabButton2 =
    (elementProperties.has('button2') && elementProperties.get('button2')?.get('newTab') === 'true') ||
    generalProperties.get(legacySecondButtonClick) === 'newTab';
  const primaryButtonProps: ModalProps['primaryButton'] = {
    label: secondButton.text,
    variant: 'primary',
    onClick: () => onButtonClick(secondButton, openInNewTabButton2),
  };

  const openInNewTabButton1 =
    (elementProperties.has('button1') && elementProperties.get('button1')?.get('newTab') === 'true') ||
    generalProperties.get(legacyFirstButtonClick) === 'newTab';
  const variant =
    elementProperties.has('button1') && elementProperties.get('button1')?.get('isTertiary') === 'true'
      ? 'tertiary'
      : 'secondary';
  const secondaryButtonProps: ModalProps['secondaryButton'] = {
    label: firstButton.text,
    variant,
    onClick: () => onButtonClick(firstButton, openInNewTabButton1),
  };

  return (
    <ModalCopiedFromPenny
      onClose={onClose}
      isOpen
      asset={<img src={message.imageUrl} alt="modal image" />}
      header={message.header}
      primaryButton={primaryButtonProps}
      secondaryButton={secondaryButtonProps}
    >
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Box textStyle="body" whiteSpace="pre-line">
            {description}
          </Box>
          <Text textStyle="body4">{subDescription}</Text>
        </Flex>
      </Flex>
    </ModalCopiedFromPenny>
  );
};
