import { Box } from '@chakra-ui/react';
import { Card, Container, ExternalLayout, Group, Text } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  PaperCheckDeliveryMethod,
  Payment,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  AcceptPaymentSuccessHeaderWidget,
  DeliveryDateListItemWidget,
  DeliveryMethodListItemWidget,
  FooterWidget,
  JoinMelioWidget,
} from '@melio/vex-widgets';
import { ReactElement } from 'react';

export type AcceptPaymentSuccessScreenProps = {
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod | VirtualCardAccountDeliveryMethod;
  paymentEstimatedDelivery: Payment['estimatedDelivery'];
  paymentMaxEstimatedDelivery: Payment['maxEstimatedDelivery'];
  showJoinMelioFooter: boolean;
  headerTitle: string | undefined;
  headerDescription: string | undefined;
  externalLayoutHeader?: ReactElement;
};

export const AcceptPaymentSuccessScreen = forwardRef<AcceptPaymentSuccessScreenProps, 'div'>(
  (
    {
      deliveryMethod,
      paymentEstimatedDelivery,
      paymentMaxEstimatedDelivery,
      headerTitle,
      showJoinMelioFooter,
      externalLayoutHeader,
      headerDescription,
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    usePageView('Payment', {
      PageName: 'payment-accepted',
      Intent: 'payment-accepted',
      DeliveryMethodType: deliveryMethod.type,
      DeliveryMethodId: deliveryMethod.id,
    });

    const footer = <FooterWidget />;

    return (
      <ExternalLayout
        data-component="acceptPaymentSuccessScreen"
        data-testid="accept-payment-success-screen"
        header={externalLayoutHeader}
        content={
          <Card paddingX="none" paddingY="none">
            <Group variant="vertical">
              <Container paddingX="xl" paddingY="l">
                <Group variant="vertical" spacing="xxl">
                  <AcceptPaymentSuccessHeaderWidget title={headerTitle} description={headerDescription} />
                  <Group variant="vertical" spacing="s">
                    <Text textStyle="body4SemiUpper" color="global.neutral.900">
                      {formatMessage('vex.screen.acceptPaymentSuccess.paymentSection.title')}
                    </Text>
                    <Group variant="vertical" spacing="m" hasDivider>
                      <DeliveryMethodListItemWidget deliveryMethod={deliveryMethod} />
                      <DeliveryDateListItemWidget
                        deliveryMethodType={deliveryMethod.type}
                        estimatedDelivery={paymentEstimatedDelivery}
                        maxEstimatedDelivery={paymentMaxEstimatedDelivery}
                      />
                    </Group>
                  </Group>
                </Group>
              </Container>
              {showJoinMelioFooter && (
                <Box borderTop="basic.light">
                  <JoinMelioWidget />
                </Box>
              )}
            </Group>
          </Card>
        }
        footer={footer}
        ref={ref}
      />
    );
  }
);

AcceptPaymentSuccessScreen.displayName = 'AcceptPaymentSuccessScreen';
