import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { VexGuestPaymentApiClient } from '@melio/platform-api-axios-client';

export type { PaymentUnilateralDeliveryMethodTypeOption } from '@melio/platform-api-axios-client';

type UseUnilateralOrderedDeliveryMethodTypeOptionsParams = {
  paymentId: string;
  options?: UseCollectionApiProps<
    typeof VexGuestPaymentApiClient.getVexGuestPaymentUnilateralOrderedDeliveryMethodTypeOptions
  >;
};

export const useUnilateralOrderedDeliveryMethodTypeOptions = ({
  paymentId,
  options = {},
}: UseUnilateralOrderedDeliveryMethodTypeOptionsParams) =>
  useCollectionApi({
    ...options,
    queryKey: ['VexGuestPaymentApi', 'getVexGuestPaymentUnilateralOrderedDeliveryMethodTypeOptions', paymentId],
    queryFn: () => VexGuestPaymentApiClient.getVexGuestPaymentUnilateralOrderedDeliveryMethodTypeOptions(paymentId),
  });
