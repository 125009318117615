export const Bank: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_132201)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#98D5EC"
      />
      <mask
        id="mask0_11475_132201"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_132201)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7851 98.8008H119.932V126.393H22.7851V98.8008Z"
          fill="#273B49"
        />
        <path d="M110.923 95.6548H32.8636V98.8004H110.923V95.6548Z" fill="white" />
        <path d="M102.369 55.8105H40.3489V89.364H102.369V55.8105Z" fill="#DCD7DA" />
        <path d="M107.715 92.5093H35.0026V95.6549H107.715V92.5093Z" fill="#DCD7DA" />
        <path d="M105.576 89.3638H37.1405V92.5094H105.576V89.3638Z" fill="white" />
        <path d="M49.9723 51.6162H43.5565V89.3638H49.9723V51.6162Z" fill="white" />
        <path d="M59.596 51.6162H53.1802V89.3638H59.596V51.6162Z" fill="white" />
        <path d="M89.5368 51.6162H83.1209V89.3638H89.5368V51.6162Z" fill="white" />
        <path d="M99.1605 51.6162H92.7447V89.3638H99.1605V51.6162Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.973 57.9073H38.7453C38.4245 57.9073 38.1037 57.8025 37.8899 57.488L35.7512 54.6569C35.3235 54.1326 35.5374 53.5034 36.072 53.0841L70.8245 32.0083C71.1453 31.7987 71.573 31.7987 72.0007 32.0083L106.753 53.0841C107.288 53.3987 107.395 54.1326 107.074 54.6569L104.935 57.488C104.615 57.8025 104.401 57.9073 103.973 57.9073Z"
          fill="white"
        />
        <path d="M77.7738 68.3931H64.9421V89.364H77.7738V68.3931Z" fill="#C2C0C5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3591 51.6163C74.3118 51.6163 76.7057 49.269 76.7057 46.3735C76.7057 43.4781 74.3118 41.1309 71.3591 41.1309C68.4063 41.1309 66.0126 43.4781 66.0126 46.3735C66.0126 49.269 68.4063 51.6163 71.3591 51.6163Z"
          fill="#DCD7DA"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_132201">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
