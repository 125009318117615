import { AxiosError } from 'axios';

export const TIMEOUT_EVENT_NAME = 'request_timeout_partner_redirect';

export const handleTimeoutRequest = (error: unknown) => {
  const axiosError = error as AxiosError;
  if (axiosError.response?.status === 504 || axiosError.response === undefined) {
    //check if the response is from POST, DELETE, PUT, PATCH
    if (axiosError?.config?.method && ['post', 'patch', 'put', 'delete'].includes(axiosError.config.method)) {
      const event = new CustomEvent(TIMEOUT_EVENT_NAME);
      window.dispatchEvent(event);
    }
  }
};
