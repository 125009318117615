import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  DeliveryMethodSelectionByPayeeHeaderWidget,
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  ExternalLayoutContentWidget,
  FooterWidget,
  PoweredByMelioWidget,
  VendorCheckManuallyAddressPayeeForm,
} from '@melio/vex-widgets';

import { CheckAccountFormModel } from '../../../../../types';

type AddCheckAddressManuallyProps = Pick<
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  'accountName' | 'paymentAmount' | 'paymentNote' | 'paymentInvoiceNumber'
> & {
  isSaving?: boolean;
  isLoading?: boolean;
  addressValues?: Partial<CheckAccountFormModel>;
  onBack: VoidFunction;
  onDone: (data: CheckAccountFormModel) => void;
  showPoweredByMelio?: boolean;
};

const activityPrefix = 'activities.addCheckDeliveryMethodByPayee.screens.addCheckDeliveryMethod';

export const AddCheckAddressManuallyScreen = forwardRef<AddCheckAddressManuallyProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      isSaving,
      paymentNote,
      paymentInvoiceNumber,
      addressValues,
      onBack,
      onDone,
      showPoweredByMelio,
      children,
      ...props
    },
    ref
  ) => {
    usePageView('Vendor', { PageName: 'paper-check-manual', Intent: 'add-delivery-method-manually' });
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CheckAccountFormModel>();
    const legalInfoOverride = accountName
      ? formatMessage('vex.widgets.legal.infoWithAccountName', { accountName })
      : undefined;

    const contentHeader = (
      <DeliveryMethodSelectionByPayeeHeaderWidget
        accountName={accountName}
        paymentNote={paymentNote}
        paymentInvoiceNumber={paymentInvoiceNumber}
        paymentAmount={paymentAmount}
        showAvatar={false}
      />
    );

    const contentForm = (
      <VendorCheckManuallyAddressPayeeForm
        onSubmit={onDone}
        isSaving={isSaving}
        defaultValues={addressValues}
        onSubmissionStateChange={onSubmissionStateChange}
        size="small"
      />
    );

    const content = (
      <ExternalLayoutContentWidget
        header={{
          backButtonProps: { isDisabled: cancelButtonProps?.isDisabled, onClick: onBack },
          title: contentHeader,
        }}
        form={{
          title: formatMessage(`${activityPrefix}.title`),
          fields: contentForm,
        }}
        continueSection={{
          buttonProps: {
            label: formatMessage(`${activityPrefix}.continue`),
            ...submitButtonProps,
          },
          legalInfoOverride,
        }}
      />
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddCheckDeliveryMethodByPayeeActivity.AddCheckAddressManuallyScreen"
        data-testid="add-check-delivery-method-by-payee-activity-add-check-address-manually-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

AddCheckAddressManuallyScreen.displayName = 'AddCheckDeliveryMethodByPayeeActivity.AddCheckAddressManuallyScreen';
