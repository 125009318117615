import { Card, ExternalLayout, Group, Text } from '@melio/penny';
import { BankAccountDeliveryMethod, PaperCheckDeliveryMethod, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import {
  DeliveryDateListItemWidget,
  DeliveryMethodListItemWidget,
  PayorPendingPaymentBanner,
  PoweredByMelioFooterWidget,
} from '@melio/vex-widgets';

export type ActionBlockedOfferExpiredScreenProps = {
  accountName: string;
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod;
  estimatedDelivery: Payment['estimatedDelivery'];
  maxEstimatedDelivery: Payment['maxEstimatedDelivery'];
  invoiceNumber?: string;
  paymentAmount: number;
};

export const ActionBlockedOfferExpiredScreen = forwardRef<ActionBlockedOfferExpiredScreenProps, 'div'>(
  (
    {
      accountName,
      deliveryMethod,
      estimatedDelivery,
      maxEstimatedDelivery,
      invoiceNumber,
      paymentAmount,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatCurrency } = useMelioIntl();

    const content = (
      <Card paddingX="xl" paddingY="l">
        <Group variant="vertical">
          <PayorPendingPaymentBanner
            variant="warning"
            description={formatMessage(
              `activities.pushToDebit.screens.actionBlockedOfferExpired.description.${
                invoiceNumber ? 'withInvoiceNumber' : 'withoutInvoiceNumber'
              }`,
              {
                accountName,
                amount: formatCurrency(paymentAmount),
                invoiceNumber,
              }
            )}
          />
          <Group variant="vertical" spacing="s">
            <Text textStyle="body4SemiUpper" color="global.neutral.900">
              {formatMessage(
                'activities.pushToDebit.screens.actionBlockedOfferExpired.vendorReceivePaymentSection.title'
              )}
            </Text>
            <Group variant="vertical" spacing="m" hasDivider>
              <DeliveryMethodListItemWidget deliveryMethod={deliveryMethod} />
              <DeliveryDateListItemWidget
                deliveryMethodType={deliveryMethod.type}
                estimatedDelivery={estimatedDelivery}
                maxEstimatedDelivery={maxEstimatedDelivery}
              />
            </Group>
          </Group>
        </Group>
      </Card>
    );

    const footer = <PoweredByMelioFooterWidget />;

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.ActionBlockedOfferExpiredScreen"
        data-testid="push-to-debit-activity-action-blocked-offer-expired-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

ActionBlockedOfferExpiredScreen.displayName = 'PushToDebitActivity.ActionBlockedOfferExpiredScreen';
