import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

import { getAllVexLocalStorageAuthKeys } from '../utils/authLocalStorage';
import { getStorageInstance } from '../utils/vexStorage';

// The time after which we delete the old auth tokens from local storage (30 days in milliseconds).
// We don't want to delete them immediately, because the user may have multiple tabs open.
const MAX_ALLOWED_TIME_FOR_EXPIRED_TOKEN = 30 * 24 * 60 * 60 * 1000;

export const useDeleteExpiredOldAuthTokens = () => {
  useEffect(() => {
    const vexAuthKeys = getAllVexLocalStorageAuthKeys();
    const store = getStorageInstance();
    vexAuthKeys.forEach((key) => {
      const token = store.getItem(key);
      if (token) {
        try {
          const { exp: tokenExpirationTimeInSeconds } = jwtDecode<{ exp: number }>(token);
          const tokenExpirationTimeInMillis = tokenExpirationTimeInSeconds * 1000;
          const latestAllowedExpirationTime = Date.now() - MAX_ALLOWED_TIME_FOR_EXPIRED_TOKEN;

          const isExpired = latestAllowedExpirationTime >= tokenExpirationTimeInMillis;

          if (isExpired) {
            store.removeItem(key);
          }
        } catch (e) {
          store.removeItem(key);
        }
      }
    });
  }, []);
};
