import { Button, Group, SelectionCard, Text } from '@melio/penny';
import { BankAccountDeliveryMethod, PaperCheckDeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { getDeliveryMethodIconKey, useGetDeliveryMethodDescription } from '@melio/vex-domain';

export type AcceptPaymentForExistsDeliveryMethodProps = {
  isSaving?: boolean;
  companyName: string;
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod;
  onDone: VoidFunction;
};

const messagesPath = 'vex.widgets.acceptPaymentForExistsDeliveryMethod';
export const AcceptPaymentForExistsDeliveryMethod = forwardRef<AcceptPaymentForExistsDeliveryMethodProps, 'div'>(
  ({ companyName, deliveryMethod, onDone, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group
        data-component="AcceptPaymentForExistsDeliveryMethod"
        data-testid="AcceptPaymentForExistsDeliveryMethod"
        variant="vertical"
        textAlign="center"
        spacing="l"
        ref={ref}
        {...props}
      >
        <Text as="h1" textStyle="heading3Semi">
          {formatMessage(`${messagesPath}.title`, { companyName })}
        </Text>
        <SelectionCard
          data-testid="delivery-method-list-item-by-payee"
          descriptionProps={{ label: formatMessage('app.free') }}
          icon={getDeliveryMethodIconKey(deliveryMethod.type)}
          labelProps={{ label: formatMessage('widgets.deliveryMethodListItemByPayor.bankAccount.label') }}
          mainLabelProps={{ label: useGetDeliveryMethodDescription(deliveryMethod) }}
        />
        <Group variant="vertical" spacing="s">
          <Button
            data-testid="layout-next-button"
            size="large"
            isFullWidth
            onClick={onDone}
            isLoading={isSaving}
            label={formatMessage(`${messagesPath}.button`)}
          />
        </Group>
      </Group>
    );
  }
);

AcceptPaymentForExistsDeliveryMethod.displayName = 'AcceptPaymentForExistsDeliveryMethod';
