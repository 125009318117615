import { useMelioIntl } from '@melio/platform-provider';
import { object, string } from 'yup';

export const useEmailValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    email: string()
      .email(formatMessage(`vex.widgets.login.email.input.validation`))
      .required(formatMessage(`vex.widgets.login.email.input.validation`)),
  });
};
