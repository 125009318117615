import { useMemo } from 'react';
import { getPartnerLocalStorage } from '@melio/local-storage';

import { getPartnerName } from '@/utils/partner.utils';

export const usePartnerLocalStorage = () => {
  const partnerName = getPartnerName();

  return useMemo(() => getPartnerLocalStorage({ partnerName }), [partnerName]);
};
