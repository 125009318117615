import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TryDifferentEmailIcon = (props: IconProps) => (
  <Icon width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 50C100 77.614 77.614 100 50 100C22.386 100 0 77.614 0 50C0 22.386 22.386 0 50 0C77.614 0 100 22.386 100 50Z"
      fill="#283643"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.6153 65.7985L55.5813 16.6477C54.0463 14.2156 52.0233 13 50.0003 13C47.9763 13 45.9543 14.2156 44.4193 16.6477L15.3854 65.7985C12.3144 70.6638 14.5234 75 20.2893 75H79.7103C85.4762 75 87.6853 70.6638 84.6153 65.7985Z"
      fill="#FFDC24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 60C48.343 60 47 61.343 47 63C47 64.656 48.343 66 50 66C51.657 66 53 64.656 53 63C53 61.343 51.657 60 50 60Z"
      fill="#273B49"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.8835 54.9958C50.7578 55.0566 51.5201 54.4459 51.5845 53.6343L52.9921 35.9905C53.0016 35.8611 53.0037 35.7131 52.9921 35.5788C52.8706 34.0458 51.4314 32.894 49.7789 33.0077C48.1264 33.1214 46.8868 34.4575 47.0082 35.9905L48.4168 53.6343C48.4728 54.3518 49.0884 54.9409 49.8835 54.9958Z"
      fill="#273B49"
    />
  </Icon>
);
