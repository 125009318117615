import { MtlMandatoryValidationFields, useRiskMtlSchemaValidations } from '@melio/ap-domain';
import { pickBy } from 'lodash';
import { object, SchemaOf } from 'yup';

import { CompleteRequiredDetailsFormField, CompleteRequiredDetailsFormFields, MissingFieldsMap } from './types';

type DynamicSchemaFields = {
  [K in keyof CompleteRequiredDetailsFormFields]?: SchemaOf<CompleteRequiredDetailsFormFields[K]>;
};

export const useCompleteRequiredDetailsValidationSchema = ({ missingFields }: { missingFields: MissingFieldsMap }) => {
  const mandatoryFields = Object.values(missingFields).reduce<MtlMandatoryValidationFields>(
    (prev, field) => ({
      ...prev,
      [field.name]: field.isRequired,
    }),
    {}
  );

  const {
    firstName,
    lastName,
    companyName,
    dateOfBirth,
    address,
    legalCompanyName,
    phoneNumber,
    legalAddress,
    industry,
    businessType,
    taxInfoType,
    taxInfoIdentifier,
    existingTaxIdIsEin,
    taxIdEinOverride,
    contactLastName,
    contactFirstName,
    email,
  } = useRiskMtlSchemaValidations(mandatoryFields);

  return object().shape(
    pickBy(
      {
        firstName: firstName(),
        lastName: lastName(),
        companyName: companyName(),
        dateOfBirth: dateOfBirth(),
        address: address(),
        legalCompanyName: legalCompanyName(),
        phoneNumber: phoneNumber(),
        legalAddress: legalAddress(),
        industry: industry(),
        businessType: businessType(),
        taxInfoType: taxInfoType(),
        taxInfoIdentifier: taxInfoIdentifier(),
        existingTaxIdIsEin: existingTaxIdIsEin(),
        taxIdEinOverride: taxIdEinOverride(),
        contactLastName: contactLastName(),
        contactFirstName: contactFirstName(),
        email: email(),
      } as unknown as DynamicSchemaFields,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (_: unknown, key: string) => missingFields[key as CompleteRequiredDetailsFormField]
    )
  ) as SchemaOf<CompleteRequiredDetailsFormFields>;
};
