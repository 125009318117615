import { Box, BoxProps, Container as ChakraContainer, Flex } from '@chakra-ui/react';
import { Group, Image, Text, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { PoweredByMelioVendorWidget } from '@melio/vex-widgets';
import { Outlet } from 'react-router-dom';

export type VendorRegistrationLayoutProps = {
  theme: {
    leftSideColor: BoxProps['backgroundColor'];
    textColor: BoxProps['color'];
  };
  logoUrl: string;
  integrationName: string;
};

export const VendorRegistrationLayout: React.FC<VendorRegistrationLayoutProps> = ({
  theme,
  integrationName,
  logoUrl,
}) => {
  const { formatMessage } = useMelioIntl();

  const isSmallScreen = useBreakpointValue({ m: true, l: false });

  const leftPanelHeader = (
    <Box width="full" backgroundColor={theme.leftSideColor} flexDirection="column">
      <Group spacing="m" variant="vertical">
        {!logoUrl ? (
          <Text textStyle="heading3Semi" shouldSupportEllipsis>
            {integrationName}
          </Text>
        ) : (
          <Image src={logoUrl} alt="partner-logo" height="64px" width="170px" objectFit="contain" />
        )}
        <Box color={theme.textColor}>
          <Text textStyle={isSmallScreen ? 'heading2Semi' : 'heading3Semi'} color="inherit">
            {formatMessage('vex.activities.vendorOnboarding.partnerPanel.title', { partnerName: integrationName })}
          </Text>
        </Box>
      </Group>
    </Box>
  );

  const leftPanelFooter = (
    <Box backgroundColor={theme.leftSideColor} width="full">
      <PoweredByMelioVendorWidget color={theme.textColor} />
    </Box>
  );

  return (
    <Flex direction={isSmallScreen ? 'column' : 'row'} minHeight="100vh" height={isSmallScreen ? undefined : '100vh'}>
      <Box maxWidth={isSmallScreen ? undefined : '440px'} minWidth={isSmallScreen ? undefined : '440px'}>
        <ChakraContainer
          backgroundColor={theme.leftSideColor}
          height="full"
          paddingY={isSmallScreen ? 'l' : 'xxl'}
          paddingX={isSmallScreen ? 'l' : 'xxl'}
        >
          <Group variant="vertical" spacing="l" justifyContent="space-between" height="full">
            {leftPanelHeader}
            {leftPanelFooter}
          </Group>
        </ChakraContainer>
      </Box>
      <ChakraContainer overflowY="auto" justifyContent="center" paddingX={isSmallScreen ? 's' : 'xxl'} flexGrow={1}>
        <Flex maxWidth="600px" direction="column" flexGrow={1}>
          <Outlet />
        </Flex>
      </ChakraContainer>
    </Flex>
  );
};

VendorRegistrationLayout.displayName = 'VendorRegistrationLayout';
