export const VendorAdd: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_132118)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72C126 101.823 101.823 126 72 126Z"
        fill="#98D5EC"
      />
      <mask
        id="mask0_11475_132118"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72C126 101.823 101.823 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_132118)">
        <path fillRule="evenodd" clipRule="evenodd" d="M42.0005 41.7598H100.91V107.64H42.0005V41.7598Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.4228 84.2582C58.4228 84.2582 59.7704 83.6178 64.9451 83.2443C65.592 82.0702 65.8615 80.2558 66.0233 78.7617C62.5734 76.2535 60.2016 71.5574 60.2016 66.2209C60.2016 58.1628 61.2258 52.5596 71.9526 52.5596C82.6794 52.5596 83.7036 58.2162 83.7036 66.2209C83.7036 71.6108 81.3318 76.3068 77.8281 78.815C77.936 80.3092 78.1515 82.1236 78.8522 83.2977C83.5958 83.6178 85.4825 84.2049 85.4825 84.2049C92.2743 86.0193 94.2687 93.0634 94.2687 97.8662C83.6497 97.9196 65.2685 97.9196 49.6364 97.9196C49.6364 93.1167 51.9543 86.6596 58.4228 84.2582Z"
          fill="#019DD3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.0005 89.2798H100.91V107.64H42.0005V89.2798Z"
          fill="#DCD7DA"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M57.2732 100.08H85.6369V102.24H57.2732V100.08Z" fill="#687680" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.8187 94.6797H80.1823V96.8397H63.8187V94.6797Z"
          fill="#687680"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.9095 47.1597H77.4551V34.1906C77.4551 34.1906 77.5227 28.8518 71.1788 28.8002C65.0406 28.7503 64.9095 34.0887 64.9095 34.0887V47.1597Z"
          fill="#D8D8D8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3648 37.111C72.0297 37.111 72.6222 36.8146 73.0053 36.3529C73.2874 36.0127 73.4558 35.5828 73.4558 35.1153C73.4558 34.0131 72.5196 33.1196 71.3648 33.1196C70.2099 33.1196 69.2737 34.0131 69.2737 35.1153C69.2737 36.2175 70.2099 37.111 71.3648 37.111Z"
          fill="#98D5EC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_132118">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
