import { Vendor } from '@melio/platform-api';

import { VendorFullyExpanded } from '@/api/vendors/vendors.api.types';
import { DeletedEventPayloadType } from '@/queries/event-emitter-query';
import { UpdateListOnIndividualActionType, updateListOnIndividualUpdate } from '@/utils/eventEmitter.utils';
import { queryClient } from '../reactQueryClient';
import { getVendorByIdQueryKey, getVendorsListQueryKey } from '../reactQueryKeys';

export type VendorCreatedHandler = ({ vendor }: { vendor: Vendor }) => void;
export type VendorUpdatedHandler = ({ vendor }: { vendor: VendorFullyExpanded }) => void;
export type VendorDeletedHandler = ({ deletedId }: DeletedEventPayloadType) => void;
export type VendorDeliveryMethodsUpdatedHandler = ({ vendorId }: { vendorId: string }) => void;

export const handleVendorCreated: VendorCreatedHandler = ({ vendor }) => {
  queryClient.setQueriesData<Vendor[] | undefined>(getVendorsListQueryKey(), (oldList) =>
    updateListOnIndividualUpdate({
      type: UpdateListOnIndividualActionType.UPDATE_OR_CREATE,
      updatedIndividual: vendor,
      oldList,
    }),
  );
  queryClient.setQueriesData<VendorFullyExpanded | undefined>(getVendorByIdQueryKey(vendor.id), () => {
    return {
      ...vendor,
      deliveryMethods: [],
      paymentsOverview: {
        paid: {
          count: 0,
          sum: 0,
        },
        scheduled: {
          count: 0,
          sum: 0,
        },
        unpaid: {
          count: 0,
          sum: 0,
        },
      },
    };
  });
};

export const handleVendorUpdated: VendorUpdatedHandler = ({ vendor }) => {
  queryClient.setQueriesData<Vendor[] | undefined>(getVendorsListQueryKey(), (oldList) =>
    updateListOnIndividualUpdate({
      type: UpdateListOnIndividualActionType.UPDATE_OR_CREATE,
      updatedIndividual: vendor,
      oldList,
    }),
  );
  queryClient.setQueriesData<VendorFullyExpanded | undefined>(getVendorByIdQueryKey(vendor.id), () => {
    return vendor;
  });
};

export const handleVendorDeleted: VendorDeletedHandler = ({ deletedId }) => {
  queryClient.setQueriesData<Vendor[] | undefined>(getVendorsListQueryKey(), (oldList) =>
    updateListOnIndividualUpdate({
      type: UpdateListOnIndividualActionType.DELETE,
      updatedIndividual: { id: deletedId },
      oldList,
    }),
  );
};

export const handleVendorDeliveryMethodsUpdated: VendorDeliveryMethodsUpdatedHandler = ({ vendorId }) => {
  queryClient.invalidateQueries(getVendorByIdQueryKey(vendorId));
};
