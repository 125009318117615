import { Card, ExternalLayout } from '@melio/penny';
import { DebitDeliveryMethod, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { PayorPendingPaymentBanner, PoweredByMelioFooterWidget } from '@melio/vex-widgets';

export type ActionBlockedAlreadyDebitScreenProps = {
  accountName: string;
  debitDeliveryMethod: DebitDeliveryMethod;
  paymentAmount: Payment['amount'];
};

export const ActionBlockedAlreadyDebitScreen = forwardRef<ActionBlockedAlreadyDebitScreenProps, 'div'>(
  ({ accountName, debitDeliveryMethod, paymentAmount, children, ...props }, ref) => {
    const { formatMessage, formatCurrency } = useMelioIntl();

    const content = (
      <Card paddingX="xl" paddingY="l">
        <PayorPendingPaymentBanner
          variant="success"
          title={formatMessage('activities.pushToDebit.screens.actionBlockedAlreadyDebit.title')}
          description={formatMessage('activities.pushToDebit.screens.actionBlockedAlreadyDebit.description', {
            accountName,
            amount: formatCurrency(paymentAmount),
            last4digits: debitDeliveryMethod.details.lastFourDigits,
          })}
        />
      </Card>
    );

    const footer = <PoweredByMelioFooterWidget />;

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.ActionBlockedAlreadyDebitScreen"
        data-testid="push-to-debit-activity-action-blocked-already-debit-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

ActionBlockedAlreadyDebitScreen.displayName = 'PushToDebitActivity.ActionBlockedAlreadyDebitScreen';
