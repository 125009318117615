import { Box } from '@chakra-ui/react';
import { Button, Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { useIsMobile } from '../../hooks/useIsMobile';

export type HeaderAuthSectionProps = {
  onLoginButtonClick?: () => void;
  loggedInUserName?: string;
};
export const HeaderAuthSection = forwardRef<HeaderAuthSectionProps, 'div'>(
  ({ loggedInUserName, onLoginButtonClick, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const isMobile = useIsMobile();
    const isLoggedIn = Boolean(loggedInUserName);

    const messageText = isLoggedIn
      ? formatMessage('vex.widgets.headerAuthSection.whenLoggedIn', { name: loggedInUserName })
      : formatMessage('vex.widgets.headerAuthSection.whenLoggedOut');

    return (
      <Box data-component="HeaderAuthSection" ref={ref} {...props}>
        <Group variant="horizontal" spacing="s" alignItems="center" justifyContent="flex-end">
          {!isMobile ? (
            <Text color="global.neutral.100" textStyle="body3" data-testid="header-auth-section-text">
              {messageText}
            </Text>
          ) : null}

          {isLoggedIn ? (
            <Box color="white">
              <Icon data-testid="user-icon" type="user" color="inherit" />
            </Box>
          ) : onLoginButtonClick ? (
            <Button
              onClick={onLoginButtonClick}
              size="small"
              variant="secondary-inverse"
              label={formatMessage('vex.widgets.headerAuthSection.loginButton.label')}
              data-testid="header-auth-section-login-button"
            />
          ) : null}
        </Group>
      </Box>
    );
  }
);

HeaderAuthSection.displayName = 'HeaderAuthSection';
