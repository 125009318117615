import { CardBrand } from '@melio/platform-provider';

import { AmexLogo, DinersLogo, DiscoverLogo, MastercardLogo, VisaLogo } from './assets';

export type TBTCardBrandLogoProps = {
  brand: CardBrand;
  isSelected: boolean;
};

const opacityValue = (isSelected: boolean) => (isSelected ? 1 : 0.35);

export const TBTCardBrandLogo = ({ brand, isSelected }: TBTCardBrandLogoProps) => {
  switch (brand) {
    case 'visa':
      return <VisaLogo opacity={opacityValue(isSelected)} />;
    case 'mastercard':
      return <MastercardLogo opacity={opacityValue(isSelected)} />;
    case 'american-express':
      return <AmexLogo opacity={opacityValue(isSelected)} />;
    case 'diners-club':
      return <DinersLogo opacity={opacityValue(isSelected)} />;
    case 'discover':
      return <DiscoverLogo opacity={opacityValue(isSelected)} />;
    default:
      return <></>;
  }
};
