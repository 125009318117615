import { atom, DefaultValue, selector, useSetRecoilState } from 'recoil';

import { ActiveFlowEnum, AppModelType, ScreensEnum, Toast } from './app.types';

export const appState = atom<AppModelType>({
  key: 'appState',
  default: {},
});

export const selectedPartner = atom<string | null>({
  key: 'selectedPartner',
  default: null,
});

export const appSelectedActiveScreenSelector = selector({
  key: 'appSelectedActiveScreenSelector',
  get: ({ get }) => {
    const app = get(appState);
    return app.activeScreen;
  },
});

export const appSelectedActiveFlowSelector = selector({
  key: 'appSelectedActiveFlowSelector',
  get: ({ get }) => {
    const app = get(appState);
    return app.activeFlow;
  },
});

export const appToastSelector = selector({
  key: 'appToastSelector',
  get: ({ get }) => {
    const app = get(appState);
    return app.toast;
  },
});

export const appPathPrefixSelector = selector<string | undefined>({
  key: 'appPathPrefixSelector',
  get: ({ get }) => {
    const app = get(appState);
    return app.pathPrefix;
  },
  set: ({ get, set }, pathPrefix) => {
    if (pathPrefix instanceof DefaultValue) {
      return;
    }
    set(appState, { ...get(appState), pathPrefix });
  },
});

export const appAccessTokenSelector = selector<string | undefined>({
  key: 'appAccessTokenSelector',
  get: ({ get }) => {
    const app = get(appState);
    return app.accessToken;
  },
  set: ({ get, set }, accessToken) => {
    if (accessToken instanceof DefaultValue) {
      return;
    }
    set(appState, { ...get(appState), accessToken });
  },
});

export const appAccountUrlSelector = selector<string | undefined>({
  key: 'appAccountUrlSelector',
  get: ({ get }) => {
    const app = get(appState);
    return app.accountUrl;
  },
  set: ({ get, set }, accountUrl) => {
    if (accountUrl instanceof DefaultValue) {
      return;
    }
    set(appState, { ...get(appState), accountUrl });
  },
});

export const useSetAppActiveScreen = () => {
  const setActiveScreen = useSetRecoilState(appState);
  return (screen?: ScreensEnum) =>
    setActiveScreen((state) => {
      return { ...state, activeScreen: screen };
    });
};

export const useSetAppActiveFLow = () => {
  const setActiveScreen = useSetRecoilState(appState);
  return (activeFLow?: ActiveFlowEnum) =>
    setActiveScreen((state) => {
      return { ...state, activeFlow: activeFLow };
    });
};
export const useSetAppToast = () => {
  const setToast = useSetRecoilState(appState);
  return (toast?: Toast) =>
    setToast((state) => {
      return { ...state, toast };
    });
};
