import { BannerProps } from './Banner';
import { ElementProperties, GeneralProperties, ParsedProperties } from './types';

export const splitMessage = (message: string, seperatator: string) => {
  const [description, subDescription] = message.split(seperatator);

  return {
    description: description || subDescription || message,
    subDescription: description ? subDescription : '',
  };
};

export const extractProperties = (extras: Record<string, string>): ParsedProperties => {
  const elementProperties: ElementProperties = new Map();
  const generalProperties: GeneralProperties = new Map();

  Object.entries(extras).forEach(([key, value]) => {
    if (key.includes('_')) {
      const [elementId, propertyName] = key.split('_', 2);
      if (!elementId || !propertyName) {
        return;
      }
      if (!elementProperties.has(elementId)) {
        elementProperties.set(elementId, new Map());
      }
      elementProperties.get(elementId)?.set(propertyName, value);
    } else {
      generalProperties.set(key, value);
    }
  });

  return { elementProperties, generalProperties };
};

export const getMode = (mode: string | undefined): BannerProps['mode'] => {
  if (mode === 'dark' || mode === 'light') {
    return mode;
  }
  return 'light';
};
