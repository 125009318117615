import { ExternalLayout, useToast } from '@melio/penny';
import { DeliveryMethodType, useVexGuestDeliveryMethod, VexPaymentResponse } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { ReactNode } from 'react';

import { ServerErrorScreen } from '../../shared/screens/server-error';
import { AcceptPaymentWithVirtualCardScreen } from './screens/AcceptPaymentWithVirtualCard/AcceptPaymentWithVirtualCard.screen';

export type AcceptPaymentWithVirtualCardActivityProps = {
  paymentId: string;
  contentHeaderTitle: ReactNode;
  onBack: VoidFunction;
  onDone: () => Promise<void>;
  isGuest: boolean;
  paymentData: VexPaymentResponse;
  paymentError: string | null;
  isLoading: boolean;
};

export const AcceptPaymentWithVirtualCardActivity: React.VFC<AcceptPaymentWithVirtualCardActivityProps> = ({
  onDone,
  contentHeaderTitle,
  onBack,
  paymentData,
  paymentError,
  isLoading: isLoadingPayment,
}) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const { mutateAsync: createVendorDeliveryMethod, isLoading: isCreatingDeliveryMethod } = useVexGuestDeliveryMethod();

  const onAcceptPayment = async () => {
    try {
      await createVendorDeliveryMethod({
        type: DeliveryMethodType.VirtualCard,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        details: { accountEmail: paymentData.vendor.contactEmail! },
      });
      await onDone();
    } catch (error) {
      toast({
        type: 'success',
        title: formatMessage('vex.activities.acceptPaymentWithVirtualCard.errors.toast.title'),
      });
    }
  };

  if (paymentError) {
    return <ServerErrorScreen />;
  }

  if (!paymentData || isLoadingPayment) {
    return <ExternalLayout isLoading />;
  }

  if (!paymentData.vendor.contactEmail) {
    return <ServerErrorScreen />;
  }

  return (
    <AcceptPaymentWithVirtualCardScreen
      onBack={onBack}
      onDone={onAcceptPayment}
      isSaving={isCreatingDeliveryMethod || isLoadingPayment}
      contentHeaderTitle={contentHeaderTitle}
      payorOrganizationName={paymentData.orgName}
      payorEmail={paymentData.creatorAccountEmail}
      vendorContactEmail={paymentData.vendor.contactEmail}
      vendorId={paymentData.vendor.id}
    />
  );
};

AcceptPaymentWithVirtualCardActivity.displayName = 'AcceptPaymentWithVirtualCardActivity';
