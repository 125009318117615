// TODO: this is temporary - should be moved into patterns along with PCIComplicance and maybe other footers
import { Box } from '@chakra-ui/react';
import { Group, Link, Text } from '@melio/penny';
import { FormattedMessage, TrustedPartners, useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { AmexGray } from './assets/AmexGray';
import { DinersGray } from './assets/DinersGray';
import { DiscoverGray } from './assets/DiscoverGray';
import { EvolveGray } from './assets/EvolveGray';
import { MasterGray } from './assets/MasterGray';
import { MelioSmallGray } from './assets/MelioSmallGray';
import { QuickbooksGray } from './assets/QuickbooksGray';
import { VisaGray } from './assets/VisaGray';

const mapTrustedPartnerToLogo: Record<TrustedPartners, React.ComponentType> = {
  Evolve: EvolveGray,
  Mastercard: MasterGray,
  Visa: VisaGray,
  Quickbooks: QuickbooksGray,
  Discover: DiscoverGray,
  Diners: DinersGray,
  Amex: AmexGray,
};

export const PoweredByMelioFooterWidget = forwardRef<unknown, 'div'>((props, ref) => {
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl, helpCenterUrl, trustedPartners } = useConfig().settings;
  const poweredByText = formatMessage('widgets.poweredByMelioFooter.firstLine');

  return (
    <Box data-component="PoweredByMelioFooter" {...props} ref={ref}>
      <Group variant="vertical" spacing="s" alignItems="center">
        <Group variant="vertical" spacing="m" alignItems="center">
          <Group alignItems="center">
            {poweredByText && (
              <Text textStyle="body4" color="global.neutral.800">
                {poweredByText}
              </Text>
            )}
            <MelioSmallGray />
          </Group>
          <Text textStyle="body4" color="global.neutral.800">
            {formatMessage('widgets.poweredByMelioFooter.secondLine')}
          </Text>
          <Group alignItems="center">
            {trustedPartners.map((item, index) => {
              const Comp = mapTrustedPartnerToLogo[item];
              return <Comp key={index} />;
            })}
          </Group>
          <Text textStyle="body4" color="global.neutral.800">
            <FormattedMessage
              id="widgets.poweredByMelioFooter.privacyAndTermsLine"
              values={{
                privacyPolicy: (
                  <Link
                    variant="inline"
                    href={privacyPolicyUrl}
                    label={formatMessage('widgets.poweredByMelioFooter.privacyPolicyText')}
                    newTab
                  />
                ),
                termsOfService: (
                  <Link
                    variant="inline"
                    href={termsOfServiceUrl}
                    label={formatMessage('widgets.poweredByMelioFooter.termsOfServiceText')}
                    newTab
                  />
                ),
              }}
            />
          </Text>
        </Group>
        <Text textStyle="body4" color="global.neutral.800">
          <FormattedMessage
            id="widgets.poweredByMelioFooter.lastLine"
            values={{
              helpCenter: (
                <Link
                  variant="inline"
                  href={helpCenterUrl}
                  label={formatMessage('widgets.poweredByMelioFooter.helpCenterText')}
                  newTab
                />
              ),
            }}
          />
        </Text>
      </Group>
    </Box>
  );
});

PoweredByMelioFooterWidget.displayName = 'PoweredByMelioFooter';
