import { PaymentUpgradeOfferOptionEnum, VendorPreferredPremiumMethodsEnum } from '@melio/platform-api';

export const ANALYTICS_CONTEXT = 'DeliveryMethod';

export const mapUpgradeOfferToAnalyticsOffer: Partial<Record<PaymentUpgradeOfferOptionEnum, string>> = {
  [PaymentUpgradeOfferOptionEnum.Fach]: 'fast-ach',
  [PaymentUpgradeOfferOptionEnum.Rtp]: 'rtp',
};

export const mapUpgradeOfferToAnalyticsPersistentFastOffer: Partial<Record<PaymentUpgradeOfferOptionEnum, string>> = {
  [PaymentUpgradeOfferOptionEnum.Fach]: 'persistent-fast-ach',
  [PaymentUpgradeOfferOptionEnum.Rtp]: 'persistent-rtp',
};

export const mapUpgradeOfferToVendorFastPreferenceValue: Partial<
  Record<PaymentUpgradeOfferOptionEnum, VendorPreferredPremiumMethodsEnum>
> = {
  [PaymentUpgradeOfferOptionEnum.Fach]: VendorPreferredPremiumMethodsEnum.FAST_ACH,
  [PaymentUpgradeOfferOptionEnum.Rtp]: VendorPreferredPremiumMethodsEnum.RTP,
};
