import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient } from '@melio/api-client';
import { AccountsApiClient, Expand, GetMeOrgData, OrganizationsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

import { ApiError } from '../../core';
import { OrganizationExpanded, OrganizationFullyExpanded, OrganizationWithLogoUrl } from './types';

export const useOrganizationsMe = () =>
  useQuery<GetMeOrgData[], ApiError>({
    queryKey: ['AccountsApi', 'getAccountsMeOrgs'],
    queryFn: async () => {
      const { data } = await AccountsApiClient.getAccountsMeOrgs();
      return data.data;
    },
  });

type QueryFn = typeof OrganizationsApiClient.getOrganizations;
type CreateFn = typeof OrganizationsApiClient.postOrganizations;

export type UseOrganizationsProps<
  T extends OrganizationExpanded = OrganizationExpanded,
  TExpand extends Expand = never
> = UseCollectionApiProps<QueryFn, T> & Optional<TExpand, { params: { expand: TExpand } }>;

const useExpandedOrganizations = <
  T extends OrganizationExpanded = OrganizationExpanded,
  TExpand extends Expand = never
>({
  params,
  ...props
}: UseOrganizationsProps<T, TExpand>) => {
  const queryClient = useMelioQueryClient();

  return useCollectionApi<QueryFn, CreateFn, never, never, T>({
    ...props,
    queryKey: ['OrganizationsApi', params],
    queryFn: () => OrganizationsApiClient.getOrganizations(params?.expand ?? 'none'),
    createFn: OrganizationsApiClient.postOrganizations,
    onCreate: () => queryClient.invalidateQueries('OrganizationsApi', 'collection'),
  });
};

export const useOrganizations = (props: UseOrganizationsProps = {}) => useExpandedOrganizations(props);

export const useOrganizationsWithLogoUrl = (props: UseOrganizationsProps<OrganizationWithLogoUrl> = {}) =>
  useExpandedOrganizations<OrganizationWithLogoUrl, 'logoUrl'>({ ...props, params: { expand: 'logoUrl' } });

export const useOrganizationsFullyExpanded = (props: UseOrganizationsProps<OrganizationFullyExpanded> = {}) =>
  useExpandedOrganizations<OrganizationFullyExpanded, ['billsAndPaymentsOverview', 'logoUrl']>({
    ...props,
    params: { expand: ['billsAndPaymentsOverview', 'logoUrl'] },
  });

export type {
  GetMeOrgData as MeOrganization,
  Organization,
  PostOrganizationsRequest,
  PostOrganizationsResponse,
} from '@melio/platform-api-axios-client';
export { CompanyType } from '@melio/platform-api-axios-client';
