import { RadioOption, SelectOption } from '@melio/penny';
import { SelectNewOption } from '@melio/penny/dist/types/components/selectionAndInputs/SelectNew/SelectNew.types';
import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';

import { useMtlMessages } from './useMtlMessages';

const allBusinessTypes = [
  OrganizationBusinessType.SoleProprietorship,
  OrganizationBusinessType.Partnership,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.NonProfit,
  OrganizationBusinessType.NonGovernmentalOrganization,
  OrganizationBusinessType.MunicipalCorporation,
  OrganizationBusinessType.TrustOrEstate,
];

const einOnlyBusinessTypes: OrganizationBusinessType[] = [
  OrganizationBusinessType.Partnership,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.NonProfit,
  OrganizationBusinessType.NonGovernmentalOrganization,
  OrganizationBusinessType.MunicipalCorporation,
];

const allTaxIdTypes = [TaxIdTypeEnum.Ein, TaxIdTypeEnum.Ssn, TaxIdTypeEnum.Itin];

export const masks = {
  dateOfBirth: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], // i.e 12/30/1995 (MM/DD/YYYY)
  taxId: {
    [TaxIdTypeEnum.Ein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], // i.e 12-3456789,
    [TaxIdTypeEnum.Ssn]: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 123-45-6789,
    [TaxIdTypeEnum.Itin]: [/9/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 923-45-6781,
    any: [/./, /./, /./, /./, /./, /./, /./, /./, /./, /./], // i.e 123456789 | XXXXXX123,
  },
};

export const isEinOnlyBusinessType = (selectedBusinessType: OrganizationBusinessType | null) =>
  selectedBusinessType ? einOnlyBusinessTypes.includes(selectedBusinessType) : false;

export const useMtlFormValues = () => {
  const {
    labels: {
      company: { businessTypeOption, taxIdTypeOption, einOnlyHelperText },
    },
  } = useMtlMessages();

  const businessTypeOptions: SelectOption[] = allBusinessTypes.map((value) => ({
    label: businessTypeOption(value),
    value,
    testId: value,
  }));

  const businessTypeOptionsSelectNew = allBusinessTypes.map<SelectNewOption<string>>((value) => ({
    label: businessTypeOption(value),
    value,
    testId: value,
  }));

  const taxIdTypeOptions = (selectedBusinessType: OrganizationBusinessType | null) =>
    allTaxIdTypes.map<RadioOption>((value) => ({
      mainLabelProps: { label: taxIdTypeOption(value) },
      value,
      ...(selectedBusinessType && {
        disabled: {
          isDisabled: value !== TaxIdTypeEnum.Ein && isEinOnlyBusinessType(selectedBusinessType),
          message: einOnlyHelperText(selectedBusinessType),
        },
      }),
    }));

  return {
    businessTypeOptions,
    businessTypeOptionsSelectNew,
    taxIdTypeOptions,
  };
};
