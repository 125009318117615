import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { AuthApiClient } from '@melio/platform-api-axios-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getHealthData = () => AuthApiClient.getHealthData();
export type UseHealthProps = UseModelApiProps<typeof getHealthData>;

export const useHealth = (props: UseHealthProps = {}) =>
  useModelApi({
    ...props,
    id: 'fake_id',
    queryKey: 'AuthApi',
    queryFn: () => AuthApiClient.getHealthData(),
  });
