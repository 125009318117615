import { useEffect, useState } from 'react';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';

export function useDevice() {
  const [devicePixelRatio, setDevicePixelRatio] = useState<number>(window.devicePixelRatio);

  useEffect(() => {
    const resizeHandler = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return {
    devicePixelRatio,
    isDesktop,
    isMobile,
    isTablet,
  };
}
