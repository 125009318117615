export const PayZero: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_132061)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#98D5EC"
      />
      <mask
        id="mask0_11475_132061"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_132061)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.08 38.5195L50.4 41.7595L54.72 38.5195L59.04 41.7595L63.36 38.5195L67.68 41.7595L72 38.5195L76.32 41.7595L80.64 38.5195L84.96 41.7595L89.28 38.5195L93.6 41.7595L97.92 38.5195L102.24 41.7595L106.56 38.5195V126H46.08V38.5195Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.44 38.5195L41.76 41.7595L46.08 38.5195L50.4 41.7595L54.72 38.5195L59.04 41.7595L63.36 38.5195L67.68 41.7595L72 38.5195L76.32 41.7595L80.64 38.5195L84.96 41.7595L89.28 38.5195L93.6 41.7595L97.92 38.5195V126H37.44V38.5195Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.44 53.6395H52.56C51.912 53.6395 51.48 53.2075 51.48 52.5595C51.48 51.9115 51.912 51.4795 52.56 51.4795H91.44C92.088 51.4795 92.52 51.9115 92.52 52.5595C92.52 53.2075 92.088 53.6395 91.44 53.6395Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.96 68.7596H47.16C46.512 68.7596 46.08 68.3276 46.08 67.6796C46.08 67.0316 46.512 66.5996 47.16 66.5996H57.96C58.608 66.5996 59.04 67.0316 59.04 67.6796C59.04 68.3276 58.608 68.7596 57.96 68.7596Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.52 75.2391H47.16C46.512 75.2391 46.08 74.8071 46.08 74.1591C46.08 73.5111 46.512 73.0791 47.16 73.0791H65.52C66.168 73.0791 66.6 73.5111 66.6 74.1591C66.6 74.8071 66.168 75.2391 65.52 75.2391Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.96 81.7196H47.16C46.512 81.7196 46.08 81.2876 46.08 80.6396C46.08 79.9916 46.512 79.5596 47.16 79.5596H57.96C58.608 79.5596 59.04 79.9916 59.04 80.6396C59.04 81.2876 58.608 81.7196 57.96 81.7196Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.2 88.1991H47.16C46.512 88.1991 46.08 87.7671 46.08 87.1191C46.08 86.4711 46.512 86.0391 47.16 86.0391H61.2C61.848 86.0391 62.28 86.4711 62.28 87.1191C62.28 87.7671 61.848 88.1991 61.2 88.1991Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 68.7596H88.2C87.552 68.7596 87.12 68.3276 87.12 67.6796C87.12 67.0316 87.552 66.5996 88.2 66.5996H99C99.648 66.5996 100.08 67.0316 100.08 67.6796C100.08 68.3276 99.648 68.7596 99 68.7596Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 75.2391H91.44C90.792 75.2391 90.36 74.8071 90.36 74.1591C90.36 73.5111 90.792 73.0791 91.44 73.0791H99C99.648 73.0791 100.08 73.5111 100.08 74.1591C100.08 74.8071 99.648 75.2391 99 75.2391Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 88.1991H89.28C88.632 88.1991 88.2 87.7671 88.2 87.1191C88.2 86.4711 88.632 86.0391 89.28 86.0391H99C99.648 86.0391 100.08 86.4711 100.08 87.1191C100.08 87.7671 99.648 88.1991 99 88.1991Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.88 57.9598H60.12C59.472 57.9598 59.04 57.5278 59.04 56.8798C59.04 56.2318 59.472 55.7998 60.12 55.7998H83.88C84.528 55.7998 84.96 56.2318 84.96 56.8798C84.96 57.5278 84.528 57.9598 83.88 57.9598Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 81.7196H84.96C84.312 81.7196 83.88 81.2876 83.88 80.6396C83.88 79.9916 84.312 79.5596 84.96 79.5596H99C99.648 79.5596 100.08 79.9916 100.08 80.6396C100.08 81.2876 99.648 81.7196 99 81.7196Z"
          fill="#CDCCD0"
        />
        <path
          d="M47.8999 99.5862C48.4409 99.5608 48.8143 99.3631 49.02 98.9929C49.1276 98.8031 49.1813 98.5832 49.1813 98.3332C49.1813 97.9377 49.0358 97.6371 48.7447 97.4315C48.577 97.3112 48.2954 97.1973 47.8999 97.0897V99.5862ZM46.2909 95.0061C46.2909 95.3194 46.3938 95.5646 46.5995 95.7418C46.8083 95.919 47.0883 96.0424 47.4395 96.112V93.8813C47.025 93.8972 46.7292 94.0238 46.552 94.261C46.378 94.4983 46.2909 94.7467 46.2909 95.0061ZM45.4367 95.0632C45.4367 94.5822 45.6123 94.1487 45.9635 93.7627C46.3178 93.3767 46.813 93.1805 47.449 93.1742V92.543H47.8999V93.1647C48.5296 93.209 49.012 93.3956 49.3474 93.7247C49.686 94.0506 49.8631 94.4825 49.879 95.0204H49.0484C49.0263 94.78 48.9615 94.5727 48.8539 94.3986C48.6545 94.0791 48.3366 93.9114 47.8999 93.8956V96.2022C48.6339 96.4079 49.1323 96.6009 49.3949 96.7812C49.822 97.0786 50.0356 97.5184 50.0356 98.1007C50.0356 98.9423 49.762 99.5403 49.2145 99.8946C48.9108 100.091 48.4725 100.221 47.8999 100.284V101.205H47.449V100.284C46.5282 100.224 45.9033 99.8978 45.5743 99.3061C45.3939 98.9866 45.3037 98.5547 45.3037 98.0105H46.1438C46.1691 98.444 46.2372 98.7603 46.3479 98.9597C46.5441 99.3172 46.9079 99.5182 47.4395 99.5624V96.9758C46.7561 96.8461 46.2514 96.6294 45.9255 96.3256C45.5996 96.0219 45.4367 95.601 45.4367 95.0632Z"
          fill="#0BA44A"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_132061">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
