/* eslint-disable max-lines */
import { CSSObject } from '@emotion/styled';
import { BrandSymbolKey, IconKey, LayoutProps } from '@melio/penny';
import {
  Card,
  DeliveryMethodByPayor,
  DeliveryMethodType,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
} from '@melio/platform-api';
import { SpaceApi } from '@usersnap/browser';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const accountIntegrationTypes = ['deposits', 'plaid'] as const;

export type AccountIntegrationType = (typeof accountIntegrationTypes)[number];

const cardBrands = ['visa', 'mastercard', 'american-express', 'diners-club', 'discover', 'unknown'] as const;
export type CardBrand = (typeof cardBrands)[number];

type ManagedAccount = 'managed-account';
type ManagedAccountDeliveryTypes = ManagedAccount | `${ManagedAccount}:check` | `${ManagedAccount}:ach`;

type DeliveryMethodMemoBlacklist = DeliveryMethodType | ManagedAccountDeliveryTypes;

export type EOYCheck = {
  enabled: boolean;
  promoteFastCheck: boolean;
  link: string;
};

export const TrustedPartners = {
  Evolve: 'Evolve',
  Mastercard: 'Mastercard',
  Quickbooks: 'Quickbooks',
  Diners: 'Diners',
  Visa: 'Visa',
  Discover: 'Discover',
  Amex: 'Amex',
} as const;

export type TrustedPartners = (typeof TrustedPartners)[keyof typeof TrustedPartners];

export enum PaymentSchedulingPreference {
  ByScheduleDate = 'byScheduleDate',
  ByDeliveryDate = 'byDeliveryDate',
}

export enum ContextualOnboardingButtons {
  QuickBooksOnline = 'QuickBooksOnline',
  GmailSync = 'GmailSync',
  FirstPayment = 'FirstPayment',
}

export type UnilateralRequest = {
  announceFees: boolean;
};
export type FileType = 'jpg' | 'png' | 'pdf';

export type FundingSourceGroupName = FundingSource['type'] | Card['type'];
export type FundingSourceCardPolicy = {
  fundingSourceCard?: {
    showCreatedBy?: boolean;
  };
};
export type FundingSourcePolicy = {
  [key in FundingSource['type'] | Card['type']]?: {
    order?: number;
    fundingSourceGroup?: FundingSourceGroupName;
    read?: boolean;
    write?: boolean;
  };
};

export enum VendorDirectory {
  Fiserv = 'Fiserv',
}

type EventDestination = 'Braze';

export type LinkConstants = {
  'app.settings.SupportSection.accordion.businessBills.links.link1.href': string;
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href': string;
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href': string;
  'app.settings.SupportSection.accordion.addDebitCard.links.link1.href': string;
  'app.settings.SupportSection.accordion.verifyMicroDeposits.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBankAccount.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBill.links.link1.href': string;
  'app.settings.SupportSection.accordion.payBill.links.link1.href': string;
  'app.settings.SupportSection.accordion.deliveryTimelines.links.link1.href': string;
  'app.settings.SupportSection.accordion.vendors.links.link1.href': string;
  'app.settings.SupportSection.accordion.cancelPayment.links.link1.href': string;
  'app.settings.SupportSection.accordion.security.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBillClone.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBillClone.links.link2.href': string;
  'app.settings.SupportSection.accordion.addBillClone.links.link3.href': string;
  'widgets.footer.termsOfServiceLink.href': string;
  'widgets.footer.privacyPolicy.href': string;
  'widgets.learnAboutRates.href': string;
  'widgets.companySettings.header.sectionBanner.linkHref': string;
  'activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.creditKey': string;
  'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.vendor-ineligible.learnMore.href': string;
  'activities.financingApplication.screens.applicationForm.title.learnMore': string;
  'creditKey.legal.privacyPolicy': string;
  'creditKey.legal.termsOfService': string;
  'fx.legal.termsAndConditions': string;
  helpCenter: string;
  submitSupportRequest: string;
  backButtonUrl: string;
  backButtonUrlFallback: string;
  portalLink: string;
  failedToCollectSupport: string;
  financingMoreDetails: string;
  unilateralDeliveryLearnMore: string;
  undepositedCheckLearnMore: string;
  contactCreditKey: string;
  resolveFailedPayment: string;
  contactMelioSupport: string;
  quickBooksDesktopWebConnectorGuide: string;
  verifyBankAccountWithMicroDeposit: string;
};

export type DeliveryMethodTypeOptionDisabledReasonsMap = {
  [key in SupportedDeliveryMethodTypeOption['type']]: SupportedDeliveryMethodTypeOption['reason'][];
};

export enum VendorDetailsFormType {
  Modal = 'Modal',
  Drawer = 'Drawer',
}

export enum BillsEmailInboxDashboardView {
  Link = 'link',
  IconAndLink = 'icon-and-link',
}

export type SubscriptionsDataConfig = {
  expectOrgsToBeSubscribed?: boolean;
  isGracePeriodSupported?: boolean;
  claimSuggestedPlan?: boolean;
  links: {
    seeAllFeatures?: string;
    learnMore?: {
      smb?: string;
      accountingClients?: string;
      accountingFirms?: string;
    };
  };
};

export type PaymentRequestUrlDecodedPayload = {
  vendorDetails?: {
    name?: string;
  };
  paymentRequestDetails?: {
    ref?: string;
  };
};

export type MelioConfig = {
  tabTitle: string;
  server: {
    apiServer: string;
  };
  settings: {
    payment: {
      isConfirmationNumberEnabled: boolean;
      scheduling: {
        scheduleBy: PaymentSchedulingPreference;
        showEarlierApprovalDate: boolean;
        maxDeliveryDateInMonths?: number;
        showFuturePaymentBanner: boolean;
        disableEditDeliveryDateForCards: boolean;
        recurringDeliveryMethodBlackList: DeliveryMethodType[];
        minAmountUsd?: number;
        maxAmountUsd?: number;
      };
      editing: {
        defaultToManagedAccountPreferredDeliveryMethod: boolean;
        shouldCheckFundingSourceEntitlements: boolean;
        disableEditDeliveryMethod: boolean;
      };
      scheduled: {
        showZeroFees: boolean;
        showEstimatedDeliveryDateBanner: boolean;
      };
      showCreatedByIndication: boolean;
      memoToVendor: {
        maxLength: number;
        deliveryMethodBlackList: DeliveryMethodMemoBlacklist[];
        allowSendingEmailToVendorWhenAddingMemo: boolean;
      };
      allowNotifyingVendor: boolean;
    };
    batchPayments: {
      showDeliverySpeedColumn: boolean;
      showVendorVerifiedDeliveryMethodTooltip: boolean;
      usePreDefinedlDeliveryMethodList: boolean;
      backgroundColor: LayoutProps['backgroundColor'];
      showDebitSameDateIndication: boolean;
      showZeroFees: boolean;
      useMinimalHeaderDesign: boolean;
      showLateIndicator: boolean;
      headerVariant?: 'light' | 'dark';
      showFeesTooltipTitle?: boolean;
      showRelativeDates?: boolean;
      closeButtonOnLeft?: boolean;
      showVendorDetails?: boolean;
    };
    fundingSourcePolicy?: FundingSourcePolicy & FundingSourceCardPolicy;
    uploadBill?: {
      shouldShowMultipleBillUploadIndication: boolean;
    };
    entitledFundingSourceCard?: boolean;
    icons: {
      fundingSource: {
        bank: IconKey | BrandSymbolKey;
      };
      deliveryMethod: {
        virtualCard: BrandSymbolKey;
      };
    };
    billsEmailInboxDashboardView: BillsEmailInboxDashboardView;
    supportRequestTimeoutEnable: boolean;
    assets: { [key: string]: string };
    collectMissingCompanyLegalInfoFields: boolean;
    partnerDisplayName: string;
    partnerProductName: string;
    expiredSessionTime: number;
    bankIntegrations?: AccountIntegrationType[];
    supportEmail: string;
    termsOfServiceUrl: string;
    learnMoreAccountVerificationURL: string;
    mccLearnMoreUrl: string;
    privacyPolicyUrl: string;
    goodsReceivedAmountThreshold: number;
    requireInvoiceForBillsAmountThreshold: number;
    helpCenterUrl: string;
    howContactToSupportArticleUrl: string;
    registerToMelioGetPaid: string;
    fileSizeLimit: number;
    fileAllowedFormats: FileType[];
    tokenProvider: 'tabapay' | 'tbt';
    eoyCheck: EOYCheck;
    trustedPartners: TrustedPartners[];
    unilateralRequest: UnilateralRequest;
    payDashboardBillsTabTourEnabled: boolean;
    payDashboardSearchLabelDisabled: boolean;
    newPayExperience: {
      isEnabled: boolean;
      firstTimeExperience: {
        assetType: 'image' | 'video';
        imageUrl: string;
        mobileImageUrl: string;
        videoLink: string;
      };
    };
    accountingFirmDashboard: {
      emptyStateAsset: string;
    };
    newBillExperience: {
      isRecurringEnabled: boolean;
      isRecurringPaymentImprovementsEnabled: boolean;
      shouldShowIconInBillsTabCta: boolean;
      invoiceNumberFormatRegexp?: RegExp;
    };
    isEmbeddedExperience: boolean;
    isPaymentApprovalLimitEnabled: boolean;
    showApproveActionButton: boolean;
    isDeclinePaymentReasonEnabled: boolean;
    collaborator: {
      isAddCollaboratorEnabled: boolean;
      isChangeCollaboratorPermissionLevelEnabled: boolean;
      showRoleTransferDisclaimer: boolean;
    };
    isEntitlementsEnabled: boolean;
    isLineItemsEnabled: boolean;
    isRequestCallbackEnabled?: boolean;
    paymentLateApproval: { debitDatePassedIndicationEnabled: boolean; debitDateTodayIndicationEnabled: boolean };
    isMultiColoredAvatarsEnabled: boolean;
    isUnilateralDeliveryMethodSupported: boolean;
    isPaymentTimelineEnabled: boolean;
    virtualCardLearnMoreLink: string;
    contractorLearnMoreLink: string;
    tax1099LearnMoreLink: string;
    showDemoRequestIcon: boolean;
    contextualOnboarding: {
      buttons: ContextualOnboardingButtons[];
    };
    vendor: {
      collectedDetails: 'extended' | 'minimal';
      requiredDirectoryFields: string[];
      vendorSelect: {
        createVendor: {
          showVendorDetailsForm: boolean;
          vendorDetailsFormType: VendorDetailsFormType;
        };
      };
      createVendor: {
        shouldSwitchToExtendedFormOnApiErrors: boolean;
        hasSkipDeliveryMethodButton: boolean;
        canAddVendorWithTheSameName: boolean;
        companyNameFormatRegexp?: RegExp;
        companyNameMaxLength?: number;
        companyNameMinLength?: number;
        shouldShowBankDetailsFields: boolean;
        shouldShowAddressDetailsBanner: boolean;
        shouldCollectAccountNumberForUnmanagedVendor?: boolean;
      };
      forms: {
        shouldUseZipCodeMask: boolean;
        shouldUseTooltipsForManagedVendorForm: boolean;
        shouldUseSearchVendorSectionLabel: boolean;
      };
      warnEditingVendorWithFuturePayments: boolean;
    };
    virtualCardExpiryPeriodInDays: number;
    deliveryMethodTypeOrder: Record<DeliveryMethodByPayor['type'], number>;
    deliveryMethodTypeOptionDisabledReasons: DeliveryMethodTypeOptionDisabledReasonsMap;
    syncProgressIndicationEnabled: boolean;
    vex: {
      zdSupportFormUrl: string;
      vendorsContactEmail: string;
      persistentFastOptOutArticleUrl: string;
      unilateral: {
        isEmailLoginEnabled: boolean;
        shouldSuggestJoinMelio: boolean;
        isNewUnilateral: boolean;
        suvcInformationLink: string;
      };
    };
    showCheckMarkIconForManagedVendor: boolean;
    singlePaymentStepLayout: {
      backgroundColor: LayoutProps['backgroundColor'];
    };
    subscriptions?: SubscriptionsDataConfig;
    subscriptionsFirstWeek: {
      flagName?: 'platform-subscriptions-accountants-first-week';
      enabled?: boolean;
    };
    InternationalFx: {
      flagName?: 'international-fx';
      enabled?: boolean;
    };
    MarkAsPaid: {
      flagName?: 'mark-as-paid';
      enabled?: boolean;
    };
    InvalidVendorBankAccountModal: {
      flagName?: 'platform-invalid-vendor-bank-account-modal';
      enabled?: boolean;
    };
    File1099SettingsTaxAndReports: {
      flagName?: 'platform-feature-1099-tax-and-reports-settings';
      enabled: boolean;
    };
    accountingSoftware: {
      isConnectConfirmationEnabled: boolean;
    };
    guestPaymentFlow?: {
      generateDeepLinkToPaymentRequestPayDashboard: (
        paymentRequestId: string,
        payload: PaymentRequestUrlDecodedPayload
      ) => string;
    };
    unmaskVendorAccountNumber?: boolean;
  };
  services: {
    forethoughtApiKey: string;
    zendeskKey: string;
    zendeskWorkflow: string;
    googleClientId: string;
    smartystreets: {
      apiKey: string;
    };
    googleMaps: {
      apiKey: string;
    };
    plaid: {
      env: string;
      apiKey: string;
    };
    tabapay: {
      card: TabapayIframe;
      credit?: TabapayIframe;
      debit?: TabapayIframe;
    };
    tbt: {
      apiKey: string;
      supportedCardProviders: { [K in CardBrand]: boolean };
      fontsUrl: string;
    };
    userSnap: {
      spaceId: string;
      projectId: string;
    };
  };
  production: boolean;
  featureFlagProvider: {
    shouldLoad: boolean;
    clientId: string;
  };
  analytics: {
    shouldTrackEvents: boolean;
    shouldPrintEvents: boolean;
    blockedEventDestinations: EventDestination[];
    key: string;
  };
  dataDog: {
    shouldInit: boolean;
    appId: string;
    clientId: string;
    site: string;
    service: string;
  };
  tutorialVideo: string;
  zendesk: {
    useNewWidgetApi: boolean;
    headerColor: string;
    resultListsColor: string;
    themeColor: string;
    title: string;
    logo: string;
  };
  links: LinkConstants;
  sizzersApi?: {
    baseUrl: string;
    meliomeUrl: string;
  };
};

type TabapayIframe = {
  url: string;
  iframeWidth?: CSSObject['width'];
  iframeHeight?: CSSObject['height'];
};

export type InitOptions = {
  custom?: Record<string, unknown>;
  user?: {
    email?: string;
    userId?: string;
  };
  locale?: string;
  useSystemFonts?: boolean;
  useLocalStorage?: boolean;
};

export type FeedbackProviderProps = {
  feedbackWidgetProps?: {
    userEmail?: string;
    userId?: string;
    userCreatedAt?: string;
    organizationId?: string;
    isFeedbackWidgetOn?: boolean;
  };
  children?: React.ReactNode;
};

export type PartialMelioConfig = RecursivePartial<MelioConfig>;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    onUserSnapLoad?: (api: SpaceApi) => Promise<void>;
    cy?: {
      Cypress: {
        testingType: string;
      };
    };
  }
}

export type DynamicSettingsType = {
  shouldShowContentKeys?: boolean;
};

export type DynamicSettingsProps = {
  dynamicSettings?: DynamicSettingsType;
};

type SettingsType = MelioConfig['settings'];
type SettingsValuesTypes = SettingsType[keyof SettingsType];
type SettingsWithFlagName = Extract<SettingsValuesTypes, { flagName?: string }>;

// Union of all 'flagName' properties within MelioConfig settings
export type SettingsWithFlagNameKeys = SettingsValuesTypes;
export type PartnerFeatureFlags = SettingsWithFlagName extends { flagName?: infer FlagName } ? FlagName : never;

export type SettingsKeysWithFlagName = {
  [K in keyof SettingsType]-?: SettingsType[K] extends { flagName?: unknown } ? K : never;
}[keyof SettingsType];

export type SettingsKeysWithEnabledProp = {
  [K in keyof SettingsType]-?: SettingsType[K] extends { enabled?: boolean } ? K : never;
}[keyof SettingsType];
