import { TrackerTimelineStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type LinkData = { messageKey: FormattedMessageType; href: string };
export type FormattedMessageType = Parameters<ReturnType<typeof useMelioIntl>['formatMessage']>[0];
type StepItem = {
  title: { messageKey: FormattedMessageType };
  description: {
    messageKey: FormattedMessageType;
    date?: Date;
    link?: LinkData;
    payorEmail?: string;
  };
};
export type FailureStepItem = [StepItem, StepItem];
export type SuccessStepItem = [StepItem, StepItem, StepItem];

export type PaymentTrackerSteps = FailureStepItem | SuccessStepItem;
export type TrackerTimelineStatusEnumWithoutFailed = (typeof TrackerTimelineStatusEnum)[Exclude<
  keyof typeof TrackerTimelineStatusEnum,
  'FAILED'
>];

export type TrackerStepData = {
  title: { messageKey: FormattedMessageType; restValues?: Record<string, string> };
  description?: {
    messageKey: FormattedMessageType;
    date?: Date;
    link?: { href: string; messageKey: FormattedMessageType };
    payorEmail?: string;
  };
};

export enum VendorType {
  GetPaid = 'GetPaid',
  Erp = 'Erp',
  Guest = 'Guest',
}
