import { EmptyVendorsIcon } from '@/cl/icons/melio/emptyVendors.icon';
import { ExpiredSessionIcon } from '@/cl/icons/melio/ExpiredSession.icon';
import Favicon from '@/images/defaultFavicon.svg';
import { PartnerIcons } from '@/partnersConfig.types';
import { Tax1099Icon } from '../default/taxSummary.icon';
import { MelioIcon } from '../system';
import { EmptyIcon } from './empty.icon';
import { MelioSmallIcon } from './melioSmall.icon';

export const MelioIcons: PartnerIcons = {
  Favicon,
  MelioIcon,
  LogoIcon: MelioIcon,
  SmallLogoIcon: MelioSmallIcon,
  ErrorIcon: EmptyIcon,
  EmptyBillsIcon: EmptyIcon,
  EmptyVendorsIcon: EmptyVendorsIcon,
  NoBillsFoundIcon: EmptyIcon,
  NoVendorsFoundIcon: EmptyIcon,
  VerifyEmailIcon: EmptyIcon,
  TaxSummaryIcon: Tax1099Icon,
  ExpiredSessionIcon: ExpiredSessionIcon,
  BackToPartnerIcon: EmptyIcon,
  AddBillIcon: EmptyIcon,
  StartNewExperienceIcon: EmptyIcon,
  TryDifferentEmailIcon: EmptyIcon,
};
