import { Card, Container, ExternalLayout, Group, useFormSubmissionController, useToast } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { ApiError, MeOrganization } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import {
  CompanySelection,
  DeliveryMethodSelectionByPayeeHeaderWidget,
  FooterWidget,
  PoweredByMelioWidget,
  SelectOrganizationModel,
} from '@melio/vex-widgets';

export type CompanySelectionScreenProps = {
  accountName: string;
  paymentAmount: number;
  isSaving?: boolean;
  onDone: (id: SelectOrganizationModel) => void;
  error?: ApiError;
  showPoweredByMelio: boolean;
  organizations: MeOrganization[];
};
export const CompanySelectionScreen = forwardRef<CompanySelectionScreenProps, 'div'>(
  (
    { accountName, paymentAmount, isSaving, onDone, showPoweredByMelio, organizations, error, children, ...props },
    ref
  ) => {
    useAnalyticsView('CompanySelectionScreen');
    const { onSubmissionStateChange } = useFormSubmissionController<SelectOrganizationModel>();
    const { toast } = useToast();

    if (error) {
      toast({ type: 'error', title: error.message });
    }

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            <DeliveryMethodSelectionByPayeeHeaderWidget
              accountName={accountName}
              paymentAmount={paymentAmount}
              showAvatar
            />
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical">
              <CompanySelection
                isSaving={isSaving}
                organizations={organizations}
                onSubmit={onDone}
                onSubmissionStateChange={onSubmissionStateChange}
              />
            </Group>
          </Container>
        </Group>
      </Card>
    );
    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="CompanySelectionScreen"
        data-testid="company-selection-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

CompanySelectionScreen.displayName = 'CompanySelectionScreen';
