import { ServerConfig } from '@melio/platform-api-axios-client';
import { Logger } from '@melio/platform-logger';
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';

const MINUTE = 1000 * 60;

// Create a client
const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 5 * MINUTE,
        staleTime: 60 * MINUTE,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

setLogger({
  log: (message) => {
    Logger.log(message as never, 'log');
  },
  warn: (message) => {
    Logger.log(message as never, 'warn');
  },
  error: (error) => {
    Logger.handleException(error);
  },
});

type ApiProviderProps = {
  accessToken?: string;
  getRequestHeaders?: () => Promise<Record<string, string>>;
  onTokenExpired?: () => Promise<unknown>;
  basePath?: string;
  queryClient?: QueryClient;
};

export const ApiProvider: React.FC<ApiProviderProps> = ({
  basePath,
  accessToken,
  getRequestHeaders,
  onTokenExpired,
  children,
  queryClient = getQueryClient(),
}) => {
  useEffect(() => {
    ServerConfig.update({ basePath, accessToken, getRequestHeaders, onTokenExpired });
  }, [basePath, accessToken, getRequestHeaders, onTokenExpired]);
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
