import { useToast } from '@melio/penny';
import {
  useVendorPreferences as useVendorPreferencesApi,
  useVendorPreferredPremiumMethod,
  VendorPersistentFastEligibilityTypeEnum,
  VendorPreferredPremiumMethodsEnum,
  VendorPremiumMethodsStateEnum,
} from '@melio/platform-api';

export const usePersistentFastPreference = ({ vendorId }: { vendorId: string | undefined }) => {
  const { toast } = useToast();

  const {
    isLoading,
    isFetching,
    data: vendorPreferences,
    refetch: refetchVendorPreferences,
  } = useVendorPreferencesApi({ vendorId, options: { enabled: !!vendorId } });

  const { mutateAsync: createVendorPreferredPremiumMethod, isLoading: isMutating } = useVendorPreferredPremiumMethod();

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
  };

  const isPersistentFastPreferenceCreated = !!vendorPreferences?.preferredPremiumMethod.value;
  const isVendorOptedOutOfPersistentFast =
    vendorPreferences?.preferredPremiumMethod.state === VendorPremiumMethodsStateEnum.OPT_OUT;

  const isEligibleToPersistentFastOffer =
    !!vendorPreferences?.persistentFastEligibility &&
    vendorPreferences.persistentFastEligibility !== VendorPersistentFastEligibilityTypeEnum.NOT_ELIGIBLE;
  const persistentFastEligibility = vendorPreferences?.persistentFastEligibility;

  const handleSavePersistentFastPreference = async (value: VendorPreferredPremiumMethodsEnum) => {
    try {
      await createVendorPreferredPremiumMethod({ vendorId: vendorId as string, value });
      await refetchVendorPreferences();
    } catch (error) {
      handleFail(error as PlatformError);
    }
  };

  return {
    isPersistentFastPreferenceCreated,
    isPersistentFastPreferenceLoading: isLoading,
    isPersistentFastPreferenceUpdating: isMutating || isFetching,
    isPersistentFastOfferEnabled:
      !isVendorOptedOutOfPersistentFast && isEligibleToPersistentFastOffer && !isPersistentFastPreferenceCreated,
    persistentFastEligibility,
    handleSavePersistentFastPreference,
  };
};
