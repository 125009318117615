import { PartialMelioConfig } from '@melio/platform-provider';

import { extendDefaultConfig, PartnerConfigByEnv } from '@/configByPartner/partnerConfig.utils';
import { melioLinks } from '@/links';
import { generateDeepLinkToPaymentRequestPayDashboard } from '@/utils/generateMelioRedirectPath';

const defaultMelioConfig: PartialMelioConfig = {
  tabTitle: 'Melio',
  settings: {
    supportEmail: 'support@melio.com',
    isLineItemsEnabled: true,
    isRequestCallbackEnabled: true,
    fundingSourcePolicy: {
      'flex-account': {
        order: 0,
        fundingSourceGroup: 'flex-account',
        read: true,
        write: true,
      },
      'bank-account': {
        order: 1,
        fundingSourceGroup: 'bank-account',
        read: true,
        write: true,
      },
      credit: {
        order: 2,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
      debit: {
        order: 3,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
    },
    vex: {
      unilateral: {
        isEmailLoginEnabled: true,
        shouldSuggestJoinMelio: true,
        isNewUnilateral: true,
        suvcInformationLink: 'https://meliopayments.com/guides/single-use-virtual-cards/',
      },
    },
    subscriptions: {
      isGracePeriodSupported: true,
      claimSuggestedPlan: true,
      links: {
        seeAllFeatures: 'https://go.meliopayments.com/pricing',
        learnMore: {
          smb: 'https://go.meliopayments.com/learnmore/smb',
          accountingClients: 'https://go.meliopayments.com/learnmore/clients',
          accountingFirms: 'https://go.meliopayments.com/learnmore/accountants',
        },
      },
    },
    subscriptionsFirstWeek: {
      flagName: 'platform-subscriptions-accountants-first-week',
      enabled: true,
    },
    guestPaymentFlow: {
      generateDeepLinkToPaymentRequestPayDashboard,
    },
    InternationalFx: {
      enabled: true,
      flagName: 'international-fx',
    },
    MarkAsPaid: {
      enabled: true,
    },
  },
  services: {
    forethoughtApiKey: '',
  },
  zendesk: {
    useNewWidgetApi: true,
  },
  links: melioLinks,
};

const prodMelioConfig: PartialMelioConfig = {
  services: {
    zendeskKey: '51fdfad6-7341-4cb2-8eb8-88ccece82bbc',
  },
};

const melioPartialConfig: PartnerConfigByEnv = {
  default: defaultMelioConfig,
  dev: {},
  prod: prodMelioConfig,
};

export const melioConfig = extendDefaultConfig(melioPartialConfig);
