import { Container, Group, Link, Tracker, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  DeliveryMethodType,
  PaymentDeliveryPreferenceEnum,
  PaymentUpgradeOfferOption,
  TrackerTimelineStatusEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePageView } from '@melio/vex-domain';
import { GuestLayout, PartnerFooter } from '@melio/vex-widgets';
import React from 'react';

import { getUpsellOfferDataByPriority } from '../../payment-upgrade/utils';
import { DeliveryNachaNumber, PaymentTrackingHeader } from '../components';
import { UpsellOfferBanner } from '../components/upsell-offer-banner/UpsellOfferBanner';
import { TrackerStepData } from '../types';
import { getTrackerStepIndex } from './utils';

export const PaymentTrackingScreen = ({
  trackerStatus,
  deliveryEta,
  amount,
  payorOrganizationName,
  deliveryNachaNumber,
  deliveryPreference,
  trackerSteps,
  onClickUpsellOfferButton,
  paymentUpgradeOffers,
  isUpsellOfferBannerEnabled,
  deliveryMethodType,
}: {
  trackerStatus: TrackerTimelineStatusEnum;
  deliveryEta: Date;
  amount: number;
  payorOrganizationName: string;
  deliveryNachaNumber: string | null;
  deliveryPreference?: PaymentDeliveryPreferenceEnum | null;
  trackerSteps: TrackerStepData[];
  onClickUpsellOfferButton: () => void;
  paymentUpgradeOffers: PaymentUpgradeOfferOption[];
  isUpsellOfferBannerEnabled: boolean;
  deliveryMethodType: DeliveryMethodType;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const borderProps = { borderRadius: 'l', border: 'basic.light' };
  const { trackerStepIndex } = getTrackerStepIndex(trackerStatus);
  const { track } = useAnalytics();
  usePageView('Payment');

  const handleOnClickLink = () => {
    track('Payment', 'Click', { Cta: 'contact-support' });
  };
  const upsellOfferData = getUpsellOfferDataByPriority(paymentUpgradeOffers);
  const handleOnClickUpsellOfferButton = () => {
    track('Payment', 'Click', { Cta: upsellOfferData?.isInstantlyUpsellOffer ? 'get-paid-now' : 'get-paid-today' });
    onClickUpsellOfferButton();
  };

  const isActive = (index: number) => index === trackerStepIndex;

  const isCompleted = (index: number) =>
    trackerStatus === TrackerTimelineStatusEnum.DEPOSITED ? true : index < trackerStepIndex;

  return (
    <GuestLayout data-testid="vendor-payment-tracking-page" footer={<PartnerFooter />}>
      <Group variant="vertical" spacing="l">
        <PaymentTrackingHeader
          trackerStatus={trackerStatus}
          deliveryEta={deliveryEta}
          amount={amount}
          payorOrganizationName={payorOrganizationName}
          deliveryMethodType={deliveryMethodType}
          deliveryPreference={deliveryPreference}
        />
        <Group {...borderProps} spacing="none" variant="vertical" hasDivider={!!deliveryNachaNumber}>
          <Container paddingX="m" paddingY="m">
            <Tracker variant="vertical">
              {trackerSteps.map((step, index) => (
                <Tracker.Step
                  key={`tracker-step-${index}`}
                  data-testid={`tracker-step-${index}`}
                  isActive={isActive(index)}
                  isCompleted={isCompleted(index)}
                >
                  <Tracker.StepTitle
                    label={formatMessage(step.title.messageKey, step.title.restValues)}
                    data-testid={`step-title-${index}`}
                  />
                  {step.description && (
                    <Typography.Description
                      data-testid={`step-description-${index}`}
                      label={formatMessage(step.description.messageKey, {
                        formattedDate: formatDate(step.description.date, {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        }),
                        link: step.description.link && (
                          <Link
                            data-testid={`step-description-${index}-link`}
                            color="secondary"
                            href={step.description.link.href}
                            label={formatMessage(step.description.link.messageKey)}
                            onClick={handleOnClickLink}
                            newTab
                          />
                        ),
                        payorEmail: step.description.payorEmail,
                      })}
                    />
                  )}
                </Tracker.Step>
              ))}
            </Tracker>
          </Container>
          {deliveryNachaNumber && (
            <Container paddingX="m" paddingY="m" alignItems="center">
              <DeliveryNachaNumber deliveryNachaNumber={deliveryNachaNumber} deliveryPreference={deliveryPreference} />
            </Container>
          )}
        </Group>
        {isUpsellOfferBannerEnabled && paymentUpgradeOffers.length > 0 && upsellOfferData && (
          <UpsellOfferBanner
            fee={upsellOfferData.fee}
            onClick={handleOnClickUpsellOfferButton}
            isPaymentEligibleToBeInstantly={upsellOfferData.isInstantlyUpsellOffer}
            paymentAmount={amount}
          />
        )}
      </Group>
    </GuestLayout>
  );
};

PaymentTrackingScreen.displayName = 'PaymentTrackingScreen';
