import { ExternalLayout } from '@melio/penny';
import { PaymentUpgradeOfferOption, PaymentUpgradeOfferOptionEnum, VexGuestPaymentResponse } from '@melio/platform-api';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { getIsPaymentCompleted, getIsPaymentFailed, getUpgradeOfferByType, getUpgradeTypeOfPayment } from '../../utils';

type PaymentUpgradeIndexScreenProps = {
  payment?: VexGuestPaymentResponse;
  paymentUpgradeOffers: Array<PaymentUpgradeOfferOption>;
  isLoading: boolean;
};

export const PaymentUpgradeIndexScreen: FC<PaymentUpgradeIndexScreenProps> = ({
  payment,
  paymentUpgradeOffers,
  isLoading,
}) => {
  const isPaymentFailed = getIsPaymentFailed(payment);
  const isPaymentCompleted = getIsPaymentCompleted(payment);
  const isPaymentAlreadyExpeditedBefore = Boolean(getUpgradeTypeOfPayment(payment));

  const rtpUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Rtp);
  const fachUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Fach);
  const p2dUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Debit);

  if (isLoading) {
    return <ExternalLayout isLoading />;
  }

  if (isPaymentCompleted) {
    return <Navigate to="../payment-delivered" replace />;
  }

  if (payment && isPaymentAlreadyExpeditedBefore && !isPaymentCompleted && !isPaymentFailed) {
    return <Navigate to="../success" replace />;
  }

  if (rtpUpgradeOffer || fachUpgradeOffer) {
    return <Navigate to="../confirm" replace />;
  }

  if (p2dUpgradeOffer) {
    return <Navigate to="../card-details" replace />;
  }

  return null;
};
