import { Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  orgName: string;
};

export const PaymentTitleRow = ({ orgName }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Text textStyle="body3Semi">
      {formatMessage('activities.paymentUpgrade.screens.paymentUpgradeConfirm.header.title', { orgName })}
    </Text>
  );
};

PaymentTitleRow.displayName = 'PaymentTitleRow';
