import { EventProperties } from '@melio/platform-analytics';
import { DeliveryMethodType } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { SUVCInformationModal, SUVCInformationModalProps } from '@melio/vex-widgets';

type ScreenProps = {
  track: (properties: EventProperties) => void;
} & Omit<SUVCInformationModalProps, 'learnMoreLink'>;

export const SUVCInformationModalScreen = ({
  track,
  onClose: onModalClose,
  isOpen,
  onConfirm: onModalConfirm,
}: ScreenProps) => {
  const {
    settings: {
      vex: {
        unilateral: { suvcInformationLink },
      },
    },
  } = useConfig();

  const onClose = () => {
    track({
      Intent: 'delivery-method-info',
      DeliveryMethodType: DeliveryMethodType.VirtualCard,
      Cta: 'close',
    });
    onModalClose();
  };

  const onBack = () => {
    track({
      Intent: 'delivery-method-info',
      Cta: 'go-back',
      DeliveryMethodType: DeliveryMethodType.VirtualCard,
    });
    onModalClose();
  };

  const onConfirm = () => {
    track({
      Intent: 'delivery-method-info',
      Cta: 'confirm-and-receive-card',
      DeliveryMethodType: DeliveryMethodType.VirtualCard,
    });
    onModalConfirm?.();
  };

  return (
    <SUVCInformationModal
      data-testid="virtual-card-information-modal"
      onConfirm={onConfirm}
      onBack={onBack}
      onClose={onClose}
      learnMoreLink={suvcInformationLink}
      isOpen={isOpen}
    />
  );
};
