import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiKeys, GetVexPaymentResponseData, VexPaymentsApiClient } from '@melio/platform-api-axios-client';

export type VexPaymentResponse = Omit<GetVexPaymentResponseData, 'orgName' | 'note'> & {
  orgName: string;
  note: string;
};

export const useVexPayment = (
  paymentId: string,
  options?: UseModelApiProps<typeof VexPaymentsApiClient.getVexPayment, VexPaymentResponse>
) =>
  useModelApi({
    ...options,
    id: paymentId,
    queryKey: [ApiKeys.VexPaymentsApi, 'getVexPayment', paymentId],
    queryFn: VexPaymentsApiClient.getVexPayment,
    select: (data) => ({
      ...data,
      orgName: data.orgName || '',
      note: data.note || '',
    }),
  });
