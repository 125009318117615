import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

type Props = {
  children?: ReactNode;
};

export const AbsoluteCenter = ({ children }: Props) => {
  return (
    <Box position={'absolute'} top={'50%'} left={'50%'} transform="translate(-50%, -50%)" zIndex={'overlay'}>
      {children}
    </Box>
  );
};
