import { AvatarImageControl, Button, Group, SectionBanner, Text } from '@melio/penny';
import { FileInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { LogoUploadBaseProps } from './types';

export type LogoUploadWidgetProps = LogoUploadBaseProps & {
  setLogo: (file: File) => Promise<FileInfo>;
  removeLogo: () => Promise<void>;
  isLoading?: boolean;
  onSkip: () => void;
};

export const LogoUploadWidget = forwardRef<LogoUploadWidgetProps>(
  ({ onSubmit, setLogo, removeLogo, onSkip, isSaving, defaultValues, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { logoUrl } = defaultValues || {};
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | undefined>(logoUrl);
    useEffect(() => {
      setSelectedFileUrl(logoUrl);
    }, [logoUrl]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { fileSizeLimit } = useConfig().settings;

    const handleLogoUpload = async (file: File | null) => {
      setErrorMessage(null);
      if (!file) {
        setSelectedFileUrl(undefined);
        await removeLogo();
        return;
      }

      if (file.size > fileSizeLimit) {
        setErrorMessage(formatMessage('vex.widgets.vendorOnboarding.logoUpload.validation.fileSize'));
        return;
      }
      try {
        // Optimistically set the value until upload completes
        setSelectedFileUrl(URL.createObjectURL(file));
        await setLogo(file);
      } catch (e) {
        // Restore previous value
        setSelectedFileUrl(logoUrl);
        setErrorMessage(formatMessage('vex.widgets.vendorOnboarding.logoUpload.validation.unknownError'));
      }
    };

    return (
      <Group
        variant="vertical"
        spacing="l"
        ref={ref}
        data-testid="LogoUploadWidget"
        data-component="LogoUploadWidget"
        {...props}
      >
        {errorMessage && (
          <SectionBanner variant="critical" description={errorMessage} data-testid="logo-upload-error-message" />
        )}
        <Group variant="vertical" spacing="xxs">
          <Text textStyle="body4" color="global.neutral.900">
            {formatMessage('vex.widgets.vendorOnboarding.logoUpload.requirements')}
          </Text>
        </Group>

        <Group justifyContent="flex-start">
          <AvatarImageControl
            variant="square"
            viewModePlaceholder=""
            deleteButtonText={formatMessage('vex.widgets.vendorOnboarding.logoUpload.deleteLogo')}
            isLoading={isSaving}
            value={selectedFileUrl}
            /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
            onChange={handleLogoUpload}
            acceptTypes={['png', 'jpg', 'pdf']}
            ref={ref}
          />
        </Group>

        <Group justifyContent="flex-end">
          {!logoUrl && (
            <Button
              size="medium"
              variant="tertiary"
              isDisabled={isSaving}
              onClick={onSkip}
              label={formatMessage('vex.widgets.vendorOnboarding.logoUpload.skip')}
              data-testid="logo-upload-skip-button"
            />
          )}
          <Button
            size="medium"
            variant="primary"
            isDisabled={isSaving}
            onClick={onSubmit}
            label={formatMessage('vex.widgets.vendorOnboarding.logoUpload.submit')}
            data-testid="logo-upload-continue-button"
          />
        </Group>
      </Group>
    );
  }
);
LogoUploadWidget.displayName = 'LogoUploadWidget';
