import { useState } from 'react';

import { AddCheckDeliveryMethodByPayeeActivityProps, AddCheckDeliveryMethodByPayeeStep } from './types';

export const useAddCheckDeliveryMethodByPayeeStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: AddCheckDeliveryMethodByPayeeActivityProps['onBack'];
}) => {
  const [currentStep, goToStep] = useState<AddCheckDeliveryMethodByPayeeStep>('ADD_CHECK_DETAILS');

  const goToPreviousStep = () => {
    if (currentStep === 'CHECK_DETAILS_ADDED_SUCCESSFULLY' || currentStep === 'ADD_CHECK_ADDRESS_MANUALLY') {
      goToStep('ADD_CHECK_DETAILS');
    } else if (currentStep === 'ADDRESS_SUGGESTIONS' || currentStep === 'INVALID_ADDRESS_CONFIRMATION') {
      goToStep('ADD_CHECK_ADDRESS_MANUALLY');
    } else {
      onFirstStepBack();
    }
  };
  const goToManuallyAddressFormStep = () => goToStep('ADD_CHECK_ADDRESS_MANUALLY');
  const goToAddCheckDetails = () => goToStep('ADD_CHECK_DETAILS');

  return { currentStep, goToStep, goToPreviousStep, goToManuallyAddressFormStep, goToAddCheckDetails };
};
