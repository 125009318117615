import { Card, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

import {
  components,
  ExternalLayoutContentWidgetContinueSectionProps,
  ExternalLayoutContentWidgetFormProps,
} from './components';

export type ExternalLayoutContentWidgetProps = {
  header: { backButtonProps?: { isDisabled?: boolean; onClick: VoidFunction }; title: ReactNode };
  form: ExternalLayoutContentWidgetFormProps;
  continueSection?: ExternalLayoutContentWidgetContinueSectionProps;
  children?: never;
};

const Content = forwardRef<ExternalLayoutContentWidgetProps, 'div'>(
  ({ header, form, continueSection, ...props }, ref) => (
    <Card
      data-component="ExternalLayoutContentWidget"
      {...props}
      paddingX="none"
      paddingY="none"
      width="full"
      ref={ref}
    >
      <Group variant="vertical" spacing="none" width="full">
        <ExternalLayoutContentWidget.Header {...header} />
        <Group variant="vertical" spacing="none">
          <ExternalLayoutContentWidget.Form {...form} />
          <ExternalLayoutContentWidget.ContinueSection {...continueSection} />
        </Group>
      </Group>
    </Card>
  )
);

Content.displayName = 'ExternalLayoutContentWidget';

export const ExternalLayoutContentWidget = Object.assign(Content, components);
