import { isArray, isDate, isObject, isString } from 'lodash';

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

function replaceDate(src: string) {
  // eslint-disable-next-line no-restricted-syntax
  return DATE_PATTERN.test(src) ? new Date(src) : src;
}

export function convertDates<T>(entity: T): T {
  if (isDate(entity)) {
    return entity;
  } else if (isString(entity)) {
    return replaceDate(entity) as unknown as T;
  } else if (isArray(entity)) {
    return entity.map(convertDates) as unknown as T;
  } else if (isObject(entity)) {
    return Object.entries(entity).reduce(
      (target, [key, value]) => ({
        ...target,
        [key]: convertDates(value as T),
      }),
      {} as T
    );
  } else {
    return entity;
  }
}
