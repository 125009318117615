import { VendorOnboardingCardsEnum, vendorOnboardingCardsOrder } from '../../shared';
import { LocalizationKey } from '../../types';

const vendorOnboardingCardsToReview = [
  VendorOnboardingCardsEnum.EmailVerification,
  VendorOnboardingCardsEnum.PersonalDetails,
  VendorOnboardingCardsEnum.BusinessDetails,
  VendorOnboardingCardsEnum.BankDetails,
];

export const orderedOnboardingCardsToReview = vendorOnboardingCardsOrder.filter((card) =>
  vendorOnboardingCardsToReview.includes(card)
);

export const sectionTitlesKeysMap: Partial<Record<VendorOnboardingCardsEnum, LocalizationKey>> = {
  [VendorOnboardingCardsEnum.EmailVerification]: 'vex.screen.reviewDetailsCard.emailDetailsSection.title',
  [VendorOnboardingCardsEnum.PersonalDetails]: 'vex.screen.reviewDetailsCard.personalDetailsSection.title',
  [VendorOnboardingCardsEnum.BusinessDetails]: 'vex.screen.reviewDetailsCard.businessDetailsSection.title',
  [VendorOnboardingCardsEnum.BankDetails]: 'vex.screen.reviewDetailsCard.bankAccountDetailsSection.title',
};

export enum ReviewDetailsErrorTypes {
  BUSINESS_DETAILS_ERROR = 'BUSINESS_DETAILS_ERROR',
  BANK_DETAILS_ERROR = 'BANK_DETAILS_ERROR',
}
