import { DeliveryMethodType, PaymentUpgradeOfferOptionEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type UsePaymentAlreadyDeliveredLabels = {
  amount: number;
  orgName: string;
  offerType?: PaymentUpgradeOfferOptionEnum | null;
  deliveryMethodType?: DeliveryMethodType | null;
  formattedDeliveryMethodDetails: string;
  deliveryEta: Date;
};

export const usePaymentAlreadyDeliveredLabels = ({
  offerType,
  deliveryMethodType,
  amount,
  orgName,
  formattedDeliveryMethodDetails,
  deliveryEta,
}: UsePaymentAlreadyDeliveredLabels) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const deliveryDate = formatDate(deliveryEta, { dateStyle: 'long' });

  if (deliveryMethodType === DeliveryMethodType.PaperCheck) {
    return {
      title: formatMessage('vex.activities.paymentUpgrade.screens.paymentAlreadyDelivered.paperCheck.title'),
      description: formatMessage(
        'vex.activities.paymentUpgrade.screens.paymentAlreadyDelivered.paperCheck.description',
        {
          amount,
          orgName,
          deliveryDate,
        }
      ),
    };
  }
  return {
    title: formatMessage('vex.activities.paymentUpgrade.screens.paymentAlreadyDelivered.title'),
    description: formatMessage('vex.activities.paymentUpgrade.screens.paymentAlreadyDelivered.description', {
      offerType,
      last4digits: formattedDeliveryMethodDetails,
      deliveryDate,
    }),
  };
};
