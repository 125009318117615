export const updateFaviconAndTitleForPartner = ({
  tabTitle,
  faviconHref,
}: {
  tabTitle: string;
  faviconHref: string;
}) => {
  document.title = tabTitle;

  const faviconEl = document.getElementById('favicon') as HTMLLinkElement | null;
  if (faviconEl) {
    faviconEl.href = faviconHref;
  }
};
