import { Card, ExternalLayout, Group, StatusIconSolid, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { FooterWidget } from '@melio/vex-widgets';

type AlreadyAcceptedScreenProps = { paymentAmount: number; payorOrganizationName: string };

export const AlreadyAcceptedScreen = forwardRef<AlreadyAcceptedScreenProps, 'div'>(
  ({ paymentAmount, payorOrganizationName }, ref) => {
    const { formatMessage, formatCurrency } = useMelioIntl();

    const content = (
      <Card paddingX="xl" paddingY="l">
        <Group variant="vertical" spacing="s">
          <StatusIconSolid variant="success" size="large" />
          <Text as="h1" textStyle="body2Semi">
            {formatMessage('vex.screen.unilateralAndMsnEnrollment.AlreadyAccepted.title')}
          </Text>
          <Text>
            {formatMessage('vex.screen.unilateralAndMsnEnrollment.AlreadyAccepted.description', {
              paymentAmount: formatCurrency(paymentAmount),
              payorOrganizationName,
            })}
          </Text>
        </Group>
      </Card>
    );

    return (
      <ExternalLayout
        data-component="PaymentAlreadyAcceptedScreen"
        data-testid="payment-already-accepted-screen"
        footer={<FooterWidget />}
        ref={ref}
        content={content}
      />
    );
  }
);

AlreadyAcceptedScreen.displayName = 'AlreadyAcceptedScreen';
