import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const BankSuccess: React.VFC = () => (
  <LazyAnimation
    id="bank-success"
    getAnimationData={() => import('./assets/bank-success.lottie')}
    width="100%"
    height="100%"
  />
);
