// XXX move to shared
import { useTbt } from '@melio/form-controls';
import { ExternalLayout } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CardParams, useFeeCatalog, useFundingSources, usePayment, usePaymentFees } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';
import { useState } from 'react';

import {
  ActionBlockedPaymentCanceledScreen,
  CardAddedSuccessfullyScreen,
  CardHolderDetailsScreen,
  CardValidationModalScreen,
  TBTCreateCardDetailsScreen,
} from './screens';
import { IneligibleScreen } from './shared/IneligibleScreen';
import { usePushToDebitActivityCallbacks } from './shared/usePushToDebitActivityCallbacks';
import { getPushToDebitFixedFee, getPushToDebitPercentFee } from './shared/utils';
import { PushToDebitActivityProps, TBTFormWidgetFields } from './types';
import { usePushToDebitStep } from './usePushToDebitStep';

// TODO: https://linear.app/meliopayments/issue/PLA-1224 add invoice number by sending payment intent id or by using "expand=billInfo" when backend ready
const BaseTBTPushToDebitActivity: React.VFC<PushToDebitActivityProps> = ({
  paymentId,
  accountName,
  onError,
  onDone,
}) => {
  const {
    data: payment,
    error: paymentError,
    update: updatePayment,
    isMutating: isUpdatingPayment,
  } = usePayment({ id: paymentId });

  const { data: paymentFees } = usePaymentFees({
    paymentId,
    params: { deliveryMethodType: 'card' },
  });

  const { data: feeCatalogEntries } = useFeeCatalog();

  const { verifyCard } = useFundingSources({ enabled: false });
  const [isVerifying, verifying] = useBoolean(false);

  const { bt, tokenize } = useTbt();

  const { currentStep, goToStep, goToPreviousStep } = usePushToDebitStep();

  const [cardDetails, setCardDetails] = useState<CardParams>();

  const onCreateVendorDeliveryMethodDone = (id: string) => {
    onDone(id);
    goToStep('CARD_ADDED_SUCCESSFULLY');
  };

  const {
    showVerificationErrorDialog,
    createDeliveryMethod,
    isCardValidationModalOpen,
    onCardValidationModalDone,
    isCreatingDeliveryMethod,
  } = usePushToDebitActivityCallbacks({
    vaulting: 'TBT',
    onError,
    cardDetails,
    payment,
    updatePayment,
    onCreateVendorDeliveryMethodDone,
  });

  const { createTrackHandler } = useAnalytics();

  const trackCreateCardDetailsSubmit = createTrackHandler<{ Status?: 'succeeded' | 'failed' }>(
    'PushToDebitAddCardDetails',
    'Submitted'
  );

  if (paymentError?.code === '404') {
    return <ActionBlockedPaymentCanceledScreen accountName={accountName} />;
  }

  if (!payment) {
    return <ExternalLayout isLoading />;
  }

  if (!payment.paymentActions?.pushToDebit?.eligible) {
    return (
      <IneligibleScreen
        payment={payment}
        accountName={accountName}
        existingPaymentDeliveryMethod={payment.deliveryMethod}
      />
    );
  }

  if (!paymentFees || !feeCatalogEntries) {
    return <ExternalLayout isLoading />;
  }

  const onCreateCardDetailsDone = async ({ cardNumber, cardExpiration, cardVerificationCode }: TBTFormWidgetFields) => {
    if (bt) {
      try {
        verifying.on();
        const cardParams = await tokenize({ cardNumber, cardExpiration, cardVerificationCode });
        verifyCard({
          cardBin: cardParams.cardBin,
          tabapayToken: cardParams.tabapayToken,
          tokenProvider: 'basistheory',
        })
          .then(() => {
            trackCreateCardDetailsSubmit({ Status: 'succeeded' });
            setCardDetails(cardParams);
            goToStep('ADD_CARD_HOLDER_DETAILS');
          })
          .catch(showVerificationErrorDialog)
          .finally(verifying.off);
      } catch (error) {
        verifying.off();
        trackCreateCardDetailsSubmit({ Status: 'failed' });
        onError?.(error as PlatformError);
        return;
      }
    }
  };

  switch (currentStep) {
    case 'ADD_CARD_DETAILS':
    default:
      return (
        <>
          <TBTCreateCardDetailsScreen
            accountName={accountName}
            paymentAmount={payment.amount}
            calculatedFee={getPushToDebitFixedFee(paymentFees)}
            feePercentValue={getPushToDebitPercentFee(feeCatalogEntries)}
            paymentNote={payment.note}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onDone={onCreateCardDetailsDone}
            onError={showVerificationErrorDialog}
            isSaving={isVerifying}
          />
          <CardValidationModalScreen isOpen={isCardValidationModalOpen} onDone={onCardValidationModalDone} />
        </>
      );

    case 'ADD_CARD_HOLDER_DETAILS':
      return (
        <CardHolderDetailsScreen
          onBack={goToPreviousStep}
          accountName={accountName}
          paymentAmount={payment.amount}
          onDone={createDeliveryMethod}
          isSaving={isCreatingDeliveryMethod || isUpdatingPayment}
        />
      );

    case 'CARD_ADDED_SUCCESSFULLY':
      return <CardAddedSuccessfullyScreen accountName={accountName} />;
  }
};

export const TBTPushToDebitActivity = withAnalyticsContext(BaseTBTPushToDebitActivity);
