import { useMtlDateUtils } from '@melio/ap-domain';
import {
  CalculateGracePeriodStatus,
  CompleteRequiredDetailsFormFields,
  getFilledFields,
  getRequiredFields,
} from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ApiError, PatchAccountsAccountIdRequest, useAccount, useOrganizationPreferences } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useKeydownEvent } from '@melio/platform-utils';
import { isEmpty, omitBy } from 'lodash';
import { useState } from 'react';

import { CompleteRequiredDetailsScreen } from './complete-required-details-screen';
import { useCompleteComplianceInformation } from './hooks';
import { SubmittedFieldsMessageType } from './types';

export type CompleteRequiredDetailsActivityProps = {
  onBack?: VoidFunction;
  onClose: VoidFunction;
  onDone: VoidFunction;
  onError?: ErrorFunction;
  onSkip: VoidFunction;
  forceCloseButton?: boolean;
  isLoading?: boolean;
  submitButtonLabel: string;
};

export const CompleteRequiredDetailsActivity = withAnalyticsContext<CompleteRequiredDetailsActivityProps>(
  ({
    setAnalyticsProperties,
    onDone,
    onClose,
    onError,
    onSkip,
    onBack,
    forceCloseButton,
    isLoading,
    submitButtonLabel,
  }) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const [accountUpdateError, setAccountUpdateError] = useState<PlatformError>();
    const { toast } = useToast();
    const { createDate, dateToISOString } = useMtlDateUtils();
    const { create: createOrganizationPreference } = useOrganizationPreferences({ enabled: false });

    const {
      data: account,
      isLoading: isAccountLoading,
      update: updateAccount,
      isMutating: isUpdateAccountLoading,
      error: accountError,
    } = useAccount({ id: 'me', enabled: false });

    const {
      isLoading: isLoadingCompleteComplianceInformation,
      error: complianceInformationError,
      missingFields,
      isGracePeriodStarted,
      isGracePeriodEnded,
      numberOfPaymentsLeftInGracePeriod,
      invalidateQuery: invalidateAccountLimitationsQuery,
    } = useCompleteComplianceInformation();

    setAnalyticsProperties({
      PageName: 'complete-the-required-details',
    });

    const handleUpdateAccount = async (
      payload: PatchAccountsAccountIdRequest,
      submittedFieldsType: SubmittedFieldsMessageType | null
    ) => {
      try {
        track('Dashboard', 'Saved');
        setAccountUpdateError(undefined);
        await updateAccount(payload);
        void invalidateAccountLimitationsQuery();
        onDone();
        submittedFieldsType &&
          toast({
            type: 'success',
            title: formatMessage(`widgets.completeRequiredDetailsModal.update.message.success.${submittedFieldsType}`),
          });
      } catch (e) {
        const error: PlatformError = {
          message: (e as Error).message || formatMessage('screens.generalError.title'),
          code: (e as ApiError).code,
        };
        setAccountUpdateError(error);
        onError?.(error);
        track('Dashboard', 'Failure');
        toast({
          type: 'error',
          title: error.message,
        });
      }
    };

    const handleSubmit = (data: CompleteRequiredDetailsFormFields) => {
      const {
        firstName,
        lastName,
        dateOfBirth,
        legalCompanyName,
        legalAddress,
        taxInfoType,
        taxInfoIdentifier,
        phoneNumber,
        industry,
        businessType,
        existingTaxIdIsEin,
        taxIdEinOverride,
        address,
        companyName,
        contactLastName,
        contactFirstName,
        email,
      } = data;
      const shouldOverrideTaxId = existingTaxIdIsEin === 'No' && !!taxIdEinOverride;

      const { filledFields, numFilledOrgFields, numFilledUserFields } = getFilledFields(missingFields, data);
      const { requiredFields, numberOfRequiredUserFields, numberOfRequiredOrgFields } =
        getRequiredFields(missingFields);

      const getSubmittedFieldsMessageType = () => {
        if (!numFilledOrgFields && numFilledUserFields) {
          return SubmittedFieldsMessageType.PERSONAL_ONLY;
        }
        if (numFilledOrgFields && !numFilledUserFields) {
          return SubmittedFieldsMessageType.COMPANY_ONLY;
        }
        if (!numFilledOrgFields && !numFilledUserFields) {
          return null;
        }
        return SubmittedFieldsMessageType.GENERIC;
      };

      const submittedFieldsMessageType = getSubmittedFieldsMessageType();

      void handleUpdateAccount(
        {
          company: omitBy(
            {
              contactFirstName,
              contactLastName,
              name: companyName,
              legalName: legalCompanyName,
              address: address && { ...address, line2: address.line2 || '' },
              legalAddress: legalAddress && { ...legalAddress, line2: legalAddress.line2 || '' },
              taxInfo:
                taxInfoType && taxInfoIdentifier ? { type: taxInfoType, identifier: taxInfoIdentifier } : undefined,
              phoneNumber,
              industry,
              businessType,
              ...(shouldOverrideTaxId && { taxIdEinOverride }),
            },
            isEmpty
          ),
          user: omitBy(
            {
              firstName,
              lastName,
              email,
              ...(dateOfBirth && { dateOfBirth: dateToISOString(createDate(dateOfBirth)) }),
            },
            isEmpty
          ),
        },
        submittedFieldsMessageType
      );

      if (shouldOverrideTaxId) {
        void createOrganizationPreference({
          key: 'taxIdEinOverride',
          value: 'true',
        });
      }

      track('Organization', 'Status', {
        PageName: 'dashboard-vendors',
        Intent: 'submit-details',
        GracePeriodStatus: CalculateGracePeriodStatus(isGracePeriodStarted, isGracePeriodEnded),
        // GracePeriodDaysRemain: '', // TODO: should complete on phase 2
        GracePeriodPaymentsRemain: numberOfPaymentsLeftInGracePeriod,
        NumRequiredUserFields: numberOfRequiredUserFields,
        NumRequiredOrgFields: numberOfRequiredOrgFields,
        RequiredFields: requiredFields,
        NumFilledUserFields: numFilledUserFields,
        NumFilledOrgFields: numFilledOrgFields,
        FilledFields: filledFields,
        Status: 'success',
      });

      track('VerifyTaxDetails', 'Submitted');
    };
    const isLoadingScreen = isLoading || isAccountLoading || isLoadingCompleteComplianceInformation;
    const error = accountError || complianceInformationError || accountUpdateError;

    const isDismissible =
      (!error && !isLoadingCompleteComplianceInformation && !missingFields.some((field) => field.isRequired)) ||
      !!forceCloseButton;

    useKeydownEvent({
      key: 'Escape',
      handler: () => onClose(),
      enabled: isDismissible,
    });

    return (
      <CompleteRequiredDetailsScreen
        missingFields={missingFields}
        isSaving={isUpdateAccountLoading}
        isLoading={isLoadingScreen}
        onDone={handleSubmit}
        account={account}
        onSkip={onSkip}
        onClose={onClose}
        onBack={onBack}
        isDismissible={isDismissible}
        error={error?.message}
        submitButtonLabel={submitButtonLabel}
        numberOfPaymentsLeftInGracePeriod={numberOfPaymentsLeftInGracePeriod}
        isGracePeriodEnded={isGracePeriodEnded}
        isGracePeriodStarted={isGracePeriodStarted}
      />
    );
  }
);
