import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethodType, GetMeOrgData, useSwitchOrganization } from '@melio/platform-api';
import { useNavigate, useWizard, UseWizardArgs } from '@melio/platform-utils';
import { SelectOrganizationModel } from '@melio/vex-widgets';
import { noop } from 'lodash';
import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useSessionConfig } from '../../contexts';
import { ServerErrorScreen } from '../../shared/screens/server-error';
import { AcceptPaymentWithBankAccountDeliveryMethodParams } from '../../types';
import { CreateOrConfirmDeliveryMethodActivity } from '../create-or-confirm-delivery-method';
import { SelectOrganizationScreen } from './screens/SelectOrganization.screen';

type Steps = 'select-org' | 'delivery-method';

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  ['select-org']: 'select-org',
  ['delivery-method']: 'delivery-method',
};

const navigationMap: UseWizardArgs<Steps>['navigationMap'] = {
  ['select-org']: () => 'delivery-method',
  ['delivery-method']: noop,
};

type AcceptPaymentWithBankAccountWizardProps = {
  onAcceptPayment: (params: AcceptPaymentWithBankAccountDeliveryMethodParams) => Promise<void>;
  cancelUrlFallback: string;
  contentHeaderTitle: ReactNode;
  organizationId: string | undefined;
  payorOrganizationName: string;
  refetchOrganizationData: () => Promise<unknown>;
  isFetchingOrganizationData: boolean;
  organizations: GetMeOrgData[] | undefined;
  header: ReactElement | undefined;
};
export const AcceptPaymentWithBankAccountWizard = withAnalyticsContext<AcceptPaymentWithBankAccountWizardProps>(
  ({
    onAcceptPayment,
    cancelUrlFallback,
    contentHeaderTitle,
    organizationId,
    payorOrganizationName,
    setAnalyticsProperties,
    refetchOrganizationData,
    isFetchingOrganizationData,
    organizations,
    header,
  }) => {
    const isMultiOrg = organizations && organizations.length > 1;
    const firstStep: Steps = isMultiOrg ? 'select-org' : 'delivery-method';
    const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(organizationId);
    const { goNextMap, goBack } = useWizard<Steps, typeof navigationMap>({
      flowName: 'accept-payment-with-bank-account',
      firstStep,
      locationsMap,
      navigationMap,
      cancelUrlFallback,
    });
    const [isError, setIsError] = useState(false);
    const { setAccessTokens } = useSessionConfig();
    const { track } = useAnalytics();
    const { toast } = useToast();
    const navigate = useNavigate();
    const cancelFlow = () => navigate(cancelUrlFallback);

    const { isLoading: isLoadingSwitchOrganization, mutateAsync: switchOrganization } =
      useSwitchOrganization(setAccessTokens);

    setAnalyticsProperties({ DeliveryMethodType: DeliveryMethodType.BankAccount });

    const handleDeliveryMethodError = useCallback(() => {
      setIsError(true);
    }, []);

    const handleSelectOrganization = async (data: SelectOrganizationModel) => {
      try {
        track('SelectOrganization', 'Chose', {
          Cta: 'continue',
          PageName: 'chose-which-business-gets-paid',
          Intent: 'chose-business',
        });
        await switchOrganization(data.organizationId);
        await refetchOrganizationData();
        setSelectedOrgId(data.organizationId);
      } catch (error) {
        if (error instanceof Error) {
          toast({ type: 'error', title: error.message });
        }
      }
    };

    useEffect(
      function navigateSelectOrganizationNextPage() {
        if (selectedOrgId && organizationId === selectedOrgId && !isFetchingOrganizationData) {
          goNextMap['select-org']({ navArgs: [] });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isFetchingOrganizationData, organizationId, selectedOrgId]
    );

    if (isError) {
      return <ServerErrorScreen />;
    }

    return (
      <Routes>
        <Route
          path="select-org"
          element={
            <SelectOrganizationScreen
              contentHeaderTitle={contentHeaderTitle}
              onSubmit={handleSelectOrganization}
              onBack={cancelFlow}
              organizations={organizations ?? []}
              isLoading={isLoadingSwitchOrganization}
              header={header}
            />
          }
        />
        <Route
          path="delivery-method/*"
          element={
            <CreateOrConfirmDeliveryMethodActivity
              onAcceptPayment={onAcceptPayment}
              onBack={firstStep === 'delivery-method' ? cancelFlow : goBack}
              onError={handleDeliveryMethodError}
              contentHeaderTitle={contentHeaderTitle}
              deliveryMethodType={DeliveryMethodType.BankAccount}
              cancelUrlFallback={firstStep === 'delivery-method' ? cancelUrlFallback : '/select-org'}
              organizationId={organizationId}
              payorOrganizationName={payorOrganizationName}
              header={header}
            />
          }
        />
      </Routes>
    );
  }
);

AcceptPaymentWithBankAccountWizard.displayName = 'AcceptPaymentWithBankAccountWizard';
