import { useBreakpointValue } from '@melio/penny';
import {
  PaymentUpgradeOfferOption,
  PaymentUpgradeOfferOptionEnum,
  TrackerTimelineStatusEnum,
  VendorManagedBy,
  VexGuestPaymentResponse,
} from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';
import { useIsMobile } from '@melio/vex-widgets';

import { getUpgradeOfferByType } from '../../payment-upgrade/utils';

export const useTrackPaymentAnalyticsData = ({
  guestPaymentData,
  paymentId,
  paymentUpgradeOffers,
}: {
  guestPaymentData: VexGuestPaymentResponse | undefined;
  paymentId: string;
  paymentUpgradeOffers: Array<PaymentUpgradeOfferOption>;
}) => {
  const isMobile = useIsMobile();
  const deviceBreakpoint = useBreakpointValue({ xs: 'xs', s: 's', m: 'm', l: 'l' });

  const location = useLocation();

  return {
    PaymentId: paymentId,
    PageName: 'vendor-track-payment',
    DeviceType: isMobile ? 'mobile' : 'desktop',
    DeviceBreakpoint: deviceBreakpoint,
    PathName: location.pathname,
    VendorId: guestPaymentData?.vendor.id,
    DeliveryMethod: guestPaymentData?.deliveryMethod?.type,
    DeliveryMethodId: guestPaymentData?.deliveryMethod?.id,
    PaymentStatus: guestPaymentData?.trackerTimelineStatus,
    PaymentSubStatus: guestPaymentData?.status,
    IsTraceNumber: !!guestPaymentData?.deliveryNachaNumber,
    possibleAcitons: {
      contactPayor:
        guestPaymentData?.trackerTimelineStatus === TrackerTimelineStatusEnum.FAILED &&
        guestPaymentData?.vendor.managedBy !== VendorManagedBy.Shared,
      contactSupport:
        guestPaymentData?.trackerTimelineStatus === TrackerTimelineStatusEnum.FAILED &&
        guestPaymentData?.vendor.managedBy === VendorManagedBy.Shared,
      pushToRtp: !!getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Rtp),
      pushToFastAch: !!getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Fach),
      pushToDebit: !!getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Debit),
    },
  };
};
