import { OrganizationPreference } from '@melio/platform-api';

import { preferencesApi } from './apiClients';

export type OrganizationPreferences = { [p: string]: string };
export const getPreferences = () => preferencesApi.getOrganizationPreferences().then((res) => res.data.data);

export const setPreference = ({ key, value }: OrganizationPreference) =>
  preferencesApi
    .setOrganizationPreferences({
      setOrganizationPreferenceRequest: { organizationPreference: { key, value } },
    })
    .then((res) => res.data.data);
