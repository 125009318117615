import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { FormWidgetProps } from '../../../types';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
} from '../../../utils/bank-account-validations/bank-account-validations.utils';
import { useAchValidationSchema } from '../../bank-details/util/use-bank-account-validation-schema';

export type VendorBankDetailsPayeeFormFields = { routingNumber: string; accountNumber: string };
export type VendorBankDetailsPayeeFormProps = FormWidgetProps<VendorBankDetailsPayeeFormFields>;

export const VendorBankDetailsPayeeForm = forwardRef<VendorBankDetailsPayeeFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, defaultValues: _defaultValues, isSaving, ...props }, ref) => {
    const defaultValues = defaults(_defaultValues, {
      routingNumber: '',
      accountNumber: '',
    });
    const { formatMessage } = useMelioIntl();

    const schema = useAchValidationSchema();
    const { formProps, registerField } = useMelioForm<VendorBankDetailsPayeeFormFields>({
      onSubmit,
      schema,
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <Form data-component="VendorBankDetailsPayeeForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          data-private
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorBankDetailsPayeeForm.routingNumber.label'),
          }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorBankDetailsPayeeForm.routingNumber.placeholder', {
            length: ROUTING_NUMBER_LENGTH,
          })}
          {...registerField('routingNumber')}
        />
        <Form.SecuredTextField
          data-private
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorBankDetailsPayeeForm.accountNumber.label'),
          }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorBankDetailsPayeeForm.accountNumber.placeholder', {
            minLength: ACCOUNT_NUMBER_MIN_LENGTH,
            maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
          })}
          {...registerField('accountNumber')}
        />
      </Form>
    );
  }
);
VendorBankDetailsPayeeForm.displayName = 'VendorBankDetailsPayeeForm';
