import { cloneDeep, merge } from 'lodash';
import React, { useContext } from 'react';

import { defaultConfig } from './defaultConfig';
import { MelioConfig, PartialMelioConfig } from './types';

const ConfigContext = React.createContext<MelioConfig>({} as MelioConfig);

export type ConfigProviderProps = {
  config?: PartialMelioConfig;
};

const defaultConfigClone = cloneDeep(defaultConfig);
export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children, config = {} }) => (
  <ConfigContext.Provider value={merge(defaultConfigClone, config)}>{children}</ConfigContext.Provider>
);

export const useConfig = () => useContext<MelioConfig>(ConfigContext);
