import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const SmallBusiness: React.VFC = () => (
  <LazyAnimation
    id="small-business"
    getAnimationData={() => import('./assets/small-business.lottie')}
    width="100%"
    height="100%"
  />
);
