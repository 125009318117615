// Import from Chakra and not from the DS to not cause a circular dependency
import { Box } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderers: Record<string, any> = {
  semiBold: (...chunks: unknown[]) => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
  bold: (...chunks: unknown[]) => <strong>{chunks}</strong>,
  mark: (...chunks: unknown[]) => <mark>{chunks}</mark>,
  emphasize: (...chunks: unknown[]) => <em className="emphasize">{chunks}</em>,
  success: (...chunks: unknown[]) => (
    <Box as="span" color="global.success.700">
      {chunks}
    </Box>
  ),
  br: <br />,
};
