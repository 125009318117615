import { useBasisTheory } from '@basis-theory/basis-theory-react';
import { CardParams } from '@melio/platform-api';

import { TBTFormWidgetFields } from './TBTForm';

export const useTbt = () => {
  const { bt } = useBasisTheory();

  const result = {
    bt,
    tokenize: async ({ cardNumber, cardExpiration, cardVerificationCode }: TBTFormWidgetFields) => {
      const tokenizedData = await bt?.tokenize({
        type: 'card',
        data: {
          number: cardNumber,
          expiration_month: cardExpiration.month(),
          expiration_year: cardExpiration.year(),
          cvc: cardVerificationCode,
        },
        mask: {
          number: '{{ data.number | slice: 0, 6 }}XXXXXX{{ data.number | last4 }}',
          expiration_month: '{{ data.expiration_month }}',
          expiration_year: '{{ data.expiration_year }}',
        },
      });

      type CardTokenType = {
        id: string;
        data: { number: string; expiration_month: string; expiration_year: string; cvc: string };
      };
      const cardTokenizedData = tokenizedData as CardTokenType;

      const cardParams = {
        lastFourDigits: cardTokenizedData?.data?.number.slice(-4),
        cardBin: cardTokenizedData?.data?.number.slice(0, 6),
        expirationMonth: String(cardTokenizedData?.data?.expiration_month).padStart(2, '0'),
        expirationYear: String(cardTokenizedData?.data?.expiration_year),
        tabapayToken: cardTokenizedData?.id,
        tokenProvider: 'basistheory',
      } as unknown as CardParams;

      return cardParams;
    },
  };

  return result;
};
