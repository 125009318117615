/* eslint-disable @typescript-eslint/consistent-type-definitions */
// eslint-disable-next-line import/no-extraneous-dependencies
import { To } from '@remix-run/router';
import {
  Location,
  NavigateOptions as RouterNavigationOptions,
  useNavigate as routerUseNavigate,
} from 'react-router-dom';

import { Override } from '../types-utils';
import { LocationState } from '../useWizard/types';

export type TypedLocation = Override<Location, { state: LocationState }>;

interface NavigateOptions<S> extends RouterNavigationOptions {
  state: S;
}

interface NavigateFunction<S> {
  (to: To, options?: NavigateOptions<S>): void;
  (delta: number): void;
}

export const useNavigate = <S>() => routerUseNavigate() as NavigateFunction<S>;
