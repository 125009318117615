import { Address } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

export type AddressAutocompleteSuggestion = {
  city: string;
  secondary: string;
  state: string;
  streetLine: string;
  zipcode: string;
};

const useSmartyStreetsClient = () => {
  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const config = useConfig();
  const credentials = new SmartyStreetsCore.SharedCredentials(config.services.smartystreets.apiKey);

  return SmartyStreetsCore.buildClient.usAutocompletePro(credentials);
};

export const createAddressLabel = ({ line1, line2, city, state, postalCode }: Address) =>
  `${line1}${line2 ? ` ${line2},` : ','} ${city}, ${state} ${postalCode}`;

export const useAddressAutocomplete = () => {
  const client = useSmartyStreetsClient();
  const suggestionToAddress = (suggestion: AddressAutocompleteSuggestion): Address => ({
    line1: suggestion.streetLine,
    line2: suggestion.secondary,
    city: suggestion.city,
    state: suggestion.state,
    postalCode: suggestion.zipcode,
  });

  const addressAutocomplete = async (searchQuery: string): Promise<Address[]> => {
    if (!searchQuery) {
      return [];
    }

    const { result } = await client.send(new SmartyStreetsSDK.usAutocompletePro.Lookup(searchQuery));

    return result.map((suggestion) => suggestionToAddress(suggestion));
  };

  return { addressAutocomplete };
};
