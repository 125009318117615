import { useAccountWithLogoUrl, useFiles } from '@melio/platform-api';
import { useEffect } from 'react';

export const useUploadLogoFile = ({ enabled }: { enabled?: boolean }) => {
  const { create: createFile, isMutating: isUploading } = useFiles({ enabled: false, associatedType: 'user' });
  const {
    data: accountData,
    update: updateAccount,
    isMutating: isUpdatingAccount,
    isFetched: isFetchedAccount,
    refetch: refetchAccount,
    isLoading: isAccountLoading,
  } = useAccountWithLogoUrl({ id: 'me', enabled });
  useEffect(() => {
    if (enabled) {
      void refetchAccount(); // TODO: Remove when https://meliorisk.atlassian.net/browse/ME-60330 is resolved
    }
  }, [enabled, refetchAccount]);
  const upload = async (file: File) => {
    const res = await createFile(file);
    await updateAccount({ company: { logoId: res?.id } });
    await refetchAccount(); // TODO: Remove when https://meliorisk.atlassian.net/browse/ME-60330 is resolved
    return res;
  };

  const reset = async () => {
    await updateAccount({ company: { logoId: null } });
    await refetchAccount(); // TODO: Remove when https://meliorisk.atlassian.net/browse/ME-60330 is resolved
  };

  return {
    upload,
    reset,
    isUpdating: isUploading || isUpdatingAccount,
    isFetched: isFetchedAccount,
    isLoading: isAccountLoading,
    data: accountData && { logoUrl: accountData?.logoUrl },
  };
};
