import { useNonEntityMutation } from '@melio/api-client';
import { ApiKeys, PostAuthResponse, VexAuthenticationApiClient } from '@melio/platform-api-axios-client';

type ResetAccessTokenFunctionType = (accessToken: string, refreshToken: string | null) => void;

export const useSwitchOrganization = (resetAccessToken: ResetAccessTokenFunctionType) =>
  useNonEntityMutation(
    (organizationId: string) => VexAuthenticationApiClient.postVexAuthSwitchOrganization(organizationId),
    [ApiKeys.VexAuthenticationApi, 'collection', 'postVexAuthSwitchOrganization'],
    {
      onSuccess: (response: PostAuthResponse) => {
        const { accessToken, refreshToken } = response.data;
        resetAccessToken(accessToken, refreshToken);
      },
    }
  );
