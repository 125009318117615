import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { ApiKeys, VexDeliveryMethodsApiClient, VexOrganizationsApiClient } from '@melio/platform-api-axios-client';

type OrganizationDeliveryMethodsType = {
  organizationId?: string;
  options?: UseCollectionApiProps<typeof VexOrganizationsApiClient.getOrganizationDeliveryMethods>;
};

export const useOrganizationDeliveryMethods = ({
  organizationId = '',
  options = {},
}: OrganizationDeliveryMethodsType) =>
  useCollectionApi({
    ...options,
    queryKey: [ApiKeys.VexOrganizationsApi, 'getOrganizationDeliveryMethods', organizationId],
    queryFn: () => {
      if (!organizationId) {
        throw new Error('Organization id is required');
      }
      return VexOrganizationsApiClient.getOrganizationDeliveryMethods(organizationId);
    },
    createFn: VexDeliveryMethodsApiClient.postVexDeliveryMethods,
  });
