import { ComponentThemeType } from '@/cl/theme/theme.hooks';

type FullScreenContainerThemeProps = {
  centered?: boolean;
};

export const FullScreenContainerTheme: ComponentThemeType<FullScreenContainerThemeProps> = (
  currentTheme,
  { centered },
) => ({
  baseStyle: {
    screen: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    },
    container: {
      flex: 1,
      width: { xs: 'auto', s: '488px' } as never,
      gridGap: '40px',
      display: 'flex',
      flexDir: 'column',
      alignItems: 'center',
      margin: '0 auto',
      padding: { xs: '0 16px', s: 'initial' } as never,
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleContainer: {
      gridGap: '16px',
      display: 'flex',
      flexDir: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    childrenContainer: {
      gridGap: '40px',
      paddingBottom: '20px',
      width: '100%',
      display: 'flex',
      flexDir: 'column',
      alignItems: 'center',
    },
    closeContainer: {
      justifyContent: 'flex-end',
      height: '72px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    closeButton: {
      marginRight: '40px',
    },
  },
  sizes: {
    large: {
      container: {
        ...(centered ? { justifyContent: 'center' } : { paddingTop: '0' }),
      },
    },
    small: {
      container: {
        ...(centered ? { justifyContent: 'center' } : { paddingTop: '32px' }),
      },
    },
  },
});
