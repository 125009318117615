import { RadioOption } from '@melio/penny';
import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { taxTypeOptions } from '../consts';
import { isEinOnlyBusinessType } from '../utils';

export const useTaxTypeOptions = (selectedBusinessType: OrganizationBusinessType | null) => {
  const { formatMessage } = useMelioIntl();

  const taxTypeAdjustedOptions = useMemo(
    () =>
      taxTypeOptions.map<RadioOption>(({ value, label }) => ({
        mainLabelProps: { label: formatMessage(label) || '' },
        value,
        ...(selectedBusinessType && {
          disabled: {
            isDisabled: value !== TaxIdTypeEnum.Ein && isEinOnlyBusinessType(selectedBusinessType),
            message: formatMessage('app.mtl.labels.einOnlyHelperText', {
              businessType: selectedBusinessType
                ? formatMessage(`app.mtl.labels.businessType.option.${selectedBusinessType}`)
                : '',
            }),
          },
        }),
      })),
    [formatMessage, selectedBusinessType]
  );

  return taxTypeAdjustedOptions;
};
