import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { BaseSuccessScreen } from '../../../base-screens';

export type CollectW9FileSuccessScreenProps = {
  accountName: string;
};

export const CollectW9FileSuccessScreen = forwardRef<CollectW9FileSuccessScreenProps, 'div'>(
  ({ accountName, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    return (
      <BaseSuccessScreen
        title={formatMessage('vex.screen.collectW9File.success.title')}
        description={formatMessage('vex.screen.collectW9File.success.description', { accountName })}
        ref={ref}
        data-testid="collect-w9-file-success-screen"
        data-component="CollectW9FileActivity.CollectW9FileSuccessScreen"
        displayTrustedPartners={false}
        {...props}
      />
    );
  }
);

CollectW9FileSuccessScreen.displayName = 'CollectW9FileActivity.CollectW9FileSuccessScreen';
