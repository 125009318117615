import { mapValues } from 'lodash';
import { atom, DefaultValue, selector } from 'recoil';
import { Vendor } from '@melio/platform-api';

import { VendorsDashboardType } from '@/store/Vendors/Vendors.types';
import { sortVendors } from '@/utils/vendors.utils';

export const vendorsDashboardState = atom<VendorsDashboardType>({
  key: 'vendorsDashboardState',
  default: {
    selectedVendorId: undefined,
    searchQuery: '',
  },
});

export const vendorsState = atom<Vendor[] | null>({
  key: 'vendorsState',
  default: null,
});

export const vendorsDashboardSelectedVendorIdSelector = selector<string | undefined>({
  key: 'vendorsDashboardSelectedVendorIdSelector',
  get: ({ get }) => {
    const vendorsDashboard = get(vendorsDashboardState);
    return vendorsDashboard.selectedVendorId;
  },
  set: ({ get, set }, selectedVendorId) => {
    if (selectedVendorId instanceof DefaultValue) {
      return;
    }
    set(vendorsDashboardState, { ...get(vendorsDashboardState), selectedVendorId });
  },
});

export const vendorsDashboardSearchQuerySelector = selector<string>({
  key: 'vendorsDashboardSearchQuerySelector',
  get: ({ get }) => {
    const vendorsDashboard = get(vendorsDashboardState);
    return vendorsDashboard.searchQuery;
  },
  set: ({ get, set }, searchQuery) => {
    if (searchQuery instanceof DefaultValue) {
      return;
    }
    set(vendorsDashboardState, { ...get(vendorsDashboardState), searchQuery });
  },
});

export const vendorsSortedSelector = selector<Vendor[] | null>({
  key: 'vendorsSortedSelector',
  get: ({ get }) => {
    const vendors = get(vendorsState);
    if (vendors === null) {
      return null;
    }
    return sortVendors(vendors);
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const vendorsGroupedByFirstLetterSelector = selector<Record<string, Vendor[]> | null>({
  key: 'vendorsGroupedByFirstLetterSelector',
  get: ({ get }) => {
    const sortedVendors = get(vendorsSortedSelector);
    if (sortedVendors === null) {
      return null;
    }
    return sortedVendors.reduce((acc: { [key: string]: Vendor[] }, curr: Vendor) => {
      const firstLetter = curr.name.charAt(0).toUpperCase();
      if (!Object.keys(acc).includes(firstLetter)) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(curr);
      return acc;
    }, {});
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const vendorsFilteredBySearchQuerySelector = selector<Record<string, Vendor[]> | null>({
  key: 'vendorsFilteredBySearchQuerySelector',
  get: ({ get }) => {
    const searchQuery = get(vendorsDashboardSearchQuerySelector);
    const groupedVendors = get(vendorsGroupedByFirstLetterSelector);
    if (groupedVendors === null) {
      return null;
    }
    const filterFunc = (vendor: Vendor) =>
      !searchQuery ||
      vendor.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor.contact.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor.contact.phoneNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor.contact.name?.toLowerCase().includes(searchQuery.toLowerCase());
    return mapValues(groupedVendors, (group) => group.filter(filterFunc));
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});
