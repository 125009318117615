import {
  ApiCreateRequest,
  ApiDeleteRequest,
  ApiListRequest,
  ApiUpdateRequest,
  extractData,
} from '@melio/platform-api-axios-client';
import { isObject } from 'lodash';
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useIsMutating, useQuery, useQueryClient } from 'react-query';

import {
  ApiError,
  ApiQueryKeyV2,
  CollectionPropsV2,
  EntityWithId,
  Parser,
  PromiseFunctionReturnData,
  UseCollectionApiResultV2,
} from './types';
import { useCreateMutationV2 } from './useCreateMutationV2';
import { useDeleteMutationV2 } from './useDeleteMutationV2';
import { useUpdateMutationV2 } from './useUpdateMutationV2';
import { createQueryKeyV2 } from './utilV2';

export const useCollectionApiV2 = <
  TQueryFn extends ApiListRequest,
  TCreateQueryFn extends ApiCreateRequest = never,
  TUpdateQueryFn extends ApiUpdateRequest = never,
  TDeleteQueryFn extends ApiDeleteRequest = never,
  TData = PromiseFunctionReturnData<TQueryFn>[number],
  TCreateVariables = Required<Parameters<TCreateQueryFn>>[0],
  TUpdateVariables = Required<Parameters<TUpdateQueryFn>>[1]
>({
  queryKey: _queryKey,
  queryFn: _queryFn,
  scope = 'default',
  enabled,
  createFn,
  onCreate,
  onCreateError,
  prepareCreateParams,
  updateFn,
  onUpdate,
  onUpdateError,
  prepareUpdateParams,
  deleteFn,
  onDelete,
  onDeleteError,
  populateModels = false,
  ...options
}: CollectionPropsV2<
  TQueryFn,
  TCreateQueryFn,
  TUpdateQueryFn,
  TDeleteQueryFn,
  TData,
  TCreateVariables,
  TUpdateVariables
>): UseCollectionApiResultV2<TQueryFn, TDeleteQueryFn, TData, TCreateVariables, TUpdateVariables> => {
  const queryKey = createQueryKeyV2({
    queryKey: _queryKey,
    role: 'collection',
    scope,
  });
  type TQueryFnData = PromiseFunctionReturnData<TQueryFn>[number];

  const queryFn = _queryFn && (() => _queryFn()?.then((data) => extractData<TQueryFnData[], TData[]>(data ?? {})));

  const queryClient = useQueryClient();

  type TDataWithId = TData & EntityWithId;

  const hasId = (item: TData): item is TDataWithId => isObject(item) && 'id' in item;
  const setDataInModel = (entity: TDataWithId) => {
    const targetQueryKey = [queryKey[0], 'model', entity.id, scope, ...queryKey.slice(3)];
    queryClient.setQueryDefaults(targetQueryKey, { placeholderData: entity });
    if (populateModels) {
      queryClient.setQueryData([queryKey[0], 'model', entity.id, scope], entity);
    }
  };

  const query = useQuery<TQueryFnData[], ApiError, TData[], ApiQueryKeyV2>({
    ...options,
    queryKey,
    queryFn,
    enabled: enabled && !!queryFn,
    onSuccess: (data) => {
      data?.filter(hasId).forEach(setDataInModel);
      options.onSuccess?.(data);
    },
  });

  const useCreateQueryKey = useMemo(() => [...queryKey, 'create'], [queryKey]) as typeof queryKey;
  const useUpdateQueryKey = useMemo(() => [...queryKey, 'update'], [queryKey]) as typeof queryKey;
  const useDeleteQueryKey = useMemo(() => [...queryKey, 'delete'], [queryKey]) as typeof queryKey;

  // apply the select logic that is designed to handle an array to a single item
  const mutationSelect: Parser<PromiseFunctionReturnData<TQueryFn>[number], TData> = (data) => {
    if (options.select) {
      return options.select([data])[0] as TData;
    } else {
      return data as TData;
    }
  };

  const createMutation = useCreateMutationV2<TCreateQueryFn, TCreateVariables>(createFn, useCreateQueryKey, {
    onSuccess: onCreate,
    onError: onCreateError,
    prepareData: prepareCreateParams,
    select: mutationSelect,
  });

  const updateMutation = useUpdateMutationV2<TUpdateQueryFn, TQueryFnData, TUpdateVariables>(
    updateFn,
    useUpdateQueryKey,
    {
      onSuccess: onUpdate,
      onError: onUpdateError,
      prepareParams: prepareUpdateParams,
      select: mutationSelect,
    }
  );

  const deleteMutation = useDeleteMutationV2(deleteFn, useDeleteQueryKey, {
    onSuccess: onDelete,
    onError: onDeleteError,
  });

  const isCreating = useIsMutating(useCreateQueryKey) > 0;
  const isUpdating = useIsMutating(useUpdateQueryKey) > 0;
  const isDeleting = useIsMutating(useDeleteQueryKey) > 0;
  const _isMutating = useIsMutating(queryKey) > 0;
  const isMutating = useMemo(
    () => _isMutating || isCreating || isUpdating || isDeleting,
    [_isMutating, isCreating, isUpdating, isDeleting]
  );

  return {
    ...query,
    queryKey,
    update: updateMutation.update,
    create: createMutation.mutateAsync,
    delete: deleteMutation.mutateAsync,
    isFetching: useIsFetching(queryKey) > 0,
    isCreating,
    isUpdating,
    isDeleting,
    isMutating,
    _mutations: {
      create: createMutation,
      update: updateMutation,
      delete: deleteMutation,
    },
  };
};
