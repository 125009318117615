import { mergeWith, MergeWithCustomizer } from 'lodash';
import { PartialMelioConfig } from '@melio/platform-provider';
import { MelioConfig } from '@melio/platform-provider';

import { defaultConfig } from './default.config';

const DEFAULT_CONFIG_KEY = 'default';
const PROD_CONFIG_KEY = 'prod';
const DEV_CONFIG_KEY = 'dev';

export type PartnerConfigByEnv = {
  [DEFAULT_CONFIG_KEY]: PartialMelioConfig;
  [PROD_CONFIG_KEY]: PartialMelioConfig;
  [DEV_CONFIG_KEY]: PartialMelioConfig;
};

const mergeConfigCustomizer: MergeWithCustomizer = (current, override, key) => {
  switch (key) {
    case 'deliveryMethodTypeOptionDisabledReasons':
      return { ...current, ...override };
    default:
      return undefined; // undefined = use default merge behavior
  }
};

export const extendDefaultConfig = (partnerConfig: PartnerConfigByEnv): MelioConfig =>
  mergeWith(
    {},
    defaultConfig,
    partnerConfig[DEFAULT_CONFIG_KEY],
    partnerConfig[defaultConfig.production ? PROD_CONFIG_KEY : DEV_CONFIG_KEY],
    mergeConfigCustomizer,
  );
