import { Box } from '@chakra-ui/react';
import { Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

import { PoliciesAndHelpWidget } from './PoliciesAndHelp/PoliciesAndHelp.widget';
import { TrustedPartnersWidget } from './TrustedPartners/TrustedPartners.widget';

type FooterWidgetProps = {
  displayTrustedPartners?: boolean;
  children?: ReactNode;
};

export const FooterWidget = forwardRef<FooterWidgetProps, 'div'>(
  ({ displayTrustedPartners = true, children, ...props }, ref) => (
    <Box data-component="FooterWidget" ref={ref} {...props}>
      <Group variant="vertical" spacing="m" alignItems="center">
        {children}
        {displayTrustedPartners && <TrustedPartnersWidget data-testid="TrustedPartnersWidget" />}
        <PoliciesAndHelpWidget />
      </Group>
    </Box>
  )
);

FooterWidget.displayName = 'FooterWidget';
