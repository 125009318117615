import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: remove the returnUrl parameter in https://meliorisk.atlassian.net/browse/ME-55061
export const useNavigateToEmailVerification = (returnUrl?: string) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const navigateToEmailVerification = useCallback(
    (additionalQueryParams?: Record<string, string>) => {
      const queryParams = new URLSearchParams({ ...additionalQueryParams, returnUrl: returnUrl ?? pathname });
      navigate({ pathname: '../email-verification', search: queryParams.toString() });
    },
    [navigate, pathname, returnUrl]
  );

  return { navigateToEmailVerification };
};
