import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { Logger } from '@melio/platform-logger';

import { useConfig } from './ConfigProvider';

const tbtOptions: { elements: true } = { elements: true };
export const TBTProvider: React.FC = ({ children }) => {
  const { services, settings } = useConfig();
  const tbtEnabled = settings.tokenProvider === 'tbt';
  const { bt, error } = useBasisTheory(tbtEnabled ? services.tbt.apiKey : '', tbtOptions);

  if (tbtEnabled && error) {
    // in this case bt will remain undefined
    Logger.handleException(error);
  }

  return <BasisTheoryProvider bt={bt}>{children}</BasisTheoryProvider>;
};
