import { _createFormFieldInput, Search, SearchOption, SearchProps, useToast } from '@melio/penny';
import { Address, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useAddressAutocomplete } from './useAddressAutocomplete';

export type AddressSearchWidgetProps = Omit<SearchProps, 'options' | 'emptyState'> &
  Partial<Pick<SearchProps, 'emptyState'>>;

export const AddressSearchWidget = _createFormFieldInput(
  forwardRef<AddressSearchWidgetProps, 'input'>(({ value, isViewMode, emptyState, ...props }, ref) => {
    const { addressAutocomplete } = useAddressAutocomplete();
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const [options, setOptions] = useState<SearchOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadOptions = (searchTerm: string): void => {
      setIsLoading(true);

      addressAutocomplete(searchTerm)
        .then((results) => {
          setOptions(
            results.map((option) => ({ value: option, label: formatAddress(option) })) as unknown as SearchOption[]
          );
        })
        .catch(() => {
          toast({ type: 'error', title: formatMessage('form.addressSearch.searchError') });

          setOptions([]);
        })
        .finally(() => setIsLoading(false));
    };

    return (
      <Search
        data-component="AddressSearchWidget"
        ref={ref}
        options={options}
        onInputChange={loadOptions}
        emptyState={emptyState || { label: formatMessage('form.addressSearch.emptyState') }}
        isViewMode={isViewMode}
        isLoading={isLoading}
        value={value}
        formatSelectedValue={(data) => (data.value ? formatAddress(data.value as unknown as Address) : data.value)}
        filterOptions={(options) => options}
        {...props}
      />
    );
  })
);

AddressSearchWidget.displayName = 'AddressSearchWidget';
