import { Form, useMelioForm } from '@melio/penny';
import { Address } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { CheckAccountFormModel, FormWidgetProps } from '../../../../types';
import { AddressSearchWidget, AddressSearchWidgetProps } from '../../../AddressSearch';
import { useCheckValidationSchema } from '../useCheckValidationSchema';
export type VendorCheckDetailsPayeeFormCollapsedProps = FormWidgetProps<CheckAccountFormModel> & {
  onEmptyStateClick: () => void;
};
export const VendorCheckDetailsPayeeCollapsedForm = forwardRef<VendorCheckDetailsPayeeFormCollapsedProps, 'form'>(
  ({ onSubmit, defaultValues, isSaving, onSubmissionStateChange, onEmptyStateClick, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const addressValues = defaults(defaultValues, {
      printName: '',
      line1: null,
      line2: '',
      city: '',
      state: '',
      postalCode: '',
    });

    const { formProps, registerField, setValue, getValues } = useMelioForm<CheckAccountFormModel>({
      onSubmit,
      schema: useCheckValidationSchema(),
      defaultValues: addressValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - types will change in penny
    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address;
      const setFormField = (field: keyof Omit<CheckAccountFormModel, 'printName'>) =>
        setValue(field, address ? address[field] : '', {
          shouldValidate: true,
        });

      setFormField('line1');
      setFormField('line2');
      setFormField('state');
      setFormField('city');
      setFormField('postalCode');
    };
    const isRequiredAddressFilled = () => getValues().line1;

    return (
      <Form
        data-component="VendorCheckDetailsPayeeCollapsedForm"
        data-testid="VendorCheckDetailsPayeeCollapsedForm"
        {...props}
        {...formProps}
        columns={2}
        ref={ref}
      >
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.printName.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.printName.placeholder')}
          helperTextProps={{
            label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.printName.helperText'),
          }}
          {...registerField('printName')}
        />
        <AddressSearchWidget
          {...registerField('line1')}
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line1.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line1.placeholder')}
          onChange={handleAddressChange}
          emptyState={{
            label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line1.emptyStateLabel'),
            onClick: onEmptyStateClick,
          }}
        />
        {isRequiredAddressFilled() && (
          <Form.TextField
            colSpan={2}
            labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line2.label') }}
            placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line2.placeholder')}
            {...registerField('line2')}
          />
        )}
      </Form>
    );
  }
);

VendorCheckDetailsPayeeCollapsedForm.displayName = 'VendorCheckDetailsPayeeCollapsedForm';
