import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';
export const MoneySuccess: React.VFC = () => (
  <LazyAnimation
    getAnimationData={() => import('./assets/money-success.lottie')}
    id="money-success"
    width="100%"
    height="100%"
  />
);
