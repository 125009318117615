import React from 'react';
export const UsBankLogoDark: React.VFC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="80" height="32" viewBox="0 0 80 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 18.6457H3.97448L4.98037 12.2669H7.53189C11.58 12.2669 14.6467 10.2797 14.6467 5.96173C14.6467 2.87046 12.3896 1.10402 8.56231 1.10402H2.77232L0 18.6457ZM5.39745 9.4946L6.25613 4.09715H8.1207C9.93621 4.09715 10.844 4.83317 10.844 6.3052C10.844 8.36604 9.66633 9.4946 7.45829 9.4946H5.39745Z"
      fill="#228800"
    />
    <path
      d="M17.3483 18.9156C19.1147 18.9156 20.3414 18.0814 21.151 16.8793C21.1265 17.419 21.1265 18.0569 21.2246 18.6457H24.6103C24.4877 17.5662 24.5613 16.3886 24.8311 14.7203L25.4445 10.8194C26.0333 7.13935 23.997 5.52012 20.3414 5.52012C17.4955 5.52012 15.1403 6.79588 14.5024 9.91168H17.888C18.1334 8.78312 18.8203 8.09617 20.1697 8.09617C21.8135 8.09617 22.2305 8.93032 21.9852 10.4269L21.9116 10.9666H19.8017C15.9499 10.9666 13.2512 12.0216 13.2512 15.3091C13.2512 17.5417 14.8949 18.9156 17.3483 18.9156ZM18.5505 16.4622C17.3483 16.4622 16.7595 15.7753 16.7595 14.9411C16.7595 13.5672 17.7408 13.0765 19.8998 13.0765H21.5681L21.3964 14.0824C21.1756 15.6035 19.998 16.4622 18.5505 16.4622Z"
      fill="#228800"
    />
    <path
      d="M25.6446 23.0128H29.2265L38.7947 5.81452H35.2128L31.2383 13.5427L29.4473 5.81452H25.7918L28.883 17.1982L25.6446 23.0128Z"
      fill="#228800"
    />
    <path
      d="M37.44 18.6457H45.07C49.0445 18.6457 51.6205 16.5358 51.6205 13.2973C51.596 11.1874 50.4429 9.98528 48.6029 9.5682C50.7128 9.00392 51.964 7.58096 51.964 5.32385C51.9886 2.69872 50.2712 1.10402 46.542 1.10402H40.2123L37.44 18.6457ZM42.9356 8.31697L43.647 3.82728H45.9042C47.5479 3.82728 48.2839 4.41609 48.2839 5.76545C48.2839 7.43376 47.3517 8.31697 45.3399 8.31697H42.9356ZM41.7334 15.8979L42.543 10.8685H44.8983C46.9346 10.8685 47.7442 11.629 47.7442 13.1011C47.7442 14.7448 46.6892 15.8979 44.6039 15.8979H41.7334Z"
      fill="#228800"
    />
    <path
      d="M56.2373 4.04809C57.3659 4.04809 58.2491 3.21393 58.2491 2.10991C58.2491 1.03042 57.3904 0.220804 56.2618 0.220804C55.1333 0.220804 54.2501 1.05496 54.2501 2.13444C54.2501 3.23847 55.1088 4.04809 56.2373 4.04809ZM51.9194 18.6457H55.4522L57.4885 5.81452H53.9557L51.9194 18.6457Z"
      fill="#228800"
    />
    <path d="M57.3475 18.6457H60.8803L63.8244 0H60.2915L57.3475 18.6457Z" fill="#228800" />
    <path d="M62.7516 18.6457H66.2845L69.2286 0H65.6957L62.7516 18.6457Z" fill="#228800" />
    <path
      d="M73.1607 18.9401C76.4237 18.9401 78.6563 17.4436 78.6563 14.6958C78.6563 12.2669 77.3069 11.3592 74.3138 10.7213C72.6945 10.3533 72.0812 10.0343 72.0812 9.27379C72.0812 8.43964 72.7436 7.94897 73.9458 7.94897C75.1479 7.94897 75.8594 8.61138 75.933 9.4946H79.1715C78.9997 6.67321 76.9389 5.52012 74.0439 5.52012C71.1244 5.49558 68.7446 6.91855 68.7446 9.69087C68.7446 11.8008 69.9468 12.8557 72.8417 13.4691C74.6818 13.8862 75.1725 14.2051 75.1725 15.0147C75.1725 15.947 74.5591 16.4377 73.2834 16.4377C71.6151 16.4377 71.0753 15.6526 71.0017 14.6222H67.6896C67.7142 17.2964 69.7014 18.9401 73.1607 18.9401Z"
      fill="#228800"
    />
    <path
      d="M45.473 30.0873C46.8545 30.0873 47.7997 28.9717 47.7997 27.3497C47.7997 26.1053 47.0362 25.4016 46.0183 25.4016C45.3004 25.4016 44.746 25.7106 44.4188 26.1826L44.8732 23.4707H43.5645L42.4739 29.9929H43.7826L43.9098 29.2292C44.1189 29.684 44.6369 30.0873 45.473 30.0873ZM45.164 29.1348C44.4915 29.1348 44.0916 28.74 44.0916 28.0534C44.0916 27.0236 44.6914 26.3371 45.4821 26.3371C46.091 26.3371 46.4364 26.7318 46.4364 27.4355C46.4364 28.431 45.9547 29.1348 45.164 29.1348Z"
      fill="#717173"
    />
    <path
      d="M47.8992 31.5205H49.2261L52.7706 25.5046H51.4437L49.9714 28.2079L49.3079 25.5046H47.9537L49.0989 29.4866L47.8992 31.5205Z"
      fill="#717173"
    />
    <g clipPath="url(#clip0_9711_282787)">
      <path
        d="M68.3926 27.933C68.4407 27.933 68.4813 27.8977 68.4882 27.8502C68.5088 27.7104 68.5189 27.599 68.5189 27.4536C68.5189 27.0693 68.4474 26.7373 68.3061 26.4043C68.1655 26.072 68.009 25.7928 67.7629 25.541C67.5169 25.2892 67.2552 25.0887 66.9224 24.9443C66.5895 24.8001 66.2896 24.7268 65.906 24.7268C65.5225 24.7268 65.1592 24.8001 64.8263 24.9443C64.4938 25.0887 64.2002 25.2892 63.9541 25.541C63.7083 25.7922 63.504 26.0713 63.3628 26.4043C63.2218 26.737 63.1503 27.1008 63.1503 27.4851C63.1503 27.8694 63.2218 28.1725 63.3628 28.5089C63.504 28.8454 63.7001 29.1418 63.9459 29.3892C64.1916 29.6369 64.4849 29.8358 64.8181 29.9803C65.151 30.1244 65.5142 30.1978 65.8978 30.1978C66.2813 30.1978 66.708 30.1244 67.0408 29.9803C67.374 29.8358 67.6673 29.6369 67.9131 29.3892C68.0157 29.286 68.1094 29.1743 68.1943 29.0547C68.2257 29.0106 68.2146 28.9495 68.1693 28.9193L67.3607 28.3808C67.3157 28.3509 67.2556 28.3641 67.2258 28.4091C67.1751 28.4866 67.1175 28.558 67.0538 28.6235C66.9227 28.7579 66.764 28.8646 66.5816 28.9414C66.3992 29.0178 66.1341 29.0565 65.9187 29.0565H65.8937C65.6786 29.0565 65.4769 29.0178 65.2944 28.9414C65.112 28.8646 64.953 28.7579 64.8222 28.6235C64.6908 28.4888 64.5859 28.3273 64.5109 28.1441C64.4836 28.0774 64.4608 28.0066 64.4434 27.933H68.3929H68.3926ZM64.5106 26.7102C64.5856 26.5267 64.6905 26.3656 64.8219 26.2306C64.9527 26.0965 65.1117 25.9895 65.2941 25.913C65.4766 25.8365 65.6783 25.7978 65.8934 25.7978H65.855C66.0704 25.7978 66.2722 25.8365 66.4546 25.913C66.637 25.9895 66.7957 26.0965 66.9268 26.2306C67.0582 26.3656 67.1631 26.5267 67.2381 26.7102C67.2654 26.777 67.2879 26.8478 67.3056 26.9214H64.4431C64.4605 26.8478 64.4833 26.777 64.5106 26.7102V26.7102Z"
        fill="#717173"
      />
      <path
        d="M70.3141 22.7095H69.3108C69.2576 22.7095 69.2145 22.7523 69.2145 22.8052V29.8963C69.2145 29.9492 69.2576 29.992 69.3108 29.992H70.3141C70.3673 29.992 70.4104 29.9492 70.4104 29.8963V22.8052C70.4104 22.7523 70.3673 22.7095 70.3141 22.7095Z"
        fill="#717173"
      />
      <path
        d="M72.0797 23.2829C72.0636 23.2646 72.0449 23.248 72.0233 23.2366C71.9309 23.1869 71.8248 23.2105 71.7621 23.281L71.1397 23.9819C71.0836 24.0449 71.0783 24.1361 71.1308 24.2079C71.1647 24.2545 71.2214 24.2787 71.2794 24.2787H72.5611C72.6194 24.2787 72.6764 24.2538 72.7103 24.2069C72.7619 24.1352 72.7562 24.0445 72.7005 23.9819L72.08 23.2829H72.0797Z"
        fill="#717173"
      />
      <path
        d="M72.4219 24.9326H71.4185C71.3654 24.9326 71.3223 24.9755 71.3223 25.0283V29.8962C71.3223 29.9491 71.3654 29.9919 71.4185 29.9919H72.4219C72.4751 29.9919 72.5182 29.9491 72.5182 29.8962V25.0283C72.5182 24.9755 72.4751 24.9326 72.4219 24.9326Z"
        fill="#717173"
      />
      <path
        d="M60.4822 24.8074C59.9229 24.8074 59.4301 25.0271 59.0168 25.4611C58.6035 25.0274 58.1107 24.8074 57.5513 24.8074C56.992 24.8074 56.4634 25.028 56.0792 25.429C55.7011 25.8243 55.4927 26.3549 55.4927 26.9236V29.8962C55.4927 29.9491 55.5357 29.9919 55.589 29.9919H56.5828C56.636 29.9919 56.6791 29.9491 56.6791 29.8962V26.9236C56.6791 26.6665 56.7652 26.4298 56.9214 26.257C57.0804 26.0811 57.3043 25.9838 57.5513 25.9838C57.7984 25.9838 58.022 26.0808 58.1813 26.257C58.3374 26.4298 58.4236 26.6665 58.4236 26.9236V29.8962C58.4236 29.9491 58.4667 29.9919 58.5199 29.9919H59.514C59.5672 29.9919 59.6103 29.9491 59.6103 29.8962V26.9236C59.6103 26.6668 59.6965 26.4298 59.8526 26.257C60.0119 26.0811 60.2355 25.9838 60.4826 25.9838C60.7296 25.9838 60.9532 26.0808 61.1125 26.257C61.2687 26.4298 61.3548 26.6665 61.3548 26.9236V29.8962C61.3548 29.9491 61.3979 29.9919 61.4511 29.9919H62.4449C62.4982 29.9919 62.5412 29.9491 62.5412 29.8962V26.9236C62.5412 26.3549 62.3328 25.8243 61.9547 25.429C61.5708 25.028 61.0479 24.8074 60.4826 24.8074H60.4822Z"
        fill="#717173"
      />
      <path
        d="M76.5927 30.812H75.311C75.2533 30.812 75.1963 30.8366 75.1625 30.8828C75.1102 30.9546 75.1153 31.0459 75.1713 31.1088L75.7918 31.8078C75.8079 31.8261 75.8266 31.8428 75.8481 31.8541C75.9406 31.9038 76.0467 31.8802 76.1094 31.8097L76.7318 31.1088C76.7875 31.0462 76.7932 30.9555 76.7416 30.8838C76.7077 30.8366 76.6507 30.812 76.5924 30.812H76.5927Z"
        fill="#717173"
      />
      <path
        d="M78.4759 26.3968C78.3372 26.0691 78.1436 25.7771 77.901 25.5291C77.6584 25.281 77.3693 25.0831 77.0415 24.9411C76.7133 24.7989 76.3551 24.7268 75.9773 24.7268C75.5995 24.7268 75.2409 24.7989 74.9131 24.9411C74.5853 25.0834 74.2958 25.281 74.0532 25.5291C73.8109 25.7767 73.6177 26.0685 73.4784 26.3968C73.3394 26.7247 73.269 27.0832 73.269 27.4621C73.269 27.8411 73.3394 28.2017 73.4784 28.5331C73.6174 28.8649 73.8109 29.1569 74.0532 29.4012C74.2955 29.6454 74.5847 29.8412 74.9128 29.9834C75.2409 30.1257 75.5991 30.1978 75.977 30.1978C76.3548 30.1978 76.713 30.1257 77.0411 29.9834C77.3693 29.8412 77.6587 29.6451 77.901 29.4012C78.1436 29.1566 78.3368 28.8646 78.4759 28.5331C78.6149 28.2014 78.6852 27.8411 78.6852 27.4621C78.6852 27.0832 78.6146 26.725 78.4756 26.3968H78.4759ZM74.5156 27.4621C74.5156 27.2327 74.5505 27.018 74.6189 26.8242C74.6873 26.6303 74.7849 26.4625 74.9096 26.3256C75.0341 26.1887 75.1884 26.0792 75.3686 25.9999C75.5488 25.9206 75.7537 25.88 75.9773 25.88C76.2009 25.88 76.4055 25.9203 76.586 25.9999C76.7666 26.0795 76.9208 26.189 77.0453 26.3256C77.1697 26.4622 77.2673 26.63 77.336 26.8242C77.4044 27.0187 77.4393 27.2333 77.4393 27.4621C77.4393 27.6909 77.4047 27.9059 77.336 28.1001C77.2673 28.2943 77.1694 28.462 77.0453 28.5986C76.9211 28.7355 76.7666 28.845 76.586 28.9244C76.4061 29.0037 76.2012 29.0443 75.9773 29.0443C75.7534 29.0443 75.5485 29.004 75.3686 28.9244C75.1884 28.845 75.0338 28.7352 74.9096 28.5986C74.7852 28.4617 74.6873 28.2943 74.6192 28.1001C74.5508 27.9065 74.516 27.6919 74.516 27.4621H74.5156Z"
        fill="#717173"
      />
    </g>
    <defs>
      <clipPath id="clip0_9711_282787">
        <rect width="23.1929" height="9.52887" fill="white" transform="translate(55.4927 22.4712)" />
      </clipPath>
    </defs>
  </svg>
);
