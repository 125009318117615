import { SectionBanner } from '@melio/penny';

import { PersistentFastLabels } from './types';

type Props = {
  labels: PersistentFastLabels['success'];
};

export const PersistentFastPreferenceSuccessBanner = ({ labels }: Props) => (
  <SectionBanner
    data-testid="persistent-fast-success-banner"
    title={labels.title}
    description={labels.description}
    variant="success"
    hideIcon
  />
);

PersistentFastPreferenceSuccessBanner.displayName = 'PersistentFastPreferenceSuccessBanner';
