import { Group, StatusIndicator, StatusIndicatorProps, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ExtendedStatusIndicatorWidgetProps = {
  label: string;
  status: StatusIndicatorProps['status'];
};

export const ExtendedStatusIndicatorWidget = forwardRef<ExtendedStatusIndicatorWidgetProps, 'div'>(
  ({ label, status, ...props }, ref) => (
    <Group alignItems="center" spacing="xxs" ref={ref} data-component="ExtendedStatusIndicatorWidget" {...props}>
      <StatusIndicator status={status} />
      <Text textStyle="body4" color="global.neutral.800">
        {label}
      </Text>
    </Group>
  )
);

ExtendedStatusIndicatorWidget.displayName = 'ExtendedStatusIndicatorWidget';
