import { Box } from '@chakra-ui/react';
import { IconButton, IconButtonProps } from '@melio/penny';

export const CloseButton = (props: Pick<IconButtonProps, 'onClick' | 'isDisabled' | 'aria-label'>) => (
  <Box gridArea="header-close" justifySelf="right">
    <IconButton
      data-component="NewSinglePaymentStepLayout.CloseButton"
      data-testid="layout-close-button"
      role="button"
      tabIndex={0}
      aria-label="close"
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      autoFocus
      icon="close"
      variant="naked"
      size="medium"
    />
  </Box>
);

CloseButton.displayName = 'NewSinglePaymentStepLayout.CloseButton';
