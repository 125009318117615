import { createContext, useContext } from 'react';

import { CreateConfig } from '../../../hooks/useActivityConfigData';
import { isPartnerMelio } from '../../../shared/partner';

export type ActivityConfig = {
  showPoweredByMelio: boolean;
  showNewAddressFlow: boolean;
  showJoinMelioButton: boolean;
  showLoginHeaderSection: boolean;
  emailVerificationReturnUrl: string;
};

export type ActivityConfigParams = {
  isNewAddressFlowVisible: boolean;
};

export const createActivityConfig: CreateConfig<ActivityConfig, ActivityConfigParams> = (appConfig, params) => {
  const isMelio = isPartnerMelio();
  const { isEmailLoginEnabled } = appConfig.vex.unilateral;

  return {
    showPoweredByMelio: !isMelio,
    showNewAddressFlow: params?.isNewAddressFlowVisible ?? false,
    showJoinMelioButton: isMelio,
    showLoginHeaderSection: isEmailLoginEnabled ?? false,
    emailVerificationReturnUrl: '../accept',
  };
};

export const AddDeliveryMethodByPayeeActivityConfigContext = createContext<ActivityConfig>({} as ActivityConfig);
export const useActivityContext = () => useContext<ActivityConfig>(AddDeliveryMethodByPayeeActivityConfigContext);
