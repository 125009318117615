/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { getPartnerLocalStorage, LocalStorageKeys } from '@melio/local-storage';
import { PartnerName } from '@melio/platform-api';

import { usePartnerConfig } from '../../partners/usePartnerConfig';
const localStorage = getPartnerLocalStorage({ partnerName: PartnerName.Sbb });

export const useSbbLogic = () => {
  const {
    partnerConfig: { config },
  } = usePartnerConfig();
  const backButtonReferenceId = localStorage.getItem(LocalStorageKeys.backButtonReferenceId);

  const generateSbbBackUrl = React.useCallback(() => {
    const { backButtonUrl, backButtonUrlFallback } = config.links || {};
    const url =
      backButtonUrl && backButtonReferenceId
        ? backButtonUrl.replace(`{BACK_BUTTON_REF_ID}`, encodeURIComponent(decodeURIComponent(backButtonReferenceId)))
        : backButtonUrlFallback;

    if (!url) {
      throw new Error('could not generate back to partner url');
    }

    return url;
  }, [config]);

  return { generateSbbBackUrl };
};
