import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';

export const BankMissing: React.VFC = () => (
  <LazyAnimation
    getAnimationData={() => import('./assets/bank-missing.lottie')}
    id="bank-missing"
    width="100%"
    height="100%"
  />
);
