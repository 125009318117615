import { atom, selector, useSetRecoilState } from 'recoil';

import { VendorActionsTypes } from '@/store/VendorActions/VendorActions.types';

export const VendorActionState = atom<VendorActionsTypes>({
  key: 'VendorActionState',
  default: {
    paymentId: '',
    vendorId: '',
    accountName: '',
    accountEmail: '',
    unilateralRequestId: '',
    virtualCardId: '',
    onboardingSessionUuid: '',
  },
});

export const vendorActionPaymentIdSelector = selector<string>({
  key: 'vendorActionPaymentIdSelector',
  get: ({ get }) => {
    const vendorAction = get(VendorActionState);
    return vendorAction.paymentId;
  },
});

export const useVendorActionPaymentId = () => {
  const setPaymentId = useSetRecoilState(VendorActionState);
  return (paymentId: string) =>
    setPaymentId((state) => {
      return { ...state, paymentId };
    });
};

export const vendorActionVendorIdSelector = selector<string>({
  key: 'vendorActionVendorIdSelector',
  get: ({ get }) => {
    const vendorAction = get(VendorActionState);
    return vendorAction.vendorId;
  },
});

export const useVendorActionVendorId = () => {
  const setVendorId = useSetRecoilState(VendorActionState);
  return (vendorId: string) =>
    setVendorId((state) => {
      return { ...state, vendorId };
    });
};

export const vendorActionAccountNameSelector = selector<string>({
  key: 'vendorActionAccountNameSelector',
  get: ({ get }) => {
    const vendorAction = get(VendorActionState);
    return vendorAction.accountName;
  },
});
export const vendorActionAccountEmailSelector = selector<string>({
  key: 'vendorActionAccountEmailSelector',
  get: ({ get }) => {
    const vendorAction = get(VendorActionState);
    return vendorAction.accountEmail;
  },
});

export const useVendorActionAccountName = () => {
  const setAccountName = useSetRecoilState(VendorActionState);
  return (accountName: string) =>
    setAccountName((state) => {
      return { ...state, accountName };
    });
};
export const useVendorActionAccountEmail = () => {
  const setAccountEmail = useSetRecoilState(VendorActionState);
  return (accountEmail: string) =>
    setAccountEmail((state) => {
      return { ...state, accountEmail };
    });
};

export const vendorActionUnilateralRequestIdSelector = selector<string>({
  key: 'vendorActionUnilateralRequestIdSelector',
  get: ({ get }) => {
    const vendorAction = get(VendorActionState);
    return vendorAction.unilateralRequestId;
  },
});

export const useVendorActionUnilateralRequestId = () => {
  const setUnilateralRequestId = useSetRecoilState(VendorActionState);
  return (unilateralRequestId: string) =>
    setUnilateralRequestId((state) => {
      return { ...state, unilateralRequestId };
    });
};

export const vendorActionVirtualCardIdSelector = selector<string>({
  key: 'vendorActionVirtualCardIdSelector',
  get: ({ get }) => {
    const vendorAction = get(VendorActionState);
    return vendorAction.virtualCardId;
  },
});

export const useVendorActionVirtualCardId = () => {
  const setVirtualCardId = useSetRecoilState(VendorActionState);
  return (virtualCardId: string) =>
    setVirtualCardId((state) => {
      return { ...state, virtualCardId };
    });
};
