/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, EffectCallback, useEffect } from 'react';

import { useIsFirstRender } from './useIsFirstRender';

export function useUpdateEffect(effect: EffectCallback, deps: DependencyList) {
  const isFirst = useIsFirstRender();
  useEffect(() => {
    if (!isFirst) {
      effect();
    }
  }, deps);
}
