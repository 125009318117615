import { ExternalLayout } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BankAccountDeliveryMethod,
  DataToPrefillAnOnboardingFormBankDetails,
  DeliveryMethodType,
  useOrganizationDeliveryMethods,
  useVexMyOrganization,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import {
  AddBankAccountFormCardWidget,
  AddBankAccountFormCardWidgetProps,
  CollapsibleCardFormWidget,
  ConfirmBankAccountCardWidget,
} from '@melio/vex-widgets';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { isPlaidAccount } from '../../shared';

type DefaultBankAccountDetails = {
  accountNumber: string;
  routingNumber: string;
};

export type AddOrConfirmBankAccountCardActivityProps = {
  isOpened?: boolean;
  isDisabled?: boolean;
  isFilled: boolean;
  defaultBankAccountDetails?: DefaultBankAccountDetails;
  onOpen: () => void;
  setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
  onBankAccountCreatedOrConfirmed: () => void;
  prePopulatedBankDetails: DataToPrefillAnOnboardingFormBankDetails;
};

enum BankAccountCardState {
  ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT',
  CONFIRM_BANK_ACCOUNT = 'CONFIRM_BANK_ACCOUNT',
}

export const AddOrConfirmBankAccountCardActivity = withAnalyticsContext<AddOrConfirmBankAccountCardActivityProps>(
  forwardRef(
    (
      {
        onOpen,
        setDirtyStatus,
        isFilled,
        isOpened,
        isDisabled,
        defaultBankAccountDetails,
        prePopulatedBankDetails,
        onBankAccountCreatedOrConfirmed,
        setAnalyticsProperties,
      },
      ref
    ) => {
      const { formatMessage } = useMelioIntl();
      const { track } = useAnalytics();
      const { data: organizationData, isFetching: isLoadingOrganizationData } = useVexMyOrganization({
        enabled: isOpened,
      });
      const organizationId = organizationData?.id;
      setAnalyticsProperties({
        PageName: 'link-your-bank-account',
      });

      const {
        data: organizationDeliveryMethods,
        isCreating: isCreatingDeliveryMethod,
        isLoading: isLoadingDeliveryMethods,
        create: createVendorOrganizationDeliveryMethod,
        refetch: refetchOrganizationDeliveryMethods,
      } = useOrganizationDeliveryMethods({ organizationId, options: { enabled: !!organizationId && isOpened } });

      const [cardState, setCardState] = useState<BankAccountCardState>();

      const existingBankAccountDeliveryMethod = organizationDeliveryMethods?.find(
        ({ type }) => type === DeliveryMethodType.BankAccount
      ) as BankAccountDeliveryMethod | undefined;

      const hasExistingBankAccountDm = !!existingBankAccountDeliveryMethod;

      const hasExistingBankAccountDmOrDefaultDetails = hasExistingBankAccountDm || !!defaultBankAccountDetails;

      const isPopulated = hasExistingBankAccountDm || !isEmpty(prePopulatedBankDetails);

      useEffect(() => {
        setAnalyticsProperties({ IsPopulated: isPopulated });
      }, [isPopulated, setAnalyticsProperties]);

      useEffect(() => {
        setCardState(
          hasExistingBankAccountDmOrDefaultDetails
            ? BankAccountCardState.CONFIRM_BANK_ACCOUNT
            : BankAccountCardState.ADD_BANK_ACCOUNT
        );
      }, [hasExistingBankAccountDmOrDefaultDetails]);

      const onAddBankAccount: AddBankAccountFormCardWidgetProps['onSubmit'] = async ({
        routingNumber,
        accountNumber,
      }) => {
        await createVendorOrganizationDeliveryMethod({ accountNumber, routingNumber });
        await refetchOrganizationDeliveryMethods();
        onBankAccountCreatedOrConfirmed();
        setCardState(BankAccountCardState.CONFIRM_BANK_ACCOUNT);
      };

      const onConfirmBankAccount = async () => {
        const shouldCreateNewBankAccountUsingDefaultDetails = !hasExistingBankAccountDm;

        if (shouldCreateNewBankAccountUsingDefaultDetails) {
          try {
            const { accountNumber, routingNumber } = defaultBankAccountDetails as DefaultBankAccountDetails;
            await createVendorOrganizationDeliveryMethod({ accountNumber, routingNumber });
          } catch (error) {
            track('Organization', 'Click', { ErrorType: 'general-error', Status: 'failure' });
            return;
          }
        }
        track('Organization', 'Click', { Cta: 'continue' });
        onBankAccountCreatedOrConfirmed();
      };

      const isLoading = isLoadingOrganizationData || isLoadingDeliveryMethods;

      const onValidationError = (isRequiredError: boolean) => {
        track('Connection', 'Status', {
          ErrorType: `${isRequiredError ? 'missing' : 'invalid'}-bank-details`,
          Status: 'failure',
        });
      };

      const getCardContent = () => {
        if (cardState === BankAccountCardState.CONFIRM_BANK_ACCOUNT) {
          const existingBankAccountDetails = existingBankAccountDeliveryMethod?.details;
          const { accountNumber, routingNumber } =
            existingBankAccountDetails ?? (defaultBankAccountDetails as DefaultBankAccountDetails);

          const handleBankAccountChange = () => {
            setCardState(BankAccountCardState.ADD_BANK_ACCOUNT);
            track('Organization', 'Click', {
              Cta: 'change-account',
            });
          };

          return (
            <ConfirmBankAccountCardWidget
              isPlaidAccount={isPlaidAccount(existingBankAccountDeliveryMethod)}
              accountNumber={accountNumber}
              routingNumber={routingNumber}
              onUpdateBankAccountClick={handleBankAccountChange}
              onSubmit={onConfirmBankAccount}
              isSaving={isCreatingDeliveryMethod}
            />
          );
        }

        return (
          <AddBankAccountFormCardWidget
            onValidationError={onValidationError}
            isDisabled={false}
            isSaving={isCreatingDeliveryMethod}
            setDirtyStatus={setDirtyStatus}
            onCancel={
              hasExistingBankAccountDmOrDefaultDetails
                ? () => setCardState(BankAccountCardState.CONFIRM_BANK_ACCOUNT)
                : undefined
            }
            onSubmit={onAddBankAccount}
            existingBankAccountDetails={
              hasExistingBankAccountDm
                ? {
                    isPlaidAccount: isPlaidAccount(existingBankAccountDeliveryMethod),
                    accountNumber: existingBankAccountDeliveryMethod.details.accountNumber,
                  }
                : undefined
            }
            prePopulatedBankDetails={
              prePopulatedBankDetails && !hasExistingBankAccountDm
                ? {
                    accountNumber: prePopulatedBankDetails.accountNumber || '',
                    routingNumber: prePopulatedBankDetails.routingNumber,
                  }
                : undefined
            }
          />
        );
      };

      useEffect(() => {
        if (isOpened && !!organizationId) {
          track('Organization', 'View');
        }
      }, [organizationId, isOpened, track]);

      return (
        <CollapsibleCardFormWidget
          title={formatMessage('vex.activities.addOrConfirmBankAccountCard.title')}
          subtitle={formatMessage('vex.activities.addOrConfirmBankAccountCard.subtitle')}
          isOpened={isOpened}
          isFilled={isFilled}
          isDisabled={isDisabled}
          onOpen={onOpen}
          ref={ref}
        >
          {isLoading ? <ExternalLayout isLoading /> : getCardContent()}
        </CollapsibleCardFormWidget>
      );
    }
  )
);

AddOrConfirmBankAccountCardActivity.displayName = 'AddOrConfirmBankAccountCardActivity';
