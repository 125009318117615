import { useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import { AccountsApiClient, Expand } from '@melio/platform-api-axios-client';

import { getMissingUserLegalInfoFields } from './helpers';
import { Account, AccountWithBillsAndPaymentsOverview, AccountWithLogoUrl } from './types';

type QueryFn = typeof AccountsApiClient.getAccountsAccountId;
type UpdateFn = typeof AccountsApiClient.patchAccountsAccountId;
type CreateFn = typeof AccountsApiClient.postAccounts;

export type UseAccountProps<T extends Account = Account, TExpand extends Expand = never> = UseModelApiProps<
  QueryFn,
  T
> &
  Optional<TExpand, { params: { expand: TExpand } }> & {
    onUpdate?: (data: T) => void;
    onUpdateError?: ErrorFunction;
  };

const useExpandedAccount = <T extends Account = Account, TExpand extends Expand = never>({
  params,
  ...props
}: UseAccountProps<T, TExpand>) => {
  const query = useModelApi<QueryFn, UpdateFn, never, CreateFn, T>({
    ...props,
    queryKey: ['AccountsApi', params],
    queryFn: (id: string) => AccountsApiClient.getAccountsAccountId(id, params?.expand),
    createFn: AccountsApiClient.postAccounts,
    updateFn: AccountsApiClient.patchAccountsAccountId,
  });

  const { invalidateQueries } = useMelioQueryClient();
  const invalidateQuery = ({ id }: { id: string }) => invalidateQueries('AccountsApi', 'model', id);

  return {
    ...query,
    missingUserLegalInfoFields: query.isFetched && query.data ? getMissingUserLegalInfoFields(query.data) : undefined,
    invalidateQuery,
  };
};

export type AccountModel<T extends Account = Account, TExpand extends Expand = never> = ReturnType<
  typeof useExpandedAccount<T, TExpand>
>;

export const useAccount = (props: UseAccountProps) => useExpandedAccount(props);

export const useAccountWithLogoUrl = (props: UseAccountProps<AccountWithLogoUrl>) =>
  useExpandedAccount<AccountWithLogoUrl, 'logoUrl'>({ ...props, params: { expand: 'logoUrl' } });

export const useAccountWithTaxIdentifier = (props: UseAccountProps<AccountWithLogoUrl>) =>
  useExpandedAccount<AccountWithLogoUrl, 'taxId'>({ ...props, params: { expand: 'taxId' } });

export const useAccountWithBillsAndPaymentsOverview = (props: UseAccountProps<AccountWithBillsAndPaymentsOverview>) =>
  useExpandedAccount<AccountWithBillsAndPaymentsOverview, 'billsAndPaymentsOverview'>({
    ...props,
    params: { expand: 'billsAndPaymentsOverview' },
  });
