import { useEffect, useMemo } from 'react';
import { LocalStorageKeys } from '@melio/local-storage';
import { PartnerName } from '@melio/platform-api';
import { useInitWizard } from '@melio/platform-utils';

import { useInitPartner } from '@/hooks/partners';
import {
  getPartnerNameFromHost,
  getPartnerNameFromLocalStorage,
  getPartnerNameFromPath,
  getPartnerNameFromUrlParams,
} from '@/utils/partner.utils';

export const usePartnerAppInit = (): PartnerName => {
  const { partnerName, basePath } = useMemo(() => {
    const partnerNameFromHost = getPartnerNameFromHost();
    const partnerNameFromPath = getPartnerNameFromPath();
    const urlParams = getPartnerNameFromUrlParams();
    const partnerNameFromUrlParams = urlParams?.partnerName;
    const basePathFromUrlParams = urlParams?.basePath;
    const partnerNameFromLocalStorage = getPartnerNameFromLocalStorage();

    const calculatedPartnerName =
      partnerNameFromPath || partnerNameFromHost || partnerNameFromUrlParams || partnerNameFromLocalStorage;

    const existingBasePath = window.sessionStorage.getItem(LocalStorageKeys.basePath) || '';

    const newBasePath = basePathFromUrlParams || partnerNameFromPath || existingBasePath || '';

    return { basePath: newBasePath, partnerName: calculatedPartnerName };
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem(LocalStorageKeys.basePath, basePath);
  }, [basePath]);

  useEffect(() => {
    window.sessionStorage.setItem(LocalStorageKeys.partnerName, partnerName);
  }, [partnerName]);

  useInitWizard();
  useInitPartner(partnerName!, basePath);

  return partnerName;
};
