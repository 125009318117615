import { Box, Flex } from '@chakra-ui/react';
import {
  BaseModal,
  BaseModalProps,
  Button,
  Container,
  Group,
  ModalProps,
  Text,
  useBreakpointValue,
} from '@melio/penny';
import React from 'react';

export type ModelCopiedFromPennyProps = BaseModalProps & {
  asset: React.ReactNode;
  assetAspectRatio?: string;
  header?: string;
  description?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  primaryButton: ModalProps['primaryButton'];
  secondaryButton?: ModalProps['secondaryButton'];
};

export const ModalCopiedFromPenny = ({
  header,
  description,
  primaryButton,
  secondaryButton,
  children,
  footer,
  asset,
  assetAspectRatio = '7 / 3',
  ...props
}: ModelCopiedFromPennyProps) => {
  const paddingX = useBreakpointValue<'m' | 'xl'>({ xs: 'm', s: 'xl' });
  const footerAlignment = useBreakpointValue<'vertical' | 'horizontal'>({ xs: 'vertical', s: 'horizontal' });
  const fullWithButton = useBreakpointValue<boolean>({ xs: true, s: false });

  return (
    <BaseModal data-component="PromotionalModal" {...props}>
      <Box overflowY="auto">
        <Flex
          flexDirection="column"
          justifyContent="center"
          gap="xl"
          paddingBottom="s"
          role="heading"
          data-component="PromotionalModalHeader"
          aria-level={1}
        >
          <Box
            borderTopRadius="l"
            display="block"
            justifyContent="center"
            width="100%"
            height="fit-content"
            maxHeight="560px"
            data-aspect-ratio={assetAspectRatio}
          >
            {asset}
          </Box>
          <Container paddingX={paddingX}>
            <Group variant="vertical">
              {header && (
                <Text data-testid="modal-promotional-header" as="h2" textStyle="heading2Semi">
                  {header}
                </Text>
              )}
              {description && <Text>{description}</Text>}
            </Group>
          </Container>
        </Flex>
        <BaseModal.Body>{children}</BaseModal.Body>
      </Box>
      <BaseModal.Footer {...{ paddingX }}>
        <Group variant="vertical" spacing="m">
          <Group spacing="s" variant={footerAlignment} justifyContent="flex-end">
            {secondaryButton && (
              <Button
                {...secondaryButton}
                data-testid="modal-btn-secondary"
                isFullWidth={fullWithButton}
                size="medium"
              />
            )}
            {primaryButton && (
              <Button {...primaryButton} data-testid="modal-btn-primary" isFullWidth={fullWithButton} size="medium" />
            )}
          </Group>
          {footer}
        </Group>
      </BaseModal.Footer>
    </BaseModal>
  );
};
