import { Button, Container, Group, GroupProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

import { PCIComplianceInfo } from '../../PCIComplianceInfo';
import { TermsAndPolicyDisclaimerWidget } from '../../TermsAndPolicyDisclaimer';

export type ExternalLayoutContentWidgetContinueSectionProps = {
  buttonProps?: { onClick?: () => Promise<void> | void; isDisabled?: boolean; isLoading?: boolean; label: string };
  displayLegalInfo?: boolean;
  legalInfoOverride?: string;
  termsAndPolicyDisclaimer?: ReactNode;
  spacing?: GroupProps['spacing'];
};

export const ContinueSection = forwardRef<ExternalLayoutContentWidgetContinueSectionProps, 'div'>(
  (
    {
      buttonProps,
      legalInfoOverride,
      displayLegalInfo = true,
      termsAndPolicyDisclaimer = <TermsAndPolicyDisclaimerWidget />,
      spacing,
      ...props
    },
    ref
  ) => (
    <Container
      data-component="ExternalLayoutContent.ContinueSection"
      {...props}
      paddingX="xl"
      paddingTop="xs"
      paddingBottom="l"
      ref={ref}
    >
      <Group variant="vertical" spacing={spacing}>
        {buttonProps && <Button {...buttonProps} data-testid="layout-next-button" size="large" isFullWidth />}
        {displayLegalInfo && (
          <Group variant="vertical" spacing="m">
            {termsAndPolicyDisclaimer}
            <PCIComplianceInfo legalInfoOverride={legalInfoOverride} />
          </Group>
        )}
      </Group>
    </Container>
  )
);

ContinueSection.displayName = 'ExternalLayoutContentWidget.ContinueSection';
