import {
  BankAccountDeliveryMethod,
  DeliveryMethodType,
  PaperCheckDeliveryMethod,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { type MessageKey, useMelioIntl } from '@melio/platform-i18n';

type UseAcceptPaymentSuccessLabelsProps = {
  paymentAmount: number | undefined;
  payorName: string | undefined;
  paymentInvoiceNumber: string | undefined;
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod | VirtualCardAccountDeliveryMethod | undefined;
};

type AcceptPaymentSuccessLabels = {
  title: string;
  description: string;
};

export const useAcceptPaymentSuccessLabels = ({
  paymentAmount,
  payorName,
  paymentInvoiceNumber,
  deliveryMethod,
}: UseAcceptPaymentSuccessLabelsProps): AcceptPaymentSuccessLabels | undefined => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  if (!paymentAmount || !payorName || !deliveryMethod) {
    return undefined;
  }

  const isAcceptedWithVirtualCardDM = deliveryMethod.type === DeliveryMethodType.VirtualCard;

  const virtualCardDescriptionKey: MessageKey =
    'vex.activities.unilateral.paymentAcceptedSuccessfully.virtualCard.description';
  const defaultDescriptionKey: MessageKey = 'vex.activities.unilateral.paymentAcceptedSuccessfully.default.description';

  const description = formatMessage(isAcceptedWithVirtualCardDM ? virtualCardDescriptionKey : defaultDescriptionKey, {
    paymentAmount: formatCurrency(paymentAmount),
    payorName,
    paymentInvoiceNumber,
  });

  return {
    title: formatMessage('vex.activities.unilateral.paymentAcceptedSuccessfully.title'),
    description,
  };
};
