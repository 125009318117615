import { BrandSymbolKey, IconKey } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  PaperCheckDeliveryMethod,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import {
  getDeliveryMethodIconKey,
  useGetDeliveryMethodDescription,
  useGetDeliveryMethodLabel,
} from '@melio/vex-domain';

import { PaymentReviewLineItem } from '../PaymentReviewLineItem';

type BankOrCheckOrVCDeliveryMethod =
  | BankAccountDeliveryMethod
  | PaperCheckDeliveryMethod
  | VirtualCardAccountDeliveryMethod;

type BankOrCheckOrVCDeliveryMethodType = BankOrCheckOrVCDeliveryMethod['type'];

export type DeliveryMethodListItemWidgetProps = {
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod | VirtualCardAccountDeliveryMethod;
  children?: never;
};

const iconType = (type: BankOrCheckOrVCDeliveryMethodType): IconKey | BrandSymbolKey => getDeliveryMethodIconKey(type);

export const DeliveryMethodListItemWidget = forwardRef<DeliveryMethodListItemWidgetProps, 'div'>(
  ({ deliveryMethod, ...props }, ref) => (
    <PaymentReviewLineItem
      data-component="DeliveryMethodListItemWidget"
      labelProps={{ label: useGetDeliveryMethodLabel(deliveryMethod.type) }}
      mainLabelProps={{ label: useGetDeliveryMethodDescription(deliveryMethod) }}
      icon={{ type: iconType(deliveryMethod.type) }}
      contentTestId="delivery-method-list-item-by-payee"
      data-testid={`${deliveryMethod.type}-delivery-method-list-item-by-payee`}
      {...props}
      ref={ref}
    />
  )
);

DeliveryMethodListItemWidget.displayName = 'DeliveryMethodListItemWidget';
