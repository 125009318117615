import { useSearchParams } from 'react-router-dom';

const UTM_QUERY_PARAMS = {
  utmSource: 'utm_source',
  utmCampaign: 'utm_campaign',
  utmMedium: 'utm_medium',
  utmIdentifier: 'utm_identifier',
};

export const useUtmParameters = () => {
  const [searchParams] = useSearchParams();
  const utmMedium = searchParams.get(UTM_QUERY_PARAMS.utmMedium);
  const utmCampaign = searchParams.get(UTM_QUERY_PARAMS.utmCampaign);
  const utmIdentifier = searchParams.get(UTM_QUERY_PARAMS.utmIdentifier);
  const utmSource = searchParams.get(UTM_QUERY_PARAMS.utmSource);

  return {
    utmSource,
    utmCampaign,
    utmMedium,
    utmIdentifier,
  };
};
