/* eslint-disable react-hooks/exhaustive-deps */
import { ExternalLayout, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaperCheckDeliveryMethod, useDeliveryMethods, usePayment } from '@melio/platform-api';
import { createAddressLabel, useAddressAutocomplete } from '@melio/vex-widgets';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { CheckAccountFormModel } from '../../../types';
import { useActivityContext } from '../AddDeliveryMethodByPayee/AddDeliveryMethodByPayee.config';
import { DeliveryMethodAddedSuccessfullyScreen } from '../screens';
import {
  AddCheckAddressManuallyScreen,
  AddCheckDeliveryMethodScreen,
  AddressSuggestionsScreen,
  InvalidAddressConfirmationScreen,
} from './screens';
import {
  AddCheckDeliveryMethodByPayeeActivityProps,
  CheckDetailsWithAddressSuggestions,
  CheckDetailsWithAddressSuggestionsKey,
} from './types';
import { useAddCheckDeliveryMethodByPayeeStep } from './useAddCheckDeliveryMethodByPayeeStep';

export const AddCheckDeliveryMethodByPayeeActivity: React.VFC<AddCheckDeliveryMethodByPayeeActivityProps> = ({
  paymentId,
  accountName,
  paymentNote,
  paymentInvoiceNumber,
  onBack: onFirstStepBack,
  onError,
  onDone,
  userName,
}) => {
  const { data: payment, refetch: refetchPayment, isLoading: isLoadingPayment } = usePayment({ id: paymentId });
  const { create: createVendorDeliveryMethod, isMutating: isCreatingDeliveryMethod } = useDeliveryMethods({
    vendorId: payment?.vendorId,
    enabled: false,
  });
  const { toast } = useToast();
  const [newDeliveryMethod, setNewDeliveryMethod] = useState<PaperCheckDeliveryMethod>();
  const { track } = useAnalytics();
  const isLoggedIn = !!userName;
  const [checkDetailsWithAddressSuggestions, setCheckDetailsWithAddressSuggestions] =
    useState<CheckDetailsWithAddressSuggestions>({
      checkDetailsWithSuggestedAddress: undefined,
      checkDetailsWithOriginalAddress: { line1: '', line2: '', city: '', state: '', postalCode: '', printName: '' },
    });

  const { currentStep, goToStep, goToPreviousStep, goToManuallyAddressFormStep } = useAddCheckDeliveryMethodByPayeeStep(
    { onFirstStepBack }
  );

  const { addressAutocomplete } = useAddressAutocomplete();

  const {
    showPoweredByMelio,
    showNewAddressFlow,
    showLoginHeaderSection,
    showJoinMelioButton,
    emailVerificationReturnUrl,
  } = useActivityContext();

  useEffect(() => {
    if (newDeliveryMethod && payment?.deliveryMethodId === newDeliveryMethod?.id) {
      goToStep('CHECK_DETAILS_ADDED_SUCCESSFULLY');
    }
  }, [payment?.deliveryMethodId, newDeliveryMethod?.id]);

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const createDeliveryMethod = (data: CheckAccountFormModel) => {
    const { printName, ...address } = data;

    createVendorDeliveryMethod({
      type: 'paper-check',
      details: {
        printName,
        address,
      },
    })
      .then((deliveryMethod) => {
        setNewDeliveryMethod(deliveryMethod as PaperCheckDeliveryMethod);
        /* As a side effect of setting a new delivery method, the payment entity is updated with the new one.
        so we refetch the payment to get the new data */
        refetchPayment().catch(handleFail);
        track('UnilateralCreateCheckDeliveryMethod', 'Submitted', { intent: 'Accept Payment' });
        onDone(deliveryMethod.id);
      })
      .catch(handleFail);
  };

  const handleManuallyAddressVerification = (checkDetailsWithOriginalAddress: CheckAccountFormModel) => {
    const { printName, line2, ...originalAddress } = checkDetailsWithOriginalAddress;
    const addressQuery = createAddressLabel(originalAddress);
    addressAutocomplete(addressQuery)
      .then(([addressSuggestions]) => {
        const getAddressSuggestions = () => {
          if (!addressSuggestions) {
            return undefined;
          }
          return {
            printName,
            ...addressSuggestions,
            line2,
          };
        };
        setCheckDetailsWithAddressSuggestions({
          checkDetailsWithSuggestedAddress: getAddressSuggestions(),
          checkDetailsWithOriginalAddress,
        });
        if (isEqual(checkDetailsWithOriginalAddress, getAddressSuggestions())) {
          return createDeliveryMethod(checkDetailsWithOriginalAddress);
        }
        getAddressSuggestions() ? goToStep('ADDRESS_SUGGESTIONS') : goToStep('INVALID_ADDRESS_CONFIRMATION');
      })
      .catch(handleFail);
  };
  const handleOnBackClicked = () => {
    track('UnilateralAddCheckDeliveryMethod', 'Click', { intent: 'Go Back' });
    goToPreviousStep();
  };
  const handleAddressNotFound = () => {
    track('UnilateralAddCheckDeliveryMethod', 'Click', { intent: "Can't find address" });
    goToManuallyAddressFormStep();
  };
  const createDeliveryMethodWithOriginalAddress = () => {
    track('UnilateralInvalidAddressConfirmation', 'Click', { intent: 'Continue with unverified address' });
    createDeliveryMethod(checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress);
  };
  const createDeliveryMethodWithSelectedAddress = ({ key }: CheckDetailsWithAddressSuggestionsKey) => {
    track('UnilateralAddressSuggestions', 'Chose', { intent: 'Confirm Address' });
    createDeliveryMethod(checkDetailsWithAddressSuggestions[key] as CheckAccountFormModel);
  };
  const onEditAddressClicked = () => {
    track('UnilateralInvalidAddressConfirmation', 'Click', { intent: 'Edit Address' });
    goToManuallyAddressFormStep();
  };

  if ((isLoadingPayment && !newDeliveryMethod) || !payment) {
    return <ExternalLayout isLoading />;
  }
  switch (currentStep) {
    case 'ADD_CHECK_ADDRESS_MANUALLY':
      return (
        <AddCheckAddressManuallyScreen
          addressValues={checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress}
          accountName={accountName}
          paymentAmount={payment.amount}
          paymentInvoiceNumber={paymentInvoiceNumber}
          paymentNote={paymentNote}
          onBack={handleOnBackClicked}
          onDone={handleManuallyAddressVerification}
          isSaving={isCreatingDeliveryMethod || isLoadingPayment}
          showPoweredByMelio={showPoweredByMelio}
        />
      );
    case 'ADDRESS_SUGGESTIONS':
      return (
        <AddressSuggestionsScreen
          checkDetailsWithAddressSuggestions={checkDetailsWithAddressSuggestions}
          accountName={accountName}
          paymentAmount={payment.amount}
          onBack={handleOnBackClicked}
          onDone={createDeliveryMethodWithSelectedAddress}
          onEditAddressClicked={onEditAddressClicked}
          isSaving={isCreatingDeliveryMethod || isLoadingPayment}
          showPoweredByMelio={showPoweredByMelio}
        />
      );
    case 'INVALID_ADDRESS_CONFIRMATION':
      return (
        <InvalidAddressConfirmationScreen
          isLoading={isCreatingDeliveryMethod}
          accountName={accountName}
          checkDetailsWithOriginalAddress={checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress}
          onBack={handleOnBackClicked}
          onDone={createDeliveryMethodWithOriginalAddress}
          onEditAddressClicked={onEditAddressClicked}
          showPoweredByMelio={showPoweredByMelio}
        />
      );
    case 'CHECK_DETAILS_ADDED_SUCCESSFULLY':
      return (
        <DeliveryMethodAddedSuccessfullyScreen
          accountName={accountName}
          paymentInvoiceNumber={paymentInvoiceNumber}
          showJoinMelioButton={!isLoggedIn && showJoinMelioButton}
          isPaperCheck
          paymentAmount={payment.amount}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          deliveryMethod={newDeliveryMethod!}
          estimatedDelivery={payment.estimatedDelivery}
          maxEstimatedDelivery={payment.maxEstimatedDelivery}
          showLoginHeaderSection={showLoginHeaderSection}
          showPoweredByMelio={showPoweredByMelio}
          userName={userName}
        />
      );
    case 'ADD_CHECK_DETAILS':
    default:
      return (
        <AddCheckDeliveryMethodScreen
          onAddressNotFound={handleAddressNotFound}
          showNewAddressFlow={showNewAddressFlow}
          accountName={accountName}
          paymentNote={paymentNote}
          paymentInvoiceNumber={paymentInvoiceNumber}
          paymentAmount={payment.amount}
          onBack={handleOnBackClicked}
          onDone={createDeliveryMethod}
          isSaving={isCreatingDeliveryMethod || isLoadingPayment}
          showPoweredByMelio={showPoweredByMelio}
          showLoginHeaderSection={showLoginHeaderSection}
          emailVerificationReturnUrl={emailVerificationReturnUrl}
          userName={userName}
        />
      );
  }
};
