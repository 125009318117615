import { Box, usePrevious } from '@chakra-ui/react';
import { masks, useMtlMessages } from '@melio/ap-domain';
import { Form, SelectOption, Text, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Account, Industry, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { FormWidgetProps } from '../../types';
import { getFilledFields } from '../../utils';
import { AddressSearchWidget, IndustryTypeSelectWidget } from '../form-controls';
import { BusinessTypeAndTaxInfoWidget } from './components/BusinessTypeAndTaxInfo.widget';
import { CompleteRequiredDetailsFormFields, MissingField, MissingFieldsMap, TrackClickType } from './types';
import { useCompleteRequiredDetailsValidationSchema } from './useCompleteRequiredDetailsValidationSchema';

type CompleteRequiredDetailsProps = FormWidgetProps<CompleteRequiredDetailsFormFields> & {
  missingFields: MissingField[];
  isLoading: boolean;
  account: Account;
  ctaLabel: string;
  trackClick: TrackClickType;
};

export const CompleteRequiredDetailsForm = ({
  missingFields,
  onSubmit,
  isLoading,
  isSaving,
  onSubmissionStateChange,
  trackClick,
  account: { company },
  ctaLabel,
}: CompleteRequiredDetailsProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { businessType, taxInfo } = company;
  const missingFieldsMap = Object.fromEntries(missingFields.map((field) => [field.name, field])) as MissingFieldsMap;

  const defaultValues: Partial<CompleteRequiredDetailsFormFields> = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    companyName: '',
    businessType: missingFieldsMap.taxInfoIdentifier ? businessType : undefined,
    taxInfoType: taxInfo?.type ?? TaxIdTypeEnum.Ein,
    existingTaxIdIsEin: taxInfo?.type === 'EIN' ? 'Yes' : 'No',
    taxInfoIdentifier: '',
    legalCompanyName: '',
    taxIdEinOverride: '',
    contactLastName: '',
    contactFirstName: '',
    phoneNumber: '',
    industry: null,
  };

  const useFormResult = useMelioForm<CompleteRequiredDetailsFormFields>({
    onSubmit,
    onSubmissionStateChange,
    schema: useCompleteRequiredDetailsValidationSchema({ missingFields: missingFieldsMap }),
    isLoading,
    isSaving,
    defaultValues,
  });
  const { formProps, registerField, setValue, trigger } = useFormResult;
  const prevSubmitCount = usePrevious(useFormResult.formState.submitCount);

  useEffect(() => {
    if (prevSubmitCount !== useFormResult.formState.submitCount) {
      if (Object.keys(useFormResult.formState.errors).length) {
        const data = useFormResult.getValues();
        const { filledFields, numFilledOrgFields, numFilledUserFields } = getFilledFields(missingFields, data);

        const failureReasons = Object.entries(useFormResult.formState.errors).reduce(
          (acc: Record<string, string | undefined>, [key, value]) => {
            acc[key] = value.message;
            return acc;
          },
          {}
        );

        track('Organization', 'Status', {
          Intent: 'submit-details',
          NumFilledUserFields: numFilledUserFields,
          NumFilledOrgFields: numFilledOrgFields,
          FilledFields: filledFields,
          Cta: ctaLabel,
          Status: 'Failure',
          FailureReasons: failureReasons,
        });
      }
    }
  }, [
    useFormResult,
    useFormResult.formState.submitCount,
    useFormResult.formState.errors,
    useFormResult.formState.dirtyFields,
    prevSubmitCount,
    ctaLabel,
    track,
    missingFields,
  ]);

  const {
    labels: { user: userLabels, company: companyLabels },
    placeholders,
  } = useMtlMessages();

  const handleIndustryCreatable = (option: SelectOption) => {
    const { name, naicsCode } = option.value as unknown as Industry;
    setValue('industry', { name, naicsCode: naicsCode || 999999 });
    void trigger('industry');
  };

  const shouldShowTitles = new Set(missingFields.map(({ entity }) => entity)).size > 1;

  return (
    <Form
      data-component="CompleteRequiredDetailsFormWidgetForm"
      data-testid="complete-required-details-form"
      {...formProps}
      columns={2}
    >
      {shouldShowTitles && (
        <Form.ContentBox colSpan={2}>
          <Box marginTop={4}>
            <Text textStyle="body4Semi">
              {formatMessage('widgets.completeRequiredDetailsModal.stage.personal-details.title')}
            </Text>
          </Box>
        </Form.ContentBox>
      )}
      <Form.TextField
        {...registerField('firstName')}
        isHidden={!missingFieldsMap['firstName']}
        labelProps={{ label: userLabels.firstName }}
        placeholder={placeholders.personalFirstName}
        colSpan={!missingFieldsMap['lastName'] ? 2 : 1}
        isRequired={missingFieldsMap['firstName']?.isRequired}
      />
      <Form.TextField
        {...registerField('lastName')}
        isHidden={!missingFieldsMap['lastName']}
        labelProps={{ label: userLabels.lastName }}
        placeholder={placeholders.personalLastName}
        colSpan={!missingFieldsMap['firstName'] ? 2 : 1}
        isRequired={missingFieldsMap['lastName']?.isRequired}
      />
      <Form.TextField
        {...registerField('dateOfBirth')}
        isHidden={!missingFieldsMap['dateOfBirth']}
        maskProps={{
          mask: masks.dateOfBirth,
          keepCharPositions: false,
          guide: true,
          placeholderChar: ' ',
        }}
        labelProps={{ label: userLabels.dateOfBirth }}
        placeholder={placeholders.dateOfBirth}
        colSpan={2}
        isRequired={missingFieldsMap['dateOfBirth']?.isRequired}
        onClick={() => trackClick('submit-details', 'insert-dob')}
      />

      {shouldShowTitles && (
        <Form.ContentBox colSpan={2}>
          <Box marginTop={4}>
            <Text textStyle="body4Semi">
              {formatMessage('widgets.completeRequiredDetailsModal.stage.business-details.title')}
            </Text>
          </Box>
        </Form.ContentBox>
      )}
      <Form.TextField
        {...registerField('contactFirstName')}
        isHidden={!missingFieldsMap['contactFirstName']}
        labelProps={{ label: companyLabels.contactFirstName }}
        placeholder={placeholders.firstName}
        colSpan={!missingFieldsMap['contactLastName'] ? 2 : 1}
        isRequired={missingFieldsMap['contactFirstName']?.isRequired}
      />
      <Form.TextField
        {...registerField('contactLastName')}
        isHidden={!missingFieldsMap['contactLastName']}
        labelProps={{ label: companyLabels.contactLastName }}
        placeholder={placeholders.lastName}
        colSpan={!missingFieldsMap['contactFirstName'] ? 2 : 1}
        isRequired={missingFieldsMap['contactLastName']?.isRequired}
      />
      <Form.TextField
        {...registerField('companyName')}
        isHidden={!missingFieldsMap['companyName']}
        labelProps={{ label: companyLabels.name }}
        placeholder={placeholders.companyName}
        colSpan={2}
        isRequired={missingFieldsMap['companyName']?.isRequired}
      />
      <Form.TextField
        {...registerField('legalCompanyName')}
        isHidden={!missingFieldsMap['legalCompanyName']}
        labelProps={{ label: companyLabels.legalName }}
        placeholder={placeholders.legalCompanyName}
        helperTextProps={{ label: formatMessage('widgets.completeLegalInfo.legalCompanyName.helperText') }}
        colSpan={2}
        isRequired={missingFieldsMap['legalCompanyName']?.isRequired}
      />
      <Form.PhoneField
        {...registerField('phoneNumber')}
        isHidden={!missingFieldsMap['phoneNumber']}
        labelProps={{ label: companyLabels.phoneNumber }}
        placeholder={placeholders.phoneNumber}
        isRequired={missingFieldsMap['phoneNumber']?.isRequired}
        colSpan={2}
      />
      <AddressSearchWidget
        {...registerField('address')}
        isHidden={!missingFieldsMap['address']}
        labelProps={{ label: companyLabels.address }}
        placeholder={placeholders.address}
        isRequired={missingFieldsMap['address']?.isRequired}
        colSpan={2}
      />
      <AddressSearchWidget
        {...registerField('legalAddress')}
        isHidden={!missingFieldsMap['legalAddress']}
        labelProps={{ label: companyLabels.legalAddress }}
        placeholder={placeholders.legalAddress}
        isRequired={missingFieldsMap['legalAddress']?.isRequired}
        colSpan={2}
      />
      <IndustryTypeSelectWidget
        {...registerField('industry')}
        onCreateOption={handleIndustryCreatable}
        isHidden={!missingFieldsMap['industry']}
        labelProps={{ label: companyLabels.industry }}
        placeholder={placeholders.industry}
        colSpan={2}
        isRequired={missingFieldsMap['industry']?.isRequired}
      />
      <BusinessTypeAndTaxInfoWidget
        missingData={missingFieldsMap}
        existingBusinessType={!!businessType}
        companyName={company.name || ''}
        colSpan={2}
        {...useFormResult}
      />
    </Form>
  );
};
