import { useParams } from 'react-router-dom';
import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';
import { PaymentUpgradeActivity } from '@melio/vex-activities';

export const PaymentUpgradeRoute = () => {
  const [isUpsellInPlatformUIEnabled] = useAnonymousFeature(FeatureFlags.EnableUpsellInPlatformUI, false);

  const { paymentId } = useParams<{ paymentId: string }>();

  // TODO: remove isUpsellInPlatformUIEnabled condition at https://meliorisk.atlassian.net/browse/ME-37066
  if (!paymentId || !isUpsellInPlatformUIEnabled) {
    return null;
  }

  return <PaymentUpgradeActivity paymentId={paymentId} />;
};

PaymentUpgradeRoute.displayName = 'PaymentUpgradeRoute';
