import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AddBillIcon = (props: IconProps) => (
  <Icon width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
        fill="#98D5EC"
      />
      <mask id="mask0_5469_305136" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5469_305136)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 19L30 22L34 19L38 22L42 19L46 22L50 19L54 22L58 19L62 22L66 19L70 22L74 19L78 22L82 19V100H26V19Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 19L22 22L26 19L30 22L34 19L38 22L42 19L46 22L50 19L54 22L58 19L62 22L66 19L70 22L74 19V100H18V19Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68 33H32C31.4 33 31 32.6 31 32C31 31.4 31.4 31 32 31H68C68.6 31 69 31.4 69 32C69 32.6 68.6 33 68 33Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37 47H27C26.4 47 26 46.6 26 46C26 45.4 26.4 45 27 45H37C37.6 45 38 45.4 38 46C38 46.6 37.6 47 37 47Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 53H27C26.4 53 26 52.6 26 52C26 51.4 26.4 51 27 51H44C44.6 51 45 51.4 45 52C45 52.6 44.6 53 44 53Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37 59H27C26.4 59 26 58.6 26 58C26 57.4 26.4 57 27 57H37C37.6 57 38 57.4 38 58C38 58.6 37.6 59 37 59Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 65H27C26.4 65 26 64.6 26 64C26 63.4 26.4 63 27 63H40C40.6 63 41 63.4 41 64C41 64.6 40.6 65 40 65Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 47H65C64.4 47 64 46.6 64 46C64 45.4 64.4 45 65 45H75C75.6 45 76 45.4 76 46C76 46.6 75.6 47 75 47Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 53H68C67.4 53 67 52.6 67 52C67 51.4 67.4 51 68 51H75C75.6 51 76 51.4 76 52C76 52.6 75.6 53 75 53Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 65H66C65.4 65 65 64.6 65 64C65 63.4 65.4 63 66 63H75C75.6 63 76 63.4 76 64C76 64.6 75.6 65 75 65Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61 37H39C38.4 37 38 36.6 38 36C38 35.4 38.4 35 39 35H61C61.6 35 62 35.4 62 36C62 36.6 61.6 37 61 37Z"
          fill="#CDCCD0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 59H62C61.4 59 61 58.6 61 58C61 57.4 61.4 57 62 57H75C75.6 57 76 57.4 76 58C76 58.6 75.6 59 75 59Z"
          fill="#CDCCD0"
        />
        <path
          d="M27.6852 75.543C28.1861 75.5195 28.5317 75.3364 28.7222 74.9937C28.8219 74.8179 28.8716 74.6143 28.8716 74.3828C28.8716 74.0166 28.7369 73.7383 28.4674 73.5479C28.3121 73.4365 28.0514 73.3311 27.6852 73.2314V75.543ZM26.1953 71.3022C26.1953 71.5923 26.2906 71.8193 26.4811 71.9834C26.6744 72.1475 26.9337 72.2617 27.2589 72.3262V70.2607C26.8751 70.2754 26.6011 70.3926 26.437 70.6123C26.2759 70.832 26.1953 71.062 26.1953 71.3022ZM25.4044 71.355C25.4044 70.9097 25.5669 70.5083 25.8921 70.1509C26.2202 69.7935 26.6787 69.6118 27.2676 69.606V69.0215H27.6852V69.5972C28.2682 69.6382 28.7148 69.811 29.0254 70.1157C29.3389 70.4175 29.5029 70.8174 29.5176 71.3154H28.7486C28.7281 71.0928 28.668 70.9009 28.5684 70.7397C28.3838 70.4438 28.0895 70.2886 27.6852 70.2739V72.4097C28.3648 72.6001 28.8262 72.7788 29.0693 72.9458C29.4648 73.2212 29.6626 73.6284 29.6626 74.1675C29.6626 74.9468 29.4092 75.5005 28.9023 75.8286C28.6211 76.0103 28.2154 76.1304 27.6852 76.189V77.0415H27.2676V76.189C26.415 76.1333 25.8364 75.8315 25.5317 75.2837C25.3647 74.9878 25.2812 74.5879 25.2812 74.084H26.0591C26.0825 74.4854 26.1455 74.7783 26.248 74.9629C26.4297 75.2939 26.7667 75.48 27.2589 75.521V73.126C26.6261 73.0059 26.1587 72.8052 25.8569 72.5239C25.5552 72.2427 25.4044 71.853 25.4044 71.355Z"
          fill="#0BA44A"
        />
      </g>
    </svg>
  </Icon>
);
