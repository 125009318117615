import { featureFlags } from '@melio/shared-web';
import { UseFeatureOptions } from '@melio/shared-web/dist/feature-flags';

import { FeatureFlags } from './legacyFeatureList';

export { FeatureFlags };

export const useFeature = <T>(flagKey: FeatureFlags, defaultValue: T, options?: UseFeatureOptions) =>
  featureFlags.useFeature(flagKey, defaultValue, options);
export const useAnonymousFeature = <T>(flagKey: FeatureFlags, defaultValue: T, options?: UseFeatureOptions) =>
  featureFlags.useAnonymousFeature(flagKey, defaultValue, options);
