import { Card, Container, ExternalLayout, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  FeeCatalogValueTypeEnum,
  PaymentUpgradeOfferOption,
  PaymentUpgradeOfferOptionEnum,
  VendorPreferredPremiumMethodsEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { FooterWidget, useIsMobile } from '@melio/vex-widgets';
import { useEffect } from 'react';

import { PersistentFastPreferenceCardFooter, PersistentFastPreferenceSuccessBanner } from '../../components';
import type { PersistentFastLabels } from '../../components/types';
import {
  ANALYTICS_CONTEXT,
  mapUpgradeOfferToAnalyticsOffer,
  mapUpgradeOfferToAnalyticsPersistentFastOffer,
  mapUpgradeOfferToVendorFastPreferenceValue,
} from '../../consts';
import { useSuccessScreenDeliveryDateLabel } from '../../hooks';

export type PaymentUpgradeSuccessScreenProps = {
  amount: number;
  formattedDeliveryMethodDetails: string;
  orgName: string;
  offerType: PaymentUpgradeOfferOptionEnum;
  expeditedDeliveryEta: Date;
  paymentUpgradeOffer?: PaymentUpgradeOfferOption;
  isPersistentFastOfferEnabled: boolean;
  isPersistentFastPreferenceUpdating: boolean;
  isPersistentFastPreferenceSuccessBannerVisible: boolean;
  onSavePersistentFastPreference: (value: VendorPreferredPremiumMethodsEnum) => Promise<void>;
  persistentFastLabels: PersistentFastLabels;
};

export const PaymentUpgradeSuccessScreen = withAnalyticsContext<PaymentUpgradeSuccessScreenProps>(
  forwardRef(
    (
      {
        amount,
        formattedDeliveryMethodDetails,
        orgName,
        expeditedDeliveryEta,
        offerType,
        paymentUpgradeOffer,
        isPersistentFastOfferEnabled,
        isPersistentFastPreferenceUpdating,
        isPersistentFastPreferenceSuccessBannerVisible,
        onSavePersistentFastPreference,
        setAnalyticsProperties,
        children,
        persistentFastLabels,
        ...props
      },
      ref
    ) => {
      const { track } = useAnalytics();
      const { formatMessage, formatCurrency, formatPercents } = useMelioIntl();
      const isMobile = useIsMobile();

      useEffect(() => {
        setAnalyticsProperties({
          PageName: 'payment-on-the-way',
          DeliveryMethodShown: mapUpgradeOfferToAnalyticsOffer[offerType],
          DeliveryMethodChosen: mapUpgradeOfferToAnalyticsOffer[offerType],
          ...(isPersistentFastOfferEnabled && {
            SpecialDeliveryMethod: mapUpgradeOfferToAnalyticsPersistentFastOffer[offerType],
          }),
        });
        // This hook should occur only once on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      usePageView(ANALYTICS_CONTEXT, { Intent: 'fast-delivery-method-success' });

      const deliveryEtaLabel = useSuccessScreenDeliveryDateLabel({
        offerType,
        expeditedDeliveryEta,
      });

      const handleSavePersistentFastPreference = async () => {
        track(ANALYTICS_CONTEXT, 'Click', { Cta: 'confirm', Intent: 'accept-persistent-fast-delivery-method' });
        await onSavePersistentFastPreference(
          mapUpgradeOfferToVendorFastPreferenceValue[offerType] as VendorPreferredPremiumMethodsEnum
        );
      };

      const { calculatedFee, feeCatalog } = paymentUpgradeOffer || {};

      const formattedFeeAmount =
        calculatedFee && feeCatalog
          ? feeCatalog.valueType === FeeCatalogValueTypeEnum.Percent
            ? formatPercents(feeCatalog.value, { divide: true })
            : formatCurrency(calculatedFee)
          : undefined;

      const paddingX = isMobile ? 's' : 'xl';

      const content = (
        <Card paddingX="none" paddingY="none">
          <Group hasDivider variant="vertical" spacing="none">
            <Container paddingX={paddingX} paddingY={isMobile ? 's' : 'xl'}>
              <Group variant="vertical" spacing="xl">
                <Group variant="vertical" spacing={isMobile ? 'm' : 's'}>
                  <StatusIconSolid variant="success" size="large" />
                  <Text textStyle="heading3Semi">
                    {formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.title')}
                  </Text>
                  <Text textStyle={isMobile ? 'body3' : 'body2'}>
                    {formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.description', {
                      amount: formatCurrency(amount),
                      organizationCompanyName: orgName,
                      offerType,
                      last4digits: formattedDeliveryMethodDetails,
                      deliveryEtaLabel,
                    })}
                  </Text>
                </Group>
                {isPersistentFastPreferenceSuccessBannerVisible && (
                  <PersistentFastPreferenceSuccessBanner labels={persistentFastLabels.success} />
                )}
              </Group>
            </Container>
            {formattedFeeAmount && isPersistentFastOfferEnabled && (
              <Container paddingX={paddingX} paddingY={isMobile ? 's' : 'xl'}>
                <PersistentFastPreferenceCardFooter
                  isSaving={isPersistentFastPreferenceUpdating}
                  onSave={handleSavePersistentFastPreference}
                  persistentFastOfferLabels={persistentFastLabels.offer}
                />
              </Container>
            )}
          </Group>
        </Card>
      );

      const footer = <FooterWidget />;

      return (
        <ExternalLayout
          data-component="PaymentUpgradeActivity.PaymentUpgradeSuccessScreen"
          data-testid="vex-payment-upgrade-success-screen"
          {...props}
          content={content}
          footer={footer}
          ref={ref}
        />
      );
    }
  )
);

PaymentUpgradeSuccessScreen.displayName = 'PaymentUpgradeActivity.PaymentUpgradeSuccessScreen';
