import {
  Button,
  Card,
  Container,
  ExternalLayout,
  Group,
  IconButton,
  NakedButton,
  SelectionCard,
  Text,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  createAddressLabel,
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  FooterWidget,
  PCIComplianceInfo,
  PoweredByMelioWidget,
  TermsAndPolicyDisclaimerWidget,
} from '@melio/vex-widgets';

import { CheckAccountFormModel } from '../../../../../types';

type AddCheckAddressManuallyProps = Pick<
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  'accountName' | 'paymentAmount'
> & {
  checkDetailsWithOriginalAddress: CheckAccountFormModel;
  isSaving?: boolean;
  isLoading?: boolean;
  onBack: VoidFunction;
  onDone: () => void;
  onEditAddressClicked: () => void;
  showPoweredByMelio?: boolean;
};

const activityPrefix = 'activities.addCheckDeliveryMethodByPayee.screens.invalidAddressConfirmation';

export const InvalidAddressConfirmationScreen = forwardRef<AddCheckAddressManuallyProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      isSaving,
      onBack,
      onDone,
      checkDetailsWithOriginalAddress,
      onEditAddressClicked,
      showPoweredByMelio,
      children,
      isLoading,
      ...props
    },
    ref
  ) => {
    usePageView('Vendor', { PageName: 'invalid-paper-check-address' });
    const { formatMessage } = useMelioIntl();
    const legalInfoOverride = accountName
      ? formatMessage('vex.widgets.legal.infoWithAccountName', { accountName })
      : undefined;

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            <IconButton
              data-testid="layout-back-button"
              aria-label="back"
              icon="chevron-left"
              variant="naked"
              size="medium"
              onClick={onBack}
            />
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="m">
              <Text as="h1" textStyle="heading3Semi">
                {formatMessage(`${activityPrefix}.title`)}
              </Text>
              <Text>{formatMessage(`${activityPrefix}.description`)}</Text>
              <SelectionCard
                mainLabelProps={{ label: createAddressLabel(checkDetailsWithOriginalAddress) }}
                descriptionProps={{
                  label: formatMessage(`${activityPrefix}.cardDescription`),
                }}
                icon="warning"
              />
            </Group>
          </Container>
          <Container paddingX="xl" paddingBottom="l">
            <Group variant="vertical" spacing="m">
              <Button
                data-testid="layout-next-button"
                size="large"
                isFullWidth
                onClick={onDone}
                label={formatMessage(`${activityPrefix}.continue`)}
                isLoading={isLoading}
              />
              <Group.Item>
                <Container justifyContent="center">
                  <NakedButton onClick={onEditAddressClicked} label={formatMessage(`${activityPrefix}.editAddress`)} />
                </Container>
              </Group.Item>
              <Group variant="vertical" spacing="m">
                <TermsAndPolicyDisclaimerWidget />
                <PCIComplianceInfo legalInfoOverride={legalInfoOverride} />
              </Group>
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddCheckDeliveryMethodByPayeeActivity.InvalidAddressConfirmationScreen"
        data-testid="add-check-delivery-method-by-payee-activity-invalid-address-confirmation-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

InvalidAddressConfirmationScreen.displayName = 'AddCheckDeliveryMethodByPayeeActivity.InvalidAddressConfirmationScreen';
