import { CardNumberElement as ICardNumberElement } from '@basis-theory/basis-theory-react/types';
import { forwardRef } from 'react';

import { TBTFormFieldWidget } from '../TBTFormField';
import { TBTFormFieldWidgetProps } from '../TBTFormField/TBTFormField.widget';

export type TBTCardNumberFieldWidgetProps = Omit<TBTFormFieldWidgetProps, 'elementType'>;

export const TBTCardNumberFieldWidget = forwardRef<ICardNumberElement, TBTCardNumberFieldWidgetProps>((props, ref) => (
  // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  <TBTFormFieldWidget data-component="TBTCardNumberFieldWidget" {...props} elementType="cardNumber" ref={ref} />
));

TBTCardNumberFieldWidget.displayName = 'TBTCardNumberFieldWidget';
