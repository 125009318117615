import {
  BankAccountType,
  DeliveryMethodType,
  useAcceptUnilateralPayment,
  useOrganizationDeliveryMethods,
  useVexGuestDeliveryMethod,
} from '@melio/platform-api';

import { BankAccountFormModel } from '../../../types';

type UseVexDeliveryMethodParams = {
  isGuest: boolean;
};

export const useCreateDeliveryMethod = ({ isGuest }: UseVexDeliveryMethodParams) => {
  const { mutateAsync: createGuestVendorDeliveryMethod, isSuccess: isGuestVendorDeliveryMethodCreatedSuccessfully } =
    useVexGuestDeliveryMethod();
  const { create: createOwnedVendorDeliveryMethod } = useOrganizationDeliveryMethods({
    options: { enabled: false },
  });
  const {
    copyDeliveryMethodOwnedToLocalVendor,
    isSuccess: isOwnedVendorDeliveryMethodCopiedToLocalVendorSuccessfully,
  } = useAcceptUnilateralPayment();

  const createBankAccountDeliveryMethod = async (
    data: BankAccountFormModel
  ): Promise<{ deliveryMethodId: string | undefined }> => {
    if (isGuest) {
      const {
        data: { id: deliveryMethodId },
      } = await createGuestVendorDeliveryMethod({
        type: DeliveryMethodType.BankAccount,
        details: {
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          accountType: BankAccountType.Checking,
        },
      });

      return { deliveryMethodId };
    }

    const { id: ownedVendorDeliveryMethodId } = await createOwnedVendorDeliveryMethod({
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
    });
    return copyDeliveryMethodOwnedToLocalVendor(ownedVendorDeliveryMethodId);
  };

  return {
    createBankAccountDeliveryMethod,
    copyDeliveryMethodOwnedToLocalVendor,
    isCreatedSuccessfully:
      isGuestVendorDeliveryMethodCreatedSuccessfully || isOwnedVendorDeliveryMethodCopiedToLocalVendorSuccessfully,
  };
};
