import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import { DeliveryMethodsApiClient } from '@melio/platform-api-axios-client';

import { DeliveryMethod, PatchDeliveryMethodsRequest, PostDeliveryMethodsRequest } from './types';

type QueryFN = typeof DeliveryMethodsApiClient.getVendorsVendorIdDeliveryMethods;
type CreateFN = (
  data: PostDeliveryMethodsRequest
) => ReturnType<typeof DeliveryMethodsApiClient.postVendorsVendorIdDeliveryMethods>;
type UpdateFN = typeof DeliveryMethodsApiClient.patchDeliveryMethodsDeliveryMethodId;
type DeleteFN = typeof DeliveryMethodsApiClient.deleteDeliveryMethodsDeliveryMethodId;

export type UseDeliveryMethodsProps<T extends DeliveryMethod = DeliveryMethod> = UseCollectionApiProps<QueryFN, T> & {
  vendorId?: string;
  isGetOwn?: boolean;
  onCreate?: (data: DeliveryMethod) => void;
  onCreateError?: ErrorFunction;
};

export const useDeliveryMethods = <T extends DeliveryMethod = DeliveryMethod>({
  vendorId,
  isGetOwn,
  onCreate,
  enabled = true,
  ...props
}: UseDeliveryMethodsProps<T> = {}) => {
  const queryClient = useMelioQueryClient();
  const query = useCollectionApi<
    QueryFN,
    CreateFN,
    UpdateFN,
    DeleteFN,
    T,
    PostDeliveryMethodsRequest,
    PatchDeliveryMethodsRequest
  >({
    ...props,
    enabled: isGetOwn ?? (enabled && !!vendorId),
    queryKey: vendorId ? ['DeliveryMethodsApi', vendorId] : ['DeliveryMethodsApi'],
    queryFn: () =>
      vendorId
        ? DeliveryMethodsApiClient.getVendorsVendorIdDeliveryMethods(vendorId)
        : DeliveryMethodsApiClient.getDeliveryMethods(),
    createFn: (data: PostDeliveryMethodsRequest) =>
      DeliveryMethodsApiClient.postVendorsVendorIdDeliveryMethods(vendorId as string, data),
    deleteFn: DeliveryMethodsApiClient.deleteDeliveryMethodsDeliveryMethodId,
    updateFn: DeliveryMethodsApiClient.patchDeliveryMethodsDeliveryMethodId,
    onCreate: (deliveryMethods) =>
      Promise.all([
        queryClient.invalidateQueries('VendorsApi', 'model', vendorId),
        queryClient.invalidateQueries('VendorsApi', 'collection'),
        queryClient.invalidateQueries('DeliveryMethodTypeOptionsApi'),
        onCreate?.(deliveryMethods),
      ]),
  });

  const confirmDeliveryMethod = useNonEntityMutation(
    DeliveryMethodsApiClient.postDeliveryMethodsDeliveryMethodIdConfirm,
    query.queryKey,
    { onSuccess: () => queryClient.invalidateQueries('VendorsApi', 'model', vendorId) }
  );

  return {
    ...query,
    confirm: confirmDeliveryMethod.mutateAsync,
  };
};

export type DeliveryMethodCollection = ReturnType<typeof useDeliveryMethods>;
