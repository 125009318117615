export const AmexGray = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M-0.000366211 0H23.9996V24H-0.000366211V0Z" fill="#8E8E90" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.69397 20.2024V11.6028L24 11.6165V13.992L22.2309 15.8825L24 17.7904V20.2163H21.1754L19.6744 18.5599L18.184 20.2227L8.69397 20.2024Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.71643 19.2595V12.5491H15.4063V14.0949H11.5587V15.1442H15.3146V16.6645H11.5587V17.6951H15.4063V19.2595H9.71643Z"
      fill="#8E8E90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3776 19.2595L18.526 15.9005L15.3776 12.5493H17.8144L19.7382 14.6761L21.6676 12.5493H23.9997V12.6021L20.9184 15.9005L23.9997 19.1643V19.2595H21.6441L19.6861 17.1114L17.7482 19.2595H15.3776Z"
      fill="#8E8E90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.40713 3.96986H13.0966L14.3925 6.91239V3.96986H18.9474L19.7329 6.17452L20.521 3.96986H23.9991V12.5695H5.6178L9.40713 3.96986Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.106 4.90409L7.12854 11.6089H9.17068L9.73241 10.2662H12.7761L13.3375 11.6089H15.4304L12.4653 4.90409H10.106ZM10.3619 8.76156L11.2548 6.62715L12.1471 8.76156H10.3619Z"
      fill="#8E8E90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.403 11.6078V4.90314L18.2735 4.913L19.7506 9.03487L21.2372 4.90314H23.9999V11.6079L22.2222 11.6235V7.01793L20.544 11.6079H18.9214L17.2084 7.00233V11.6079L15.403 11.6078Z"
      fill="#8E8E90"
    />
  </svg>
);
