import { flow, isArray } from 'lodash';

import { EventProperties } from '../types';
import { sanitizeId } from '../utils/sanitizeId';

type EventPropertiesParser = (properties: EventProperties) => EventProperties;

function parseProp(prop: string, oldValue: string, newValue: string): string {
  return prop === oldValue ? newValue : prop;
}

const parseDeliveryMethodType: EventPropertiesParser = (properties) => {
  const { DeliveryMethod, ShownDeliveryMethod, ...rest } = properties;
  return {
    ...rest,
    ...(DeliveryMethod && {
      DeliveryMethod: parseProp(DeliveryMethod, 'international-account', 'international'),
    }),
    ...(ShownDeliveryMethod && {
      ShownDeliveryMethod: ShownDeliveryMethod.map((dmType) =>
        parseProp(dmType, 'international-account', 'international')
      ),
    }),
  };
};

const idKeys = ['paymentid', 'vendorid', 'billid', 'billids', 'vendorids', 'paymentids', 'billsubscriptionid'];

const sanitizeIds: EventPropertiesParser = (properties) =>
  Object.fromEntries(
    Object.entries(properties).map(([key, value]) => {
      if (!idKeys.includes(key.toLowerCase())) {
        return [key, value];
      }
      if (isArray(value)) {
        return [key, value.map(sanitizeId)];
      }
      return [key, sanitizeId(value as number | string)];
    })
  ) as EventProperties;

export const parsePropertiesForAnalytics = (properties?: EventProperties) =>
  properties ? (flow([parseDeliveryMethodType, sanitizeIds])(properties) as EventProperties) : undefined;
