import { usePageViewStatus } from '@melio/vex-domain';
import { FC } from 'react';

import { BaseErrorScreen, BaseSuccessScreen } from '../../../base-screens';
import { ANALYTICS_CONTEXT } from '../../consts';

type PaymentUpgradeFailedScreenProps = {
  description: string;
  isSuccessVariant: boolean;
  pageViewAnalyticProperties: {
    PageName: string;
    DeliveryMethodChosen: string;
    StatusType: string;
    ErrorType: string;
  };
};

export const PaymentUpgradeFailedScreen: FC<PaymentUpgradeFailedScreenProps> = ({
  description,
  isSuccessVariant,
  pageViewAnalyticProperties,
}) => {
  usePageViewStatus(ANALYTICS_CONTEXT, pageViewAnalyticProperties);

  const ScreenComponent = isSuccessVariant ? BaseSuccessScreen : BaseErrorScreen;

  return <ScreenComponent data-testid="vex-payment-upgrade-error-screen" description={description} />;
};

PaymentUpgradeFailedScreen.displayName = 'PaymentUpgradeActivity.PaymentUpgradeFailedScreen';
