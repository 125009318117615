import { ThemeOptions } from '@melio/penny';

import { MelioIcons } from '@/cl/icons/melio';
import { OptumBankIcon, OptumBankLogo } from '@/cl/theme/partners/optum-bank-logo';

// off-the-shelf partners that we'll eventually move to store the config in db
export const optumBankTheme: ThemeOptions = {
  logos: {
    dark: OptumBankLogo,
    light: OptumBankLogo,
  },
  colors: {
    global: {
      brand: {
        100: '#e6e6e6',
        200: '#d0edfe',
        300: '#d0edfe',
        400: '#d0edfe',
        500: '#316BBE',
        600: '#316BBE',
        700: '#316BBE',
        800: '#00396C',
        900: '#00396C',
        1000: '#00396C',
      },
    },
    // deprecated palette
    brand: {
      darkest: '#00396C',
      dark: '#00396C',
      main: '#316BBE',
      light: '#6896d8',
      lighter: '#7ea5de',
      lightest: '#e6e6e6',
    },
  },
  // @ts-ignore
  borders: {
    selection: {
      selectedBrand: '2px solid #FF612B',
    },
  },
};

export const OptumBankIcons = {
  ...MelioIcons,
  SmallLogoIcon: undefined,
  LogoIcon: OptumBankIcon,
};
