import { useRecoilValue } from 'recoil';
import { PartnerName } from '@melio/platform-api';

import { selectedPartner } from '@/store/app/app.model';
import { useCapitalOneLogic } from './capitalOne.hooks';
import { useSbbLogic } from './sbb.hooks';

type PartnerLogic = {
  generateBackToPartnerUrl: () => string | undefined;
};
export const usePartnerLogic = (): PartnerLogic => {
  const currentPartner = useRecoilValue(selectedPartner);

  const { generateCapitaOneBackUrl } = useCapitalOneLogic();
  const { generateSbbBackUrl } = useSbbLogic();

  switch (currentPartner) {
    case PartnerName.CapitalOne:
      return {
        generateBackToPartnerUrl: generateCapitaOneBackUrl,
      };
    case PartnerName.Sbb:
      return {
        generateBackToPartnerUrl: generateSbbBackUrl,
      };
    default:
      return {
        generateBackToPartnerUrl: () => undefined,
      };
  }
};
