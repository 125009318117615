import { APIErrorWithErrorCode, VEXErrorCodes } from '@melio/platform-api';

import { VendorOnboardingCardsEnum } from '../../shared';
import { ReviewDetailsErrorTypes } from './consts';

export const formatPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  const [, partOne, partTwo, partThree] = phone.match(/(\d{1,3})(\d{0,3})(\d*)/) || [];
  if (!partOne || !partTwo) {
    return '';
  }

  return `(${partOne}) ${partTwo}${partThree ? '-' + partThree : ''}`;
};

export const formatTaxId = (taxId: string) => taxId.slice(-4).padStart(taxId.length, '•');

export const extractReviewDetailsErrorTypeFromError = (
  error: APIErrorWithErrorCode
): ReviewDetailsErrorTypes | undefined => {
  if (error.errorCode === VEXErrorCodes.VendorOnboardingMissingBusinessDetails) {
    return ReviewDetailsErrorTypes.BUSINESS_DETAILS_ERROR;
  } else if (error.errorCode === VEXErrorCodes.VendorOnboardingMissingDeliveryMethod) {
    return ReviewDetailsErrorTypes.BANK_DETAILS_ERROR;
  }

  return undefined;
};

export const cardToAnalyticIntent = (card: VendorOnboardingCardsEnum) => {
  switch (card) {
    case VendorOnboardingCardsEnum.TermAndConditions:
      return 'set-up-your-account';
    case VendorOnboardingCardsEnum.PersonalDetails:
      return 'enter-your-details';
    case VendorOnboardingCardsEnum.BusinessDetails:
      return 'enter-your-business-details';
    case VendorOnboardingCardsEnum.UploadLogo:
      return 'upload-your-logo';
    case VendorOnboardingCardsEnum.ReviewDetails:
      return 'review-your-details';
    case VendorOnboardingCardsEnum.EmailVerification:
      return 'verify-your-email';
    case VendorOnboardingCardsEnum.BankDetails:
      return 'link-your-bank-account';
    default:
      return 'set-up-your-account';
  }
};
