import { ApiQueryKey, ModelId, QueryKeyEnum, Scope } from '@melio/platform-api';

const QUERY_KEYS = {
  VENDORS: 'vendors',
  VENDOR_STATS: 'vendorStats',
  ACCOUNT: 'account',
  AUTH: 'auth',
  PREFERENCES: 'preferences',
  PAYMENT_SOURCES: 'paymentSources',
  PAYMENT_INTENTS: 'paymentIntents',
  Files: 'Files',
  PAYMENT: 'payments',
  ACCOUNTING_PLATFORMS: 'AccountingPlatformsApi',
  REPORT: 'report',
  ACCOUNTS: 'accounts',
  BILLS: 'bills',
};

const defaultScope: Scope = 'default';

const getCollectionKey =
  (key: QueryKeyEnum) =>
  (params: unknown[] = [], scope = defaultScope): ApiQueryKey =>
    [key, 'collection', scope, ...params];

const getModelKey =
  (key: QueryKeyEnum) =>
  (id?: ModelId, params: unknown[] = [], scope = defaultScope): ApiQueryKey =>
    [key, 'model', id, scope, ...params];

export const getAccountingPlatformsListQueryKey = () => [
  QUERY_KEYS.ACCOUNTING_PLATFORMS,
  'getAccountingPlatforms',
  'collection',
];
export const getAccountingPlatformQueryKey = () => QUERY_KEYS.ACCOUNTING_PLATFORMS;

export const getAccountingPlatformSyncStatusQueryKey = (accountingPlatformId: string) => [
  QUERY_KEYS.ACCOUNTING_PLATFORMS,
  'syncStatus',
  accountingPlatformId,
];

export const getVendorByIdQueryKey = getModelKey('VendorsApi' as never);

export const getVendorsListQueryKey = getCollectionKey('VendorsApi' as never);

export const getAccountListQueryKey = getCollectionKey('account');

export const getAccountMeQueryKey = (expand: 'logoUrl' | 'paymentOverview' | 'none' = 'none') =>
  getModelKey('account')('me', [{ expand }]);

export const getPreferencesQueryKey = getCollectionKey('organization-preference');

export const getVexAuthTokenFromActionTokenQueryKey = (token: string) => [
  QUERY_KEYS.AUTH,
  'authVexTokenFromActionToken',
  token,
];
export const getAuthTokenFromActionTokenQueryKey = (token: string) => [
  QUERY_KEYS.AUTH,
  'authTokenFromActionToken',
  token,
];
