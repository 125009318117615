import { Group, StatusIconSolid, Text } from '@melio/penny';
import { DeliveryMethodType, PaymentDeliveryPreferenceEnum, TrackerTimelineStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePaymentTrackingHeaderText } from './usePaymentTrackingHeaderText';

export const PaymentTrackingHeader = ({
  trackerStatus,
  amount,
  payorOrganizationName,
  deliveryEta,
  deliveryPreference,
  deliveryMethodType,
}: {
  trackerStatus: TrackerTimelineStatusEnum;
  amount: number;
  payorOrganizationName: string;
  deliveryEta: Date;
  deliveryPreference: PaymentDeliveryPreferenceEnum | null | undefined;
  deliveryMethodType: DeliveryMethodType;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { subtitle, statusIconVariant, title } = usePaymentTrackingHeaderText({
    deliveryEta,
    trackerStatus,
    deliveryPreference,
    deliveryMethodType,
  });
  return (
    <Group variant="vertical" spacing="s" alignItems="center" data-testid={`${trackerStatus}-payment-tracking-header`}>
      {subtitle ? (
        <Group variant="vertical" alignItems="center" spacing="xs">
          <StatusIconSolid
            data-testid={`status-icon-variant-${statusIconVariant}`}
            variant={statusIconVariant}
            size="extra-large"
          />
          <Text data-testid="payment-tracking-header-subtitle" textStyle="body4Semi" color="global.neutral.900">
            {subtitle}
          </Text>
          <Text data-testid="payment-tracking-header-title" textStyle="heading2Semi">
            {title}
          </Text>
        </Group>
      ) : (
        <>
          <StatusIconSolid
            data-testid={`status-icon-variant-${statusIconVariant}`}
            variant={statusIconVariant}
            size="extra-large"
          />
          <Text data-testid="payment-tracking-header-title" textStyle="heading2Semi">
            {title}
          </Text>
        </>
      )}
      <Group variant="vertical" alignItems="center" spacing="xxxs">
        <Text textStyle="body2Semi">{formatCurrency(amount)}</Text>
        <Text
          data-testid="payment-tracking-header-payor-organization-name"
          textStyle="body4"
          color="global.neutral.900"
        >
          {formatMessage('vex.activities.vendorPaymentTracking.header.fromPayorOrganizationName', {
            payorOrganizationName,
          })}
        </Text>
      </Group>
    </Group>
  );
};

PaymentTrackingHeader.displayName = 'PaymentTrackingHeader';
