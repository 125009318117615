import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ErrorIcon = (props: IconProps) => (
  <Icon width="75px" height="71px" viewBox="0 0 75 71" fill="none" {...props}>
    <path d="M26 23.9995H48.2225" stroke="#228800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.666 33.199H51.5564" stroke="#228800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.666 46.9995H56.5549" stroke="#228800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.334 56.1993H59.8907" stroke="#228800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.8886 70H9.33203L33.5323 3.20058C34.0085 1.89389 35.4619 1 37.1103 1C38.7584 1 40.2121 1.89389 40.6881 3.20058L64.8886 70Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 69.9999H73.2237" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
