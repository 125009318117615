import { ThemeOptions } from '@melio/penny';

import { Bank } from './icons/Bank';
import { Spinner } from './loader';
import { UsBankLogo, UsBankLogoDark } from './logos';

export const usBankTheme: ThemeOptions = {
  loader: Spinner,
  logos: {
    light: UsBankLogo,
    dark: UsBankLogoDark,
  },
  fonts: {
    primary: '"U.S. Bank Circular Web", Roboto,Arial,sans-serif',
  },
  colors: {
    // new palette
    global: {
      brand: {
        100: '#EEF6FF',
        200: '#D9EBFF',
        300: '#CCE4FF',
        400: '#B2D7FF',
        500: '#80BDFF',
        600: '#3395FF',
        700: '#235AE4',
        800: '#003CC8',
        900: '#001E79',
        1000: '#001E79',
      },
      brandSecondary: {
        100: '#EEF6FF',
        200: '#D9EBFF',
        300: '#CCE4FF',
        400: '#B2D7FF',
        500: '#80BDFF',
        600: '#3395FF',
        700: '#235AE4',
        800: '#003CC8',
        900: '#001E79',
        1000: '#001E79',
      },
      warning: {
        100: '#FFF4E4',
        200: '#FFE0CC',
        300: '#FEC29A',
        400: '#FEA367',
        500: '#FE8434',
        600: '#FE6601',
        700: '#EB5E01',
        800: '#CB5101',
        900: '#662900',
        1000: '#662900',
      },
      success: {
        100: '#F2FFF4',
        200: '#CCFFD4',
        300: '#00F03A',
        400: '#00D133',
        500: '#00A829',
        600: '#008F22',
        700: '#007D1E',
        800: '#005715',
        900: '#2E2E32',
        1000: '#2E2E32',
      },
      informative: {
        100: '#EEF6FF',
        200: '#D9EBFF',
        300: '#CCE4FF',
        400: '#B2D7FF',
        500: '#80BDFF',
        600: '#3395FF',
        700: '#235AE4',
        800: '#003CC8',
        900: '#2E2E32',
        1000: '#2E2E32',
      },
      critical: {
        100: '#FFE9EA',
        200: '#FFB8BB',
        300: '#FF858A',
        400: '#FF5259',
        500: '#FF1A23',
        600: '#E50009',
        700: '#CF2A36',
        800: '#941E27',
        900: '#2E2E32',
        1000: '#2E2E32',
      },
    },
    brand: {
      darkest: '#001E79',
      dark: '#003CC8',
      main: '#235AE4',
      light: '#4989BB',
      lighter: '#D9EBFF',
      lightest: '#EEF6FF',
    },
  },
  icons: {
    bank: Bank,
  },
};
