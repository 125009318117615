import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
export const OptumBankLogo: React.VFC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 177.67 32.66"
    // @ts-ignore
    style="enable-background:new 0 0 177.67 32.66;"
    xmlSpace="preserve"
  >
    <style type="text/css">
      .st0{'{fill:#4B4D4F;}'}
      .st1{'{fill:#FF612B;}'}
    </style>
    <g id="Guides"></g>
    <g id="Optum_Bank">
      <g>
        <g>
          <path
            fill="#4B4D4F"
            d="M108.43,22.86V0.88h7.25c4.3,0,6.81,2.51,6.81,5.65c0,2.07-1.19,3.83-3.33,4.65
				c2.92,0.69,4.58,2.92,4.58,5.56c0,3.39-2.64,6.12-7.03,6.12H108.43z M115.65,3.01h-4.71v7.29h4.71c2.7,0,4.33-1.63,4.33-3.64
				S118.35,3.01,115.65,3.01z M116.66,12.47h-5.72v8.26h5.78c2.83,0,4.52-1.92,4.52-4.11C121.24,14.38,119.51,12.47,116.66,12.47z"
          />
          <path
            fill="#4B4D4F"
            d="M130.32,23.21c-3.08,0-5.09-1.82-5.09-4.3c0-2.76,1.57-4.27,5.72-4.99l4.84-0.88v-0.72
				c0-2.29-1.48-3.89-3.89-3.89c-2.29,0-3.61,1.26-4.08,3.27l-2.23-0.53c0.5-2.48,2.51-4.8,6.44-4.8c3.96,0,6.12,2.42,6.12,5.97
				v10.55h-2.32v-2.07C134.43,22.23,132.42,23.21,130.32,23.21z M127.65,18.68c0,1.54,1.16,2.51,3.01,2.51
				c2.07,0,3.8-1.04,5.12-2.48v-3.77l-3.42,0.6C128.72,16.24,127.65,17.05,127.65,18.68z"
          />
          <path
            fill="#4B4D4F"
            d="M140.5,22.86V6.66h2.32l0.03,1.82c1.35-1.38,3.2-2.17,5.24-2.17c3.55,0,5.81,2.42,5.81,5.9v10.65h-2.36
				V12.66c0-2.76-1.6-4.21-3.89-4.21c-1.6,0-3.33,0.63-4.8,2.17v12.25H140.5z"
          />
          <path
            fill="#4B4D4F"
            d="M156.27,22.86V0h2.36v15.2l7.88-8.54h2.95l-6.56,6.97l7.1,9.23h-2.98l-5.75-7.51l-2.64,2.79v4.71H156.27z"
          />
          <path
            fill="#4B4D4F"
            d="M174.39,12.42c-1.81,0-3.26-1.44-3.26-3.3s1.46-3.3,3.26-3.3s3.28,1.44,3.28,3.3S176.2,12.42,174.39,12.42z
				 M174.39,11.78c1.46,0,2.58-1.13,2.58-2.65c0-1.52-1.13-2.65-2.58-2.65c-1.44,0-2.55,1.13-2.55,2.65
				C171.84,10.65,172.95,11.78,174.39,11.78z M173.22,10.67V7.55h1.39c0.56,0,0.98,0.33,0.98,0.91c0,0.35-0.22,0.65-0.55,0.83
				l0.81,1.38h-0.75l-0.71-1.24h-0.5v1.24H173.22z M174.49,8.07h-0.6v0.85h0.56c0.31,0,0.46-0.2,0.46-0.41
				C174.92,8.25,174.77,8.07,174.49,8.07z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#4B4D4F"
              d="M109.12,29.44l0.01,3.14h-0.71V27.7h0.95l1.28,2.96l0.25,0.61l0.25-0.61l1.28-2.96h0.95v4.88h-0.73
					l0.01-3.14l0.01-0.68l-0.29,0.7l-1.21,2.78h-0.59l-1.21-2.78l-0.28-0.68L109.12,29.44z"
            />
            <path
              fill="#4B4D4F"
              d="M116.1,32.66c-0.99,0-1.82-0.78-1.82-1.9c0-1.13,0.77-1.89,1.72-1.89c1.14,0,1.69,0.88,1.69,1.85
					c0,0.08,0,0.15-0.01,0.24H115c0.08,0.65,0.52,1.1,1.11,1.1c0.42,0,0.75-0.18,0.99-0.53l0.53,0.31
					C117.3,32.36,116.75,32.66,116.1,32.66z M116,29.46c-0.53,0-0.89,0.42-0.99,0.98h1.97C116.93,29.88,116.57,29.46,116,29.46z"
            />
            <path
              fill="#4B4D4F"
              d="M118.52,32.58v-3.64h0.71l0.01,0.41c0.28-0.3,0.66-0.48,1.12-0.48c0.51,0,0.9,0.24,1.1,0.61
					c0.3-0.37,0.74-0.61,1.28-0.61c0.79,0,1.28,0.55,1.28,1.32v2.39h-0.72v-2.22c0-0.56-0.31-0.85-0.76-0.85
					c-0.36,0-0.66,0.17-0.93,0.46c0.01,0.07,0.01,0.15,0.01,0.22v2.39h-0.72v-2.22c0-0.56-0.32-0.85-0.77-0.85
					c-0.33,0-0.64,0.16-0.91,0.43v2.64H118.52z"
            />
            <path
              fill="#4B4D4F"
              d="M125.08,32.58v-5.08h0.72v1.81c0.27-0.28,0.64-0.43,1.07-0.43c0.98,0,1.71,0.79,1.71,1.89
					c0,1.07-0.76,1.88-1.76,1.89c-0.44,0-0.84-0.14-1.14-0.41l-0.14,0.33H125.08z M126.73,29.51c-0.37,0-0.68,0.15-0.93,0.44v1.65
					c0.25,0.29,0.57,0.43,0.93,0.43c0.66,0,1.13-0.52,1.13-1.26C127.86,30.05,127.4,29.51,126.73,29.51z"
            />
            <path
              fill="#4B4D4F"
              d="M131,32.66c-0.99,0-1.82-0.78-1.82-1.9c0-1.13,0.77-1.89,1.72-1.89c1.14,0,1.69,0.88,1.69,1.85
					c0,0.08,0,0.15-0.01,0.24h-2.69c0.08,0.65,0.52,1.1,1.11,1.1c0.42,0,0.75-0.18,0.99-0.53l0.53,0.31
					C132.2,32.36,131.65,32.66,131,32.66z M130.89,29.46c-0.53,0-0.89,0.42-0.99,0.98h1.97C131.83,29.88,131.46,29.46,130.89,29.46z
					"
            />
            <path
              fill="#4B4D4F"
              d="M133.42,32.58v-3.64h0.71l0.01,0.59c0.35-0.54,0.87-0.72,1.37-0.66l0.03,0.67c-0.61-0.08-1.08,0.1-1.4,0.61
					v2.43H133.42z"
            />
            <path fill="#4B4D4F" d="M138.41,32.58h-0.77V27.7H141v0.63h-2.59v1.45h1.99v0.63h-1.99V32.58z" />
            <path
              fill="#4B4D4F"
              d="M141.82,32.58V27.7h1.79c1.44,0,2.41,1.04,2.41,2.44s-1.01,2.44-2.47,2.44H141.82z M143.5,28.35h-0.91v3.58
					h0.93c1.07,0,1.74-0.77,1.74-1.79S144.58,28.35,143.5,28.35z"
            />
            <path fill="#4B4D4F" d="M146.63,31.99h0.82V28.3h-0.82v-0.6h2.4v0.6h-0.82v3.69h0.82v0.59h-2.4V31.99z" />
            <path
              fill="#4B4D4F"
              d="M152.04,31.98c0.64,0,1.1-0.29,1.4-0.87l0.66,0.27c-0.4,0.79-1.07,1.28-2.06,1.28
					c-1.33,0-2.4-1.02-2.4-2.52s1.07-2.52,2.41-2.52c1,0,1.67,0.47,2.07,1.28l-0.68,0.29c-0.31-0.59-0.76-0.89-1.4-0.89
					c-0.9,0-1.62,0.73-1.62,1.83C150.41,31.22,151.11,31.97,152.04,31.98z"
            />
          </g>
        </g>
      </g>
      <path
        fill="#FF612B"
        d="M73.82,22.85V6.01h5.34l0.03,2.01c1.07-1.54,2.64-2.26,4.46-2.26c2.29,0,3.93,1.07,4.81,2.73
		c1.07-1.82,3.08-2.73,5.15-2.73c3.68,0,5.69,2.77,5.69,6.25v10.84h-5.44V12.82c0-1.63-1.04-2.61-2.29-2.61s-2.29,0.97-2.29,2.61
		v10.02h-5.44V12.82c0-1.63-1.04-2.61-2.29-2.61c-1.26,0-2.29,0.97-2.29,2.61v10.02H73.82z M71.99,16.44c0,4.34-3.46,6.79-7.86,6.79
		c-4.4,0-7.86-2.45-7.86-6.79V6.01h5.47v10.28c0,1.48,0.97,2.42,2.39,2.42c1.41,0,2.39-0.94,2.39-2.42V6.01h5.47V16.44z M54.8,10.34
		V6.01h-4.49V1.7h-5.44v15.12c0,4.18,2.48,6.29,6.47,6.29c1.16,0,2.39-0.13,3.46-0.57v-4.31c-0.79,0.25-1.48,0.35-2.07,0.35
		c-1.45,0-2.42-0.66-2.42-2.2v-6.03H54.8z M37.99,14.43c0,2.42-1.76,4.21-3.93,4.21c-2.2,0-3.96-1.79-3.96-4.21
		c0-2.39,1.76-4.18,3.96-4.18C36.23,10.25,37.99,12.04,37.99,14.43z M30.1,29.39v-8.3c1.38,1.29,3.21,2.04,5.22,2.04
		c4.56,0,8.14-3.87,8.14-8.7c0-4.81-3.58-8.67-8.14-8.67c-2.11,0-3.96,0.82-5.37,2.2l-0.03-1.95h-5.28v23.38H30.1z M17.22,11.85
		c0,3.46-2.55,6.03-5.72,6.03s-5.72-2.58-5.72-6.03c0-3.46,2.55-6.03,5.72-6.03S17.22,8.39,17.22,11.85z M11.5,0.6
		C5.06,0.6,0,5.6,0,11.85S5.06,23.1,11.5,23.1c6.44,0,11.5-5,11.5-11.25S17.94,0.6,11.5,0.6z"
      />
    </g>
  </svg>
);

export const OptumBankIcon = (props: IconProps) => (
  <Icon viewBox="0 0 177.67 32.66" width={{ xs: '177px', s: '200px' }} height={{ xs: '30px', s: '50px' }} {...props}>
    <path
      fill="#4B4D4F"
      d="M108.43,22.86V0.88h7.25c4.3,0,6.81,2.51,6.81,5.65c0,2.07-1.19,3.83-3.33,4.65
				c2.92,0.69,4.58,2.92,4.58,5.56c0,3.39-2.64,6.12-7.03,6.12H108.43z M115.65,3.01h-4.71v7.29h4.71c2.7,0,4.33-1.63,4.33-3.64
				S118.35,3.01,115.65,3.01z M116.66,12.47h-5.72v8.26h5.78c2.83,0,4.52-1.92,4.52-4.11C121.24,14.38,119.51,12.47,116.66,12.47z"
    />
    <path
      fill="#4B4D4F"
      d="M130.32,23.21c-3.08,0-5.09-1.82-5.09-4.3c0-2.76,1.57-4.27,5.72-4.99l4.84-0.88v-0.72
				c0-2.29-1.48-3.89-3.89-3.89c-2.29,0-3.61,1.26-4.08,3.27l-2.23-0.53c0.5-2.48,2.51-4.8,6.44-4.8c3.96,0,6.12,2.42,6.12,5.97
				v10.55h-2.32v-2.07C134.43,22.23,132.42,23.21,130.32,23.21z M127.65,18.68c0,1.54,1.16,2.51,3.01,2.51
				c2.07,0,3.8-1.04,5.12-2.48v-3.77l-3.42,0.6C128.72,16.24,127.65,17.05,127.65,18.68z"
    />
    <path
      fill="#4B4D4F"
      d="M140.5,22.86V6.66h2.32l0.03,1.82c1.35-1.38,3.2-2.17,5.24-2.17c3.55,0,5.81,2.42,5.81,5.9v10.65h-2.36
				V12.66c0-2.76-1.6-4.21-3.89-4.21c-1.6,0-3.33,0.63-4.8,2.17v12.25H140.5z"
    />
    <path
      fill="#4B4D4F"
      d="M156.27,22.86V0h2.36v15.2l7.88-8.54h2.95l-6.56,6.97l7.1,9.23h-2.98l-5.75-7.51l-2.64,2.79v4.71H156.27z"
    />
    <path
      fill="#4B4D4F"
      d="M174.39,12.42c-1.81,0-3.26-1.44-3.26-3.3s1.46-3.3,3.26-3.3s3.28,1.44,3.28,3.3S176.2,12.42,174.39,12.42z
				 M174.39,11.78c1.46,0,2.58-1.13,2.58-2.65c0-1.52-1.13-2.65-2.58-2.65c-1.44,0-2.55,1.13-2.55,2.65
				C171.84,10.65,172.95,11.78,174.39,11.78z M173.22,10.67V7.55h1.39c0.56,0,0.98,0.33,0.98,0.91c0,0.35-0.22,0.65-0.55,0.83
				l0.81,1.38h-0.75l-0.71-1.24h-0.5v1.24H173.22z M174.49,8.07h-0.6v0.85h0.56c0.31,0,0.46-0.2,0.46-0.41
				C174.92,8.25,174.77,8.07,174.49,8.07z"
    />
    <path
      fill="#4B4D4F"
      d="M109.12,29.44l0.01,3.14h-0.71V27.7h0.95l1.28,2.96l0.25,0.61l0.25-0.61l1.28-2.96h0.95v4.88h-0.73
					l0.01-3.14l0.01-0.68l-0.29,0.7l-1.21,2.78h-0.59l-1.21-2.78l-0.28-0.68L109.12,29.44z"
    />
    <path
      fill="#4B4D4F"
      d="M116.1,32.66c-0.99,0-1.82-0.78-1.82-1.9c0-1.13,0.77-1.89,1.72-1.89c1.14,0,1.69,0.88,1.69,1.85
					c0,0.08,0,0.15-0.01,0.24H115c0.08,0.65,0.52,1.1,1.11,1.1c0.42,0,0.75-0.18,0.99-0.53l0.53,0.31
					C117.3,32.36,116.75,32.66,116.1,32.66z M116,29.46c-0.53,0-0.89,0.42-0.99,0.98h1.97C116.93,29.88,116.57,29.46,116,29.46z"
    />
    <path
      fill="#4B4D4F"
      d="M118.52,32.58v-3.64h0.71l0.01,0.41c0.28-0.3,0.66-0.48,1.12-0.48c0.51,0,0.9,0.24,1.1,0.61
					c0.3-0.37,0.74-0.61,1.28-0.61c0.79,0,1.28,0.55,1.28,1.32v2.39h-0.72v-2.22c0-0.56-0.31-0.85-0.76-0.85
					c-0.36,0-0.66,0.17-0.93,0.46c0.01,0.07,0.01,0.15,0.01,0.22v2.39h-0.72v-2.22c0-0.56-0.32-0.85-0.77-0.85
					c-0.33,0-0.64,0.16-0.91,0.43v2.64H118.52z"
    />
    <path
      fill="#4B4D4F"
      d="M125.08,32.58v-5.08h0.72v1.81c0.27-0.28,0.64-0.43,1.07-0.43c0.98,0,1.71,0.79,1.71,1.89
					c0,1.07-0.76,1.88-1.76,1.89c-0.44,0-0.84-0.14-1.14-0.41l-0.14,0.33H125.08z M126.73,29.51c-0.37,0-0.68,0.15-0.93,0.44v1.65
					c0.25,0.29,0.57,0.43,0.93,0.43c0.66,0,1.13-0.52,1.13-1.26C127.86,30.05,127.4,29.51,126.73,29.51z"
    />
    <path
      fill="#4B4D4F"
      d="M131,32.66c-0.99,0-1.82-0.78-1.82-1.9c0-1.13,0.77-1.89,1.72-1.89c1.14,0,1.69,0.88,1.69,1.85
					c0,0.08,0,0.15-0.01,0.24h-2.69c0.08,0.65,0.52,1.1,1.11,1.1c0.42,0,0.75-0.18,0.99-0.53l0.53,0.31
					C132.2,32.36,131.65,32.66,131,32.66z M130.89,29.46c-0.53,0-0.89,0.42-0.99,0.98h1.97C131.83,29.88,131.46,29.46,130.89,29.46z
					"
    />
    <path
      fill="#4B4D4F"
      d="M133.42,32.58v-3.64h0.71l0.01,0.59c0.35-0.54,0.87-0.72,1.37-0.66l0.03,0.67c-0.61-0.08-1.08,0.1-1.4,0.61
					v2.43H133.42z"
    />
    <path fill="#4B4D4F" d="M138.41,32.58h-0.77V27.7H141v0.63h-2.59v1.45h1.99v0.63h-1.99V32.58z" />
    <path
      fill="#4B4D4F"
      d="M141.82,32.58V27.7h1.79c1.44,0,2.41,1.04,2.41,2.44s-1.01,2.44-2.47,2.44H141.82z M143.5,28.35h-0.91v3.58
					h0.93c1.07,0,1.74-0.77,1.74-1.79S144.58,28.35,143.5,28.35z"
    />
    <path fill="#4B4D4F" d="M146.63,31.99h0.82V28.3h-0.82v-0.6h2.4v0.6h-0.82v3.69h0.82v0.59h-2.4V31.99z" />
    <path
      fill="#4B4D4F"
      d="M152.04,31.98c0.64,0,1.1-0.29,1.4-0.87l0.66,0.27c-0.4,0.79-1.07,1.28-2.06,1.28
					c-1.33,0-2.4-1.02-2.4-2.52s1.07-2.52,2.41-2.52c1,0,1.67,0.47,2.07,1.28l-0.68,0.29c-0.31-0.59-0.76-0.89-1.4-0.89
					c-0.9,0-1.62,0.73-1.62,1.83C150.41,31.22,151.11,31.97,152.04,31.98z"
    />
    <path
      fill="#FF612B"
      d="M73.82,22.85V6.01h5.34l0.03,2.01c1.07-1.54,2.64-2.26,4.46-2.26c2.29,0,3.93,1.07,4.81,2.73
		c1.07-1.82,3.08-2.73,5.15-2.73c3.68,0,5.69,2.77,5.69,6.25v10.84h-5.44V12.82c0-1.63-1.04-2.61-2.29-2.61s-2.29,0.97-2.29,2.61
		v10.02h-5.44V12.82c0-1.63-1.04-2.61-2.29-2.61c-1.26,0-2.29,0.97-2.29,2.61v10.02H73.82z M71.99,16.44c0,4.34-3.46,6.79-7.86,6.79
		c-4.4,0-7.86-2.45-7.86-6.79V6.01h5.47v10.28c0,1.48,0.97,2.42,2.39,2.42c1.41,0,2.39-0.94,2.39-2.42V6.01h5.47V16.44z M54.8,10.34
		V6.01h-4.49V1.7h-5.44v15.12c0,4.18,2.48,6.29,6.47,6.29c1.16,0,2.39-0.13,3.46-0.57v-4.31c-0.79,0.25-1.48,0.35-2.07,0.35
		c-1.45,0-2.42-0.66-2.42-2.2v-6.03H54.8z M37.99,14.43c0,2.42-1.76,4.21-3.93,4.21c-2.2,0-3.96-1.79-3.96-4.21
		c0-2.39,1.76-4.18,3.96-4.18C36.23,10.25,37.99,12.04,37.99,14.43z M30.1,29.39v-8.3c1.38,1.29,3.21,2.04,5.22,2.04
		c4.56,0,8.14-3.87,8.14-8.7c0-4.81-3.58-8.67-8.14-8.67c-2.11,0-3.96,0.82-5.37,2.2l-0.03-1.95h-5.28v23.38H30.1z M17.22,11.85
		c0,3.46-2.55,6.03-5.72,6.03s-5.72-2.58-5.72-6.03c0-3.46,2.55-6.03,5.72-6.03S17.22,8.39,17.22,11.85z M11.5,0.6
		C5.06,0.6,0,5.6,0,11.85S5.06,23.1,11.5,23.1c6.44,0,11.5-5,11.5-11.25S17.94,0.6,11.5,0.6z"
    />
  </Icon>
);
