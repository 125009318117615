import { Box } from '@chakra-ui/react';
import { Group, Image, Link, Logo, Text } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { AmexLogo, DinersClubLogo, DiscoverLogo, MasterCardLogo, VisaLogo } from './assets';

const logosProps = [
  { logo: MasterCardLogo, alt: 'Master card logo' },
  { logo: DinersClubLogo, alt: 'Diners club logo' },
  { logo: VisaLogo, alt: 'Visa logo' },
  { logo: DiscoverLogo, alt: 'Discover logo' },
  { logo: AmexLogo, alt: 'Amex logo' },
];
export const PartnerFooter = forwardRef(({ ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl, helpCenterUrl } = useConfig().settings;

  return (
    <Group data-testid="partner-footer" variant="vertical" spacing="s" {...props} ref={ref}>
      {/*Currently we are presenting melio logo in the future we can support also the partners logos*/}
      <Logo size="large" type="dark" />
      <Text textStyle="body4Semi">{formatMessage('vex.layout.partnerFooter.subTitle')}</Text>
      <Box display="flex" gap="m" alignItems="center" flexWrap="wrap">
        {logosProps.map((logoProps) => (
          <Image alt={logoProps.alt} key={logoProps.alt} src={logoProps.logo} />
        ))}
      </Box>
      <Group variant="vertical" spacing="xs">
        <Group hasDivider>
          <Box color="global.neutral.800" textStyle="body4">
            <Link
              href={privacyPolicyUrl}
              label={formatMessage('vex.layout.partnerFooter.links.privacyPolicy.label')}
              color="inherit"
              newTab
            />
          </Box>
          <Box color="global.neutral.800" textStyle="body4">
            <Link
              href={termsOfServiceUrl}
              label={formatMessage('vex.layout.partnerFooter.links.termsOfService.label')}
              color="inherit"
              newTab
            />
          </Box>
        </Group>
        <Group>
          <Text textStyle="body4" color="global.neutral.800">
            {formatMessage('vex.layout.partnerFooter.helpCenter.text')}&nbsp;
            <Link
              href={helpCenterUrl}
              label={formatMessage('vex.layout.partnerFooter.links.helpCenter.label')}
              newTab
            />
          </Text>
        </Group>
      </Group>
    </Group>
  );
});

PartnerFooter.displayName = 'PartnerFooter';
