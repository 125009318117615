export enum VendorAction {
  AcceptPaymentWithMsnEnrollment = 'acceptPaymentWithMsnEnrollment',
  AcceptPayment = 'acceptPayment',
  ReadVirtualCardDetails = 'readVirtualCardDetails',
  ShiftVirtualCardToACH = 'shiftVirtualCardToACH',
  UpsellPayment = 'upsellPayment',
  RegisterPartnerVendor = 'registerPartnerVendor',
  VendorPaymentTracking = 'vendorPaymentTracking',
  CollectW9 = 'vendorProvideW9',
}

interface AcceptPaymentActionType {
  action: VendorAction.AcceptPaymentWithMsnEnrollment | VendorAction.AcceptPayment;
  paymentId?: string;
  vendorId: string;
  unilateralRequestId?: string;
  newUnilateralVariant?: boolean;
}
interface ReadVirtualCardDetailsActionType {
  action: VendorAction.ReadVirtualCardDetails;
  paymentId: string;
  virtualCardId: string;
}

interface ShiftVirtualCardToACHActionType {
  action: VendorAction.ShiftVirtualCardToACH;
  paymentId: string;
  virtualCardId: string;
}

interface UpsellPaymentActionType {
  action: VendorAction.UpsellPayment;
  paymentId: string;
}

interface VendorPaymentTrackingType {
  action: VendorAction.VendorPaymentTracking;
  paymentId: string;
  vendorId: string;
}

interface RegisterPartnerVendorActionType {
  action: VendorAction.RegisterPartnerVendor;
  onboardingSessionUuid: string;
}

interface VendorCollectW9ActionType {
  action: VendorAction.CollectW9;
  vendorId: string;
}

export type ActionData =
  | AcceptPaymentActionType
  | ReadVirtualCardDetailsActionType
  | ShiftVirtualCardToACHActionType
  | UpsellPaymentActionType
  | VendorPaymentTrackingType
  | RegisterPartnerVendorActionType
  | VendorCollectW9ActionType;
export type TokenPayload = {
  accountId?: string;
  account?: { accountName: string };
  claims: { paymentId: string; vendorId: string };
  user?: { email: string };
  organization?: { companyName: string };
};
