import { useToast } from '@melio/penny';
import { useCollectW9RequestZenworkUrl, useZenworkRequestCompleted } from '@melio/platform-api/src/vex/collect-w9';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

export const useZenworkW9Form = (vendorId: string) => {
  const { mutateAsync: requestZenworkUrl, isLoading: isLoadingZenworkUrl } = useCollectW9RequestZenworkUrl(vendorId);
  const { mutateAsync: onCompleteZenworkForm } = useZenworkRequestCompleted(vendorId);
  const [zenworkLink, setZenworkLink] = useState<string | null>(null);
  const [showIFrame, setShowIFrame] = useState<boolean>(false);
  const [isZenworkFormCompleted, setIsZenworkFormCompleted] = useState<boolean>(false);
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const ZENWORK_ORIGIN = 'https://classic.1099cloud.com';
  const SUCCESS_MESSAGE = 'Form successfully saved.';

  useEffect(() => {
    const handleEvent = (event: MessageEvent<string>) => {
      if (event.origin !== ZENWORK_ORIGIN) {
        return;
      }
      if (event.data === SUCCESS_MESSAGE) {
        void onCompleteZenworkForm(vendorId);
        setShowIFrame(false);
        setIsZenworkFormCompleted(true);
      }
    };

    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestZenworkUrl = async () => {
    try {
      const { data: zenWorkLinkResponse } = await requestZenworkUrl(vendorId);
      setZenworkLink(zenWorkLinkResponse.link);
      setShowIFrame(true);
    } catch (e) {
      toast({ type: 'error', title: formatMessage('vex.screen.collectW9File.error.general.title') });
      throw e;
    }
  };

  return {
    handleRequestZenworkUrl,
    zenworkLink,
    showIFrame,
    isZenworkFormCompleted,
    isLoadingZenworkUrl,
  };
};
