import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformBillLineItemLabelsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatformsBillLineItemLabelsById
> & { accountingPlatformId?: string; noInvalidations?: boolean };

export const useAccountingPlatformBillLineItemLabels = (props: UseAccountingPlatformBillLineItemLabelsProps) => {
  const { accountingPlatformId, noInvalidations } = props;
  const meta = { noInvalidations };
  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatformsBillLineItemLabelsById>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'billLineItemLabels', accountingPlatformId],
    queryFn: () =>
      AccountingPlatformsApiClient.getAccountingPlatformsBillLineItemLabelsById(accountingPlatformId as string),
    enabled: !!accountingPlatformId && props.enabled,
    meta,
  });

  return {
    ...query,
    hasAccountingPlatformBillLineItemLabels: !!query.data?.length,
  };
};
