import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { extractData, IndustriesApiClient } from '@melio/platform-api-axios-client';

export type UseIndustryTypesProps = UseCollectionApiProps<typeof IndustriesApiClient.getIndustryTypes> & {
  params: { q: string; limit?: number; sixDigitNaicsOnly?: boolean };
};

export const useIndustryTypes = ({ params, ...props }: UseIndustryTypesProps) => {
  const query = useCollectionApi({
    ...props,
    enabled: props.enabled !== false && !!params.q,
    queryKey: ['IndustriesApi', params],
    queryFn: () => IndustriesApiClient.getIndustryTypes(params.q, params.limit, params.sixDigitNaicsOnly),
  });

  return {
    ...query,
    fetch: (params: UseIndustryTypesProps['params']) =>
      IndustriesApiClient.getIndustryTypes(params.q, params?.limit).then(extractData),
  };
};

export type IndustryTypesCollection = ReturnType<typeof useIndustryTypes>;
