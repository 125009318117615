import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { PlansApiClient } from '@melio/platform-api-axios-client';

import { Plan, PlansApiGetPlansMeRequest } from './types';

type QueryFn = typeof PlansApiClient.getPlansMe;

export type UsePlansMeProps = UseCollectionApiProps<QueryFn, Plan> & {
  params?: PlansApiGetPlansMeRequest;
};

export const usePlansMe = ({ params, ...props }: UsePlansMeProps = {}) =>
  useCollectionApi({
    ...props,
    queryKey: ['PlansApi', 'me', params],
    queryFn: () => PlansApiClient.getPlansMe(params?.melioAccount),
  });
