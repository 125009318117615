import * as braze from '@braze/web-sdk';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DoubleButtonModal } from './DoubleButtonModal';
import { SingleButtonModal } from './SingleButtonModal';
import { ModalProps, ModalType } from './types';

export const Modal = ({
  message,
  clearMessage,
  logButtonClick,
  logMessageClick,
  logImpression,
  properties,
}: ModalProps) => {
  const navigate = useNavigate();

  const {
    buttons: [firstButton, secondButton],
  } = message;

  useEffect(() => {
    logImpression();
  }, [logImpression]);

  // We must have an image, a message, and at least one button to render.
  if (!message.imageUrl || !message.message || !firstButton) {
    return null;
  }

  const logAndClear = (button: braze.InAppMessageButton) => {
    logButtonClick(button);
    clearMessage();
  };

  const route = (uri: string, openInNewTab: boolean) => {
    if (uri.startsWith('/')) {
      if (openInNewTab) {
        const fullUrl = `${window.location.origin}/melio${uri}`;
        return window.open(fullUrl);
      }
      return navigate(uri);
    }

    if (openInNewTab) {
      return window.open(uri);
    }

    return window.location.replace(uri);
  };

  const handleClick = (button: braze.InAppMessageButton, openInNewTab: boolean) => {
    logAndClear(button);
    if (button.uri) {
      route(button.uri, openInNewTab);
    }
  };

  const onClose = () => {
    logMessageClick();
    clearMessage();
  };

  const modalType = secondButton ? ModalType.DOUBLE_BUTTON : ModalType.SINGLE_BUTTON;

  if (modalType === ModalType.DOUBLE_BUTTON) {
    return (
      <DoubleButtonModal
        onClose={onClose}
        onButtonClick={handleClick}
        logImpression={logImpression}
        message={message}
        properties={properties}
      ></DoubleButtonModal>
    );
  }

  return (
    <SingleButtonModal
      onButtonClick={handleClick}
      onClose={onClose}
      logImpression={logImpression}
      message={message}
      properties={properties}
    ></SingleButtonModal>
  );
};
