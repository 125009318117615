import { ThemeOptions } from '@melio/penny';

import { MelioLogo, MelioLogoDark } from '@/cl/theme/partners/melio/logos';

// off-the-shelf partners that we'll eventually move to store the config in db
export const gustoTheme: ThemeOptions = {
  logos: {
    dark: MelioLogoDark,
    light: MelioLogo,
  },
  colors: {
    global: {
      brand: {
        100: '#9DCCCC',
        200: '#6CB3B3',
        300: '#3B9999',
        400: '#3B9999',
        500: '#0A8080',
        600: '#0A8080',
        700: '#0A8080',
        800: '#086666',
        900: '#086666',
        1000: '#064D4D',
      },
    },
    // deprecated palette
    brand: {
      darkest: '#064D4D',
      dark: '#086666',
      main: '#0A8080',
      light: '#3B9999',
      lighter: '#6CB3B3',
      lightest: '#9DCCCC',
    },
  },
};
