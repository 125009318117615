/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useToast } from '@melio/penny';

import { useSetAppToast } from '@/store/app/app.model';
import { appToastSelector } from '@/store/app/app.model';

const TOAST_POSITION = 'top';

type Props = {
  children: ReactNode;
};

export const ToastProvider = ({ children }: Props) => {
  const { toast } = useToast();
  const appToast = useRecoilValue(appToastSelector);
  const setAppToast = useSetAppToast();

  useEffect(() => {
    if (appToast) {
      toast({
        ...appToast,
        duration: null,
        position: TOAST_POSITION,
        onCloseComplete: () => setAppToast(undefined),
      });
    }
  }, [appToast]);

  return <>{children}</>;
};
