import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { VendorOnboardingInfoWidget } from '@melio/vex-widgets';
import { useEffect } from 'react';

type VendorOnboardingInfoActivityProps = {
  isOpened?: boolean;
  isDisabled?: boolean;
  isFilled: boolean;
  onOpen: () => void;
  onSubmit: () => void;
  feeCap: number;
  feePercentage: number;
};

export const VendorOnboardingInfoActivity = withAnalyticsContext<VendorOnboardingInfoActivityProps>(
  forwardRef(({ onSubmit, setAnalyticsProperties, ...props }, ref) => {
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'set-up-your-account',
      Intent: 'sign-up',
    });

    const onContinueClick = () => {
      onSubmit();
      track('Connection', 'Click', {
        Cta: 'lets-go',
      });
    };

    const onPrivacyPolicyClick = () => {
      track('Connection', 'Click', {
        Cta: 'privacy-policy',
      });
    };

    const onTermsOfServiceClick = () => {
      track('Connection', 'Click', {
        Cta: 'terms-of-service',
      });
    };

    useEffect(() => {
      if (props.isOpened) {
        track('Connection', 'View', {});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpened, track]);

    return (
      <VendorOnboardingInfoWidget
        ref={ref}
        onPrivacyPolicyClick={onPrivacyPolicyClick}
        onTermsOfServiceClick={onTermsOfServiceClick}
        onSubmit={onContinueClick}
        {...props}
      />
    );
  })
);

VendorOnboardingInfoActivity.displayName = 'VendorOnboardingInfoActivity';
