import { Account, DataToPrefillAnOnboardingFormUser } from '@melio/platform-api';
import { defaults, isEmpty, omitBy } from 'lodash';
import { useMemo } from 'react';

import { useDayOfBirthDate } from './useDayOfBirthDate';

export const useMergePrePopulatedUserData = (
  accountData: Omit<Account['user'], 'email'>,
  prePopulateUser: DataToPrefillAnOnboardingFormUser
) => {
  const mergedPersonalData = useMemo(
    () => defaults(omitBy(accountData, isEmpty), prePopulateUser),
    [accountData, prePopulateUser]
  );

  const formattedDateOfBirth = useDayOfBirthDate(mergedPersonalData?.dateOfBirth);

  return {
    ...mergedPersonalData,
    dateOfBirth: formattedDateOfBirth,
  };
};
