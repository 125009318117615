export const NewEmail: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_132157)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72C126 101.823 101.823 126 72 126Z"
        fill="#273B49"
      />
      <mask
        id="mask0_11475_132157"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72C126 101.823 101.823 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_132157)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.68 57.5396H98.3792H102.399V82.2713C102.399 85.8501 99.4977 88.7513 95.9189 88.7513H47.16C43.5812 88.7513 40.68 85.8501 40.68 82.2713V57.5396Z"
          fill="#008BC1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.68 57.5396L61.366 75.9021H82.5924L102.399 57.5396V96.7167H40.68V57.5396Z"
          fill="#98D5EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.3167 75.8599H82.5431L102.35 96.6744C102.35 96.6744 40.6967 96.6744 40.6307 96.6744C40.5647 96.6744 54.5502 82.6682 54.5502 82.6682L61.3167 75.8599Z"
          fill="#C4EFFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.5394 38.6987L102.399 57.5395H40.68L71.5394 38.6987Z"
          fill="#008BC1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.4617 50.9754C47.4617 49.7825 48.4288 48.8154 49.6217 48.8154H93.456C94.6489 48.8154 95.616 49.7825 95.616 50.9754V66.509L82.2746 76.2143H61.4886L47.4617 66.509V50.9754Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.1151 70.1419C67.577 70.1419 64.9526 67.4787 64.9526 64.0378C64.9526 59.8387 68.6267 56.3784 72.6703 56.3784C76.3444 56.3784 78.7939 59.1389 78.7939 62.5409C78.7939 65.5735 77.0637 67.1287 75.4307 67.1287C74.3421 67.1287 73.72 66.3706 73.6617 65.5541V65.418C72.9813 66.39 71.8149 67.1287 70.5707 67.1287C68.7628 67.1287 67.5964 65.8457 67.5964 63.96C67.5964 61.2579 69.8709 59.0806 72.1648 59.0806C73.4284 59.0806 74.2838 59.761 74.6531 60.5969L74.9059 59.3916H76.2278L75.1197 64.6599C75.1003 64.7765 75.0808 64.9515 75.0808 65.0681C75.0808 65.7096 75.4891 66.079 75.9945 66.079C76.7527 66.079 78.1135 65.2236 78.1135 62.5409C78.1135 59.3527 75.9167 57.0394 72.6119 57.0394C68.8406 57.0394 65.633 60.3636 65.633 63.9989C65.633 67.1093 67.9658 69.5004 71.1928 69.5004C72.5731 69.5004 73.7589 69.0727 74.8087 68.4118L75.1586 68.9172C73.9533 69.6948 72.5536 70.1419 71.1151 70.1419ZM70.979 66.0401C72.3009 66.0401 73.2923 65.107 73.8561 64.2905L74.4199 61.5883C74.206 60.9857 73.5645 60.1692 72.4175 60.1692C70.4735 60.1692 68.9961 61.9966 68.9961 63.8823C68.9961 65.1264 69.7154 66.0401 70.979 66.0401Z"
          fill="#273B49"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_132157">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
