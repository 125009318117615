export const Declined: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12006_608431)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
        fill="#273B49"
      />
      <mask
        id="mask0_12006_608431"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_12006_608431)">
        <circle cx="72" cy="72" r="38" stroke="#D0021B" strokeWidth="6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.0329 77.6353C71.568 77.1001 72.4357 77.1001 72.9709 77.6353L87.3598 92.0242C87.8949 92.5594 88.7626 92.5594 89.2978 92.0242L92.0244 89.2975C92.5596 88.7624 92.5596 87.8947 92.0244 87.3595L77.6355 72.9706C77.1004 72.4355 77.1004 71.5678 77.6355 71.0326L92.025 56.6432C92.5601 56.108 92.5601 55.2404 92.025 54.7052L89.2983 51.9785C88.7631 51.4434 87.8954 51.4434 87.3603 51.9785L72.9709 66.368C72.4357 66.9031 71.568 66.9031 71.0329 66.368L56.6434 51.9785C56.1083 51.4434 55.2406 51.4434 54.7054 51.9785L51.9788 54.7052C51.4436 55.2404 51.4436 56.108 51.9788 56.6432L66.3682 71.0326C66.9034 71.5678 66.9034 72.4355 66.3682 72.9706L51.9793 87.3595C51.4441 87.8947 51.4441 88.7624 51.9793 89.2975L54.706 92.0242C55.2411 92.5594 56.1088 92.5594 56.6439 92.0242L71.0329 77.6353Z"
          fill="#D03027"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_12006_608431">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
