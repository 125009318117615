import { useState } from 'react';

import { AddBankDeliveryMethodByPayeeActivityProps, AddBankDeliveryMethodByPayeeStep } from './types';

export const useAddBankDeliveryMethodByPayeeStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: AddBankDeliveryMethodByPayeeActivityProps['onBack'];
}) => {
  const [currentStep, goToStep] = useState<AddBankDeliveryMethodByPayeeStep>('ADD_BANK_DETAILS');

  const goToPreviousStep = () => {
    currentStep === 'BANK_DETAILS_ADDED_SUCCESSFULLY' ? goToStep('ADD_BANK_DETAILS') : onFirstStepBack();
  };

  return { currentStep, goToStep, goToPreviousStep };
};
