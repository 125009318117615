import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { PayorPendingPaymentDetailsWidget } from '../PayorPendingPaymentDetails';

export type DeliveryMethodSelectionByPayeeHeaderWidgetProps = {
  accountName: string;
  paymentAmount?: number;
  paymentInvoiceNumber?: string | null;
  paymentNote?: string;
  showAvatar?: boolean;
};

export const DeliveryMethodSelectionByPayeeHeaderWidget = forwardRef<
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  'div'
>(({ accountName, paymentAmount, paymentInvoiceNumber, paymentNote, showAvatar = true, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  const description = paymentAmount
    ? formatMessage('widgets.deliveryMethodSelectionByPayeeHeader.descriptionWithPayment', {
        accountName,
      })
    : formatMessage('widgets.deliveryMethodSelectionByPayeeHeader.description', {
        accountName,
      });

  return (
    <PayorPendingPaymentDetailsWidget
      data-component="DeliveryMethodSelectionByPayeeHeaderWidget"
      description={description}
      accountName={accountName}
      paymentAmount={paymentAmount}
      paymentInvoiceNumber={paymentInvoiceNumber}
      paymentNote={paymentNote}
      paymentInvoiceNumberLabel={formatMessage(
        'widgets.deliveryMethodSelectionByPayeeHeader.paymentInvoiceNumberLabel'
      )}
      paymentNoteLabel={formatMessage('widgets.deliveryMethodSelectionByPayeeHeader.paymentNoteLabel')}
      showAvatar={showAvatar}
      {...props}
      ref={ref}
    />
  );
});

DeliveryMethodSelectionByPayeeHeaderWidget.displayName = 'DeliveryMethodSelectionByPayeeHeaderWidget';
