import { useNonEntityMutation } from '@melio/api-client';
import {
  ApiKeys,
  PostVendorsCopyDeliveryMethodResponse,
  VexDeliveryMethodsApiClient,
} from '@melio/platform-api-axios-client';

type CreateFunction = typeof VexDeliveryMethodsApiClient.acceptUnilateralPayment;

export const useAcceptUnilateralPayment = (props?: Parameters<typeof useNonEntityMutation<CreateFunction>>[2]) => {
  const acceptUnilateralMutation = useNonEntityMutation<CreateFunction>(
    (deliveryMethodId: string) => VexDeliveryMethodsApiClient.acceptUnilateralPayment(deliveryMethodId),
    [ApiKeys.VexDeliveryMethodsApi, 'collection', 'default'],
    {
      ...props,
    }
  );

  const copyDeliveryMethodOwnedToLocalVendor = async (deliveryMethodId: string) => {
    const response = (await acceptUnilateralMutation.mutateAsync(
      deliveryMethodId
    )) as PostVendorsCopyDeliveryMethodResponse;
    return { deliveryMethodId: response?.data?.deliveryMethod?.id };
  };

  return {
    ...acceptUnilateralMutation,
    copyDeliveryMethodOwnedToLocalVendor,
  };
};
