import { DeliveryMethodType } from '@melio/platform-api';
import { useWizard, UseWizardArgs } from '@melio/platform-utils';
import { noop } from 'lodash';
import { FC, ReactElement, ReactNode, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AcceptPaymentWithBankAccountDeliveryMethodParams, BankAccountFormModel } from '../../types';
import { AddBankAccountActivity } from '../add-bank-account';
import { ConfirmDeliveryMethodActivity } from './ConfirmDeliveryMethod.activity';

type Steps = 'confirm-bank-account' | 'add-bank-account';

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  ['confirm-bank-account']: 'confirm-bank-account',
  ['add-bank-account']: 'add-bank-account',
};

const navigationMap: UseWizardArgs<Steps>['navigationMap'] = {
  ['confirm-bank-account']: noop,
  ['add-bank-account']: noop,
};

type CreateOrConfirmDeliveryMethodWizardProps = {
  onAcceptPayment: (params: AcceptPaymentWithBankAccountDeliveryMethodParams) => Promise<void>;
  onBack: () => void;
  onError: () => void;
  contentHeaderTitle: ReactNode;
  deliveryMethodType: DeliveryMethodType;
  isDeliveryMethodExist: boolean;
  cancelUrlFallback: string;
  organizationId: string | undefined;
  payorOrganizationName: string;
  header: ReactElement | undefined;
};

export const CreateOrConfirmDeliveryMethodWizard: FC<CreateOrConfirmDeliveryMethodWizardProps> = ({
  contentHeaderTitle,
  onAcceptPayment,
  onBack,
  onError,
  isDeliveryMethodExist,
  deliveryMethodType,
  cancelUrlFallback,
  organizationId,
  payorOrganizationName,
  header,
}) => {
  const firstStepRef = useRef<Steps>(isDeliveryMethodExist ? 'confirm-bank-account' : 'add-bank-account');

  useWizard<Steps, typeof navigationMap>({
    flowName: 'create-or-confirm-delivery-method',
    firstStep: firstStepRef.current,
    locationsMap,
    navigationMap,
    cancelUrlFallback,
  });

  const onSubmitBankAccountDetails = (bankAccountData: BankAccountFormModel) => onAcceptPayment({ bankAccountData });
  const onConfirmBankAccount = (existingDeliveryMethodId: string) => onAcceptPayment({ existingDeliveryMethodId });

  return (
    <Routes>
      <Route
        path="add-bank-account/*"
        element={
          <AddBankAccountActivity
            contentHeaderTitle={contentHeaderTitle}
            payorOrganizationName={payorOrganizationName}
            onSubmitBankAccountDetails={onSubmitBankAccountDetails}
            onBack={onBack}
            header={header}
          />
        }
      />
      <Route
        path="confirm-bank-account"
        element={
          <ConfirmDeliveryMethodActivity
            contentHeaderTitle={contentHeaderTitle}
            onDone={onConfirmBankAccount}
            onBack={onBack}
            onError={onError}
            deliveryMethodType={deliveryMethodType}
            organizationId={organizationId}
            header={header}
          />
        }
      />
    </Routes>
  );
};

CreateOrConfirmDeliveryMethodWizard.displayName = 'CreateOrConfirmDeliveryMethodWizard';
