import { Card, ExternalLayout, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

type CardAddedSuccessfullyScreenProps = {
  accountName: string;
};

// TODO: after standardizing gaps and spacing fix this screen -
// https://linear.app/meliopayments/issue/PLA-749/after-standardizing-gaps-and-margins-fix-addbankdeliverymethodbyvendor
export const CardAddedSuccessfullyScreen = forwardRef<CardAddedSuccessfullyScreenProps, 'div'>(
  ({ accountName, children, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    useAnalyticsView('PushToDebitAddCardSucceeded');
    const content = (
      <Card paddingX="xl" paddingY="l">
        <Group variant="vertical" spacing="m">
          <Group variant="vertical">
            <StatusIconSolid variant="success" size="large" />
            <Text as="h1" textStyle="body2Semi" data-testid="layout-title">
              {formatMessage('activities.pushToDebit.screens.cardAddedSuccessfully.title')}
            </Text>
            <Text>
              {formatMessage('activities.pushToDebit.screens.cardAddedSuccessfully.onItsWayText', {
                accountName,
              })}
            </Text>
          </Group>
          <Text textStyle="body4" color="global.neutral.900">
            {formatMessage('activities.pushToDebit.screens.cardAddedSuccessfully.inCaseOfIssueText')}
          </Text>
        </Group>
      </Card>
    );

    return (
      <ExternalLayout
        data-component="PushToDebitActivity.CardAddedSuccessfullyScreen"
        data-testid="push-to-debit-activity-card-added-successfully-screen"
        {...props}
        content={content}
        ref={ref}
      />
    );
  }
);

CardAddedSuccessfullyScreen.displayName = 'PushToDebitActivity.CardAddedSuccessfullyScreen';
