import { ExternalLayout, Group, SelectionCard, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { getDeliveryMethodIconKey, useGetDeliveryMethodDescription, usePageView } from '@melio/vex-domain';
import { ExternalLayoutContentWidget, FooterWidget } from '@melio/vex-widgets';
import { ReactElement } from 'react';

export type ConfirmDeliveryMethodScreenProps = {
  companyName?: string;
  deliveryMethod: DeliveryMethod;
  contentHeaderTitle?: React.ReactNode;
  onDone: () => Promise<void>;
  isSaving?: boolean;
  onBack?: VoidFunction;
  header: ReactElement | undefined;
};

export const ConfirmDeliveryMethodScreen = forwardRef<ConfirmDeliveryMethodScreenProps, 'div'>(
  (
    { companyName, deliveryMethod, onDone, onBack, isSaving, contentHeaderTitle, header, children: _, ...props },
    ref
  ) => {
    const analyticsProperties = {
      PageName: 'current-receiving-method',
      DeliveryMethodType: deliveryMethod.type,
      DeliveryMethodId: deliveryMethod.id,
    };
    usePageView('DeliveryMethod', {
      Intent: 'confirm-delivery-method',
      ...analyticsProperties,
    });
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const titleText = companyName
      ? formatMessage('vex.screen.confirmDeliveryMethod.titleWithCompanyName', { companyName })
      : formatMessage('vex.screen.confirmDeliveryMethod.title');

    const handleDone = async () => {
      track('DeliveryMethod', 'Click', {
        Cta: 'confirm-and-continue',
        ...analyticsProperties,
      });
      await onDone();
    };

    const footer = <FooterWidget />;

    const content = (
      <ExternalLayoutContentWidget
        data-testid="confirm-delivery-method-screen"
        header={{
          backButtonProps: onBack ? { onClick: onBack } : undefined,
          title: contentHeaderTitle,
        }}
        form={{
          title: '',
          fields: (
            <Group variant="vertical" spacing="l">
              <Text textStyle="heading3Semi">{titleText}</Text>
              <SelectionCard
                icon={getDeliveryMethodIconKey(deliveryMethod.type)}
                mainLabelProps={{
                  label: useGetDeliveryMethodDescription(deliveryMethod),
                }}
                descriptionProps={{
                  label: formatMessage('vex.screen.confirmDeliveryMethod.description.label'),
                }}
              />
            </Group>
          ),
        }}
        continueSection={{
          buttonProps: {
            label: formatMessage('vex.screen.confirmDeliveryMethod.continueButton'),
            onClick: handleDone,
            isLoading: isSaving,
          },
          displayLegalInfo: false,
        }}
      />
    );

    return (
      <ExternalLayout
        data-component="ConfirmDeliveryMethodScreen"
        ref={ref}
        content={content}
        footer={footer}
        header={header}
        {...props}
      />
    );
  }
);

ConfirmDeliveryMethodScreen.displayName = 'ConfirmDeliveryMethodScreen';
