import { Group } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  useResolveEmailVerificationCode,
  UserRegistrationFlowEnum,
  useSendEmailValidationCode,
  useVexMyOrganization,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import {
  CodeVerificationFormCardWidget,
  CollapsibleCardFormWidget,
  EmailVerificationFormCardWidget,
} from '@melio/vex-widgets';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSessionConfig } from '../../contexts';
import { getPartnerName } from '../../shared/partner';

type EmailVerificationCardVariants = 'email-input' | 'code-input' | 'read-only';

type EmailVerificationCardActivityProps = {
  isOpened?: boolean;
  isDisabled?: boolean;
  isFilled: boolean;
  verifiedEmailAddress: string | undefined;
  onDone: () => Promise<void>;
  onOpen: () => void;
  setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
};

export const EmailVerificationCardActivity = withAnalyticsContext<EmailVerificationCardActivityProps>(
  forwardRef(
    (
      { onOpen, onDone, setDirtyStatus, setAnalyticsProperties, isFilled, isOpened, isDisabled, verifiedEmailAddress },
      ref
    ) => {
      const { formatMessage } = useMelioIntl();
      const [cardVariant, setCardVariant] = useState<EmailVerificationCardVariants>(
        verifiedEmailAddress ? 'read-only' : 'email-input'
      );
      const [userEmail, setUserEmail] = useState<string>(verifiedEmailAddress || '');
      const { onboardingSessionUuid } = useParams<{ onboardingSessionUuid: string }>();
      const {
        settings: {
          vex: { vendorsContactEmail },
        },
      } = useConfig();
      const contactUrl = `mailto:${vendorsContactEmail}?subject=${formatMessage(
        'vex.activities.vendorOnboarding.businessDetails.contactUsMailSubject',
        { onboardingSessionUuid }
      )}`;
      const [isEmailEdited, setIsEmailEdited] = useState(false);
      const partnerName = getPartnerName();
      const { setAccessTokens } = useSessionConfig();
      const { removeQuery: removeVexOrganizationQuery } = useVexMyOrganization({ enabled: false });
      const { mutateAsync: sendEmailValidationCode, isLoading: isSendingValidationCode } = useSendEmailValidationCode();
      const { track } = useAnalytics();
      setAnalyticsProperties({
        Intent: 'verification',
      });

      const onEmailSubmit = async (email: string) => {
        await sendEmailValidationCode({
          email,
          partnerName,
        });
        setUserEmail(email);
        setCardVariant('code-input');
        track('Connection', 'Click', {
          PageName: 'verify-your-email',
          Cta: 'continue',
          ...(isEmailEdited && { Intent: 'edit-email-address' }),
        });
      };

      const onEmailViewContinue = async () => {
        await onDone();
        track('Connection', 'Click', {
          PageName: 'verify-your-email',
          Cta: 'continue',
          Intent: 'edit-email-address',
        });
      };

      const { mutateAsync: resolveEmailVerificationCode } = useResolveEmailVerificationCode();
      const onCodeComplete = async (code: string, email: string) => {
        try {
          const { accessToken, refreshToken } = await resolveEmailVerificationCode({
            verificationCode: parseInt(code, 10),
            email,
            partnerName,
            registrationFlow: UserRegistrationFlowEnum.ErpVendorOnboarding,
          });
          setAccessTokens(accessToken, refreshToken);
          removeVexOrganizationQuery();
          await onDone();
          setCardVariant('read-only');
          track('Organization', 'Status', { PageName: 'enter-code', StatusType: 'success' });
          return true;
        } catch (e) {
          track('Organization', 'Status', { PageName: 'enter-code', StatusType: 'failure' });
          return false;
        }
      };

      const onResendEmailVerificationCode = (email: string, alreadySent: boolean) => {
        sendEmailValidationCode({ email, partnerName })
          .then(() => {
            track('Connection', 'Click', {
              ...(alreadySent && { Intent: 'resend-code' }),
              PageName: 'enter-code',
              Cta: 'resend-code',
              businessEmail: email,
            });
          })
          .catch(() => {
            track('Connection', 'Status', {
              PageName: 'enter-code',
              ErrorType: 'resend-email-verification-code',
              businessEmail: email,
              Status: 'failure',
            });
          });
      };

      const onEditEmail = () => {
        setCardVariant('email-input');
        setIsEmailEdited(true);
        track('Connection', 'Click', {
          PageName: 'enter-code',
          Cta: 'edit-email-address',
          ...(isEmailEdited && { Intent: 'resend-code' }),
        });
      };

      const onValidationError = (isRequiredError: boolean) => {
        track('Connection', 'Status', {
          PageName: 'verify-your-email',
          ErrorType: isRequiredError ? 'enter-an-email-address' : 'enter-a-valid-email-address',
          Status: 'failure',
        });
      };

      const onContactSupportClick = () => {
        track('Connection', 'Click', {
          PageName: 'edit-email-address',
          Cta: 'contact-support',
        });
      };

      const title =
        cardVariant === 'email-input'
          ? 'vex.screen.vendorOnboarding.emailVerification.title'
          : 'vex.screen.vendorOnboarding.codeVerification.title';

      useEffect(() => {
        if (isOpened) {
          track(isFilled ? 'Organization' : 'Connection', 'View', {
            PageName: cardVariant === 'code-input' ? 'enter-code' : 'verify-your-email',
            ...(isFilled && cardVariant !== 'code-input' && { Intent: 'edit-email-address' }),
          });
        }
      }, [isOpened, track, cardVariant, isFilled]);

      return (
        <CollapsibleCardFormWidget
          title={isFilled ? formatMessage('vex.screen.vendorOnboarding.emailVerified.title') : formatMessage(title)}
          isOpened={isOpened}
          isFilled={isFilled}
          subtitle={
            cardVariant === 'email-input'
              ? formatMessage('vex.widgets.vendorOnboarding.emailVerification.subTitle')
              : cardVariant === 'code-input'
              ? formatMessage('vex.widgets.vendorOnboarding.codeVerification.subTitle', { userEmail })
              : undefined
          }
          isDisabled={isDisabled}
          onOpen={onOpen}
          ref={ref}
        >
          <Group>
            {cardVariant === 'email-input' ? (
              <EmailVerificationFormCardWidget
                contactUrl={contactUrl}
                onContactSupportClick={onContactSupportClick}
                onSubmit={({ email }) => onEmailSubmit(email)}
                isSaving={isSendingValidationCode}
                onDirty={setDirtyStatus}
                onValidationError={onValidationError}
              />
            ) : cardVariant === 'code-input' ? (
              <CodeVerificationFormCardWidget
                emailAddress={userEmail}
                onCodeComplete={onCodeComplete}
                onCancel={onEditEmail}
                onResendCode={onResendEmailVerificationCode}
              />
            ) : (
              <EmailVerificationFormCardWidget
                contactUrl={contactUrl}
                onSubmit={onEmailViewContinue}
                isReadOnly
                defaultValues={{ email: userEmail }}
                onContactSupportClick={onContactSupportClick}
              />
            )}
          </Group>
        </CollapsibleCardFormWidget>
      );
    }
  )
);

EmailVerificationCardActivity.displayName = 'EmailVerificationCardActivity';
