import { ContextualOnboardingButtons } from '@melio/platform-provider';

import { extendDefaultConfig } from '@/configByPartner/partnerConfig.utils';
import cloverLogo from '@/images/clover/favicon.svg';
import { cloverLinks } from '@/links';

export const cloverConfig = extendDefaultConfig({
  default: {
    tabTitle: 'Pay Bills by Melio',
    services: {
      tabapay: {
        card: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioClover.html',
          // 224 - iframe content size; 6 - is what missing from 40, after adding 24 margin-top of security footer + 10 gridGap of SinglePaymentStepLayout
          iframeHeight: [430, 244 + 6],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioClover.html',
          iframeHeight: [430, 244 + 6],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioClover.html',
          iframeHeight: [430, 244 + 6],
          iframeWidth: '100%',
        },
      },
      zendeskWorkflow: 'Clover',
      forethoughtApiKey: '',
      zendeskKey: 'b08525e0-cf98-43f0-9c14-14d41869be85',
    },
    settings: {
      partnerProductName: 'Pay Bills',
      isLineItemsEnabled: true,
      payDashboardBillsTabTourEnabled: true,
      newPayExperience: {
        isEnabled: true,
        firstTimeExperience: {
          assetType: 'image',
          imageUrl: 'https://platform-static.meliopayments.com/assets/clover/illustrations/first-time-experience.jpg',
          mobileImageUrl:
            'https://platform-static.meliopayments.com/assets/clover/illustrations/first-time-experience-mobile.jpg',
        },
      },
      assets: {
        freeAchPromotionModalDesktopImg:
          'https://platform-static.meliopayments.com/assets/clover/illustrations/free_ach_promotion_modal_image_desktop.jpg',
        freeAchPromotionModalMobileImg:
          'https://platform-static.meliopayments.com/assets/clover/illustrations/free_ach_promotion_modal_image_mobile.jpg',
      },
      showDemoRequestIcon: false,
      contextualOnboarding: {
        buttons: [
          ContextualOnboardingButtons.QuickBooksOnline,
          ContextualOnboardingButtons.GmailSync,
          ContextualOnboardingButtons.FirstPayment,
        ],
      },
      fundingSourcePolicy: {
        'bank-account': {
          fundingSourceGroup: 'bank-account',
          read: true,
          write: true,
        },
        credit: {
          fundingSourceGroup: 'credit',
          read: true,
          write: true,
        },
        debit: {
          fundingSourceGroup: 'debit',
          read: true,
          write: true,
        },
      },
      trustedPartners: ['Evolve', 'Mastercard', 'Quickbooks', 'Diners', 'Visa', 'Discover', 'Amex'],
      supportEmail: 'support-billpay@clover.melio.com',
      eoyCheck: {
        enabled: true,
        promoteFastCheck: true,
        link: 'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5401742662812',
      },
      unilateralRequest: {
        announceFees: true,
      },
      helpCenterUrl: 'https://paybillsbymelio.zendesk.com/hc/en-us',
      isMultiColoredAvatarsEnabled: false,
    },
    tutorialVideo: 'https://www.youtube.com/embed/x8dLodmm-rY',
    zendesk: {
      useNewWidgetApi: true,
      headerColor: '#C3C4C2',
      resultListsColor: '#C3C4C2',
      themeColor: '#C3C4C2',
      logo: cloverLogo,
      title: 'Pay Bills Support',
    },
    links: cloverLinks,
  },
  prod: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.tabapay.com/SSOMelioClover.html',
        },
        credit: {
          url: 'https://sso.tabapay.com/SSOMelioClover.html',
        },
        debit: {
          url: 'https://sso.tabapay.com/SSOMelioClover.html',
        },
      },
    },
    links: {
      failedToCollectSupport:
        'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
    },
  },
  dev: {
    links: {
      failedToCollectSupport:
        'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
    },
  },
});
