import { Icon, IconProps } from '@chakra-ui/react';

export const expiredSessionIcon = (props: IconProps) => {
  return (
    <Icon width="100px" height="100px" viewBox="0 0 100 100" fill="#C1E7F5" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
        fill="#98D5EC"
      />
      <mask id="mask0_5446_305220" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5446_305220)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0001 92.2858C73.3538 92.2858 92.2858 73.3538 92.2858 50.0001C92.2858 26.6463 73.3538 7.71436 50.0001 7.71436C26.6463 7.71436 7.71436 26.6463 7.71436 50.0001C7.71436 73.3538 26.6463 92.2858 50.0001 92.2858Z"
          fill="#019DD3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0002 81.7145C67.5155 81.7145 81.7145 67.5155 81.7145 50.0002C81.7145 32.4849 67.5155 18.2859 50.0002 18.2859C32.4849 18.2859 18.2859 32.4849 18.2859 50.0002C18.2859 67.5155 32.4849 81.7145 50.0002 81.7145Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 79.6002C49.3657 79.6002 48.9429 79.1773 48.9429 78.543V76.4287C48.9429 75.7944 49.3657 75.3716 50 75.3716C50.6343 75.3716 51.0572 75.7944 51.0572 76.4287V78.543C51.0572 79.1773 50.6343 79.6002 50 79.6002Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 20.4001C50.6343 20.4001 51.0572 20.823 51.0572 21.4573V23.5716C51.0572 24.2059 50.6343 24.6287 50 24.6287C49.3657 24.6287 48.9429 24.2059 48.9429 23.5716V21.4573C48.9429 20.823 49.3657 20.4001 50 20.4001Z"
          fill="#ADB4BA"
        />
        <path d="M50 50L65.54 32.98" stroke="#CF3028" strokeWidth="1.42857" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.9545 59.6202C64.2716 59.0916 64.1659 58.4573 63.6373 58.1402L50.5288 49.1545C50.0002 48.8373 49.3659 48.943 49.0488 49.4716C48.7316 50.0002 48.8373 50.6345 49.3659 50.9516L62.4745 59.9373C63.003 60.1488 63.6373 60.043 63.9545 59.6202Z"
          fill="#273B49"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.2715 26.3201C64.8553 26.3201 65.3286 25.8468 65.3286 25.263C65.3286 24.6791 64.8553 24.2058 64.2715 24.2058C63.6877 24.2058 63.2144 24.6791 63.2144 25.263C63.2144 25.8468 63.6877 26.3201 64.2715 26.3201Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7373 36.7857C75.3212 36.7857 75.7945 36.3124 75.7945 35.7285C75.7945 35.1447 75.3212 34.6714 74.7373 34.6714C74.1535 34.6714 73.6802 35.1447 73.6802 35.7285C73.6802 36.3124 74.1535 36.7857 74.7373 36.7857Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.263 36.7857C25.8468 36.7857 26.3201 36.3124 26.3201 35.7285C26.3201 35.1447 25.8468 34.6714 25.263 34.6714C24.6791 34.6714 24.2058 35.1447 24.2058 35.7285C24.2058 36.3124 24.6791 36.7857 25.263 36.7857Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7285 26.3201C36.3124 26.3201 36.7857 25.8468 36.7857 25.263C36.7857 24.6791 36.3124 24.2058 35.7285 24.2058C35.1447 24.2058 34.6714 24.6791 34.6714 25.263C34.6714 25.8468 35.1447 26.3201 35.7285 26.3201Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7285 75.7945C36.3124 75.7945 36.7857 75.3212 36.7857 74.7373C36.7857 74.1535 36.3124 73.6802 35.7285 73.6802C35.1447 73.6802 34.6714 74.1535 34.6714 74.7373C34.6714 75.3212 35.1447 75.7945 35.7285 75.7945Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.263 65.3286C25.8468 65.3286 26.3201 64.8553 26.3201 64.2715C26.3201 63.6877 25.8468 63.2144 25.263 63.2144C24.6791 63.2144 24.2058 63.6877 24.2058 64.2715C24.2058 64.8553 24.6791 65.3286 25.263 65.3286Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7373 65.3286C75.3212 65.3286 75.7945 64.8553 75.7945 64.2715C75.7945 63.6877 75.3212 63.2144 74.7373 63.2144C74.1535 63.2144 73.6802 63.6877 73.6802 64.2715C73.6802 64.8553 74.1535 65.3286 74.7373 65.3286Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.2715 75.7945C64.8553 75.7945 65.3286 75.3212 65.3286 74.7373C65.3286 74.1535 64.8553 73.6802 64.2715 73.6802C63.6877 73.6802 63.2144 74.1535 63.2144 74.7373C63.2144 75.3212 63.6877 75.7945 64.2715 75.7945Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.8628 23.9943C54.3343 23.8886 53.7 24.3115 53.5943 24.84L48.8371 49.7886C48.7314 50.3172 49.1543 50.9515 49.6828 51.0572C50.2114 51.1629 50.8457 50.74 50.9514 50.2115L55.7085 25.2629C55.8143 24.7343 55.4971 24.1 54.8628 23.9943Z"
          fill="#273B49"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4001 50C20.4001 49.3657 20.823 48.9429 21.4573 48.9429H23.5716C24.2059 48.9429 24.6287 49.3657 24.6287 50C24.6287 50.6343 24.2059 51.0572 23.5716 51.0572H21.4573C20.823 51.0572 20.4001 50.6343 20.4001 50Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.6002 50C79.6002 50.6343 79.1773 51.0572 78.543 51.0572H76.4287C75.7944 51.0572 75.3716 50.6343 75.3716 50C75.3716 49.3657 75.7944 48.9429 76.4287 48.9429H78.543C79.1773 48.9429 79.6002 49.3657 79.6002 50Z"
          fill="#ADB4BA"
        />
      </g>
    </Icon>
  );
};
