import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { PersonalDetailsFormFields } from '../../../types';
import { DATE_FORMAT, MAX_AGE, MIN_AGE, NAME_FORMAT, NAME_MIN_LENGTH, PHONE_NUMBER_REGEX } from '../consts';

export const usePersonalDetailsValidationSchema = (): SchemaOf<PersonalDetailsFormFields> => {
  const { formatMessage } = useMelioIntl();
  const { isValidAge, dateToString } = useDateUtils(DATE_FORMAT);

  return object().shape({
    firstName: string()
      .trim()
      .nullable()
      .required(formatMessage('vex.widgets.vendorOnboarding.personalDetails.firstName.validation'))
      .matches(NAME_FORMAT, formatMessage('app.mtl.validations.onlyLetters'))
      .test(
        'length',
        formatMessage('app.mtl.validations.length', {
          min: NAME_MIN_LENGTH,
        }),
        (val) => (val ? val.length >= NAME_MIN_LENGTH : false)
      ),
    lastName: string()
      .trim()
      .nullable()
      .required(formatMessage('vex.widgets.vendorOnboarding.personalDetails.lastName.validation'))
      .matches(NAME_FORMAT, formatMessage('app.mtl.validations.onlyLetters'))
      .test(
        'length',
        formatMessage('app.mtl.validations.length', {
          min: NAME_MIN_LENGTH,
        }),
        (val) => (val ? val.length >= NAME_MIN_LENGTH : false)
      ),
    dateOfBirth: string()
      .nullable()
      .required(formatMessage('vex.widgets.vendorOnboarding.personalDetails.dateOfBirth.required'))
      // date is being convert by yup to string using 'toString' method, which results in a string that cannot be parsed by useDateUtils/date-fns
      // eslint-disable-next-line no-restricted-syntax
      .transform((currentValue: string) => (currentValue ? dateToString(new Date(currentValue)) : currentValue))
      .test(
        'validMinAge',
        formatMessage(`app.mtl.validations.dateOfBirth.minAge`, {
          age: MIN_AGE,
        }),
        (v) => !!v && isValidAge(v, MIN_AGE, 'min')
      )
      .test(
        'validMaxAge',
        formatMessage(`app.mtl.validations.dateOfBirth.maxAge`, {
          age: MAX_AGE,
        }),
        (v) => !!v && isValidAge(v, MAX_AGE, 'max')
      ),
    phoneNumber: string()
      .trim()
      .required(formatMessage('vex.widgets.vendorOnboarding.personalDetails.missingPhoneNumber'))
      .matches(PHONE_NUMBER_REGEX, formatMessage('vex.widgets.vendorOnboarding.personalDetails.invalidPhoneFormat')),
  });
};
