import { useCollaborator, useMelioQueryClient, usePermissions } from '@melio/platform-api';

import { useRefetchPermissions } from './useRefetchPermissions';

type Props = {
  enabled?: boolean;
};

export const usePermissionsProviderData = (props?: Props) => {
  const enabled = typeof props?.enabled === 'boolean' ? props.enabled : false; // for usage with spread operator
  const queryClient = useMelioQueryClient();
  const { data: actor } = useCollaborator({ id: 'me', enabled });
  const actorRole = actor?.roleUniqueName;
  const { data: rolesAndPermissions, isFetching } = usePermissions({ enabled });
  const policyRole = rolesAndPermissions?.role;

  const invalidatePermissions = () => {
    if (enabled) {
      void queryClient.invalidateQueries('permissions');
    }
  };

  useRefetchPermissions({
    actorRole,
    policyRole,
    isFetching,
    onRefetch: invalidatePermissions,
  });

  return {
    permissions: rolesAndPermissions?.permissions,
    role: rolesAndPermissions?.role,
    roles: rolesAndPermissions?.roles,
  };
};
