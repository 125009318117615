import { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';

import { Loader } from '@/cl/components/Loader/Loader.component';

type Props = PropsWithChildren<{
  isLoading: boolean;
  isAbsoluteCenter?: boolean;
  overContent?: boolean;
}>;

export const WithLoading = ({ isLoading, isAbsoluteCenter, overContent, children }: Props) => {
  if (overContent) {
    return (
      <>
        {isLoading && (
          <>
            <Box
              width="100%"
              height="100%"
              position="absolute"
              backgroundColor="rgba(255, 255, 255, 0.5)"
              zIndex={'overlay'}
            />
            <Loader isAbsoluteCenter={isAbsoluteCenter} />
          </>
        )}
        {children}
      </>
    );
  }
  if (isLoading) {
    return <Loader isAbsoluteCenter={isAbsoluteCenter} />;
  }

  return <>{children}</>;
};
