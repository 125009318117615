/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { getPartnerLocalStorage, LocalStorageKeys } from '@melio/local-storage';
import { PartnerName } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usePartnerConfig } from '../../partners/usePartnerConfig';

const localStorage = getPartnerLocalStorage({ partnerName: PartnerName.CapitalOne });

export const useCapitalOneLogic = () => {
  const {
    partnerConfig: { config },
  } = usePartnerConfig();
  const [isBackToL3Enabled] = useFeature(FeatureFlags.CapOnePortalBackToL3, false);

  const generateCapitaOneBackUrl = React.useCallback(() => {
    if (!config.links) {
      return;
    }

    const { backButtonUrl, backButtonUrlFallback, portalLink } = config.links;
    let linkToCap1 = backButtonUrl;
    if (isBackToL3Enabled && portalLink) {
      linkToCap1 = portalLink;
    }
    const url = `${linkToCap1}?target=survey`;

    if (url && backButtonUrlFallback) {
      const backButtonReferenceId = localStorage.getItem(LocalStorageKeys.backButtonReferenceId);
      return backButtonReferenceId
        ? url.replace(`{BACK_BUTTON_REF_ID}`, encodeURIComponent(decodeURIComponent(backButtonReferenceId)))
        : backButtonUrlFallback;
    }
  }, [config, isBackToL3Enabled]);

  return { generateCapitaOneBackUrl };
};
