import { Box, Flex } from '@chakra-ui/react';
import { ModalProps, Text } from '@melio/penny';
import React, { useEffect } from 'react';

import { ModalCopiedFromPenny } from './ModalCopiedFromPenny';
import { Props } from './types';
import { splitMessage } from './utils';

const legacyFirstButtonClick = `clickBehaviorButton01`;

export const SingleButtonModal = ({ onButtonClick, onClose, message, logImpression, properties }: Props) => {
  const {
    buttons: [firstButton],
  } = message;

  const { elementProperties, generalProperties } = properties;

  useEffect(() => {
    logImpression();
  }, [logImpression]);

  if (!firstButton || !message.message) {
    return null;
  }

  const { description, subDescription } = splitMessage(message.message, '__subDescription__');

  const openInNewTab =
    (elementProperties.has('button1') && elementProperties.get('button1')?.get('newTab') === 'true') ||
    generalProperties.get(legacyFirstButtonClick) === 'newTab';
  const primaryButtonProps: ModalProps['primaryButton'] = {
    label: firstButton.text,
    variant: 'primary',
    onClick: () => onButtonClick(firstButton, openInNewTab),
  };

  return (
    <ModalCopiedFromPenny
      onClose={onClose}
      isOpen
      asset={<img src={message.imageUrl} alt="modal image" />}
      header={message.header}
      primaryButton={primaryButtonProps}
    >
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Box textStyle="body" whiteSpace="pre-line">
            {description}
          </Box>
          <Text textStyle="body4">{subDescription}</Text>
        </Flex>
      </Flex>
    </ModalCopiedFromPenny>
  );
};
