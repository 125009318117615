import { Text as _Text } from '@melio/penny';
import { ComponentProps, useEffect, useRef } from 'react';

export function PageTitle({
  autoFocus = true,
  ...props
}: Omit<ComponentProps<typeof _Text>, 'as'> & { autoFocus?: boolean }) {
  const ref = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [ref, autoFocus]);

  const textProps = {
    as: 'h1',
    ...props,
    tabIndex: -1,
    style: { outline: 'none' },
  } as const;

  return <_Text {...textProps} ref={ref} tabIndex={0} />;
}
