import { useModelApi, useNonEntityMutation } from '@melio/api-client';
import { ApiKeys, PostVexGuestPaymentUpgradeRequest, VexGuestPaymentApiClient } from '@melio/platform-api-axios-client';

type UsePaymentUpgradeProps = {
  paymentId: string;
  isPaymentUpgradeOffersFetchingEnabled?: boolean;
};

export const usePaymentUpgrade = ({ paymentId, isPaymentUpgradeOffersFetchingEnabled }: UsePaymentUpgradeProps) => {
  const {
    data: paymentUpgradeOffers,
    error: paymentUpgradeOffersError,
    isLoading: isPaymentUpgradeOffersLoading,
  } = useModelApi({
    id: paymentId,
    queryKey: ['VexGuestPaymentApi', 'getVexGuestPaymentUpgradeOffers', paymentId],
    queryFn: VexGuestPaymentApiClient.getVexGuestPaymentUpgradeOffers,
    enabled: isPaymentUpgradeOffersFetchingEnabled,
    retry: false,
  });

  const {
    mutateAsync: postPaymentUpgrade,
    error: postPaymentUpgradeError,
    isLoading: isPostPaymentUpgradeLoading,
  } = useNonEntityMutation(
    (params: PostVexGuestPaymentUpgradeRequest) =>
      VexGuestPaymentApiClient.postVexGuestPaymentUpgrade(paymentId, params),
    [ApiKeys.VexGuestPaymentApi, 'collection', 'default']
  );

  return {
    paymentUpgradeOffers,
    paymentUpgradeOffersError,
    isPaymentUpgradeOffersLoading,
    postPaymentUpgrade,
    postPaymentUpgradeError,
    isPostPaymentUpgradeLoading,
  };
};
