import { BaseTranslations } from './types';
import { FormatMessage } from './useMelioIntl';

export type FormattedMessageProps<T extends BaseTranslations> = {
  id: keyof T;
  values?: Record<string, unknown>;
  formatMessage: FormatMessage<T>;
};

export function FormattedMessage<T extends BaseTranslations>({
  id,
  values = {},
  formatMessage,
}: FormattedMessageProps<T>) {
  return <>{formatMessage(id, values)}</>;
}

FormattedMessage.displayName = 'FormattedMessage';
