import { ExternalLayout, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  DeliveryMethodType,
  PaperCheckDeliveryMethod,
  useGuestPayment,
  useVexGuestDeliveryMethod,
} from '@melio/platform-api';
import { createAddressLabel, useAddressAutocomplete } from '@melio/vex-widgets';
import { isEqual } from 'lodash';
import { useState } from 'react';

import { PaymentCanceledScreen } from '../../shared/screens/payment-canceled';
import { ServerErrorScreen } from '../../shared/screens/server-error';
import { CheckAccountFormModel } from '../../types';
import {
  AddCheckAddressManuallyScreen,
  AddCheckDeliveryMethodScreen,
  AddressSuggestionsScreen,
  InvalidAddressConfirmationScreen,
} from '../add-delivery-method-by-payee/AddCheckDeliveryMethodByPayee/screens';
import {
  CheckDetailsWithAddressSuggestions,
  CheckDetailsWithAddressSuggestionsKey,
} from '../add-delivery-method-by-payee/AddCheckDeliveryMethodByPayee/types';
import { useAddCheckDeliveryMethodByPayeeStep } from '../add-delivery-method-by-payee/AddCheckDeliveryMethodByPayee/useAddCheckDeliveryMethodByPayeeStep';

export type AcceptPaymentWithCheckProps = {
  paymentId: string;
  onBack: VoidFunction;
  onDone: (id: string) => Promise<void>;
  onError?: ErrorFunction;
};

export const AcceptPaymentWithCheckActivity: React.VFC<AcceptPaymentWithCheckProps> = ({
  paymentId,
  onDone,
  onBack: onFirstStepBack,
  onError,
}) => {
  const {
    data: paymentData,
    isLoading: isPaymentLoading,
    refetch: refetchGuestPayment,
    error: paymentError,
  } = useGuestPayment(paymentId);
  const { mutateAsync: createVendorDeliveryMethod, isLoading: isCreatingDeliveryMethod } = useVexGuestDeliveryMethod();

  const { toast } = useToast();
  const { track } = useAnalytics();
  const [newDeliveryMethod, setNewDeliveryMethod] = useState<PaperCheckDeliveryMethod>();

  const [checkDetailsWithAddressSuggestions, setCheckDetailsWithAddressSuggestions] =
    useState<CheckDetailsWithAddressSuggestions>({
      checkDetailsWithSuggestedAddress: undefined,
      checkDetailsWithOriginalAddress: { line1: '', line2: '', city: '', state: '', postalCode: '', printName: '' },
    });

  const { currentStep, goToStep, goToPreviousStep, goToManuallyAddressFormStep } = useAddCheckDeliveryMethodByPayeeStep(
    { onFirstStepBack }
  );

  const { addressAutocomplete } = useAddressAutocomplete();

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const handleAcceptPayment = (data: CheckAccountFormModel) => {
    const { printName, ...address } = data;
    createVendorDeliveryMethod({
      type: DeliveryMethodType.PaperCheck,
      details: {
        printName,
        address,
      },
    })
      .then(({ data: deliveryMethod }) => {
        setNewDeliveryMethod(deliveryMethod as PaperCheckDeliveryMethod);
        refetchGuestPayment().catch(handleFail);
        track('DeliveryMethod', 'Click', {
          Intent: 'accept-payment',
          Cta: 'accept-payment',
          PageName: 'paper-check-delivery-method',
        });
        return onDone((deliveryMethod as PaperCheckDeliveryMethod).id);
      })
      .catch(handleFail);
  };

  const handleManuallyAddressVerification = (checkDetailsWithOriginalAddress: CheckAccountFormModel) => {
    const { printName, line2, ...originalAddress } = checkDetailsWithOriginalAddress;
    const addressQuery = createAddressLabel(originalAddress);
    addressAutocomplete(addressQuery)
      .then(([addressSuggestions]) => {
        const getAddressSuggestions = () => {
          if (!addressSuggestions) {
            return undefined;
          }
          return {
            printName,
            ...addressSuggestions,
            line2,
          };
        };
        setCheckDetailsWithAddressSuggestions({
          checkDetailsWithSuggestedAddress: getAddressSuggestions(),
          checkDetailsWithOriginalAddress,
        });
        if (isEqual(checkDetailsWithOriginalAddress, getAddressSuggestions())) {
          return handleAcceptPayment(checkDetailsWithOriginalAddress);
        }
        getAddressSuggestions() ? goToStep('ADDRESS_SUGGESTIONS') : goToStep('INVALID_ADDRESS_CONFIRMATION');
      })
      .catch(handleFail);
  };
  const handleOnBackClicked = () => {
    track('Vendor', 'Click', {
      Intent: 'Go-Back',
      Cta: 'back',
      PageName: 'paper-check-delivery-method',
    });
    goToPreviousStep();
  };
  const handleAddressNotFound = () => {
    track('Vendor', 'Click', {
      Intent: 'not-find-address',
      Cta: 'not-find-address',
      PageName: 'paper-check-delivery-method',
    });
    goToManuallyAddressFormStep();
  };
  const createDeliveryMethodWithOriginalAddress = () => {
    track('Vendor', 'Click', {
      Intent: 'Continue-with-unverified-address',
      Cta: 'continue',
      PageName: 'paper-check-delivery-method',
    });
    handleAcceptPayment(checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress);
  };
  const createDeliveryMethodWithSelectedAddress = ({ key }: CheckDetailsWithAddressSuggestionsKey) => {
    track('Vendor', 'Click', {
      Intent: 'confirm-address',
      Cta: 'confirm',
      PageName: 'paper-check-delivery-method',
    });
    handleAcceptPayment(checkDetailsWithAddressSuggestions[key] as CheckAccountFormModel);
  };
  const onEditAddressClicked = () => {
    track('Vendor', 'Click', {
      Intent: 'edit-address',
      Cta: 'edit',
      PageName: 'paper-check-delivery-method',
    });
    goToManuallyAddressFormStep();
  };

  if ((isPaymentLoading && !newDeliveryMethod) || !paymentData) {
    return <ExternalLayout isLoading />;
  }

  if (paymentData.isCanceled) {
    return <PaymentCanceledScreen accountName={paymentData.orgName} accountEmail={paymentData.creatorAccountEmail} />;
  }

  if (paymentError) {
    return <ServerErrorScreen />;
  }

  switch (currentStep) {
    case 'ADD_CHECK_DETAILS':
    default:
      return (
        <AddCheckDeliveryMethodScreen
          onAddressNotFound={handleAddressNotFound}
          showNewAddressFlow
          accountName={paymentData.orgName}
          paymentNote={paymentData.note}
          paymentInvoiceNumber={paymentData.invoiceNumber}
          paymentAmount={paymentData.amount}
          onBack={handleOnBackClicked}
          onDone={handleAcceptPayment}
          isSaving={isCreatingDeliveryMethod || isPaymentLoading}
          showLoginHeaderSection={false}
        />
      );
    case 'ADD_CHECK_ADDRESS_MANUALLY':
      return (
        <AddCheckAddressManuallyScreen
          addressValues={checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress}
          accountName={paymentData.orgName}
          paymentAmount={paymentData.amount}
          paymentInvoiceNumber={paymentData.invoiceNumber}
          paymentNote={paymentData.note}
          onBack={handleOnBackClicked}
          onDone={handleManuallyAddressVerification}
          isSaving={isCreatingDeliveryMethod || isPaymentLoading}
        />
      );
    case 'ADDRESS_SUGGESTIONS':
      return (
        <AddressSuggestionsScreen
          checkDetailsWithAddressSuggestions={checkDetailsWithAddressSuggestions}
          accountName={paymentData.orgName}
          paymentAmount={paymentData.amount}
          onBack={handleOnBackClicked}
          onDone={createDeliveryMethodWithSelectedAddress}
          onEditAddressClicked={onEditAddressClicked}
          isSaving={isCreatingDeliveryMethod || isPaymentLoading}
        />
      );
    case 'INVALID_ADDRESS_CONFIRMATION':
      return (
        <InvalidAddressConfirmationScreen
          accountName={paymentData.orgName}
          checkDetailsWithOriginalAddress={checkDetailsWithAddressSuggestions.checkDetailsWithOriginalAddress}
          onBack={handleOnBackClicked}
          onDone={createDeliveryMethodWithOriginalAddress}
          onEditAddressClicked={onEditAddressClicked}
          isLoading={isCreatingDeliveryMethod}
        />
      );
  }
};

AcceptPaymentWithCheckActivity.displayName = 'AcceptPaymentWithCheckActivity';
