import { apiClient, RolesAndPermissions } from '@melio/javascript-sdk';

import { useModelApi, UseModelApiProps } from '../../core';
import { PermissionsModel } from './types';

type UsePermissionsProps = Omit<UseModelApiProps<RolesAndPermissions>, 'id'>;

export const usePermissions = (props?: UsePermissionsProps): PermissionsModel => {
  const client = apiClient.permissions();

  return useModelApi<RolesAndPermissions>({
    ...props,
    id: 'true', // needed to enabled the query!
    queryKey: 'permissions',
    queryFn: client.get,
  });
};
