/* eslint-disable react-hooks/exhaustive-deps */
import type { RoleUniqueNames } from '@melio/platform-api';
import { useEffect } from 'react';

type Props = {
  onRefetch: VoidFunction;
  actorRole?: RoleUniqueNames | null;
  policyRole?: RoleUniqueNames | null;
  isFetching: boolean;
};

export const useRefetchPermissions = ({ onRefetch, actorRole, isFetching, policyRole }: Props) => {
  useEffect(() => {
    if (actorRole && policyRole && !isFetching && policyRole !== actorRole) {
      onRefetch();
    }
  }, [actorRole, policyRole, isFetching]);
};
