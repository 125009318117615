/* eslint-disable react-hooks/exhaustive-deps */
import { useAnalytics } from '@melio/platform-analytics';
import {
  BankAccountDeliveryMethod,
  DebitDeliveryMethod,
  DeliveryMethod,
  PaperCheckDeliveryMethod,
  Payment,
} from '@melio/platform-api';
import { useEffect, VFC } from 'react';

import {
  ActionBlockedAlreadyDebitScreen,
  ActionBlockedIneligibleScreen,
  ActionBlockedOfferExpiredScreen,
  ActionBlockedPaymentDeliveredScreen,
} from '../screens';

type IneligibleScreenProps = {
  payment: Payment;
  existingPaymentDeliveryMethod?: DeliveryMethod;
  accountName: string;
};

export const IneligibleScreen: VFC<IneligibleScreenProps> = ({
  payment,
  existingPaymentDeliveryMethod,
  accountName,
}) => {
  const { track } = useAnalytics();

  useEffect(() => {
    track('PushToDebitIneligible', 'View', {
      IneligibleReason: payment.paymentActions?.pushToDebit.ineligibleReason,
    });
  }, [payment?.paymentActions?.pushToDebit.ineligibleReason]);

  const paymentPushToDebitAction = payment.paymentActions?.pushToDebit;

  if (existingPaymentDeliveryMethod) {
    if (paymentPushToDebitAction?.ineligibleReason === 'max-date-expired') {
      return (
        <ActionBlockedOfferExpiredScreen
          accountName={accountName}
          paymentAmount={payment.amount}
          deliveryMethod={existingPaymentDeliveryMethod as BankAccountDeliveryMethod | PaperCheckDeliveryMethod}
          estimatedDelivery={payment.estimatedDelivery}
          maxEstimatedDelivery={payment.maxEstimatedDelivery}
        />
      );
    }

    if (paymentPushToDebitAction?.ineligibleReason === 'payment-delivered') {
      return (
        <ActionBlockedPaymentDeliveredScreen
          accountName={accountName}
          paymentAmount={payment.amount}
          deliveryMethod={existingPaymentDeliveryMethod as BankAccountDeliveryMethod | PaperCheckDeliveryMethod}
          estimatedDelivery={payment.estimatedDelivery}
          maxEstimatedDelivery={payment.maxEstimatedDelivery}
        />
      );
    }

    if (paymentPushToDebitAction?.ineligibleReason === 'already-debit-delivery-method') {
      return (
        <ActionBlockedAlreadyDebitScreen
          accountName={accountName}
          paymentAmount={payment.amount}
          debitDeliveryMethod={existingPaymentDeliveryMethod as DebitDeliveryMethod}
        />
      );
    }
  }

  return <ActionBlockedIneligibleScreen accountName={accountName} />;
};
