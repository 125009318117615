import { useEffect } from 'react';

export const useKeydownEvent = ({
  key,
  enabled = true,
  handler,
  tunnelInsteadOfBubble,
}: {
  key: KeyboardEvent['key'];
  handler(e: KeyboardEvent): void;
  enabled?: boolean;
  tunnelInsteadOfBubble?: boolean;
}) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const callback = (e: KeyboardEvent) => {
      if (e.key === key) {
        handler(e);
      }
    };
    window.addEventListener('keydown', callback, tunnelInsteadOfBubble);

    return () => {
      window.removeEventListener('keydown', callback, tunnelInsteadOfBubble);
    };
  }, [enabled, handler, key, tunnelInsteadOfBubble]);
};
