import { Button, Group, Icon, SectionBanner, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const UpsellOfferBanner = ({
  paymentAmount,
  fee,
  onClick,
  isPaymentEligibleToBeInstantly,
}: {
  paymentAmount: number;
  fee: number;
  onClick: () => void;
  isPaymentEligibleToBeInstantly: boolean;
}) => {
  const { formatCurrency, formatMessage, formatPercents } = useMelioIntl();
  return (
    <SectionBanner
      hideIcon
      variant="brand"
      data-testid="upsell-offer-banner"
      description={
        <Group>
          <Icon type="get-started" size="large" />
          <Group variant="vertical" spacing="xs">
            <Text data-testid="upsell-offer-banner-title" textStyle="body2Semi">
              {formatMessage(
                isPaymentEligibleToBeInstantly
                  ? 'vex.activities.vendorPaymentTracking.upsellOfferBanner.title.instantly'
                  : 'vex.activities.vendorPaymentTracking.upsellOfferBanner.title.today'
              )}
            </Text>
            <Text data-testid="upsell-offer-banner-description-text" textStyle="body3">
              {formatMessage('vex.activities.vendorPaymentTracking.upsellOfferBanner.description', {
                paymentAmount: formatCurrency(paymentAmount),
                fee: formatPercents(fee, { divide: true }),
              })}
            </Text>
          </Group>
        </Group>
      }
      action={
        <Button
          isFullWidth
          data-testid="upsell-offer-banner-navigation-button"
          label={formatMessage(
            isPaymentEligibleToBeInstantly
              ? 'vex.activities.vendorPaymentTracking.upsellOfferBanner.button.instantly'
              : 'vex.activities.vendorPaymentTracking.upsellOfferBanner.button.today'
          )}
          onClick={onClick}
        />
      }
    />
  );
};

UpsellOfferBanner.displayName = 'UpsellOfferBanner';
