import { Loader } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BusinessDetails,
  DataToPrefillAnOnboardingFormCompany,
  MsnOrigin,
  useAccount,
  useUpdateVendorOrganization,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import {
  CollapsibleCardFormWidget,
  VendorBusinessDetailsFormFields,
  VendorBusinessDetailsFormWidget,
} from '@melio/vex-widgets';
import { isEmpty, omit, omitBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useMergePrePopulatedCompanyData } from './hooks/useMergedPrePopulatedCompanyData';
import { useVendorMultiOrganizations } from './hooks/useVendorMultiOrganizations';
import { padTaxIdNumber } from './utils';

type BusinessDetailsCardActivityProps = {
  isOpened?: boolean;
  isDisabled?: boolean;
  isFilled: boolean;
  onboardingSessionUuid: string;
  onOpen: () => void;
  setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
  onSubmit: () => void;
  prePopulatedCompany: DataToPrefillAnOnboardingFormCompany;
};

export const BusinessDetailsCardActivity = withAnalyticsContext<BusinessDetailsCardActivityProps>(
  forwardRef(
    (
      {
        onOpen,
        setDirtyStatus,
        onSubmit,
        setAnalyticsProperties,
        isFilled,
        onboardingSessionUuid,
        isOpened,
        isDisabled,
        prePopulatedCompany,
      },
      ref
    ) => {
      const { track } = useAnalytics();
      setAnalyticsProperties({
        PageName: 'enter-your-business-details',
        Intent: 'add-business-details',
      });
      const { formatMessage } = useMelioIntl();
      const {
        settings: {
          vex: { vendorsContactEmail },
        },
      } = useConfig();

      const contactUrl = `mailto:${vendorsContactEmail}?subject=${formatMessage(
        'vex.activities.vendorOnboarding.emailVerification.contactUsMailSubject',
        { onboardingSessionUuid }
      )}`;
      const {
        organizationsList,
        isLoadingOrganizationList,
        isSwitchingOrganization,
        selectedOrganizationId,
        onSwitchOrganization,
      } = useVendorMultiOrganizations({
        accountFetchEnabled: !!isOpened,
        vexOrgListFetchEnabled: !!isOpened,
      });
      const {
        isFetched: isAccountFetched,
        data: accountMeData,
        isLoading: isAccountLoading,
        refetch: refetchAccount,
      } = useAccount({ id: 'me', enabled: isOpened });

      const { mutateAsync: updateOrganization } = useUpdateVendorOrganization(selectedOrganizationId);
      const [isSavingVendorOrg, setIsSavingVendorOrg] = useState(false);

      const { legalName, name, address, legalAddress, businessType, taxInfo, industry } = accountMeData?.company || {};
      const mergedCompanyData = useMergePrePopulatedCompanyData(
        {
          legalName,
          name,
          address,
          legalAddress,
          businessType,
          taxInfo,
          industry,
        },
        prePopulatedCompany
      );

      const isPopulated = useMemo(
        () => !isEmpty(omitBy(omit(mergedCompanyData, ['businessType', 'taxIdType']), isEmpty)),
        [mergedCompanyData]
      );

      const submitBusinessDetails = async (values: VendorBusinessDetailsFormFields) => {
        try {
          setIsSavingVendorOrg(true);
          const shouldIncludeTaxIdentifier = values.taxIdNumber !== padTaxIdNumber(taxInfo?.identifier || '');

          await updateOrganization({
            origin: MsnOrigin.AR_ERP_FORM,
            businessDetails: shouldIncludeTaxIdentifier
              ? (values as BusinessDetails)
              : (omit(values, 'taxIdNumber') as BusinessDetails),
          });
          track('Organization', 'Click', { Cta: 'continue' });
          await refetchAccount();
          onSubmit();
        } catch {
          track('Organization', 'Status', {
            StatusType: 'failure',
            ErrorType: 'general-error',
          });
        } finally {
          setIsSavingVendorOrg(false);
        }
      };

      const onValidationError = (isRequiredError: boolean) => {
        track('Connection', 'Status', {
          ErrorType: `${isRequiredError ? 'missing' : 'invalid'}-business-details`,
          Status: 'failure',
        });
      };

      useEffect(() => {
        if (isOpened) {
          track('Organization', 'View');
        }
      }, [isOpened, track]);

      useEffect(() => {
        if (selectedOrganizationId) {
          setAnalyticsProperties({ OrganizationId: selectedOrganizationId, IsPopulated: isPopulated });
        }
      }, [selectedOrganizationId, setAnalyticsProperties, isPopulated]);

      return (
        <CollapsibleCardFormWidget
          title={formatMessage('vex.activities.vendorOnboarding.businessDetails.title')}
          subtitle={formatMessage('vex.activities.vendorOnboarding.businessDetails.subtitle')}
          isOpened={isOpened}
          isFilled={isFilled}
          isDisabled={isDisabled}
          onOpen={onOpen}
          ref={ref}
        >
          {isAccountLoading && <Loader />}
          {isAccountFetched && (
            <VendorBusinessDetailsFormWidget
              contactUrl={contactUrl}
              onValidationError={onValidationError}
              onOrgSelection={onSwitchOrganization}
              isFilled={isFilled}
              isMultiOrg={!isLoadingOrganizationList && !!organizationsList && organizationsList.length > 1}
              onSubmit={submitBusinessDetails}
              setDirtyStatus={setDirtyStatus}
              isSaving={isSavingVendorOrg}
              isLoadingOrg={isSwitchingOrganization || isLoadingOrganizationList}
              organizations={organizationsList
                ?.filter((org) => !!org.companyName)
                .map((org) => ({ id: org.id, name: org.companyName as string }))}
              defaultValues={mergedCompanyData}
            />
          )}
        </CollapsibleCardFormWidget>
      );
    }
  )
);

BusinessDetailsCardActivity.displayName = 'BusinessDetailsCardActivity';
