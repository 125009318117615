import { Group, Text } from '@melio/penny';
import { FormattedMessage } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { MelioSmallGray } from './assets/MelioSmallGray';

export const PoweredByMelioWidget = forwardRef<unknown, 'div'>((props, ref) => (
  <Group
    data-component="PoweredByMelioWidget"
    data-testid="PoweredByMelioWidget"
    alignItems="center"
    ref={ref}
    {...props}
  >
    <Text textStyle="body4" color="global.neutral.800">
      <FormattedMessage id="widgets.poweredByMelioFooter.firstLine" />
    </Text>
    <MelioSmallGray />
  </Group>
));

PoweredByMelioWidget.displayName = 'PoweredByMelioWidget';
