// eslint-disable-next-line no-restricted-imports
import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient } from '@melio/api-client';
import { useCreateMutationV2 } from '@melio/api-client/src/core';
import { VendorsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { CreateFn, DeleteFn, ListFn, UpdateFn, Vendor, VendorsListParams } from './types';
import { patchVendorsVendorsIds } from './utils';

export { AutoPayStatus as VendorAutoPayStatusEnum } from '@melio/platform-api-axios-client';

export type UseVendorsProps = UseCollectionApiProps<ListFn, Vendor> & {
  params?: VendorsListParams;
};

export const useVendors = ({ params, ...props }: UseVendorsProps = {}) => {
  const query = useCollectionApi<ListFn, CreateFn, UpdateFn, DeleteFn, Vendor>({
    ...props,
    queryKey: ['VendorsApi', params],
    queryFn: () =>
      VendorsApiClient.getVendors(
        params?.expand,
        params?.searchTerm,
        params?.sort,
        JSON.stringify(params?.search),
        params?.limit,
        params?.cursor
      ),
    updateFn: VendorsApiClient.patchVendorsVendorId,
    deleteFn: VendorsApiClient.deleteVendorsVendorId,
    createFn: VendorsApiClient.postVendors,
  });

  const melioQueryClient = useMelioQueryClient();

  const batchUpdateMutation = useMutation(query.queryKey, patchVendorsVendorsIds, {
    onSuccess: () => melioQueryClient.invalidateQueries('VendorsApi'),
  });

  const batchCreateMutation = useCreateMutationV2(VendorsApiClient.postVendorsBulk, query.queryKey, {
    onSuccess: () => melioQueryClient.invalidateQueries('VendorsApi'),
  });

  return {
    ...query,
    batchUpdate: batchUpdateMutation.mutateAsync,
    batchCreate: batchCreateMutation.mutateAsync,
    _mutations: { ...query._mutations, batchUpdate: batchUpdateMutation },
  };
};

export type VendorCollection = ReturnType<typeof useVendors>;
