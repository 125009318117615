import { FC } from 'react';

import { FormattedMessage as _FormattedMessage, FormattedMessageProps } from './FormattedMessage';
import { LocalizationProvider as _LocalizationProvider, LocalizationProviderProps } from './LocalizationProvider';
import { BaseTranslations, Renderers } from './types';
import { useMelioIntl as _useMelioIntl } from './useMelioIntl';

export function createProvider<T extends BaseTranslations>(messages: T, renderers: Renderers = {}) {
  const useMelioIntl = () => _useMelioIntl<T>(renderers);

  const LocalizationProvider: FC<Omit<LocalizationProviderProps<T>, 'messages'>> = (props) => (
    <_LocalizationProvider {...props} messages={messages} />
  );

  const FormattedMessage: FC<Omit<FormattedMessageProps<T>, 'formatMessage'>> = (props) => (
    <_FormattedMessage<T> {...props} formatMessage={useMelioIntl().formatMessage} />
  );

  return {
    LocalizationProvider,
    useMelioIntl,
    FormattedMessage,
  };
}
