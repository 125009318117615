import { useModelApi, useNonEntityMutation } from '@melio/api-client/src';
import { ApiKeys, PostShiftVirtualCardToAchRequest, VexGuestApiClient } from '@melio/platform-api-axios-client';

import { DeliveryMethod } from '../../entities-v2';

type QueryFn = typeof VexGuestApiClient.getShiftPaymentDeliveryMethod;

export const useGetShiftPaymentDeliveryMethod = (paymentId: string) =>
  useModelApi<QueryFn, never, never, never, DeliveryMethod[]>({
    queryKey: [ApiKeys.VexGuestApi, 'getShiftPaymentDeliveryMethod'],
    id: paymentId,
    queryFn: () => VexGuestApiClient.getShiftPaymentDeliveryMethod(paymentId),
  });

export const usePostShiftVirtualCardToAch = (paymentId: string) =>
  useNonEntityMutation(
    (props: PostShiftVirtualCardToAchRequest) => VexGuestApiClient.shiftVirtualCardToExistsAch(paymentId, props),
    [ApiKeys.VexGuestApi, 'collection', paymentId, 'shiftVirtualCardToExistsAch']
  );
