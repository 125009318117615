import { ApiKeys, PostVexW9RequestResponse, VexGuestW9ApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

export const useUploadW9File = (vendorId: string) =>
  useMutation<PostVexW9RequestResponse, Error, { id: string; file: File }>(
    async ({ id, file }) => VexGuestW9ApiClient.postVexRequestW9UploadFile(id, file),
    {
      mutationKey: [ApiKeys.VexGuestW9Api, 'model', 'upload-w9-form', vendorId],
    }
  );
