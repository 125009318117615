import { _createFormFieldInput, Search, SearchOption, SearchProps, useToast } from '@melio/penny';
import { Address, formatAddress } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { Logger } from '@melio/platform-logger';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef, useDebounceCallback } from '@melio/platform-utils';
import { useState } from 'react';
import { object, string } from 'yup';

import { useAddressAutocomplete } from './useAddressAutocomplete';

export type AddressSearchWidgetProps = Omit<SearchProps, 'options' | 'emptyState'>;
export const addressSchema = object().shape({
  line1: string().required(),
  line2: string().notRequired(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
});

export const ADDRESS_SEARCH_FAILURE_DEBOUNCE = 1000;

export const AddressSearchWidget = _createFormFieldInput(
  forwardRef<AddressSearchWidgetProps, 'input'>(({ value, isViewMode, ...props }, ref) => {
    const [isCustomCheckAddressEnabled] = useFeature(FeatureFlags.CustomCheckAddressEnabled, false);
    const { addressAutocomplete } = useAddressAutocomplete();
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const [options, setOptions] = useState<SearchOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const loadOptions = (searchTerm: string): void => {
      setIsLoading(true);
      addressAutocomplete(searchTerm)
        .then((results) => {
          const options =
            results.length === 0 && isCustomCheckAddressEnabled ? [{ line1: searchTerm } as Address] : results;
          setOptions(
            options.map((option) => ({
              value: option,
              label: formatAddress(option),
            })) as unknown as SearchOption[]
          );
        })
        .catch((err) => {
          setIsError(true);
          // eslint-disable-next-line no-console
          console.error('addressAutocomplete failed', err);
          Logger.handleException(err);
          showErrorToast();
          const customAddress = { line1: searchTerm } as Address;
          const options = isCustomCheckAddressEnabled
            ? ([{ value: customAddress, label: formatAddress(customAddress) }] as unknown as SearchOption[])
            : [];
          setOptions(options);
        })
        .finally(() => setIsLoading(false));
    };

    const showErrorToast = useDebounceCallback(() => {
      if (isError) {
        toast({ type: 'error', title: formatMessage('form.addressSearch.searchError') });
        setIsError(false);
      }
    }, ADDRESS_SEARCH_FAILURE_DEBOUNCE);

    return (
      <Search
        data-component="AddressSearchWidget"
        ref={ref}
        options={options}
        onInputChange={loadOptions}
        emptyState={{ label: formatMessage('form.addressSearch.emptyState') }}
        isViewMode={isViewMode}
        isLoading={isLoading}
        value={value}
        formatSelectedValue={(data) => (data.value ? formatAddress(data.value as unknown as Address) : data.value)}
        filterOptions={(options) => options}
        {...props}
      />
    );
  })
);

AddressSearchWidget.displayName = 'AddressSearchWidget';
