import { apiClient, Collaborator, CollaboratorExpandableFields } from '@melio/javascript-sdk';

import { useModelApi, UseModelApiProps } from '../../core';
import { CollaboratorModel } from './types';

type Props<T extends CollaboratorExpandableFields> = UseModelApiProps<Collaborator<T>> & {
  params?: { expand?: CollaboratorExpandableFields[] };
};

export const useCollaborator = <T extends CollaboratorExpandableFields = never>({
  params,
  ...props
}: Props<T>): CollaboratorModel<T> => {
  const client = apiClient.collaborators();

  const query = useModelApi<Collaborator<T>>({
    ...props,
    queryKey: ['collaborator', params],
    queryFn: () => client.get(props.id as string, params) as unknown as Promise<Collaborator<T>>,
  }) as CollaboratorModel<T>;

  return query;
};

export {
  FirmAccessLevel as FirmAccessLevelEnum,
  PermissionLevel as PermissionLevelEnum,
} from '@melio/platform-api-axios-client';
