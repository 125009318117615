import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EmptyVendorsIcon = (props: IconProps) => (
  <Icon width="100%" height="120px" viewBox="0 0 144 120" fill="none" {...props}>
    <path d="M126.5 36.45L79.0498 12.4L30.2998 36.45V93L79.0498 116.4L126.5 93V36.45Z" fill="#E4DBFF" />
    <path d="M96.6001 52.7V78.7L107 73.7091V46.85" fill="white" />
    <path
      d="M96.6002 108.104L125.417 93.2461C126.082 92.903 126.5 92.2171 126.5 91.4685V37.6251C126.5 36.8622 126.066 36.1657 125.381 35.8296L79.614 13.372C79.0575 13.0989 78.4057 13.0994 77.8495 13.3732L32.2444 35.8289C31.5608 36.1655 31.1279 36.8613 31.1279 37.6232V53.35M89.4502 111.79L79.6467 116.845C79.0724 117.141 78.3904 117.142 77.8157 116.846L31.9149 93.2503C31.2435 92.9051 30.8236 92.2114 30.8293 91.4565L30.8716 85.85L30.8965 81.3"
      stroke="#212124"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.1689 39.033L56.9497 49.9234M125.85 37.1L78.6598 60.7784L64.0997 53.4984M78.6598 72.2V87.2075V102.262V109.789"
      stroke="#212124"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.6001 52.7V78.7L107 73.7091V46.85"
      stroke="#212124"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_5804_3190"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="59"
      width="58"
      height="58"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 60.5H58.7452V115.852H3V60.5Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
    </mask>
    <g mask="url(#mask0_5804_3190)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.02106 88.5323C2.78118 94.7553 4.58027 101.036 8.95426 106.226C24.6673 124.874 57.8561 112.959 57.8561 88.4971C57.8561 73.0353 45.4103 60.5005 30.0565 60.5C14.684 60.499 3.56762 74.3645 3.02106 88.5323Z"
        fill="#E4DBFF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7934 95.4785C20.4994 96.182 19.4544 97.1149 18.2845 98.0411C16.9648 99.0872 15.7563 100.015 15.1854 101.682C14.1877 104.598 15.838 107.888 18.3369 109.751C20.8368 111.614 24.0089 112.345 27.0945 112.92C30.6153 113.575 34.3078 112.528 37.8516 112.003C40.2487 111.647 42.5485 110.308 44.5089 108.949C45.881 107.998 49.3514 105.937 49.0562 104.074C48.7137 101.913 45.5586 99.3109 43.9576 98.0334C41.954 96.4345 39.6639 95.1931 37.2211 94.4145C32.4934 92.9066 27.2313 93.1411 22.6568 95.063C22.3555 95.1895 22.0685 95.3288 21.7934 95.4785Z"
      fill="white"
    />
    <path
      d="M30.725 63.9285C0.0104252 63.9285 -1.06081 112.846 30.725 112.846C44.2335 112.846 55.184 101.896 55.184 88.3874C55.184 85.3882 54.644 82.5144 53.6561 79.8592"
      stroke="#212124"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9463 103.123C17.2567 98.0213 22.2673 94.3159 28.3692 93.366C35.6194 92.2377 45.2091 96.3453 48.988 103.636"
      stroke="#212124"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.2033 80.8359C37.1725 80.7912 37.1416 80.747 37.1097 80.7038C34.6993 77.3754 29.6558 76.5191 26.3007 78.9053C22.3382 81.7241 21.7565 87.6383 25.4722 90.8633C28.4318 93.4321 34.3686 93.8147 36.8927 90.404C38.8793 87.719 39.1205 83.6269 37.2033 80.8359Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.2121 92.1322C39.0856 89.6364 40.0082 83.5798 37.0537 80.0442C34.0997 76.5085 27.9752 76.3403 24.8309 79.7083C21.6866 83.0768 22.276 89.1751 26.0065 91.8792C27.5689 93.0121 30.004 93.1875 31.9331 93.2312C32.2874 93.2389 32.6464 93.2338 32.9884 93.1402C33.6646 92.9561 34.6227 92.5118 35.2121 92.1322Z"
      stroke="#212124"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.061 36.2751C115.788 44.0737 125.178 49.8509 133.199 45.5119C139.509 42.0974 140.762 33.6011 136.512 27.7669C133.036 22.997 125.612 21.6095 120.733 24.5047C116.658 26.9225 114.611 31.4363 115.061 36.2751Z"
      fill="white"
      stroke="#212124"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M122.38 35.1675H131.611" stroke="#212124" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M126.996 30.5521V39.7829" stroke="#212124" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
