import { Box } from '@chakra-ui/react';
import { Text } from '@melio/penny';
import {
  APIErrorWithErrorCode,
  DataToPrefillAnOnboardingForm,
  useAccount,
  useCompleteVendorOnboarding,
  useVexMyOrganization,
  VEXErrorCodes,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useCardForms, useVendorEmailVerified } from '@melio/vex-domain';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSessionConfig } from '../../contexts';
import { VendorOnboardingCardsEnum, vendorOnboardingCardsOrder } from '../../shared';
import { AddOrConfirmBankAccountCardActivity } from '../add-or-confirm-bank-account-card';
import { BusinessDetailsCardActivity } from '../business-details-card/BusinessDetailsCard.activity';
import { LogoUploadCardActivity } from '../logo-upload-card';
import { PersonalDetailsCardActivity } from '../personal-details-card/PersonalDetailsCard.activity';
import { ReviewDetailsCardActivity } from '../review-details-card';
import { VendorOnboardingInfoActivity } from '../vendor-onboarding-info/VendorOnboardingInfo.activity';
import { EmailVerificationCardActivity } from './EmailVerificationCard.activity';

const VENDOR_ONBOARDING_ACTION_NAME = 'registerPartnerVendor';

type VendorOnboardingFormActivityProps = {
  onboardingSessionUuid: string;
  feeData: {
    feeCap: number;
    feePercentage: number;
  };
  prePopulatedData: DataToPrefillAnOnboardingForm;
};

export const VendorOnboardingFormActivity = forwardRef<VendorOnboardingFormActivityProps, 'div'>(
  ({ onboardingSessionUuid, feeData, prePopulatedData, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { accessToken } = useSessionConfig();
    const [isLoadOnboardingSavedStateFFEnabled] = useFeature(FeatureFlags.LoadOnboardingSavedState, false);
    const { isEmailVerified, verifiedEmailAddress } = useVendorEmailVerified({
      accessToken,
      action: VENDOR_ONBOARDING_ACTION_NAME,
    });
    const shouldUseSavedState = isLoadOnboardingSavedStateFFEnabled && isEmailVerified;
    const { openNextStep, openCard, getIsOpened, getIsDisabled, getIsSubmitted, setDirtyCard } = useCardForms<string>({
      firstCard: VendorOnboardingCardsEnum.TermAndConditions,
      cardsOrder: vendorOnboardingCardsOrder,
      flowUniqueIdentifier: onboardingSessionUuid,
      shouldUseSavedState,
    });
    const generateCommonFormCardProps = (onboardingFormCardType: VendorOnboardingCardsEnum) => ({
      onSubmit: () => openNextStep(),
      onOpen: () => openCard(onboardingFormCardType),
      setDirtyStatus: setDirtyCard(onboardingFormCardType),
      isFilled: getIsSubmitted(onboardingFormCardType),
      isDisabled: getIsDisabled(onboardingFormCardType),
      isOpened: getIsOpened(onboardingFormCardType),
    });

    const { refetch: refetchAccount } = useAccount({
      id: 'me',
      enabled: getIsSubmitted(VendorOnboardingCardsEnum.PersonalDetails),
    });

    const onEmailVerificationDone = async () => {
      const isEmailVerificationAlreadySubmitted = getIsSubmitted(VendorOnboardingCardsEnum.EmailVerification);

      if (!isEmailVerificationAlreadySubmitted) {
        await refetchAccount();
      }

      openNextStep();
    };

    const navigate = useNavigate();
    const isReadyToFetchOrganizationData = getIsSubmitted(VendorOnboardingCardsEnum.BusinessDetails);
    const { data: organizationData } = useVexMyOrganization({ enabled: isReadyToFetchOrganizationData });

    const {
      mutateAsync: completeVendorOnboarding,
      isSuccess: isCompletedVendorOnboarding,
      error: completeVendorOnboardingError,
    } = useCompleteVendorOnboarding();

    const onSubmit = async () => {
      if (!organizationData) {
        return;
      }

      await completeVendorOnboarding({ organizationId: organizationData.id, onboardingSessionUuid });
    };

    const userPrePopulatedData = useMemo(
      () => (verifiedEmailAddress === prePopulatedData?.user?.email ? prePopulatedData?.user : {}),
      [prePopulatedData?.user, verifiedEmailAddress]
    );

    const isOnboardingAlreadyCompletedError =
      !!completeVendorOnboardingError &&
      (completeVendorOnboardingError as APIErrorWithErrorCode).errorCode ===
        VEXErrorCodes.VendorOnboardingAlreadyCompleted;

    useEffect(() => {
      if (isCompletedVendorOnboarding) {
        navigate('success', { replace: true });
      } else if (isOnboardingAlreadyCompletedError) {
        navigate('already-registered', { replace: true });
      }
    }, [isCompletedVendorOnboarding, navigate, isOnboardingAlreadyCompletedError]);

    return (
      <Box
        display="flex"
        width="full"
        flexDirection="column"
        gap="m"
        overflowWrap="break-word"
        ref={ref}
        paddingY="48px"
        {...props}
      >
        <Text textStyle="display2Semi">{formatMessage('vex.activities.vendorOnboarding.vendorsFormPanel.title')}</Text>
        <VendorOnboardingInfoActivity
          onSubmit={() => openNextStep()}
          isFilled={getIsSubmitted(VendorOnboardingCardsEnum.TermAndConditions)}
          isOpened={getIsOpened(VendorOnboardingCardsEnum.TermAndConditions)}
          onOpen={() => openCard(VendorOnboardingCardsEnum.TermAndConditions)}
          feeCap={feeData?.feeCap}
          feePercentage={feeData?.feePercentage}
        />
        <EmailVerificationCardActivity
          {...generateCommonFormCardProps(VendorOnboardingCardsEnum.EmailVerification)}
          onDone={onEmailVerificationDone}
          verifiedEmailAddress={shouldUseSavedState ? verifiedEmailAddress : undefined}
        />
        <PersonalDetailsCardActivity
          {...generateCommonFormCardProps(VendorOnboardingCardsEnum.PersonalDetails)}
          prePopulatedUser={userPrePopulatedData || {}}
        />
        <BusinessDetailsCardActivity
          {...generateCommonFormCardProps(VendorOnboardingCardsEnum.BusinessDetails)}
          onboardingSessionUuid={onboardingSessionUuid}
          prePopulatedCompany={prePopulatedData?.company || {}}
        />
        <AddOrConfirmBankAccountCardActivity
          {...generateCommonFormCardProps(VendorOnboardingCardsEnum.BankDetails)}
          onBankAccountCreatedOrConfirmed={openNextStep}
          prePopulatedBankDetails={prePopulatedData?.bankDetails || {}}
        />
        <LogoUploadCardActivity {...generateCommonFormCardProps(VendorOnboardingCardsEnum.UploadLogo)} />
        <ReviewDetailsCardActivity
          {...generateCommonFormCardProps(VendorOnboardingCardsEnum.ReviewDetails)}
          onEditSection={openCard}
          onSubmit={onSubmit}
        />
      </Box>
    );
  }
);

VendorOnboardingFormActivity.displayName = 'VendorOnboardingFormActivity';
