import type { Integrations, UserTraits as SegmentTraits } from '@segment/analytics-next';
import { delay, identity, isUndefined, merge } from 'lodash';

import { Action, AnalyticsConfig, AnalyticsJS, EventProperties, SegmentOpts, Traits, ViewContext } from './types';
import { loadSegment } from './utils/load-segment';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    analytics: AnalyticsJS;
  }
}

const defaultConfig: AnalyticsConfig = {
  printEvents: true,
  reportEvents: false,
  segmentKey: 'HHGcPnPXtwBScXC7UAmy75QPLHNfJFMs',
  blockedEventDestinations: [],
};

const defaultTraits: Traits = {};

const excludedSalesIntegrations = [
  'Facebook Pixel',
  'Google Tag Manager',
  'AdWords',
  'Google Ads (Classic)',
  'Twitter Ads',
  'LinkedIn Insight Tag',
  'Google AdWords New',
  'Google Analytics',
];

export type NavigatorWithGlobalPrivacyControl = Navigator & { globalPrivacyControl: boolean };

const getSegmentOptions = (optionsArg: SegmentOpts = {}, optedOutFromSales: boolean) => {
  if (!optedOutFromSales) {
    return optionsArg;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const integrations: Integrations = optionsArg.integrations || {};
  const updatedIntegrations = excludedSalesIntegrations.reduce(
    (acc, integration) => ({ ...acc, [integration]: false }),
    integrations
  );

  return { ...optionsArg, integrations: updatedIntegrations } as SegmentOpts;
};

export class Analytics {
  // Singleton Implementation
  private static instance: Analytics;
  public static getInstance = (config: Partial<AnalyticsConfig> = {}, getService?: () => AnalyticsJS) => {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics(config, getService);
    } else {
      Analytics.instance.updateConfig(config);
      getService && (Analytics.instance._getService = getService);
    }

    return Analytics.instance;
  };
  // End singleton Implementation

  public static resetInstance = (config: Partial<AnalyticsConfig> = {}, getService?: () => AnalyticsJS) => {
    Analytics.instance = new Analytics(config, getService);
  };

  public static init = (config: Partial<AnalyticsConfig> = {}) => {
    this.getInstance(config);
  };

  private traits: SegmentTraits & Traits = defaultTraits;
  private config: AnalyticsConfig = defaultConfig;
  private history: ViewContext[] = [];
  private optedOutOfSales = false;
  private isUserIdentified = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private userIdentifiedCallback: VoidFunction = () => {};

  constructor(config?: Partial<AnalyticsConfig>, private _getService: () => AnalyticsJS = () => window.analytics) {
    config && this.updateConfig(config);
    if ((navigator as NavigatorWithGlobalPrivacyControl).globalPrivacyControl) {
      this.disableSalesIntegrations();
    }
  }

  ready = (cb: VoidFunction) => {
    defer(() => {
      void this.service?.ready(cb);
    });
  };

  onUserIdentified = (cb: VoidFunction) => {
    defer(() => {
      if (this.isUserIdentified) {
        return cb();
      }

      this.userIdentifiedCallback = cb;
    });
  };

  private log(...args: unknown[]) {
    if (this.config.printEvents) {
      args[0] = `[E] ${args[0] as string}`;
      console.log(...args); // eslint-disable-line no-console
    }
  }

  private error(...args: unknown[]) {
    if (this.config.printEvents) {
      args[0] = `[E] ${args[0] as string}`;
      console.error(...args); // eslint-disable-line no-console
    }
  }

  private updateConfig(config: Partial<AnalyticsConfig>) {
    merge(this.config, config);
    if (this.config.reportEvents) {
      loadSegment(this.config.segmentKey, this.config.blockedEventDestinations);
    }
  }

  private updateTraits(traits: Partial<Traits>) {
    merge(this.traits, traits);
  }

  private get viewContext() {
    return this.history[this.history.length - 1];
  }

  private get pageEntryPoint() {
    return this.history[this.history.length - 2];
  }

  private get service() {
    return this.config.reportEvents ? this._getService() : null;
  }

  setViewContext = (viewContext: ViewContext, viewFn?: VoidFunction) => {
    defer(() => {
      if (this.viewContext != viewContext) {
        this.history.push(viewContext);
        viewFn ? viewFn() : this.track(viewContext, 'Viewed');
      }
    });

    return this;
  };

  identify = (traits: Traits = {}, options?: SegmentOpts, callback?: VoidFunction) => {
    defer(() => {
      const segmentOpts = getSegmentOptions(options, this.optedOutOfSales);
      this.updateTraits(traits);
      this.log('identify', ...[this.traits.userId, this.traits, segmentOpts].filter(identity));
      if (this.traits.userId) {
        this.log('service', this.service);
        this.service?.identify(this.traits.userId, this.traits, segmentOpts, callback);
        try {
          this.isUserIdentified = true;
          this.userIdentifiedCallback();
        } catch (e) {
          this.error(e);
        }
      } else {
        this.service?.identify(this.traits, segmentOpts, callback);
      }
    });
  };

  track = (
    viewContext: ViewContext,
    action: Action,
    properties: EventProperties = {},
    segmentOptsArg: SegmentOpts = {},
    callback?: VoidFunction
  ) => {
    defer(() => {
      const segmentOpts = getSegmentOptions(segmentOptsArg, this.optedOutOfSales);
      const eventName = `${viewContext}-${action}`;
      const fullProperties = this.enrichEventProperties(properties);
      this.service?.track(eventName, fullProperties, segmentOpts, callback);
      this.log(`track, %c${eventName}`, 'color: tomato; font-weight: bold', fullProperties, segmentOpts);
    });
  };

  trackMarketing = (
    eventName: string,
    properties: EventProperties = {},
    segmentOpts: SegmentOpts = {},
    callback?: VoidFunction
  ) => {
    {
      const executeMarketingTracking = () => {
        const finalEventName = `marketing_${eventName}`;
        const fullProperties = this.enrichEventProperties(properties);
        this.service?.track(finalEventName, fullProperties, segmentOpts, callback);
        this.log(
          `trackMarketing, %c${finalEventName}`,
          'color: tomato; font-weight: bold',
          fullProperties,
          segmentOpts
        );
      };

      if ('requestIdleCallback' in window) {
        requestIdleCallback(executeMarketingTracking);
      } else {
        // Fallback for browsers that do not support requestIdleCallback
        setTimeout(executeMarketingTracking, 1);
      }
    }
  };

  private enrichEventProperties = (properties: EventProperties) =>
    Object.fromEntries(
      Object.entries({ ...this.traits, PageEntryPoint: this.pageEntryPoint, ...properties }).filter(
        ([, value]) => !isUndefined(value)
      )
    );

  disableSalesIntegrations = () => {
    this.optedOutOfSales = true;
  };
}

const defer = (fn: VoidFunction) => delay(fn, 1);
