import { Provider as MessagingProvider } from '@melio/in-app-marketing';
import { DesignSystemProvider, DesignSystemProviderProps } from '@melio/penny';
import { ApiProvider, ApiProviderProps, PartnerName } from '@melio/platform-api';
import { EngagementProvider } from '@melio/platform-engagement';
import { LocalizationProvider, LocalizationProviderProps } from '@melio/platform-i18n';
import { PermissionsProvider } from '@melio/platform-permissions';
import { SystemMessageProvider } from '@melio/platform-utils';
import { getDeviceDataHeader } from '@melio/risk-data-collection';
import { useCallback } from 'react';

import { ConfigProvider, ConfigProviderProps } from './ConfigProvider';
import { FeedbackProvider } from './FeedbackProvider';
import { TBTProvider } from './TBTProvider';
import { DynamicSettingsProps, FeedbackProviderProps } from './types';

export type MelioProviderProps = DesignSystemProviderProps &
  LocalizationProviderProps &
  ApiProviderProps &
  DynamicSettingsProps &
  ConfigProviderProps &
  FeedbackProviderProps & { partnerName?: PartnerName; permissionsEnabled?: boolean };

export const MelioProvider: React.FC<MelioProviderProps> = ({
  config,
  accessToken,
  onTokenExpired,
  feedbackWidgetProps,
  translations,
  dynamicSettings,
  locale,
  permissionsEnabled,
  children,
  partnerName,
  ...rest
}) => {
  const getEnrichedRequestHeaders = useCallback(
    () =>
      Promise.resolve({
        'x-site-context': partnerName ?? '',
        ...getDeviceDataHeader(),
      }).catch(() => ({})),
    [partnerName]
  );

  const shouldShowContentKeys = dynamicSettings?.shouldShowContentKeys ?? false;

  return (
    <ConfigProvider config={config}>
      <LocalizationProvider translations={translations} locale={locale} shouldShowContentKeys={shouldShowContentKeys}>
        <DesignSystemProvider {...rest}>
          <ApiProvider
            apiServer={config?.server?.apiServer}
            accessToken={accessToken}
            onTokenExpired={onTokenExpired}
            getRequestHeaders={getEnrichedRequestHeaders}
          >
            <PermissionsProvider enabled={permissionsEnabled} accessToken={accessToken}>
              <FeedbackProvider feedbackWidgetProps={feedbackWidgetProps}>
                <TBTProvider>
                  <EngagementProvider accessToken={accessToken} config={{ production: config?.production }}>
                    <MessagingProvider>
                      <SystemMessageProvider>{children}</SystemMessageProvider>
                    </MessagingProvider>
                  </EngagementProvider>
                </TBTProvider>
              </FeedbackProvider>
            </PermissionsProvider>
          </ApiProvider>
        </DesignSystemProvider>
      </LocalizationProvider>
    </ConfigProvider>
  );
};
