// eslint-disable-next-line no-restricted-imports
import { AuthApi } from '@melio/platform-api-axios-client';
import axios, { AxiosError } from 'axios';

import type { AuthLocalStorage } from './authLocalStorage';

const axiosInstance = axios.create();

let refreshTokenPromise: Promise<string | null> | null = null;

export const getRefreshAccessTokenFn = ({
  authLocalStorage,
  onUnauthorized,
  setAccessTokens,
  apiBasePath,
}: {
  authLocalStorage: AuthLocalStorage;
  onUnauthorized: () => void;
  setAccessTokens: (accessToken: string, refreshToken: string | null) => void;
  apiBasePath: string;
}) => {
  type AxiosRefreshTokenResponseErrorInterceptor = Parameters<typeof axiosInstance.interceptors.response.use>[1];

  const axiosRefreshTokenResponseErrorInterceptor: AxiosRefreshTokenResponseErrorInterceptor = async (
    error: AxiosError
  ) => {
    const isUnauthorized = error?.response?.status === 401;
    if (isUnauthorized) {
      authLocalStorage.deleteAccessToken();
      authLocalStorage.deleteRefreshToken();
      onUnauthorized();
    }
    return Promise.reject(error);
  };

  const refreshTokenAxiosAbortController = new AbortController();
  const refreshTokenAxiosInstance = axios.create({
    signal: refreshTokenAxiosAbortController.signal,
  });

  refreshTokenAxiosInstance.interceptors.response.use(
    (response) => response,
    axiosRefreshTokenResponseErrorInterceptor
  );

  const authRefreshApiClient = new AuthApi(undefined, apiBasePath, refreshTokenAxiosInstance);

  const refreshAccessToken = async () => {
    // This check is needed to handle the case when multiple requests are made to refresh the access token at the same time.
    // In case of multiple requests, we want to make only one request to refresh the access token.
    if (!refreshTokenPromise) {
      refreshTokenPromise = (async () => {
        let accessToken: string | null = null;
        const refreshTokenFromStorage = authLocalStorage.getRefreshToken();

        if (refreshTokenFromStorage) {
          try {
            const response = await authRefreshApiClient.postAuthTokenRefresh({
              refreshAccessTokenParameters: {
                refreshToken: refreshTokenFromStorage,
              },
            });

            const { data } = response.data;
            accessToken = data.accessToken;

            setAccessTokens(accessToken, data.refreshToken);
          } catch (e) {
            const error = e as AxiosError;
            // eslint-disable-next-line no-console
            console.log(`postAuthTokenRefresh failed ${error.message}`);
          }
        }

        setTimeout(() => (refreshTokenPromise = null));
        return accessToken;
      })();
    }
    return await refreshTokenPromise;
  };

  return refreshAccessToken;
};
