import { Box } from '@chakra-ui/react';
import { Card, Container, ExternalLayout, Group, IconButton } from '@melio/penny';
import { MeOrganization } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { CompanySelection, FooterWidget, SelectOrganizationModel } from '@melio/vex-widgets';
import { ComponentProps, ReactNode } from 'react';

type SelectOrganizationScreenProps = {
  contentHeaderTitle: ReactNode;
  organizations: MeOrganization[];
  onSubmit: (data: SelectOrganizationModel) => Promise<void>;
  onBack: () => void;
  isLoading?: boolean;
  header: ComponentProps<typeof ExternalLayout>['header'];
};
export const SelectOrganizationScreen = forwardRef<SelectOrganizationScreenProps, 'div'>(
  ({ contentHeaderTitle, onSubmit, organizations, onBack, isLoading, header }, ref) => {
    usePageView('Vendor', {
      PageName: 'chose-which-business-gets-paid',
      Intent: 'chose-business',
    });

    return (
      <ExternalLayout
        data-component="CompanySelectionScreen"
        data-testid="company-selection-screen"
        content={
          <Card paddingX="none" paddingY="none">
            <Group variant="vertical" spacing="none" width="full">
              <Container paddingX="xl" paddingY="l" backgroundColor="light">
                <Box marginY="s">
                  <IconButton aria-label="back" variant="naked" onClick={onBack} icon="chevron-left" size="medium" />
                </Box>
                {contentHeaderTitle}
              </Container>
              <Container paddingX="xl" paddingY="l">
                <Group variant="vertical">
                  <CompanySelection
                    organizations={organizations}
                    onSubmit={onSubmit}
                    titleMessagePath="vex.widgets.orgSelection.title"
                    inputLabelMessagePath="vex.widgets.orgSelection.input.label"
                    buttonMessagePath="vex.widgets.orgSelection.button"
                    isSaving={isLoading}
                    isRequired
                  />
                </Group>
              </Container>
            </Group>
          </Card>
        }
        header={header}
        footer={<FooterWidget />}
        ref={ref}
      />
    );
  }
);
SelectOrganizationScreen.displayName = 'SelectOrganizationScreen';
