/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { QueryObserver } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { useFundingSources } from '@melio/platform-api';
import { FundingSource } from '@melio/platform-api';

import { queryClient } from '@/queries/reactQueryClient';
import { fundingSourcesState } from '@/store/FundingSources/FundingSources.model';

export const useFundingSourcesObserver = () => {
  const setFundingSources = useSetRecoilState(fundingSourcesState);
  const { queryKey } = useFundingSources();

  React.useEffect(() => {
    const observer = new QueryObserver<FundingSource[]>(queryClient, { queryKey });
    return observer.subscribe(({ data: fundingSources, isLoading }) => {
      if (!isLoading && fundingSources) {
        setFundingSources(fundingSources as FundingSource[]);
      }
    });
  }, [setFundingSources]);
};
