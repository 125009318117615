import { ExternalLayout } from '@melio/penny';
import { DeliveryMethodType, useOrganizationDeliveryMethods } from '@melio/platform-api';
import { ReactElement, ReactNode, useEffect } from 'react';

import { AcceptPaymentWithBankAccountDeliveryMethodParams } from '../../types';
import { CreateOrConfirmDeliveryMethodWizard } from './CreateOrConfirmDeliveryMethod.wizard';

type Props = {
  onAcceptPayment: (params: AcceptPaymentWithBankAccountDeliveryMethodParams) => Promise<void>;
  onBack: () => void;
  onError: () => void;
  contentHeaderTitle: ReactNode;
  deliveryMethodType: DeliveryMethodType;
  cancelUrlFallback: string;
  organizationId: string | undefined;
  payorOrganizationName: string;
  header: ReactElement | undefined;
};

export const CreateOrConfirmDeliveryMethodActivity = ({
  deliveryMethodType,
  cancelUrlFallback,
  onAcceptPayment,
  onBack,
  onError,
  contentHeaderTitle,
  organizationId,
  payorOrganizationName,
  header,
}: Props) => {
  const {
    data: deliveryMethods = [],
    error: deliveryMethodsError,
    isLoading: isLoadingDeliveryMethods,
  } = useOrganizationDeliveryMethods({ organizationId, options: { enabled: !!organizationId } });

  useEffect(() => {
    if (deliveryMethodsError) {
      onError();
    }
  }, [deliveryMethodsError, onError]);

  if (isLoadingDeliveryMethods) {
    return <ExternalLayout isLoading />;
  }

  if (deliveryMethodsError) {
    return null;
  }

  const isDeliveryMethodExist = deliveryMethods.some((deliveryMethod) => deliveryMethod.type === deliveryMethodType);

  return (
    <CreateOrConfirmDeliveryMethodWizard
      onAcceptPayment={onAcceptPayment}
      onBack={onBack}
      onError={onError}
      contentHeaderTitle={contentHeaderTitle}
      payorOrganizationName={payorOrganizationName}
      deliveryMethodType={deliveryMethodType}
      isDeliveryMethodExist={isDeliveryMethodExist}
      cancelUrlFallback={cancelUrlFallback}
      organizationId={organizationId}
      header={header}
    />
  );
};

CreateOrConfirmDeliveryMethodActivity.displayName = 'CreateOrConfirmDeliveryMethodActivity';
