import { isNumber } from 'lodash';

export const sanitizeId = (externalId?: string | number): number | string => {
  if (!externalId) {
    return -1;
  }

  if (isNumber(externalId)) {
    return externalId;
  }
  const [prefix, internalId] = externalId.split('_');

  if (prefix && !internalId) {
    return prefix;
  }
  if (!internalId) {
    return -1;
  }

  return parseInt(internalId, 10);
};
