export const MastercardLogo: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="36" height="24" rx="4" fill="#10427A" />
    <path d="M21.0599 6.5H14.9399V17.5H21.0599V6.5Z" fill="#FF5F00" />
    <path
      d="M29.33 12.0001C29.33 13.8566 28.5925 15.637 27.2797 16.9498C25.967 18.2626 24.1865 19.0001 22.33 19.0001C20.7582 19.0063 19.2311 18.4773 18 17.5001C18.8321 16.845 19.5047 16.0096 19.9671 15.0569C20.4296 14.1043 20.6699 13.0591 20.6699 12.0001C20.6699 10.9411 20.4296 9.89585 19.9671 8.94317C19.5047 7.99048 18.8321 7.15514 18 6.50006C19.2311 5.52281 20.7582 4.99378 22.33 5.00006C24.1865 5.00006 25.967 5.73755 27.2797 7.05031C28.5925 8.36306 29.33 10.1435 29.33 12.0001Z"
      fill="#F79E1B"
    />
    <path
      d="M15.3299 12.0001C15.3299 10.941 15.5702 9.89584 16.0327 8.94315C16.4952 7.99046 17.1678 7.15513 17.9999 6.50006C16.7688 5.52281 15.2417 4.99378 13.6699 5.00006C11.8134 5.00006 10.0329 5.73755 8.72017 7.05031C7.40742 8.36306 6.66992 10.1435 6.66992 12.0001C6.66992 13.8566 7.40742 15.637 8.72017 16.9498C10.0329 18.2626 11.8134 19.0001 13.6699 19.0001C15.2417 19.0063 16.7688 18.4773 17.9999 17.5001C17.1678 16.845 16.4952 16.0096 16.0327 15.057C15.5702 14.1043 15.3299 13.0591 15.3299 12.0001Z"
      fill="#EB001B"
    />
  </svg>
);
