import { Button, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

export type AlreadyRegisteredScreenProps = {
  onGoBackToPartnerDashboardClick: (() => void) | undefined;
  partnerName: string;
};

export const AlreadyRegisteredScreen = forwardRef<AlreadyRegisteredScreenProps, 'div'>(
  ({ onGoBackToPartnerDashboardClick, partnerName, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Container
        height="full"
        alignItems="center"
        justifyContent="center"
        ref={ref}
        data-component="AlreadyRegisteredScreen"
        {...props}
      >
        <Group variant="vertical" alignItems="center" textAlign="center" spacing="xxl">
          <Group variant="vertical" spacing="xs">
            <Text textStyle="heading1Semi">{formatMessage('vex.screen.vendorOnboarding.alreadyRegistered.title')}</Text>
            <Text textStyle="body2">{formatMessage('vex.screen.vendorOnboarding.alreadyRegistered.subtitle')}</Text>
          </Group>
          {onGoBackToPartnerDashboardClick && (
            <Button
              size="large"
              data-testid="go-back-to-partner-dashboard-button"
              label={formatMessage('vex.screen.vendorOnboarding.alreadyRegistered.buttonLabel', { partnerName })}
              onClick={onGoBackToPartnerDashboardClick}
            />
          )}
        </Group>
      </Container>
    );
  }
);

AlreadyRegisteredScreen.displayName = 'AlreadyRegisteredScreen';
