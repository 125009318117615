import {
  apiClient,
  PostVendorsVendorIdUnilateralRequestsRequest,
  UnilateralRequest,
  Vendor,
} from '@melio/javascript-sdk';

import { UseCollectionApiProps } from '../../core';
import { useCollectionApi } from '../../core/useCollectionApi';
import { useCreateMutation } from '../../core/useCreateMutation';
import { UnilateralRequestCollection } from './types';

type UseUnilateralRequestsProps = UseCollectionApiProps<UnilateralRequest> & {
  vendorId: Vendor['id'];
};

export const useUnilateralRequests = ({
  vendorId,
  ...props
}: UseUnilateralRequestsProps): UnilateralRequestCollection => {
  const requestClient = apiClient.unilateralRequests(vendorId);
  const query = useCollectionApi<UnilateralRequest, PostVendorsVendorIdUnilateralRequestsRequest>({
    ...props,
    queryKey: ['unilateral-request', vendorId],
    queryFn: requestClient.fetch,
  });

  const create = useCreateMutation<UnilateralRequest, PostVendorsVendorIdUnilateralRequestsRequest>(
    (params) => requestClient.create(params),
    query
  );

  return {
    ...query,
    create,
    getValidationForResend: requestClient.getValidationForResend,
  };
};
