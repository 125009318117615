import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MelioIcon = (props: IconProps) => (
  <Icon width="62px" height="24px" viewBox="0 0 62 24" fill="none" {...props}>
    <g clipPath="url(#clip0_7475_261728)">
      <path
        d="M33.271 13.7564C33.3918 13.7564 33.4935 13.6676 33.511 13.5479C33.5626 13.196 33.5881 12.9153 33.5881 12.5491C33.5881 11.5812 33.4084 10.7449 33.054 9.90627C32.7011 9.06918 32.3085 8.36606 31.691 7.7319C31.0734 7.09774 30.4169 6.59279 29.5816 6.22894C28.7463 5.86589 27.9937 5.68119 27.0312 5.68119C26.0688 5.68119 25.1572 5.86589 24.3219 6.22894C23.4874 6.59279 22.7506 7.09774 22.1331 7.7319C21.5163 8.36447 21.0037 9.06759 20.6493 9.90627C20.2956 10.7442 20.116 11.6605 20.116 12.6284C20.116 13.5963 20.2956 14.3596 20.6493 15.207C21.0037 16.0544 21.4957 16.8012 22.1124 17.4242C22.7292 18.0481 23.4651 18.5491 24.3012 18.9129C25.1365 19.276 26.0481 19.4607 27.0106 19.4607C27.973 19.4607 29.0436 19.276 29.8789 18.9129C30.715 18.5491 31.4509 18.0481 32.0677 17.4242C32.3252 17.1642 32.5604 16.8828 32.7734 16.5816C32.8521 16.4706 32.8243 16.3168 32.7106 16.2407L30.6816 14.8844C30.5687 14.8091 30.4177 14.8424 30.343 14.9558C30.2159 15.1508 30.0712 15.3307 29.9115 15.4956C29.5824 15.8341 29.1843 16.1028 28.7265 16.2962C28.2687 16.4888 27.6035 16.5863 27.063 16.5863H27.0002C26.4606 16.5863 25.9543 16.4888 25.4965 16.2962C25.0388 16.1028 24.6398 15.8341 24.3115 15.4956C23.9817 15.1563 23.7186 14.7497 23.5303 14.2883C23.4619 14.1202 23.4047 13.9419 23.361 13.7564H33.2717H33.271ZM23.5295 10.6768C23.7179 10.2146 23.9809 9.80877 24.3107 9.4687C24.639 9.13101 25.038 8.86149 25.4957 8.66887C25.9535 8.47624 26.4598 8.37874 26.9994 8.37874H26.9033C27.4437 8.37874 27.95 8.47624 28.4078 8.66887C28.8656 8.86149 29.2637 9.13101 29.5928 9.4687C29.9226 9.80877 30.1857 10.2146 30.374 10.6768C30.4424 10.8448 30.4988 11.0232 30.5433 11.2087H23.3602C23.4039 11.0232 23.4611 10.8448 23.5295 10.6768V10.6768Z"
        fill="#5C5C5C"
      />
      <path
        d="M38.0927 0.599998H35.5749C35.4414 0.599998 35.3333 0.707889 35.3333 0.840979V18.7012C35.3333 18.8343 35.4414 18.9422 35.5749 18.9422H38.0927C38.2261 18.9422 38.3343 18.8343 38.3343 18.7012V0.840979C38.3343 0.707889 38.2261 0.599998 38.0927 0.599998Z"
        fill="#5C5C5C"
      />
      <path
        d="M42.5234 2.0443C42.4829 1.99832 42.436 1.95631 42.382 1.92777C42.1499 1.80253 41.8837 1.86198 41.7263 2.03954L40.1646 3.80488C40.0239 3.96342 40.0104 4.1933 40.1423 4.37404C40.2274 4.49136 40.3696 4.55239 40.5151 4.55239H43.7315C43.8777 4.55239 44.0208 4.48977 44.1058 4.37166C44.2354 4.19092 44.2211 3.96263 44.0812 3.80488L42.5242 2.0443H42.5234Z"
        fill="#5C5C5C"
      />
      <path
        d="M43.382 6.19962H40.8642C40.7307 6.19962 40.6226 6.30751 40.6226 6.4406V18.7013C40.6226 18.8343 40.7307 18.9422 40.8642 18.9422H43.382C43.5154 18.9422 43.6236 18.8343 43.6236 18.7013V6.4406C43.6236 6.30751 43.5154 6.19962 43.382 6.19962Z"
        fill="#5C5C5C"
      />
      <path
        d="M13.4207 5.88412C12.0171 5.88412 10.7804 6.43743 9.74328 7.53056C8.7061 6.43822 7.46945 5.88412 6.06589 5.88412C4.66233 5.88412 3.33586 6.43981 2.37181 7.4497C1.42286 8.44533 0.899902 9.78182 0.899902 11.2142V18.7013C0.899902 18.8344 1.00799 18.9422 1.14151 18.9422H3.63549C3.76901 18.9422 3.8771 18.8344 3.8771 18.7013V11.2142C3.8771 10.5666 4.09328 9.97048 4.4851 9.53529C4.88407 9.09217 5.44597 8.84723 6.06589 8.84723C6.68581 8.84723 7.24691 9.09138 7.64668 9.53529C8.0385 9.97048 8.25468 10.5666 8.25468 11.2142V18.7013C8.25468 18.8344 8.36277 18.9422 8.49629 18.9422H10.9911C11.1246 18.9422 11.2327 18.8344 11.2327 18.7013V11.2142C11.2327 10.5674 11.4488 9.97048 11.8407 9.53529C12.2404 9.09217 12.8015 8.84723 13.4215 8.84723C14.0414 8.84723 14.6025 9.09138 15.0022 9.53529C15.3941 9.97048 15.6102 10.5666 15.6102 11.2142V18.7013C15.6102 18.8344 15.7183 18.9422 15.8519 18.9422H18.3458C18.4794 18.9422 18.5874 18.8344 18.5874 18.7013V11.2142C18.5874 9.78182 18.0645 8.44533 17.1155 7.4497C16.1523 6.43981 14.8401 5.88412 13.4215 5.88412H13.4207Z"
        fill="#5C5C5C"
      />
      <path
        d="M53.8481 21.008H50.6317C50.487 21.008 50.344 21.0698 50.2589 21.1864C50.1278 21.3671 50.1405 21.597 50.2812 21.7555L51.8381 23.5161C51.8787 23.5621 51.9256 23.6041 51.9796 23.6326C52.2117 23.7579 52.4779 23.6984 52.6353 23.5208L54.197 21.7555C54.3369 21.5978 54.3512 21.3695 54.2217 21.1887C54.1366 21.0698 53.9936 21.008 53.8473 21.008H53.8481Z"
        fill="#5C5C5C"
      />
      <path
        d="M58.5738 9.88724C58.2257 9.06205 57.7401 8.32642 57.1313 7.70178C56.5225 7.07713 55.7969 6.57852 54.9743 6.22102C54.1509 5.86272 53.252 5.68119 52.3039 5.68119C51.3557 5.68119 50.456 5.86272 49.6334 6.22102C48.8109 6.57932 48.0844 7.07713 47.4757 7.70178C46.8677 8.32563 46.3828 9.06046 46.0332 9.88724C45.6842 10.7132 45.5078 11.6161 45.5078 12.5705C45.5078 13.5249 45.6842 14.4334 46.0332 15.2681C46.3821 16.1036 46.8677 16.8392 47.4757 17.4543C48.0837 18.0695 48.8093 18.5625 49.6327 18.9208C50.456 19.2791 51.3549 19.4607 52.3031 19.4607C53.2512 19.4607 54.1501 19.2791 54.9735 18.9208C55.7969 18.5625 56.5233 18.0687 57.1313 17.4543C57.7401 16.8384 58.2249 16.1028 58.5738 15.2681C58.9227 14.4326 59.0991 13.5249 59.0991 12.5705C59.0991 11.6161 58.9219 10.714 58.573 9.88724H58.5738ZM48.636 12.5705C48.636 11.9926 48.7234 11.452 48.8951 10.9637C49.0668 10.4754 49.3116 10.0529 49.6247 9.70809C49.937 9.36327 50.3241 9.08741 50.7763 8.88765C51.2285 8.68789 51.7428 8.58563 52.3039 8.58563C52.865 8.58563 53.3784 8.6871 53.8314 8.88765C54.2844 9.08821 54.6715 9.36406 54.9838 9.70809C55.2962 10.0521 55.5409 10.4746 55.7134 10.9637C55.8851 11.4536 55.9725 11.9942 55.9725 12.5705C55.9725 13.1468 55.8859 13.6882 55.7134 14.1773C55.5409 14.6664 55.2954 15.0889 54.9838 15.433C54.6723 15.7778 54.2844 16.0536 53.8314 16.2534C53.38 16.4532 52.8658 16.5554 52.3039 16.5554C51.742 16.5554 51.2277 16.454 50.7763 16.2534C50.3241 16.0536 49.9363 15.777 49.6247 15.433C49.3124 15.0881 49.0668 14.6664 48.8959 14.1773C48.7242 13.6898 48.6368 13.1492 48.6368 12.5705H48.636Z"
        fill="#5C5C5C"
      />
    </g>
    <defs>
      <clipPath id="clip0_7475_261728">
        <rect width="59.1" height="24" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
