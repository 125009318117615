import { Button, Container, Group, Illustration, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';

export type RegistrationSucceededScreenProps = {
  onGoBackToPartnerDashboardClick: (() => void) | undefined;
};

export const RegistrationSucceededScreen = withAnalyticsContext<RegistrationSucceededScreenProps>(
  forwardRef(({ onGoBackToPartnerDashboardClick, setAnalyticsProperties, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    setAnalyticsProperties({
      PageName: 'youre-all-set-up',
      Intent: 'finish-onboarding',
    });
    const { track } = useAnalytics();

    usePageView('Organization');

    const handleGoBackToPartnerDashboardClick = () => {
      if (onGoBackToPartnerDashboardClick) {
        onGoBackToPartnerDashboardClick();
        track('Organization', 'Click', { Cta: 'done' });
      }
    };

    return (
      <Container
        height="full"
        alignItems="center"
        justifyContent="center"
        ref={ref}
        data-component="RegistrationSucceededScreen"
        {...props}
      >
        <Group variant="vertical" alignItems="center" textAlign="center" spacing="xxl">
          <Group variant="vertical" width="full" alignItems="center" spacing="m">
            <Illustration type="success" size="medium" />
            <Group variant="vertical" spacing="xs">
              <Text textStyle="heading1Semi">
                {formatMessage('vex.screen.vendorOnboarding.registrationSucceeded.title')}
              </Text>
              <Text textStyle="body2">
                {formatMessage('vex.screen.vendorOnboarding.registrationSucceeded.subtitle')}
              </Text>
            </Group>
          </Group>
          {onGoBackToPartnerDashboardClick && (
            <Button
              size="large"
              data-testid="go-back-to-partner-dashboard-button"
              label={formatMessage('vex.screen.vendorOnboarding.registrationSucceeded.buttonLabel')}
              onClick={handleGoBackToPartnerDashboardClick}
            />
          )}
        </Group>
      </Container>
    );
  })
);

RegistrationSucceededScreen.displayName = 'RegistrationSucceededScreen';
