import * as brazeSDK from '@braze/web-sdk';

import { getBrazeConfig } from './consts';
import { EngagementModalButton, InAppMessage, ModalMessage, UserEngagement } from './types';

export class Engagement {
  public isInitialized = false;
  public inAppSubscriptionId?: string;
  private service = brazeSDK;

  constructor(
    user: UserEngagement,
    onEventHandler: (inAppMessage: ModalMessage) => void,
    config: { production?: boolean },
    service?: typeof brazeSDK
  ) {
    this.init(user, onEventHandler, config, service);
  }

  public init = (
    user: UserEngagement,
    onEventHandler: (inAppMessage: ModalMessage) => void,
    config: { production?: boolean },
    service?: typeof brazeSDK
  ): boolean => {
    if (service) {
      this.service = service;
    }

    const { apiKey, options } = getBrazeConfig(config);

    this.isInitialized = this.isInitialized ? this.isInitialized : this.service.initialize(apiKey, options);

    if (this.isInitialized && !this.inAppSubscriptionId && user.id) {
      this.service.changeUser(user.id);
      this.updateContactInfo(user);
      this.subscribeToInAppMessage(onEventHandler);
      this.service.openSession();
    }
    return this.isInitialized;
  };
  public subscribeToInAppMessage = (onEventHandler: (inAppMessage: ModalMessage) => void): string | undefined => {
    if (!this.inAppSubscriptionId) {
      this.inAppSubscriptionId = this.service.subscribeToInAppMessage((inAppMessage) => {
        onEventHandler(inAppMessage as ModalMessage);
      });
    }

    return this.inAppSubscriptionId;
  };

  public updateContactInfo = ({
    contactInfo: { contactFirstName, contactLastName },
    email,
    partnerName,
  }: Omit<UserEngagement, 'id'>) => {
    if (contactFirstName && contactLastName && email) {
      const brazeUser = this.service.getUser();
      brazeUser?.setFirstName(contactFirstName);
      brazeUser?.setLastName(contactLastName);
      brazeUser?.setEmail(email);
      brazeUser?.setCustomUserAttribute('partnerName', partnerName);
    }
  };
  public logCustomEvent = (eventName: string, eventProperties?: Record<string, unknown>) => {
    if (this.isInitialized) {
      const result = this.service.logCustomEvent(eventName, eventProperties);
      this.service.requestImmediateDataFlush();

      return result;
    }

    return false;
  };

  public logInAppMessageButtonClick = (button: EngagementModalButton, inAppMessage: InAppMessage): boolean =>
    this.service.logInAppMessageButtonClick(button, inAppMessage);

  public logInAppMessageClick = (inAppMessage: ModalMessage): boolean =>
    this.service.logInAppMessageClick(inAppMessage);

  public logInAppMessageImpression = (inAppMessage?: ModalMessage): boolean => {
    if (inAppMessage) {
      return this.service.logInAppMessageImpression(inAppMessage);
    }

    return false;
  };
}
