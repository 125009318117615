import { useMelioIntl } from '@melio/platform-provider';
import { object, SchemaOf, string } from 'yup';

import { AddBankAccountCardFormModel } from '../../../types';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
  validateRoutingNumber,
  ValidationError,
} from '../../../utils/bank-account-validations/bank-account-validations.utils';

const isDigitsOnly = (value = '') => /^\d+$/.test(value);

export const useAddBankAccountValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    routingNumber: string()
      .required(
        formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.validation.required', {
          length: ROUTING_NUMBER_LENGTH,
        })
      )
      .test('routingNumber', '', (value, testContext) => {
        const routingNumberValidation = validateRoutingNumber(value);
        if (routingNumberValidation.valid) {
          return true;
        }

        if (routingNumberValidation.error === ValidationError.INVALID_CHARACTERS) {
          return testContext.createError({
            message: formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.validation.digitsOnly'),
          });
        }

        if (routingNumberValidation.error === ValidationError.INVALID_LENGTH) {
          return testContext.createError({
            message: formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.validation.invalidLength', {
              length: ROUTING_NUMBER_LENGTH,
            }),
          });
        }

        return testContext.createError({
          message: formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.validation.invalidNumber', {
            length: ROUTING_NUMBER_LENGTH,
          }),
        });
      }),
    accountNumber: string()
      .required(
        formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumber.validation.required', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      )
      .test(
        'digitsOnly',
        formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumber.validation.digitsOnly'),
        isDigitsOnly
      )
      .min(
        ACCOUNT_NUMBER_MIN_LENGTH,
        formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumber.validation.invalidMinLength', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      )
      .max(
        ACCOUNT_NUMBER_MAX_LENGTH,
        formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumber.validation.invalidMaxLength', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      ),
    accountNumberVerification: string()
      .required(
        formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumberVerification.validation.required', {
          minLength: ACCOUNT_NUMBER_MIN_LENGTH,
          maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
        })
      )
      .test(
        'equalsToAccountNumber',
        formatMessage(
          'vex.widgets.addBankAccountFormCard.fields.accountNumberVerification.validation.verificationFailed'
        ),
        function (accountNumberVerificationValue) {
          const { accountNumber } = this.parent as AddBankAccountCardFormModel;
          return accountNumberVerificationValue === accountNumber;
        }
      ),
    routingNumberVerification: string()
      .required(
        formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumberVerification.validation.required', {
          length: ROUTING_NUMBER_LENGTH,
        })
      )
      .test(
        'equalsToRoutingNumber',
        formatMessage(
          'vex.widgets.addBankAccountFormCard.fields.routingNumberVerification.validation.verificationFailed'
        ),
        function (routingNumberVerificationValue) {
          const { routingNumber } = this.parent as AddBankAccountCardFormModel;
          return routingNumberVerificationValue === routingNumber;
        }
      ),
  }) as SchemaOf<AddBankAccountCardFormModel>;
};
