export const Pending: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11475_131845)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
        fill="#019DD3"
      />
      <mask
        id="mask0_11475_131845"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_131845)">
        <path d="M143.426 105.567H1.94586V135.807H143.426V105.567Z" fill="#084A77" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.1899 75.0937L45.7299 34.0537H98.6499L72.1899 75.0937Z"
          fill="#98D5EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.1899 58.8647L98.6499 99.9047H45.7299L72.1899 58.8647Z"
          fill="#98D5EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.3795 76.3784L94.6509 98.476H50.108L72.3795 76.3784Z"
          fill="#FDDA21"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.69 65.3954L63.2433 54.4863H81.9211L72.69 65.3954Z"
          fill="#FDDA21"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.831 106.21H42.4313C41.7833 106.21 41.3513 105.778 41.3513 105.13C41.3513 102.106 43.7273 99.73 46.7513 99.73H97.5113C100.535 99.73 102.911 102.106 102.911 105.13C102.911 105.778 102.479 106.21 101.831 106.21Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8909 29.6758H103.291C103.939 29.6758 104.371 30.1078 104.371 30.7558C104.371 33.7798 101.995 36.1558 98.9709 36.1558H48.2109C45.1869 36.1558 42.8109 33.7798 42.8109 30.7558C42.8109 30.1078 43.2429 29.6758 43.8909 29.6758Z"
          fill="#ADB4BA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.108 38.149L51.9196 36.9727L61.8723 56.2643L50.108 38.149Z"
          fill="#E2F3FC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11475_131845">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
