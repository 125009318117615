import { Icon, IconProps } from '@chakra-ui/react';

export const MelioIcon = (props: IconProps) => (
  <Icon width={{ xs: '64px', s: '42px' }} height={{ xs: '24px', s: '17px' }} viewBox="0 0 42 17" fill="none" {...props}>
    <path
      d="M23.5681 9.74338C23.6537 9.74338 23.7257 9.68049 23.7381 9.59571C23.7747 9.3464 23.7927 9.14763 23.7927 8.88822C23.7927 8.20264 23.6655 7.61026 23.4144 7.0162C23.1645 6.42326 22.8864 5.92522 22.449 5.47602C22.0115 5.02683 21.5465 4.66916 20.9549 4.41143C20.3632 4.15427 19.8301 4.02344 19.1483 4.02344C18.4666 4.02344 17.8209 4.15427 17.2292 4.41143C16.6381 4.66916 16.1162 5.02683 15.6788 5.47602C15.2419 5.9241 14.8788 6.42214 14.6277 7.0162C14.3772 7.6097 14.25 8.25879 14.25 8.94437C14.25 9.62996 14.3772 10.1707 14.6277 10.7709C14.8788 11.3711 15.2273 11.9001 15.6642 12.3414C16.101 12.7833 16.6223 13.1382 17.2145 13.3959C17.8062 13.6531 18.4519 13.7839 19.1337 13.7839C19.8154 13.7839 20.5737 13.6531 21.1654 13.3959C21.7576 13.1382 22.2789 12.7833 22.7158 12.3414C22.8982 12.1572 23.0648 11.9579 23.2157 11.7445C23.2714 11.6659 23.2517 11.557 23.1712 11.5031L21.734 10.5424C21.6541 10.489 21.5471 10.5126 21.4942 10.5929C21.4041 10.731 21.3016 10.8585 21.1885 10.9753C20.9554 11.2151 20.6734 11.4054 20.3491 11.5424C20.0248 11.6788 19.5536 11.7479 19.1708 11.7479H19.1264C18.7441 11.7479 18.3855 11.6788 18.0612 11.5424C17.737 11.4054 17.4544 11.2151 17.2219 10.9753C16.9882 10.735 16.8019 10.4469 16.6685 10.1201C16.6201 10.0011 16.5795 9.87477 16.5486 9.74338H23.5687H23.5681ZM16.6679 7.56197C16.8013 7.23462 16.9877 6.94714 17.2213 6.70626C17.4538 6.46706 17.7364 6.27615 18.0607 6.13971C18.3849 6.00327 18.7435 5.9342 19.1258 5.9342H19.0577C19.4405 5.9342 19.7991 6.00327 20.1234 6.13971C20.4476 6.27615 20.7297 6.46706 20.9627 6.70626C21.1964 6.94714 21.3827 7.23462 21.5161 7.56197C21.5645 7.68101 21.6045 7.80735 21.636 7.93874H16.548C16.579 7.80735 16.6195 7.68101 16.6679 7.56197V7.56197Z"
      fill="currentColor"
    />
    <path
      d="M26.9819 0.425781H25.1985C25.104 0.425781 25.0273 0.502204 25.0273 0.596475V13.2475C25.0273 13.3418 25.104 13.4182 25.1985 13.4182H26.9819C27.0765 13.4182 27.1531 13.3418 27.1531 13.2475V0.596475C27.1531 0.502204 27.0765 0.425781 26.9819 0.425781Z"
      fill="currentColor"
    />
    <path
      d="M30.1226 1.4461C30.0939 1.41353 30.0606 1.38377 30.0224 1.36356C29.858 1.27484 29.6694 1.31695 29.5579 1.44273L28.4517 2.69317C28.3521 2.80547 28.3425 2.96831 28.4359 3.09633C28.4962 3.17943 28.5969 3.22266 28.7 3.22266H30.9783C31.0818 3.22266 31.1832 3.17831 31.2434 3.09464C31.3352 2.96662 31.325 2.80491 31.226 2.69317L30.1231 1.4461H30.1226Z"
      fill="currentColor"
    />
    <path
      d="M30.728 4.39062H28.9446C28.8501 4.39062 28.7734 4.46705 28.7734 4.56132V13.2459C28.7734 13.3402 28.8501 13.4166 28.9446 13.4166H30.728C30.8226 13.4166 30.8992 13.3402 30.8992 13.2459V4.56132C30.8992 4.46705 30.8226 4.39062 30.728 4.39062Z"
      fill="currentColor"
    />
    <path
      d="M9.50559 4.16797C8.5114 4.16797 7.63544 4.55989 6.90077 5.33419C6.16611 4.56045 5.29015 4.16797 4.29596 4.16797C3.30177 4.16797 2.36219 4.56158 1.67932 5.27692C1.00715 5.98216 0.636719 6.92883 0.636719 7.94345V13.2468C0.636719 13.3411 0.713281 13.4175 0.807859 13.4175H2.57443C2.669 13.4175 2.74557 13.3411 2.74557 13.2468V7.94345C2.74557 7.48471 2.89869 7.06247 3.17623 6.75421C3.45884 6.44034 3.85685 6.26683 4.29596 6.26683C4.73507 6.26683 5.13252 6.43977 5.41569 6.75421C5.69323 7.06247 5.84635 7.48471 5.84635 7.94345V13.2468C5.84635 13.3411 5.92291 13.4175 6.01749 13.4175H7.78462C7.8792 13.4175 7.95576 13.3411 7.95576 13.2468V7.94345C7.95576 7.48528 8.10889 7.06247 8.38643 6.75421C8.6696 6.44034 9.06705 6.26683 9.50615 6.26683C9.94526 6.26683 10.3427 6.43977 10.6259 6.75421C10.9034 7.06247 11.0565 7.48471 11.0565 7.94345V13.2468C11.0565 13.3411 11.1331 13.4175 11.2277 13.4175H12.9943C13.0888 13.4175 13.1654 13.3411 13.1654 13.2468V7.94345C13.1654 6.92883 12.795 5.98216 12.1228 5.27692C11.4405 4.56158 10.511 4.16797 9.50615 4.16797H9.50559Z"
      fill="currentColor"
    />
    <path
      d="M38.1421 14.8789H35.8638C35.7613 14.8789 35.66 14.9227 35.5998 15.0052C35.5069 15.1333 35.5159 15.2961 35.6155 15.4084L36.7184 16.6555C36.7471 16.688 36.7803 16.7178 36.8186 16.738C36.983 16.8267 37.1716 16.7846 37.283 16.6588L38.3892 15.4084C38.4883 15.2967 38.4985 15.1349 38.4067 15.0069C38.3465 14.9227 38.2451 14.8789 38.1415 14.8789H38.1421Z"
      fill="currentColor"
    />
    <path
      d="M41.4894 7.00273C41.2429 6.41821 40.8989 5.89714 40.4677 5.45469C40.0364 5.01223 39.5225 4.65905 38.9398 4.40582C38.3566 4.15202 37.7199 4.02344 37.0482 4.02344C36.3766 4.02344 35.7394 4.15202 35.1567 4.40582C34.574 4.65961 34.0595 5.01223 33.6283 5.45469C33.1976 5.89658 32.8542 6.41709 32.6065 7.00273C32.3594 7.5878 32.2344 8.22734 32.2344 8.90338C32.2344 9.57942 32.3594 10.2229 32.6065 10.8141C32.8536 11.406 33.1976 11.927 33.6283 12.3627C34.0589 12.7985 34.5729 13.1477 35.1561 13.4015C35.7394 13.6553 36.3761 13.7839 37.0477 13.7839C37.7193 13.7839 38.356 13.6553 38.9392 13.4015C39.5225 13.1477 40.037 12.7979 40.4677 12.3627C40.8989 11.9265 41.2423 11.4054 41.4894 10.8141C41.7366 10.2223 41.8616 9.57942 41.8616 8.90338C41.8616 8.22734 41.736 7.58836 41.4889 7.00273H41.4894ZM34.4502 8.90338C34.4502 8.49405 34.5121 8.11112 34.6337 7.76523C34.7553 7.41935 34.9287 7.12008 35.1505 6.87583C35.3718 6.63158 35.6459 6.43618 35.9662 6.29468C36.2866 6.15318 36.6508 6.08075 37.0482 6.08075C37.4457 6.08075 37.8094 6.15262 38.1303 6.29468C38.4511 6.43674 38.7253 6.63214 38.9465 6.87583C39.1678 7.11952 39.3412 7.41879 39.4633 7.76523C39.5849 8.11224 39.6469 8.49518 39.6469 8.90338C39.6469 9.31159 39.5855 9.69509 39.4633 10.0415C39.3412 10.388 39.1672 10.6872 38.9465 10.9309C38.7259 11.1752 38.4511 11.3706 38.1303 11.5121C37.8105 11.6536 37.4463 11.726 37.0482 11.726C36.6502 11.726 36.286 11.6541 35.9662 11.5121C35.6459 11.3706 35.3712 11.1746 35.1505 10.9309C34.9293 10.6867 34.7553 10.388 34.6343 10.0415C34.5127 9.69621 34.4507 9.31327 34.4507 8.90338H34.4502Z"
      fill="currentColor"
    />
  </Icon>
);
