import { useConfig } from '@melio/platform-provider';

type AppSettings = ReturnType<typeof useConfig>['settings'];
export type CreateConfig<T, E = never> = (settings: AppSettings, params?: E) => T;

export function useActivityConfigData<T extends Record<string, unknown>, E>(
  createConfig: CreateConfig<T, E>,
  params?: E
) {
  const { settings } = useConfig();
  const config = createConfig(settings, params);

  return { config };
}
