import { Navigate } from 'react-router-dom';
import { PartnerName } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useLocation } from '@melio/platform-utils';
import { EmailVerificationActivity, NOT_FOUND_PAGE, useSessionConfig } from '@melio/vex-activities';

import { useResetAccessToken } from '@/hooks/session.hooks';
import { getPartnerName } from '@/utils/partner.utils';

export const EmailVerificationRoute = () => {
  const {
    vex: {
      zdSupportFormUrl,
      unilateral: { isEmailLoginEnabled },
    },
  } = useConfig().settings;
  const showPoweredByMelio = getPartnerName() !== PartnerName.Melio;
  const resetAccessTokenFunction = useResetAccessToken();
  const { setAccessTokens } = useSessionConfig();
  const { pathname } = useLocation();

  if (!isEmailLoginEnabled) {
    return <Navigate to={NOT_FOUND_PAGE} replace relative="path" />;
  }

  const resetApAccessToken = (accessToken: string, refreshToken: string | null) => {
    resetAccessTokenFunction(accessToken, refreshToken);
  };

  // This is a temporary solution to determine if we should use vex access tokens
  // TODO: remove this once we open the new unilateral flow to all users (once ME-53778 is done)
  const shouldUseVexAccessToken = pathname.includes('vex');

  return (
    <EmailVerificationActivity
      setAccessTokens={shouldUseVexAccessToken ? setAccessTokens : resetApAccessToken}
      helpLinkHref={zdSupportFormUrl || ''}
      showPoweredByMelio={showPoweredByMelio}
    />
  );
};

EmailVerificationRoute.displayName = 'EmailVerificationRoute';
