const SUBMITTED_CARDS_KEY = 'submittedSteps';
export const saveCardsFormState = <T>({
  submittedSteps,
  uniqueIdentifier,
}: {
  submittedSteps: Set<T>;
  uniqueIdentifier: string;
}) => {
  const itemKey = uniqueIdentifier ? `${uniqueIdentifier}-${SUBMITTED_CARDS_KEY}` : SUBMITTED_CARDS_KEY;
  localStorage.setItem(itemKey, JSON.stringify([...submittedSteps]));
};

export const getSavedCardsFromState = <T>({ uniqueIdentifier }: { uniqueIdentifier: string }): Set<T> => {
  let savedSCardsFormState: Set<T>;
  try {
    const itemKey = uniqueIdentifier ? `${uniqueIdentifier}-${SUBMITTED_CARDS_KEY}` : SUBMITTED_CARDS_KEY;
    const savedSubmittedSteps = localStorage.getItem(itemKey);
    savedSCardsFormState = new Set(savedSubmittedSteps ? (JSON.parse(savedSubmittedSteps) as []) : []);
  } catch {
    savedSCardsFormState = new Set<T>([]);
  }

  return savedSCardsFormState;
};

export const getLastOpenedCardFromSubmittedSteps = <T>({
  cardsOrder,
  submittedSteps,
}: {
  cardsOrder: T[];
  submittedSteps: Set<T>;
}) => {
  const lastOpenedCard = cardsOrder.find((card) => !submittedSteps.has(card));
  return lastOpenedCard;
};
