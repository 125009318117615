import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { FeeCatalogsApiClient } from '@melio/platform-api-axios-client';

import { convertFeeCatalogsCentsToDollars } from './utils';

export type UseFeeCatalogProps = UseCollectionApiProps<typeof FeeCatalogsApiClient.getFeeCatalogs>;

export const useFeeCatalog = (props: UseFeeCatalogProps = {}) =>
  useCollectionApi({
    ...props,
    queryKey: 'FeeCatalogsApi',
    queryFn: FeeCatalogsApiClient.getFeeCatalogs,
    select: convertFeeCatalogsCentsToDollars,
  });

export type FeeCatalogCollection = ReturnType<typeof useFeeCatalog>;
