import { Box } from '@chakra-ui/react';
import { IconButton, IconButtonProps } from '@melio/penny';

export const BackButton = (props: Pick<IconButtonProps, 'onClick' | 'isDisabled'>) => (
  <Box gridArea="header-back" justifySelf="left">
    <IconButton
      data-component="NewSinglePaymentStepLayout.BackButton"
      data-testid="layout-back-button"
      {...props}
      aria-label="back"
      icon="chevron-left"
      variant="naked"
      size="medium"
    />
  </Box>
);

BackButton.displayName = 'NewSinglePaymentStepLayout.BackButton';
