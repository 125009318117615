import { useMelioIntl } from '@melio/platform-i18n';
import {
  differenceInCalendarDays,
  differenceInYears,
  format as formatDate,
  // eslint-disable-next-line no-restricted-imports
  isEqual,
  isPast,
  isValid,
  parse,
  parseISO,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isString } from 'lodash';
import { useCallback } from 'react';

export const useDateUtils = (format?: string) => {
  const { timeZone, locale } = useMelioIntl();

  const dateToISOString = (date: Date) => date.toISOString().slice(0, 10);

  const convertFromISO = (isoDate: string) => parseISO(isoDate);

  const dateToString = (date: Date) => formatDate(date, format || 'yyyy-MM-ddTHH:mm:ss.SSSXXX');

  const getPreviousDay = (date: Date) => {
    const previousDate = createDate(date.toISOString());
    return previousDate.setDate(previousDate.getDate() - 1);
  };

  const parseDate = useCallback(
    // eslint-disable-next-line no-restricted-syntax
    (dateStr: string) => (format ? parse(dateStr, format, new Date()) : parseISO(dateStr)),
    [format]
  );

  const convertToDate = (date: Date | number | string) => (isString(date) ? createDate(date) : date);

  const createDate = useCallback(
    (dateStr?: string): Date =>
      // eslint-disable-next-line no-restricted-syntax
      zonedTimeToUtc(dateStr ? parseDate(dateStr) : new Date(), timeZone),
    [timeZone, parseDate]
  );

  const isValidScheduledDate = (scheduledDate: Date | string) => {
    const daysAfterToday = differenceInCalendarDays(
      isString(scheduledDate) ? parseDate(scheduledDate) : scheduledDate,
      createDate()
    );
    return daysAfterToday >= 0;
  };

  const isEqualDate = (dateLeft: Date | number | string, dateRight: Date | number | string) =>
    isEqual(convertToDate(dateLeft), convertToDate(dateRight));

  const isDatePartEqual = useCallback(
    (dateLeft: Date, dateRight: Date) =>
      dateLeft.toLocaleDateString(locale, { timeZone }) === dateRight.toLocaleDateString(locale, { timeZone }),
    [locale, timeZone]
  );

  const isValidPastDate = (date?: Date | number | string) => {
    if (!date) {
      return false;
    }
    const parsedDate = convertToDate(date);
    return isValid(parsedDate) && isPast(parsedDate);
  };

  const isValidAge = (date: Date | number | string | undefined, age: number, mode: 'max' | 'min' = 'min') => {
    if (!date) {
      return false;
    }
    const parsedDate = convertToDate(date);
    const now = createDate();
    return isValid(parsedDate) && mode === 'min'
      ? differenceInYears(now, parsedDate) >= age
      : differenceInYears(now, parsedDate) <= age;
  };

  return {
    dateToString,
    dateToISOString,
    convertFromISO,
    createDate,
    isValidScheduledDate,
    isEqualDate,
    isDatePartEqual,
    isValidPastDate,
    isValidAge,
    getPreviousDay,
    parseDate,
  };
};
