export const Expired: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11828_10731)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
        fill="#018BBB"
      />
      <mask
        id="mask0_11828_10731"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126 72C126 101.823 101.823 126 72 126C42.1769 126 18 101.823 18 72C18 42.1769 42.1769 18 72 18C101.823 18 126 42.1769 126 72Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11828_10731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.3782 67.6968C86.8985 67.6968 83.4598 69.2747 81.2058 72.2695C77.3988 77.3304 78.4151 84.5189 83.476 88.3269L92.6398 95.2195C94.7047 96.7736 97.125 97.5231 99.5248 97.5231C103.005 97.5231 106.443 95.9453 108.696 92.9504C112.503 87.8895 111.487 80.7011 106.426 76.8941L97.2632 70.0004C95.1972 68.4452 92.778 67.6957 90.3782 67.6968ZM90.3793 73.4305C91.6256 73.4305 92.8136 73.8279 93.817 74.5818L102.98 81.4754C105.506 83.3762 106.016 86.9769 104.115 89.5031C103.022 90.9567 101.348 91.7905 99.5248 91.7905C98.2774 91.7905 97.0883 91.3909 96.086 90.6381L86.9233 83.7445C85.6986 82.8243 84.907 81.4819 84.6931 79.9656C84.4782 78.4493 84.867 76.9405 85.7882 75.7169C86.8812 74.2632 88.5552 73.4305 90.3793 73.4305Z"
          fill="#C9CED2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.802 77.7103C104.232 77.179 98.9657 80.7959 97.5909 86.3924C96.08 92.543 99.8405 98.753 105.991 100.264L117.127 103C117.68 103.135 118.234 103.229 118.785 103.281C124.357 103.814 129.622 100.195 130.997 94.5994C132.508 88.4488 128.747 82.2388 122.597 80.7278L111.461 77.9933C110.907 77.8572 110.354 77.7632 109.802 77.7103ZM109.257 83.4181C109.536 83.4451 109.818 83.4926 110.094 83.5607L121.23 86.2952C124.299 87.0502 126.184 90.1606 125.43 93.231C124.756 95.9742 122.134 97.8426 119.331 97.5748C119.052 97.5467 118.771 97.4992 118.494 97.4322L107.358 94.6966C104.289 93.9416 102.404 90.8302 103.158 87.7608C103.832 85.0176 106.454 83.1503 109.257 83.4181Z"
          fill="#C9CED2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.719 87.0684C124.963 86.8049 122.103 87.5339 119.72 89.3353C114.667 93.1531 113.665 100.344 117.483 105.396L124.396 114.545C126.414 117.214 129.371 118.752 132.457 119.047C135.213 119.311 138.074 118.583 140.458 116.781C145.51 112.964 146.511 105.772 142.694 100.72L135.78 91.5709C133.764 88.9022 130.806 87.3622 127.719 87.0684ZM127.175 92.7751C128.795 92.9306 130.226 93.7298 131.207 95.0269L138.119 104.176C140.025 106.698 139.524 110.301 137.001 112.205C135.853 113.074 134.433 113.477 133.003 113.339C131.383 113.186 129.951 112.385 128.971 111.089L122.058 101.94C121.135 100.719 120.743 99.2098 120.953 97.6934C121.165 96.1771 121.954 94.8314 123.176 93.9091C124.324 93.0419 125.744 92.639 127.175 92.7751Z"
          fill="#C9CED2"
        />
        <path
          d="M63.7818 68.8149C60.4187 65.7963 55.2747 65.5306 51.5929 68.4132L44.1161 74.2678C39.9862 77.5003 39.619 83.2102 42.4918 87.5983H42.4929C45.3646 91.9863 50.0086 94.7921 54.8664 92.8157L63.663 89.2366C67.429 87.7041 69.6797 84.03 69.5793 80.1906"
          stroke="#C9CED2"
          strokeWidth="5.309"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.1405 79.6885C40.3973 79.6885 37.6454 80.667 35.4509 82.6531L26.948 90.347C22.2533 94.5968 21.8904 101.849 26.1402 106.544C28.4028 109.045 31.5186 110.315 34.6463 110.315C37.3895 110.315 40.1413 109.338 42.3359 107.352L50.8387 99.6577C55.5346 95.409 55.8964 88.1578 51.6466 83.462C49.3829 80.9607 46.2682 79.6885 43.1405 79.6885ZM43.1405 85.4222C44.7832 85.4222 46.2941 86.0918 47.3957 87.309C49.5168 89.6536 49.3354 93.2857 46.9918 95.4079L38.4889 103.101C37.4327 104.055 36.0686 104.584 34.6463 104.584C33.0025 104.584 31.4916 103.913 30.3911 102.697C28.2699 100.353 28.4514 96.7201 30.795 94.599L39.2978 86.905C40.3541 85.9482 41.7192 85.4222 43.1405 85.4222Z"
          fill="#C9CED2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.706 123.377C21.706 123.377 19.0881 124.858 17.1592 125.048L18.8299 126.856C18.8299 126.856 20.0568 126.616 21.6239 125.739C21.6239 125.739 21.8453 124.074 21.706 123.377Z"
          fill="#687680"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0213 95.6719C27.532 95.6719 26.0178 95.9646 24.5598 96.5802L13.9985 101.047C8.16539 103.514 5.4373 110.241 7.90403 116.074C9.75515 120.449 14.0017 123.077 18.4707 123.077C19.9601 123.077 21.4742 122.785 22.9322 122.169L33.4935 117.701C39.3255 115.235 42.0536 108.507 39.5869 102.674C37.7369 98.3006 33.4903 95.6719 29.0213 95.6719ZM29.0213 101.406C31.3325 101.406 33.4071 102.779 34.3068 104.907C34.9029 106.317 34.9148 107.876 34.3392 109.295C33.7635 110.715 32.6706 111.824 31.259 112.422L20.6988 116.888C19.9838 117.191 19.2343 117.343 18.4707 117.343C16.1596 117.343 14.0849 115.968 13.1852 113.841C11.953 110.93 13.3202 107.559 16.2319 106.327L26.7932 101.86C27.5082 101.558 28.2577 101.406 29.0213 101.406Z"
          fill="#C9CED2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7742 85.6551C44.7742 85.6551 42.327 84.5988 38.9833 87.1671C38.9833 87.1671 39.3494 87.989 39.7749 88.705C40.2004 89.4221 40.6033 89.893 40.6033 89.893C40.6033 89.893 43.273 87.6531 46.3186 87.8907C46.3186 87.8907 45.9072 87.368 44.7742 85.6551Z"
          fill="#AEB5BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.9917 95.4077C46.9917 95.4077 49.5156 96.6519 53.4576 96.1745C53.4576 96.1745 53.1002 97.4403 51.2858 99.2309C51.2858 99.2309 47.4042 99.5301 44.2388 97.9176L46.9917 95.4077Z"
          fill="#AEB5BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.14 94.6055C107.14 94.6055 104.92 96.9221 100.826 97.4221C100.826 97.4221 101.456 98.1327 102.482 98.7365C102.482 98.7365 106.492 98.1079 108.126 94.8679L107.14 94.6055Z"
          fill="#AEB5BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.491 92.866C125.491 92.866 128.531 92.0484 130.927 94.7095C130.927 94.7095 130.778 95.5141 130.441 96.2766C130.441 96.2766 129.403 94.2754 125.072 94.2235C125.072 94.2235 125.396 93.555 125.491 92.866Z"
          fill="#687680"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M122.78 102.937C122.78 102.937 119.805 103.943 115.964 102.705C115.964 102.705 116.239 103.903 117.105 104.885C117.105 104.885 121.016 105.714 123.876 104.353L122.78 102.937Z"
          fill="#AEB5BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.758 83.7153C110.758 83.7153 107.279 82.4604 105.085 84.7025C105.085 84.7025 105.266 85.3958 105.247 85.8203C105.247 85.8203 107.498 84.1625 110.954 84.9649C110.954 84.9649 110.855 83.8817 110.758 83.7153Z"
          fill="#AEB5BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9329 103.054C23.9329 103.054 27.5465 101.053 29.5164 101.412C29.5164 101.412 29.861 102.221 30.3913 102.697C30.3913 102.697 28.4419 103.054 24.8887 104.935C24.8887 104.935 24.2612 104.068 23.9329 103.054Z"
          fill="#AEB5BB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7575 110.28C33.7575 110.28 37.0126 110.728 40.3563 108.728C40.3563 108.728 40.3272 109.803 39.4308 111.953C39.4308 111.953 33.8169 113.236 32.3535 111.803C32.3535 111.803 33.1754 111.265 33.7575 110.28Z"
          fill="#AEB5BB"
        />
        <path
          d="M65.6665 60.3333L56.9617 52.9536"
          stroke="#FFDC24"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.1222 57.6603L89.3021 47.9165"
          stroke="#FFDC24"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.7277 56.6438L75.2536 41.7798"
          stroke="#FFDC24"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.314 52.9345L67.8042 49.0962"
          stroke="#FFDC24"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.8489 63.1415L93.7988 60.0884"
          stroke="#FFDC24"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.5414 50.2197L82.6517 43.4492"
          stroke="#FFDC24"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11828_10731">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);
