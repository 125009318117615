import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EmptyBillsIcon = (props: IconProps) => (
  <Icon width="100%" height="82px" viewBox="0 0 77 82" fill="none" {...props}>
    <path
      d="M64.9945 44.125V6.33285C64.9945 3.38762 62.6069 1 59.6616 1H16.9989C14.0536 1 11.666 3.38762 11.666 6.33285V44.125"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.3368 39.0945C72.138 38.4592 73.2319 38.3381 74.1526 38.7826C75.0735 39.2269 75.659 40.1589 75.6599 41.1815V75.6671C75.6599 78.6124 73.2722 81 70.327 81H6.33285C3.38762 81 1 78.6124 1 75.6671V41.1815C1.00107 40.1589 1.5864 39.2269 2.50727 38.7826C3.42815 38.3381 4.52203 38.4592 5.32323 39.0945L31.7422 60.0492C35.6091 63.0861 41.051 63.0861 44.9179 60.0492L71.3368 39.0945Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.8672 54.5357L64.9924 71.9272"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7914 54.5357L11.6641 71.9272"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0413 34.2201C40.6038 36.2726 43.0693 37.4337 45.6467 37.3311C49.6963 37.3311 52.9813 34.8673 52.9813 31.831C52.9813 28.7949 49.707 26.3348 45.661 26.3348C41.6151 26.3348 38.3301 23.871 38.3301 20.8313C38.3301 17.7915 41.6151 15.3312 45.661 15.3312C48.2389 15.2269 50.7053 16.3882 52.2667 18.4422"
      stroke="#228800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M45.6602 37.3321V40.9968" stroke="#228800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M45.6602 11.6657V15.3304" stroke="#228800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.3301 14.3321H27.6629" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.3301 24.9978H27.6629" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.3301 35.6635H27.6629" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
