import { isUndefined } from 'lodash';

const cleanArguments = <T extends unknown[]>(args: T): T => {
  for (let i = args.length - 1; i >= 0; i -= 1) {
    if (isUndefined(args[i])) {
      args.pop();
    } else {
      break;
    }
  }
  return args;
};

type FN = (...args: any[]) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
/**
 * A utility to invoke a function with arguments, and omit options args, so the signature is clean
 *
 * `invokeFunction(_.merge, {}, {}, undefined, undefined)` triggers `_.merge({}, {})`
 *
 * @param fn the function to invoke
 * @param args function arguments
 * @returns the return value of FN
 */
export function invokeFunction<T extends FN>(fn: T, args: Parameters<T>): ReturnType<T> {
  return fn.apply(null, cleanArguments<Parameters<T>>(args)); // eslint-disable-line @typescript-eslint/no-unsafe-return, prefer-spread
}
