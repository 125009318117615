/* eslint-disable max-lines */
import {
  BillSubscriptionsApiGetBillSubscriptionsRequest,
  CollaboratorExpandableFields,
  DeliveryMethodTypeOption,
  FeesBreakdown,
  FeesBreakdownRequest,
  InvitationStatus,
  OrganizationPreference,
  PostPaymentIntentsFromPaymentRequest,
  PostPlaidLinkTokenRequest,
} from '@melio/platform-api-axios-client';
import { memoize } from 'lodash';

import {
  BillSubscription,
  BulkPatchPaymentIntentResponse,
  BulkPostPaymentSettingsCalculatorRequest,
  Collaborator,
  CountryInternationalDeliveryDetails,
  CreateOrganizationBeneficialOwner,
  FreeChecksData,
  GetVendorsVendorIdUnilateralRequestsValidationForResendResponse,
  InternationalBankDetails,
  Invitation,
  OrganizationBeneficialOwner,
  OrganizationBeneficialOwnerBulkResponse,
  OrgPreferenceCreateRequest,
  Params,
  PatchBillSubscriptionsRequest,
  PatchCollaboratorRequest,
  PatchPaymentIntentsBulkRequest,
  PatchPaymentIntentsPaymentIntentIdRequest,
  Payment,
  PaymentFee,
  PaymentFeeCalculationParameters,
  PaymentIntent,
  PaymentIntentsApiGetPaymentIntentsRequest,
  PaymentRequest,
  PaymentSettingsCalculations,
  PaymentSettingsCalculatorRequestParams,
  PaymentsReportCreateParams,
  PostBankDetailsRequest,
  PostBillSubscriptionsRequest,
  PostInvitationsRequest,
  PostPaymentIntentsBulkResponse,
  PostPaymentIntentsFromBillBulkRequest,
  PostPaymentIntentsFromBillRequest,
  PostPaymentIntentsRequest,
  PostPaymentSettingsCalculatorBulkResponse,
  PostPaymentSettingsCalculatorRequest,
  PostPaymentSettingsCalculatorResponse,
  PostPlaidLinkTokenResponseData,
  PostVendorsVendorIdUnilateralRequestsRequest,
  ReceiptInfo,
  RolesAndPermissions,
  UnilateralRequest,
  Vendor,
} from './entities';
import { httpClient, HttpResponse } from './http-client';
import { convertDates } from './utils/date-utils';

type UpdateEntityParams<T> = RecursivePartial<T>;

type API<T, C = T, U = UpdateEntityParams<T>, Q = unknown> = {
  readonly url: string;
  fetch?(params?: Q): Promise<T[]>;
  get?: (id: string) => Promise<T>;
  create?(params: C): Promise<T>;
  update?(id: string, data: U): Promise<T>;
  delete?(id: string): Promise<unknown>;
};

export type PlatformResponse<T = unknown> = { data: T };
const parsePlatformPayload = <T>({ data }: HttpResponse<PlatformResponse<T>>) => convertDates(data?.data);
const parsePlatformPayloadAsString = ({ data }: { data: unknown }) => data as string;
const ignoreResponse: VoidFunction = () => void 0;

class InternationalCountriesApi implements API<CountryInternationalDeliveryDetails> {
  url = '/v1/delivery-methods/international/countries';
  fetch = () =>
    httpClient.get<PlatformResponse<CountryInternationalDeliveryDetails[]>>(this.url).then(parsePlatformPayload);
}

class InternationalBankDetailsApi implements API<InternationalBankDetails, PostBankDetailsRequest> {
  url = '/v1/delivery-methods/international/bank-details';
  getBankDetails = (params: PostBankDetailsRequest) =>
    httpClient
      .post<PlatformResponse<InternationalBankDetails>, PostBankDetailsRequest>(this.url, params)
      .then(parsePlatformPayload);
}

class PaymentFeesApi implements API<PaymentFee, void, void, PaymentFeeCalculationParameters> {
  constructor(private paymentId: Payment['id']) {}

  get url() {
    return `/v1/payments/${this.paymentId}/calculate-fee`;
  }

  fetch = (params: PaymentFeeCalculationParameters) =>
    httpClient
      .post<PlatformResponse<PaymentFee[]>, PaymentFeeCalculationParameters>(this.url, params)
      .then(parsePlatformPayload);
}

class OrganizationBeneficialOwnerApi implements API<OrganizationBeneficialOwner, CreateOrganizationBeneficialOwner> {
  url = '/v1/organization-beneficial-owners';
  fetch = () => httpClient.get<PlatformResponse<OrganizationBeneficialOwner[]>>(this.url).then(parsePlatformPayload);
  create = (params: CreateOrganizationBeneficialOwner) =>
    httpClient
      .post<PlatformResponse<OrganizationBeneficialOwner>, CreateOrganizationBeneficialOwner>(this.url, params)
      .then(parsePlatformPayload);
  batchCreate = (params: CreateOrganizationBeneficialOwner[]) =>
    httpClient
      .post<PlatformResponse<OrganizationBeneficialOwnerBulkResponse['data']>, CreateOrganizationBeneficialOwner[]>(
        [this.url, 'bulk'].join('/'),
        params
      )
      .then(parsePlatformPayload);
}

class BillSubscriptionsApi
  implements API<BillSubscription, PostBillSubscriptionsRequest, PatchBillSubscriptionsRequest>
{
  url = `/v1/bill-subscriptions`;
  fetch = () => httpClient.get<PlatformResponse<BillSubscription[]>>(this.url).then(parsePlatformPayload);
  get = (id: BillSubscription['id'], params?: BillSubscriptionsApiGetBillSubscriptionsRequest) =>
    httpClient.get<PlatformResponse<BillSubscription>>([this.url, id].join('/'), { params }).then(parsePlatformPayload);
  create = (params: PostBillSubscriptionsRequest) =>
    httpClient
      .post<PlatformResponse<BillSubscription>, PostBillSubscriptionsRequest>(this.url, params)
      .then(parsePlatformPayload);
  update = (
    id: BillSubscription['id'],
    data: PatchBillSubscriptionsRequest,
    params?: BillSubscriptionsApiGetBillSubscriptionsRequest
  ) =>
    httpClient
      .patch<PlatformResponse<BillSubscription>>([this.url, id].join('/'), data, { params })
      .then(parsePlatformPayload);
  cancel = (id: BillSubscription['id']) =>
    httpClient.post<PlatformResponse<BillSubscription>>([this.url, id, 'cancel'].join('/')).then(ignoreResponse);
}

class PaymentIntentsApi
  implements API<PaymentIntent, PostPaymentIntentsRequest, PatchPaymentIntentsPaymentIntentIdRequest>
{
  url = `/v1/payment-intents`;
  paymentSettingsUrl = `/v1/services/payment-settings`;
  fetch = (params?: PaymentIntentsApiGetPaymentIntentsRequest) =>
    httpClient.get<PlatformResponse<PaymentIntent[]>>(this.url, { params }).then(parsePlatformPayload);
  get = (id: PaymentIntent['id']) =>
    httpClient.get<PlatformResponse<PaymentIntent>>([this.url, id].join('/')).then(parsePlatformPayload);
  create = (params: PostPaymentIntentsRequest) =>
    httpClient
      .post<PlatformResponse<PaymentIntent>, PostPaymentIntentsRequest>(this.url, params)
      .then(parsePlatformPayload);
  delete = (id: PaymentIntent['id']) => httpClient.delete([this.url, id].join('/')).then(ignoreResponse);
  createFromBill = (params: PostPaymentIntentsFromBillRequest) =>
    httpClient
      .post<PlatformResponse<PaymentIntent>, PostPaymentIntentsFromBillRequest>(
        [this.url, 'from-bill'].join('/'),
        params
      )
      .then(parsePlatformPayload);
  batchCreateFromBill = (data: PostPaymentIntentsFromBillBulkRequest, params?: Params) =>
    httpClient
      .post<PlatformResponse<PostPaymentIntentsBulkResponse['data']>, PostPaymentIntentsFromBillBulkRequest>(
        [this.url, 'from-bill/bulk'].join('/'),
        data,
        { params }
      )
      .then(parsePlatformPayload);
  createFromPayment = (params: PostPaymentIntentsFromPaymentRequest) =>
    httpClient
      .post<PlatformResponse<PaymentIntent>, PostPaymentIntentsFromPaymentRequest>(
        [this.url, 'from-payment'].join('/'),
        params
      )
      .then(parsePlatformPayload);
  update = (id: PaymentIntent['id'], data: PatchPaymentIntentsPaymentIntentIdRequest) =>
    httpClient.patch<PlatformResponse<PaymentIntent>>([this.url, id].join('/'), data).then(parsePlatformPayload);
  batchUpdate = (data: PatchPaymentIntentsBulkRequest, params?: Params) =>
    httpClient
      .patch<PlatformResponse<BulkPatchPaymentIntentResponse['data']>>([this.url, 'bulk'].join('/'), data, { params })
      .then(parsePlatformPayload);
  confirm = (id: PaymentIntent['id']) =>
    httpClient.post<PlatformResponse<PaymentIntent>>([this.url, id, 'confirm'].join('/')).then(parsePlatformPayload);
  getPaymentSettings = (
    data: BulkPostPaymentSettingsCalculatorRequest,
    params?: PaymentSettingsCalculatorRequestParams
  ) =>
    httpClient
      .post<
        PlatformResponse<PostPaymentSettingsCalculatorBulkResponse['data']>,
        BulkPostPaymentSettingsCalculatorRequest
      >([this.paymentSettingsUrl, 'bulk'].join('/'), data, { params })
      .then(parsePlatformPayload);
}

class PaymentSettingsCalculationsApi implements API<PaymentSettingsCalculations> {
  url = `/v1/services/payment-settings`;
  getPaymentSettings = (
    data: BulkPostPaymentSettingsCalculatorRequest,
    params?: PaymentSettingsCalculatorRequestParams
  ) =>
    httpClient
      .post<
        PlatformResponse<PostPaymentSettingsCalculatorBulkResponse['data']>,
        BulkPostPaymentSettingsCalculatorRequest
      >([this.url, 'bulk'].join('/'), data, { params })
      .then(parsePlatformPayload);

  getPaymentSetting = (data: PostPaymentSettingsCalculatorRequest, params?: PaymentSettingsCalculatorRequestParams) =>
    httpClient
      .post<PlatformResponse<PostPaymentSettingsCalculatorResponse['data']>, PostPaymentSettingsCalculatorRequest>(
        [this.url].join('/'),
        data,
        { params }
      )
      .then(parsePlatformPayload);
}

class PlaidLinkTokenApi implements API<PostPlaidLinkTokenResponseData, PostPlaidLinkTokenRequest> {
  url = `/v1/funding-sources/plaid/link/token`;
  create = (params: PostPlaidLinkTokenRequest) =>
    httpClient
      .post<PlatformResponse<PostPlaidLinkTokenResponseData>, PostPlaidLinkTokenRequest>(this.url, params)
      .then(parsePlatformPayload);
}

class FeeReceiptApi implements API<ReceiptInfo> {
  url = `/v1/fees/receipts`;
  fetch = () => httpClient.get<PlatformResponse<ReceiptInfo[]>>(this.url).then(parsePlatformPayload);
  getData = (id: ReceiptInfo['id']) =>
    httpClient.get<PlatformResponse<string>>([this.url, id].join('/')).then(parsePlatformPayload);
  downloadPdf = (id: ReceiptInfo['id']) =>
    httpClient.get<PlatformResponse<string>>([this.url, id, 'download'].join('/')).then(parsePlatformPayloadAsString);
}

class FreeChecksApi implements API<FreeChecksData> {
  url = `/v1/fees/free-checks`;
  get = () => httpClient.get<PlatformResponse<FreeChecksData>>(this.url).then(parsePlatformPayload);
}

class FeesBreakdownApi implements API<FeesBreakdown> {
  url = `/v1/fees/breakdown`;
  getFeesBreakdown = (params: FeesBreakdownRequest) =>
    httpClient.post<PlatformResponse<FeesBreakdown>, FeesBreakdownRequest>(this.url, params).then(parsePlatformPayload);
}

class OrganizationPreferencesApi implements API<OrganizationPreference, OrganizationPreference> {
  url = `/v1/organization-preferences`;
  fetch = () => httpClient.get<PlatformResponse<OrganizationPreference[]>>(this.url).then(parsePlatformPayload);
  create = (params: OrganizationPreference) =>
    httpClient
      .post<PlatformResponse<OrganizationPreference>, OrgPreferenceCreateRequest>(this.url, {
        organizationPreference: { ...params },
      })
      .then(parsePlatformPayload);
  get = (key: OrganizationPreference['key']) =>
    httpClient.get<PlatformResponse<OrganizationPreference>>([this.url, key].join('/')).then(parsePlatformPayload);
}

class PaymentsReportApi implements API<never, PaymentsReportCreateParams> {
  url = '/v1/reports/payments';
  createReport = (createParams: PaymentsReportCreateParams) =>
    httpClient
      .post<string, PaymentsReportCreateParams>(this.url, createParams)
      .then(({ data, headers }) => ({ data, headers }));
}

class UnilateralRequestApi implements API<UnilateralRequest, PostVendorsVendorIdUnilateralRequestsRequest> {
  constructor(private vendorId: Vendor['id']) {}

  get url() {
    return `/v1/vendors/${this.vendorId}/unilateral-requests`;
  }

  fetch = () => httpClient.get<PlatformResponse<UnilateralRequest[]>>(this.url).then(parsePlatformPayload);
  create = (params: PostVendorsVendorIdUnilateralRequestsRequest) =>
    httpClient
      .post<PlatformResponse<UnilateralRequest>, PostVendorsVendorIdUnilateralRequestsRequest>(this.url, params)
      .then(parsePlatformPayload);
  getValidationForResend = () =>
    httpClient
      .get<PlatformResponse<GetVendorsVendorIdUnilateralRequestsValidationForResendResponse['data']>>(
        [this.url, 'validation-for-resend'].join('/')
      )
      .then(parsePlatformPayload);
}

class PaymentRequestApi implements API<PaymentRequest> {
  url = `/v1/payment-requests`;
  get = (id: PaymentRequest['id']) =>
    httpClient.get<PlatformResponse<PaymentRequest>>([this.url, id].join('/')).then(parsePlatformPayload);
  cancel = (id: PaymentRequest['id']) =>
    httpClient.post<PlatformResponse<PaymentRequest>>([this.url, id, 'cancel'].join('/')).then(parsePlatformPayload);
  approve = (id: PaymentRequest['id']) =>
    httpClient.post<PlatformResponse<PaymentRequest>>([this.url, id, 'approve'].join('/')).then(parsePlatformPayload);
}

class CollaboratorApi implements API<Collaborator> {
  url = '/v1/collaborators';

  fetch = (expand?: CollaboratorExpandableFields[]) =>
    httpClient
      .get<PlatformResponse<Collaborator[]>>(this.url, expand && { params: { expand } })
      .then(parsePlatformPayload);
  get = (id: string, params?: { expand?: CollaboratorExpandableFields[] }) =>
    httpClient.get<PlatformResponse<Collaborator>>([this.url, id].join('/'), { params }).then(parsePlatformPayload);
  update = (id: Collaborator['id'], data: PatchCollaboratorRequest) =>
    httpClient.patch<PlatformResponse<Collaborator>>([this.url, id].join('/'), data).then(parsePlatformPayload);
  delete = (id: Collaborator['id']) => httpClient.delete([this.url, id].join('/')).then(ignoreResponse);
}

class InvitationApi implements API<Invitation, PostInvitationsRequest> {
  url = '/v1/invitations';

  fetch = (params?: { status: InvitationStatus }) =>
    httpClient.get<PlatformResponse<Invitation[]>>(this.url, params && { params }).then(parsePlatformPayload);
  create = (data: PostInvitationsRequest) =>
    httpClient.post<PlatformResponse<Invitation>, PostInvitationsRequest>(this.url, data).then(parsePlatformPayload);
  cancel = (id: Invitation['id']) =>
    httpClient.post<PlatformResponse<Invitation>>([this.url, id, 'cancel'].join('/')).then(parsePlatformPayload);
  resend = (id: Invitation['id']) =>
    httpClient.post<PlatformResponse<Invitation>>([this.url, id, 'resend'].join('/')).then(parsePlatformPayload);
}

class PermissionsApi implements API<RolesAndPermissions> {
  url = '/v1/permissions';

  get = () => httpClient.get<PlatformResponse<RolesAndPermissions>>(this.url).then(parsePlatformPayload);
}

class DeliveryMethodTypeOptionsRequestApi implements API<DeliveryMethodTypeOption> {
  constructor(private vendorId: Vendor['id']) {}

  get url() {
    return `/v1/vendors/${this.vendorId}/delivery-method-type-options`;
  }

  fetch = () => httpClient.get<PlatformResponse<DeliveryMethodTypeOption[]>>(this.url).then(parsePlatformPayload);
}

class APIClient {
  organizationBeneficialOwners = memoize(() => new OrganizationBeneficialOwnerApi());
  paymentIntents = memoize(() => new PaymentIntentsApi());
  billSubscriptions = memoize(() => new BillSubscriptionsApi());
  plaidLinkTokens = memoize(() => new PlaidLinkTokenApi());
  feeReceipt = memoize(() => new FeeReceiptApi());
  organizationPreferences = memoize(() => new OrganizationPreferencesApi());
  freeChecks = memoize(() => new FreeChecksApi());
  feesBreakdown = memoize(() => new FeesBreakdownApi());
  paymentsReport = memoize(() => new PaymentsReportApi());
  paymentRequests = memoize(() => new PaymentRequestApi());
  paymentFees = memoize((paymentId: Payment['id']) => new PaymentFeesApi(paymentId));
  unilateralRequests = memoize((vendorId: Vendor['id']) => new UnilateralRequestApi(vendorId));
  internationalCountries = memoize(() => new InternationalCountriesApi());
  internationalBankDetails = memoize(() => new InternationalBankDetailsApi());
  paymentSettings = memoize(() => new PaymentSettingsCalculationsApi());
  collaborators = memoize(() => new CollaboratorApi());
  invitations = memoize(() => new InvitationApi());
  permissions = memoize(() => new PermissionsApi());
  deliveryMethodTypeOptions = memoize((vendorId: Vendor['id']) => new DeliveryMethodTypeOptionsRequestApi(vendorId));
}

export const apiClient = new APIClient();
