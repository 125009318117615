import { Loader } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { DataToPrefillAnOnboardingFormUser, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { CollapsibleCardFormWidget, PersonalDetailsFormFields, PersonalDetailsFormWidget } from '@melio/vex-widgets';
import { isEmpty, omitBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useMergePrePopulatedUserData } from './hooks/useMergePrePopulatedUserData';

type PersonalDetailsCardActivityProps = {
  isOpened?: boolean;
  isDisabled?: boolean;
  isFilled: boolean;
  onOpen: () => void;
  setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
  onSubmit: () => void;
  prePopulatedUser: DataToPrefillAnOnboardingFormUser;
};

export const PersonalDetailsCardActivity = withAnalyticsContext<PersonalDetailsCardActivityProps>(
  forwardRef(
    (
      { onOpen, setDirtyStatus, onSubmit, setAnalyticsProperties, isFilled, isOpened, isDisabled, prePopulatedUser },
      ref
    ) => {
      const { track } = useAnalytics();
      setAnalyticsProperties({
        Intent: 'add-personal-details',
        PageName: 'enter-your-details',
      });
      const { formatMessage } = useMelioIntl();
      const {
        isMutating: isAccountUpdating,
        isFetched: isAccountFetched,
        isLoading: isAccountLoading,
        data: accountMeData,
        update: updateAccount,
      } = useAccount({ id: 'me', enabled: isOpened });

      const { firstName, lastName, dateOfBirth, phoneNumber } = accountMeData?.user || {};
      const mergedPrePopulatedUser = useMergePrePopulatedUserData(
        {
          firstName: firstName || '',
          lastName: lastName || '',
          dateOfBirth,
          phoneNumber,
        },
        prePopulatedUser
      );

      const isPopulated = useMemo(() => !isEmpty(omitBy(mergedPrePopulatedUser, isEmpty)), [mergedPrePopulatedUser]);

      const submitPersonalDetails = async (values: PersonalDetailsFormFields) => {
        await updateAccount({ user: { ...values, dateOfBirth: values.dateOfBirth?.toString() } });
        track('Organization', 'Click', {
          Cta: 'continue',
        });
        onSubmit();
      };

      const onValidationError = (isRequiredError: boolean) => {
        track('Organization', 'Status', {
          ErrorType: `${isRequiredError ? 'missing' : 'invalid'}-personal-details`,
          Status: 'failure',
        });
      };

      useEffect(() => {
        if (isOpened && isAccountFetched) {
          track('Organization', 'View');
        }
      }, [isAccountFetched, isOpened, track]);

      useEffect(() => {
        if (isAccountFetched) {
          setAnalyticsProperties({ OrganizationId: accountMeData?.organizationId, IsPopulated: isPopulated });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isAccountFetched, accountMeData?.organizationId]);

      return (
        <CollapsibleCardFormWidget
          title={formatMessage('vex.activities.vendorOnboarding.personalDetails.title')}
          subtitle={formatMessage('vex.activities.vendorOnboarding.personalDetails.subtitle')}
          isOpened={isOpened}
          isFilled={isFilled}
          isDisabled={isDisabled}
          onOpen={onOpen}
          ref={ref}
        >
          {isAccountLoading && <Loader />}
          {isAccountFetched && !isAccountLoading && (
            <PersonalDetailsFormWidget
              onValidationError={onValidationError}
              onSubmit={submitPersonalDetails}
              setDirtyStatus={setDirtyStatus}
              isSaving={isAccountUpdating}
              defaultValues={mergedPrePopulatedUser}
            />
          )}
        </CollapsibleCardFormWidget>
      );
    }
  )
);

PersonalDetailsCardActivity.displayName = 'PersonalDetailsCardActivity';
