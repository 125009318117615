import { EventProperties, useAnalytics, ViewContext } from '@melio/platform-analytics';
import { useEffect } from 'react';

export const usePageView = (viewContext: ViewContext, viewProperties?: EventProperties) => {
  const { track } = useAnalytics();

  useEffect(() => {
    track(viewContext, 'View', viewProperties);
    /** This hook should occur only once on page load */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const usePageViewStatus = (viewContext: ViewContext, viewProperties?: EventProperties) => {
  const { track } = useAnalytics();

  useEffect(() => {
    track(viewContext, 'Status', viewProperties);
    /** This hook should occur only once on page load */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
