import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';

export const useVendorEmailVerified = ({ accessToken, action }: { accessToken: string | undefined; action: string }) =>
  useMemo(() => {
    if (!accessToken) {
      return {
        isEmailVerified: false,
        verifiedEmailAddress: undefined,
      };
    }

    try {
      const decodedOnboardingAccessToken = jwtDecode<{ user: { email: string }; claims: { action?: string } }>(
        accessToken
      );
      const isEmailVerified =
        Boolean(decodedOnboardingAccessToken?.user?.email) && action === decodedOnboardingAccessToken?.claims?.action;
      const verifiedEmailAddress = isEmailVerified ? decodedOnboardingAccessToken.user.email : undefined;

      return {
        isEmailVerified,
        verifiedEmailAddress,
      };
    } catch {
      return {
        isEmailVerified: false,
        verifiedEmailAddress: undefined,
      };
    }
  }, [accessToken, action]);
