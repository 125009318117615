export const SyncUser: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126 72C126 101.824 101.824 126 72 126C42.1762 126 18 101.824 18 72C18 42.1762 42.1762 18 72 18C101.824 18 126 42.1762 126 72Z"
      fill="#98D5EC"
    />
    <path
      d="M33.8034 65.7257C34.2525 62.2705 35.1841 58.8985 36.5716 55.7063C39.85 48.0526 45.5147 41.6564 52.7447 37.4449C59.9748 33.2333 68.3945 31.4251 76.7833 32.2825C85.1722 33.1398 93.0943 36.6181 99.4015 42.2133C105.709 47.8084 110.073 55.2296 111.862 63.4011"
      stroke="#273B49"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M40.1301 83.0249L33.7492 77.6691C33.5365 77.4906 33.2111 77.5778 33.1162 77.8388L30.268 85.6674"
      stroke="#273B49"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <path
      d="M106.045 60.9829L112.425 66.3387C112.638 66.5172 112.964 66.43 113.058 66.169L115.907 58.3404"
      stroke="#273B49"
      strokeWidth="4"
      strokeLinecap="square"
    />
    <circle cx="72.2859" cy="59.5452" r="13.8206" fill="#008BC1" />
    <mask
      id="mask0_11828_10514"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="32"
      y="32"
      width="81"
      height="81"
    >
      <circle cx="72.0556" cy="72.0556" r="40.0556" fill="#212124" />
    </mask>
    <g mask="url(#mask0_11828_10514)">
      <path
        d="M72.4553 77.6514C57.2198 77.6514 44.7884 92.2726 44.1051 110.622C44.07 111.567 44.6941 112.402 45.5987 112.676L72.4553 120.82L99.3118 112.676C100.216 112.402 100.841 111.567 100.805 110.622C100.122 92.2726 87.6907 77.6514 72.4553 77.6514Z"
        fill="#008BC1"
      />
    </g>
    <path
      d="M112.193 77.049C111.723 80.5531 110.77 83.9757 109.361 87.2187C106.03 94.9939 100.315 101.508 93.0429 105.818C85.7709 110.128 77.3202 112.008 68.9156 111.188C60.5111 110.367 52.5895 106.887 46.2989 101.253C40.0084 95.6194 35.6758 88.1237 33.9292 79.8527"
      stroke="#273B49"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
