import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient } from '@melio/api-client';
import { OrganizationPreference, OrganizationPreferencesApiClient } from '@melio/platform-api-axios-client';

import { CreateFn, ListFn } from './types';

export type UseOrganizationPreferencesProps = UseCollectionApiProps<ListFn, OrganizationPreference>;

export const useOrganizationPreferences = (props: UseOrganizationPreferencesProps = {}) => {
  const queryClient = useMelioQueryClient();

  return useCollectionApi<ListFn, CreateFn>({
    ...props,
    queryKey: 'OrganizationPreferencesApi',
    queryFn: OrganizationPreferencesApiClient.getOrganizationPreferences,
    createFn: (organizationPreference: OrganizationPreference) =>
      OrganizationPreferencesApiClient.setOrganizationPreferences({ organizationPreference }),
    onCreate: (data: OrganizationPreference) =>
      queryClient.invalidateQueries('OrganizationPreferencesApi', 'model', data.key),
  });
};

export type OrganizationPreferenceCollection = ReturnType<typeof useOrganizationPreferences>;
