import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useUploadLogoFile } from '@melio/vex-domain';
import { CollapsibleCardFormWidget, LogoUploadWidget } from '@melio/vex-widgets';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

type LogoUploadCardActivityProps = {
  isOpened?: boolean;
  isDisabled?: boolean;
  isFilled: boolean;
  onSubmit: () => void;
  onOpen: () => void;
  setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
};

export const LogoUploadCardActivity = withAnalyticsContext<LogoUploadCardActivityProps>(
  forwardRef(({ onOpen, isFilled, isOpened, isDisabled, onSubmit, setAnalyticsProperties }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'upload-your-logo',
      Intent: 'add-business-logo',
      IsPopulated: false,
    });

    const { data: accountData, isFetched: isAccountFetched } = useAccount({ id: 'me', enabled: isOpened });

    // Find way to avoid trying to fetch this (not render before auth)
    const {
      data: currentLogo,
      upload: setLogo,
      reset: removeLogo,
      isUpdating,
      isFetched,
      isLoading,
    } = useUploadLogoFile({ enabled: isOpened });

    useEffect(() => {
      if (isOpened && isAccountFetched) {
        track('Organization', 'View');
      }
    }, [isAccountFetched, isOpened, track]);

    const onLogoStepSubmit = () => {
      onSubmit();
      track('Organization', 'Click', {
        Cta: 'continue',
        organizationId: accountData?.organizationId,
      });
    };

    const onRemoveLogo = async () => {
      await removeLogo();
      track('Organization', 'Click', {
        Cta: 'delete',
        organizationId: accountData?.organizationId,
      });
    };

    const onLogoStepSkip = () => {
      onSubmit();
      track('Organization', 'Click', {
        Cta: 'skip',
        organizationId: accountData?.organizationId,
      });
    };

    useEffect(
      () => setAnalyticsProperties({ isPopulated: !isEmpty(currentLogo) }),
      [currentLogo, setAnalyticsProperties]
    );

    return (
      <CollapsibleCardFormWidget
        title={formatMessage('vex.activities.vendorOnboarding.logoUpload.title')}
        subtitle={formatMessage('vex.activities.vendorOnboarding.logoUpload.subTitle')}
        isOptional
        isOpened={isOpened}
        isDisabled={isDisabled}
        isFilled={isFilled && !!currentLogo?.logoUrl}
        onOpen={onOpen}
        ref={ref}
      >
        {(isFetched || isLoading) && (
          <LogoUploadWidget
            isSaving={isUpdating}
            defaultValues={currentLogo}
            onSubmit={onLogoStepSubmit}
            setLogo={setLogo}
            removeLogo={onRemoveLogo}
            onSkip={onLogoStepSkip}
          />
        )}
      </CollapsibleCardFormWidget>
    );
  })
);

LogoUploadCardActivity.displayName = 'LogoUploadCardActivity';
