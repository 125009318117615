import { useMemo, useState } from 'react';

type IsBooleanResult = [
  boolean,
  {
    on: VoidFunction;
    off: VoidFunction;
    toggle: VoidFunction;
  }
];

export const useBoolean = (defaultValue = false): IsBooleanResult => {
  const [state, setState] = useState(defaultValue);

  const mutationFunctions = useMemo(() => {
    const on = () => setState(true);
    const off = () => setState(false);
    const toggle = () => setState(!state);
    return {
      on,
      off,
      toggle,
    };
  }, [state, setState]);
  return [state, mutationFunctions];
};
