import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { BaseSchema, mixed, number, object, SchemaOf, string } from 'yup';

import { VendorBusinessDetailsFormFields } from '../../../types';
import { addressSchema, COMPANY_MAX_LEN, COMPANY_MIN_LEN, TAX_ID_NUMBER_REGEX } from '../consts';

export const useVendorBusinessDetailsSchema = ({
  isTaxIdNumberFocused,
}: {
  isTaxIdNumberFocused: boolean;
}): SchemaOf<VendorBusinessDetailsFormFields> => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    businessName: string()
      .trim()
      .required(formatMessage('vex.widgets.vendorOnboarding.businessDetails.businessName.validation.empty'))
      .min(COMPANY_MIN_LEN, formatMessage('vex.widgets.vendorOnboarding.businessDetails.businessName.validation.min'))
      .max(COMPANY_MAX_LEN, formatMessage('vex.widgets.vendorOnboarding.businessDetails.businessName.validation.max')),
    legalBusinessName: string()
      .trim()
      .required(formatMessage('vex.widgets.vendorOnboarding.businessDetails.legalBusinessName.validation.empty'))
      .min(
        COMPANY_MIN_LEN,
        formatMessage('vex.widgets.vendorOnboarding.businessDetails.legalBusinessName.validation.min')
      )
      .max(
        COMPANY_MAX_LEN,
        formatMessage('vex.widgets.vendorOnboarding.businessDetails.legalBusinessName.validation.max')
      ),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    businessAddress: addressSchema.required(
      formatMessage('vex.widgets.businessDetailsForm.address.validation')
    ) as BaseSchema,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    legalBusinessAddress: addressSchema.required(
      formatMessage('vex.widgets.businessDetailsForm.legalAddress.validation')
    ) as BaseSchema,
    taxIdType: mixed<TaxIdTypeEnum>()
      .oneOf(Object.values(TaxIdTypeEnum))
      .required(formatMessage('vex.widgets.vendorOnboarding.businessDetails.taxIdType.validation')),
    taxIdNumber: string()
      .when('taxIdType', (taxIdType: TaxIdTypeEnum) =>
        !isTaxIdNumberFocused
          ? string()
              .required(
                formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType || TaxIdTypeEnum.Ein}.required`)
              )
              .trim()
          : string()
              .required(
                formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType || TaxIdTypeEnum.Ein}.required`)
              )
              .matches(
                TAX_ID_NUMBER_REGEX,
                formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType || TaxIdTypeEnum.Ein}.invalid`)
              )
      )
      .trim(),
    businessType: mixed<OrganizationBusinessType>()
      .nullable()
      .oneOf(Object.values(OrganizationBusinessType))
      .required(formatMessage('vex.widgets.vendorOnboarding.businessDetails.businessType.validation')),
    industry: object({
      name: string().required().default(''),
      naicsCode: number().nullable(),
    })
      .nullable()
      .required(formatMessage('vex.widgets.vendorOnboarding.businessDetails.industry.validation')),
  });
};
