import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';
export const NewEmail: React.VFC = () => (
  <LazyAnimation
    getAnimationData={() => import('./assets/new-email.lottie')}
    id="new-email"
    width="100%"
    height="100%"
    loop={false}
  />
);
