import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatform, AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformCategoriesProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatformsCategoriesById
> & {
  accountingPlatformId?: AccountingPlatform['id'];
  noInvalidations?: boolean;
};

export const useAccountingPlatformCategories = (props?: UseAccountingPlatformCategoriesProps) => {
  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatformsCategoriesById>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'getAccountingPlatformsCategoriesById', props?.accountingPlatformId],
    queryFn: () =>
      AccountingPlatformsApiClient.getAccountingPlatformsCategoriesById(props?.accountingPlatformId as string),
    meta: { noInvalidations: props?.noInvalidations },
    enabled: props?.enabled && !!props?.accountingPlatformId,
  });

  return {
    ...query,
  };
};
