import { LazyAnimation } from '@melio/platform-utils';

export const VendorAdd: React.VFC = () => (
  <LazyAnimation
    id="vendor-add"
    getAnimationData={() => import('./assets/vendor-add.lottie')}
    width="100%"
    height="100%"
  />
);
