import { Avatar, Group, Text } from '@melio/penny';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { LongTextWithTooltipWidget, useIsMobile } from '@melio/vex-widgets';

import { PaymentTitleRow } from './PaymentTitleRow.component';

type Props = {
  orgName: string;
  amount: number;
  invoiceNumber?: string | null;
  note?: string | null;
};

export const PaymentHeader = ({ amount, orgName, invoiceNumber, note }: Props) => {
  const { formatCurrency } = useMelioIntl();
  const isMobile = useIsMobile();

  return (
    <Group variant="vertical" spacing={isMobile ? 's' : 'm'}>
      <Avatar name={orgName} size="medium" />
      <PaymentTitleRow orgName={orgName} />
      <Text data-component="Currency" textStyle={isMobile ? 'display2Semi' : 'display1Semi'}>
        {formatCurrency(amount)}
      </Text>
      {(!!invoiceNumber || !!note) && (
        <Group variant="vertical" spacing="none">
          {!!invoiceNumber && (
            <LongTextWithTooltipWidget textStyle="body4Semi" color="global.neutral.900" tooltipContent={invoiceNumber}>
              <FormattedMessage
                id="activities.paymentUpgrade.screens.paymentUpgradeConfirm.header.invoiceNumber"
                values={{
                  invoiceNumber: (
                    <Text textStyle={isMobile ? 'body3' : 'body4'} color="global.neutral.900">
                      {invoiceNumber}
                    </Text>
                  ),
                }}
              />
            </LongTextWithTooltipWidget>
          )}
          {!!note && (
            <LongTextWithTooltipWidget textStyle="body4Semi" color="global.neutral.900" tooltipContent={note}>
              <FormattedMessage
                id="activities.paymentUpgrade.screens.paymentUpgradeConfirm.header.note"
                values={{
                  note: (
                    <Text textStyle={isMobile ? 'body3' : 'body4'} color="global.neutral.900">
                      {note}
                    </Text>
                  ),
                }}
              />
            </LongTextWithTooltipWidget>
          )}
        </Group>
      )}
    </Group>
  );
};

PaymentHeader.displayName = 'PaymentHeader';
