import { ThemeOptions } from '@melio/penny';

import { MelioLogo, MelioLogoDark } from '../melio/logos';

export const ftiTheme: ThemeOptions = {
  logos: {
    dark: MelioLogoDark,
    light: MelioLogo,
  },
  colors: {
    brand: {
      darkest: '#000F85',
      dark: '#082E9E',
      main: '#285CE5',
      light: '#759FFF',
      lighter: '#98BDFF',
      lightest: '#B7D1FF',
    },
    neutral: {
      light: '#D9DEE8',
      dark: '#8B95A9',
    },
  },
};
