import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CapOneIcon = (props: IconProps) => (
  <Icon width="147px" height="30px" viewBox="0 0 1976 403" fill="none" {...props}>
    <path
      d="M761.872 291.452C714.061 325.289 657.429 360.846 596.518 398.169L594.092 399.58C593.7 399.861 593.436 400.286 593.358 400.762C593.279 401.238 593.393 401.725 593.673 402.117C593.954 402.509 594.379 402.773 594.855 402.852C595.33 402.931 595.818 402.817 596.209 402.536L598.238 401.477C649.887 374.522 709.209 342.494 768.929 309.628L769.899 309.098C766.447 303.602 763.747 297.667 761.872 291.452ZM1075.6 37.3877C1000.27 -45.5946 515.451 29.0497 309.211 74.0922L304.403 75.151C303.937 75.2371 303.524 75.5025 303.252 75.8903C302.98 76.278 302.87 76.757 302.948 77.2245C302.985 77.4635 303.07 77.6927 303.197 77.8984C303.324 78.1041 303.492 78.2822 303.689 78.4221C303.886 78.5619 304.11 78.6607 304.346 78.7127C304.582 78.7646 304.827 78.7686 305.065 78.7244L309.872 77.9303C480.651 47.5344 835.882 5.35943 912.318 81.1508C935.65 104.268 929.961 134.09 903.056 169.383C918.034 179.241 928.647 194.484 932.695 211.955C1038.24 141.369 1109.16 74.4452 1075.6 37.3877Z"
      fill="#CC2427"
    />
    <path
      d="M792.349 269.615C793.32 298.863 811.844 322.818 835.97 322.818C882.723 322.818 902.615 265.468 901.247 226.998C900.277 197.705 881.267 173.795 857.141 173.795C816.961 173.795 790.982 230.969 792.349 269.615ZM767.65 268.026C765.93 218.131 805.008 167.089 861.817 167.089C899.792 167.089 924.668 192.411 926.035 231.189C927.844 283.82 891.324 332.127 831.869 332.127C793.893 332.127 769.017 306.893 767.65 268.026Z"
      fill="#013D5B"
    />
    <path
      d="M229.819 254.835C227.255 255.793 224.614 256.531 221.924 257.041C218.66 257.791 212.75 258.85 204.282 260.218C199.927 260.614 195.705 261.921 191.888 264.056C190.867 264.753 189.994 265.647 189.32 266.684C188.646 267.722 188.185 268.883 187.963 270.1C187.643 271.253 187.678 272.476 188.064 273.609C188.449 274.743 189.167 275.733 190.124 276.452C193.639 278.33 197.62 279.157 201.591 278.835C205.588 278.819 209.559 278.194 213.368 276.982C216.882 275.903 220.16 274.167 223.027 271.864C224.87 270.208 226.291 268.136 227.173 265.82C228.304 262.223 229.188 258.553 229.819 254.835ZM279.174 223.954C278.796 229.204 278.059 234.422 276.969 239.571L268.589 286.467C268.201 288.372 268.453 290.352 269.307 292.1C270.16 293.847 271.567 295.263 273.308 296.128L273.043 297.981H225.232L224.791 286.07C217.599 290.349 209.872 293.657 201.812 295.907C194.331 297.886 186.613 298.821 178.877 298.687C163.44 298.687 154.618 297.099 148.488 291.761C142.357 286.423 139.269 282.54 139.666 274.952C140.192 269.855 142.201 265.023 145.444 261.056C148.976 257.194 153.333 254.178 158.191 252.233C164.287 249.506 170.752 247.693 177.377 246.85C185.846 245.483 197.622 244.115 213.368 242.792C218.77 242.429 224.083 241.223 229.114 239.218C232.377 237.939 233.524 236.527 234.186 233.836C235.068 229.028 233.524 226.292 227.129 225.013C209.883 221.616 177.598 227.219 160.97 231.366L169.791 206.97C191.023 203.633 212.473 201.878 233.965 201.72C268.368 201.72 279.395 211.513 279.042 224.131L279.174 223.954Z"
      fill="#013D5B"
    />
    <path
      d="M417.667 298.158L434.296 204.455H480.078L463.582 298.158H417.667ZM436.81 182.75C438.177 175.074 450.35 168.81 463.891 168.81C477.431 168.81 487.443 175.074 485.944 182.75C484.444 190.426 472.403 196.735 459.171 196.735C445.94 196.735 435.442 190.471 436.81 182.75Z"
      fill="#013D5B"
    />
    <path
      d="M642.697 255.101C640.624 255.762 637.978 256.556 634.758 257.306C631.538 258.056 625.584 258.983 617.115 260.395C612.786 260.807 608.593 262.13 604.81 264.277C603.787 264.967 602.909 265.852 602.228 266.882C601.547 267.911 601.076 269.065 600.84 270.276C600.579 271.462 600.688 272.699 601.154 273.819C601.619 274.94 602.418 275.89 603.443 276.541C606.979 278.4 610.973 279.211 614.954 278.879C618.95 278.893 622.923 278.283 626.731 277.07C630.184 276.016 633.403 274.309 636.213 272.041C638.03 270.363 639.421 268.276 640.271 265.953C641.349 262.401 642.16 258.774 642.697 255.101ZM692.052 224.219C691.647 229.472 690.88 234.69 689.758 239.836L681.466 286.864C681.065 288.771 681.305 290.758 682.151 292.513C682.997 294.269 684.4 295.696 686.142 296.57L685.877 298.202H638.066L637.669 286.247C630.478 290.529 622.751 293.837 614.69 296.084C607.199 298.087 599.463 299.022 591.71 298.864C576.273 298.864 567.496 297.32 561.365 291.937C555.235 286.555 552.103 282.761 552.544 275.173C552.988 270.063 554.99 265.213 558.278 261.277C561.823 257.41 566.195 254.394 571.069 252.454C577.149 249.75 583.577 247.909 590.167 246.983C598.679 245.704 610.5 244.248 626.246 242.925C631.592 242.56 636.854 241.401 641.859 239.484C645.211 238.204 646.534 236.748 647.019 234.057C647.857 229.337 646.358 226.513 640.007 225.234C622.673 221.793 590.475 227.396 573.847 231.543L582.669 207.146C603.9 203.801 625.35 202.032 646.843 201.853C681.334 201.853 692.317 211.735 692.052 224.308V224.219Z"
      fill="#013D5B"
    />
    <path
      d="M133.183 264.497C120.706 267.296 107.95 268.658 95.1633 268.556C73.1102 268.556 55.9088 257.703 56.835 237.675C57.4966 224.44 73.2425 196.426 113.159 196.426C125.714 196.179 138.074 199.565 148.752 206.176L154.53 173.618C140.139 167.973 124.718 165.432 109.277 166.163C56.9673 166.692 6.6863 190.471 0.467333 238.822C-5.88395 288.011 54.1445 300.584 80.7846 300.231C96.1777 300.231 112.188 299.57 127.14 298.025L133.183 264.497Z"
      fill="#013D5B"
    />
    <path
      d="M505.306 203.926L508.57 184.823L556.47 174.412L551.397 203.926H574.862L570.451 222.807H547.692L533.799 298.069C533.799 298.069 487.708 297.761 487.532 298.069L501.293 223.072H483.915L487.443 204.058L505.306 203.926Z"
      fill="#013D5B"
    />
    <path d="M738.54 298.07L691.92 298.158L715.384 167.442L760.813 168.722L738.54 298.07Z" fill="#013D5B" />
    <path
      d="M357.11 226.425C351.228 226.389 345.501 228.313 340.835 231.895C335.937 235.894 332.776 241.632 332.013 247.909C330.558 256.115 331.44 261.982 334.66 265.556C336.493 267.422 338.706 268.872 341.149 269.808C343.592 270.744 346.207 271.145 348.818 270.982C352.824 271.071 356.8 270.273 360.462 268.644C364.023 266.882 367.064 264.221 369.283 260.924C371.861 257.234 373.579 253.013 374.311 248.571C375.135 245.75 375.273 242.772 374.713 239.887C374.152 237.001 372.911 234.292 371.091 231.984C369.315 230.083 367.14 228.6 364.723 227.639C362.306 226.678 359.706 226.263 357.11 226.425ZM273.926 330.495L295.979 204.94H336.203L333.072 220.955C337.479 215.541 343.271 211.425 349.832 209.043C358.049 205.526 366.874 203.653 375.811 203.529C384.354 202.818 392.93 204.418 400.643 208.161C408.061 211.817 413.848 218.109 416.874 225.807C419.894 233.957 420.581 242.788 418.858 251.306C417.718 258.616 415.129 265.625 411.244 271.92C407.358 278.216 402.254 283.671 396.232 287.967C383.97 296.79 372.9 299.261 357.154 299.349C352.39 299.39 347.638 298.872 342.996 297.805C339.803 297.018 336.742 295.769 333.91 294.099C331.148 292.109 328.618 289.815 326.368 287.261L318.914 330.495H273.926Z"
      fill="#013D5B"
    />
    <path
      d="M1105.06 237.94C1104.8 230.352 1101 225.278 1093.2 225.278C1074.41 225.278 1054.43 262.071 1050.99 276.938C1081.82 276.938 1105.81 258.807 1105.06 237.94ZM1109.08 295.599L1112.69 298.07C1103.52 316.731 1084.86 331.73 1062.94 331.73C1045.03 331.73 1030.35 319.819 1029.55 297.408C1028.1 257.218 1065.72 219.94 1096.55 219.94C1109.78 219.94 1122 225.72 1122.53 239.925C1123.67 271.865 1078.86 281.615 1049.31 282.276C1048.27 286.126 1047.88 290.122 1048.16 294.099C1048.65 307.334 1056.68 318.452 1073.22 318.452C1087.51 318.452 1101.84 306.937 1109.08 295.599Z"
      fill="#013D5B"
    />
    <path
      d="M925.373 236.748C928.549 236.307 933.665 235.733 935.871 235.733C939.267 235.733 942.398 236.395 942.575 239.704C942.575 241.689 939.046 255.938 938.164 259.247L931.107 290.878C928.24 303.76 925.109 317.348 922.286 327.936H940.634L950.955 277.996C982.403 245.218 994.532 234.542 1001.99 234.542C1002.76 234.468 1003.54 234.565 1004.27 234.826C1005 235.087 1005.67 235.507 1006.22 236.054C1006.77 236.602 1007.2 237.264 1007.46 237.994C1007.73 238.723 1007.83 239.503 1007.76 240.277C1007.1 247.975 1005.55 255.571 1003.13 262.909L992.856 299.304C990.563 306.025 989.227 313.034 988.886 320.127C988.79 321.736 989.049 323.345 989.644 324.843C990.24 326.34 991.157 327.688 992.331 328.791C993.505 329.894 994.908 330.725 996.439 331.225C997.971 331.725 999.593 331.883 1001.19 331.686C1014.42 331.686 1024.35 317.701 1032.86 303.981L1030.35 299.569C1026.91 305.084 1018.97 317.216 1012.7 317.216C1010.72 317.216 1008.82 315.892 1008.78 312.804C1009.01 307.739 1009.96 302.733 1011.6 297.937L1023.29 255.321C1025.61 247.727 1027.09 239.901 1027.7 231.983C1027.81 230.385 1027.57 228.782 1027 227.285C1026.42 225.789 1025.53 224.437 1024.38 223.323C1023.23 222.209 1021.85 221.361 1020.33 220.837C1018.82 220.314 1017.21 220.128 1015.61 220.293C1003.57 220.293 987.519 229.116 953.601 268.203H953.16L957.571 249.144C959.909 238.689 962.202 227.836 964.363 220.293C951.641 224.82 938.63 228.49 925.418 231.277L925.373 236.748Z"
      fill="#013D5B"
    />
    <path
      d="M1200.64 327.142L1228.78 182.132C1234.87 180.604 1241.06 179.513 1247.3 178.868C1254.23 177.996 1261.19 177.539 1268.17 177.5C1283.07 177.5 1294.5 180.441 1302.44 186.323C1306.36 189.357 1309.49 193.3 1311.54 197.811C1313.6 202.323 1314.54 207.268 1314.26 212.219C1314.49 220.767 1311.64 229.114 1306.23 235.733C1300.25 242.709 1292.28 247.682 1283.38 249.983C1288.47 251.169 1293.26 253.392 1297.45 256.512C1301.21 259.308 1304.24 262.97 1306.27 267.188C1308.29 271.54 1309.3 276.289 1309.23 281.084C1309.57 287.531 1308.4 293.968 1305.82 299.882C1303.23 305.796 1299.29 311.022 1294.32 315.142C1284.4 323.171 1270.42 327.23 1252.55 327.23L1200.64 327.142ZM1252.51 311.745C1262.11 312.335 1271.6 309.437 1279.24 303.584C1282.46 300.818 1285 297.353 1286.67 293.453C1288.35 289.554 1289.11 285.323 1288.9 281.084C1289.09 277.972 1288.57 274.857 1287.37 271.975C1286.18 269.094 1284.35 266.521 1282.02 264.453C1276.46 260.271 1269.59 258.206 1262.65 258.629H1233.41L1223.05 311.569L1252.51 311.745ZM1267.28 192.632C1260.11 192.48 1252.94 193.191 1245.94 194.749L1236.19 243.851H1260.93C1269.78 244.296 1278.48 241.47 1285.37 235.91C1288.41 233.301 1290.82 230.036 1292.42 226.36C1294.01 222.685 1294.76 218.694 1294.59 214.69C1294.59 199.984 1285.49 192.632 1267.28 192.632Z"
      fill="#013D5B"
    />
    <path
      d="M1363.79 219.587L1350.03 290.173C1349.5 293.085 1349.22 296.036 1349.19 298.996C1349.06 301.203 1349.42 303.411 1350.24 305.463C1351.07 307.514 1352.33 309.358 1353.95 310.863C1357.66 313.923 1362.38 315.495 1367.18 315.275C1371.67 315.227 1376.12 314.559 1380.42 313.289C1384.85 312 1389.11 310.193 1393.12 307.907L1410.23 219.675H1429.51L1408.78 325.951L1392.9 327.627V320.569C1383.02 326.7 1371.7 330.138 1360.08 330.539C1351.92 330.988 1343.9 328.288 1337.68 322.995C1334.86 320.338 1332.67 317.097 1331.24 313.499C1329.82 309.901 1329.2 306.035 1329.43 302.172C1329.39 298.687 1329.74 295.209 1330.49 291.805L1344.43 219.807L1363.79 219.587Z"
      fill="#013D5B"
    />
    <path
      d="M1436.17 305.745C1441.29 308.728 1446.75 311.069 1452.44 312.715C1457.86 314.375 1463.49 315.237 1469.16 315.274C1475.76 315.624 1482.3 313.846 1487.82 310.201C1490 308.6 1491.76 306.488 1492.93 304.049C1494.11 301.611 1494.67 298.921 1494.56 296.216C1494.65 293.053 1493.69 289.95 1491.83 287.393C1489.38 284.482 1486.24 282.237 1482.7 280.863L1468.32 274.731C1462.35 272.475 1456.98 268.849 1452.66 264.143C1449.4 260.075 1447.68 254.979 1447.81 249.762C1447.71 245.141 1448.69 240.562 1450.68 236.388C1452.66 232.214 1455.59 228.56 1459.23 225.718C1467.54 219.214 1477.89 215.883 1488.43 216.322C1494.18 216.319 1499.9 217.061 1505.46 218.527C1510.99 219.934 1516.31 222.101 1521.25 224.968L1518.47 239.35C1509.66 234.414 1499.8 231.66 1489.71 231.321C1483.84 231.022 1478.03 232.565 1473.08 235.733C1471.16 237.061 1469.59 238.851 1468.54 240.94C1467.48 243.028 1466.97 245.349 1467.04 247.688C1466.93 250.382 1467.88 253.012 1469.69 255.011C1472.43 257.54 1475.64 259.517 1479.13 260.835L1493.5 266.967C1499.54 269.253 1504.85 273.106 1508.9 278.128C1512.33 282.894 1514.09 288.667 1513.88 294.539C1514.04 299.511 1513.03 304.451 1510.95 308.969C1508.87 313.486 1505.77 317.457 1501.89 320.568C1492.88 327.443 1481.75 330.926 1470.44 330.406C1464.12 330.419 1457.83 329.678 1451.69 328.2C1446.26 326.911 1441.03 324.862 1436.17 322.112V305.745Z"
      fill="#013D5B"
    />
    <path
      d="M1530.11 327.142L1550.62 221.484L1570.29 219.19L1549.39 327.142H1530.11ZM1565.66 197.573C1562.65 197.729 1559.68 196.806 1557.28 194.97C1556.27 194.049 1555.47 192.915 1554.94 191.649C1554.42 190.383 1554.18 189.016 1554.24 187.647C1554.14 185.692 1554.43 183.737 1555.1 181.896C1555.77 180.055 1556.79 178.366 1558.12 176.927C1559.55 175.591 1561.24 174.559 1563.08 173.892C1564.93 173.225 1566.88 172.937 1568.84 173.044C1571.86 172.881 1574.84 173.822 1577.22 175.691C1578.24 176.6 1579.04 177.725 1579.57 178.985C1580.09 180.245 1580.33 181.607 1580.26 182.971C1580.43 184.93 1580.17 186.903 1579.49 188.751C1578.82 190.599 1577.75 192.277 1576.36 193.668C1574.97 195.059 1573.29 196.129 1571.44 196.804C1569.59 197.478 1567.62 197.741 1565.66 197.573Z"
      fill="#013D5B"
    />
    <path
      d="M1644.83 327.142L1658.59 256.556C1659.1 253.641 1659.38 250.691 1659.43 247.733C1659.57 245.504 1659.21 243.273 1658.37 241.203C1657.53 239.134 1656.23 237.28 1654.58 235.778C1650.48 232.779 1645.45 231.326 1640.38 231.675C1635.9 231.722 1631.44 232.39 1627.15 233.66C1622.8 234.939 1618.64 236.762 1614.75 239.086L1597.6 327.318H1578.14L1598.92 221.043L1614.75 219.102V226.293C1624.62 220.165 1635.92 216.742 1647.52 216.367C1655.96 215.82 1664.28 218.501 1670.81 223.866C1673.62 226.523 1675.82 229.764 1677.25 233.362C1678.67 236.96 1679.29 240.826 1679.06 244.689C1679.08 248.173 1678.73 251.649 1678 255.056L1664.06 327.054L1644.83 327.142Z"
      fill="#013D5B"
    />
    <path
      d="M1772.21 323.083C1767.32 325.511 1762.13 327.291 1756.77 328.377C1751.22 329.636 1745.54 330.287 1739.84 330.318C1725.72 330.318 1714.92 326.524 1707.33 318.98C1699.75 311.436 1696.04 300.584 1696.04 286.467C1695.82 273.892 1698.41 261.427 1703.63 249.983C1708.06 240.072 1715.05 231.517 1723.87 225.189C1732 219.423 1741.73 216.339 1751.7 216.366C1761.63 215.782 1771.39 219.126 1778.87 225.675C1782.23 229.002 1784.84 233.003 1786.53 237.414C1788.23 241.824 1788.97 246.544 1788.71 251.262C1788.46 260.102 1786.98 268.864 1784.3 277.29H1715.71C1715.71 278.967 1715.4 280.731 1715.27 282.496C1715.14 284.261 1715.27 285.981 1715.27 287.658C1715.27 305.863 1724.58 314.965 1743.19 314.965C1748.91 314.944 1754.61 314.353 1760.21 313.201C1765.41 312.135 1770.48 310.539 1775.34 308.436L1772.21 323.083ZM1750.64 230.439C1746.91 230.428 1743.21 231.209 1739.8 232.729C1736.38 234.25 1733.33 236.477 1730.84 239.262C1724.7 246.189 1720.43 254.572 1718.45 263.614H1769.48C1770.29 259.652 1770.71 255.617 1770.71 251.571C1771.14 245.887 1769.32 240.261 1765.64 235.91C1763.64 234.041 1761.28 232.606 1758.7 231.695C1756.11 230.784 1753.37 230.416 1750.64 230.616V230.439Z"
      fill="#013D5B"
    />
    <path
      d="M1797.84 305.745C1802.94 308.736 1808.39 311.077 1814.07 312.716C1819.5 314.373 1825.15 315.235 1830.83 315.274C1837.42 315.637 1843.95 313.858 1849.44 310.201C1851.63 308.608 1853.4 306.498 1854.57 304.058C1855.75 301.617 1856.31 298.923 1856.19 296.216C1856.3 293.051 1855.34 289.941 1853.46 287.393C1851.02 284.493 1847.89 282.249 1844.37 280.864L1829.95 274.732C1823.98 272.451 1818.63 268.828 1814.29 264.144C1811.02 260.075 1809.3 254.979 1809.44 249.762C1809.34 245.142 1810.32 240.562 1812.3 236.388C1814.29 232.214 1817.22 228.561 1820.86 225.719C1829.19 219.222 1839.55 215.892 1850.1 216.322C1855.83 216.323 1861.54 217.064 1867.08 218.528C1872.62 219.934 1877.93 222.102 1882.87 224.969L1880.14 239.351C1871.32 234.407 1861.44 231.654 1851.34 231.322C1845.47 231.032 1839.66 232.574 1834.71 235.733C1832.79 237.063 1831.23 238.855 1830.18 240.944C1829.14 243.034 1828.63 245.353 1828.71 247.689C1828.65 249.024 1828.85 250.359 1829.3 251.616C1829.76 252.873 1830.45 254.027 1831.36 255.012C1834.09 257.531 1837.28 259.507 1840.75 260.835L1855.13 266.967C1861.17 269.266 1866.49 273.116 1870.57 278.129C1873.99 282.9 1875.73 288.673 1875.51 294.54C1875.66 299.512 1874.66 304.452 1872.58 308.969C1870.5 313.487 1867.39 317.458 1863.51 320.568C1854.53 327.444 1843.41 330.928 1832.11 330.406C1825.79 330.423 1819.5 329.682 1813.36 328.201C1807.92 326.92 1802.7 324.87 1797.84 322.112V305.745Z"
      fill="#013D5B"
    />
    <path
      d="M1890.95 305.746C1896.05 308.728 1901.5 311.069 1907.18 312.716C1912.61 314.373 1918.26 315.235 1923.94 315.275C1930.53 315.638 1937.06 313.858 1942.55 310.201C1944.75 308.611 1946.52 306.502 1947.7 304.062C1948.89 301.622 1949.45 298.927 1949.34 296.216C1949.44 293.046 1948.46 289.936 1946.56 287.393C1944.13 284.493 1941 282.249 1937.48 280.864L1923.05 274.732C1917.1 272.464 1911.75 268.839 1907.44 264.144C1904.21 260.14 1902.48 255.13 1902.55 249.983C1902.45 245.362 1903.43 240.783 1905.41 236.609C1907.4 232.435 1910.33 228.781 1913.97 225.94C1922.3 219.443 1932.66 216.113 1943.21 216.543C1948.94 216.543 1954.65 217.285 1960.19 218.749C1965.73 220.148 1971.05 222.316 1975.98 225.19L1973.25 239.571C1964.43 234.628 1954.55 231.875 1944.45 231.542C1938.58 231.253 1932.77 232.794 1927.82 235.954C1925.9 237.284 1924.34 239.076 1923.29 241.165C1922.24 243.254 1921.74 245.573 1921.82 247.909C1921.75 249.244 1921.96 250.579 1922.41 251.836C1922.86 253.093 1923.56 254.248 1924.47 255.233C1927.2 257.752 1930.39 259.728 1933.86 261.056L1948.24 267.188C1954.26 269.481 1959.56 273.315 1963.63 278.305C1967.05 283.076 1968.79 288.85 1968.57 294.717C1968.71 299.655 1967.7 304.558 1965.62 309.043C1963.55 313.527 1960.47 317.471 1956.62 320.569C1947.64 327.444 1936.52 330.928 1925.22 330.406C1918.9 330.423 1912.61 329.682 1906.47 328.201C1901.03 326.92 1895.8 324.87 1890.95 322.113V305.746Z"
      fill="#013D5B"
    />
  </Icon>
);
