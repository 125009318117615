import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';
export const Success: React.VFC = () => (
  <LazyAnimation
    getAnimationData={() => import('./assets/success.lottie')}
    id="success"
    width="100%"
    height="100%"
    loop={false}
  />
);
