import { ExternalLayout, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { CardHolderDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import {
  CardHolderDetailsForm,
  ExternalLayoutContentWidget,
  PoweredByMelioFooterWidget,
  PushToDebitHeaderWidget,
  PushToDebitHeaderWidgetProps,
} from '@melio/vex-widgets';

type CardHolderDetailsScreenProps = Pick<PushToDebitHeaderWidgetProps, 'accountName' | 'paymentAmount'> & {
  isSaving?: boolean;
  defaultValues?: Partial<CardHolderDetails>;
  onBack: VoidFunction;
  onDone: (data: CardHolderDetails) => void;
};

// TODO: change to standardized the gaps and margins - PLA-749
export const CardHolderDetailsScreen = forwardRef<CardHolderDetailsScreenProps, 'div'>(
  ({ accountName, paymentAmount, isSaving, defaultValues, onBack, onDone, children, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CardHolderDetails>();

    useAnalyticsView('PushToDebitAddCardHolderDetails');

    const content = (
      <ExternalLayoutContentWidget
        header={{
          backButtonProps: { isDisabled: cancelButtonProps?.isDisabled, onClick: onBack },
          title: <PushToDebitHeaderWidget accountName={accountName} paymentAmount={paymentAmount} showAvatar={false} />,
        }}
        form={{
          title: formatMessage('activities.addBankDeliveryMethodByPayee.screens.addBankDeliveryMethod.title'),
          fields: (
            <CardHolderDetailsForm
              onSubmit={onDone}
              defaultValues={defaultValues}
              isSaving={isSaving}
              onSubmissionStateChange={onSubmissionStateChange}
              size="small"
            />
          ),
        }}
        continueSection={{
          buttonProps: {
            ...submitButtonProps,
            label: formatMessage('activities.pushToDebit.screens.cardHolderDetails.continue'),
          },
        }}
      />
    );

    const footer = <PoweredByMelioFooterWidget />;
    return (
      <ExternalLayout
        data-component="PushToDebitActivity.CardHolderDetailsScreen"
        data-testid="push-to-debit-activity-card-holder-details-screen"
        {...props}
        ref={ref}
        content={content}
        footer={footer}
      />
    );
  }
);

CardHolderDetailsScreen.displayName = 'PushToDebitActivity.CardHolderDetailsScreen';
