import { OrganizationBusinessType } from '@melio/platform-api';

const einOnlyBusinessTypes: OrganizationBusinessType[] = [
  OrganizationBusinessType.Partnership,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.NonProfit,
  OrganizationBusinessType.NonGovernmentalOrganization,
  OrganizationBusinessType.MunicipalCorporation,
];

export const isEinOnlyBusinessType = (selectedBusinessType: OrganizationBusinessType | null) =>
  selectedBusinessType ? einOnlyBusinessTypes.includes(selectedBusinessType) : false;
