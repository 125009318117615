import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EmptyVendorsIcon = (props: IconProps) => (
  <Icon width="100%" height="82px" viewBox="0 0 121 82" fill="none" {...props}>
    <path
      d="M36.3613 53.2062C41.8841 53.2062 46.3613 48.7289 46.3613 43.2062C46.3613 37.6834 41.8841 33.2062 36.3613 33.2062C30.8386 33.2062 26.3613 37.6834 26.3613 43.2062C26.3613 48.7289 30.8386 53.2062 36.3613 53.2062Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.4519 67.4918C50.063 60.6491 43.6076 56.0654 36.3599 56.0654C29.1119 56.0654 22.6565 60.6491 20.2676 67.4918"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.1585 58.5039C59.9378 55.1018 60.944 51.2313 60.944 47.1258C60.944 33.5464 49.936 22.5384 36.3569 22.5384C22.7775 22.5384 11.7695 33.5464 11.7695 47.1258C11.7695 51.175 12.7483 54.9956 14.4825 58.3639"
      stroke="#228800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M73.0996 43.2551H107.675" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M73.0996 54.6861H100.177" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M73.0996 31.8249H107.675" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M119.289 1H1V81H119.289V1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
