import { Avatar, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { FC } from 'react';

export type PayorAccountNameProps = {
  description: string;
};

const PayorAccountName: FC<PayorAccountNameProps> = ({ description }) => (
  <Text textStyle="body3Semi">{description}</Text>
);

export type PayorPendingPaymentDetailsWidgetProps = {
  accountName: string;
  description: string;
  paymentAmount?: number;
  paymentInvoiceNumberLabel?: string;
  paymentInvoiceNumber?: string | null;
  paymentNoteLabel?: string;
  paymentNote?: string;
  feeLabel?: string;
  fee?: string;
  showAvatar?: boolean;
};

export const PayorPendingPaymentDetailsWidget = forwardRef<PayorPendingPaymentDetailsWidgetProps, 'div'>(
  (
    {
      accountName,
      description,
      paymentAmount,
      paymentInvoiceNumberLabel,
      paymentInvoiceNumber,
      paymentNoteLabel,
      paymentNote,
      feeLabel,
      fee,
      showAvatar = true,
      ...props
    },
    ref
  ) => {
    const { formatCurrency } = useMelioIntl();
    const hasExtraInfo =
      (feeLabel && fee) || (paymentInvoiceNumber && paymentInvoiceNumberLabel) || (paymentNote && paymentNoteLabel);
    return (
      <Group data-component="PayorPendingPaymentDetailsWidget" variant="vertical" spacing="m" {...props} ref={ref}>
        <Group variant="vertical">
          {showAvatar && <Avatar name={accountName} size="medium" />}
          <PayorAccountName description={description} />
        </Group>
        {paymentAmount && (
          <Text data-component="Currency" textStyle="display1Semi">
            {formatCurrency(paymentAmount)}
          </Text>
        )}
        {hasExtraInfo && (
          <Group variant="vertical" spacing="none">
            {feeLabel && fee && (
              <Group spacing="xxs">
                <Text textStyle="body4Semi" color="global.neutral.900">
                  {feeLabel}
                </Text>
                <Text textStyle="body4" color="global.neutral.900">
                  {fee}
                </Text>
              </Group>
            )}
            {paymentInvoiceNumber && paymentInvoiceNumberLabel && (
              <Group spacing="xxs">
                <Text data-testid="invoice-label" textStyle="body4Semi" color="global.neutral.900">
                  {paymentInvoiceNumberLabel}
                </Text>
                <Text data-testid="invoice-number" textStyle="body4" color="global.neutral.900">
                  {paymentInvoiceNumber}
                </Text>
              </Group>
            )}
            {paymentNote && paymentNoteLabel && (
              <Group spacing="xxs">
                <Text data-testid="note-label" textStyle="body4Semi" color="global.neutral.900">
                  {paymentNoteLabel}
                </Text>
                <Text data-private data-testid="note-text" textStyle="body4" color="global.neutral.900">
                  {paymentNote}
                </Text>
              </Group>
            )}
          </Group>
        )}
      </Group>
    );
  }
);

PayorPendingPaymentDetailsWidget.displayName = 'PayorPendingPaymentDetailsWidget';
