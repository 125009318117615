import { ExternalLayout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { CardParams, useFeeCatalog, usePayment, usePaymentFees } from '@melio/platform-api';
import { useState } from 'react';

import {
  ActionBlockedPaymentCanceledScreen,
  CardAddedSuccessfullyScreen,
  CardHolderDetailsScreen,
  CardValidationModalScreen,
  TabapayCreateCardDetailsScreen,
} from './screens';
import { IneligibleScreen } from './shared/IneligibleScreen';
import { usePushToDebitActivityCallbacks } from './shared/usePushToDebitActivityCallbacks';
import { getPushToDebitFixedFee, getPushToDebitPercentFee } from './shared/utils';
import { PushToDebitActivityProps } from './types';
import { usePushToDebitStep } from './usePushToDebitStep';

// TODO: https://linear.app/meliopayments/issue/PLA-1224 add invoice number by sending payment intent id or by using "expand=billInfo" when backend ready
const BaseTabapayPushToDebitActivity: React.VFC<PushToDebitActivityProps> = ({
  paymentId,
  accountName,
  onError,
  onDone,
}) => {
  const {
    data: payment,
    error: paymentError,
    update: updatePayment,
    isMutating: isUpdatingPayment,
  } = usePayment({ id: paymentId });

  const { data: paymentFees } = usePaymentFees({
    paymentId,
    params: { deliveryMethodType: 'card' },
  });

  const { data: feeCatalogEntries } = useFeeCatalog();

  const { currentStep, goToStep, goToPreviousStep } = usePushToDebitStep();

  const [cardDetails, setCardDetails] = useState<CardParams>();

  const onCreateVendorDeliveryMethodDone = (id: string) => {
    onDone(id);
    goToStep('CARD_ADDED_SUCCESSFULLY');
  };

  const {
    showVerificationErrorDialog,
    createDeliveryMethod,
    isCardValidationModalOpen,
    onCardValidationModalDone,
    isCreatingDeliveryMethod,
  } = usePushToDebitActivityCallbacks({
    vaulting: 'Tabapay',
    onError,
    cardDetails,
    payment,
    updatePayment,
    onCreateVendorDeliveryMethodDone,
  });

  if (paymentError?.code === '404') {
    return <ActionBlockedPaymentCanceledScreen accountName={accountName} />;
  }

  if (!payment) {
    return <ExternalLayout isLoading />;
  }

  if (!payment.paymentActions?.pushToDebit?.eligible) {
    return (
      <IneligibleScreen
        payment={payment}
        accountName={accountName}
        existingPaymentDeliveryMethod={payment.deliveryMethod}
      />
    );
  }

  if (!paymentFees || !feeCatalogEntries) {
    return <ExternalLayout isLoading />;
  }

  switch (currentStep) {
    case 'ADD_CARD_DETAILS':
    default:
      return (
        <>
          <TabapayCreateCardDetailsScreen
            accountName={accountName}
            paymentAmount={payment.amount}
            calculatedFee={getPushToDebitFixedFee(paymentFees)}
            feePercentValue={getPushToDebitPercentFee(feeCatalogEntries)}
            paymentNote={payment.note}
            onDone={(data) => {
              setCardDetails(data);
              goToStep('ADD_CARD_HOLDER_DETAILS');
            }}
            onError={showVerificationErrorDialog}
          />
          <CardValidationModalScreen isOpen={isCardValidationModalOpen} onDone={onCardValidationModalDone} />
        </>
      );

    case 'ADD_CARD_HOLDER_DETAILS':
      return (
        <CardHolderDetailsScreen
          onBack={goToPreviousStep}
          accountName={accountName}
          paymentAmount={payment.amount}
          onDone={createDeliveryMethod}
          isSaving={isCreatingDeliveryMethod || isUpdatingPayment}
        />
      );

    case 'CARD_ADDED_SUCCESSFULLY':
      return <CardAddedSuccessfullyScreen accountName={accountName} />;
  }
};

export const TabapayPushToDebitActivity = withAnalyticsContext(BaseTabapayPushToDebitActivity);
