import { useState } from 'react';

import { PushToDebitStep } from './types';

export const usePushToDebitStep = () => {
  const [currentStep, goToStep] = useState<PushToDebitStep>('ADD_CARD_DETAILS');

  const goToPreviousStep = (): void => {
    if (currentStep === 'ADD_CARD_HOLDER_DETAILS') {
      goToStep('ADD_CARD_DETAILS');
    }
  };

  return {
    currentStep,
    goToPreviousStep,
    goToStep,
  };
};
