import { FailureStepItem, VendorType } from '../types';

const getFailureGeustVendorSteps = ({
  paymentCreatedAt,
  payorEmail,
}: {
  paymentCreatedAt: Date;
  payorEmail: string;
}): FailureStepItem => [
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
      date: paymentCreatedAt,
    },
  },
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.description',
      payorEmail,
    },
  },
];

const getFailureGetPaidVendorSteps = ({
  paymentCreatedAt,
  howContactToSupportArticleUrl,
}: {
  paymentCreatedAt: Date;
  howContactToSupportArticleUrl: string;
}): FailureStepItem => [
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
      date: paymentCreatedAt,
    },
  },
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.getPaid.description',
      link: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.getPaid.contactSupportGuide.link',
        href: howContactToSupportArticleUrl,
      },
    },
  },
];

const getFailureErpVendorSteps = ({
  paymentCreatedAt,
  vendorsContactEmail,
}: {
  paymentCreatedAt: Date;
  vendorsContactEmail: string;
}): FailureStepItem => [
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
      date: paymentCreatedAt,
    },
  },
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.erp.description',
      link: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.erp.contactSupport.link',
        href: `mailto:${vendorsContactEmail}`,
      },
    },
  },
];
export const getFailureSteps = ({
  paymentCreatedAt,
  payorEmail,
  howContactToSupportArticleUrl,
  vendorsContactEmail,
  vendorType,
}: {
  paymentCreatedAt: Date;
  payorEmail: string;
  howContactToSupportArticleUrl: string;
  vendorsContactEmail: string;
  vendorType: VendorType;
}): FailureStepItem => {
  switch (vendorType) {
    case VendorType.GetPaid:
      return getFailureGetPaidVendorSteps({ paymentCreatedAt, howContactToSupportArticleUrl });
    case VendorType.Erp:
      return getFailureErpVendorSteps({ vendorsContactEmail, paymentCreatedAt });
    case VendorType.Guest:
      return getFailureGeustVendorSteps({ paymentCreatedAt, payorEmail });
  }
};
