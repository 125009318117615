import { Box } from '@chakra-ui/react';
import { Group, StatusIconSolid, StatusIconSolidProps, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import {
  PayorPendingPaymentBannerProps,
  PayorPendingPaymentBannerVariant,
} from './PayorPendingPaymentBanner.widget.types';

const iconMapping: Record<
  PayorPendingPaymentBannerVariant,
  Extract<StatusIconSolidProps['variant'], 'processing' | 'issues' | 'success' | 'alert' | 'decline' | 'informative'>
> = {
  processing: 'processing',
  issues: 'issues',
  success: 'success',
  warning: 'alert',
  critical: 'decline',
  informative: 'informative',
};

export const PayorPendingPaymentBanner = forwardRef<PayorPendingPaymentBannerProps, 'div'>((props, ref) => {
  const { variant, title, description, ...rest } = props;

  return (
    <Box data-component="PayorPendingPaymentBanner" {...rest} ref={ref}>
      <Group variant="vertical" spacing="s">
        <StatusIconSolid variant={iconMapping[variant]} size="large" />
        {title && <Text textStyle="body2Semi">{title}</Text>}
        {description && <Text textStyle="body2">{description}</Text>}
      </Group>
    </Box>
  );
});

PayorPendingPaymentBanner.displayName = 'PayorPendingPaymentBanner';
