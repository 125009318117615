import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { object, string } from 'yup';

import { OptionsField } from './types';

export const allBusinessTypes = [
  OrganizationBusinessType.SoleProprietorship,
  OrganizationBusinessType.Partnership,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.NonProfit,
  OrganizationBusinessType.NonGovernmentalOrganization,
  OrganizationBusinessType.MunicipalCorporation,
  OrganizationBusinessType.TrustOrEstate,
];

export const BIZ_OPP_ADDRESS_REGEX =
  /^((unit|#)?\s?(num|number|#)?\s\d+)|(((p[\s\\.]?[o\s][\\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/;
export const TAX_ID_NUMBER_REGEX = /^[0-9]{9}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{7}$/;
export const taxTypeOptions: OptionsField<TaxIdTypeEnum> = [
  {
    label: 'vex.widgets.forms.taxIdTypeSelect.options.ein',
    value: TaxIdTypeEnum.Ein,
  },
  {
    label: 'vex.widgets.forms.taxIdTypeSelect.options.ssn',
    value: TaxIdTypeEnum.Ssn,
  },
  {
    label: 'vex.widgets.forms.taxIdTypeSelect.options.itin',
    value: TaxIdTypeEnum.Itin,
  },
];

export const addressSchema = object().nullable().shape({
  line1: string().required(),
  line2: string().notRequired(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
  countryCode: string().optional(),
});

export const taxIdMasks = {
  [TaxIdTypeEnum.Ein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], // i.e 12-3456789,
  [TaxIdTypeEnum.Ssn]: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 123-45-6789,
  [TaxIdTypeEnum.Itin]: [/9/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // i.e 923-45-6781,
  any: [/./, /./, /./, /./, /./, /./, /./, /./, /./, /./], // i.e 123456789 | XXXXXX123,
};

export const COMPANY_MIN_LEN = 3;
export const COMPANY_MAX_LEN = 255;
