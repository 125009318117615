import React from 'react';

import { useIsMissingKycComplianceInformation } from '@/hooks/useIsMissingKycComplianceInformation';
import { CompleteMtlDetailsScreen } from '@/screens/CompleteMtlDetails.screen';

export const WithKYCComplianceRouteGuard: React.FC<{ enabled?: boolean }> = ({ enabled = true, children }) => {
  const { isVisitedBlockerScreen, onBlockerScreenUserInteraction, missingKycComplianceInformation } =
    useIsMissingKycComplianceInformation();

  if (enabled && !isVisitedBlockerScreen && missingKycComplianceInformation) {
    return <CompleteMtlDetailsScreen onDone={onBlockerScreenUserInteraction} />;
  }

  return <>{children}</>;
};
