import {
  Account,
  DataToPrefillAnOnboardingFormCompany,
  OrganizationBusinessType,
  TaxIdTypeEnum,
  TaxInfo,
} from '@melio/platform-api';
import { defaults, isEmpty, omit, omitBy } from 'lodash';
import { useMemo } from 'react';

import { getValidTaxInfoByBusinessType, padTaxIdNumber } from '../utils';

export const useMergePrePopulatedCompanyData = (
  companyData: Partial<Account['company']>,
  prePopulateCompany: DataToPrefillAnOnboardingFormCompany
) =>
  useMemo(() => {
    const prePopulatedTaxInfo: TaxInfo = getValidTaxInfoByBusinessType({
      businessType: prePopulateCompany?.businessType,
      taxIdNumber: prePopulateCompany?.taxIdNumber || '',
      taxIdType: prePopulateCompany?.taxIdType,
    });

    const normalizedPrePopulatedCompanyData = {
      ...omit(prePopulateCompany, ['taxIdType', 'taxIdNumber', 'dbaName']),
      ...(prePopulateCompany?.dbaName && { name: prePopulateCompany.dbaName }),
      taxInfo: prePopulatedTaxInfo,
    };

    const { legalName, address, legalAddress, name, businessType, taxInfo, industry } = defaults(
      omitBy(companyData, isEmpty),
      normalizedPrePopulatedCompanyData
    );
    const companyAddress = address?.line1 ? address : null;
    const companyLegalAddress = legalAddress ? legalAddress : null;

    return {
      legalBusinessName: legalName || '',
      legalBusinessAddress: companyLegalAddress,
      businessAddress: companyAddress,
      businessName: name || '',
      businessType: businessType || OrganizationBusinessType.LimitedLiabilityCompany,
      taxIdType: taxInfo?.type || TaxIdTypeEnum.Ein,
      taxIdNumber: taxInfo?.identifier ? padTaxIdNumber(taxInfo.identifier) : '',
      industry: industry ? { name: industry.name, naicsCode: industry.naicsCode } : null,
    };
  }, [companyData, prePopulateCompany]);
