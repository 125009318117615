import type { DeliveryMethod, MeOrganization } from '@melio/platform-api';
import { useCallback, useEffect, useState } from 'react';

import { AddDeliveryMethodByPayeeStep } from './types';

type UseAddDeliveryMethodByPayeeStepProps = {
  organizations: MeOrganization[];
  selectedDeliveryMethod?: DeliveryMethod;
  selectedOrganization?: MeOrganization;
  isDmCopied: boolean;
};
export const useAddDeliveryMethodByPayeeStep = ({
  organizations,
  selectedDeliveryMethod,
  selectedOrganization,
  isDmCopied,
}: UseAddDeliveryMethodByPayeeStepProps) => {
  const [currentStep, setCurrentStep] = useState<AddDeliveryMethodByPayeeStep>('SELECT_DELIVERY_METHOD_TYPE');

  const handleNavigationByOrganizations = useCallback(() => {
    if (selectedDeliveryMethod && isDmCopied) {
      return setCurrentStep('DELIVERY_METHOD_ADDED_SUCCESSFULLY');
    }
    if (selectedDeliveryMethod && selectedOrganization) {
      return setCurrentStep('ACCEPT_PAYMENT_FOR_EXISTS_DELIVERY_METHOD');
    }
    if (selectedOrganization && !selectedDeliveryMethod) {
      return setCurrentStep('SELECT_DELIVERY_METHOD_TYPE');
    }
    if (organizations.length > 1) {
      return setCurrentStep('COMPANY_SELECTION_SCREEN');
    }
  }, [isDmCopied, organizations.length, selectedDeliveryMethod, selectedOrganization]);

  useEffect(() => {
    handleNavigationByOrganizations();
  }, [handleNavigationByOrganizations]);

  const goToPreviousStep = () => {
    setCurrentStep('SELECT_DELIVERY_METHOD_TYPE');
  };

  return { currentStep, goToStep: setCurrentStep, goToPreviousStep };
};
