import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { GetMeOrgData, VexOrganizationsApiClient } from '@melio/platform-api-axios-client';

export const useVexMyOrganizations = (
  props: UseCollectionApiProps<typeof VexOrganizationsApiClient.getVexMyOrganizations, GetMeOrgData>
) =>
  useCollectionApi({
    ...props,
    queryKey: ['VexOrganizationsApi', 'getVexMyOrganizations'],
    queryFn: VexOrganizationsApiClient.getVexMyOrganizations,
  });
