import { Card, Container, ExternalLayout, Group, Text } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  PaperCheckDeliveryMethod,
  Payment,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  DeliveryDateListItemWidget,
  DeliveryMethodListItemWidget,
  FooterWidget,
  PayorPendingPaymentBanner,
  PoweredByMelioWidget,
} from '@melio/vex-widgets';

type PaymentDetailsProps = {
  paymentDetailsTitle?: string;
  deliveryMethod?: BankAccountDeliveryMethod | PaperCheckDeliveryMethod | VirtualCardAccountDeliveryMethod;
  estimatedDelivery?: Payment['estimatedDelivery'];
  maxEstimatedDelivery?: Payment['maxEstimatedDelivery'];
};

type TrackSuccessType = {
  name: string;
};

export type BaseSuccessScreenProps = {
  title?: string;
  description: string;
  variantIcon?: 'success' | 'informative';
  showPoweredByMelio?: boolean;
  trackSuccessScreen?: TrackSuccessType;
  'data-testid'?: string;
  displayTrustedPartners?: boolean;
} & PaymentDetailsProps;

const PaymentDetails = ({
  paymentDetailsTitle,
  deliveryMethod,
  estimatedDelivery,
  maxEstimatedDelivery,
}: PaymentDetailsProps) => {
  if (!(paymentDetailsTitle && deliveryMethod && estimatedDelivery && maxEstimatedDelivery)) {
    return null;
  }

  return (
    <Group variant="vertical" spacing="s">
      <Text textStyle="body4SemiUpper" color="global.neutral.900">
        {paymentDetailsTitle}
      </Text>
      <Group variant="vertical" spacing="m" hasDivider>
        <DeliveryMethodListItemWidget deliveryMethod={deliveryMethod} />
        <DeliveryDateListItemWidget
          deliveryMethodType={deliveryMethod.type}
          estimatedDelivery={estimatedDelivery}
          maxEstimatedDelivery={maxEstimatedDelivery}
        />
      </Group>
    </Group>
  );
};

export const BaseSuccessScreen = forwardRef<BaseSuccessScreenProps, 'div'>(
  (
    {
      title,
      description,
      variantIcon = 'success',
      paymentDetailsTitle,
      deliveryMethod,
      estimatedDelivery,
      maxEstimatedDelivery,
      showPoweredByMelio,
      trackSuccessScreen,
      displayTrustedPartners,
      children: _,
      ...props
    },
    ref
  ) => {
    const pageEvent = trackSuccessScreen?.name || 'SuccessScreen';
    const eventParams = {
      Intent: pageEvent,
      description: paymentDetailsTitle,
    };
    usePageView(pageEvent, eventParams);
    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" hasDivider>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="xl">
              <PayorPendingPaymentBanner variant={variantIcon} title={title} description={description} />
              <PaymentDetails
                paymentDetailsTitle={paymentDetailsTitle}
                deliveryMethod={deliveryMethod}
                estimatedDelivery={estimatedDelivery}
                maxEstimatedDelivery={maxEstimatedDelivery}
              />
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = (
      <FooterWidget displayTrustedPartners={displayTrustedPartners}>
        {showPoweredByMelio && <PoweredByMelioWidget />}
      </FooterWidget>
    );

    return (
      <ExternalLayout
        data-component="BaseSuccessScreen"
        data-testid={props['data-testid'] ?? 'base-success-screen'}
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

BaseSuccessScreen.displayName = 'BaseSuccessScreen';
