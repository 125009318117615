import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountsApiClient, Expand } from '@melio/platform-api-axios-client';

import { Account, AccountWithBillsAndPaymentsOverview, AccountWithLogoUrl } from './types';

type QueryFn = typeof AccountsApiClient.getAccounts;
type UpdateFn = typeof AccountsApiClient.patchAccountsAccountId;
type CreateFn = typeof AccountsApiClient.postAccounts;

export type UseAccountsProps<T extends Account = Account, TExpand extends Expand = never> = UseCollectionApiProps<
  QueryFn,
  T
> &
  Optional<TExpand, { params: { expand: TExpand } }>;

const useExpandedAccounts = <T extends Account = Account, TExpand extends Expand = never>({
  params,
  ...props
}: UseAccountsProps<T, TExpand>) =>
  useCollectionApi<QueryFn, CreateFn, UpdateFn, never, T>({
    ...props,
    queryKey: ['AccountsApi', params],
    queryFn: () => AccountsApiClient.getAccounts(params?.expand ?? 'none'),
    createFn: AccountsApiClient.postAccounts,
    updateFn: AccountsApiClient.patchAccountsAccountId,
  });

export type AccountCollection<T extends Account = Account, TExpand extends Expand = never> = ReturnType<
  typeof useExpandedAccounts<T, TExpand>
>;

export const useAccounts = (props: UseAccountsProps = {}) => useExpandedAccounts(props);

export const useAccountsWithLogoUrl = (props: UseAccountsProps<AccountWithLogoUrl> = {}) =>
  useExpandedAccounts<AccountWithLogoUrl, 'logoUrl'>({ ...props, params: { expand: 'logoUrl' } });

export const useAccountsWithBillsAndPaymentsOverview = (
  props: UseAccountsProps<AccountWithBillsAndPaymentsOverview> = {}
) =>
  useExpandedAccounts<AccountWithBillsAndPaymentsOverview, 'billsAndPaymentsOverview'>({
    ...props,
    params: { expand: 'billsAndPaymentsOverview' },
  });
