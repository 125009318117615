import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { matchPath, Params, useLocation, useParams, useSearchParams } from 'react-router-dom';

const VENDOR_ONBOARDING_ACTION_NAME = 'registerPartnerVendor';

const VENDOR_ONBOARDING_ACTION_KEY_NAME = 'onboardingSessionUuid';
const DEFAULT_ACTION_KEY_NAME = 'paymentId';

type DecodedTokenActionData = { action: string; paymentId?: string; onboardingSessionUuid?: string };

const extractIdentifierFromActionToken = ({ token }: { token: string }) => {
  try {
    const {
      actionData: { action, onboardingSessionUuid, paymentId },
    } = jwtDecode<{ actionData: DecodedTokenActionData }>(token);

    if (action === VENDOR_ONBOARDING_ACTION_NAME) {
      return onboardingSessionUuid;
    }
    return paymentId;
  } catch (e) {
    return undefined;
  }
};

const extractIdentifierFromPath = ({
  pathname,
  pathParams,
}: {
  pathname: string;
  pathParams: Readonly<Params<string>>;
}) => {
  if (matchPath('/vex/vendor-onboarding/:onboardingSessionUuid/*', pathname)) {
    return pathParams[VENDOR_ONBOARDING_ACTION_KEY_NAME];
  }

  return pathParams[DEFAULT_ACTION_KEY_NAME];
};

export const useExtractTokenIdentifierFromUrl = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const tokenIdentifierFromUrl = useMemo(() => {
    const isAuthActionRoute = !!matchPath('/vex/auth/action', location.pathname);

    if (isAuthActionRoute) {
      const token = searchParams.get('token');

      if (!token) {
        return undefined;
      }

      return extractIdentifierFromActionToken({ token });
    }

    return extractIdentifierFromPath({ pathname: location.pathname, pathParams: params });
  }, [location.pathname, params, searchParams]);

  return tokenIdentifierFromUrl;
};
