import { ExternalLayout } from '@melio/penny';
import {
  useReActivateVirtualCard,
  useVirtualCardAcceptance,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { isPartnerMelio } from '../../shared';
import { BaseSuccessScreen } from '../base-screens';

type SuvcExpiredActivityType = {
  paymentId: string;
};

export const SuvcExpiredActivity: React.VFC<SuvcExpiredActivityType> = ({ paymentId }) => {
  const showPoweredByMelio = !isPartnerMelio();
  const { data: suvcData, ...suvcAcceptance } = useVirtualCardAcceptance(paymentId);
  const { formatMessage, formatCurrency } = useMelioIntl();
  const reActivateVirtualCard = useReActivateVirtualCard();

  useEffect(() => {
    void reActivateVirtualCard.mutateAsync(paymentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (suvcAcceptance.isLoading || reActivateVirtualCard.isLoading) {
    return <ExternalLayout isLoading />;
  }

  if (reActivateVirtualCard.isError || !suvcData?.data) {
    return <Navigate to="../../error" />;
  }

  const { deliveryMethod, payment, organization, ...virtualCard } = suvcData.data;

  return (
    <Routes>
      <Route
        index
        element={
          <BaseSuccessScreen
            data-testid="suvc-card-expired-screen"
            title={formatMessage('vex.screen.suvcExpired.title')}
            variantIcon="informative"
            description={formatMessage('vex.screen.suvcExpired.description', {
              amount: formatCurrency(Number(virtualCard.amount)),
              payorCompanyName: organization.companyName,
            })}
            paymentDetailsTitle={formatMessage('vex.screen.suvcExpired.paymentDetailsTitle')}
            deliveryMethod={deliveryMethod as VirtualCardAccountDeliveryMethod}
            estimatedDelivery={payment.deliveryEta as Date}
            maxEstimatedDelivery={payment.maxDeliveryEta as Date}
            showPoweredByMelio={showPoweredByMelio}
          />
        }
      />
    </Routes>
  );
};

SuvcExpiredActivity.displayName = 'SuvcExpiredActivity';
