import { useMemo, useState } from 'react';

import { getLastOpenedCardFromSubmittedSteps, getSavedCardsFromState, saveCardsFormState } from './utils';

const useCardsSavedState = <T>({
  cardsOrder,
  flowUniqueIdentifier,
}: {
  cardsOrder: T[];
  flowUniqueIdentifier: string | undefined;
}) => {
  const lastStoredFormsState = useMemo(() => {
    const storedSubmittedSteps = getSavedCardsFromState<T>({
      uniqueIdentifier: flowUniqueIdentifier || '',
    });

    const lastOpenedCard = getLastOpenedCardFromSubmittedSteps<T>({
      cardsOrder,
      submittedSteps: storedSubmittedSteps,
    });

    return { lastOpenedCard, storedSubmittedSteps };
  }, [cardsOrder, flowUniqueIdentifier]);

  return lastStoredFormsState;
};

export type UseCardFormsArgs<T> = {
  firstCard: T;
  cardsOrder: T[];
  flowUniqueIdentifier: string;
  shouldUseSavedState?: boolean;
};

export type UseCardsFromReturnType<T> = {
  openCard: (formCardId: T) => void;
  openNextStep: () => void;
  getIsOpened: (formCardId: T) => boolean;
  getIsSubmitted: (formCardId: T) => boolean;
  setDirtyCard: (formCardId: T) => (isDirty: boolean, onDirtyCallback: () => void | Promise<void>) => void;
  getIsDisabled: (formCardId: T) => boolean;
};

export const useCardForms = <T>({
  firstCard,
  cardsOrder,
  flowUniqueIdentifier,
  shouldUseSavedState = false,
}: UseCardFormsArgs<T>): UseCardsFromReturnType<T> => {
  const { lastOpenedCard, storedSubmittedSteps } = useCardsSavedState<T>({ cardsOrder, flowUniqueIdentifier });
  const defaultOpenedCard = shouldUseSavedState ? lastOpenedCard : firstCard;
  const defaultSubmittedSteps = shouldUseSavedState ? storedSubmittedSteps : new Set<T>();
  const [openedCard, setOpenedCard] = useState<T>(defaultOpenedCard || firstCard);
  const [submittedSteps, setSubmittedSteps] = useState<Set<T>>(defaultSubmittedSteps || new Set<T>());
  const [dirtyCards, setDirtyCards] = useState<Map<T, () => void>>(new Map());
  const getIsSubmitted = (formCardId: T) => submittedSteps.has(formCardId);
  const openCard = (formCardId: T) => {
    if (dirtyCards.size > 0) {
      [...dirtyCards.values()].map((onDirtyCallback) => onDirtyCallback());

      return;
    }

    setOpenedCard(formCardId);
  };
  const openNextStep = (): void => {
    const newSubmittedSteps = new Set([...submittedSteps, openedCard]);
    setSubmittedSteps(new Set(newSubmittedSteps));
    const nextStep = cardsOrder.find((card) => !newSubmittedSteps.has(card)) || firstCard;
    openCard(nextStep);
    saveCardsFormState({
      submittedSteps: newSubmittedSteps,
      uniqueIdentifier: flowUniqueIdentifier || '',
    });
  };
  const getIsOpened = (formCardId: T): boolean => openedCard === formCardId;
  const getIsDisabled = (formCardId: T): boolean => {
    const cardIndex = cardsOrder.indexOf(formCardId);
    const previousCards = cardsOrder.slice(0, cardIndex);

    return previousCards.some((card) => getIsOpened(card)) && !getIsOpened(formCardId);
  };

  const setDirtyCard =
    (formCardId: T): ((isDirty: boolean, onDirtyCallback: () => void | Promise<void>) => void) =>
    (isDirty: boolean, onDirtyCallback: () => void) => {
      if (isDirty && !dirtyCards.has(formCardId)) {
        dirtyCards.set(formCardId, onDirtyCallback);
        setDirtyCards(new Map([...dirtyCards]));
      } else if (dirtyCards.has(formCardId) && !isDirty) {
        dirtyCards.delete(formCardId);
        setDirtyCards(new Map([...dirtyCards]));
      }
    };

  const hookReturn: UseCardsFromReturnType<T> = {
    openCard,
    openNextStep,
    getIsOpened,
    getIsSubmitted,
    setDirtyCard,
    getIsDisabled,
  };
  return hookReturn;
};
