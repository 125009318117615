import { Form, RadioOption, useMelioForm } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { map } from 'lodash';

import { CheckAccountFormModel, FormWidgetProps } from '../../../../types';
import { createAddressLabel } from '../../../AddressSearch';
export type VendorAddressSuggestionsFormProps = FormWidgetProps<CheckDetailsWithAddressSuggestionsKey> & {
  checkDetailsWithAddressSuggestions: CheckDetailsWithAddressSuggestions;
};
export type CheckDetailsWithAddressSuggestions = {
  checkDetailsWithSuggestedAddress: CheckAccountFormModel | undefined;
  checkDetailsWithOriginalAddress: CheckAccountFormModel;
};

export type CheckDetailsWithAddressSuggestionsKey = {
  key: keyof CheckDetailsWithAddressSuggestions;
};
export const VendorCheckAddressSuggestionsPayeeFormWidget: React.FC<VendorAddressSuggestionsFormProps> = forwardRef<
  VendorAddressSuggestionsFormProps,
  'form'
>(
  (
    {
      onSubmit,
      defaultValues: _defaultValues,
      isSaving,
      onSubmissionStateChange,
      checkDetailsWithAddressSuggestions,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const createRadioGroupOptions = () => {
      const mapAddressSuggestionsToArray = map(checkDetailsWithAddressSuggestions, (value, key) => ({ key, value }));
      const options: RadioOption[] = mapAddressSuggestionsToArray.map((item) => ({
        mainLabelProps: { label: createAddressLabel(item.value as CheckAccountFormModel) },
        value: item.key,
        descriptionProps: {
          label: formatMessage(
            `activities.addCheckDeliveryMethodByPayee.screens.addressSuggestions.optionGroupDescription.${item.key}` as MessageKey
          ),
        },
      }));

      return options;
    };

    const { formProps, registerField } = useMelioForm<CheckDetailsWithAddressSuggestionsKey>({
      onSubmit,
      defaultValues: { key: 'checkDetailsWithSuggestedAddress' },
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <Form data-component="VendorCheckAddressSuggestionsPayeeFormWidget" {...props} {...formProps} ref={ref}>
        <Form.RadioGroup
          variant="vertical"
          options={createRadioGroupOptions()}
          {...registerField('key')}
          aria-label="check details with suggested address"
        />
      </Form>
    );
  }
);

VendorCheckAddressSuggestionsPayeeFormWidget.displayName = 'VendorCheckAddressSuggestionsPayeeFormWidget';
