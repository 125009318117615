import { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';
import { IconButton, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { Size } from '@/cl/theme/theme.types';
import { FullScreenContainerTheme } from './FullScreenContainer.component.theme';

type Props = PropsWithChildren<{
  size?: Size;
  title?: string;
  subtitle?: string;
  icon?: JSX.Element;
  centered?: boolean;
  onCloseHandler?: VoidFunction;
}>;

export const FullScreenContainer = ({
  size = 'small',
  title,
  subtitle,
  children,
  icon,
  centered,
  onCloseHandler,
}: Props) => {
  const styles = useAppTheme(FullScreenContainerTheme, {
    size,
    centered,
  });

  return (
    <Box data-component="FullScreenContainer" sx={styles['screen']}>
      <Box sx={styles['closeContainer']}>
        {onCloseHandler && (
          <Box sx={styles['closeButton']}>
            <IconButton icon="close" variant={'naked'} size="medium" onClick={onCloseHandler} aria-label={'button'} />
          </Box>
        )}
      </Box>
      <Box sx={styles['container']}>
        {icon && <Box sx={styles['iconContainer']}>{icon}</Box>}
        {title && (
          <Box sx={styles['titleContainer']}>
            <Text as="h2" textStyle="heading1Semi">
              {title}
            </Text>
            {subtitle && <Text textStyle="body3">{subtitle}</Text>}
          </Box>
        )}
        <Box sx={styles['childrenContainer']}>{children}</Box>
      </Box>
    </Box>
  );
};
