import { cloneDeep, get, has, isArray, map, update } from 'lodash';

import { Data, PropertyChanger } from './types';

const updateProperty = <T extends Data>(object: T, path: string, func: PropertyChanger) => {
  const [first, ...second] = path.split('[].');
  const firstLevelKey = first || '';

  if (!has(object, firstLevelKey)) {
    return object;
  }

  if (!isArray(get(object, firstLevelKey))) {
    update(object, firstLevelKey, func);
    if (second.length > 1) {
      updateProperty(get(object, firstLevelKey) as Data, second.join('[].'), func);
    }
    return object;
  }

  map(get(object, firstLevelKey) as Data, (val) => updateProperty(val as Data, second.join('[].'), func));
  return object;
};

/**
 * A utility to invoke a given function on a specific list of properties in an object
 * if one of the properties is an array the function will be executed on each of the instances in the array
 *
 * @example updateProperties({a: 100, b: [{ c: 100}]}, ["a", "b[].c"], (x) => x * 2) returns {a: 200, b: [{ c: 200}]}
 * @param object can be any object
 * @param properties list of properties in the object. nested properties are separated by ".", arrays are marked with "[]"
 * @param func a function to execute on each of the properties
 */
export const updateProperties = <T extends Data, R extends T = T>(
  object: T,
  properties: string[],
  func: PropertyChanger
) => {
  const result = cloneDeep(object);
  properties.map((property) => updateProperty(result, property, func));
  return result as R;
};
