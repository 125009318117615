import { Button, Card, Container, ExternalLayout, Group, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FeeCatalogValueTypeEnum, PaymentUpgradeOfferOption, PaymentUpgradeOfferOptionEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { FooterWidget, PCIComplianceInfo, TermsAndPolicyDisclaimerWidget, useIsMobile } from '@melio/vex-widgets';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { P2DOfferBanner, PaymentHeader, PersistentFastPreferenceOfferBanner } from '../../components';
import { PersistentFastLabels } from '../../components/types';
import {
  ANALYTICS_CONTEXT,
  mapUpgradeOfferToAnalyticsOffer,
  mapUpgradeOfferToAnalyticsPersistentFastOffer,
} from '../../consts';
import { useConfirmScreenDeliveryDateLabel } from '../../hooks';
import { HandleSubmitACHUpgradeProps } from '../../types';
import { getUpgradeOfferByType } from '../../utils';

export type PaymentUpgradeConfirmScreenProps = {
  amount: number;
  formattedDeliveryMethodDetails: string;
  orgName: string;
  invoiceNumber: string | null;
  note: string;
  paymentUpgradeOffers: Array<PaymentUpgradeOfferOption>;
  isPersistentFastOfferEnabled: boolean;
  isPaymentUpgraded: boolean;
  isSubmitting: boolean;
  onNavigateToCardDetailsScreen: () => void;
  onSubmit: ({ offerType, isPersistentFastActive }: HandleSubmitACHUpgradeProps) => Promise<void>;
  persistentFastOfferLabels: PersistentFastLabels['offer'];
};

export const PaymentUpgradeConfirmScreen = withAnalyticsContext<PaymentUpgradeConfirmScreenProps>(
  forwardRef(
    (
      {
        amount,
        formattedDeliveryMethodDetails,
        orgName,
        invoiceNumber,
        note,
        paymentUpgradeOffers,
        isPersistentFastOfferEnabled,
        isPaymentUpgraded,
        isSubmitting,
        onNavigateToCardDetailsScreen,
        onSubmit,
        setAnalyticsProperties,
        children,
        persistentFastOfferLabels,
        ...props
      },
      ref
    ) => {
      const isMobile = useIsMobile();
      const { track } = useAnalytics();
      const { routeReady } = useMonitoring();
      const { formatPercents, formatCurrency, formatMessage } = useMelioIntl();
      const [isPersistentFastActive, setPersistentFastCheckboxState] = useBoolean(false);

      const rtpUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Rtp);
      const fachUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Fach);
      const p2dUpgradeOffer = getUpgradeOfferByType(paymentUpgradeOffers, PaymentUpgradeOfferOptionEnum.Debit);

      const upgradeOffer = rtpUpgradeOffer || fachUpgradeOffer;

      useEffect(() => {
        setAnalyticsProperties({
          PageName: 'confirm-your-fast-transfer',
          DeliveryMethodShown: paymentUpgradeOffers
            .map(({ offerType }) => mapUpgradeOfferToAnalyticsOffer[offerType])
            .sort()
            .join('_'),
          ...(upgradeOffer &&
            isPersistentFastOfferEnabled && {
              SpecialDeliveryMethod: mapUpgradeOfferToAnalyticsPersistentFastOffer[upgradeOffer.offerType],
            }),
        });
        // This hook should occur only once on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      usePageView(ANALYTICS_CONTEXT, { Intent: 'choose-to-accept-fast-delivery-method' });

      const deliveryEtaLabel = useConfirmScreenDeliveryDateLabel(upgradeOffer);

      if (!upgradeOffer) {
        return null;
      }

      const { calculatedFee, feeCatalog, offerType } = upgradeOffer;

      const formattedFeeAmount =
        feeCatalog.valueType === FeeCatalogValueTypeEnum.Percent
          ? formatPercents(feeCatalog.value, { divide: true })
          : formatCurrency(calculatedFee);

      const handleNavigateToP2D = (e: React.MouseEvent<HTMLLinkElement>) => {
        e.preventDefault();
        e.stopPropagation();
        track(ANALYTICS_CONTEXT, 'Click', {
          Cta: 'enter-your-card-details',
          Intent: 'choose-to-accept-fast-delivery-method',
          DeliveryMethodChosen: mapUpgradeOfferToAnalyticsOffer[offerType],
        });
        onNavigateToCardDetailsScreen();
      };

      const handleSubmit = async () => {
        track(ANALYTICS_CONTEXT, 'Click', {
          Cta: 'confirm',
          Intent: 'accept-fast-delivery-method',
          DeliveryMethodChosen: mapUpgradeOfferToAnalyticsOffer[offerType],
          ...(isPersistentFastOfferEnabled && { CheckBoxState: isPersistentFastActive }),
        });
        await onSubmit({ offerType, isPersistentFastActive });
      };

      if (isPaymentUpgraded) {
        return <Navigate to="../success" />;
      }

      const content = (
        <Card ref={routeReady} paddingX="none" paddingY="none">
          <Group variant="vertical" spacing="none" width="full">
            <Container paddingX={isMobile ? 's' : 'xl'} paddingY="l" backgroundColor="light">
              <PaymentHeader amount={amount} orgName={orgName} invoiceNumber={invoiceNumber} note={note} />
            </Container>
            <Container
              data-testid="payment-upgrade-confirm-card-body"
              paddingX={isMobile ? 's' : 'xl'}
              paddingY={isMobile ? 's' : 'xl'}
            >
              <Group variant="vertical" spacing={isMobile ? 'm' : 'xl'}>
                <Group variant="vertical" spacing={isMobile ? 'xxs' : 's'}>
                  <Text textStyle="heading3Semi" as="h1" data-testid="layout-title">
                    {formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.title', {
                      offerType,
                    })}
                  </Text>
                  <Group variant="vertical" spacing={isMobile ? 'xxs' : 'xs'}>
                    <Text>
                      {formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.description', {
                        eta: deliveryEtaLabel,
                        method: formatMessage(
                          'activities.paymentUpgrade.screens.paymentUpgradeConfirm.deliveryMethod.mainLabel',
                          {
                            last4digits: formattedDeliveryMethodDetails,
                            offerType,
                          }
                        ),
                      })}
                    </Text>
                    <Text color="global.neutral.800" textStyle="body4">
                      {formatMessage(
                        'activities.paymentUpgrade.screens.paymentUpgradeConfirm.deliveryMethod.description',
                        {
                          feeAmount: formattedFeeAmount,
                        }
                      )}
                    </Text>
                  </Group>
                </Group>
                {isPersistentFastOfferEnabled && (
                  <PersistentFastPreferenceOfferBanner
                    isChecked={isPersistentFastActive}
                    isDisabled={isSubmitting}
                    onChange={setPersistentFastCheckboxState.toggle}
                    labels={persistentFastOfferLabels}
                  />
                )}
                <Group variant="vertical" spacing="m">
                  <Group variant="vertical" alignItems="center">
                    <Button
                      data-testid="layout-next-button"
                      size="large"
                      isFullWidth
                      isLoading={isSubmitting}
                      label={formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.submit')}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={handleSubmit}
                    />
                    <TermsAndPolicyDisclaimerWidget alignCenter textStyle="body4" />
                  </Group>
                  {Boolean(p2dUpgradeOffer && !rtpUpgradeOffer) && (
                    <P2DOfferBanner onNavigateToP2D={handleNavigateToP2D} />
                  )}
                  <PCIComplianceInfo />
                </Group>
              </Group>
            </Container>
          </Group>
        </Card>
      );

      return (
        <ExternalLayout
          data-component="PaymentUpgradeActivity.PaymentUpgradeConfirmScreen"
          data-testid="vex-payment-upgrade-confirm-screen"
          {...props}
          content={content}
          footer={<FooterWidget />}
          ref={ref}
        />
      );
    }
  )
);

PaymentUpgradeConfirmScreen.displayName = 'PaymentUpgradeActivity.PaymentUpgradeConfirmScreen';
