import { useContext } from 'react';

import { MonitoringAPI, MonitoringContext } from './MonitoringProvider';

export function useMonitoring<Action extends string>() {
  const context = useContext(MonitoringContext);
  if (context === undefined) {
    throw new Error('useMonitoring must be used within a MonitoringProvider');
  }
  return context as MonitoringAPI<Action>;
}
