import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const ErrorPageTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      flex: 1,
      gridGap: '40px',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '16px',
    },
    primaryButton: {
      mb: '16px',
    },
    secondaryButton: {
      mt: '16px',
    },
  },
});
