import { Box } from '@chakra-ui/react';
import { isEinOnlyBusinessType, masks, useMtlFormValues, useMtlMessages } from '@melio/ap-domain';
import { Form, SectionBanner, useMelioForm } from '@melio/penny';
import { TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { ExistingTaxIdIsEinOption } from '../../CompleteLegalInfoForm';
import { CompleteRequiredDetailsFormFields, MissingFieldsMap } from '../types';

export type CompleteLegalInfoUseFormReturnType = ReturnType<typeof useMelioForm<CompleteRequiredDetailsFormFields>>;

type BusinessTypeAndTaxInfoProps = CompleteLegalInfoUseFormReturnType & {
  missingData: MissingFieldsMap;
  existingBusinessType?: boolean;
  companyName?: string;
  colSpan?: number;
};

const existingTaxIdEinOptions: ExistingTaxIdIsEinOption[] = ['Yes', 'No'];

type UseTaxFormFieldProps = Pick<CompleteLegalInfoUseFormReturnType, 'watch' | 'setValue' | 'trigger' | 'formState'> &
  Pick<BusinessTypeAndTaxInfoProps, 'missingData'>;

const useTaxFormFields = ({ watch, trigger, formState, setValue, missingData }: UseTaxFormFieldProps) => {
  const selectedBusinessType = watch('businessType') || null;
  const selectedTaxIdType = watch('taxInfoType') || TaxIdTypeEnum.Ein;
  const existingTaxIdIsEin = watch('existingTaxIdIsEin');
  const einOnlyBusinessType = isEinOnlyBusinessType(selectedBusinessType);
  const watchBusinessType = watch('businessType');

  useEffect(() => {
    if (einOnlyBusinessType) {
      setValue('taxInfoType', TaxIdTypeEnum.Ein);
    } else {
      setValue('existingTaxIdIsEin', 'Yes');
    }
  }, [setValue, einOnlyBusinessType]);

  const isTaxInfoFieldHidden = !missingData.taxInfoIdentifier || !selectedBusinessType || einOnlyBusinessType;

  useEffect(() => {
    const shouldTriggerValidations = formState.isSubmitted;
    if (shouldTriggerValidations && (!isTaxInfoFieldHidden || (einOnlyBusinessType && watchBusinessType))) {
      void trigger('taxInfoIdentifier');
    }
  }, [trigger, selectedTaxIdType, isTaxInfoFieldHidden, formState.isSubmitted, einOnlyBusinessType, watchBusinessType]);

  useEffect(() => {
    if (!isTaxInfoFieldHidden && selectedTaxIdType) {
      setValue('taxInfoType', selectedTaxIdType);
    }
  }, [isTaxInfoFieldHidden, selectedTaxIdType, setValue]);

  return {
    selectedBusinessType,
    selectedTaxIdType,
    isEinOnlyBusinessType: einOnlyBusinessType,
    userOverridesTaxId: existingTaxIdIsEin === 'No',
    isTaxInfoFieldHidden,
  };
};

export const BusinessTypeAndTaxInfoWidget = ({
  companyName,
  missingData,
  existingBusinessType,
  watch,
  setValue,
  registerField,
  formState,
  trigger,
  colSpan,
}: BusinessTypeAndTaxInfoProps) => {
  const { selectedBusinessType, selectedTaxIdType, isEinOnlyBusinessType, userOverridesTaxId, isTaxInfoFieldHidden } =
    useTaxFormFields({
      watch,
      setValue,
      formState,
      trigger,
      missingData,
    });
  const { formatMessage } = useMelioIntl();
  const {
    labels: { company: companyLabels },
    placeholders,
    emptyState,
  } = useMtlMessages();
  const { businessTypeOptionsSelectNew: businessTypeOptions, taxIdTypeOptions } = useMtlFormValues();

  const taxIdFormFieldCommonProps = {
    helperTextProps: {
      label: isEinOnlyBusinessType && selectedBusinessType ? companyLabels.einOnlyHelperText(selectedBusinessType) : '',
    },
    placeholder: placeholders.taxId(selectedTaxIdType),
  };

  const taxIdTooltipLabel = formatMessage(`widgets.completeLegalInfo.taxInfo.identifier.existing.tooltip`);
  const isReadOnlyTaxIdIdentifierHidden = !!missingData.taxInfoIdentifier || !selectedBusinessType;

  return (
    <>
      <Form.SelectNew
        {...registerField('businessType')}
        isReadOnly={!missingData.businessType}
        isHidden={!missingData.businessType && !missingData.taxInfoIdentifier}
        labelProps={{
          label: companyLabels.businessType,
          ...(existingBusinessType && {
            tooltipProps: {
              label: formatMessage(`widgets.completeLegalInfo.businessType.existing.tooltip`),
            },
          }),
        }}
        options={businessTypeOptions}
        placeholder={placeholders.businessType}
        emptyState={{ label: emptyState.businessType }}
        colSpan={colSpan}
        isRequired={missingData.businessType?.isRequired}
      />
      <Form.ContentBox colSpan={colSpan}>
        {/* No TaxId section */}
        <Box
          data-testid="TaxIdForm-nonExisting"
          style={{
            all: 'inherit',
            gap: '1rem',
            display: 'flex',
            flexDirection: 'column',
            ...(!missingData.taxInfoIdentifier && { display: 'none' }),
          }}
        >
          <Form.RadioGroup
            {...registerField('taxInfoType')}
            isRequired
            isHidden={isTaxInfoFieldHidden}
            labelProps={{ label: companyLabels.taxIdType }}
            options={taxIdTypeOptions(selectedBusinessType)}
          />
          <Form.SecuredTextField
            {...registerField('taxInfoIdentifier')}
            maskProps={{
              mask: masks.taxId[selectedTaxIdType],
            }}
            data-private
            isHidden={!missingData.taxInfoIdentifier || !selectedBusinessType}
            labelProps={{
              label: companyLabels.taxId(selectedTaxIdType),
            }}
            helperTextProps={{
              label:
                isEinOnlyBusinessType && selectedBusinessType
                  ? companyLabels.einOnlyHelperText(selectedBusinessType)
                  : '',
            }}
            placeholder={placeholders.taxId(selectedTaxIdType)}
            isRequired={missingData.taxInfoIdentifier?.isRequired || !!missingData.businessType}
          />
        </Box>

        {/* With Tax ID section */}
        <Box
          data-testid="TaxIdForm-existing"
          style={{
            all: 'inherit',
            gap: '1rem',
            display: 'flex',
            flexDirection: 'column',
            ...(!!missingData.taxInfoIdentifier && { display: 'none' }),
          }}
        >
          {!missingData.taxInfoIdentifier && selectedBusinessType && (
            <SectionBanner
              description={formatMessage(
                isEinOnlyBusinessType
                  ? 'widgets.completeLegalInfo.taxInfo.identifier.banner.EIN'
                  : 'widgets.completeLegalInfo.taxInfo.identifier.banner.other'
              )}
              data-testid="taxInfoBanner"
            />
          )}
          <Form.SecuredTextField
            {...registerField('taxInfoIdentifier')}
            {...taxIdFormFieldCommonProps}
            data-private
            labelProps={{
              label: formatMessage(`widgets.completeLegalInfo.taxInfo.identifier.unknown.label`),
              tooltipProps: taxIdTooltipLabel
                ? {
                    label: taxIdTooltipLabel,
                  }
                : undefined,
            }}
            isHidden={isReadOnlyTaxIdIdentifierHidden}
            maskProps={{
              mask: masks.taxId[selectedTaxIdType],
            }}
            isTextVisible
            isRequired={!isReadOnlyTaxIdIdentifierHidden || (!!missingData.businessType && !!selectedBusinessType)}
          />
          <Form.RadioGroup
            {...registerField('existingTaxIdIsEin')}
            isHidden={!missingData.taxInfoIdentifier || !isEinOnlyBusinessType || !selectedBusinessType}
            labelProps={{
              label: formatMessage('widgets.completeLegalInfo.taxInfo.existingTaxIdIsEin.label', { companyName }),
            }}
            options={existingTaxIdEinOptions.map((value) => ({
              value,
              mainLabelProps: {
                label: formatMessage(`widgets.completeLegalInfo.taxInfo.existingTaxIdIsEin.option.${value}.label`),
              },
            }))}
          />
          <Form.RadioGroup
            {...registerField('taxInfoType')}
            isRequired
            isHidden={!missingData.taxInfoIdentifier || isEinOnlyBusinessType || !selectedBusinessType}
            labelProps={{
              label: formatMessage('widgets.completeLegalInfo.taxInfo.type.label.confirm'),
            }}
            options={taxIdTypeOptions(selectedBusinessType)}
          />
          <Form.SecuredTextField
            {...registerField('taxIdEinOverride')}
            {...taxIdFormFieldCommonProps}
            isHidden={!missingData.taxInfoIdentifier || !selectedBusinessType || !userOverridesTaxId}
            labelProps={{
              label: companyLabels.taxId(TaxIdTypeEnum.Ein),
            }}
            maskProps={{
              mask: masks.taxId[TaxIdTypeEnum.Ein],
            }}
            data-private
          />
        </Box>
      </Form.ContentBox>
    </>
  );
};
