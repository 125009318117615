import {
  Button,
  Card,
  Container,
  ExternalLayout,
  Group,
  IconButton,
  NakedButton,
  Text,
  useFormSubmissionController,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import {
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  FooterWidget,
  PCIComplianceInfo,
  PoweredByMelioWidget,
  TermsAndPolicyDisclaimerWidget,
  VendorCheckAddressSuggestionsPayeeFormWidget,
} from '@melio/vex-widgets';

import { CheckDetailsWithAddressSuggestions, CheckDetailsWithAddressSuggestionsKey } from '../../types';

type AddCheckAddressManuallyProps = Pick<
  DeliveryMethodSelectionByPayeeHeaderWidgetProps,
  'accountName' | 'paymentAmount'
> & {
  checkDetailsWithAddressSuggestions: CheckDetailsWithAddressSuggestions;
  isSaving?: boolean;
  isLoading?: boolean;
  onBack: VoidFunction;
  onDone: (data: CheckDetailsWithAddressSuggestionsKey) => void;
  onEditAddressClicked: () => void;
  showPoweredByMelio?: boolean;
};

const activityPrefix = 'activities.addCheckDeliveryMethodByPayee.screens.addressSuggestions';

export const AddressSuggestionsScreen = forwardRef<AddCheckAddressManuallyProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      isSaving,
      onBack,
      onDone,
      checkDetailsWithAddressSuggestions,
      onEditAddressClicked,
      showPoweredByMelio,
      children,
      ...props
    },
    ref
  ) => {
    usePageView('Vendor', { PageName: 'paper-check-address-suggestion', Intent: 'add-address-suggestion' });
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CheckDetailsWithAddressSuggestionsKey>();
    const legalInfoOverride = accountName
      ? formatMessage('vex.widgets.legal.infoWithAccountName', { accountName })
      : undefined;

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            <IconButton
              data-testid="layout-back-button"
              {...cancelButtonProps}
              onClick={onBack}
              aria-label="back"
              icon="chevron-left"
              variant="naked"
              size="medium"
            />
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="m">
              <Text textStyle="heading3Semi">{formatMessage(`${activityPrefix}.title`)}</Text>
              <Text>
                {formatMessage(`${activityPrefix}.description`, {
                  originalAddress: checkDetailsWithAddressSuggestions?.checkDetailsWithOriginalAddress?.line1,
                })}
              </Text>
              <VendorCheckAddressSuggestionsPayeeFormWidget
                checkDetailsWithAddressSuggestions={checkDetailsWithAddressSuggestions}
                onSubmit={onDone}
                isSaving={isSaving}
                onSubmissionStateChange={onSubmissionStateChange}
              />
            </Group>
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="m">
              <Button
                data-testid="layout-next-button"
                {...submitButtonProps}
                size="large"
                isFullWidth
                label={formatMessage(`${activityPrefix}.continue`)}
              />
              <Container justifyContent="center">
                <NakedButton onClick={onEditAddressClicked} label={formatMessage(`${activityPrefix}.editAddress`)} />
              </Container>
              <Group variant="vertical" spacing="m">
                <TermsAndPolicyDisclaimerWidget />
                <PCIComplianceInfo legalInfoOverride={legalInfoOverride} />
              </Group>
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddCheckDeliveryMethodByPayeeActivity.AddressSuggestionsScreen"
        data-testid="add-check-delivery-method-by-payee-activity-address-suggestions-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

AddressSuggestionsScreen.displayName = 'AddCheckDeliveryMethodByPayeeActivity.AddressSuggestionsScreen';
