export enum SettingsNestedPages {
  PROFILE = 'profile',
  COMPANY = 'company',
  NOTIFICATION_PREFERENCES = 'notification-preferences',
  ACCOUNT_SOFTWARE = 'accounting-software',
  PAYMENT_METHODS = 'payment-methods',
  BILLING = 'billing',
  SUPPORT = 'support',
  COLLABORATORS = 'collaborators',
  RECEIVING_METHODS = 'receiving-methods',
  WORKFLOWS = 'approval-workflows',
  SUBSCRIPTION_PLANS = 'subscription-plans',
  INVOICE_ITEMS = 'invoice-items',
  INVOICE_SETTINGS = 'invoice-settings',
  INVOICE_EMAIL_NOTIFICATIONS = 'invoice-email-notifications',
  TAX_AND_REPORTS = 'tax-and-reports',
}
