import {
  type MeOrganization,
  BankAccountDeliveryMethod,
  useOrganizationACHDeliveryMethods,
  useOrganizationsMe,
} from '@melio/platform-api';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useUnilateralOrganizations = () => {
  const [selectedOrganization, setSelectedOrganization] = useState<MeOrganization | undefined>();
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<BankAccountDeliveryMethod | undefined>();
  const [queryParams, setSearchParams] = useSearchParams();

  const { data: organizations = [], error: fetchOrgsError } = useOrganizationsMe();
  const { getOrganizationDeliveryMethodsById, error: copyDMError } = useOrganizationACHDeliveryMethods();

  const setSelectedOrgId = useCallback(
    (organizationId: string) => {
      const searchParams = new URLSearchParams({
        org: organizationId,
      });
      setSearchParams(searchParams.toString());
    },
    [setSearchParams]
  );

  const handleOrgSelection = useCallback(
    async (organizationId: string) => {
      const organization = organizations.find((org) => org.id === organizationId);
      if (!organization) {
        return;
      }

      const deliveryMethods = await getOrganizationDeliveryMethodsById(organizationId);

      setSelectedOrgId(organizationId);
      setSelectedOrganization(organization);
      setSelectedDeliveryMethod(deliveryMethods.at(0));
    },
    [getOrganizationDeliveryMethodsById, organizations, setSelectedOrgId]
  );

  // Set selected org when only one org is available
  useEffect(() => {
    if (organizations.length !== 1 || !organizations[0]) {
      return;
    }

    // eslint-disable-next-line no-console
    handleOrgSelection(organizations[0].id).catch(console.error);
  }, [handleOrgSelection, organizations]);

  // Set selected org from query param
  useEffect(() => {
    const selectedOrgId = queryParams.get('org');
    if (!selectedOrgId) {
      return;
    }

    const organization = organizations.find((org) => org.id === selectedOrgId);
    if (!organization) {
      return;
    }

    getOrganizationDeliveryMethodsById(selectedOrgId)
      .then((deliveryMethods) => {
        setSelectedOrganization(organization);
        setSelectedDeliveryMethod(deliveryMethods.at(0));
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [getOrganizationDeliveryMethodsById, organizations, queryParams]);

  return {
    organizations,
    handleOrgSelection,
    selectedOrganization,
    selectedDeliveryMethod,
    fetchOrgsError: fetchOrgsError || undefined,
    copyDMError: copyDMError || undefined,
  };
};
