import { Container, ExternalLayout, Group, SelectionCard, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { getDeliveryMethodIconKey, useGetDeliveryMethodDescription, usePageView } from '@melio/vex-domain';
import {
  ExternalLayoutContentWidget,
  FooterWidget,
  PayorPendingPaymentDetailsWidget,
  PoweredByMelioWidget,
} from '@melio/vex-widgets';

export type ShiftToAchBankDetailsScreenProps = {
  accountName: string;
  paymentAmount: number;
  paymentInvoiceNumber?: string | null;
  paymentNote?: string;
  deliveryMethod: DeliveryMethod;
  onDone: VoidFunction;
  isSaving?: boolean;
  isLoading?: boolean;
  showPoweredByMelio: boolean;
  onEdit: VoidFunction;
};

export const ShiftToAchBankDetailsScreen = forwardRef<ShiftToAchBankDetailsScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      paymentNote,
      showPoweredByMelio,
      deliveryMethod,
      onDone,
      onEdit,
      isSaving,
      isLoading,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    usePageView('shift-suvc-to-ach-details', { Intent: 'shift-suvc-to-ach-details' });

    const handleAcceptPaymentClick = () => {
      track('shift-suvc-to-ach-accept-payment-ach-details', 'Click', {
        intent: 'shift-suvc-to-ach-accept-payment-ach-details',
      });
      onDone();
    };
    const handleEditPaymentDetailsClick = () => {
      track('shift-suvc-to-ach-edit-ach-details', 'Click', { intent: 'shift-suvc-to-ach-edit-ach-details' });
      onEdit();
    };
    const legalInfoOverride = formatMessage('vex.widgets.legal.infoWithAccountName', { accountName });

    const contentHeader = (
      <PayorPendingPaymentDetailsWidget
        accountName={accountName}
        description={formatMessage('vex.screen.suvcAcceptance.ShiftToAchInsertDetails.description.title', {
          accountName,
        })}
        paymentAmount={paymentAmount}
        paymentInvoiceNumber={paymentInvoiceNumber}
        paymentNote={paymentNote}
        paymentInvoiceNumberLabel={formatMessage('widgets.billDetails.form.invoiceNumber.label')}
        paymentNoteLabel={formatMessage('widgets.deliveryMethodSelectionByPayeeHeader.paymentNoteLabel')}
        showAvatar
      />
    );
    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;
    const content = (
      <ExternalLayoutContentWidget
        header={{
          title: contentHeader,
        }}
        form={{
          title: '',
          fields: (
            <Group variant="vertical" textAlign="center" spacing="l">
              <Container textAlign="start">
                <Text textStyle="heading3Semi">
                  {formatMessage('vex.screen.suvcAcceptance.ShiftToAchBankDetails.dm.title')}
                </Text>
              </Container>
              <SelectionCard
                icon={getDeliveryMethodIconKey(deliveryMethod.type)}
                mainLabelProps={{
                  label: useGetDeliveryMethodDescription(deliveryMethod),
                }}
                descriptionProps={{
                  label: formatMessage('vex.screen.confirmDeliveryMethod.description.label'),
                }}
                action={{
                  label: formatMessage('widgets.deliveryMethodCard.edit'),
                  onClick: handleEditPaymentDetailsClick,
                }}
              />
            </Group>
          ),
        }}
        continueSection={{
          buttonProps: {
            label: formatMessage('activities.addBankDeliveryMethodByPayee.screens.addBankDeliveryMethod.continue'),
            onClick: handleAcceptPaymentClick,
            isLoading: isSaving,
          },
          legalInfoOverride,
        }}
      />
    );
    if (!deliveryMethod) {
      return null;
    }

    return (
      <ExternalLayout
        isLoading={isLoading}
        data-component="ShiftVirtualCardToACHActivity.ShiftToAchBankDetailsScreen"
        data-testid="shift-virtual-card-to-ach-activity-shift-to-ach-bank-details-screen"
        ref={ref}
        content={content}
        footer={footer}
        {...props}
      />
    );
  }
);

ShiftToAchBankDetailsScreen.displayName = 'ShiftVirtualCardToACHActivity.ShiftToAchBankDetailsScreen';
