/* eslint-disable react-hooks/exhaustive-deps */
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod } from '@melio/platform-api';
import { useEffect } from 'react';

import { useActivityContext } from '../../add-delivery-method-by-unilateral/AddDeliveryMethodByUnilateral.config';
import { AddBankDeliveryMethodByVendorActivity } from '../AddBankDeliveryMethodByPayee';
import { AddCheckDeliveryMethodByVendorActivity } from '../AddCheckDeliveryMethodByPayee';
import { SelectDeliveryMethodTypeScreen } from './screens';
import { AddDeliveryMethodByVendorActivityProps } from './types';
import { useAddDeliveryMethodByPayeeStep } from './useAddDeliveryMethodByVendorStep';

const ANALYTICS_PREFIX = 'RequestDM';

export const AddDeliveryMethodByVendorActivity: React.VFC<AddDeliveryMethodByVendorActivityProps> = ({
  vendorId,
  accountName,
  onError,
  onDone,
}) => {
  const { track } = useAnalytics();

  const { showPoweredByMelio, showLoginHeaderSection, emailVerificationReturnUrl } = useActivityContext();

  const { currentStep, goToStep, goToPreviousStep } = useAddDeliveryMethodByPayeeStep();

  useEffect(() => {
    track(ANALYTICS_PREFIX, 'VendorOpened', {
      vendorId,
    });
  }, []);

  const handleFail = (error: PlatformError) => {
    onError?.(error);
  };

  const handleDeliveryMethodAdded = (deliveryMethodId: DeliveryMethod['id']) => {
    track(ANALYTICS_PREFIX, 'VendorCompleted', {
      vendorId,
      deliveryMethodId,
    });
    onDone(deliveryMethodId);
  };

  switch (currentStep) {
    case 'SELECT_DELIVERY_METHOD_TYPE':
    default:
      return (
        <SelectDeliveryMethodTypeScreen
          accountName={accountName}
          onDone={(deliveryMethodType) => {
            goToStep(deliveryMethodType === 'bank-account' ? 'ADD_BANK' : 'ADD_CHECK');
          }}
          origin="vendor"
          showPoweredByMelio={showPoweredByMelio}
          showLoginHeaderSection={showLoginHeaderSection}
          emailVerificationReturnUrl={emailVerificationReturnUrl}
        />
      );
    case 'ADD_BANK':
      return (
        <AddBankDeliveryMethodByVendorActivity
          accountName={accountName}
          vendorId={vendorId}
          onDone={handleDeliveryMethodAdded}
          onBack={goToPreviousStep}
          onError={handleFail}
        />
      );
    case 'ADD_CHECK':
      return (
        <AddCheckDeliveryMethodByVendorActivity
          accountName={accountName}
          vendorId={vendorId}
          onDone={handleDeliveryMethodAdded}
          onBack={goToPreviousStep}
          onError={handleFail}
        />
      );
  }
};
