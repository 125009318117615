import { NakedButton, NakedButtonProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const NakedNextButton = forwardRef<NakedButtonProps, 'button'>((props, ref) => (
  <NakedButton
    data-component="NewSinglePaymentStepLayout.NakedNextButton"
    data-testid="layout-next-button"
    tabIndex={0}
    size="large"
    {...props}
    ref={ref}
  />
));

NakedNextButton.displayName = 'NewSinglePaymentStepLayout.NakedNextButton';
