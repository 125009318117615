import { Container, ContainerProps, Text, TextProps, useBreakpointValue } from '@melio/penny';

type DescriptionProps = TextProps & Pick<ContainerProps, 'textAlign' | 'paddingTop' | 'paddingBottom'>;

export const Description = ({ children, as, textStyle, ...props }: DescriptionProps) => {
  const textAlign = useBreakpointValue<ContainerProps['textAlign']>({ xs: 'start', s: 'center' });

  return (
    <Container textAlign={textAlign} {...props}>
      <Text
        as={as ?? 'h2'}
        data-component="NewSinglePaymentStepLayout.Description"
        data-testid="layout-description"
        textStyle={textStyle}
      >
        {children}
      </Text>
    </Container>
  );
};

Description.displayName = 'NewSinglePaymentStepLayout.Description';
