import { useTbt } from '@melio/form-controls';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  CardHolderDetails,
  CardParams,
  DebitDeliveryMethod,
  DeliveryMethodType,
  useVexGuestDeliveryMethod,
} from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';
import { TBTFormWidgetFields } from '@melio/vex-widgets';
import { useState } from 'react';

import { ANALYTICS_CONTEXT } from './consts';

export const useP2DUpgrade = () => {
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { bt, tokenize } = useTbt();
  const [cardParams, setCardParams] = useState<CardParams>();
  const [isCardTokenizingInProgress, cardTokenizingInProgress] = useBoolean(false);
  const { mutateAsync: createVendorDeliveryMethod, isLoading: isCreatingCardDeliveryMethod } =
    useVexGuestDeliveryMethod();

  const handleFail = (error: PlatformError) => {
    track(ANALYTICS_CONTEXT, 'Status', {
      StatusType: 'Failure',
      DeliveryMethodChosen: 'card',
      PageName: 'get-paid-faster',
      ErrorType: error.message,
    });
    toast({ type: 'error', title: error.message });
  };

  const handleTokenizeCardDetails = async (cardDetails: TBTFormWidgetFields) => {
    try {
      setCardParams(undefined);

      if (!bt) {
        throw new Error('Card service is not initialized');
      }

      cardTokenizingInProgress.on();

      const cardParams = await tokenize(cardDetails);

      setCardParams(cardParams);
    } catch (error) {
      handleFail(error as PlatformError);
    } finally {
      cardTokenizingInProgress.off();
    }
  };

  const handleCreateCardDeliveryMethod = async (cardHolderData: CardHolderDetails) => {
    try {
      if (!cardParams) {
        throw new Error('No card details');
      }

      const cardDeliveryMethodDetails = {
        cardBin: cardParams.cardBin,
        expirationMonth: cardParams.expirationMonth,
        expirationYear: cardParams.expirationYear,
        lastFourDigits: cardParams.lastFourDigits,
        tokenProvider: cardParams.tokenProvider,
        tabapayToken: cardParams.tabapayToken,
        cardOwner: {
          firstName: cardHolderData.firstName,
          lastName: cardHolderData.lastName,
        },
        address: {
          line1: cardHolderData.line1,
          city: cardHolderData.city,
          state: cardHolderData.state,
          postalCode: cardHolderData.postalCode,
        },
      };

      const cardDeliveryMethod = await createVendorDeliveryMethod({
        type: DeliveryMethodType.Card,
        details: cardDeliveryMethodDetails,
      });

      return cardDeliveryMethod.data as DebitDeliveryMethod;
    } catch (error) {
      handleFail(error as PlatformError);

      return null;
    }
  };

  return {
    handleTokenizeCardDetails,
    handleCreateCardDeliveryMethod,
    isCardTokenizingInProgress,
    isCreatingCardDeliveryMethod,
    cardParams,
  };
};
