import { createContext, useContext } from 'react';

import { CreateConfig } from '../../hooks/useActivityConfigData';

export type ActivityConfig = {
  showLoginHeaderSection: boolean;
  shouldSuggestJoinMelio: boolean;
};

export const createActivityConfig: CreateConfig<ActivityConfig> = (appConfig) => ({
  showLoginHeaderSection: appConfig.vex.unilateral.isEmailLoginEnabled ?? false,
  shouldSuggestJoinMelio: appConfig.vex.unilateral.shouldSuggestJoinMelio ?? false,
});

export const UnilateralConfigContext = createContext<ActivityConfig>({} as ActivityConfig);
export const useActivityContext = () => useContext<ActivityConfig>(UnilateralConfigContext);
