import { useState } from 'react';

import { AddDeliveryMethodByPayeeStep } from './types';

export const useAddDeliveryMethodByPayeeStep = () => {
  const [currentStep, goToStep] = useState<AddDeliveryMethodByPayeeStep>('SELECT_DELIVERY_METHOD_TYPE');

  const goToPreviousStep = () => {
    goToStep('SELECT_DELIVERY_METHOD_TYPE');
  };

  return { currentStep, goToStep, goToPreviousStep };
};
