import { useLocation } from '@melio/platform-utils';

/**
 * This hook returns the path to a router in the Vex router
 * @param pathInVexRouter
 */
export const usePathInVexRouter = ({ pathInVexRouter }: { pathInVexRouter: string }) => {
  const { pathname } = useLocation();

  const vexRouter = pathname.slice(0, pathname.indexOf('/vex') + 4);

  return `${vexRouter}/${pathInVexRouter}`;
};
