import { Button, Form, Group, SectionBanner, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { PersonalDetailsFormFields } from '../../types';
import { usePersonalDetailsValidationSchema } from './hooks/usePersonalDetailsValidationSchema';
import { PersonalDetailsFormBaseProps } from './types';

export const PersonalDetailsFormWidget = forwardRef<
  PersonalDetailsFormBaseProps & {
    setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
    onValidationError?: (isRequiredError: boolean) => void;
  },
  'form'
>(
  (
    { onSubmit, defaultValues: _defaultValues, isSaving, isDisabled, setDirtyStatus, onValidationError, ...props },
    ref
  ) => {
    const defaultValues = defaults(_defaultValues, {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
      phone: '',
    });

    const [isPersonalDetailsError, setIsPersonalDetailsError] = useState(false);

    const onSubmitPersonalDetails = async (formValues: PersonalDetailsFormFields) => {
      try {
        setIsPersonalDetailsError(false);
        await onSubmit(formValues);
      } catch (error) {
        setIsPersonalDetailsError(true);
      }
    };

    const {
      formProps,
      registerField,
      submitButtonProps,
      trigger,
      formState: { isDirty, isValid, errors },
    } = useMelioForm<PersonalDetailsFormFields>({
      onSubmit: onSubmitPersonalDetails,
      schema: usePersonalDetailsValidationSchema(),
      defaultValues,
      isSaving,
    });

    const { formatMessage } = useMelioIntl();

    useEffect(() => {
      if (onValidationError && !isEmpty(errors)) {
        const isRequiredError = !!Object.values(errors).some((error) => error?.type === 'required');
        onValidationError(isRequiredError);
      }
    }, [errors, onValidationError]);

    useEffect(() => {
      if (setDirtyStatus) {
        const { onClick } = submitButtonProps;
        setDirtyStatus(isDirty && !isValid, onClick);
      }
    }, [isDirty, isValid, trigger, setDirtyStatus, submitButtonProps]);

    return (
      <Group spacing="s-m" variant="vertical" width="full">
        {isPersonalDetailsError && (
          <SectionBanner
            data-testid="personal-details-form-error-banner"
            variant="critical"
            description={formatMessage('vex.widgets.vendorOnboarding.personalDetails.errorBanner.description')}
          />
        )}
        <Form
          size="small"
          {...formProps}
          columns={2}
          ref={ref}
          data-testid="PersonalDetailsFormWidget"
          data-component="PersonalDetailsFormWidget"
          {...props}
        >
          <Form.TextField
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.personalDetails.firstName.label') }}
            {...registerField('firstName')}
          />
          <Form.TextField
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.personalDetails.lastName.label') }}
            {...registerField('lastName')}
          />
          <Form.DateField
            excludeHolidays={false}
            weekDays={[0, 1, 2, 3, 4, 5, 6]}
            format="MM/dd/yyyy"
            colSpan={2}
            helperTextProps={{
              label: formatMessage('vex.widgets.vendorOnboarding.personalDetails.dateOfBirth.helperText'),
            }}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.personalDetails.dateOfBirth.label') }}
            placeholder={formatMessage('vex.widgets.vendorOnboarding.personalDetails.dateOfBirth.placeholder')}
            {...registerField('dateOfBirth')}
          />
          <Form.PhoneField
            colSpan={2}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.personalDetails.phoneNumber.label') }}
            {...registerField('phoneNumber')}
          />
        </Form>
        <Group justifyContent="flex-end">
          <Button
            size="medium"
            variant="primary"
            {...submitButtonProps}
            label={formatMessage('vex.widgets.vendorOnboarding.personalDetails.submit')}
            data-testid="personal-details-submit-button"
          />
        </Group>
      </Group>
    );
  }
);

PersonalDetailsFormWidget.displayName = 'PersonalDetailsFormWidget';
