import { useParams } from 'react-router-dom';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { CollectW9Activity } from '@melio/vex-activities';
import { BaseErrorScreen } from '@melio/vex-activities/src/components/base-screens';

export const CollectW9Route = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { vendorId } = useParams<{ vendorId: string }>();

  setAnalyticsProperties({ vendorId, pageEntryPoint: 'collect-w9' });
  const trackBaseError = {
    name: 'collect-w9-error',
  };
  const GeneralServerErrorScreen = (
    <BaseErrorScreen trackBaseError={trackBaseError} description={formatMessage('vex.generalServerError')} />
  );

  if (!vendorId) return GeneralServerErrorScreen;

  return <CollectW9Activity vendorId={vendorId} />;
});

CollectW9Route.displayName = 'CollectW9';
