/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { head, keys, last, mapValues, omit, take } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from '../hooks';
import {
  ExternalNavRecovery,
  GoNextMap,
  LocationState,
  NavigationMap,
  OnComplete,
  PersistedState,
  UseWizardArgs,
  UseWizardReturnType,
} from './types';
import {
  buildTargetUrl,
  getCurrentStepIndex,
  getLog,
  getPersistedState,
  getQueryExternalNavRecoveryTarget,
} from './useWizardUtils';

export const APP_INIT_URL_KEY = 'appInitUrl';

export const useWizard = <Steps extends string, NM extends NavigationMap<Steps>>({
  firstStep,
  locationsMap,
  flowName,
  cancelUrlFallback,
  navigationMap,
  verbose,
  enableMidFlowEntry,
}: UseWizardArgs<Steps>): UseWizardReturnType<Steps, NM> => {
  const log = getLog(!!verbose);
  const navigate = useNavigate<LocationState>();
  const location = useLocation<LocationState>();
  const [onCompleteState, setOnCompleteState] = useState<OnComplete | null>(null);
  const [startedCancelFlowAction, setStartedCancelFlowAction] = useState<boolean>(false);

  const [flowHistory, setFlowHistory] = useState<Steps[]>([]);
  const [isFirstPageInApp, setIsFirstPageInApp] = useState<boolean>(false);

  const stepFromUrl = keys(locationsMap).find((step) => {
    const stepUrl = locationsMap[step as Steps];

    return location.pathname.includes(stepUrl);
  }) as Steps | undefined;
  const stepFromUrlLocation = stepFromUrl ? locationsMap[stepFromUrl] : '';

  const isFirstStepLocation = stepFromUrl === firstStep;
  const currentStepIndex = getCurrentStepIndex(location, flowName);

  const applyOnComplete = () => {
    if (!onCompleteState) {
      return;
    }

    window.sessionStorage.removeItem(flowName);
    log('Apply onComplete');

    if (typeof onCompleteState === 'string') {
      navigate(onCompleteState, { replace: true, state: omit(location.state, [flowName]) });
    } else {
      onCompleteState();
    }
  };

  useEffect(() => {
    const initialAppUrl = window.sessionStorage.getItem(APP_INIT_URL_KEY);
    const isFirstPage = !initialAppUrl || initialAppUrl === window.location.pathname;
    setIsFirstPageInApp(isFirstPage);
    log('wizard mounted', {
      flowName,
      isFirstPageInApp: isFirstPage,
    });
  }, []);

  useEffect(
    function persistFlowHistoryToSessionStorage() {
      if (getExternalNavRecoveryTarget()) {
        return;
      }

      window.sessionStorage.setItem(flowName, JSON.stringify({ flowHistory, historyLength: history.length }));
    },
    [flowHistory]
  );

  const goBackToFirstStep = () => {
    if (currentStepIndex > 0) {
      navigate(-1 * currentStepIndex);
    }
    resetToFirstStep();
  };

  const resetToFirstStep = () => {
    setFlowHistory([firstStep]);
    const targetLocation = buildTargetUrl(stepFromUrlLocation, locationsMap[firstStep], location.pathname);
    navigate(targetLocation, {
      replace: true,
      state: {
        ...location.state,
        [flowName]: { stepIndex: 0 },
      },
    });
  };

  const resetToCurrentStep = () => {
    if (!stepFromUrl) {
      return resetToFirstStep();
    }

    setFlowHistory([stepFromUrl]);
    const targetLocation = buildTargetUrl(stepFromUrlLocation, locationsMap[stepFromUrl], location.pathname);
    navigate(targetLocation, {
      replace: true,
      state: {
        ...location.state,
        [flowName]: { stepIndex: 0 },
      },
    });
  };

  const resetNavigation = () => {
    if (enableMidFlowEntry) {
      resetToCurrentStep();
    } else {
      resetToFirstStep();
    }
  };

  const getExternalNavRecoveryTarget = (): ExternalNavRecovery<Steps> | null => {
    const externalNavRecoveryRes = getQueryExternalNavRecoveryTarget(location, locationsMap);
    const storedExternalNavRecovery = getPersistedState<Steps>(flowName, resetNavigation)?.externalNavRecovery;

    if (externalNavRecoveryRes?.recoveryTarget && storedExternalNavRecovery?.recoveryTarget) {
      log('Found both search and session recovery targets, skipping recovery');

      return null;
    }

    if (externalNavRecoveryRes?.recoveryTarget) {
      log('found recoveryTarget in search param', {
        recoveryTarget: externalNavRecoveryRes.recoveryTarget,
        shouldReplace: externalNavRecoveryRes.shouldReplace,
      });

      return externalNavRecoveryRes;
    }

    if (storedExternalNavRecovery?.recoveryTarget) {
      log('found recoveryTarget in session storage', {
        recoveryTarget: storedExternalNavRecovery.recoveryTarget,
        shouldReplace: storedExternalNavRecovery.shouldReplace,
      });

      return storedExternalNavRecovery;
    }

    return null;
  };

  const returnToLastControlledStep = (
    persistedState: PersistedState<Steps>,
    recoveryTarget: Steps,
    shouldReplace: string
  ) => {
    const numStepsToGoBack = history.length - persistedState.historyLength;
    log('returning to last controlled step', {
      persistedLength: persistedState.historyLength,
      recoveryTarget,
      numStepsToGoBack,
      stepFromUrl,
    });
    window.sessionStorage.setItem(
      flowName,
      JSON.stringify({ ...persistedState, externalNavRecovery: { recoveryTarget, shouldReplace } })
    );
    navigate(-1 * numStepsToGoBack);
  };

  const completeExternalNavRecovery = (
    persistedState: PersistedState<Steps>,
    recoveryTarget: Steps,
    stepFromUrlArg: Steps,
    shouldReplace: string
  ) => {
    log('complete external nav recovery', {
      persistedState,
      recoveryTarget,
      stepFromUrlArg,
      shouldReplace,
    });
    window.sessionStorage.setItem(flowName, JSON.stringify({ ...persistedState, externalNavRecovery: null }));

    const nextStepLocation = buildTargetUrl(
      locationsMap[stepFromUrlArg],
      locationsMap[recoveryTarget],
      location.pathname
    );

    const flowHistoryToSet = [...take(persistedState.flowHistory, currentStepIndex + 1), recoveryTarget];
    setFlowHistory(flowHistoryToSet);

    if (shouldReplace === 'true') {
      log('Replacing url after external nav', {
        recoveryTarget,
        shouldReplace,
      });
      navigate(nextStepLocation, {
        replace: true,
        state: {
          ...location.state,
          [flowName]: { stepIndex: currentStepIndex },
        },
      });
    } else {
      navigate(nextStepLocation, {
        state: {
          ...location.state,
          [flowName]: { stepIndex: currentStepIndex + 1 },
        },
      });
    }
  };

  const handleExternalNavRecoveryFlow = (externalNavRecovery: ExternalNavRecovery<Steps>) => {
    const { recoveryTarget, shouldReplace } = externalNavRecovery;
    const externalNavRecoveryQueryParam = getQueryExternalNavRecoveryTarget(location, locationsMap);
    const persistedState = getPersistedState<Steps>(flowName, resetToFirstStep);

    if (!persistedState || !stepFromUrl || !recoveryTarget) {
      return;
    }

    log('Handle external nav recovery', {
      recoveryTarget,
      stepFromUrl,
      persistedState,
    });

    if (last(persistedState.flowHistory) !== stepFromUrl || externalNavRecoveryQueryParam) {
      returnToLastControlledStep(persistedState, recoveryTarget, shouldReplace);
    } else if (stepFromUrl !== recoveryTarget) {
      completeExternalNavRecovery(persistedState, recoveryTarget, stepFromUrl, shouldReplace);
    }
  };

  useEffect(
    function handleInvalidStepsOrder() {
      const externalNavRecovery = getExternalNavRecoveryTarget();

      if (externalNavRecovery) {
        handleExternalNavRecoveryFlow(externalNavRecovery);

        return;
      }

      const missingLocationState = currentStepIndex < 0;
      const noMatchingStep = !stepFromUrl;

      if (onCompleteState || startedCancelFlowAction) {
        return;
      }

      if (noMatchingStep || missingLocationState || (head(flowHistory) !== firstStep && !enableMidFlowEntry)) {
        if (currentStepIndex > 0) {
          goBackToFirstStep();
        } else {
          resetNavigation();
        }
      } else if (currentStepIndex > flowHistory.length) {
        cancelFlow();
      }
    },
    [currentStepIndex, onCompleteState, stepFromUrl, firstStep, flowHistory]
  );

  const cancelFlow = useCallback(() => {
    setStartedCancelFlowAction(true);
  }, []);

  useEffect(
    function cancelFlowEffect() {
      if (!startedCancelFlowAction) {
        return;
      }

      window.sessionStorage.removeItem(flowName);

      if (isFirstPageInApp) {
        if (isFirstStepLocation) {
          navigate(cancelUrlFallback, { replace: true, state: omit(location.state, [flowName]) });
        } else {
          navigate(-1 * currentStepIndex);
        }
      } else {
        const stepsFromEntryPage = currentStepIndex + 1;
        navigate(-1 * stepsFromEntryPage);
      }
    },
    [startedCancelFlowAction, isFirstPageInApp, isFirstStepLocation]
  );

  const completeFlow = useCallback(
    (onComplete: OnComplete) => {
      if (currentStepIndex < 0) {
        throw new Error(`tried to complete flow ${flowName} without currentStepIndex`);
      }

      log('completeFlow called');
      setOnCompleteState(onComplete);
    },
    [location, flowName, currentStepIndex]
  );

  useEffect(
    function completeFlowEffect() {
      if (!onCompleteState) {
        return;
      }

      if (isFirstStepLocation || currentStepIndex === 0) {
        applyOnComplete();
      } else {
        log('Complete navigating back to entry page', {
          stepsCount: -1 * currentStepIndex,
        });

        navigate(-1 * currentStepIndex);
      }
    },
    [onCompleteState, isFirstStepLocation, currentStepIndex]
  );

  const goToStep = useCallback(
    (nextStep: Steps, shouldReplace?: boolean) => {
      const persistedState = getPersistedState<Steps>(flowName, resetToFirstStep);
      const lastPersistedStep = last(persistedState?.flowHistory);
      const currentStep = persistedState?.flowHistory && persistedState.flowHistory[currentStepIndex];

      if (!stepFromUrlLocation || currentStepIndex < 0 || currentStep === nextStep) {
        return;
      }

      log('navigating to step', {
        nextStep,
        shouldReplace,
        lastPersistedStep,
        currentStep,
        currentStepIndex,
      });

      const nextStepLocation = buildTargetUrl(stepFromUrlLocation, locationsMap[nextStep], location.pathname);

      if (shouldReplace === true) {
        setFlowHistory((flowHistory) => [...take(flowHistory, currentStepIndex), nextStep]);
        navigate(nextStepLocation, {
          replace: true,
          relative: 'path',
          state: {
            ...location.state,
            [flowName]: { stepIndex: currentStepIndex },
          },
        });
      } else {
        setFlowHistory((flowHistory) => [...take(flowHistory, currentStepIndex + 1), nextStep]);
        navigate(nextStepLocation, {
          relative: 'path',
          state: {
            ...location.state,
            [flowName]: { stepIndex: currentStepIndex + 1 },
          },
        });
      }
    },
    [currentStepIndex, stepFromUrlLocation, history, flowName, locationsMap, location.pathname, location.state]
  );

  const goNextMap = useMemo(
    () =>
      mapValues(
        navigationMap,
        <K extends Steps>(nextStepCallback: NM[K]) =>
          (goNextArgs?: { navArgs: Parameters<NM[K]>; shouldReplace?: boolean }) => {
            const navArgs = (goNextArgs?.navArgs || []) as [];
            const nextStep = nextStepCallback(...navArgs);

            if (nextStep) {
              goToStep(nextStep, goNextArgs?.shouldReplace);
            }
          }
      ) as unknown as GoNextMap<Steps, typeof navigationMap>,
    [navigationMap, goToStep]
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [history]);

  const goBackToStep = useCallback(
    (targetStep: Steps) => {
      const stepIndex = flowHistory.findIndex((step) => step === targetStep);

      if (!locationsMap[targetStep]) {
        throw new Error(
          `tried to go back to a step that is not mapped to a location - ${targetStep}. Flow name: ${flowName}`
        );
      }

      if (stepIndex < 0) {
        throw new Error(`Can not go back to a step that was not visited - ${targetStep}. Flow name: ${flowName}`);
      }

      const stepsToGoBack = currentStepIndex - stepIndex;
      navigate(-1 * stepsToGoBack);
    },
    [currentStepIndex, flowHistory, flowName, locationsMap, history]
  );

  const getExtNavReturnUrl = useCallback(
    (targetStep: Steps, shouldReplace?: boolean) => {
      if (!stepFromUrlLocation) {
        throw new Error(`Can't create return url while no matching step for current location`);
      }

      const resultPath = buildTargetUrl(stepFromUrlLocation, locationsMap[targetStep], location.pathname);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const search = `queryExternalNavRecoveryStep=${targetStep}&shouldReplace=${!!shouldReplace}`;
      const result = `${resultPath}?${search}`;

      log('created ext nav return url', {
        url: result,
        shouldReplace,
      });

      return result;
    },
    [stepFromUrlLocation, locationsMap, location.pathname]
  );

  return {
    completeFlow,
    cancelFlow,
    currentStep: stepFromUrl || firstStep,
    goNextMap,
    goBack,
    goBackToStep,
    getExtNavReturnUrl,
  };
};
