import React from 'react';
import { LazyAnimation } from '@melio/platform-utils';
export const PaymentRequestLink: React.VFC = () => (
  <LazyAnimation
    getAnimationData={() => import('./assets/payment-request-link.lottie')}
    id="payment-request-link"
    width="100%"
    height="100%"
  />
);
