import { Card, Container, ExternalLayout, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { FormattedMessage } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { FooterWidget } from '@melio/vex-widgets';

type PaymentCanceledScreenProps = {
  accountName: string;
  accountEmail: string;
};

export const PaymentCanceledScreen = forwardRef<PaymentCanceledScreenProps, 'div'>(
  ({ accountName, accountEmail, children, ...props }, ref) => {
    useAnalyticsView('PaymentCanceledScreen');

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="s">
              <StatusIconSolid variant="cancel" size="large" />
              <Text>
                <FormattedMessage
                  id="vex.screen.paymentCanceled.content"
                  values={{
                    accountName,
                    accountEmail,
                  }}
                />
              </Text>
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget displayTrustedPartners={false} />;

    return (
      <ExternalLayout
        data-component="PaymentCanceledScreen"
        data-testid="payment-canceled-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

PaymentCanceledScreen.displayName = 'PaymentCanceledScreen';
