import { Group, StatusIconSolid, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type AcceptPaymentSuccessHeaderWidgetProps = {
  title?: string;
  description?: string;
};

export const AcceptPaymentSuccessHeaderWidget = forwardRef<AcceptPaymentSuccessHeaderWidgetProps>(
  ({ title, description }, ref) => (
    <Group variant="vertical" spacing="s" ref={ref}>
      <StatusIconSolid variant="success" size="large" />
      <Text textStyle="body2Semi">{title}</Text>
      <Text textStyle="body2">{description}</Text>
    </Group>
  )
);

AcceptPaymentSuccessHeaderWidget.displayName = 'AcceptPaymentSuccessHeaderWidget';
