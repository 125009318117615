import { ExternalLayout, useToast } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  DeliveryMethod,
  DeliveryMethodType,
  useGetShiftPaymentDeliveryMethod,
  usePostShiftVirtualCardToAch,
  useVexGuestDeliveryMethod,
  useVirtualCardAcceptance,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { WithActivityConfig } from '../../contexts/ActivityConfig';
import { BaseSuccessScreen } from '../base-screens';
import { ShiftToAchBankDetailsScreen, ShiftToAchInsertDetailsScreen } from './screens';
import {
  createActivityConfig,
  ShiftVirtualCardToACHActivityConfigContext,
  useActivityContext,
} from './ShiftVirtualCardToACH.config';

type ShiftVirtualCardToACHActivityProps = {
  paymentId: string;
};

export const Activity: React.VFC<ShiftVirtualCardToACHActivityProps> = ({ paymentId }) => {
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { showPoweredByMelio } = useActivityContext();
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod | undefined>();
  const [isEdit, setIsEdit] = useState(false);

  const trackSuccessScreen = {
    name: 'shift-suvc-to-ach-confirm',
  };

  // suvc data
  const { isLoading: suvcIsLoading, data: suvcData, error: suvcError } = useVirtualCardAcceptance(paymentId);

  // delivery method data
  const {
    data: [deliveryMethodData] = [],
    error: dmError,
    isLoading: isLoadingDms,
  } = useGetShiftPaymentDeliveryMethod(paymentId);

  // create delivery method
  const {
    mutateAsync: createACHDM,
    error: createDMError,
    isSuccess: isCreateDMSuccess,
    isLoading: isCreateDMLoading,
    data: createDMData,
  } = useVexGuestDeliveryMethod();

  // shift to ach
  const {
    isLoading: isLoadingShiftToACH,
    error: acceptPaymentError,
    mutate: acceptPayment,
    isSuccess: acceptPaymentSuccess,
  } = usePostShiftVirtualCardToAch(paymentId);

  useEffect(() => {
    if (deliveryMethodData && !isEdit) {
      setDeliveryMethod(deliveryMethodData);
      navigate('./ach-details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryMethodData]);

  useEffect(() => {
    if (createDMData) {
      setIsEdit(false);
      setDeliveryMethod(createDMData.data as BankAccountDeliveryMethod);
      navigate('./ach-details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateDMSuccess, createDMData]);

  useEffect(() => {
    const error = dmError || acceptPaymentError || suvcError || createDMError;
    if (error) {
      toast({ type: 'error', title: error.message });
    }
    return;
  }, [dmError, acceptPaymentError, toast, suvcError, createDMError]);

  const onBankAccountSubmit = ({ accountNumber, routingNumber }: { accountNumber: string; routingNumber: string }) => {
    createACHDM({
      type: DeliveryMethodType.BankAccount,
      details: {
        accountNumber,
        routingNumber,
        accountType: 'checking',
      },
    }).catch((error) => {
      if (error instanceof Error) {
        toast({ type: 'error', title: error.message });
      }
    });
  };

  const onEditDeliveryMethod = () => {
    setIsEdit(true);
    navigate('./ach-insert-details');
  };
  const confirmPayment = () => {
    acceptPayment({ deliveryMethodId: deliveryMethod?.id as string });
  };

  if (isLoadingDms || suvcIsLoading) {
    return <ExternalLayout isLoading content={<></>} />;
  }

  if (!suvcData) {
    return <Navigate to="./error" />;
  }

  const { payment, organization, ...virtualCard } = suvcData.data;

  if (virtualCard.isShiftedToBankAccount) {
    return (
      <BaseSuccessScreen
        title={formatMessage('vex.screen.suvcAcceptance.shiftAlreadyRequested.title')}
        description={formatMessage('vex.screen.suvcAcceptance.shiftAlreadyRequested.description', {
          paymentEta: payment.deliveryEta?.toDateString(),
        })}
        paymentDetailsTitle={formatMessage('vex.screen.suvcAcceptance.paymentCleared.paymentDetailsTitle')}
        deliveryMethod={virtualCard.deliveryMethod as BankAccountDeliveryMethod}
        estimatedDelivery={payment.deliveryEta as Date}
        maxEstimatedDelivery={payment.maxDeliveryEta as Date}
        trackSuccessScreen={trackSuccessScreen}
      />
    );
  }

  if (acceptPaymentSuccess) {
    return (
      <BaseSuccessScreen
        title={formatMessage('widgets.suvcAcceptanceSettings.successShiftToACHTitle', {
          amount: virtualCard.amount,
        })}
        description={formatMessage('widgets.suvcAcceptanceSettings.successShiftToACHDescription', {
          payorCompanyName: organization.companyName,
          invoiceNumber: payment.invoiceNumber,
        })}
        paymentDetailsTitle={formatMessage('widgets.suvcAcceptanceSettings.successShiftToACHSectionTitle')}
        deliveryMethod={deliveryMethod as BankAccountDeliveryMethod}
        estimatedDelivery={payment.deliveryEta as Date}
        maxEstimatedDelivery={payment.deliveryEta as Date}
        trackSuccessScreen={trackSuccessScreen}
      />
    );
  }

  return (
    <Routes>
      <Route index element={<ShiftToAchNavigator deliveryMethod={deliveryMethod} />} />
      <Route
        path="ach-details"
        element={
          <ShiftToAchBankDetailsScreen
            accountName={organization.companyName}
            onDone={confirmPayment}
            paymentNote={payment.note}
            paymentAmount={Number(virtualCard.amount)}
            paymentInvoiceNumber={payment.invoiceNumber}
            showPoweredByMelio={showPoweredByMelio}
            onEdit={onEditDeliveryMethod}
            deliveryMethod={deliveryMethod as DeliveryMethod}
            isSaving={isLoadingShiftToACH}
          />
        }
      />
      <Route
        path="ach-insert-details"
        element={
          <ShiftToAchInsertDetailsScreen
            accountName={organization.companyName}
            onDone={onBankAccountSubmit}
            paymentNote={payment?.note}
            paymentAmount={Number(virtualCard.amount)}
            paymentInvoiceNumber={payment?.invoiceNumber}
            showPoweredByMelio={showPoweredByMelio}
            isSaving={isCreateDMLoading}
          />
        }
      />
    </Routes>
  );
};

const ShiftToAchNavigator: React.VFC<{ deliveryMethod: DeliveryMethod | undefined }> = ({ deliveryMethod }) => {
  if (!deliveryMethod) {
    return <Navigate to="./ach-insert-details" />;
  } else {
    return <Navigate to="./ach-details" />;
  }
};

export const ShiftVirtualCardToACHActivity: React.VFC<ShiftVirtualCardToACHActivityProps> = (props) => (
  <WithActivityConfig Context={ShiftVirtualCardToACHActivityConfigContext} createConfig={createActivityConfig}>
    <Activity {...props} />
  </WithActivityConfig>
);
