import { apiClient, Payment, PaymentFee, PaymentFeeCalculationParameters } from '@melio/javascript-sdk';

import { useCollectionApi, UseCollectionApiProps } from '../../core';
import { PaymentFeesCollection } from './types';
import { convertPaymentFeeCentsToDollars } from './utils';

type UsePaymentFeesProps = UseCollectionApiProps<PaymentFee> & {
  paymentId: Payment['id'];
  params: PaymentFeeCalculationParameters;
};

export const usePaymentFees = ({ paymentId, ...props }: UsePaymentFeesProps): PaymentFeesCollection => {
  const client = apiClient.paymentFees(paymentId);

  const { data, ...query } = useCollectionApi({
    ...props,
    queryKey: ['payment-fee', paymentId, props.params],
    queryFn: () => client.fetch(props.params),
  });

  return {
    ...query,
    data: data?.map(convertPaymentFeeCentsToDollars),
  };
};
