import { expiredSessionIcon } from '@/cl/icons/capone/expiredSession.icon';
import { MelioIcon } from '@/cl/icons/system';
import Favicon from '@/images/capone/favicon.svg';
import { PartnerIcons } from '@/partnersConfig.types';
import { AddBillIcon } from '../default/addBill.icon';
import { Tax1099Icon } from '../default/taxSummary.icon';
import { backIcon } from './back.icon';
import { CapOneIcon } from './capOne.icon';
import { CapOneErrorIcon } from './capOneError.icon';
import { EmptyBillsIcon } from './emptyBills.icon';
import { EmptyVendorsIcon } from './emptyVendors.icon';
import { NoBillFoundIcon } from './noBillFound.icon';
import { NoVendorFoundIcon } from './noVendorFound.icon';
import { StartNewExperienceIcon } from './StartNewExperienceIcon.icon';
import { TryDifferentEmailIcon } from './TryDifferentEmail.icon';
import { VerifyEmailIcon } from './verifyEmail.icon';

export const CapOneIcons: PartnerIcons = {
  Favicon,
  MelioIcon,
  LogoIcon: CapOneIcon,
  ErrorIcon: CapOneErrorIcon,
  EmptyBillsIcon: EmptyBillsIcon,
  EmptyVendorsIcon: EmptyVendorsIcon,
  NoBillsFoundIcon: NoBillFoundIcon,
  NoVendorsFoundIcon: NoVendorFoundIcon,
  VerifyEmailIcon: VerifyEmailIcon,
  TaxSummaryIcon: Tax1099Icon,
  ExpiredSessionIcon: expiredSessionIcon,
  BackToPartnerIcon: backIcon,
  StartNewExperienceIcon: StartNewExperienceIcon,
  TryDifferentEmailIcon: TryDifferentEmailIcon,
  AddBillIcon: AddBillIcon,
};
