import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';

export const Actions = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-component="NewSinglePaymentStepLayout.Actions"
    display="flex"
    justifyContent="center"
    width="100%"
    marginX="auto"
    marginTop={{ xs: 'm', s: 'm', m: 'none' }}
    paddingX={{ xs: 's', s: 's', m: 'none' }}
    paddingY={{ xs: 's', s: 's', m: 'none' }}
    borderTop={{ xs: 'surface.default', s: 'surface.default', m: 'none' }}
    {...props}
    ref={ref}
  />
));

Actions.displayName = 'NewSinglePaymentStepLayout.Actions';
