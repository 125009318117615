import { useDisclosure } from '@chakra-ui/react';
import { Card, Container, ExternalLayout, Group, SelectionCard, Text, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethodType, PaymentUnilateralDeliveryMethodTypeOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { FooterWidget } from '@melio/vex-widgets';
import { partial } from 'lodash';
import { ComponentProps, ReactNode } from 'react';

import { SUVCInformationModalScreen } from '../../../../shared/screens/suvc-information-modal';
import { getDeliveryMethodToSelectionCardPropsMap } from './getDeliveryMethodToSelectionCardPropsMap';

export type DeliveryMethodSelectionScreenProps = {
  onDMselection: (dmType: DeliveryMethodType) => void;
  contentHeaderSection: ReactNode;
  header: ComponentProps<typeof ExternalLayout>['header'];
  orderedDeliveryMethodTypeOptions: PaymentUnilateralDeliveryMethodTypeOption[];
};

const NON_DIGITAL_DELIVERY_METHODS: DeliveryMethodType[] = [DeliveryMethodType.PaperCheck];

export const DeliveryMethodSelectionScreen = forwardRef<DeliveryMethodSelectionScreenProps, 'div'>(
  ({ onDMselection, contentHeaderSection, orderedDeliveryMethodTypeOptions, header, children, ...props }, ref) => {
    usePageView('DeliveryMethod', {
      PageName: 'choose-how-to-get-paid',
      Intent: 'choose-delivery-method-type',
      DeliveryMethodShown: orderedDeliveryMethodTypeOptions.map((option) => option.deliveryMethodType).join('_'),
    });
    const { track } = useAnalytics();

    const deliveryMethodClickTrack = partial(track, 'DeliveryMethod', 'Click');
    const { onClose: onSUVCModalClose, onOpen: onSUVCModalOpen, isOpen: isSUVCModalOpen } = useDisclosure();

    const openSUVCModal = () => {
      deliveryMethodClickTrack({
        Intent: 'delivery-method-info',
        DeliveryMethodType: DeliveryMethodType.VirtualCard,
        Cta: 'View benefits',
      });
      onSUVCModalOpen();
    };

    const { formatMessage } = useMelioIntl();

    const deliveryMethodSelectionCardConfigs = getDeliveryMethodToSelectionCardPropsMap({
      openSUVCModal,
      formatMessage,
      onDMselection,
    });

    const renderDeliveryMethodSelectionCard = (deliveryMethodType: DeliveryMethodType) => {
      const deliveryMethodSelectionCardProps = deliveryMethodSelectionCardConfigs[deliveryMethodType];

      return deliveryMethodSelectionCardProps ? (
        <SelectionCard
          key={deliveryMethodType}
          data-testid={`${deliveryMethodType}-selection-card`}
          {...deliveryMethodSelectionCardProps}
        />
      ) : null;
    };

    const renderDigitalDeliveryMethodsSelectionCards = () => {
      const digitalDeliveryMethods = orderedDeliveryMethodTypeOptions.filter(
        ({ deliveryMethodType }) => !NON_DIGITAL_DELIVERY_METHODS.includes(deliveryMethodType)
      );
      return digitalDeliveryMethods.map(({ deliveryMethodType }) =>
        renderDeliveryMethodSelectionCard(deliveryMethodType)
      );
    };

    const renderNonDigitalDeliveryMethodsSelectionCards = () => {
      const nonDigitalDeliveryMethods = orderedDeliveryMethodTypeOptions.filter(({ deliveryMethodType }) =>
        NON_DIGITAL_DELIVERY_METHODS.includes(deliveryMethodType)
      );
      return nonDigitalDeliveryMethods.map(({ deliveryMethodType }) =>
        renderDeliveryMethodSelectionCard(deliveryMethodType)
      );
    };

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            {contentHeaderSection}
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="xl">
              <Group variant="vertical" spacing="xs">
                <Text textStyle="heading3Semi">{formatMessage('vex.screen.deliveryMethodSelection.header')}</Text>
                <Text>{formatMessage('vex.screen.deliveryMethodSelection.subheader')}</Text>
              </Group>
              <Group variant="vertical" spacing="xl">
                <Group variant="vertical" width="full">
                  <Typography.SectionLabel
                    label={formatMessage('vex.screen.deliveryMethodSelection.digitalSectionLabel')}
                  />
                  <Group variant="vertical">{renderDigitalDeliveryMethodsSelectionCards()}</Group>
                </Group>
                <Group variant="vertical" width="full">
                  <Typography.SectionLabel
                    label={formatMessage('vex.screen.deliveryMethodSelection.mailSectionLabel')}
                  />
                  <Group variant="vertical">{renderNonDigitalDeliveryMethodsSelectionCards()}</Group>
                </Group>
              </Group>
            </Group>
            <SUVCInformationModalScreen
              isOpen={isSUVCModalOpen}
              onClose={onSUVCModalClose}
              onConfirm={() => onDMselection(DeliveryMethodType.VirtualCard)}
              track={deliveryMethodClickTrack}
            />
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget />;

    return (
      <ExternalLayout
        data-component="DeliveryMethodSelectionScreen"
        data-testid="unilateral-delivery-method-selection-screen"
        {...props}
        header={header}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

DeliveryMethodSelectionScreen.displayName = 'DeliveryMethodSelectionScreen';
