import { Card, ExternalLayout, Group, StatusIconSolid, Text } from '@melio/penny';
import { DeliveryMethodType, PaymentUpgradeOfferOptionEnum } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { usePageViewStatus } from '@melio/vex-domain';
import { FooterWidget } from '@melio/vex-widgets';

import { ANALYTICS_CONTEXT } from '../../consts';
import { usePaymentAlreadyDeliveredLabels } from '../../hooks';

export type PaymentAlreadyDeliveredScreenProps = {
  deliveryEta: Date;
  formattedDeliveryMethodDetails: string;
  offerType?: PaymentUpgradeOfferOptionEnum | null;
  deliveryMethodType?: DeliveryMethodType;
  amount: number;
  orgName: string;
};

export const PaymentAlreadyDeliveredScreen = forwardRef<PaymentAlreadyDeliveredScreenProps, 'div'>(
  (
    { deliveryEta, offerType, formattedDeliveryMethodDetails, deliveryMethodType, orgName, amount, children, ...props },
    ref
  ) => {
    const { title, description } = usePaymentAlreadyDeliveredLabels({
      offerType,
      deliveryMethodType,
      amount,
      orgName,
      formattedDeliveryMethodDetails,
      deliveryEta,
    });

    usePageViewStatus(ANALYTICS_CONTEXT, {
      StatusType: 'Failure',
      ErrorType: 'payment already delivered',
      PageName: 'payment-already-delivered',
    });

    const content = (
      <Card paddingX="xl" paddingY="l">
        <Group variant="vertical" spacing="xl">
          <Group variant="vertical" spacing="s">
            <StatusIconSolid variant="success" size="large" />
            <Text textStyle="body2Semi">{title}</Text>
            <Text>{description}</Text>
          </Group>
        </Group>
      </Card>
    );

    return (
      <ExternalLayout
        data-component="PaymentUpgradeActivity.PaymentAlreadyDeliveredScreen"
        data-testid="vex-payment-upgrade-payment-delivered-screen"
        {...props}
        content={content}
        footer={<FooterWidget />}
        ref={ref}
      />
    );
  }
);

PaymentAlreadyDeliveredScreen.displayName = 'PaymentUpgradeActivity.PaymentAlreadyDeliveredScreen';
