import { LottieProps } from '@melio/penny';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';
type Props = Omit<LottieProps, 'animation'> & { getAnimationData: () => Promise<LottieProps['animation']>; id: string };

const LazyLottie = lazy(() => import('./LazyLottie'));

function _LazyAnimation({ getAnimationData, ...props }: Props) {
  const { data } = useQuery<LottieProps['animation']>({
    queryKey: [`animation-${props.id}-${location.href}`],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    queryFn: () => {
      import('./LazyLottie');
      return getAnimationData().then((module) => {
        if (typeof module === 'object' && module && 'default' in module) {
          return module.default;
        } else if (typeof module === 'object' && module && 'animationData' in module) {
          return module.animationData;
        }
      });
    },
    suspense: true,
  });
  return <LazyLottie {...props} animation={data} />;
}

export function LazyAnimation({ getAnimationData, id, ...props }: Props) {
  return (
    <ErrorBoundary fallback={<div></div>}>
      <Suspense fallback={<div></div>}>
        <_LazyAnimation {...props} getAnimationData={getAnimationData} id={id} />
      </Suspense>
    </ErrorBoundary>
  );
}
