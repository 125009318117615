import { BoxProps } from '@chakra-ui/react';
import { Grid } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const Header = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Grid
    as="header"
    gridTemplateColumns="1fr 1fr 1fr"
    gridTemplateAreas="'header-back header-progressBar header-close'"
    alignItems="center"
    padding={{ xs: 's', s: 'm', m: 'm' }}
    paddingX={{ xs: 's-m' }}
    height={{ xs: '64px', s: '72px', m: '72px' }}
    borderBottom={{ xs: 'basic.light', s: 'basic.light' }}
    {...props}
    ref={ref}
  />
));

Header.displayName = 'NewSinglePaymentStepLayout.Header';
