import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const NoBillsFoundIcon = (props: IconProps) => (
  <Icon width="100%" height="80px" viewBox="0 0 80 80" fill="none" {...props}>
    <path
      d="M2.24414 33.2833H22.5241C23.8376 33.2827 25.1383 33.5409 26.3519 34.0431C27.5656 34.5452 28.6684 35.2815 29.5975 36.21L33.3341 39.9467C35.1382 41.7502 36.1849 44.175 36.2598 46.7249C36.3348 49.2748 35.4325 51.7569 33.7375 53.6633L29.1941 58.7733C27.5668 60.6036 26.6678 62.9675 26.6675 65.4167V75.9"
      stroke="#228800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.5365 13.2833H48.2031C46.214 13.2833 44.3063 14.0735 42.8998 15.48C41.4933 16.8865 40.7031 18.7942 40.7031 20.7833C40.7031 22.7724 41.4933 24.6801 42.8998 26.0866C44.3063 27.4931 46.214 28.2833 48.2031 28.2833H49.6431C51.2913 28.2809 52.9145 28.6859 54.3684 29.4622C55.8223 30.2386 57.0617 31.3623 57.9765 32.7333L61.7531 38.4"
      stroke="#228800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.6463 76.4802C43.4931 79.0734 34.7063 78.8616 26.6875 75.8784C18.6687 72.8952 11.88 67.3126 7.40415 60.0211C2.92831 52.7295 1.02325 44.1491 1.99285 35.6485C2.96245 27.1479 6.75085 19.2169 12.7537 13.1206C18.7566 7.0243 26.6282 3.11388 35.1128 2.01313C43.5974 0.912385 52.2062 2.68473 59.566 7.04744C66.9258 11.4101 72.6126 18.1119 75.7192 26.0836C78.8258 34.0554 79.1733 42.8379 76.7063 51.0302"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.3333 71.6167C65.6971 71.6167 71.6667 65.6471 71.6667 58.2833C71.6667 50.9196 65.6971 44.95 58.3333 44.95C50.9695 44.95 45 50.9196 45 58.2833C45 65.6471 50.9695 71.6167 58.3333 71.6167Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.7598 67.71L78.3331 78.2833"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
