export const BankSuccess: React.VFC = () => (
  <svg height="100%" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11478_2542)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
        fill="#98D5EC"
      />
      <mask
        id="mask0_11478_2542"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="18"
        width="108"
        height="108"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72 126C101.823 126 126 101.823 126 72C126 42.1767 101.823 18 72 18C42.1767 18 18 42.1767 18 72C18 101.823 42.1767 126 72 126Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11478_2542)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7851 98.8003H119.932V126.393H22.7851V98.8003Z"
          fill="#273B49"
        />
        <path d="M110.923 95.6548H32.8635V98.8004H110.923V95.6548Z" fill="white" />
        <path d="M102.369 55.8105H40.3488V89.364H102.369V55.8105Z" fill="#DCD7DA" />
        <path d="M107.715 92.5093H35.0026V95.6549H107.715V92.5093Z" fill="#DCD7DA" />
        <path d="M105.576 89.3638H37.1404V92.5094H105.576V89.3638Z" fill="white" />
        <path d="M49.9722 51.6162H43.5564V89.3638H49.9722V51.6162Z" fill="white" />
        <path d="M59.596 51.6162H53.1802V89.3638H59.596V51.6162Z" fill="white" />
        <path d="M89.5368 51.6162H83.1209V89.3638H89.5368V51.6162Z" fill="white" />
        <path d="M99.1605 51.6162H92.7446V89.3638H99.1605V51.6162Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.973 57.9073H38.7453C38.4244 57.9073 38.1036 57.8025 37.8898 57.488L35.7512 54.6569C35.3234 54.1326 35.5373 53.5034 36.0719 53.0841L70.8244 32.0083C71.1453 31.7987 71.5729 31.7987 72.0006 32.0083L106.753 53.0841C107.288 53.3987 107.395 54.1326 107.074 54.6569L104.935 57.488C104.615 57.8025 104.401 57.9073 103.973 57.9073Z"
          fill="white"
        />
        <path d="M77.7737 68.3926H64.942V89.3635H77.7737V68.3926Z" fill="#C2C0C5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3591 51.6163C74.3118 51.6163 76.7056 49.269 76.7056 46.3735C76.7056 43.4781 74.3118 41.1309 71.3591 41.1309C68.4062 41.1309 66.0125 43.4781 66.0125 46.3735C66.0125 49.269 68.4062 51.6163 71.3591 51.6163Z"
          fill="#DCD7DA"
        />
      </g>
      <g clipPath="url(#clip1_11478_2542)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.512 45.27C119.968 45.27 126.012 39.1654 126.012 31.635C126.012 24.1046 119.968 18 112.512 18C105.057 18 99.0125 24.1046 99.0125 31.635C99.0125 39.1654 105.057 45.27 112.512 45.27Z"
          fill="#128020"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.449 37.5799L105.453 33.5439C105.318 33.4076 105.318 33.1894 105.453 33.0531C105.588 32.9167 105.804 32.9167 105.939 33.0531L109.692 36.8436L118.818 26.2356C118.953 26.0992 119.142 26.0719 119.304 26.2083C119.439 26.3446 119.466 26.5355 119.331 26.6992L109.962 37.6072C109.881 37.6617 109.8 37.689 109.692 37.689C109.611 37.689 109.53 37.6617 109.449 37.5799Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11478_2542">
        <rect width="108" height="108" fill="white" transform="translate(18 18)" />
      </clipPath>
      <clipPath id="clip1_11478_2542">
        <rect width="27.27" height="27.27" fill="white" transform="translate(99 18)" />
      </clipPath>
    </defs>
  </svg>
);
