import { Context, ReactNode } from 'react';

import { CreateConfig, useActivityConfigData } from '../hooks/useActivityConfigData';

type WithActivityConfigProps<T, E> = {
  children: ReactNode;
  createConfig: CreateConfig<T, E>;
  params?: E;
  Context: Context<T>;
  onError?: ErrorFunction;
};

export function WithActivityConfig<T extends Record<string, unknown>, E>({
  children,
  createConfig,
  params,
  Context,
}: WithActivityConfigProps<T, E>) {
  const { config } = useActivityConfigData<T, E>(createConfig, params);
  return <Context.Provider value={config}>{children}</Context.Provider>;
}
