import { LinkConstants } from '../types';

export const defaultLinks: LinkConstants = {
  'app.settings.SupportSection.accordion.businessBills.links.link1.href':
    'https://melio.zendesk.com/hc/en-us/articles/4415984007708',
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4977565763740-',
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5024954247452-Sync-your-QuickBooks-Online-account-with-the-Vendor-Payments-experience',
  'app.settings.SupportSection.accordion.addDebitCard.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4994463103004-Add-a-debit-card',
  'app.settings.SupportSection.accordion.verifyMicroDeposits.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5033029434140-Verifying-a-bank-account-with-micro-deposits',
  'app.settings.SupportSection.accordion.addBankAccount.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4926572186908-Add-and-connect-a-bank-account-automatically',
  'app.settings.SupportSection.accordion.addBill.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4902651113884-Adding-a-bill-to-the-Vendor-Payments-experience',
  'app.settings.SupportSection.accordion.payBill.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4886795266332-Paying-a-bill-with-the-Vendor-Payments-experience',
  'app.settings.SupportSection.accordion.deliveryTimelines.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5023967248284-Payment-delivery-timelines',
  'app.settings.SupportSection.accordion.vendors.links.link1.href':
    'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/4881260808092-Add-edit-and-delete-a-vendor',
  'app.settings.SupportSection.accordion.cancelPayment.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702573855516-How-do-I-cancel-a-scheduled-payment-',
  'app.settings.SupportSection.accordion.security.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702589565724-How-secure-is-Pay-Business-Bills-',
  'app.settings.SupportSection.accordion.addBillClone.links.link1.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702518475804-Adding-a-bill-to-Pay-Business-Bills',
  'app.settings.SupportSection.accordion.addBillClone.links.link2.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702492439708-How-to-sync-with-QuickBooks-Online',
  'app.settings.SupportSection.accordion.addBillClone.links.link3.href':
    'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702494960668-How-to-automatically-receive-bills-to-your-Bills-inbox',
  'widgets.footer.termsOfServiceLink.href': 'https://meliopayments.com/terms-of-service/',
  'widgets.footer.privacyPolicy.href': 'https://meliopayments.com/privacy-policy/',
  'widgets.learnAboutRates.href': 'https://meliopayments.com/learn-about-rates/',
  'widgets.companySettings.header.sectionBanner.linkHref': 'tel:888-888-8888',
  'creditKey.legal.privacyPolicy': 'https://www.creditkey.com/legal/privacy-policy',
  'creditKey.legal.termsOfService': 'https://www.creditkey.com/legal/terms-of-service-and-electronic-disclosures',
  'activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.creditKey': 'https://www.creditkey.com/',
  'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.vendor-ineligible.learnMore.href':
    'https://melio.zendesk.com/hc/en-us/articles/6464064007964-Who-is-eligible-for-Pay-over-time-with-Melio-',
  'activities.financingApplication.screens.applicationForm.title.learnMore':
    'https://melio.zendesk.com/hc/en-us/articles/6457841438108-Pay-over-time-with-installments-in-Melio',
  helpCenter: 'https://melio.zendesk.com/hc/en-us',
  submitSupportRequest: '',
  'fx.legal.termsAndConditions': 'https://wise.com/en/legal/terms-and-conditions',
  backButtonUrl: '',
  backButtonUrlFallback: '',
  portalLink: '',
  failedToCollectSupport: '',
  financingMoreDetails: 'https://melio.zendesk.com/hc/en-us/articles/6457841438108',
  unilateralDeliveryLearnMore: 'https://melio.zendesk.com/hc/en-us/articles/4447358208018-When-will-my-payment-arrive',
  undepositedCheckLearnMore:
    'https://melio.zendesk.com/hc/en-us/articles/5655321824796-Void-a-check-that-hasn-t-been-deposited',
  contactCreditKey: 'https://melio.zendesk.com/hc/en-us/articles/6817651109660-How-to-contact-Credit-Key-',
  resolveFailedPayment: 'https://melio.zendesk.com/hc/en-us/articles/8560928299548-Resolving-a-failed-repayment',
  contactMelioSupport:
    "https://melio.zendesk.com/hc/en-us/articles/8487503442460-How-to-contact-Melio-app-support#:~:text=Here's%20how%20to%20contact%20Melio,Tap%20Support",
  quickBooksDesktopWebConnectorGuide:
    'https://melio.zendesk.com/hc/en-us/articles/15423327311260-How-to-connect-Melio-to-QuickBooks-Desktop',
  verifyBankAccountWithMicroDeposit: '',
};
