import { useEffect } from 'react';

import { APP_INIT_URL_KEY } from './useWizard';

export const useInitWizard = () => {
  useEffect(() => {
    if (!window.sessionStorage.getItem(APP_INIT_URL_KEY)) {
      window.sessionStorage.setItem(APP_INIT_URL_KEY, window.location.pathname);
    }
  }, []);
};
