import { useGuestPayment, useVexPayment } from '@melio/platform-api';

export const usePaymentData = (
  {
    paymentId,
    isGuest,
  }: {
    paymentId: string;
    isGuest: boolean;
  },
  { enabled: isUsePaymentDataEnabled }: { enabled?: boolean } = {}
) => {
  const {
    data: guestPaymentData,
    error: guestPaymentError,
    refetch: refetchGuestPayment,
    isFetching: isLoadingGuestPayment,
  } = useGuestPayment(paymentId, { enabled: isUsePaymentDataEnabled && isGuest });
  const {
    data: nonGuestPaymentData,
    isFetching: isLoadingNonGuestPayment,
    error: nonGuestPaymentError,
    refetch: refetchNonGuestPayment,
  } = useVexPayment(paymentId, { enabled: isUsePaymentDataEnabled && !isGuest });

  return {
    refetchPayment: isGuest ? refetchGuestPayment : refetchNonGuestPayment,
    isLoading: isLoadingGuestPayment || isLoadingNonGuestPayment,
    data: isGuest ? guestPaymentData : nonGuestPaymentData,
    error: isGuest ? guestPaymentError : nonGuestPaymentError,
  };
};
