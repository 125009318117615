/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { Divider, Group, Link, NakedButton, SectionBanner, Text, VerificationCodeField } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { VerificationCodeFailureResult } from './VerificationCodeFailureResult';

export type VerificationCodeWidgetProps = {
  onCodeComplete: (code: string, email: string) => Promise<unknown>;
  onUserNotFound: VoidFunction;
  emailAddress: string;
  generateNewCode: (emailAddress: string) => void;
  helpLinkHref: string;
  resendEmailError?: string;
};

// TODO: update when backend implementation complete ME-31156

const SECONDS_TO_ENABLE_RESEND = 10;

const ResendButton = ({ onClick }: { onClick: () => void }) => {
  const [secToResend, setSecToResend] = useState<number>(SECONDS_TO_ENABLE_RESEND);
  const { formatMessage } = useMelioIntl();
  const isDisabled = secToResend > 0;

  const onResendClick = () => {
    onClick();
    setSecToResend(SECONDS_TO_ENABLE_RESEND);
  };

  useEffect(() => {
    const resendIntervalId = setInterval(() => {
      if (isDisabled) {
        setSecToResend((prevCount) => prevCount - 1);
      }
    }, 1000);
    return () => {
      clearInterval(resendIntervalId);
    };
  }, [secToResend]);

  return (
    <NakedButton
      isDisabled={isDisabled}
      variant="primary"
      onClick={onResendClick}
      label={
        isDisabled
          ? formatMessage('vex.widgets.emailVerificationCodeWidget.resendCode.timer', { secToResend })
          : formatMessage('vex.widgets.emailVerificationCodeWidget.resendCode.button')
      }
    />
  );
};

export const VerificationCode = forwardRef<VerificationCodeWidgetProps, 'div'>(
  (
    { onCodeComplete, onUserNotFound, emailAddress, generateNewCode, helpLinkHref, resendEmailError, ...props },
    ref
  ) => {
    const [resentCode, setResentCode] = useState<boolean>(false);
    const [isUserNotFound, setUserNotFound] = useState<boolean>(false);
    const [rerenderField, resetField] = useState<boolean>(true);
    const { formatMessage } = useMelioIntl();

    const { track } = useAnalytics();

    const resetCodeField = () => {
      resetField(false);
      setTimeout(() => resetField(true));
    };

    const resendCode = () => {
      track('Vendor', 'Click', {
        Cta: 'resend',
      });
      generateNewCode(emailAddress);
      setResentCode(true);
      resetCodeField();
    };

    const onHelpLinkClick = () => {
      track('Vendor', 'Click', {
        Cta: 'contact-us',
      });
    };

    const onCompleteHandler = async (code: string) => {
      try {
        setResentCode(false);
        await onCodeComplete(code, emailAddress);
        return true;
      } catch (e) {
        if (e === VerificationCodeFailureResult.UserNotFoundError) {
          resetCodeField();
          setUserNotFound(true);
          return true;
        }
        return false;
      }
    };

    const onUserNotFoundClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      track('Vendor', 'Click', {
        Cta: 'go-back-and-accept-as-guest',
      });
      onUserNotFound();
    };

    return (
      <Box data-component="VerificationCode" ref={ref} {...props}>
        <Group variant="vertical" spacing="xl">
          <Group variant="vertical" spacing="s" alignItems="flex-start" textAlign="start">
            <Text textStyle="heading3Semi">{formatMessage('vex.widgets.emailVerificationCodeWidget.title')}</Text>
            <Text textStyle="body2semi">
              {formatMessage('vex.widgets.emailVerificationCodeWidget.content', { emailAddress })}
            </Text>
          </Group>

          {resentCode && (
            <SectionBanner
              description={formatMessage('vex.widgets.emailVerificationCodeWidget.resendCode.success')}
              variant="success"
            />
          )}

          {isUserNotFound && (
            <SectionBanner
              data-testid="VerificationCode.user-not-found"
              title={formatMessage('vex.widgets.emailVerificationCodeWidget.userNotFound.title')}
              description={
                <Group>
                  <Text textStyle="body3">
                    {formatMessage('vex.widgets.emailVerificationCodeWidget.userNotFound.description')}
                    <Link
                      href="#"
                      onClick={onUserNotFoundClick}
                      label={formatMessage('vex.widgets.emailVerificationCodeWidget.userNotFound.descriptionLinkText')}
                      size="medium"
                    />
                  </Text>
                </Group>
              }
              variant="critical"
            />
          )}

          {resendEmailError && (
            <SectionBanner
              data-testid="EmailLoginFrom.form-error-email"
              variant="critical"
              title={formatMessage('vex.widgets.login.email.invalidEmailError.title')}
              description={
                <Text>
                  {formatMessage('vex.widgets.login.email.invalidEmailError.message')}
                  {formatMessage('vex.widgets.login.email.invalidEmailError.link')}
                </Text>
              }
            />
          )}

          <Group variant="vertical" spacing="l" alignItems="center">
            <Box>
              {rerenderField && (
                <VerificationCodeField
                  onComplete={onCompleteHandler}
                  data-testid="VerificationCode.input-verification-code"
                />
              )}
            </Box>
          </Group>

          <Group variant="vertical" spacing="s" alignItems="center" textAlign="center">
            {!isUserNotFound && (
              <>
                <Text textStyle="body3" as="span">
                  {formatMessage('vex.widgets.emailVerificationCodeWidget.resendCode.label')}
                  <ResendButton onClick={resendCode} />
                </Text>
                <Divider label={formatMessage('vex.widgets.emailVerificationCodeWidget.dividerLabel')} />
              </>
            )}
            <Text as="span" textStyle="body3">
              {formatMessage('vex.widgets.emailVerificationCodeWidget.helpSection.text')}
              <Link
                onClick={onHelpLinkClick}
                href={helpLinkHref}
                newTab
                label={formatMessage('vex.widgets.emailVerificationCodeWidget.helpSection.link')}
              />
            </Text>
          </Group>
        </Group>
      </Box>
    );
  }
);

VerificationCode.displayName = 'VerificationCode';
