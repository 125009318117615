import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

export type UseVendorOnboardingTabBrandingProps = {
  faviconUrl: string;
  isLoading: boolean;
};

export const useVendorOnboardingTabBranding = ({ faviconUrl, isLoading }: UseVendorOnboardingTabBrandingProps) => {
  const { formatMessage } = useMelioIntl();
  const tabTitle = formatMessage('vex.activities.vendorOnboarding.tab.title');

  useEffect(() => {
    if (document.title !== tabTitle) {
      document.title = tabTitle;
    }

    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (!favicon) {
      return;
    }

    favicon.href = isLoading || !faviconUrl ? 'data:image/svg+xml' : faviconUrl;
    favicon.rel = 'shortcut icon';
    favicon.type = 'image/x-icon';
  }, [isLoading, faviconUrl, tabTitle]);
};
