import { useParams } from 'react-router-dom';
import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';
import { VendorPaymentTrackingActivity } from '@melio/vex-activities';

export const VendorPaymentTrackingRoute = () => {
  const [isVendorPaymentTrackingFeatureOpen, isLoadingPaymentTrackingFeatureFlag] = useAnonymousFeature(
    FeatureFlags.IsVendorPaymentTrackingFeatureOpen,
    false,
  );
  const [isUpsellOfferBannerEnabled, isLoadingUpsellOfferBannerFeatureFlag] = useAnonymousFeature(
    FeatureFlags.isUpsellOfferBannerEnabled,
    false,
  );

  const { paymentId } = useParams<{ paymentId: string }>();

  if (!paymentId) {
    return null;
  }

  return (
    <VendorPaymentTrackingActivity
      paymentId={paymentId}
      isVendorPaymentTrackingFeatureOpen={isVendorPaymentTrackingFeatureOpen}
      isLoading={isLoadingPaymentTrackingFeatureFlag || isLoadingUpsellOfferBannerFeatureFlag}
      isUpsellOfferBannerEnabled={isUpsellOfferBannerEnabled}
    />
  );
};

VendorPaymentTrackingRoute.displayName = 'VendorPaymentTrackingRoute';
