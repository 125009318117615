import { IconProps } from '@chakra-ui/react';
import { TabsProps, ThemeOptions } from '@melio/penny';
import { NotificationPreferences, PartnerName } from '@melio/platform-api';
import type { Translations } from '@melio/platform-i18n';
import { MelioConfig } from '@melio/platform-provider';

import { SettingsCardIdEnum } from './cl/components/SettingsCard/SettingsCard.component';
import {
  AccountingFirmFeature,
  ExpensesFeature,
  GetPaidFeature,
  ReceivingMethodsFeature,
} from './types/featuresConfig';

export type IconType = (props: IconProps) => JSX.Element;

export type AuthQueryParamsKeyMapping = {
  token: string;
  orgId?: string;
  userId?: string;
  terms?: string;
  mock?: string;
  target?: string;
  targetId?: string;
  entryPoint?: string;
  redirectUrl?: string;
  userRole?: string;
  backButtonReferenceId?: string;
};

export type TokenProvider = 'tbt' | 'tabapay';

export const partnerHostToPartnerName: { [key: string]: PartnerName } = {
  'vendorpayments-qa.capitalone.com': PartnerName.CapitalOne,
  'vendorpayments.capitalone.com': PartnerName.CapitalOne,
  'billpay-clover.by.melio.com': PartnerName.Clover,
  'app.melio.com': PartnerName.Melio,
};

export const tenantToPartnerConfiguration: { [key: string]: { partnerName: PartnerName; basePath: string } } = {
  '1403': { partnerName: PartnerName.FiservUsBank, basePath: 'cfc' },
  '12858': { partnerName: PartnerName.FiservUsBank, basePath: 'cfc' },
  '9403': { partnerName: PartnerName.FiservFirstFinancial, basePath: 'cfc' },
};

type OAuthConfiguration =
  | {
      required: true;
      queryParamsMapping: AuthQueryParamsKeyMapping;
      redirectWhenMissingToken?: boolean;
    }
  | {
      required: false;
    };

type BillsInboxConfiguration = {
  learnMoreLink: string;
  showEmailLinkTitle: boolean;
};

export type PartnerIcons = {
  Favicon: string;
  MelioIcon: IconType;
  LogoIcon: IconType;
  SmallLogoIcon?: IconType;
  ErrorIcon: IconType;
  EmptyBillsIcon: IconType;
  EmptyVendorsIcon: IconType;
  NoBillsFoundIcon: IconType;
  NoVendorsFoundIcon: IconType;
  VerifyEmailIcon: IconType;
  TaxSummaryIcon: IconType;
  ExpiredSessionIcon: IconType;
  BackToPartnerIcon: IconType;
  StartNewExperienceIcon: IconType;
  TryDifferentEmailIcon: IconType;
  AddBillIcon: IconType;
};

export enum Features {
  GetPaid = 'getPaid',
  ReceivingMethods = 'receivingMethods',
  Expenses = 'expenses',
  AccountingFirm = 'accountingFirm',
}

type FeatureConfig = {
  [Features.GetPaid]?: GetPaidFeature;
  [Features.Expenses]?: ExpensesFeature;
  [Features.ReceivingMethods]?: ReceivingMethodsFeature;
  [Features.AccountingFirm]?: AccountingFirmFeature;
};

export enum SettingsGroupEnum {
  COMPANY = 'company',
  PAYMENTS = 'payments',
  AR = 'ar',
  SUPPORT = 'support',
}

export type PartnerSettingsGroupConfig = {
  type: SettingsGroupEnum;
  items: SettingsCardIdEnum[];
};

export type PartnerSettingsConfig = PartnerSettingsGroupConfig[];

export interface PartnerConfig {
  displayName: string;
  partnerNameAnalyticsEvents: string;
  partnerProductName: string;
  tabsVariant?: TabsProps['variant'];
  theme: ThemeOptions;
  config: MelioConfig;
  icons: PartnerIcons;
  translations: Translations;
  supportEmail?: string;
  supportQuestionIds?: number[]; //TODO make default
  oAuth: OAuthConfiguration;
  showOnboardingSwiper: boolean;
  showOnboardingBackToPartner?: boolean;
  billsInbox?: BillsInboxConfiguration;
  allowEditCompanyDetails: boolean;
  allowEditFirstAndLastName: boolean;
  features?: FeatureConfig;
  hideFooter?: boolean;
  skipLogoutSuccessful?: boolean;
  supportedNotificationGroupsOptions: Partial<NotificationPreferences>;
  getSettingsConfig: (isArEnabled?: boolean, isTaxAndReportsEnabled?: boolean) => PartnerSettingsConfig;
  generatePaymentPagePath?: (ownVendorHandle: string) => string;
  getCurrentOrgUrl?: ({
    isMelioMigrationWhitelistToBlacklistEnabled,
  }: {
    isMelioMigrationWhitelistToBlacklistEnabled: boolean;
  }) => Promise<string>;
}

export interface PartnersConfig {
  [PartnerName.CapitalOne]: PartnerConfig;
  [PartnerName.Clover]: PartnerConfig;
  [PartnerName.Melio]: PartnerConfig;
  [PartnerName.Shopify]: PartnerConfig;
  [PartnerName.BuiltTechnologies]: PartnerConfig;
  [PartnerName.WellsFargo]: PartnerConfig;
  [PartnerName.Sbb]: PartnerConfig;
  [PartnerName.Fiserv]: PartnerConfig;
  [PartnerName.FiservUsBank]: PartnerConfig;
  [PartnerName.FiservXdDemo]: PartnerConfig;
  [PartnerName.FiservFirstFinancial]: PartnerConfig;
  [PartnerName.DeploymentsDemo]: PartnerConfig;
  // off-the-shelf partners that we'll eventually move to store the config in db
  gusto: PartnerConfig;
  ['optum-bank-demo']: PartnerConfig;
  paypal: PartnerConfig;
}
